import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@mui/material/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxHeight: 500,
    overflow: 'auto'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  pos: {
    marginBottom: 12,
  },
});

const meetings = [
  { id: 1, contact: 'John Doe', date: 'Monday, April 25, 2023', type: 'In-person' },
  { id: 2, contact: 'Jane Smith', date: 'Wednesday, April 27, 2023', type: 'Video' },
  { id: 3, contact: 'Bob Johnson', date: 'Friday, April 29, 2023', type: 'Phone' },
];

export default function MeetingSchedule({ scheduleCalls }) {
  const classes = useStyles();

  const secondaryTexts =  (meeting) => {
    if(meeting?.notes.length > 0)
    {
      return meeting?.followUpCall + '  \n' + meeting?.notes
    }
    else{
      return meeting?.followUpCall
    }
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          Meeting Schedule
        </Typography>
        <List>
          {scheduleCalls
            ? scheduleCalls.map((meeting) => (
                <ListItem key={meeting.id}>
                  <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: 'red' }}
                      alt={meeting?.adminUser.charAt(0)}
                      src="/static/images/avatar/1.jpg"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={meeting?.adminUser + " with " + meeting?.callee}
                    secondary={secondaryTexts(meeting)}
                  />
                </ListItem>
              ))
            : "No Call scheduled"}
        </List>
      </CardContent>
    </Card>
  );
}