import React,{ useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Input } from 'antd';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { useDispatch } from "react-redux";
import { updateModelYearsPageState } from '../../reducers/ModelYears'
import { DatePicker } from 'antd';
import { Button, Col, Form, Row, Select, Drawer, Divider } from 'antd';
import moment from 'moment';

export default function ModelYearFilters(props) {
  const { Search } = Input;
  const { pageState, modelGroupOptions, filter, setFilter, makes } = props;
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const initialState = {
    makeId: '',
    modelName: '',
    modelGroupId: '',
    startDate: '',
    endDate: '',
    modelYear: '',
    modelNumber: '',
    volumeNumber: '',
    slcMap: ''
  }
  const [State, setState] = useState({...initialState})
  const slcFlags = [{ id: 1, value: '1', name: 'Yes' }, { id: 2, value: '0', name: 'No' }]

  const dispatch = useDispatch();

  const onChange = (e) => {
    if(e.target.value === ''){
      dispatch(updateModelYearsPageState({ search: '', page: 0, filterType: 'notColumn' }))
    }
  };

  const toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
      }
      setFilter(open)
  };

  const modelYearFilter = () => {
    dispatch(updateModelYearsPageState({...State, startDate: State.startDate, endDate: State.endDate, modelYear: State.modelYear, modelGroupId: State.modelGroupId,
    makeId: State.makeId, modelName: State.modelName, modelNumber: State.modelNumber, volumeNumber: State.volumeNumber, slcMap: State.slcMap, page: 0, filterType: 'notColumn' })) 
  }

  const clearFilter = () =>{
    setState({...initialState})
    dispatch(updateModelYearsPageState({ startDate: '', endDate: '', modelYear: '', modelGroupId: '',
    makeId: '', modelName: '', modelNumber: '', volumeNumber: '', slcMap: '', page: 0, filterType: 'notColumn' }))
  }

  const isClearButtonViewable = () => {
    return (pageState.startDate === '' && pageState.modelGroupId === '' &&  pageState.volumeNumber === '' &&
    pageState.modelYear === '' && pageState.makeId === '' && pageState.modelNumber === '' && pageState.modelName === '' && pageState.slcMap === '')
  }

  const allFilters = () => (

  <Form layout="vertical">
    <Row gutter={16}>
      <Col span={25}>
      <Form.Item
          label="Search by Make"
      >
        <Select
          style={{ padding : '2px', width: "300px" }}
          size="large"
          placeholder="Select Make"
          allowClear
          onChange={(value) => {
            if(value !== undefined && value!== null){
              setState({...State, makeId: value})
            }
            else {
              setState({...State, makeId: ''})
            }
          }}
          value={State.makeId || null }
        >
        {makes &&
          makes.map((record, index) => {
            return (
              <Option key={index} value={record.id}>
                {`${record.name}`}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      </Col>
    </Row>
    <Divider
      type="horizontal"
      style={{ borderColor: "#000", margin : '2px'  , padding : '2px' }}
    />
    <Row gutter={16}>
      <Col span={25}>
      <Form.Item
          label="Search by model group"
      >
        {
          modelGroupOptions && 
          <Autocomplete
              style={{ padding : '2px', width: "300px" }}
              disablePortal
              id="combo-box-demo"
              value = {State.modelGroupId === '' ? null : ( modelGroupOptions.find(modelGroup => modelGroup.id === State.modelGroupId))}
              options={State.makeId !== '' ? modelGroupOptions.filter(modelGroup => modelGroup.make_id == State.makeId) : modelGroupOptions}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => {
                setState({...State, modelGroupId: value?.id})
              }}
              renderInput={(params) => <TextField {...params} size="small" label="Model Groups" />}
              renderOption={(props, option) => (
                <li {...props}>
                  <StyledOptionBox>{option.name}</StyledOptionBox>
                </li>
              )}
        />
        }
      </Form.Item>
      </Col>
    </Row>
    <Divider
      type="horizontal"
      style={{ borderColor: "#000", margin : '2px'  , padding : '2px' }}
    />
    <Row gutter={16}>
      <Col span={25}>
      <Form.Item
          label="Search by model name"
      >
      <Search 
        style={{ padding : '2px', width: "300px" }}
        placeholder="Model name" 
        size="large" 
        allowClear 
        className="leaseAppListSearch"  
        onChange={(e) => {
          setState({...State, modelName: e.target.value})
        }}
        enterButton
        value={State.modelName}
      />
      </Form.Item>
      </Col>
    </Row>
    <Divider
      type="horizontal"
      style={{ borderColor: "#000", margin : '2px'  , padding : '2px' }}
    />
    <Row gutter={16}>
      <Col span={25}>
      <Form.Item
          label="Search by model number"
      >
      <Search 
        style={{ padding : '2px', width: "300px" }}
        placeholder="Model number" 
        size="large" 
        allowClear 
        className="leaseAppListSearch"  
        onChange={(e) => {
          setState({...State, modelNumber: e.target.value})
        }}
        enterButton
        value={State.modelNumber}
      />
      </Form.Item>
      </Col>
    </Row>
    <Divider
      type="horizontal"
      style={{ borderColor: "#000", margin : '2px'  , padding : '2px' }}
    />
    <Row gutter={16}>
      <Col span={50}>
        <Form.Item
          label="Search by model year"
        >
          <DatePicker 
            style={{ padding : '2px', width: '300px' }}
            onChange={(event,value) => {
              setState({...State, modelYear: value})
            }} 
            picker="year" 
            value = { State.modelYear !== "" ? moment(State.modelYear) : null}
            />
        </Form.Item>
      </Col>
      <Divider
      type="horizontal"
      style={{ borderColor: "#000", margin : '2px'  , padding : '2px' }}
    />
    </Row>
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          label="Search using start and end date"
        >
        <RangePicker 
          placement="topLeft"
          onChange={(event,value) => {
              setState({...State, startDate: value[0], endDate: value[1]})
          }} 
          value = {[State.startDate !== "" ? moment(State.startDate) : null, State.endDate !== "" ? moment(State.endDate) : null]}
        />
        </Form.Item>
      </Col>
    </Row>
    <Divider
      type="horizontal"
      style={{ borderColor: "#000", margin : '2px'  , padding : '2px' }}
    />
    <Row gutter={16}>
      <Col span={25}>
      <Form.Item
          label="Search by volume number"
      >
      <Search 
        style={{ padding : '2px', width: "300px" }}
        placeholder="Volume number" 
        size="large" 
        allowClear 
        className="leaseAppListSearch"  
        onChange={(e) => {
          setState({...State, volumeNumber: e.target.value})
        }}
        enterButton
        value={State.volumeNumber}
      />
      </Form.Item>
      </Col>
    </Row>

    <Divider
      type="horizontal"
      style={{ borderColor: "#000", margin : '2px'  , padding : '2px' }}
    />
    <Row gutter={16}>
      <Col span={25}>
      <Form.Item
          label="Search by SLC Flag"
      >
        <Select
          style={{ padding : '2px', width: "300px" }}
          size="large"
          placeholder="Select SLC flag"
          allowClear
          onChange={(value) => {
            if(value !== undefined && value!== null){
              setState({...State, slcMap: value})
            }
            else {
              setState({...State, slcMap: ''})
            }
          }}
          value={State.slcMap || null }
        >
        {slcFlags &&
          slcFlags.map((record, index) => {
            return (
              <Option key={index} value={record.value}>
                {`${record.name}`}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      </Col>
    </Row>
  
    <Row align="middle" gutter={16}>
      <Col span={25}>
      <Form.Item
      >
            <Button
              style={{
                textAlign: "center",
                backgroundColor: "#1976d2",
                color: "#fff",
                fontSize: 16,
                fontWeight: "bold",
                borderRadius: "5px",
                marginRight: "5px",
                marginLeft: "20px",
                width: "100px"
              }}
              onClick={() => modelYearFilter()}
              >
              Filter
            </Button>
      </Form.Item>
      </Col>
      <Col span={25}>
      <Form.Item
      >
            <Button
              style={{
                textAlign: "center",
                backgroundColor: "#fff",
                color: "#000",
                fontSize: 16,
                fontWeight: "bold",
                borderRadius: '5px',
                border: "1px solid #000",
                width: "100px"
              }}
              onClick={() => clearFilter()}
              >
              Clear
            </Button>
      </Form.Item>
      </Col>
    </Row>
  </Form>

  );

  return (
    <Box sx={{ flexGrow: 1, pb: 2 }}>
    <Grid container spacing={2} direction="row"
            justifyContent="flex-end"
            alignItems="center"
    >
        <Grid item md={4} >
          <Search placeholder="Global Search" size="large" defaultValue={pageState.search} onChange={onChange} allowClear className="leaseAppListSearch" onSearch={(searchVal) => { dispatch(updateModelYearsPageState({ search: searchVal, page: 0, filterType: 'notColumn' })) }} enterButton />
        </Grid>
        <Grid item md={2} sm> </Grid>
        <Grid item md={6} sm={6} container spacing={1} direction="row">
          <Grid item md={4} sm={4}> </Grid>
          <Grid item md={4} sm={4}> </Grid>
          <Grid item md={4}>
            <Box display="flex" justifyContent="flex-end">
              {
                !isClearButtonViewable() && 
                <Button style={{ marginRight: '.5rem' }} onClick={ () => clearFilter() } >
                  Clear Filter
                </Button>
              }
              <Button onClick={() => setFilter(value => !value)}>
                Filters
              </Button>  
            </Box>
          </Grid>
       </Grid>
        <Grid item md={6} sm={6} container spacing={1} direction="row">
          <Grid item md={4} sm={4}> </Grid>
          <Grid item md={4} sm={4}> </Grid>
          <Grid item md={4} sm={4}>
          </Grid>
        </Grid>
    </Grid>
      
      <Drawer
        title="Filter"
        placement="right"
        getContainer={false}
        open={filter}
        onClose={toggleDrawer(false)}
        visible={filter}
      >
        {allFilters()}
      </Drawer>
    </Box>
  )
}

const StyledOptionBox = styled(Box)({
  "&:hover": {
    color: "#e93b1b",
    lineHeight: 1.5,
    textDecoration: 'underline',
    textUnderlineOffset: '8px',
    textDecorationcolor: '#e93b1b',
    textDecorationThickness: "2px",
    fontWeight: "500"
  },
  "&:focus": {
    borderColor: "#ef4829a8 !important"
  },
});