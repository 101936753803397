import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';

import { Button, Modal, Space, message, DatePicker } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { GET, POST, PUT } from '../../../util/network'
import logger from '../../../util/logger'

import { setLeaseAppExpiry } from '../../../reducers/LeaseApplications'
import { setColesseAfterRemove } from '../../../reducers/LeaseApplications';
import {useDispatch, useSelector} from "react-redux";
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';

const { confirm } = Modal

const auth_token = window.localStorage.getItem('auth_token')

export default function WorkflowActionButtons({ data, disableFlag, ...props }) {
  const { handleNoAuth, setLoading, toggleCollapse } = props
  const history = useHistory();

  const [expired, setExpired] = useState(data.expired)
  const [lessee, setLessee] = useState(data.lessee)
  const [colessee, setColessee] = useState(data.colessee)
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [docIssuedErrors, setDocIssuedErrors] = useState([]);
  const [docIssuedPassed, setDocIssuedPassed] = useState([]);

  const handleModal = () => {
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const isCreditUser = useSelector((state) => state.leaseApplications.isCreditUser);
  const isButtonDisabled = isCreditUser === false ? true : disableFlag;
  
  function createMarkup(error) {
    return { __html: error };
  }

  const repullLesseeCredit = async () => {
    setLoading(true)
    try {
      await GET(`/api/v1/lease_applications/repull-lessee-credit?lessee_id=${data.lessee.id}`)
        .then(() => {
          message.success('Credit Reports Will Be Repulled and Appear as a File Attachment Shortly')
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history)
          } else {
            message.error('Error processing lessee credit')
          }
        })
    } catch (e) {
      logger.error('Error pulling lessee credit', e)
    }
    setLoading(false)
  }

  const handleRepullBlackbox = async () => {
    setLoading(true)
    try {
      await GET(`/api/v1/lease_applications/repull-datax?application_identifier=${data.applicationIdentifier}`)
        .then(() => {
          message.success('Blackbox Will Be Repulled Shortly')
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history)
          } else {
            message.error('Error processing Blackbox')
          }
        })
    } catch (e) {
      logger.error('Error processing Blackbox', e)
    }
    setLoading(false)
  }

  const generateLeasePackage = async () => {
    setLoading(true)
    try {
      await GET(`/api/v1/lease_applications/generate-lease-package?id=${data.id}`)
        .then(() => {
          message.success('Your Lease Package will be generated and will appear as a File Attachment Shortly')
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history)
          } else if(e && e.response && e.response.status === 400){
            setDocIssuedPassed(e.response?.data?.passed_message)
            setDocIssuedErrors(e.response?.data?.message);
            handleModal()
          } else {
            message.error('Error processing Lease Package')
          }
        })
    } catch (e) {
      logger.error('Error processing Lease Package', e)
    }
    setLoading(false)
  }

  const repullColesseeCredit = async () => {
    setLoading(true)
    try {
      await POST(`/api/v1/lease_applications/repull-colessee-credit`, { colessee_id: data.colessee.id })
        .then(() => {
          message.success('Credit Reports Will Be Repulled and Appear as a File Attachment Shortly')
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history)
          } else {
            message.error('Error processing colessee credit')
          }
        })
    } catch (e) {
      logger.error('Error pulling colessee credit', e)
    }
    setLoading(false)
  }

  const handleSwapApplicants = async () => {
    setLoading(true)
    try {
      await PUT(`/api/v1/lease_applications/swap-lessee`, { application_identifier: data.applicationIdentifier })
        .then(() => {
          message.success('Successfully swapped.')
          setLessee(lessee)
          setColessee(colessee)
          
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history)
          } else {
            if (e && e.response && e.response.status === 401) {
              handleNoAuth(history)
            } else {
              message.error('Error while swapping')
            }
          }
        })
    } catch (e) {
      logger.error('Could not swap applicants', e)
    }
    setLoading(false)
  }

  const resendCreditDecision = async () => {
    setLoading(true)
    try {
      await POST(`/api/v1/lease_applications/resend-credit-decision`, { application_identifier: data.applicationIdentifier })
        .then(() => {
          message.success('Credit Decision Resent Successfully.')
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history)
          } else {
            message.error('Error while resending credit decision')
          }
        })
    } catch (e) {
      logger.error('Could not resend credit decision', e)
    }
    setLoading(false)
  }

  const generateCreditDecisionNotification = async () => {
    setLoading(true)
    try {
      await POST(`/api/v1/lease_applications/generate-credit-decision-notifications`, { application_identifier: data.applicationIdentifier })
        .then(() => {
          message.success('Credit Decision Notification Generated.')
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history)
          } else {
            message.error('Error while resending credit decision')
          }
        })
    } catch (e) {
      logger.error('Could not resend credit decision', e)
    }
    setLoading(false)
  }

  const removeColessee = async () => {
    setLoading(true)
    try {
      await PUT(`/api/v1/lease_applications/remove-colessee`, { application_identifier: data.applicationIdentifier })
        .then((response) => {
          message.success(response?.data?.message || 'Colessee removed successfully')
          setColessee(null)
          data = response?.data?.data?.leaseApplication
          dispatch(
            setColesseAfterRemove({
              colessee: null,
              deletedColessees: data.deletedColessees || [],
              colesseeCreditSummary: data?.colesseeCreditSummary,
              creditReports: data?.creditReports,
            })
          );
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history)
          } else {
            message.error('Error while removing co-lessee')
          }
        })
    } catch (e) {
      logger.error('Could not remove co-lessee', e)
    }
    setLoading(false)
  }

  const handleForceExpireApplication = async () => {
    setLoading(true)
    try {
      await PUT(`/api/v1/lease_applications/expire-lease-application`, { application_identifier: data.applicationIdentifier })
        .then(() => {
          message.success('Forced to expire lease application')
          setExpired(true)
           dispatch(setLeaseAppExpiry(true));
          
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history)
          } else {
            message.error('Error while trying to expire lease application')
          }
        })
    } catch (e) {
      logger.error('Could not expire lease application', e)
    }
    setLoading(false)
  }

  const [expireModal, setExpireModal] = useState(false)

  const handleOk = () => {
    setExpireModal(false);
  };
  const handleCancel = () => {
    setExpireModal(false);
  };

  const unexpireApplicationModal = () => {
    setExpireModal(true)
  }

  const handleUnexpireApplication = async () => {
    setLoading(true)
    if (untilExpiryDate !== '' && untilExpiryDate !== undefined && untilExpiryDate !== null) {
      try {

        await PUT(`/api/v1/lease_applications/unexpire-lease-application`, { application_identifier: data.applicationIdentifier, untilExpiryDate:untilExpiryDate })
          .then(() => {
            message.success('Unexpired lease application')
            setExpired(false)
            setExpireModal(false)
            dispatch(setLeaseAppExpiry(false));
          })
          .catch((e) => {
            if (e && e.response && e.response.status === 401) {
              handleNoAuth(history)
            } else {
              message.error('Error while trying to unexpire lease application')
            }
          })
      } catch (e) {
        logger.error('Could not unexpire lease application', e)
      }
    } else {
      message.error('Please select until expiry date')
    }
    setLoading(false)
  }

  const repullCreditReports = async () => {
    setLoading(true)
    try {
      await POST(`/api/v1/lease_applications/repull-credit-reports`, { application_identifier: data.applicationIdentifier })
        .then(() => {
          message.success('Successfully pulled credit reports.')
          setColessee(colessee)
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history)
          } else {
            message.error('Error while pulling credit reports')
          }
        })
    } catch (e) {
      logger.error('Could not pull credit reports', e)
    }
    setLoading(false)
  }

  const handleGenerateFundingApprovalChecklist = async (e) => {
    setLoading(true)
    e.preventDefault()
    try {
      const response = await PUT(`/api/v1/lease_applications/funding-approval-checklist`, { application_identifier: data.applicationIdentifier })
      response.data.match(/^http[s]?:\/\//) ? response.data : 'http://' + response.data
      // window.open(url, '_blank')
      // .then((res) => {
      //   message.success("PDF will be generated shortly");
      // })
      // .catch((e) => {
      //   if (e && e.response && e.response.status === 401) {
      //     handleNoAuth(history);
      //   } else {
      //     message.error('Error processing Blackbox');
      //   }
      // })
    } catch (e) {
      logger.error('Request Error', e)
    }
    setLoading(false)
  }

  const handleResendCreditDecision = () => {
    confirm({
      title: 'This will resend Credit Decision emails to all of the dealerships dealers.',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure?',
      onOk() {
        resendCreditDecision()
      },
    })
  }

  const handleGenerateCreditDecisionNotification = () => {
    confirm({
      title: 'This will resend Credit Decision emails to all of the dealerships dealers.',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure?',
      onOk() {
        generateCreditDecisionNotification()
      },
    })
  }

  const handleRemoveColessee = () => {
    confirm({
      title: 'Removing the co-applicant will send this application back to Awaiting Credit Decision and will destroy the colessee permanently.',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to continue?',
      onOk() {
        removeColessee()
      },
    })
  }

  const exportFundingDelays = async () => {
    let payload_filter = {
      filter: {
        application_identifier_contains: data.applicationIdentifier,
      },
    }

    setLoading(true)
    try {
      await POST(`/api/v1/lease_application_reports/export_funding_delays`, payload_filter)
        .then(() => {
          message.success('Credit Reports Will Be Repulled and Appear as a File Attachment Shortly')
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history)
          } else {
            message.error('Error processing colessee credit')
          }
        })
    } catch (e) {
      logger.error('Error pulling colessee credit', e)
    }
    setLoading(false)
  }

  const handleRepullLesseeCredit = () => {
    confirm({
      title: "This action will pull the lessee's live credit profile.",
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to continue?',
      onOk() {
        repullLesseeCredit()
      },
    })
  }

  const handleRepullColesseeCredit = () => {
    confirm({
      title: "This action will pull the lessee's live credit profile.",
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to continue?',
      onOk() {
        repullColesseeCredit()
      },
    })
  }

  const handleGenerateLeasePackage = () => {
    confirm({
      title: 'This action will generate a lease package.',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure?',
      onOk() {
        generateLeasePackage()
      },
    })
  }

  const handleRerunCreditReports = () => {
    confirm({
      title: "This action will pull both applicant's live profile.",
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure?',
      onOk() {
        repullCreditReports()
      },
    })
  }

  const handleExportFundingDelays = () => {
    confirm({
      title: 'This will send an Access DB export to email.',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure?',
      onOk() {
        exportFundingDelays()
      },
    })
  }

  const renderResendCreditDecisionButton = () => {
    if (data.notifications.creditDecisionEmailsCount > 0) {
      return (
        <Button
          shape="round"
          type="primary"
          key="resendCreditDecision"
          onClick={(e) => {
            handleResendCreditDecision(e)
          }}
          disabled={disableFlag}
        >
          Re-send Credit Decision
        </Button>
      )
    }
  }

  const renderGenerateCreditDecisionButton = () => {
    if (data.actionPermission.canSendCreditDecision) {
      return (
        <Button
          shape="round"
          type="primary"
          key="generateCreditDecision"
          onClick={() => {
            handleGenerateCreditDecisionNotification()
          }}
          disabled={disableFlag}
        >
          Generate Credit Decision Email
        </Button>
      )
    }
  }

  const renderCoLesseeButtons = () => {
    if (colessee !== null) {
      return (
        <>
          <Button
            shape="round"
            type="primary"
            key="removeColessee"
            onClick={(e) => {
              handleRemoveColessee(e)
            }}
            disabled={disableFlag}
          >
            Remove Co-Lessee
          </Button>
          <Button
            shape="round"
            type="primary"
            key="swapApplicants"
            onClick={(e) => {
              handleSwapApplicants(e)
            }}
            disabled={isButtonDisabled}
          >
            Swap Applicants
          </Button>
        </>
      )
    }
  }

  const renderGenerateLeaseDocumentsButton = () => {
    if (data.leaseDocumentRequests.length > 0) {
      return (
        <Button
          shape="round"
          type="primary"
          key="generateLeaseDocuments"
          onClick={() => {
            handleGenerateLeasePackage(data.applicationIdentifier)
          }}
          disabled={disableFlag}
        >
          Generate Lease Documents
        </Button>
      )
    }
  }

  const renderExpiryButton = () => {
    if (expired === false) {
      return (
        <Button
          shape="round"
          type="primary"
          key="forceExpireApplication"
          onClick={(e) => {
            handleForceExpireApplication(e)
          }}
          disabled={disableFlag}
        >
          Force Expire Application
        </Button>
      )
    } else {
      return (
        <Button
          shape="round"
          type="primary"
          key="unexpireApplication"
          onClick={(e) => {
            unexpireApplicationModal(e)
            // handleUnexpireApplication(e)
          }}
          disabled={disableFlag}
        >
          Unexpire Application
        </Button>
      )
    }
  }

  const [untilExpiryDate, setUntilExpiryDate] = useState(undefined)
  const onChangeExpiryDate = (date, dateString) => {
    setUntilExpiryDate(dateString)
  };

  return (<>
    <Space align="center" wrap="true">
      {renderResendCreditDecisionButton()}
      {renderGenerateCreditDecisionButton()}
      {renderCoLesseeButtons()}
      {renderGenerateLeaseDocumentsButton()}
      {renderExpiryButton()}
      {data.expired &&<Button
        shape="round"
        type="primary"
        key="repullLesseeBlackbox"
        onClick={() => {
          handleRepullBlackbox()
        }}
        disabled={disableFlag}
      >
        Re-Pull Blackbox
      </Button>}
      <Button
        shape="round"
        type="primary"
        key="exportFundingDelays"
        onClick={() => {
          handleExportFundingDelays()
        }}
        disabled={disableFlag}
      >
        Export Funding Delays
      </Button>
      <Button shape="round" type="primary" key="manageStipulations" href={`#Stipulations-p`} onClick={() => toggleCollapse('Stipulations') } disabled={disableFlag}>
        Manage Stipulations
      </Button>
      <Button shape="round" type="primary" key="manageFundingDelays" href={`#Funding%20Delays-p`} onClick={() => toggleCollapse('Funding Delays') } disabled={disableFlag}
      >
        Manage Funding Delays
      </Button>
      <Button shape="round" type="primary" key="editCreditTier" href={`${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=/admins/lease_applications/${data.id}/edit_credit_tier`} disabled={disableFlag}>
        Edit Credit Tier
      </Button>
      {data.calculator && data.calculator.calculatorId && (
        <Button shape="round" type="primary"
        onClick={() => { history.push(`/admins/lease_calculators/${data.calculator.calculatorId}/edit`) }}
        //  href={`${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=/admins/lease_calculators/${data.calculator.calculatorId}/edit`} 
         disabled={disableFlag}>
          Payment Calculator
        </Button>
      )}
      <Button shape="round" type="primary" key="overrideCalculator" href={`${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=/admins/lease_calculators/${data.calculator.calculatorId}/override`} disabled={disableFlag}>
        Override Calculator
      </Button>
      <Button shape="round" type="primary" href={`/online-funding-approval-checklist/${data.id}`} id="funding-approval-checklist" disabled={disableFlag}>
        Online Funding Approval Checklist
      </Button>
      <Button
        shape="round"
        type="primary"
        key="generateFundingApprovalChecklist"
        onClick={(e) => {
          handleGenerateFundingApprovalChecklist(e)
        }}
        disabled={disableFlag}
      >
        Generate Funding Approval Checklist
      </Button>
      {/* {data.expired &&<Button
        shape="round"
        type="primary"
        key="repullLesseeCredit"
        onClick={(e) => {
          handleRepullLesseeCredit()
        }}
        disabled={disableFlag}
      >
        Re-Pull Lessee Credit
      </Button>} */}
      {/* {data.expired &&<Button
        shape="round"
        type="primary"
        key="repullCoLesseeCredit"
        onClick={(e) => {
          handleRepullColesseeCredit()
        }}
        disabled={disableFlag}
      >
        Re-Pull Colessee Credit
      </Button>} */}
      {/* {data.expired &&<Button
        shape="round"
        type="primary"
        key="repullCreditReports"
        onClick={(e) => {
          handleRerunCreditReports()
        }}
        disabled={disableFlag}
      >
        Re-Pull Credit Report
      </Button>} */}
    </Space>

    <Modal title="Select date of expiry until date" visible={expireModal} onOk={handleOk} onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleUnexpireApplication}>
            Unexpire Application
          </Button>
        ]}
    >
      <DatePicker defaultValue={untilExpiryDate} onChange={onChangeExpiryDate} format="MM-DD-YYYY" style={{ width: '100%' }} />
    </Modal>

    <Modal
      title="Document Issue Validations Errors. Please try again!"
      visible={modalOpen}
      centered
      onCancel={handleCloseModal}
      footer={[
        <Button key="Cancel" onClick={handleCloseModal}>
          OK
        </Button>
      ]}
    >
      {
        docIssuedPassed.length > 0 && (docIssuedPassed.map((passedMsg, i) => (
          <div key={i} style={{ display: "flex", lineHeight: "20px", FontSize: "14px", verticalAlign: "middle" }}>
            <DoneIcon style={{ color: "#388e3c" }} />
            <div key={i} dangerouslySetInnerHTML={createMarkup(passedMsg)} />
          </div>
        )))
      }
      {
        docIssuedErrors.length > 0 && (docIssuedErrors.map((error, i) => (
          <div key={i} style={{ display: "flex", lineHeight: "20px", FontSize: "14px", verticalAlign: "middle" }}>
            <ClearIcon style={{ color: "#f44336" }} />
            <div key={i} dangerouslySetInnerHTML={createMarkup(error)} />
          </div>
        )))
      }
    </Modal>

    </>
  )
}
