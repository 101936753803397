import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const auth_token = window.localStorage.getItem("auth_token");
const admin_name = window.localStorage.getItem("user_name");

export default function DealershipActionButton(props) {
  const history = useHistory();
  const { params } = props;
  const { signButtonVisibility } = props
  const [open, setOpen] = useState(false);
  const [dealershipFlag, setDealershipFlag] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleRedirectDealerPortal = () => {
    setOpen(false);
    const fullName = params.row.name;
    const dealershipState = params.row.stateAbbrev;
    const zipCode = params.row.zipcode;
    const dealerPortalURL = process.env.REACT_APP_DEALER_LOGIN_URL || 'https://dealer2.speedleasing.com'

    window.open(
      `${dealerPortalURL}/login?dealership_id=${params.row.id}&admin_token=${auth_token}&full_name=${admin_name.concat(" (Admin)")}&dealership_name=${fullName}&dealership_state=${dealershipState}&zipcode=${zipCode}`,
      "_blank"
    );
  }

  return (
    <>
      <Button
        color="secondary"
        sx={{ fontSize: "10px", margin: 1, padding: "5px 6px" }}
        variant="outlined"
        onClick={(event) => {
          history.push("/dealership/" + params.row.id + "/activities");
        }}
      >
        {" "}
        Dealer Activity
      </Button>
      {signButtonVisibility === true ? (
        <>
          <Button
            color="success"
            sx={{ fontSize: "10px", margin: 1, padding: "5px 6px" }}
            variant="contained"
            // onClick={(event) => {
            //   window.open(
            //     `${process.env.REACT_APP_DEALER_LOGIN_URL}/login?dealership=true`,
            //     "_blank"
            //   );
            // }}
            onClick={handleClickOpen}
          >
            {" "}
            Sign In
          </Button>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Dealer Portal Login Confirmation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`Are you sure to Login to ${params?.row?.name}?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRedirectDealerPortal} autoFocus>
                Yes
              </Button>
              <Button onClick={handleClose}>No</Button>
            </DialogActions>
          </Dialog>
        </>
      ) : null}
    </>
  );
}
