import React from "react";
import { Row, Col, Input, Form } from 'antd';

const { TextArea } = Input;

export default function InputTextArea({label, ruleMessage, name, required, validateStatus, help, onPressEnter, onBlur, rows, type}) {
  return (
    <Row style={{ display: type === "hidden" ? "none" : "block" }}>
      <Col span={24}> 
        <Form.Item 
          label={label} 
          hasFeedback
          name={name}
          rules={[{ required: required, message: ruleMessage }]}
          validateStatus={validateStatus}
          help={help}
        >  
          <TextArea rows={rows} placeholder={label} onPressEnter={onPressEnter} onBlur={onBlur} type={type}/>
        </Form.Item>
      </Col>
    </Row> 
  );
}