import React from 'react';
import ApprovalEvents from '../components/ApprovalEvents';

export default function UnderwritingApproval({ dealership, dealershipId, getDealership, ...props }) {
  const salesApproval = dealership.salesApproval.approved;
  const underwritingApprovalBtn = dealership.underwritingApproval.approved;
  const { allowedUwApprovalGet, allowedUwApprovalUpdate } = props;

  return dealership ? (
    <ApprovalEvents 
      allowedApprovalGet={allowedUwApprovalGet}
      allowedApprovalUpdate={allowedUwApprovalUpdate}
      approvalType='underwriting' 
      disabled={!salesApproval} 
      isApproved={underwritingApprovalBtn} 
      type="Underwriting" 
      dealershipId={dealershipId} 
      getDealership={getDealership} 
      dataSource={dealership.underwritingApproval.events}
      status ={dealership.status}
      salesApproval ={salesApproval}
    />
  ) : null;
}