import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Checkbox, Row, Col, Table, Card, InputNumber, Select, Form, message } from 'antd';
import { GET, PUT } from '../../../util/network';
import logger from '../../../util/logger';
import './FinanceSettings.css';

const Option = Select.Option;

export default function FinanceSettings({ handleNoAuth, makeList }) {
    const history = useHistory();
    const [form] = Form.useForm();
    const [applicationSettings, setApplicationSettings] = useState([])
    const [currentSettings, setCurrentSettings] = useState(undefined)
    const [disableForm, setDisableForm] = useState(true)

    const layout = {
        labelCol: {
          span: 24,
        },
        wrapperCol: {
          span: 24,
        },
      };

    useEffect(() => {
      getApplicationSettings()
    }, []);

    const getApplicationSettings = async () => {
      try {
        let response = await GET(`/api/v1/application-settings`);
        setApplicationSettings(response.data.application_settings)
      } catch (e) {
        logger.error("Error while getting Application Settings",e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error getting Application Settings');
        }
      }
    }

    const updateApplicationSettings = async (id, payload) => {
      if (currentSettings){
        try {
          await PUT(`/api/v1/application-settings/${id}`, payload);
          getApplicationSettings()
          message.success("Successfully Saved!");
        } catch (e) {
          logger.error("Error while saving Settings",e);

          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error('Error saving settings');
          }          
        }
      }
    }

    const getFormValues = (appSetting) => {
        form.setFieldsValue({
          low_model_year: appSetting.low_model_year,
          high_model_year: appSetting.high_model_year,
          global_security_deposit: appSetting.global_security_deposit,
          base_servicing_fee: appSetting.base_servicing_fee,
          acquisition_fee: appSetting.acquisition_fee,
          dealer_participation_sharing_percentage_24: appSetting.dealer_participation_sharing_percentage_24,
          dealer_participation_sharing_percentage_36: appSetting.dealer_participation_sharing_percentage_36,
          dealer_participation_sharing_percentage_48: appSetting.dealer_participation_sharing_percentage_48,
          dealer_participation_sharing_percentage_60: appSetting.dealer_participation_sharing_percentage_60,
          residual_reduction_percentage_24: appSetting.residual_reduction_percentage_24,          
          residual_reduction_percentage_36: appSetting.residual_reduction_percentage_36,          
          residual_reduction_percentage_48: appSetting.residual_reduction_percentage_48,          
          residual_reduction_percentage_60: appSetting.residual_reduction_percentage_60,          
        });
    }

    const handleOnBlur = (e) => {
      if (currentSettings && (currentSettings[e.target.id] != e.target.value.replace(/\$\s?|(,*)/g, ''))){
          updateApplicationSettings(currentSettings.id, { application_setting: {[e.target.id]: e.target.value.replace(/\$\s?|(,*)/g, '') }})
      }
    }

    const handleOnBlurInTable = (e) => {
      if (currentSettings && (currentSettings[e.target.id] != e.target.value.replace('%', ''))){
        updateApplicationSettings(currentSettings.id, { application_setting: {[e.target.id]: e.target.value.replace('%', '')}})
      }
    }

    const onCheckboxChange = (e) => {
      if (currentSettings && (currentSettings[e.target.id] != e.target.checked)){
        setCurrentSettings({...currentSettings, enable_global_security_deposit: e.target.checked})
        updateApplicationSettings(currentSettings.id, { application_setting: {[e.target.id]: e.target.checked }})
      }
    }

    const columns = [
      {
        title: 'Parameter',
        dataIndex: 'parameter',
        key: 'parameter'
      },
      {
        title: '24 Months',
        dataIndex: 'months24',
        key: 'months24',
        render(val, row) {
          return (
            <Form.Item name={`${row.formMonths24}`} onBlur={handleOnBlurInTable}>
                <InputNumber formatter={value => `${value}%`} precision={2} parser={value => value.replace('%', '')} disabled={disableForm || (row.key == "1")}/>
            </Form.Item>
          )
        }
      },
      {
        title: '36 Months',
        dataIndex: 'months36',
        key: 'months36',
        render(val, row) {
          return (
            <Form.Item name={`${row.formMonths36}`} onBlur={handleOnBlurInTable}>
                <InputNumber formatter={value => `${value}%`} precision={2} parser={value => value.replace('%', '')} disabled={disableForm || (row.key == "1")} />
            </Form.Item>
          )
        }
      },
      {
        title: '48 Months',
        dataIndex: 'months48',
        key: 'months48',
        render(val, row) {
          return (
            <Form.Item name={`${row.formMonths48}`} onBlur={handleOnBlurInTable}>
                <InputNumber formatter={value => `${value}%`} precision={2} parser={value => value.replace('%', '')} disabled={disableForm || (row.key == "1")} />
            </Form.Item>
          )
        }
      },
      {
        title: '60 Months',
        dataIndex: 'months60',
        key: 'months60',
        render(val, row) {
          return (
            <Form.Item name={`${row.formMonths60}`} onBlur = {handleOnBlurInTable}>
                <InputNumber formatter={value => `${value}%`} precision={2} parser={value => value.replace('%', '')} disabled={disableForm || (row.key == "1")} />
            </Form.Item>
          )
        }
      },
    ];
    
    const data = [
      {
        key: '1',
        parameter: 'Dealer Participation Sharing Percentage',
        formMonths24: 'dealer_participation_sharing_percentage_24',
        formMonths36: 'dealer_participation_sharing_percentage_36',
        formMonths48: 'dealer_participation_sharing_percentage_48',
        formMonths60: 'dealer_participation_sharing_percentage_60',
      },
      {
        key: '2',
        parameter: 'Residual Reduction Percentage',
        formMonths24: 'residual_reduction_percentage_24',
        formMonths36: 'residual_reduction_percentage_36',
        formMonths48: 'residual_reduction_percentage_48',
        formMonths60: 'residual_reduction_percentage_60',
      },
    ];

    const formItemSyle = {
      style: {
        marginBottom: 12
      }
    }

    return (
      <Card id='finance-settings-card' title='Finance Settings'>
        <div className='lease-calculator'>
          <Form form = {form} {...layout} colon={false} className="make-form" >
            <Row gutter={[8, 0]} className='flex-jc-fs'>
              <Col xs={24} sm={24} md={24} lg={6} xl={6} >
                <Form.Item {...formItemSyle}>
                  <Select 
                    placeholder='Select a make' 
                    onSelect={(make_id) => { 
                      let appSetting = applicationSettings.find(x => x.make_id === parseInt(make_id))
                      setCurrentSettings(appSetting); 
                      getFormValues(appSetting)
                      setDisableForm(false)
                      }
                    } 
                  >
                    {makeList && makeList.map((make) => {
                      return (
                        <Option key = {Math.random()} value={make.id}>{make.name}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 0]} className='flex-jc-fs'>
              <Col xs={24} sm={24} md={24} lg={6} xl={6} >
                <Form.Item label="Low Model Year" {...formItemSyle} name="low_model_year" onBlur={handleOnBlur}>
                  <InputNumber disabled={disableForm} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={6} >
                <Form.Item label="High Model Year" {...formItemSyle} name="high_model_year" onBlur={handleOnBlur}>
                  <InputNumber disabled={disableForm} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={6} >
                <Form.Item label="Base Servicing Fee" {...formItemSyle} name="base_servicing_fee" onBlur={handleOnBlur}>
                  <InputNumber precision={2} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} disabled={disableForm} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={6} >
                <Form.Item label="Global Security Deposit" {...formItemSyle}>
                  <Row gutter={[8, 0]}>
                    <Col xs={2} sm={2} md={2} lg={4} xl={2}>
                        <Form.Item name="enable_global_security_deposit">
                          <Checkbox checked={currentSettings && currentSettings.enable_global_security_deposit} onChange={onCheckboxChange}></Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={22} sm={22} md={22} lg={20} xl={22}>
                      <Form.Item name="global_security_deposit" onBlur={handleOnBlur}>
                        <InputNumber precision={2} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} disabled={disableForm} />
                      </Form.Item>
                    </Col>
                  </Row>   
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 8]} className='flex-jc-fs'>
                <Col span={24}>
                  <Table columns={columns} dataSource={data} pagination={false} size="small" />
                </Col>
            </Row>

            <Row gutter={[8,0]} className='flex-jc-fs'>
              <Col xs={24} sm={24} md={24} lg={6} xl={6} >
                <Form.Item label="Acquisition Fee" {...formItemSyle} name="acquisition_fee" onBlur={handleOnBlur}>
                  <InputNumber precision={2} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} disabled={disableForm} />
                </Form.Item>
              </Col>
            </Row>
          </Form>            
        </div>
      </Card>
    )
}