import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import { GET, POST, PUT } from '../../util/network'
import ModelYearsList from './ModelYearsList'
import { fetchActiveModelYears } from '../../reducers/ModelYears'
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom";

export default function ModelYearsListRenderer(props) {
  const { handleNoAuth } = props;
  const history = useHistory();
  let auth_token = window.localStorage.getItem('auth_token');

  const [loading, setLoading] = useState(false)
  const [recalculateModal, setRecalculateModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [makeOptions, setMakeOptions] = useState([]);
  const dispatch = useDispatch();
  const modelYearsData = useSelector((state) => state.modelYears.modelYearList);
  const modelYears = useSelector((state) => state.modelYears);
  const pageState = useSelector((state) => state.modelYears.pageState);
  const filterFlag = useSelector((state) => state.modelYears.filterFlag);
  const filters = useSelector((state) => state.modelYears.filters);
  const errorMessage = useSelector((state) => state.modelYears.errorMessage);
  let isLoading = modelYears.isLoading;

  const losPath = (path) => {
    return `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=${path}`
  }

  const handleRecalculateModalOpen = () => {
    setRecalculateModal(true)
  }
  const handleRecalculateModalClose = () => {
    setRecalculateModal(false)
  }

  const handleExportModalOpen = () => {
    setExportModal(true)
  }
  const handleExportModalClose = () => {
    setExportModal(false)
  }

  const recalculateResiduals = async () => {
    handleRecalculateModalClose()
    setLoading(true)
    try {
      const response = await PUT(`/api/v1/model_years/recalculate-residuals`);
      message.success(response.data.message)
    } catch (e) {
      message.error("Error while recalculating residuals")
    }
    setLoading(false)
  }

  const exportData = (data, fileName, type) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const exportModelYearsList = async () => {
    handleExportModalClose()
    try {
      message.success("Model Years CSV will be sent to email and file will be downloaded in a minutes")
      const {page, limit, ...newPageState} = pageState;
      let response = await POST(`/api/v1/model_years/export-model-years`, {
        pageState: pageState,
        filterFlag: filterFlag,
        filters: filters
      })
      exportData(response.data, 'model_years_export.csv', 'text/csv;charset=utf-8;');
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history)
      } else {
        message.error('Error while Exporting lease application')
      }
    }
  }

  const getMakeOptions = async () => {
    try {
      const response = await GET(`/api/v1/makes/list?filter_option=${true}`)
      setMakeOptions(response?.data);
    } catch (e) {
       message.error("Error while loading make options.");
    }
  }

  useEffect(() => {
    getMakeOptions()
  }, []);

  useEffect(() => {
    dispatch(
      fetchActiveModelYears({
        pageState: pageState,
        filterFlag: filterFlag,
        filters: filters,
      })
    );
    // if (errorMessage) {
    //   handleNoAuth(history);
    // }
  }, [pageState, filters, errorMessage]);

  return (
    <Spin spinning={isLoading}>
      <ModelYearsList
        data={modelYearsData?.modelYears}
        loading={loading}
        pageState={pageState}
        total={modelYearsData?.total}
        type={'list'}
        recalculateResiduals={recalculateResiduals}
        recalculateModal={recalculateModal}
        handleRecalculateModalOpen={handleRecalculateModalOpen}
        handleRecalculateModalClose={handleRecalculateModalClose}
        exportModelYearsList={exportModelYearsList}
        exportModal={exportModal}
        handleExportModalOpen={handleExportModalOpen}
        handleExportModalClose={handleExportModalClose}
        modelGroups={modelYearsData?.modelGroups}
        makes={makeOptions}
      />
    </Spin>
  )
}
