import React from "react";
import { Table, Layout} from "antd";
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import MainHeader from '../../components/layouts/MainHeader';

const { Content } = Layout;
export default function InventoryNadaHistory({data, vehicleId}) {
    const columns = [
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
            sorter: (a, b) => ('' + a.year).localeCompare(b.year)
        },
        {
            title: 'Make',
            dataIndex: 'make',
            key: 'make',
            sorter: (a, b) => ('' + a.make).localeCompare(b.make)
        },
        {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            sorter: (a, b) => ('' + a.model).localeCompare(b.model)
        },
    ];


    const dataSource = data ? data.modelYears : []

    return (
      <>
        <Layout className="layout">
          <MainHeader activePage="inventory"/>
          <Layout style={{ padding: '0 24px 24px' }}>
              <MainBreadcrumb items={
                    [{ text: "Home", link_type: "linkto", link: "/dashboard" },
                    { text: "Inventory", link_type: "linkto", link: "/inventory" },
                    { text: `${vehicleId}`, link_type: "linkto", link: `/inventory/${vehicleId}` },
                    { text: 'Nada Value History', link_type: "ahref", link: void(0) }]
                  }
                  />
  
              <Content
                id='main-content'
                style={{
                background: '#fff',
                padding: 24
                }}
              >
                <Table 
                  rowKey="id" 
                  dataSource={dataSource} 
                  columns={columns} 
                />
            </Content>
          </Layout>
        </Layout>
      </>
  );
}