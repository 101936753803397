import React from "react";
import Box from '@mui/material/Box';
import {Input} from 'antd';
import {Button, Col, Form, Row, Drawer, Divider} from 'antd';

export default function DocumentRequestFilter(props) {
  const {Search} = Input;
  const {setPageState, filter, setFilter, state, setState, clearFilter} = props;
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setFilter(open)
  };

  const DocumentRequestFilter = () => {
    if (state.filter === true) {
      setPageState({...state})
    }
  }

  const allFilters = () => (

    <Form layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            className="lease-doc-filter"
            label="Lease Application Identifier"
          >
            <Search
              style={{padding: '2px', width: "300px"}}
              placeholder="Lease Application Identifier"
              size="large"
              allowClear
              className="leaseAppListSearch"
              onChange={(e) => {
                setState({...state, lease_app_id: e.target.value, filter: true})
              }}
              enterButton
              value={state.lease_app_id}
            />
          </Form.Item>
        </Col>

        <Divider
          type="horizontal"
          style={{borderColor: "#d0d0cf", margin: '2px', padding: '2px'}}
        />

        <Col span={24}>
          <Form.Item
            label="Asset Make"
            className="lease-doc-filter"
          >
            <Search
              style={{padding: '2px', width: "300px"}}
              placeholder="Asset Make"
              size="large"
              allowClear
              className="leaseAppListSearch"
              onChange={(e) => {
                setState({...state, asset_make: e.target.value, filter: true})
              }}
              enterButton
              value={state.asset_make}
            />
          </Form.Item>
        </Col>

        <Divider
          type="horizontal"
          style={{borderColor: "#d0d0cf", margin: '2px', padding: '2px'}}
        />

        <Col span={24}>
          <Form.Item
            label="Asset Model"
            className="lease-doc-filter"
          >
            <Search
              style={{padding: '2px', width: "300px"}}
              placeholder="Asset Model"
              size="large"
              allowClear
              className="leaseAppListSearch"
              onChange={(e) => {
                setState({...state, asset_model: e.target.value, filter: true})
              }}
              enterButton
              value={state.asset_model}
            />
          </Form.Item>
        </Col>

        <Divider
          type="horizontal"
          style={{borderColor: "#d0d0cf", margin: '2px', padding: '2px'}}
        />

        <Col span={24}>
          <Form.Item
            label="Asset Year"
            className="lease-doc-filter"
          >
            <Search
              style={{padding: '2px', width: "300px"}}
              placeholder="Asset Year"
              size="large"
              allowClear
              className="leaseAppListSearch"
              onChange={(e) => {
                setState({...state, asset_year: e.target.value, filter: true})
              }}
              enterButton
              value={state.asset_year}
            />
          </Form.Item>
        </Col>

        <Divider
          type="horizontal"
          style={{borderColor: "#d0d0cf", margin: '2px', padding: '2px'}}
        />

        <Col span={24}>
          <Form.Item
            label="Asset VIN"
            className="lease-doc-filter"
          >
            <Search
              style={{padding: '2px', width: "300px"}}
              placeholder="Asset VIN"
              size="large"
              allowClear
              className="leaseAppListSearch"
              onChange={(e) => {
                setState({...state, asset_vin: e.target.value, filter: true})
              }}
              enterButton
              value={state.asset_vin}
            />
          </Form.Item>
        </Col>

        <Divider
          type="horizontal"
          style={{borderColor: "#d0d0cf", margin: '2px', padding: '2px'}}
        />

        <Col span={24}>
          <Form.Item
            label="Asset Color"
            className="lease-doc-filter"
          >
            <Search
              style={{padding: '2px', width: "300px"}}
              placeholder="Asset Color"
              size="large"
              allowClear
              className="leaseAppListSearch"
              onChange={(e) => {
                setState({...state, asset_color: e.target.value, filter: true})
              }}
              enterButton
              value={state.asset_color}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row align="middle" gutter={16}>
        <Col span={25}>
          <Form.Item
          >
            <Button
              style={{
                textAlign: "center",
                backgroundColor: "#1976d2",
                color: "#fff",
                fontSize: 16,
                fontWeight: "bold",
                borderRadius: "5px",
                marginRight: "5px",
                marginLeft: "20px",
                width: "100px"
              }}
              onClick={() => DocumentRequestFilter()}
            >
              Filter
            </Button>
          </Form.Item>
        </Col>
        <Col span={25}>
          <Form.Item
          >
            <Button
              style={{
                textAlign: "center",
                backgroundColor: "#fff",
                color: "#000",
                fontSize: 16,
                fontWeight: "bold",
                borderRadius: '5px',
                border: "1px solid #000",
                width: "100px"
              }}
              onClick={() => clearFilter()}
            >
              Clear
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Box sx={{flexGrow: 1, pb: 2}}>
      <Drawer
        title="Filter"
        placement="right"
        getContainer={false}
        open={filter}
        onClose={toggleDrawer(false)}
        visible={filter}
      >
        {allFilters()}
      </Drawer>
    </Box>
  )
}
