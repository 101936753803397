import React, {useEffect, useState} from "react";
import {Button, Card, Checkbox, Col, Input, Form, Row, message, Select} from "antd";
import {GET, POST} from "../../../util/network";
import logger from "../../../util/logger";
import {useHistory} from "react-router-dom";
import {handleNoAuth} from "../../../util/authenticate";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function ContactCreateForm({dealershipOptions, setLoading}) {
  const [contactCreateForm] = Form.useForm();
  const {Option} = Select;
  const history = useHistory();

  const handleSubmit = async (values) => {
    values = {...values};
    setLoading(true)
    try {
      await POST(`/api/v1/contacts`, values);
      message.success("Contact created successfully.");
      history.push(`/contacts`);
    } catch (e) {
      logger.error("Save Funding Delay Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while saving your data');
      }
    }
    setLoading(false)
  }

  return (
    <>
      <Form
        form={contactCreateForm}
        {...layout}
        colon={false}
        onFinish={handleSubmit}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card title="Contact Info">
              <Row gutter={[24, 16]}>
                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    hasFeedback
                    rules={[{required: true, message: "First name is required!"}]}
                  >
                    <Input/>
                  </Form.Item>

                  <Form.Item
                    label="Direct Email"
                    name="direct_email"
                    hasFeedback
                    rules={[
                      {required: true, message: "Direct Email is required!"},
                      {required: false, type: "email", message: "The input is not valid E-mail!"}]}
                  >
                    <Input/>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    hasFeedback
                    rules={[{required: true, message: "Last name is required!"}]}
                  >
                    <Input/>
                  </Form.Item>

                  <Form.Item
                    label="Direct Phone Number"
                    name="direct_phone_number"
                    hasFeedback
                    rules={[{required: true, message: "Direct Phone number is required!"}]}
                  >
                    <Input/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 16]}>
                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Position"
                    name="position"
                    hasFeedback
                    rules={[{required: true, message: "Position is required!"}]}
                  >
                    <Input/>
                  </Form.Item>

                  <Form.Item
                    name="is_dealer_user"
                    valuePropName="checked"
                  >
                    <Checkbox>Is Dealer User</Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Dealership"
                    name="dealership_id"
                    hasFeedback
                    rules={[{required: true, message: "Dealership is required!"}]}
                  >
                    <Select
                      showSearch
                      filterOption={(input, option) =>
                        option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                      }
                    >
                      {
                        (dealershipOptions || []).map(({value, label}, index) => {
                          return <Option key={index} value={value}>{label}</Option>
                        })
                      }
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="is_owner"
                    valuePropName="checked"
                  >
                    <Checkbox>Is Owner</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} className="mt-20">
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <Row>
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Create Contact
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}
