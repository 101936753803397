import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Spin, Layout, Menu, Row, Col, Collapse, Table, message } from "antd";
import {
  BulbOutlined,
  FallOutlined,
  RollbackOutlined,
  ExclamationCircleOutlined,
  AlertOutlined,
} from "@ant-design/icons";
import Button from "@mui/material/Button";

import { GET } from "../../../util/network";
import logger from "../../../util/logger";

import MainHeader from "../../../components/layouts/MainHeader";
import MainBreadcrumb from "../../../components/layouts/MainBreadcrumb";
import MainFooter from "../../../components/layouts/MainFooter";
import { SiderProps } from "../../../components/layouts/MainSiderProps";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const { Content, Sider } = Layout;
const { Panel } = Collapse;

export default function CreditReportDetails(props) {
  const { appNumber, id } = props.match.params;
  const { handleNoAuth } = props;
  const history = useHistory();

  let auth_token = window.localStorage.getItem("auth_token");

  const [activeMenu, setActiveMenu] = useState([1]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [errorsData, setErrorsData] = useState();
  const [xpnErrors, setXpnErrors] = useState();
  const [tuErrors, setTuErrors] = useState();
  const [efxErrors, setEfxErrors] = useState();
  const [tuFrozen, setTuFrozen] = useState(false);
  const [isXpnReportAvailable, setIsXpnReportAvailable] = useState(false);
  const [isEfxReportAvailable, setIsEfxReportAvailable] = useState(false);
  const [xpnPullModal, setXpnPullModal] = useState(false);
  const [efxPullModal, setEfxPullModal] = useState(false);

  const actionColumn = {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render(val) {
      return val ? (
        <a
          href={`${
            process.env.REACT_APP_BASE_URL
          }/auth/${auth_token}?path=${encodeURIComponent(val)}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download
        </a>
      ) : null;
    },
  };

  const cbcCols = [
    {
      title: "MLA Status",
      dataIndex: "mla_type",
      key: "mla_type",
      render(val) {
        return val
          ? val.map((item) => {
              return (
                <>
                  {item.source} = {item.status ? item.status : ""} <br />
                </>
              );
            })
          : null;
      },
    },
    {
      title: "NoHit Status",
      dataIndex: "nohit_status",
      key: "nohit_status",
      render(val) {
        return val
          ? val.map((item) => {
              return (
                <>
                  {item.source} = {item.status ? "True" : "False"} <br />
                </>
              );
            })
          : null;
      },
    },
    {
      title: "OFAC Status",
      dataIndex: "ofac_type",
      key: "ofac_type",
      render(val) {
        return val
          ? val.map((item) => {
              return (
                <>
                  {item.source} = {item.status ? item.status : " "} <br />
                </>
              );
            })
          : null;
      },
    },
    {
      title: "CBC Reports",
      dataIndex: "credit_report_html",
      key: "credit_report_html",
      render(val) {
        return val
          ? val.map((item) => {
              return (
                <>
                  <Link
                    to={`/lease-applications/${appNumber}/credit-report-document/${item.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={item.id}
                  >
                    {"   "}
                    {item?.type}
                  </Link>
                  <br />
                </>
              );
            })
          : null;
      },
    },
  ];

  const summaryColumns = [
    { title: "Lessee", dataIndex: "lessee", key: "lessee" },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Average Score",
      dataIndex: "average_score",
      key: "average_score",
    },
    {
      title: "Highest Score",
      dataIndex: "highest_score",
      key: "highest_score",
    },
    {
      title: "Request Control",
      dataIndex: "request_control",
      key: "request_control",
    },
    { title: "Source", dataIndex: "api_name", key: "api_name" },
    { title: "Requested At", dataIndex: "requested_at", key: "requested_at" },
    { title: "Updated At", dataIndex: "updated_at", key: "updated_at" },
   
  ];

  const alertsColumns = [
    { title: "Source", dataIndex: "source", key: "source" },
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Message", dataIndex: "message", key: "message" },
  ];

  const bankruptciesColumns = [
    { title: "Date Filed", dataIndex: "date_filed", key: "date_filed" },
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Status Date", dataIndex: "date_status", key: "date_status" },
  ];

  const apiSource = { title: "CBC Source", dataIndex: "source", key: "source" }

  const repossessionsColumns = [
    { title: "Date", dataIndex: "date_filed", key: "date_filed" },
    { title: "Creditor", dataIndex: "creditor", key: "creditor" },
    { title: "Notes", dataIndex: "notes", key: "notes" },
  ];

  const errorsColumns = [
    { title: "CBC Source", dataIndex: "source", key: "source" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Error", dataIndex: "error", key: "error" },
  ];

  const getCreditReportDetails = async () => {
    setLoading(true);
    try {
      const response = await GET(`/api/v1/credit-reports/${id}/details`);
      if(response) {
        setData(response.data);

        setTuFrozen(response?.data?.credit_alerts.some(element => {
          return element.type === 'Freezed' && element.source === 'TU';
        }))
        setIsXpnReportAvailable(response?.data?.credit_report_html.some(element => {
          return element.type === 'XPN';
        }))
        setIsEfxReportAvailable(response?.data?.credit_report_html.some(element => {
          return element.type === 'EFX';
        }))
        processRecordErrors(response.data);
      }
    } catch (e) {
      logger.error("Error while fetching Credit Report", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error("Error while fetching credit report");
      }
    }
    setLoading(false);
  };

  const getCreditReportDetailsManualpull = async (source) => {
    setLoading(true);
    try {
      const response = await GET(
        `/api/v1/credit-reports/${id}/pull_by_source?source=${source}`
      );
      setData(response.data);
      setTuFrozen(response?.data?.credit_alerts.some(element => {
        return element.type === 'Freezed' && element.source === 'TU';
      }))
      setIsXpnReportAvailable(response?.data?.credit_report_html.some(element => {
        return element.type === 'XPN';
      }))
      setIsEfxReportAvailable(response?.data?.credit_report_html.some(element => {
        return element.type === 'EFX';
      }))
      processRecordErrors(response.data);
      message.success(`Credit Reports Repulled Successfully for ${source}`);
    } catch (e) {
      logger.error("Error while fetching Credit Report", e);
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error(`Credit Reports Repull failed for ${source}`);
      }
    }
    setLoading(false);
  };

  const processRecordErrors = (creditReportData) => {
    if (creditReportData.api_name === "CBC") {
      setErrorsData(
        creditReportData?.record_errors?.map((item, index) => {
          return {
            id: index,
            status: "Failure",
            error: item.message,
            source: item.source,
          };
        })
      );
      setXpnErrors(creditReportData?.record_errors?.filter((d) => d?.source === "XPN"));
      setTuErrors(creditReportData?.record_errors?.filter((d) => d?.source === "TU"));
      setEfxErrors(creditReportData?.record_errors?.filter((d) => d?.source === "EFX"));
    } else {
      setErrorsData(
        creditReportData?.record_errors?.map((item,) => {
          return item
        })
      );
    }
  };

  const handleCloseXpnModal = () => {
    setXpnPullModal(false);
  };

  const handleCloseEfxModal = () => {
    setEfxPullModal(false);
  };

  useEffect(() => {
    getCreditReportDetails();
  }, []);

  return data ? (
    <Spin spinning={loading}>
      <Layout>
        <MainHeader activePage={null} />
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={[1]}
              selectedKeys={[activeMenu.toString()]}
              style={{ width: "100%" }}
              onSelect={({ key }) => {
                if (!key.includes("sub")) {
                  setActiveMenu([...activeMenu, key]);
                }
              }}
            >
              <Menu.Item key="1">
                <BulbOutlined />
                Summary
              </Menu.Item>
              {data.api_name === "CBC" && (
                <Menu.Item key="2">
                  <AlertOutlined />
                  Alerts
                </Menu.Item>
              )}
              <Menu.Item key="3">
                <FallOutlined />
                Bankruptcies
              </Menu.Item>
              <Menu.Item key="4">
                <RollbackOutlined />
                Repossessions
              </Menu.Item>
              <Menu.Item key="5">
                <ExclamationCircleOutlined />
                Errors
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                { text: "Home", link_type: "linkto", link: "/dashboard" },
                {
                  text: "Lease Applications",
                  link_type: "linkto",
                  link: "/lease-applications-list",
                },
                {
                  text: `${data?.applicationIdentifier || appNumber}`,
                  link_type: "linkto",
                  link: `/lease-applications/${appNumber}`,
                },
                { text: "Credit Report", link_type: "ahref", link: void 0 },
              ]}
            />
            <Content id="main-content">
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <Collapse
                    accordion={false}
                    activeKey={activeMenu}
                    onChange={(key) => {
                      key !== undefined && setActiveMenu(key);
                    }}
                  >
                    <Panel header="Summary" key="1" className="slc-panel">
                      <Table
                        rowKey="id"
                        columns={
                          data.api_name !== "CBC"
                            ? [...summaryColumns, actionColumn]
                            : [...summaryColumns, ...cbcCols]
                        }
                        dataSource={[data]}
                        pagination={false}
                        size="small"
                        className="slc-table"
                      />
                    </Panel>
                    {data.api_name === "CBC" && (
                      <Panel header="Alerts" key="2" className="slc-panel">
                        <Table
                          rowKey="id"
                          columns={alertsColumns}
                          dataSource={data.credit_alerts}
                          pagination={false}
                          size="small"
                          className="slc-table"
                        />
                      </Panel>
                    )}
                    <Panel header="Bankruptcies" key="3" className="slc-panel">
                      <Table
                        rowKey="id"
                        columns={data.api_name !== "CBC" ? bankruptciesColumns : [apiSource, ...bankruptciesColumns]}
                        dataSource={data.bankruptcies || []}
                        pagination={false}
                        size="small"
                        className="slc-table"
                      />
                    </Panel>
                    <Panel header="Repossessions" key="4" className="slc-panel">
                      <Table
                        rowKey="id"
                        columns={data.api_name !== "CBC" ? repossessionsColumns : [apiSource, ...repossessionsColumns]}
                        dataSource={data.repossessions || []}
                        pagination={false}
                        size="small"
                        className="slc-table"
                      />
                    </Panel>
                    <Panel header="Errors" key="5" className="slc-panel">
                      {data.api_name === 'CBC' ? (<Table
                        rowKey="id"
                        columns={errorsColumns}
                        dataSource={errorsData || []}
                        pagination={false}
                        size="small"
                        className="slc-table"
                      />) : errorsData}
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
              <Row className="flex-jc-fs">
                {((tuErrors && tuErrors.length > 0) || tuFrozen) && (
                  <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                    <Button
                      className="slc-button-link-only"
                      type="link"
                      onClick={() => getCreditReportDetailsManualpull("TU")}
                    >
                      Repull TU Credit
                    </Button>
                  </Col>
                )}
                {((isXpnReportAvailable && xpnErrors?.length > 0) || (!isXpnReportAvailable)) && (
                  <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                    <Button
                      className="slc-button-link-only"
                      type="link"
                      onClick={() => (tuErrors === undefined || tuErrors?.length > 0) ? getCreditReportDetailsManualpull("XPN") : setXpnPullModal(true)}
                    >
                      {xpnErrors && xpnErrors?.length > 0 ? 'Repull' : 'Pull'} XPN Credit
                    </Button>
                  </Col>
                )}
                {((isEfxReportAvailable && efxErrors?.length > 0) || !isEfxReportAvailable) && (
                  <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                    <Button
                      className="slc-button-link-only"
                      type="link"
                      onClick={() => (tuErrors === undefined || tuErrors?.length > 0) ?  getCreditReportDetailsManualpull("EFX") : setEfxPullModal(true)}
                    >
                      {efxErrors && efxErrors?.length > 0 ? 'Repull' : 'Pull'} EFX Credit
                    </Button>
                  </Col>
                )}
              </Row>

              <Dialog
                open={xpnPullModal}
                onClose={handleCloseXpnModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                {xpnErrors && xpnErrors?.length > 0 ? 'Repull' : 'Pull'} Credit?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  TransUnion Bureau already pulled. Do you still want to pull Experian Bureau?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className='slc-button-link-only'
                    type="link"
                    onClick={handleCloseXpnModal}>Disagree</Button>
                  <Button className='slc-button-link-only'
                    type="link"
                    onClick={() => {
                      handleCloseXpnModal()
                      getCreditReportDetailsManualpull("XPN")
                    }
                    }
                    autoFocus>
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={efxPullModal}
                onClose={handleCloseEfxModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                {efxErrors && efxErrors?.length > 0 ? 'Repull' : 'Pull'} Credit?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  TransUnion Bureau already pulled. Do you still want to pull Equifax Bureau?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className='slc-button-link-only'
                    type="link"
                    onClick={handleCloseEfxModal}>Disagree</Button>
                  <Button className='slc-button-link-only'
                    type="link"
                    onClick={() => {
                      handleCloseEfxModal()
                      getCreditReportDetailsManualpull("EFX")
                    }
                    }
                    autoFocus>
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>

            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    </Spin>
  ) : null;
}
