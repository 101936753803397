import React from 'react';
import './pagination.css'

class Pagination extends React.Component {
    handleClick= (page) => {
        this.props.handleClick(page);
    }
    render() {
      const {totalNotificationsCount,currentPage,indexOfFirstNotification,indexOfLastNotification,totalPages} =this.props;
    
      // Logic for displaying page numbers
      const pageNumbers = [];
      for (let i = (currentPage-4 <= 0 ? 1 : currentPage-4); i <= (totalPages>4 && (currentPage+4 < totalPages) ? currentPage+4 : totalPages); i++) {
        pageNumbers.push(i);
      }

      const renderPageNumbers = pageNumbers.map(number => {
            return(
              <li key={number} className={currentPage === number ? 'active' : ''} id={number}>
                <a href='#' id={number} onClick= {()=>this.handleClick(number)}>{number}</a>
              </li>
            )
      });

      const renderPrevBtn = <li className={currentPage === 1 ? 'disabled' : 1}><a href='#' id="btnPrev" onClick=                          {currentPage === 1?(event) => event.preventDefault() :()=>this.handleClick(currentPage-1)}> &lt; Prev </a></li>;

        const renderNextBtn = <li className={currentPage === totalPages ? 'disabled' : 1}><a href='#' id="btnNext" onClick=
        {currentPage === totalPages?(event) => event.preventDefault() :()=>this.handleClick(currentPage+1)}> Next &gt; </a></li>
        const renderFirstBtn = <li><a href='#' id="btnPrev" onClick={()=>this.handleClick(1)}>&lt;&lt; First </a></li>;
        const renderLastBtn = <li><a href='#' id="btnPrev" onClick={()=>this.handleClick( totalPages)}> Last &gt;&gt;</a></li>;
        if(totalPages === 1){
            if(totalNotificationsCount === 1){
                return (<div>
                    <ul className="pagination">
                        <li>Displaying <b>{totalNotificationsCount}</b> notification </li>
                    </ul>
                </div>)
            }
            return (<div>
                <ul className="pagination">
                    <li>Displaying all <b>{totalNotificationsCount}</b> notifications </li>
                </ul>
            </div>)
        }else if(!totalNotificationsCount){
            return (<div>
            <ul className="pagination">
                <li>No entries found  </li>
            </ul>
        </div>)
        }
        return (
        <div>
          <ul className="pagination">
            <li>Displaying notifications <b>{indexOfFirstNotification} - {indexOfLastNotification}</b> of <b>{totalNotificationsCount}    </b></li>
            {currentPage !==1 && renderFirstBtn}
            {renderPrevBtn}
            {renderPageNumbers}
            {renderNextBtn}
            {currentPage !== totalPages && renderLastBtn}
          </ul>
        </div>
      );
    }
  }

export default Pagination;