import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import NegativePay from './NegativePay';
import Prenote from "./Prenote";
import {
  Card,
  Form,
  Radio,
  Select,
  Col,
  Input,
  DatePicker,
  Button,
  Row,
  message
} from "antd";
import MaskedInput from 'antd-mask-input';
import { GET } from '../../util/network';
import logger from '../../util/logger';
import moment from 'moment'

const Option = Select.Option;

export default function BankingInformation({ data, save, disableFlag, setLoading, appNumber, handleNoAuth }) {

    const history = useHistory();
    const [form] = Form.useForm();
    const colesseeAccountJointValue = data.payment.colesseeAccountJointToLessee ? data.payment.colesseeAccountJointToLessee : "Yes"

    const { paymentBankName,
        paymentAbaRoutingNumber,
        paymentAccountNumber,
        paymentAccountType,
        paymentAccountHolder,
        colesseePaymentAbaRoutingNumber,
        colesseePaymentAccountNumber,
        colesseePaymentAccountType,
        colesseePaymentBankName,
        firstPaymentDate,
        paymentFirstDay,
        secondPaymentDate,
        paymentSecondDay,
        paymentFrequency } = data.payment;


    const [secondPaymentValidateStatus, setSecondPaymentValidateStatus] = useState(undefined)
    const [secondPaymentErrorMessage, setSecondPaymentErrorMessage] = useState(undefined)
    const [disableSave, setDisableSave] = useState(false)

    const [requireSecondPayment, setRequireSecondPayment] = useState(false)
    const [colesseeAccountJointToLessee] = useState(colesseeAccountJointValue)

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };
    const onFinish = async (values) => {
        values = {...values };

        if (values.firstPaymentDate) {
          values.firstPaymentDate = values.firstPaymentDate.format("YYYY-MM-DD")
        }

        if (values.secondPaymentDate) {
          values.secondPaymentDate = values.secondPaymentDate.format("YYYY-MM-DD")
        }
        await save(values);
    }

    const handleNewPrenote = async (e) => {
        e.preventDefault();
        try {
            await GET(`/api/v1/lease_application_requests/new_prenote?application_identifier=${appNumber}`);
            message.success("Prenote successfully requested!");
          } catch (e) {
            logger.error("Request Error", e);
            if (e && e.response && e.response.status === 401) {
              handleNoAuth(history);
            } else {
              message.error('Error while requesting Prenote');
            }
          }
    }


    const handleSecondPaymentDate = (dateString) => {
        if (dateString){
            let firstpaymentdate = form.getFieldValue('firstPaymentDate')
            let secondpaymentdate = new Date(dateString)
            handlePaymentDateErrors(firstpaymentdate, secondpaymentdate)
        }else{
            setSecondPaymentValidateStatus(undefined)
            setSecondPaymentErrorMessage(undefined)
            setDisableSave(false)
        }
    }


    const handleFirstPaymentDate = (dateString) => {
        let formSecondPaymentDate = form.getFieldValue('secondPaymentDate')
        if (formSecondPaymentDate){
            let firstpaymentdate = new Date(dateString)
            let secondpaymentdate = new Date(formSecondPaymentDate)
            handlePaymentDateErrors(firstpaymentdate, secondpaymentdate)
        }
    }

    const handlePaymentDateErrors = (firstpaymentdate, secondpaymentdate) => {
        setSecondPaymentValidateStatus(undefined)
        setSecondPaymentErrorMessage(undefined)
        if(firstpaymentdate > secondpaymentdate){
            setSecondPaymentValidateStatus("error")
            setSecondPaymentErrorMessage("The Second Payment Date cannot be earlier than the First Payment Date")
            setDisableSave(true)
        }else{
            setDisableSave(false)
        }
    }


    const handlePaymentFrequency = (value) => {
        setRequireSecondPayment(value == 1)
        if (value != 1){
            setSecondPaymentValidateStatus(undefined)
            setSecondPaymentErrorMessage(undefined)
            setDisableSave(false)
        }
    }

    // const handleColesseeAccountJointToLessee = (e) => {
    //   if (e.target.value === 'No') {
    //     setColesseeAccountJointToLessee(e.target.value)
    //   }
    //   else if (e.target.value == 'Yes') {
    //     setColesseeAccountJointToLessee(e.target.value)
    //   }
    // }

    // https://en.wikipedia.org/wiki/ABA_routing_transit_number
    // Returns true if valid, false if not
    const validRoutingNumber = (routing) => {
      if (routing.length != 9) {
        return false;
      }

      var checksumTotal = (7 * (parseInt(routing.charAt(0),10) + parseInt(routing.charAt(3),10) + parseInt(routing.charAt(6),10))) +
        (3 * (parseInt(routing.charAt(1),10) + parseInt(routing.charAt(4),10) + parseInt(routing.charAt(7),10))) +
        (9 * (parseInt(routing.charAt(2),10) + parseInt(routing.charAt(5),10) + parseInt(routing.charAt(8),10)));
      var checksumMod = checksumTotal % 10;

      if (checksumMod != 0) {
        return false;
      } else {
        return true;
      }
    }

    useEffect(() => {
        setRequireSecondPayment(paymentFrequency === 1)
      }, []);


    const layout = {
        labelCol: {
        span: 24,
        },
        wrapperCol: {
        span: 24,
        },
    };

    return (
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
            paymentBankName: paymentBankName,
            paymentAbaRoutingNumber: paymentAbaRoutingNumber,
            paymentAccountType: paymentAccountType,
            paymentAccountNumber: paymentAccountNumber,
            colesseePaymentBankName: colesseePaymentBankName,
            colesseePaymentAbaRoutingNumber: colesseePaymentAbaRoutingNumber,
            colesseePaymentAccountType: colesseePaymentAccountType,
            colesseePaymentAccountNumber: colesseePaymentAccountNumber,
            colesseeAccountJointToLessee: colesseeAccountJointToLessee,
            paymentAccountHolder: paymentAccountHolder,
            firstPaymentDate: firstPaymentDate && moment(firstPaymentDate, "YYYY-MM-DD"),
            paymentFirstDay: paymentFirstDay,
            secondPaymentDate: secondPaymentDate && moment(secondPaymentDate, "YYYY-MM-DD"),
            paymentSecondDay: paymentSecondDay,
            paymentFrequency: paymentFrequency,

        }}
      >
        <Col span={24}>
          <Card title='ACH Information'>
            {/*<Col xs={24} sm={24} md={24} lg={24} xl={12}>*/}
            {/*      <Form.Item label="Does the co-applicant have a joint account with the Lessee?" name="colesseeAccountJointToLessee" rules={[{*/}
            {/*          required : true,*/}
            {/*          message : "This field is required"*/}
            {/*      }]}>*/}
            {/*          <Radio.Group onChange={handleColesseeAccountJointToLessee} value={colesseeAccountJointToLessee}>*/}
            {/*              {*/}
            {/*                  (Object.keys(data.colesseeAccountJointToLesseeOptions || {})).map((key) => {*/}
            {/*                      return <Radio disabled={disableFlag} key={key} value={data.colesseeAccountJointToLesseeOptions[key]}>{data.colesseeAccountJointToLesseeOptions[key]}</Radio>*/}
            {/*                  })*/}
            {/*              }*/}
            {/*          </Radio.Group>*/}
            {/*      </Form.Item>*/}
            {/*</Col>*/}
            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
              <Form.Item label="Lessee or Co-Lessee?" name="paymentAccountHolder" rules={[{
                required : true,
                message : "Payment Account Holder is required"
              }]}>
                <Radio.Group disabled={disableFlag}>
                  <Radio value="Lessee">Lessee</Radio>
                  <Radio value="Co-Lessee">Co-Lessee</Radio>
                  <Radio value="Both">Both</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Row gutter={[32, 0]}>
              <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                  <Form.Item label="Bank Name" name="paymentBankName" rules={[{
                      required : true,
                      message : "This field is required"
                  }]}>
                      <Input disabled={disableFlag}/>
                  </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={4}>
                  <Form.Item
                    label="ABA Routing Number"
                    name="paymentAbaRoutingNumber"
                    messageVariables={{ name: "ABA Routing Number" }}
                    rules={[
                      {
                        required : true,
                        min: 9,
                        max: 9,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || validRoutingNumber(getFieldValue('paymentAbaRoutingNumber'))) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Please enter a valid routing number'));
                        },
                      })
                    ]}
                  >
                      <MaskedInput mask="111111111" disabled={disableFlag}/>
                  </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                  <Form.Item label="Account Number" name="paymentAccountNumber" rules={[{
                      required : true,
                      message : "This field is required"
                  }]}>
                      <Input disabled={disableFlag}/>
                  </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                  <Form.Item label="Checking/Savings Account" name="paymentAccountType" rules={[{
                      required : true,
                      message : "This field is required"
                  }]}>
                      <Select style={{ width: "100%" }} placeholder="Please Select" disabled={disableFlag}>
                          {
                              (data.paymentAccountTypeOptions || []).map((obj, key) => {
                                  return <Option key={key} value={obj.optionValue}>{obj.optionName}</Option>
                              })
                          }
                      </Select>
                  </Form.Item>
              </Col>
            </Row>
            {(colesseeAccountJointToLessee === 'No') && <Row gutter={[32, 0]}>
             <Col xs={24} sm={12} md={12} lg={12} xl={8}>
                  <Form.Item label="Co-Lessee Bank Name" name="colesseePaymentBankName" rules={[{
                      required : true,
                      message : "This field is required"
                  }]}>
                      <Input disabled={disableFlag}/>
                  </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={4}>
                  <Form.Item
                    label="Co-Lessee ABA Routing Number"
                    name="colesseePaymentAbaRoutingNumber"
                    messageVariables={{ name: "ABA Routing Number" }}
                    rules={[
                      {
                        required : true,
                        min: 9,
                        max: 9,
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || validRoutingNumber(getFieldValue('colesseePaymentAbaRoutingNumber'))) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Please enter a valid routing number'));
                        },
                      })
                    ]}
                  >
                      <MaskedInput mask="111111111" disabled={disableFlag}/>
                  </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                  <Form.Item label="Co-Lessee Account Number" name="colesseePaymentAccountNumber" rules={[{
                      required : true,
                      message : "This field is required"
                  }]}>
                      <Input disabled={disableFlag}/>
                  </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                  <Form.Item label="Co-Lessee Checking/Savings Account" name="colesseePaymentAccountType" rules={[{
                      required : true,
                      message : "This field is required"
                  }]}>
                      <Select style={{ width: "100%" }} placeholder="Please Select" disabled={disableFlag}>
                          {
                              (data.paymentAccountTypeOptions || []).map((obj, key) => {
                                  return <Option key={key} value={obj.optionValue}>{obj.optionName}</Option>
                              })
                          }
                      </Select>
                  </Form.Item>
              </Col>
            </Row> }
            <Row gutter={[32, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <Row gutter={[32, 0]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      { data.paymentInfoExists  &&
                          <Form.Item label={"Prenote Request"}>
                              <Button type="primary" style={{float:'left'}} onClick={(e)=>{handleNewPrenote(e)}} disabled={disableFlag}> New Prenote </Button>
                          </Form.Item>
                      }
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Form.Item label="Prenote Status">
                         <Input type="text" disabled value={data.prenoteStatus} />
                      </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[32, 0]}>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <Row gutter={[32, 0]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="First Payment Date"
                      name="firstPaymentDate"
                      rules={[{
                        required : true,
                        message : "This field is required"
                      }]}
                      >
                        <DatePicker format="MM-DD-YYYY" style={{ width: "100%" }} onChange={handleFirstPaymentDate} disabled={disableFlag}/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="First Payment Day"
                      name="paymentFirstDay"
                      rules={[{
                        required : true,
                        message : "This field is required"
                      }]}
                      >
                        <Input disabled={disableFlag}/>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <Row gutter={[32, 0]}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="Second Payment Date"
                      name="secondPaymentDate"
                      rules={[{
                        required : requireSecondPayment,
                        message : "This field is required"
                      }]}
                      validateStatus={secondPaymentValidateStatus}
                      help={secondPaymentErrorMessage}
                    >
                      <DatePicker format="MM-DD-YYYY" style={{ width: "100%" }} onChange={handleSecondPaymentDate} disabled={disableFlag}/>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      label="Second Payment Day"
                      name="paymentSecondDay"
                      rules={[{
                        required : requireSecondPayment,
                        message : "This field is required"
                      }]}
                      >
                        <Input disabled={disableFlag}/>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='flex-jc-fs' gutter={[32, 0]}>
              <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                <Form.Item
                  label="Payment Frequency"
                  name="paymentFrequency"
                  rules={[{
                    required : true,
                    message : "This field is required"
                  }]}
                  >
                  <Select style={{ width: "100%" }} placeholder="Please Select" onChange={handlePaymentFrequency} disabled={disableFlag}>
                    {
                      (data.paymentFrequencyOptions || []).map((obj, key) => {
                        return <Option key={key} value={obj.optionValue}>{obj.optionName}</Option>
                      })
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className='flex-jc-fs mt-20' gutter={[32, 0]}>
              <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                <Button type="primary" htmlType="submit" disabled={disableSave || disableFlag}>Save</Button>
              </Col>
            </Row>
          </Card>

          <Row gutter={[16, 16]} className='mt-20'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card title='Negative Pay'>
                <NegativePay
                  
                  setLoading={setLoading}
                  appNumber={appNumber}
                  data={data}
                  save={save}
                />
              </Card>
            </Col>
          </Row>

          <Row gutter={[16, 16]} className='mt-20'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card title='Prenotes'>
                <Prenote
                  
                  setLoading={setLoading}
                  appNumber={appNumber}
                  data={data}
                  save={save}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Form>
    );
}