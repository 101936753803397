import React, { useEffect, useState } from 'react';

import { Comment, List, Avatar, Form, Button, Input, message, Row, Col, Switch, Tooltip } from 'antd';
import { UserOutlined, DeleteFilled, SaveFilled, EditFilled } from '@ant-design/icons';

import moment from 'moment';

import { POST, PUT, DELETE } from '../../../util/network';
import logger from '../../../util/logger';
import { useDispatch } from "react-redux";
import { insertComment, insertCommentReply, updateComment, deleteComment, deleteCommentReply, updateCommentVisibleToDealers } from '../../../reducers/LeaseApplications'

const { TextArea } = Input;

const Editor = ({ onChange, onSubmit, submitting, value, disabled, onChangeSwitch, addNoteTogglerBackGround }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} disabled={disabled}/>
    </Form.Item>
    <Form.Item
      name="visible_to_dealers"
      label="Visible To Dealers"
      valuePropName="checked"
    >
      <Switch disabled={disabled} onChange={onChangeSwitch} style={{ backgroundColor: addNoteTogglerBackGround ? "#e93b1b" : '#00000040' }}/>
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary" disabled={disabled} style={{ backgroundColor: "#e93b1b", color: 'white' }}>
        Add Note
      </Button>
    </Form.Item>
  </>
);

export default function Notes({ ...props }) {
  const { data } = props;

  const [comments, setComments] = useState([]);

  const [loading, setLoading] = useState(false);
  const [commentValue, setCommentValue] = useState('');
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
      loggedInUserId = authData.id;
  }
  const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
  const disableFlag = locked_by != loggedInUserId ? true : false
  const dispatch = useDispatch();
  const [repliable, setRepliable] = useState([]);
  const [replies, setReplies] = useState([])
  const [editableNotesIds, setEditableNotesIds] = useState([])
  const [editableNotesDetails, setEditableNotesDetails] = useState([])
  const [visibleToDealers, setVisibleToDealers] = useState(false)
  const [addNoteTogglerBackGround, setAddNoteTogglerBackGround] = useState(false)
  const [commentsTogglerBackGround, setCommentsTogglerBackGround] = useState([])

  const handleChange = (e) => {
    setCommentValue(e.target.value);
  };

  const handleChangeVisibleToDealers = (value) => {
    setVisibleToDealers(value);
    setAddNoteTogglerBackGround(value)
  };

  const handleSubmit = async () => {
    setLoading(true)
    try {
      let response = await POST(`/api/v1/comments/x/lease-applications/${data.id}`, { commit: commentValue, visible_to_dealers: visibleToDealers })
      message.success("Note saved successfully!")
      setCommentValue("")
      dispatch(insertComment(response.data));
    } catch (e) {
      logger.error("Submit Notes Error", e)
      message.error("Error while saving!")
    }
    setLoading(false)
  }

  const handleVisibility = async (comment, value) => {
    try {
      await PUT(`/api/v1/comments/${comment.id}/toggle-visibility`);
      dispatch(updateCommentVisibleToDealers({ id: comment.id, visibleToDealers: value }))
      message.success("Visibility updated successfully");
    } catch (e) {
      logger.error("Error while saving your data", e);
      message.error('Error while updating visibility');
    }
  }

  const addReply = async (comment, reply) => {
    setLoading(true)
    try {
      let response = await POST(`/api/v1/comments/${comment.id}/reply`, { commit: reply })
      dispatch(insertCommentReply({ commentId: comment.id, response: response.data }))
      setLoading(false)
      message.success("Note reply added successfully!")
    } catch (e) {
      logger.error("Submit Note Reply Error", e)
      setLoading(false)
      message.error("Error while saving note reply!")
    }
  }

  const editNote = async (comment) => {
    setLoading(true)
    try {
      const latestComment = editableNotesDetails.find(note => note.id === comment.id)
      const response = await PUT(`/api/v1/comments/${comment.id}/edit`, { commit: latestComment.body })
      const newEditableNotesIds = editableNotesIds.filter(noteId => noteId !== comment.id )
      dispatch(updateComment( { commentId: comment.id, body: response.data.body }))
      setEditableNotesIds(newEditableNotesIds)
      setLoading(false)
      message.success("Note updated successfully!")
    } catch (e) {
      logger.error("Update Notes Error", e)
      setLoading(false)
      message.error("Error while updating!")
    }
  }

  const deleteNote = async (comment) => {
    setLoading(true)
    try {
      await DELETE(`/api/v1/comments/${comment.id}`)
      dispatch(deleteComment({ id: comment.id }))
      setLoading(false)
      message.success("Note deleted successfully!")
    } catch (e) {
      logger.error("Delete Notes Error", e)
      setLoading(false)
      message.error("Error while deleting note!")
    }
  }

  const deleteNoteReply = async (comment, reply) => {
    setLoading(true)
    try {
      await DELETE(`/api/v1/comments/${comment.id}/reply/${reply.id}`)
      dispatch(deleteCommentReply({ id: comment.id, replyId: reply.id }))
      setLoading(false)
      message.success("Note Reply deleted successfully!")
    } catch (e) {
      logger.error("Delete Note reply Error", e)
      setLoading(false)
      message.error("Error while deleting note reply!")
    }
  }

  const replyTextArea = (comment) => {
    if(repliable.includes(comment.id)){
      return <Row>
        <Col span={23}>
          <TextArea rows={1} disabled={disableFlag} value={replies.find(reply => reply.id === comment.id)?.body || ''} onChange={(event) => {
            const newReplies = replies.filter(reply => reply.id !== comment.id)
            newReplies.push({ id: comment.id, body: event.target.value})
            setReplies(newReplies)
          }}/>
        </Col>
        <Col span={1} style={{ textAlign: 'right' }}>
          <Tooltip title={"Save Reply"}>
            <Button type="link" disabled={disableFlag} onClick={() => {
              const reply = replies.find(reply => reply.id === comment.id) || {}
              addReply(comment, reply.body || '')
              setReplies(replies.filter(reply => reply.id !== comment.id))
            }}><SaveFilled style={{ color: "#e93b1b", fontSize: '150%' }}/>
            </Button>
          </Tooltip>
        </Col>
      </Row>
    }
  }

  const commentReplies = (comment) => {
    return <List
      className="comment-replies"
      itemLayout="horizontal"
      dataSource={comment.replies}
      renderItem={reply => (
        <li>
          <Comment
            author={reply.author}
            avatar={<Avatar size="large" icon={<UserOutlined />} />}
            content={
              <Row>
                <Col span={23}>
                  <p>{reply.body}</p>
                </Col>
                {
                  reply.authorId === loggedInUserId &&
                  <Col span={1} style={{ textAlign: 'right' }}>
                    <Tooltip title={"Delete Reply"}>
                      <Button type="link" disabled={disableFlag} onClick={() => deleteNoteReply(comment, reply) }><DeleteFilled style={{ color: "#e93b1b", fontSize: '150%' }}/></Button>
                    </Tooltip>
                  </Col>
                }
              </Row>
            }
            datetime={moment(reply.createdAt).format('LLLL')}
          >
          </Comment>
        </li>
      )}
    />
  }

  const commentBody = (comment) => {
     return <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
       { commentBodyLength(comment) }
       <Col span={4} style={{ textAlign: 'right' }}>
         <Row>
           {
             switchCommentBody(comment)
           }
           {
             comment.authorId === loggedInUserId && comment.namespace === 'admins' &&
             <Col span={1}>
               {saveEditButton(comment)}
             </Col>
           }
           {
             comment.authorId === loggedInUserId && comment.namespace === 'admins' &&
             <Col span={1}>
               <Tooltip title={"Delete Note"}>
                 <Button type="link" disabled={disableFlag} onClick={() => deleteNote(comment) }><DeleteFilled style={{ color: "#e93b1b", fontSize: "150%" }}/></Button>
               </Tooltip>
             </Col>
           }
         </Row>
       </Col>
      </Row>
  }

  const switchCommentBody = (comment) => {
    return comment.authorId === loggedInUserId && comment.namespace === 'admins' &&
    <Col span={2}>
      <Tooltip title={"Visible to Dealers"}>
        <Switch defaultChecked={comment.visibleToDealers} disabled={disableFlag} onChange={(value) => {
          const tempCommentsTogglerBackGround = commentsTogglerBackGround.filter(note => note.id !== comment.id)
          tempCommentsTogglerBackGround.push({ id: comment.id, visibleToDealers: value })
          setCommentsTogglerBackGround(tempCommentsTogglerBackGround)
          handleVisibility(comment, value)
        }} style={{ backgroundColor: commentsTogglerBackGround.find(note => note.id === comment.id).visibleToDealers ? "#e93b1b" : '#00000040' }}></Switch>
      </Tooltip>
    </Col>
  }

  const commentBodyLength = (comment) => {
    if(comment.authorId === loggedInUserId && comment.namespace === 'admins') {
      return <Col span={20}>
        {editableCommentBody(comment)}
      </Col>
    }
    else{
      return <Col span={24}>
        {editableCommentBody(comment)}
      </Col>
    }
  }

  const editableCommentBody = (comment) => {
    if(editableNotesIds.includes(comment.id)){
      return <TextArea rows={1} disabled={disableFlag} defaultValue={comment.body} onChange={(event) => {
        const tempEditableNotes = editableNotesDetails.filter(note => note.id !== comment.id)
        tempEditableNotes.push({ id: comment.id, body: event.target.value })
        setEditableNotesDetails(tempEditableNotes)
      }} style={{ color: comment.namespace === 'admins' ? 'black' : 'green' }}/>
    }
    else{
      return <p style={{ color: comment.namespace === 'admins' ? 'black' : 'green' }}>{comment.body}</p>
    }
  }

  const saveEditButton = (comment) => {
    if(editableNotesIds.includes(comment.id)){
      return <Tooltip title={"Save Note"}>
        <Button type="link" disabled={disableFlag} onClick={() => {
          editNote(comment)
        }}><SaveFilled style={{ color: "#e93b1b", fontSize: '150%' }}/></Button>
      </Tooltip>
    }
    else{
      return <Tooltip title={"Edit Note"}>
        <Button type="link" disabled={disableFlag} onClick={() => {
          const newEditableNotesIds = [...editableNotesIds]
          newEditableNotesIds.push(comment.id)
          setEditableNotesIds(newEditableNotesIds)
        }}><EditFilled style={{ color: "#e93b1b", fontSize: '150%' }}/></Button>
      </Tooltip>
    }
  }

  useEffect(() => {
    setComments(data.comments)
    setEditableNotesDetails(data.comments)
    setCommentsTogglerBackGround(data.comments)
  }, [data]);

  return (
    <>
      <List
        className="comment-list"
        itemLayout="horizontal"
        dataSource={comments}
        renderItem={comment => (
          <li>
            <Comment
              author={<div>{comment.author}<p>{comment.namespace === 'admins' ? 'Admin' : 'Dealer'}</p></div>}
              avatar={<Avatar size="large" icon={<UserOutlined />}/>}
              content={
                <div>
                  { commentBody(comment) }
                  { commentReplies(comment) }
                </div>
              }
              datetime={moment(comment.createdAt).format('LLLL')}
              actions={[<Button key="nested reply" block type="primary" disabled={disableFlag} style={{ backgroundColor: "#e93b1b", color: 'white', marginBottom:'10px' }}
                                onClick={ () => {
                                  const newRepliable = [...repliable]
                                  newRepliable.push(comment.id)
                                  setRepliable(newRepliable)
                                }
                                }>Reply</Button>]}
              >
              {
                replyTextArea(comment)
              }
            </Comment>
          </li>
        )}
      />
      <Comment
        avatar={
          <Avatar size="large" icon={<UserOutlined />} />
        }
        content={
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitting={loading}
            value={commentValue}
            disabled={disableFlag}
            onChangeSwitch={handleChangeVisibleToDealers}
            addNoteTogglerBackGround={addNoteTogglerBackGround}
          />
        }
      />
    </>
  )
}
