import React from "react";
import { useHistory } from 'react-router-dom';
import { Row, Col, Table, message, Button } from "antd";
import { GET } from '../../../../util/network';
import logger from '../../../../util/logger';
import {useSelector} from "react-redux";

const columns = [
    {
        title: 'Lessee',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Type',
        dataIndex: 'blackboxEndpoint',
        key: 'blackboxEndpoint'
    },
    {
        title: 'Decision',
        dataIndex: 'decision',
        key: 'decision'
    },
    {
        title: 'Score',
        dataIndex: 'adjustedScore',
        key: 'adjustedScore'
    },
    {
        title: 'Requested At',
        dataIndex: 'createdAt',
        key: 'createdAt'
    },
    {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt'
    },
    {
        title: 'Request Control',
        dataIndex: 'requestControl',
        key: 'requestControl'
    },
];
export default function Summary({ data, handleNoAuth, setLoading }) {
  const history = useHistory();
  const isCreditUser = useSelector((state) => state.leaseApplications.isCreditUser);
  
  const dataSource = [data.leaseApplicationBlackboxRequest] || []
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
    loggedInUserId = authData.id;
  }
 
  const locked_by =  dataSource[0] && dataSource[0].lockedStatus && dataSource[0].lockedStatus.lockedById;
  const disableFlag = !(isCreditUser && locked_by == loggedInUserId);

  const handleRepullBlackbox = async () => {
    setLoading(true);
    try {
      await GET(`/api/v1/lease_applications/repull-datax?application_identifier=${data.leaseApplicationBlackboxRequest.applicationIdentifier}`);
      message.success("Blackbox Will Be Repulled Shortly");
    } catch (e) {
      logger.error("Error processing Blackbox", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error processing Blackbox');
      }
    }
    setLoading(false);
  }

  return data ? (
    <>
      <Row>
        <Col span={24}>
          <Table 
            className='slc-table' 
            rowKey="id" 
            columns={columns} 
            dataSource={dataSource} 
            pagination={false} 
            size="small"
          />
        </Col>
      </Row>
      <Row className='flex-jc-fs'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Button 
            className='slc-button-link-only'
            type="link" 
            onClick={ () => { handleRepullBlackbox() } }
            disabled={disableFlag}
          >
            Repull Blackbox
          </Button>
        </Col>
      </Row>
    </>
  ) : null ;
}