import React from "react";
import { Select, Row, Col, Form } from 'antd';

const { Option } = Select;

export default function CustomSelect({label, required, ruleMessage, name, mode, onChange, onSelect, options, defaultValue, additionalProps, disabled}) {


    return (
        <Row>
            <Col span={24}> 
                <Form.Item 
                    label={label} 
                    hasFeedback
                    name={name}
                    rules={[{ required: required, message: ruleMessage }]}
                >  
                      <Select 
                      mode={mode} 
                      style={{ width: '100%' }} 
                      placeholder={label} 
                      onChange={onChange} 
                      onSelect={onSelect} 
                      {...additionalProps} 
                      defaultValue={defaultValue} 
                      disabled={disabled}>
                            {
                                options && options.map(({optionValue, optionLabel}, index) => {
                                  return <Option key={index} value={`${optionValue}`}>{optionLabel}</Option>
                                })
                              }
                     </Select>
                </Form.Item>
            </Col>
        </Row> 
    );
}