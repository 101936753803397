import React, {useEffect, useState} from "react";
import {
  Form,
  Select,
  Col,
  Row,
  Button,
} from "antd";
import {POST} from '../../../util/network';
import logger from '../../../util/logger';
import {useHistory} from 'react-router-dom';
import {message} from 'antd';

const Option = Select.Option;

export default function dealershipStatus(props) {
  const {allowedUpdate, setLoading, handleNoAuth, getDealership, dealershipId, dealership, dealershipsStatus} = props
  const dealershipStatus = () => {
    return (dealership.status === 'Active' && dealership.canSubmit === true) ? 'Can Submit' : dealership.status
  }
  const [statusVal, setStatusVal] = useState(dealershipStatus())
  const [form] = Form.useForm();
  const history = useHistory();
  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const handleSubmit = async () => {
    if (allowedUpdate) {
      setLoading(true);
      try {
        await POST(`/api/v1/dealerships/update-status`, {
          status: statusVal,
          id: dealershipId
        });
        getDealership();
        message.success("Data saved successfully");
      } catch (e) {
        logger.error("Dealership Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while saving your data');
        }
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    setStatusVal(dealershipStatus())
    form.setFieldsValue({
      status: dealershipStatus()
    });
  }, [dealership]);

  return (
    <Form
      {...layout}
      form={form}
      initialValues={{
        status: statusVal
      }}
      onFinish={handleSubmit}
    >
      <Row gutter={[32, 0]}>
        <Col span={24}>
          <Form.Item
            label="Dealership Status"
            name="status"
            rules={[{
              required: true,
              message: 'Status is required'
            }]}
          >
            <Select
              key={dealershipStatus()}
              placeholder="Select status"
              style={{width: "100%"}}
              onChange={(value) => {
                setStatusVal(value)
              }}
            >
              {dealershipsStatus && dealershipsStatus.map((obj, key) => (
                <Option key={key} value={obj.value}>{obj.Name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
