import React, {useState, useEffect} from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import AdminUser from "./pages/AdminUsers/AdminUser";
import NewAdminUser from "./pages/AdminUsers/NewAdminUser";
import AdministrationRoute from './util/AdministrationRoute';
import AllowedActions from './util/AllowedActions.js';
import AllNotifications from './pages/AllNotifications/AllNotifications';
import Settings from "./pages/Settings/Settings";
import ControlPanel from "./pages/ControlPanel/ControlPanel";
import Dashboard from "./pages/Dashboard/Dashboard";
import LeaseApplicationRenderer from "./pages/LeaseApplication/LeaseApplicationRenderer";
import WelcomeCallView from "./pages/LeaseApplication/welcomeCall/WelcomeCallView";
import FundingApprovalChecklist from "./pages/Documents/FundingApprovalChecklist/FundingApprovalChecklist";
import VerificationCallChecklist from "./pages/Documents/VerificationCallChecklist/VerificationCallChecklist";
import EmailTemplates from "./pages/EmailTemplates/EmailTemplates";
import Inventory from "./pages/Inventory/Inventory"
import Inventories from "./pages/Inventory/InventoryRenderer"
import InventoryNadaHistoryRenderer from "./pages/Inventory/InventoryNadaHistoryRenderer"
import NewInventory from "./pages/Inventory/NewInventory"
import AdminUsersRenderer from "./pages/AdminUsers/AdminUsersRenderer";
import DealershipsRenderer from "./pages/Dealership/DealershipsRenderer"
import DealershipRenderer from "./pages/Dealership/DealershipRenderer"
import DealerUsersRenderer from "./pages/Dealers/DealerUsersRenderer";
import DealerActivityRenderer from "./pages/Dealership/DealerActivityRenderer"
import WorkflowRenderer from "./pages/Workflow/WorkflowRenderer"
import NewDealership from "./pages/Dealership/NewDealership"
import Blackbox from './pages/LeaseApplication/credits/Blackbox';
import CreditReportDetails from './pages/LeaseApplication/credits/CreditReportDetails';
import LeaseApplicationsListRenderer from './pages/LeaseApplications/LeaseApplicationListRenderer';
import TimerReportDataGrid from './pages/TimerReport/TimerReportDataGrid';
import DealerUserRender from "./pages/Dealers/DealerUserRender";
import SignIn from './pages/SignIn';
import {authenticate, handleNoAuth} from './util/authenticate';
import logger from './util/logger'
import {GET} from './util/network';
import './App.css';
import DocusignReportsRenderer from './pages/DocusignReports/DocusignReportsRenderer';
import { message } from 'antd';
import ChangePassword from './pages/ChangePassword';
import CbcUsersRenderer from "./pages/CbcUsers/CbcUsersRenderer";
import Calculator from "./pages/Calculator/Calculator";
import CalculatorRenderer from "./pages/Calculator/CalculatorRenderer";
import NewCbcUser from "./pages/CbcUsers/NewCbcUser";
import ChangeCbcPassword from "./pages/CbcUsers/ChangeCbcPassword";
import CreditReportDocument from './pages/LeaseApplication/credits/CreditReportDocument';
import DealershipEmail from "./pages/Dealership/DealerActivityComponents/DealershipEmail";
import VideoRenderer from "./pages/Settings/Training/VideoRenderer";
import MakesRenderer from "./pages/Makes/MakesRenderer";
import MakeCategoryRenderer from "./pages/MakeCategory/MakeCategoryRenderer";
import MakeCategoryView from "./pages/MakeCategory/MakeCategoryView";
import NewMake from "./pages/Makes/NewMake";
import MakeEdit from './pages/Makes/MakeEdit';
import MakeRenderer from './pages/Makes/MakeRenderer';
import ModelGroupsRenderer from "./pages/ModelGroups/ModelGroupsRenderer";
import ModelGroupRenderer from "./pages/ModelGroups/ModelGroupRenderer";
import NewModelGroup from "./pages/ModelGroups/NewModelGroup";
import UpdateModelGroup from "./pages/ModelGroups/UpdateModelGroup";
import ModelYearsListRenderer from './pages/ModelYears/ModelYearsListRenderer'
import ModelYearsAdd from './pages/ModelYears/NewModelYears'
import ModelYearsUpdate from './pages/ModelYears/UpdateModelYear'
import ModelYearsHistoryRenderer from './pages/ModelYears/ModelYearsHistoryRenderer'
import CreditTiersRenderer from "./pages/CreditTiers/CreditTiersRenderer";
import NewCreditTier from "./pages/CreditTiers/NewCreditTier";
import EditCreditTier from "./pages/CreditTiers/EditCreditTier";
import CreditTierRenderer from "./pages/CreditTiers/CreditTierRenderer";
import TloReportDetails from './pages/LeaseApplication/credits/TloReportDetails';
import UnauthorizedPage from './pages/Unauthorized';
import ApplicationStipulationsRenderer from './pages/LeaseApplicationStipulation/ApplicationStipulationsRenderer';
import ApplicationStipulationRenderer from './pages/LeaseApplicationStipulation/ApplicationStipulationRenderer';
import UpdateApplicationStipulation from './pages/LeaseApplicationStipulation/UpdateApplicationStipulation';
import FundingDelaysRenderer from "./pages/FundingDelays/FundingDelaysRendered";
import FundingDelayRenderer from "./pages/FundingDelays/FundingDelayRenderer";
import FundingDelayRendererUpdate from "./pages/FundingDelays/UpdateFundingDelay";
import LesseesRenderer from './pages/Lessee/LesseesRenderer'
import LesseeRenderer from './pages/Lessee/LesseeRenderer'
import UpdateLesseeRenderer from './pages/Lessee/UpdateLessee'
import DocumentRequestsRenderer from './pages/LeaseDocumentRequest/DocumentRequestsRenderer';
import DocumentRequestRenderer from './pages/LeaseDocumentRequest/DocumentRequestRenderer';
import UpdateDocumentRequest from './pages/LeaseDocumentRequest/UpdateDocumentRequest';
import ContactRender from "./pages/Contacts/ContactRenderer";
import CallLogsRenderer from "./pages/Dealership/CallLogsRenderer";
import ForgetPassword from './pages/ResetPassword/ForgetPassword';
import ResetAdminPassword from './pages/ResetPassword/ResetAdminPassword';
import SignOut from './pages/SignOut';
import AdminChangePassword from './pages/AdminUsers/AdminChangePassword';
import ContactsRenderer from "./pages/Contacts/ContactsRenderer";


const auth_token = window.localStorage.getItem('auth_token');

export default function App() {
  const [authorize, setAuthorize] = useState(undefined);
  useEffect(() => {
    if (authenticate) {
      checkExpiry()
    }
  }, []);

  const allowedActionsData = AllowedActions();

  const checkExpiry = async () => {
    try {
      const response = await GET(`/api/v1/admin-users/need-change-password`);

      if (response.data.need_change_password) {
        window.localStorage.removeItem('auth_token');
        window.localStorage.removeItem('user_name');
        window.localStorage.removeItem('schedulingVal');
        setAuthorize(false);
        message.error("Password Expired !, Please reset password . redirecting...");
        setTimeout(window.location.href = "/change-password", 5000);

        // window.location.href = `${process.env.REACT_APP_BASE_URL}/admins/password/new`; 
        //  window.location.href = "/change-password";

      } else {
        setAuthorize(true);
      }

      return response.data.need_change_password;
    } catch (e) {
      logger.error("Error fetching information", e);

      if (!e.response || !e.response.status) {
        return false;
      }

      if (e.response.status === 401) {
        window.localStorage.removeItem('auth_token');
        window.localStorage.removeItem('user_name');
        window.localStorage.removeItem('schedulingVal');
        setAuthorize(false);
      }
    }

    return false;
  }

  const redirectLogin = () => {
    let urlPath = window.location.pathname;
    if (urlPath != '/change-password' && urlPath != '/forget-password' && urlPath.split('/')[1] != 'reset-password'){
      return true
    }else {
      return false
    }
  }

  const shouldRedirect = redirectLogin()

  return authorize == undefined
    ? ''
    : (
      <>
        <BrowserRouter>
          {
            (!authenticate || !authorize) &&
            <Route>
              <Route exact path="/change-password"
                     component={() => {
                       return <ChangePassword/>
                     }}

              />

                <Route exact path="/forget-password"
                  component={(props) => {
                    return <ForgetPassword />
                  }}
                />

                <Route exact path="/reset-password/:token"
                  component={(props) => {
                    return <ResetAdminPassword {...props}/>
                  }}
                />

                {/* <Redirect to="/admins/login?p=0" /> */}
                <Route path="/admins/login" exact component={SignIn} />
              </Route>
          }

          {
             (!authenticate || !authorize) && shouldRedirect &&
            <Redirect to="/admins/login?p=0" />
          }

          {
            (authenticate && authorize) &&
            <Switch>
              <Route path="/admins/login" exact component={SignIn} />
              <Route path="/logout" exact component={SignOut} />
              <Route path="/admins/change-my-password" exact component={AdminChangePassword} />
              <Route path="/admins/all_notifications" exact component={(props)=>{
                return <AllNotifications {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <AdministrationRoute
                exact
                path="/administration/admin-users/add-new"
                component={(props) => {
                  return <NewAdminUser {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
                }}
              />
              <AdministrationRoute path="/administration/admin-users/:id" component={(props)=>{
                return <AdminUser {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <AdministrationRoute path="/administration/admin-users" component={(props)=>{
                return <AdminUsersRenderer {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <AdministrationRoute exact path="/admins/docusign_reports" component={(props)=>{
                return <DocusignReportsRenderer {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
                }}
              />
                <AdministrationRoute path="/administration/settings" component={(props)=>{
                return <Settings {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <AdministrationRoute path="/administration/email-templates" component={(props)=>{
                return <EmailTemplates {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <AdministrationRoute path="/administration/workflow" component={(props)=>{
                return <WorkflowRenderer {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <AdministrationRoute path="/administration/control-panel" component={(props)=>{
                return <ControlPanel {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
                }} 
                />
              <AdministrationRoute exact path="/administration/cbc-users/:userId/change-password" component={(props) => {
                  return (
                    <ChangeCbcPassword
                      {...props}
                      handleNoAuth={handleNoAuth}
                      allowedActions={allowedActionsData}
                    />
                  );
                }}
                />
                <AdministrationRoute exact path="/administration/cbc-users/add-new" component={(props) => {
                  return <NewCbcUser {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData} />
                }}
                />
                <AdministrationRoute path="/administration/cbc-users" component={(props) => {
                  return <CbcUsersRenderer {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData} />
                }}
              />
              <Route path="/admins/lease_calculators/:calculatorId/edit" handleNoAuth={handleNoAuth} allowedActions={allowedActionsData} exact component={CalculatorRenderer} />
              <Route path="/dealerships/new" component={(props)=>{
                return <NewDealership {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <Route path="/dealerships/:id" component={(props) => {
                return <DealershipRenderer {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
                }} 
              />
              <Route
                path="/dealership/:id/activities"
                component={(props) => {
                  return <DealerActivityRenderer {...props} handleNoAuth={handleNoAuth} />

                }}
              />
              <Route path="/dealerships" component={(props)=>{
                return <DealershipsRenderer {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <Route path="/new-dealer-users" component={(props) => {
                return (
                  <DealerUsersRenderer
                    {...props}
                    handleNoAuth={handleNoAuth}
                    allowedActions={allowedActionsData}
                  />
                );
              }}
              />
              <Route
                path="/dealer-users/:id"
                component={(props) => {
                  return (
                    <DealerUserRender
                      {...props}
                      handleNoAuth={handleNoAuth}
                      allowedActions={allowedActionsData}
                    />
                  );
                }}
              />
              <Route
                path="/dealer-users/create"
                component={(props) => {
                  return (
                    <DealerUserRender
                      {...props}
                      handleNoAuth={handleNoAuth}
                      allowedActions={allowedActionsData}
                    />
                  );
                }}
              />
              <Route path="/documentation" component={() => {
                window.location.href = `/documentation/index.html`;
                return '';
              }}
              />
              <Route path="/inventory/new" component={(props) => {
                return <NewInventory {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <Route path="/inventory/:id/nada-value-history" component={(props) => {
                return <InventoryNadaHistoryRenderer {...props} handleNoAuth={handleNoAuth}
                                                     allowedActions={allowedActionsData}/>
              }}
              />
              <Route path="/inventory/:id" component={(props) => {
                return <Inventory {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <Route path="/inventory" component={(props) => {
                return <Inventories {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <Route path="/dashboard" component={(props) => {
                return <Dashboard {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <Route path="/unauthorized" component={UnauthorizedPage} />
              <Route path="/lease-applications/:appNumber/blackbox/:id" component={(props)=>{
                return <Blackbox {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <Route path="/lease-applications/:appNumber/credit-report/:id/details"
                     component={(props) => {
                       return <CreditReportDetails {...props} handleNoAuth={handleNoAuth}
                                                   allowedActions={allowedActionsData}/>
                     }}
              />
              <Route path="/lease-applications/:appNumber/credit-report-document/:id"
                     component={(props) => {
                       return (
                         <CreditReportDocument
                           {...props}
                           handleNoAuth={handleNoAuth}
                           allowedActions={allowedActionsData}
                         />
                       );
                     }}
              />
              <Route path="/lease-applications/:appNumber/tlo-report/:lesseeId/details"
                component={(props) => {
                  return (
                    <TloReportDetails
                      {...props}
                      handleNoAuth={handleNoAuth}
                      allowedActions={allowedActionsData}
                    />
                  );
                }}
              />

              <Route path="/lease-applications/:id" component={(props)=>{
                return <LeaseApplicationRenderer {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
                }} 
              />
              <Route path="/lease_applications" component={() => {
                window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=/admins/lease_applications`;
                return '';
              }}
              />
              <Route path="/lease_application_welcome_calls/:appNumber" component={(props) => {
                return <WelcomeCallView {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <Route path="/online-funding-approval-checklist/:id" component={(props) => {
                return <FundingApprovalChecklist {...props} handleNoAuth={handleNoAuth}
                                                 allowedActions={allowedActionsData}/>
              }}
              />
              <Route
                path="/online-verification-call-checklist/:id"
                component={(props) => {
                  return <VerificationCallChecklist {...props} handleNoAuth={handleNoAuth}
                                                    allowedActions={allowedActionsData}/>
                }}
              />
              <Route
                path="/lease-applications-list"
                component={(props) => {
                  return <LeaseApplicationsListRenderer {...props} handleNoAuth={handleNoAuth}/>
                }}
              />
              <Route
                path="/timer-reports/list"
                component={(props) => {
                  return <TimerReportDataGrid {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
                path="/dealership/:id/emails/:email_id"
                component={(props) => {
                  return <DealershipEmail {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
                path="/contacts/:id"
                component={(props) => {
                  return (
                    <ContactRender
                      {...props}
                      handleNoAuth={handleNoAuth}
                      allowedActions={allowedActionsData}
                    />
                  );
                }}
              />
              <Route
                path="/contacts/create"
                component={(props) => {
                  return (
                    <ContactRender
                      {...props}
                      handleNoAuth={handleNoAuth}
                      allowedActions={allowedActionsData}
                    />
                  );
                }}
              />
              <Route path="/contacts" component={(props)=>{
                return <ContactsRenderer {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <Route path="/call-logs" component={(props)=>{
                return <CallLogsRenderer {...props} handleNoAuth={handleNoAuth} allowedActions={allowedActionsData}/>
              }}
              />
              <Route path="/video" exact component={VideoRenderer} />
                <Route
                path="/new-assets/make-category/list"
                component={(props) => {
                  return <MakeCategoryRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
                />
                <Route
                path="/new-assets/make-category/details/:id"
                component={(props) => {
                  return <MakeCategoryView {...props} handleNoAuth={handleNoAuth}  />
                }}
                />

                <Route
                path="/new-assets/makes/list"
                component={(props) => {
                  return <MakesRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
                />
                <Route
                path="/new-assets/makes/create"
                component={(props) => {
                  return <NewMake {...props} handleNoAuth={handleNoAuth} />;
                }}
                />
                <Route
                path="/new-assets/makes/edit/:id"
                component={(props) => {
                  return <MakeEdit {...props} handleNoAuth={handleNoAuth} />;
                }}
                />
                 <Route
                path="/new-assets/makes/details/:id"
                component={(props) => {
                  return (
                    <MakeRenderer {...props} handleNoAuth={handleNoAuth} />
                  );
                }}
                />

                <Route
                path="/new-assets/model-groups/list"
                component={(props) => {
                  return <ModelGroupsRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
                />
                <Route
                path="/new-assets/model-groups/create"
                component={(props) => {
                  return (
                    <NewModelGroup {...props} handleNoAuth={handleNoAuth} />
                  );
                }}
                />
              <Route
                path="/new-assets/model-groups/details/:id"
                component={(props) => {
                  return (
                    <ModelGroupRenderer {...props} handleNoAuth={handleNoAuth} />
                  );
                }}
              />
                <Route
                path="/new-assets/model-groups/edit/:id"
                component={(props) => {
                  return (
                    <UpdateModelGroup {...props} handleNoAuth={handleNoAuth} />
                  )
                }}
                />

                <Route
                path="/new-assets/model-years/list"
                component={(props) => {
                  return <ModelYearsListRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
                />
                <Route
                path="/new-assets/model-years/create"
                component={(props) => {
                  return <ModelYearsAdd {...props} handleNoAuth={handleNoAuth}  />
                }}
                />
              <Route
                path="/new-assets/model-years/details/:id"
                component={(props) => {
                  return <ModelYearsHistoryRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
                path="/new-assets/model-years/edit/:id"
                component={(props) => {
                  return <ModelYearsUpdate {...props} handleNoAuth={handleNoAuth}  />
                }}
              />

              <Route
                path="/calculator/credit-tiers/list"
                component={(props) => {
                  return <CreditTiersRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
              path="/calculator/credit-tiers/create"
              component={(props) => {
                return <NewCreditTier {...props} handleNoAuth={handleNoAuth} />;
              }}
              />
              <Route
              path="/calculator/credit-tiers/edit/:id"
              component={(props) => {
                return <EditCreditTier {...props} handleNoAuth={handleNoAuth} />;
              }}
              />
              <Route
              path="/calculator/credit-tiers/details/:id"
              component={(props) => {
                return <CreditTierRenderer {...props} handleNoAuth={handleNoAuth}  />
              }}
              />
              <Route
                path="/dealership/:id/emails/:email_id"
                component={(props) => {
                  return <DealershipEmail {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route path="/video" exact component={VideoRenderer} />
              <Route
                path="/lease-applications-stipulation"
                component={(props) => {
                  return <ApplicationStipulationsRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
                path="/lease_applications_stipulation/:id"
                component={(props) => {
                  return <ApplicationStipulationRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
                path="/lease_applications_stipulation_edit/:id"
                component={(props) => {
                  return <UpdateApplicationStipulation {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
                path="/funding-delays-list"
                component={(props) => {
                  return <FundingDelaysRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
                path="/funding_delays/:id"
                component={(props) => {
                  return <FundingDelayRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
                path="/edit_funding_delays/:id"
                component={(props) => {
                  return <FundingDelayRendererUpdate {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
                path="/lessee-list"
                component={(props) => {
                  return <LesseesRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
                path="/lessee/:id"
                component={(props) => {
                  return <LesseeRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
                path="/lessee_edit/:id"
                component={(props) => {
                  return <UpdateLesseeRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
                path="/lease-document-request"
                component={(props) => {
                  return <DocumentRequestsRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
                path="/lease_document_request/:id"
                component={(props) => {
                  return <DocumentRequestRenderer {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route
                path="/lease_document_request_edit/:id"
                component={(props) => {
                  return <UpdateDocumentRequest {...props} handleNoAuth={handleNoAuth}  />
                }}
              />
              <Route path="/" component={()=>{
                  window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=/admins/lease_applications`;
                  return '';
                }}
              />
            </Switch>
          }
        </BrowserRouter>
      </>
    );
}