import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Col, message, Row, Select } from 'antd';
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, sortableKeyboardCoordinates, SortableContext } from "@dnd-kit/sortable";
import { GET, PUT } from '../../../util/network';
import logger from '../../../util/logger';
import { Item, SortableItem } from "../../../components/Draggable/SortableItem";

const { Option } = Select;

export default function LeaseCalculator(props) {
  const { allowedGet, allowedUpdate, handleNoAuth } = props;
  const history = useHistory();

  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);

  const [activeId, setActiveId] = useState(null);
  const [activeValue, setActiveValue] = useState(null);

  const [initialLoad, setInitialLoad] = useState(null);

  const getMakelist = async () => {
    if (allowedGet) {
      try {
        let data = await GET('/api/v1/makes');
        setMakeList(data.data.makes);
      } catch (e) {      
        logger.error('Request Error', e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error getting make list');
        }
      }
    }
  }

  const getModelList = async (val) => {
    if (allowedGet) {
      try {
        let data = await GET(`/api/v1/model-groups?make_id=${val}`);
        
        setModelList(data.data.model_groups);
      } catch (e) {
        logger.error('Request Error', e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error getting model list');
        }
      }
    }
  }

  const makeSelect = (val) => {
    getModelList(val);
  }

  const updateModelList = async (data) => {
    if (allowedUpdate) {
      try {
        data = data.map(({ id }, index) => ({ id: id, sortIndex: index+1 }));
        
        await PUT(`/api/v1/model-groups/sort-order`, { 'modelGroups': data });
      } catch (e) {
        logger.error('Request Error', e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error updating sort order');
        }   
      }
    }
  }

  const handleDragStart = (event) => {
    let modelName;
    setActiveId(event.active.id);

    const activeModel = modelList.filter(obj => {
      return obj.id === event.active.id
    })

    if (activeModel && activeModel[0]) {
      modelName = activeModel[0].name;
    }

    if (modelName) {
      setActiveValue(modelName);
    }
  }

  const handleDragEnd = (event) => {
    const {active, over} = event;
    
    if (active.id !== over.id) {
      const oldIndex = modelList.map((model) => model.id).indexOf(active.id);
      const newIndex = modelList.map((model) => model.id).indexOf(over.id);

      updateModelList(arrayMove(modelList, oldIndex, newIndex));

      setModelList((modelList) => {
        return arrayMove(modelList, oldIndex, newIndex);
      });
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  useEffect(() => {
    if (!initialLoad) {
      getMakelist();
      
      setInitialLoad(true);
    }
  })

  return (
    <div className='lease-calculator'>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Card title='Model Group Sorting'>            
            <Row className='flex-jc-fs'>
              <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                <Select 
                  className='settings-content-select' 
                  placeholder='Please Select' 
                  onSelect={makeSelect}
                  style={{marginBottom: '5px'}}
                >
                  {makeList && makeList.map((make, index) => {
                    return (
                      <Option key={index} value={make.id} className='settings-content-option'>{make.name}</Option>
                    )
                  })}
                </Select>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                >
                    <SortableContext 
                      items={modelList.map((item) => item.id)}
                      strategy={rectSortingStrategy}
                    >
                      {
                        modelList.map((item) => 
                          <SortableItem 
                            key={item.id} 
                            id={item.id} 
                            value={item.name} 
                            width={'100%'}
                          /> 
                        )
                      }
                    </SortableContext>
                  <DragOverlay>{activeId ? <Item id={activeId} value={activeValue}/> : null}</DragOverlay>
                </DndContext>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  )
}