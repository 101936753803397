import React from 'react'
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/system';
import { updateFilterState} from '../../../../reducers/LeaseApplications'
import { useDispatch } from "react-redux";

export default function CoLesseeGroup(props) {
    const { filterState, newFilterState, setNewFilterState } = props;

    return (
        <>
            <ListItem >
                <StyledTextField
                    label="Co-Lessee"
                    id="outlined-size-small"
                    size="small"
                    value={newFilterState.colesseeFullName}
                    onChange={(event, value) => {
                      setNewFilterState({ ...newFilterState, colesseeFullName: event.target.value })
                    }}
                />
            </ListItem>
            <ListItem >
                <StyledTextField
                    label="First Name"
                    id="outlined-size-small"
                    size="small"
                    value={newFilterState.colesseeFirstName}
                    onChange={(event, value) => {
                      setNewFilterState({ ...newFilterState, colesseeFirstName: event.target.value })
                    }}
                />
            </ListItem>
            <ListItem >
                <StyledTextField
                    label="Last Name"
                    id="outlined-size-small"
                    size="small"
                    value={newFilterState.colesseeLastName}
                    onChange={(event, value) => {
                      setNewFilterState({ ...newFilterState, colesseeLastName: event.target.value })
                    }}
                />
            </ListItem>
            <ListItem >
                <StyledTextField
                    label="Co-Lessee SSN"
                    id="outlined-size-small"
                    size="small"
                    value={newFilterState.colesseeSsn}
                    onChange={(event, value) => {
                      setNewFilterState({ ...newFilterState, colesseeSsn: event.target.value })

                    }}
                />
            </ListItem>
        </>
    )
}

const StyledTextField = styled(TextField)({
    width: 350,
    marginLeft: "1px"
})
