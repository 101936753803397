import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    pageState: {
        total: 0,
        page: 0,
        search: "",
        per_page: 25,
        lease_app_id: '',
        applicant_name: '',
        applicant_status: '',
        report_category: '',
        dealership_id: null,
        submission_start_date: '',
        submission_end_date: '',
        filter_flag: false
    },
    isLoading: false,
}
export const timerReportSlice = createSlice({
    name: "timerReport",
    initialState: initialState,
    reducers: {
        setAverageTimeData: (state, action) => {
            state.isLoading = false;
            state.leaseApplications = action.payload.data;
            state.avgTimerData = action.payload.avgTimer;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload.loading;
        }
    },
});

export const { setAverageTimeData, setIsLoading} = timerReportSlice.actions;
export default timerReportSlice.reducer;  