import React from "react";
import { styled } from '@mui/system';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PublishIcon from '@mui/icons-material/Publish';
import CloseIcon from '@mui/icons-material/Close';

const StyledAddIcon = styled((props) => (
  <NoteAddIcon
    {...props}
  />
))(({ theme }) => ({
  [theme.breakpoints.up(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.down(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.up(1580)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
}))

const StyledCheckBoxSharpIcon = styled((props) => (
  <CheckBoxSharpIcon
    {...props}
  />
))(({ theme }) => ({
  [theme.breakpoints.up(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.down(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.up(1580)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
}))

const StyledCheckCircleIcon = styled((props) => (
  <CheckCircleIcon
    {...props}
  />
))(({ theme }) => ({
  [theme.breakpoints.up(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.down(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.up(1580)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
}))

const StyledDoneIcon = styled((props) => (
  <NoteAltIcon
    {...props}
  />
))(({ theme }) => ({
  [theme.breakpoints.up(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.down(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.up(1580)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
}))

const StyledDoDisturbIcon = styled((props) => (
  <DoDisturbIcon
    {...props}
  />
))(({ theme }) => ({
  [theme.breakpoints.up(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.down(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.up(1580)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
}))

const StyledCloseIcon = styled((props) => (
  <CloseIcon
    {...props}
  />
))(({ theme }) => ({
  [theme.breakpoints.up(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.down(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.up(1580)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
}))

const StyledCreditScoreIcon = styled((props) => (
  <CreditScoreIcon
    {...props}
  />
))(({ theme }) => ({
  [theme.breakpoints.up(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.down(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.up(1580)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
}))

const StyledPublishIcon = styled((props) => (
  <PublishIcon
    {...props}
  />
))(({ theme }) => ({
  [theme.breakpoints.up(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.down(1025)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
  [theme.breakpoints.up(1580)]: {
    position: "relative",
    left: "auto",
    float: 'right'
  },
}))



export {
  StyledAddIcon,
  StyledCheckBoxSharpIcon,
  StyledCheckCircleIcon,
  StyledDoneIcon,
  StyledDoDisturbIcon,
  StyledCreditScoreIcon,
  StyledPublishIcon,
  StyledCloseIcon,
};
