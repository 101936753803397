import React, { useState, useEffect } from "react";
import {Card, Row, Col, Button, message, Input, Typography} from 'antd'
import HistoricalRelated from '../HistoricalRelated'
import {GET} from "../../../util/network";
import logger from "../../../util/logger";
import { clearSsnChanged } from '../../../reducers/LeaseApplications'
import {useDispatch, useSelector} from "react-redux";
import LicenseRelated from '../LicenseRelated'
import {Loading3QuartersOutlined} from "@ant-design/icons";
import RelatedAppsSearch from "./RelatedAppsSearch";
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";

const { Search } = Input;
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    p: 4,
};

export default function HistoricalRelatedCard(props) {
    const { data, handleNoAuth, setLoading, save, relatedData, relatedPageState, setRelatedPageState, getRelatedData, setRelatedData } = props;
    const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
    const isCreditUser = useSelector((state) => state.leaseApplications.isCreditUser);
    let loggedInUserId = null;
    if(authData !== null) {
        loggedInUserId = authData.id;
    }
    const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
    const disableFlag = !(isCreditUser && locked_by == loggedInUserId);
    const dispatch = useDispatch();
    const [searchVal, setSearchVal] = useState('')
    const [licenseSearchResults, setLicenseSearchResults] = useState([])
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
      if(searchVal.length == 0){
        handleSearch();
      }
    }, [searchVal]);

    const handleRepullLesseeCredit = async (lessee_id, type) => {
        setLoading(true);
        try {
            await GET(`/api/v1/lease_applications/repull-lessee-credit?lessee_id=${lessee_id}`);
            message.success("Credit Reports Will Be Repulled and Appear as a File Attachment Shortly");
            let values = {}
            if(type === 'lessee') {
                values.lessee_attributes = {
                    id: data.lessee.id,
                    ssn_changed: false
                }
            }
            else if (type === 'colessee'){
                values.colessee_attributes = {
                    id: data.colessee.id,
                    ssn_changed: false
                }
            }
            await save(values)
            dispatch(clearSsnChanged({ type: type }))
        } catch (e) {
            logger.error("Error processing Credit Reports", e);

            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history);
            } else {
                message.error('Error processing credit reports');
            }
        }
        setLoading(false);
    }

    const handleSearch = async () => {
      setLoading(true);
      try {
        const response = await GET(`/api/v1/lease_applications/license-search?license_search=${searchVal}`);
        setLicenseSearchResults(response.data.data)
      } catch (e) {
        logger.error("Error processing License Search", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error processing License Search');
        }
      }
      setLoading(false);
    }

  return <>
    <Card title="Historical / Related Applications" className={relatedData?.data?.length > 0 ? "historical-related-table-bordered" : ""}>
      <Row style={{marginBottom: '10px'}}>
          <Col span={24}>
              <Button
                  type='primary'
                  onClick={handleOpen}
                  style={{ float: 'right' }}
                  disabled={disableFlag}
              >
                  Historical search
              </Button>
          </Col>
      </Row>
      <Row style={{marginBottom: '5px'}}>
          <Col span={20}>
              <Typography.Title level={4}>
                  Related Data
              </Typography.Title>
          </Col>
          <Col span={4}>
              <Button type='primary' onClick={() => {
                  getRelatedData()
              }} style={{ float: 'right' }} disabled={disableFlag}>
                  <Loading3QuartersOutlined/>Refresh
              </Button>
          </Col>
      </Row>
      <Row>
        <Col span={24}>
          {relatedData.data &&
            <HistoricalRelated fromModal={false} data={relatedData.data} total={relatedData.count} pageState={relatedPageState}
                               setPageState={setRelatedPageState} checkboxSelection={false} />}
        </Col>
      </Row>
      <Typography.Title level={4}>
        License Related Applications
      </Typography.Title>
      <Row>
        <Col span={24}>
          {data && ( <LicenseRelated data={data.historicalLicenseRelated} />)}
        </Col>
      </Row>
      {
        data.historicalLicenseRelated.length == 0 && <br/>
      }
        <Row>
          <Col span={8}>
            <Search placeholder="License Search" onChange={(e) => setSearchVal(e.target.value)} onSearch={handleSearch} allowClear enterButton/>
          </Col>
          <Col span={16}>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col span={24}>
            {
              licenseSearchResults.length > 0 && <LicenseRelated data={licenseSearchResults} disableFlag={disableFlag}/>
            }
          </Col>
        </Row>
      <Row className='flex-jc-fs'>
          {
              data.creditStatus !== 'Unsubmitted' && relatedData?.data?.length > 0 && data.lessee.ssnChanged && data.showCbcLesseeRepull &&
            <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                <Button
                  className='slc-button-link-only'
                  type="link"
                  onClick={ () => { handleRepullLesseeCredit(data.lessee.id, 'lessee') } }
                  disabled={disableFlag}
                >
                    Repull Lessee Credit
                </Button>
            </Col>
          }
          {
              data.colessee && data.creditStatus !== 'Unsubmitted' && relatedData?.data?.length > 0 && data.colessee.ssnChanged && data.showCbcColesseeRepull &&
            <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                <Button
                  className='slc-button-link-only'
                  type="link"
                  onClick={ () => { handleRepullLesseeCredit(data.colessee.id, 'colessee') } }
                  disabled={disableFlag}
                >
                    Repull Co-Lessee Credit
                </Button>
            </Col>
          }
        </Row>
    </Card>
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
      >
          <Box sx={style}>
              <RelatedAppsSearch data={data} setRelatedData={setRelatedData} handleNoAuth={handleNoAuth} handleClose={handleClose}/>
          </Box>
      </Modal>
  </>
}
