import React from 'react'
import {Button, Col, Select, Upload, Form, Input, Row} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import "../../../App.css";

const Option = Select.Option;
export default function TrainingForm(props) {
  const {allowedUpdate, layout, form, handleSubmit, getFile, getThumbnail, thumbnailErrorMessage, fileErrorMessage, dealerShips} = props
  return (
    <>

      <Form
        {...layout}
        id='banner-message'
        form={form}
        colon={false}
        onFinish={handleSubmit}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={20} xl={20}>
            <Row className='flex-jc-fs'>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label='Title'
                  name='title'
                  hasFeedback
                  rules={[{
                    required: true,
                    message: 'Title is required'
                  }]}
                >
                  <Input/>
                </Form.Item>
              </Col>
            </Row>
            <Row className='flex-jc-fs'>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  label="Dealers"
                  name="dealer"
                  rules={[{
                    required: true,
                    message: 'Dealers is required'
                  }]}
                >
                  <Select
                    allowClear
                    mode="multiple"
                    placeholder="Select  dealership"
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    }
                    showSearch
                  >
                    <Option key={0} value={0}>{"All"}</Option>
                    {dealerShips && dealerShips.map(option => (
                      <Option key={option.id} value={option.id}>{option.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className='flex-jc-fs'>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Row className='flex-jc-fs'>
                  <Form.Item
                    label="Thumbnail"
                    name='thumbnail'
                    getValueFromEvent={getThumbnail}
                    rules={[{
                      required: true,
                      message: 'Thumbnail is required.'
                    }]}
                  >
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      maxCount={1}
                      accept="image/png, image/jpeg, image/jpg"
                      className="upload-list-inline"
                    >
                      <Button icon={<UploadOutlined/>}>Upload Thumbnail</Button>
                    </Upload>
                  </Form.Item>
                </Row>
                {thumbnailErrorMessage !== '' && <p style={{color: 'red'}}>{thumbnailErrorMessage}</p>}
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                <Row className='flex-jc-fs'>
                  <Form.Item
                    label="File"
                    name='file'
                    getValueFromEvent={getFile}
                    rules={[{
                      required: true,
                      message: 'File is required.'
                    }]}
                  >
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      maxCount={1}
                      accept=".pdf, .mp4, .webm, .wmv, .mov, .mkv, .flv"
                      className="upload-list-inline"
                    >
                      <Button icon={<UploadOutlined/>}>Upload File</Button>
                    </Upload>
                  </Form.Item>
                </Row>
                {fileErrorMessage !== '' && <p style={{color: 'red'}}>{fileErrorMessage}</p>}
              </Col>
            </Row>
            {allowedUpdate &&
              <Row className='flex-jc-fs'>
                <Col xs={24} sm={24} md={24} lg={8} xl={6}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            }
          </Col>
        </Row>
      </Form>

    </>
  )
}
