import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Col, message, Row, Space, Switch, Table, Form, Card, Input, Upload, Layout, Spin, Modal } from "antd";
import { CloseOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { PUT, GET, POST, DELETE } from '../../util/network';
import logger from '../../util/logger';
import AttachmentQuickEdit from './components/AttachmentsQuickEdit'
import { insertLeaseAttachment, deleteLeaseAttachment } from '../../reducers/LeaseApplications'
import { useDispatch } from "react-redux";
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';

export default function Attachments({ data, appNumber, handleNoAuth, disableFlag }) {
  const [loading, setLoading] = useState(false)
  const history = useHistory();
  const dataSource = data ? data.leaseApplicationAttachments : []

  const [expandedRowKeys, setExpandedRowKeys] = useState([])

  const [form] = Form.useForm()
  const [uploadValidateStatus, setUploadValidateStatus] = useState(undefined)
  const [uploadErrorMsg, setUploadErrorMsg] = useState(undefined)

  const { Content } = Layout
  const { TextArea } = Input
  const { Dragger } = Upload

  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [docIssuedErrors, setDocIssuedErrors] = useState([]);
  const [docIssuedPassed, setDocIssuedPassed] = useState([]);


  const formLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'filename',
      key: 'filename'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Uploader',
      dataIndex: 'uploader',
      key: 'uploader'
    },
    {
      title: 'Visible to dealership',
      dataIndex: 'is_visible_to_dealership',
      key: 'is_visible_to_dealership',
      render(val, row) {
        return val == null ? null : <Switch onChange={() => handleVisibility(row.id)} disabled={disableFlag} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} defaultChecked={val} />
      }
    },
    {
      title: 'Is Credit Notified',
      dataIndex: 'is_credit_notifiable',
      key: 'is_credit_notifiable',
      render(val, row) {
        return val === true ? 'True' : 'False';
      }
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at'
    },
    {
      title: 'Dealership Actions',
      dataIndex: 'send_dealership_link',
      key: 'send_dealership_link',
      render(val, row) {
        return <>

          {
            (row.description && row.description.includes("Lease Package")) &&
            <Button onClick={() => handleSendToDealership(row.id)} type="link" disabled={disableFlag}> Send to Dealership </Button>
          }
        </>
      }
    },
    {
      title: 'Actions',
      dataIndex: 'download_send_dealership_link',
      key: 'download_send_dealership_link',
      render(val, row) {
        return <>
          {
            val
              ?
              <a target="_blank" rel="noopener noreferrer" href={val.download_url}>Download</a>
              :
              null
          }
          {
            <Button type="link" onClick={() => handleExpand(row.id)} disabled={disableFlag}> Edit </Button>
          }
          {
            <Button type="link" onClick={() => handleDeleteAttachment(row.id)} disabled={disableFlag}><DeleteOutlined style={{ color: 'red' }} /></Button>
          }
        </>
      }
    },
  ];

  const handleExpand = (rowKey) => {
    if (expandedRowKeys.includes(rowKey)) {
      setExpandedRowKeys(expandedRowKeys.filter(row => row !== rowKey))
    } else {
      setExpandedRowKeys([...expandedRowKeys, rowKey])
    }
  }

  const handleSendToDealership = async (id) => {
    try {
      await POST(`/api/v1/lease_application_attachments/mail-attachment-to-dealership?id=${id}`);
      message.success("The attachment will now be sent to the dealership");
    } catch (e) {
      logger.error("Error while saving your data", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while saving your data');
      }
    }
  }

  const handleVisibility = async (id) => {
    try {
      await PUT(`/api/v1/lease_application_attachments/toggle-visibility?id=${id}`);
      message.success("Data saved successfully");
    } catch (e) {
      logger.error("Error while saving your data", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while saving your data');
      }
    }
  }

  const handleGenerateLeasePackage = async () => {
    try {
      setLoading(true)
      const response = await GET(`/api/v1/lease_applications/generate-lease-package?id=${data.id}`);
      message.success('Generating Lease Package. Please wait...');
      dispatch(insertLeaseAttachment(response?.data?.data))
      message.success(response?.data.message);
      setLoading(false)
    } catch (e) {
      setLoading(false)
      logger.error("Error processing Lease Package", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else if(e && e.response && e.response.status === 400){
        setDocIssuedPassed(e.response?.data?.passed_message)
        setDocIssuedErrors(e.response?.data?.message);
        handleModal()
      } else {
        message.error('Error processing Lease Package');
      }
    }
  }

  const handleResendFundingRequest = async (appNumber) => {
    try {
      await GET(`/api/v1/lease_applications/resend-funding-request?application_identifier=${appNumber}`);
      message.success("Your funding request has been submitted.");
    } catch (e) {
      logger.error("Error processing Funding Request", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error processing Funding Request');
      }
    }
  }

  const handleDeleteAttachment = async (id) => {

    Modal.confirm({
      title: "confirmation",
      content: "Are you sure to delete this attachment?",
      onOk: async () => {
        setLoading(true)
        try {
          const response = await DELETE(`/api/v1/lease_application_attachments/${id}`)
          if (response) {
            dispatch(deleteLeaseAttachment({ id: id }))
            message.success("Attachment has been deleted")
          }
        } catch (e) {
          logger.error('Error while deleting attachment')

          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error('Error while deleting attachment')
          }
        }
        setLoading(false)
      }
    })
  }

  const normFile = (event) => {
    if (Array.isArray(event)) {
      return event;
    }
    return event?.fileList;
  }

  const handleModal = () => {
    // setDocIssuedErrors(messages);
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const submitAttachment = async (values) => {
    if (!loading) { setLoading(true) }

    let formData = new FormData()

    formData.append("leaseApplicationId", `${data.id}`)
    formData.append("upload", values.upload[0].originFileObj)
    formData.append("visible_to_dealers", values.visible_to_dealers)
    formData.append("is_credit_notifiable", values.is_credit_notifiable)
    if (values.description) { formData.append("description", values.description) }
    if (values.notes) { formData.append("notes", values.notes) }
    try {
      await POST(`/api/v1/lease_application_attachments`, formData).then(response => {
        dispatch(insertLeaseAttachment(response?.data?.data))
        form.resetFields()
        // getAttachments()

        setLoading(false)
        message.success(response.data.message)
      }).catch(error => {
        logger.error("Error sending Attachment", error)
        setLoading(false)
      })
    } catch (e) {
      logger.error("Error sending Attachment", e)
      setLoading(false)
    }

  }

  // WARNING: For some reason FormInstance method `resetFields` doesn't work with Dragger.
  // Thus, manual validation. Since there's only one field required, this will do
  const handleOnFinish = async (values) => {
    if (values.upload && values.upload.length) {
      submitAttachment(values)
    }
  }

  // WARNING: `Upload`'s `onDrop` and `onRemove` events have conflict with `onChange`
  // Thus, we only use `onChange` to check if files were added / removed
  const handleDraggerOnChange = async (event) => {
    if (event.fileList && event.fileList.length) {
      setUploadValidateStatus(undefined)
      setUploadErrorMsg(undefined)
    } else {
      setUploadValidateStatus('error')
      setUploadErrorMsg('Please select a file to upload!')
    }
  }

  function createMarkup(error) {
    return { __html: error };
  }

  return (
    <>
      <Spin
        spinning={loading}
        size="large"
        tip="Loading..."
      >
        <Row>
          <Col span={24}>
            <Table
              className='slc-table'
              rowKey="id"
              columns={columns}
              dataSource={dataSource}
              pagination={true}
              size="small"
              expandedRowRender={(record) => <AttachmentQuickEdit setExpandedRowKeys={setExpandedRowKeys} record={record} data={data} />}
              expandedRowKeys={expandedRowKeys}
              expandIconColumnIndex={-1}
            />
          </Col>
        </Row>
        <Row className='flex-jc-fs'>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Space direction='vertical'>
              {
                (data && data.leaseDocumentRequests.length)
                  ?
                  <Button onClick={() => handleGenerateLeasePackage(appNumber)} type="link" disabled={disableFlag}>Generate Lease Package</Button>
                  :
                  ''
              }
              {
                (data && data.documentStatus === "funding_approved")
                  ?
                  <Button onClick={() => handleResendFundingRequest(appNumber)} type="link" disabled={disableFlag}>Resend Funding Request</Button>
                  :
                  ''
              }
            </Space>
          </Col>
        </Row>
        <Content>
          <Card type="inner" title="Add Attachment" className="card-form">
            <Form
              className="input"
              form={form}
              onFinish={handleOnFinish}
              {...formLayout}
              initialValues={{ visible_to_dealers: false, is_credit_notifiable: false }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24} lg={10} style={{ marginTop: '1em' }}>
                  <Form.Item
                    name="upload"
                    rules={[{ required: true, message: 'Please select a file to upload!' }]}
                    validateStatus={uploadValidateStatus}
                    help={uploadErrorMsg}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                  >
                    <Dragger
                      id="upload-zone"
                      maxCount={1}
                      onChange={handleDraggerOnChange}
                      beforeUpload={() => false}
                      disabled={disableFlag}
                    >
                      <div className="card-elements">
                        <div className="dragger">
                          <p className="ant-upload-drag-icon">
                          </p>
                          <p className="ant-upload-text">Click or drag file to this area to upload</p>
                          <p className="ant-upload-hint">
                            Support for a single upload only. Strictly prohibit from uploading company data or other band files
                          </p>
                        </div>
                      </div>
                    </Dragger>
                  </Form.Item>
                </Col>
                <Col span={24} lg={14}>
                  <Form.Item
                    name="description"
                    label="Description"
                  >
                    <Input disabled={disableFlag} />
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: '0em' }}
                    name="notes"
                    label="Notes"
                  >
                    <TextArea rows={2} disabled={disableFlag} />
                  </Form.Item>

                  <Row gutter={[24, 24]}>
                    <Col span={12} lg={12}>
                      <Form.Item
                        name="visible_to_dealers"
                        label="Visible To Dealers"
                        valuePropName="checked"
                      >
                        <Switch disabled={disableFlag} />
                      </Form.Item>
                    </Col>
                    <Col span={12} lg={12}>
                      <Form.Item
                        name="is_credit_notifiable"
                        label="Notify Credit?"
                        valuePropName="checked"
                      >
                        <Switch disabled={disableFlag} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item style={{ marginTop: '1em', textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" disabled={disableFlag}>
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          <Modal
                title="Document Issue Validations Errors. Please try again!"
                visible={modalOpen}
                centered
                onCancel={handleCloseModal}
                footer={[
                    <Button key="Cancel" onClick={handleCloseModal}>
                        OK
                    </Button>
                ]}
            >
              {
                  docIssuedPassed.length > 0 && (docIssuedPassed.map((passedMsg, i) => (
                    <div key={i} style={{display: "flex", lineHeight: "20px", FontSize: "14px", verticalAlign: "middle"}}>
                      <DoneIcon style={{ color: "#388e3c" }}/>
                      <div key={i} dangerouslySetInnerHTML={createMarkup(passedMsg)} />
                    </div>
                  )))
                }
                {
                  docIssuedErrors.length > 0 && (docIssuedErrors.map((error, i) => (
                    <div key={i} style={{display: "flex", lineHeight: "20px", FontSize: "14px", verticalAlign: "middle"}}>
                      <ClearIcon style={{ color: "#f44336" }}/>
                      <div key={i} dangerouslySetInnerHTML={createMarkup(error)} />
                    </div>
                  )))
                }
            </Modal>
        </Content>
      </Spin>
    </>
  );
}
