import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from '../util/network';

export const fetchPendingWelcomeCalls = createAsyncThunk('dashboard/pendingWelcomeCalls', async () => {
  let response = await GET('/api/v1/lease-application-pending-calls')
  return response.data
})

const initialState = {}

export const pendingWelcomeCallSlice = createSlice({
  name: "pendingWelcomeCall",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchPendingWelcomeCalls.fulfilled, (state, action) => {
      state = action.payload
      state.isLoading = false;
      return state
    }),
      builder.addCase(fetchPendingWelcomeCalls.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchPendingWelcomeCalls.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload;
      });
  }
});

export default pendingWelcomeCallSlice.reducer;
