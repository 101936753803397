import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Form, Input, InputNumber, Select, message, Popconfirm, Typography } from 'antd';
import { PUT, DELETE } from '../../../util/network';
import logger from '../../../util/logger';
import {useDispatch} from "react-redux";
import {deleteLesseeIncomeVerification } from '../../../reducers/LeaseApplications'
import {getDetails} from '../../../reducers/LeaseApplications'

const { Option } = Select;
const EditableContext = React.createContext();
let incomeTypes = [
  {
    optionName: "Gross",
    optionValue: 0,
  },
  {
    optionName: "Net",
    optionValue: 1,
  },
]

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  editable,
  children,
  dataIndex,
  record,
  data,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave(record, values, dataIndex);
    } catch (errInfo) {
      message.error('Save failed:', errInfo);
    }
  };

  const inputRenderer = (indexName) => {
    switch(indexName) {
      case 'incomeVerificationName':
        return (
          <Select ref={inputRef} onChange={save}>
            {
              data.incomeVerificationTypeOptions && data.incomeVerificationTypeOptions.map((obj, index) => {
                return <Option key={index} value={obj.optionValue}>{obj.optionName}</Option>
              })
            }
          </Select>
        )
      case 'employerClient':
        return <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      case 'grossIncomeCents':
        return <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} precision={2} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} />
      case 'incomeFrequencyName':
        return (
          <Select ref={inputRef} onChange={save}>
            {
              data.incomeFrequencyOptions && data.incomeFrequencyOptions.map((obj, index) => {
                return <Option key={index} value={obj.optionValue}>{obj.optionName}</Option>
              })
            }
          </Select>
        )
      case 'incomeType':
        return (
            <Select ref={inputRef} onChange={save}>
              {
                  incomeTypes && incomeTypes.map((obj, index) => {
                    return <Option key={index} value={obj.optionValue}>{obj.optionName}</Option>
                  })
              }
            </Select>
        )
      default:
        return <Input ref={inputRef} onPressEnter={save} onBlur={save} />
    }
  }

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
      >
        { inputRenderer(dataIndex) }
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};


export default function IncomeVerificationList({ data, lessee, ...props }) {
  const { allowedGet, allowedUpdate, handleNoAuth } = props;
  const history = useHistory();

  const dispatch = useDispatch();
  // const tierPct = data.calculator.tierPct
  const [dataSource, setDataSource] = useState([]);
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
    loggedInUserId = authData.id;
  }
  const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
  const disableFlag = locked_by != loggedInUserId ? true : false

  let verifications = lessee.incomeVerifications || []

  let ytdRow = {}
  if (verifications.length > 0) {
    let paystubs = verifications.filter(item => item.incomeVerificationName === 'Paystub')
    if (paystubs.length > 0) {
      ytdRow = paystubs[0]
    }

    if (Object.keys(ytdRow).length === 0) {
      let w2Verifications = verifications.filter(item => item.incomeVerificationName === 'W2')
      if (w2Verifications.length > 0) {
        ytdRow = w2Verifications[0]
      }
    }
  } 

  const handleDelete = async (record) => {
    try {
      await DELETE(`/api/v1/income-verifications/${record.id}?leaseApplicationId=${data.id}&lesseeId=${lessee.id}`);
      dispatch(deleteLesseeIncomeVerification({id: record.id}))
      message.success("Deleted Successfully");
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const keyTools = {
    incomeVerificationName: {
      transformKey: 'incomeVerificationTypeId',
      format: function(value) {
        return value
      }
    },
    otherType: {
      transformKey: 'otherType',
      format: function(value) {
        return value
      }
    },
    employerClient: {
      transformKey: 'employerClient',
      format: function(value) {
        return value
      }
    },
    grossIncomeCents: {
      transformKey: 'grossIncomeCents',
      format: function(value) {
        // Makes sure that whole number, with tenths and hundredths are submitted properly
        // If value have decimal
        if (value > Math.floor(value)) {
          // Ex. 349.01 -> 34901
          return Math.floor(value) + (value % 1).toFixed(2).substr(-2)
        } else {
          // Ex. 349 -> 34900
          return Math.floor(value) + "00"
        }
      }
    },
    incomeFrequencyName: {
      transformKey: 'incomeFrequencyId',
      format: function(value) {
        return value
      }
    },
    incomeType: {
      transformKey: 'incomeType',
      format: function(value) {
        return value;
      }
    }
  }

  const submit = async (id, params) => {
    if (allowedUpdate) {
      try {
        let response = await PUT(`/api/v1/income-verifications/${id}`, { leaseApplicationId: data.id, lesseeId: lessee.id, incomeVerification: params });
        if (response) {
          dispatch(getDetails(response.data.leaseApplication));
          message.success("Data saved successfully");
        }
      } catch (e) {
        logger.error("Income Verification Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while saving your data');
        }
      }
    }
  }

  const handleSave = (row, values, dataIndex) => {
    let params = {}
    params[keyTools[dataIndex].transformKey] = keyTools[dataIndex].format(values[dataIndex])
    if (dataIndex == "otherType" && row.incomeVerificationName.toLowerCase() != "other") {
      message.error("Can't update Specification if Type of Document is not \"Other\"");
    } else {
      submit(row.id, params)
    }
  };

  const columns = [
    {
      title: 'Type of Document',
      dataIndex: 'incomeVerificationName',
      key: 'incomeVerificationName',
      editable: !disableFlag
    },

    {
      title: 'Specification',
      dataIndex: 'otherType',
      key: 'otherType',
      editable: !disableFlag
    },

    {
      title: 'Employer / Client',
      dataIndex: 'employerClient',
      key: 'employerClient',
      editable: !disableFlag
    },
    {
      title: 'Average Income',
      dataIndex: 'grossIncomeCents',
      key: 'grossIncomeCents',
      editable: !disableFlag
    },
    {
      title: 'Income Type',
      dataIndex: 'incomeType',
      key: 'incomeType',
      editable: !disableFlag
    },
    {
      title: 'Frequency',
      dataIndex: 'incomeFrequencyName',
      key: 'incomeFrequencyName',
      editable: !disableFlag
    },
    // {
    //   title: 'File',
    //   dataIndex: 'file',
    //   key: 'file',
    //   editable: false,
    //   render : (val)=>{
    //     return (
    //         val.map((obj, index) => {
    //           return <a key={index} href={obj.url} target="_blank" rel="noopener noreferrer" >{obj.fileName} <br/> </a>
    //        })
    //     )
    //   }
    // },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      editable: !disableFlag
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      editable: !disableFlag
    },
    {
      title: '',
      key: 'delete',
      render(record) {
        return (
          <>
            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)} >
              <Typography.Link disabled={disableFlag}>Delete</Typography.Link>
            </Popconfirm>
          </>
        )
      }
    }
  ];


  const newColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        data: data,
        handleSave: handleSave,
      }),
    };
  });

  useEffect(() => {
    setDataSource(lessee ? lessee.incomeVerifications : [])
  }, [lessee]);

  return dataSource.length > 0 && allowedGet ? (
    <Table
      className='slc-table'
      rowKey = "id"
      components={components}
      rowClassName={() => 'editable-row'}
      dataSource={dataSource}
      ytdRow={ytdRow}
      columns={newColumns}
      bordered
      title={() => 'Income Verification List'}
      size="small"
    />
  ) : null;
}
