import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Col, Form, InputNumber, Result, Row, Select, Table } from 'antd';
import { GET, PUT, PATCH } from '../../../util/network';
import logger from '../../../util/logger';
import FinanceSettings from '../FinanceSettings/FinanceSettings'
import "./DealStructure.css";
import _ from 'lodash';

const Option = Select.Option;

const processData = (data)=> {
  if (!data) {
    return [];
  } else {
    let obj = {};

    data.forEach(({id,credit_tier_id,adjusted_cap_cost_lower_limit,adjusted_cap_cost_upper_limit,acquisition_fee_cents})=>{
      const uniqueId = `${adjusted_cap_cost_lower_limit}-${adjusted_cap_cost_upper_limit}`;

      if (obj[uniqueId]){
          obj[uniqueId][`tier${credit_tier_id}`] = acquisition_fee_cents/100;
      } else {
          obj[uniqueId] = {
              adjusted_cap_cost_lower_limit,
              adjusted_cap_cost_upper_limit,
              uniqueId
          };
          obj[uniqueId][`tier${credit_tier_id}`] = acquisition_fee_cents/100;
      }

      obj[uniqueId][`tier${credit_tier_id}id`] = id;
    });

    return Object.values(obj);
  }
}

const processTierData = (data, tiers) => {
  if (!data) {
    return [];
  } else {
    let obj = {};

    tiers.forEach((tier) => {
      const tierData = data.find((td=>(td.id===tier)));

      if(tierData){
        const uniqueId = `tier-${tierData.id}`
        obj = {...obj,[`tier${tier}`]:tierData,uniqueId}
      }
    })
    
    return [obj];
  }
}

export default (props) => {
  const { allowedGet, allowedUpdate, handleNoAuth } = props;

  const history = useHistory();

  const [makeList, setMakeList] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [data, setData] = useState([]);
  const [creditTiersData,setCreditTiersData] = useState([]);
  const [creditTiers,setCreditTiers] = useState([]);

  const [makes, setMakes] = useState(undefined);
  const [makesForPaymentLimit, setMakesForPaymentLimit] = useState(undefined);

  const [loadingForAcquisitionFee,setLoadingForAcquisitionFee] = useState(false);
  const [loadingForPaymentLimits,setLoadingForPaymentLimits] = useState(false);

  const [modelGroupsForAcquisitionFee,setModelGroupsForAcquisitionFee] = useState([]);
  const [modelGroupIdForAcquisitionFee,setModelGroupIdForAcquisitionFee] = useState(undefined);

  const [modelGroupsForPaymentLimits,setModelGroupsForPaymentLimits] = useState([]);
  const [modelGroupIdForPaymentLimits,setModelGroupIdForPaymentLimits] = useState(undefined);

  const sortModelGroups = (data) => {
    return data.sort((a, b) => (a.name > b.name) ? 1 : -1);
  }

  const getModelGroupsForAcquisitionFee = async (make) => {
    if (allowedGet) {
      setLoadingForAcquisitionFee(true);

      try {
        let data = await GET(`/api/v1/model-groups?make_id=${make}`);
        const sortedModelGroups = data.data.model_groups ? sortModelGroups(data.data.model_groups) : null;
        
        if (sortedModelGroups) {
          setModelGroupsForAcquisitionFee(sortedModelGroups);
        }        
      } catch (e) {
        logger.error('Error while getting data', e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        }
      }

      setLoadingForAcquisitionFee(false);
    }
  }

  const getModelGroupsForPaymentLimits = async (make) => {
    if (allowedGet) {
      setLoadingForPaymentLimits(true);

      try {
        let data = await GET(`/api/v1/model-groups?make_id=${make}`);
        const sortedModelGroups = data.data.model_groups ? sortModelGroups(data.data.model_groups) : null;

        if (sortedModelGroups) {
          setModelGroupsForPaymentLimits(data.data.model_groups);
        }
      } catch (e) {
        logger.error('Error while getting data', e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        }
      }

      setLoadingForPaymentLimits(false);
    }
  }

  const getMakes = async () => {
    if (allowedGet) {
      setLoadingForAcquisitionFee(true);

      try {
        let data = await GET('/api/v1/makes');
        setMakeList(data.data.makes);        
      } catch (e) {        
        setMakeList([]);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        }
      }

      setLoadingForAcquisitionFee(false);
    }
  }

  const getData = async (value, model_group_id) => {
    if (allowedGet) {
      setLoadingForAcquisitionFee(true);

      try {
        let response = await GET(`/api/v1/adjusted_capitalized_cost_ranges?make_id=${value}&model_group_id=${model_group_id}`);

        setData(processData(response.data.data));
        setTiers(response.data.credit_tiers);
      } catch (e) {
        logger.error('Error while getting data', e);
        setData([]);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        }
      }

      setLoadingForAcquisitionFee(false);
    }
  }

  const getcreditTiers = async (value, model_group_id) => {
    if (allowedGet) {
      setLoadingForPaymentLimits(true);

      try {
        let response = await GET(`/api/v1/credit_tiers?make_id=${value}&model_group_id=${model_group_id}`);
        setCreditTiers(response.data.credit_tiers) 
        setCreditTiersData(processTierData(response.data.data,response.data.credit_tiers));
      } catch (e) {
        logger.error('Error while getting data', e);
        setCreditTiersData([]);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        }
      }

      setLoadingForPaymentLimits(false);
    }
  }

  const updateValue = async (value,id)=> {      
    if (allowedUpdate) {
      try {
        await PATCH(`/api/v1/adjusted_capitalized_cost_ranges/${id}?make_id=${makes}`,{
          acquisition_fee_cents : parseFloat(value)*100
        });
      } catch (e) {
        logger.error('Error while updating data', e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        }
      }
    }
  }

  const updateTierValue = async (value,id)=> {
    if (allowedUpdate) {
      try {
        await PUT(`/api/v1/credit_tiers/${id}`,{
          payment_limit_percentage : parseFloat(value)
        });
      } catch (e) {
        logger.error('Error while updating data', e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        }
      }
    }
  }

  const columns = [
    {
      title: 'ACC - F&I - AF',
      dataIndex: 'uniqueId',
      key: 'uniqueId',
      width: 150,
      fixed: 'left',
      render : (val,{adjusted_cap_cost_lower_limit,adjusted_cap_cost_upper_limit})=>{
        return  `$${adjusted_cap_cost_lower_limit/100} ${adjusted_cap_cost_upper_limit===0?'+':'- $'+(adjusted_cap_cost_upper_limit/100).toString()}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
    },
    ...tiers.map((val, index) => {
      return (
        {
          title: 'Tier ' + (index + 1),
          dataIndex: 'tier' + val,
          key: 'tier' + val,
          render(fieldVal, row) {
            return (<InputNumber onChange = {_.debounce((e)=>{updateValue(e,row[`tier${val}id`])},500)} className="number-input" defaultValue={fieldVal} formatter={value => '$ ' + value} placeholder={'Tier ' + index} />)
          }
        }
      )
    })
  ];

  const columns2 = [
    ...creditTiers.map((val, index) => {
    return (
        {
          title: 'Tier ' + (index + 1),
          dataIndex: 'tier'+val,
          key: 'tier'+val,
          render(fieldVal, row) {
            let id = 0;
            let payment_limit_percentage = "0.0";
            if (row[`tier${val}`]) {
              const data =  row[`tier${val}`];
              id = data.id;
              payment_limit_percentage = data.payment_limit_percentage
            }
            return (<InputNumber onChange = {_.debounce((e)=>{updateTierValue(e,id)},500)} className="number-input" defaultValue={payment_limit_percentage} formatter={value => value+ ' %'} placeholder={'Tier ' + index} />)
          }
        }
      )
    })
  ];

  useEffect(() => {
      getMakes();
  }, []);

  return allowedGet ? (    
    <div id='deal-structure' className='deal-structure'>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Card id='acquisition-fee-card' title='Acquisition Fee'>            
            <Row className='flex-jc-fs'>
              <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                <Form layout='inline' colon={false} className='make-form'>
                  <Form.Item style={{ width: '100%' }}>
                    <Select 
                      onSelect={(value) => { 
                        setMakes(value)
                        setModelGroupIdForAcquisitionFee(undefined);
                        setData([]);
                        setTiers([]);
                        getModelGroupsForAcquisitionFee(value) 
                      }} 
                      value={makes} 
                      style={{ marginBottom: 10 }} 
                      placeholder='Select a make' >
                        {makeList && makeList.map((make) => {
                          return (
                            <Option key = {Math.random()} value={make.id}>{make.name}</Option>
                          )
                        })}
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row className='flex-jc-fs'>
              <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                <Form layout='inline' colon={false} className='make-form'>
                  <Form.Item style={{ width: '100%' }}>
                    <Select 
                      onSelect={(model_group_id) => { 
                        setModelGroupIdForAcquisitionFee(model_group_id); 
                        getData(makes, model_group_id); 
                      }} 
                      value={modelGroupIdForAcquisitionFee} 
                      style={{ marginBottom: 10 }} 
                      placeholder='Select a model' >
                        {modelGroupsForAcquisitionFee && modelGroupsForAcquisitionFee.map((model) => {
                          return (
                            <Option key = {Math.random()} value={model.id}>{model.name}</Option>
                          )
                        })}
                    </Select>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table 
                  className='slc-table'
                  bordered loading={loadingForAcquisitionFee} 
                  rowKey="uniqueId" 
                  dataSource={data} 
                  columns={columns} 
                  pagination={false} 
                  scroll={{ x: 600 }} 
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row gutter={[24, 24]} className='mt-20'>
        <Col span={24}>
          <FinanceSettings makeList={makeList} />
        </Col>
      </Row>

      <Row gutter={[24, 24]} className='mt-20'>
        <Col span={24}>
          <Card id='payment-limits-card' title='Payment Limits'>            
            <p className='sub-title'>Maximum Percentage of Monthly Income Allowed for Lease Payment</p>
            <Form layout="inline" colon={false} className="make-form">
              <Form.Item>
                <Select 
                  onSelect={(value) => { 
                    setMakesForPaymentLimit(value)
                    setModelGroupIdForPaymentLimits(undefined);
                    setCreditTiers([]);
                    setCreditTiersData([]);
                    getModelGroupsForPaymentLimits(value);
                  }} 
                  value={makesForPaymentLimit} 
                  style={{ width: 250, marginBottom: 10 }} 
                  placeholder='Select a make' >
                    {makeList && makeList.map((make) => {
                      return (
                        <Option key = {Math.random()} value={make.id}>{make.name}</Option>
                      )
                    })}
                </Select>
              </Form.Item>
            </Form>
            <Form layout="inline" colon={false} className="make-form">
              <Form.Item>
                <Select 
                  onSelect={(model_group_id) => { 
                    setModelGroupIdForPaymentLimits(model_group_id)
                    getcreditTiers(makesForPaymentLimit, model_group_id)
                  }} 
                  value={modelGroupIdForPaymentLimits} 
                  style={{ width: 250, marginBottom: 10 }} 
                  placeholder='Select a model' >
                    {modelGroupsForPaymentLimits && modelGroupsForPaymentLimits.map((model) => {
                      return (
                        <Option key = {Math.random()} value={model.id}>{model.name}</Option>
                      )
                    })}
                </Select>
              </Form.Item>
            </Form>
            <Table 
              className='slc-table'
              bordered loading={loadingForPaymentLimits}
              rowKey="uniqueId"
              dataSource={creditTiersData}
              columns={columns2}
              pagination={false}
              scroll={{ x: 600 }} 
            />
          </Card>
        </Col>
      </Row>
    </div>
  ) : <Result 
        status='warning'
        title='You are not allowed to access this data.'
      /> 
}