import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, Layout, Menu, Collapse, message } from 'antd';
import Icon,{ RocketOutlined } from '@ant-design/icons';
import { POST } from '../../util/network'
import MainHeader from '../../components/layouts/MainHeader';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import MainFooter from '../../components/layouts/MainFooter';
import { SiderProps } from '../../components/layouts/MainSiderProps';
import { MagnifyingGlassSvg } from '../../components/layouts/Svg';
import CustomSelect from './components/CustomSelect';
import CustomRadio from './components/CustomRadio';
import logger from '../../util/logger';

const { Content, Sider } = Layout;
const { Panel } = Collapse;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function Workflow({ adminUserData, handleNoAuth, workflowSettingValues }) {
  const history = useHistory();

  const [activeMenu, setActiveMenu] = useState([1]);
  const adminUsers = adminUserData;
  const {
      salesDefaults, 
      dealershipUnderwritingDefaults, 
      creditCommitteeDefaults, 
      notifyDealershipUnderwriting, 
      notifyCreditCommittee,
      underwritingDefaults,
      notifyUnderwriting 
    } = workflowSettingValues;

  const radioOptions = [
    { optionValue: '1', optionLabel: 'Yes'  },
    { optionValue: '0', optionLabel: 'No'  },
  ];


  const createApprovers = async (values, settings) => {
    try {      
        await POST(`/api/v1/workflow-setting-values`, {
          settings: settings,
          adminUserId: values
        });
        message.success("Successfully Saved");
      } catch (e) {
        logger.error("Saving Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error saving your data');
        }
      }
  }


  const handleSalesApprovers = (value) => {
      createApprovers(value, 'sales')
  }

  const handleDealershipUnderwritingApprovers = (value) => {
    createApprovers(value, 'dealership_underwriting')
  }

  const handleCreditCommitteeApprovers = (value) => {
    createApprovers(value, 'dealership_credit_committee')
  }

  const handleNotifyDealershipUnderwriting = (e) => {
     if (e.target.value === '1'){
        createApprovers([], 'notify_dealership_underwriting')
     }else{
        createApprovers([], 'not_notify_dealership_underwriting')
     }
  }

  const handleNotifyCreditCommittee= (e) => {
    if (e.target.value === '1'){
        createApprovers([], 'notify_dealership_credit_committee')
     }else{
        createApprovers([], 'not_notify_dealership_credit_committee')
     }
  }

  const handleUnderwritingReviewers = (value) => {
    createApprovers(value, 'underwriting')
  }

  const handleNotifyUnderwriting= (e) => {
    if (e.target.value === '1'){
        createApprovers([], 'notify_underwriting')
     }else{
        createApprovers([], 'not_notify_underwriting')
     }
  }


  return (
    <Layout className="layout">
      <MainHeader activePage="workflow"/>
      <Layout name='Workflow'>
        <Sider {...SiderProps} >
          <Menu
            defaultSelectedKeys={[1]}
            selectedKeys={[activeMenu.toString()]}
            style={{ width: '100%' }}
            onSelect={({ key }) => { if (!key.includes("sub")){ setActiveMenu([...activeMenu, key]); } }}
            >
            <Menu.Item key="1" > <RocketOutlined /> Dealership</Menu.Item>
            <Menu.Item key="2" > <Icon component={MagnifyingGlassSvg} /> Underwriting</Menu.Item>
          </Menu>
        </Sider>

        <Layout id='content-area-layout'>
          <MainBreadcrumb items={
            [{ text: "Home", link_type: "linkto", link: "/dashboard" },
            { text: "Administration", link_type: "ahref", link: void(0)  },
            { text: "Workflow", link_type: "ahref", link: void(0) }]
            }
          />
  
          <Content id='main-content'>
            <Row gutter={[0, 24]} >
              <Col span={24} >
                <Form 
                    {...layout}  
                    colon={false}
                    scrollToFirstError={true}
                >

                <Row gutter={[0, 24]} >
                    <Col span={24} >
                      <Collapse accordion={false} onChange={(key) => { key !== undefined && setActiveMenu(key); }} activeKey={activeMenu}>
                        <Panel 
                          header="Dealership Onboarding" 
                          key="1"
                          className='slc-panel'
                        >
                          <CustomSelect 
                            label="Sales Approvers"
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            options={adminUsers}
                            defaultValue={salesDefaults}
                            disabled={false}
                            onChange={handleSalesApprovers}
                          />
                          <CustomSelect 
                            label="Underwriting Approvers"
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            options={adminUsers}
                            defaultValue={dealershipUnderwritingDefaults}
                            disabled={false}
                            onChange={handleDealershipUnderwritingApprovers}
                          />
                          <CustomRadio 
                            label="Notify Users of Pending Approval?"
                            mode="multiple"
                            style={{ width: '100%' }}
                            options={radioOptions}
                            defaultValue={notifyDealershipUnderwriting}
                            onChange={handleNotifyDealershipUnderwriting}
                          />
                          <CustomSelect 
                            label="Credit Committee Approvers"
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            options={adminUsers}
                            defaultValue={creditCommitteeDefaults}
                            disabled={false}
                            onChange={handleCreditCommitteeApprovers}
                          />
                          <CustomRadio 
                            label="Notify Users of Pending Approval?"
                            mode="multiple"
                            style={{ width: '100%' }}
                            options={radioOptions}
                            defaultValue={notifyCreditCommittee}
                            onChange={handleNotifyCreditCommittee}
                          />
                        </Panel>
                        <Panel 
                          header="Underwriting" 
                          key="2"
                          className='slc-panel'
                        >
                          <CustomSelect 
                            label="Underwriting Reviewers"
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            options={adminUsers}
                            defaultValue={underwritingDefaults}
                            disabled={false}
                            onChange={handleUnderwritingReviewers}
                          />
                          <CustomRadio 
                            label="Notify Users of Request Review?"
                            mode="multiple"
                            style={{ width: '100%' }}
                            options={radioOptions}
                            defaultValue={notifyUnderwriting}
                            onChange={handleNotifyUnderwriting}
                          />
                        </Panel>
                      </Collapse>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
      <MainFooter/>
    </Layout>
  );
}
