import React from "react";
import AdminUsers from "./AdminUsers";

import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';

export default function AdminUsersRenderer(props) {
  const { allowedActions } = props;

  const adminUserActions = GetResourceActions('AdminUser', allowedActions);
  const allowedGet = IsActionAllowed('get', adminUserActions);
  const allowedCreate = IsActionAllowed('create', adminUserActions);

  return (
      <AdminUsers
        allowedActions={adminUserActions}
        allowedGet={allowedGet}
        allowedCreate={allowedCreate}
      />
    ) 
}
