import React, {useState, useEffect} from "react";
import {message, Spin} from "antd";
import {GET} from "../../util/network";
import LesseesList from "./LesseesList";

export default function LesseesRenderer(props) {
  const {handleNoAuth} = props;
  const [loading, setLoading] = useState(false);
  const initialState = {
    page: 0,
    limit: 25,
    filter: false,
    search: '',
    name: '',
    start_date: '',
    end_date: '',
    mobile_number: '',
    home_number: '',
  }
  const [pageState, setPageState] = useState({...initialState});
  const [response, setResponse] = useState({data: [], total: 0});

  const getLessees = async () => {
    setLoading(true);
    let encodedParam = Object.entries(pageState).map(([key, val]) => `${key}=${val}`).join('&');
    try {
      const response = await GET(
        `/api/v1/lessees?` + encodedParam
      );
      setResponse(response?.data);
    } catch (e) {
      message.error("Error while loading Data.");
    }
    setLoading(false);
  };

  useEffect(() => {
    getLessees();
  }, [pageState]);

  return (
    <Spin spinning={loading}>
      <LesseesList
        data={response.data}
        loading={loading}
        initialState={initialState}
        pageState={pageState}
        setPageState={setPageState}
        total={response.total}
      />
    </Spin>
  );
}
