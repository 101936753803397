import React from "react";
import { Row, Col, Table } from "antd";
import { Link } from 'react-router-dom';

export default function WelcomeCallHistory({ data }) {
  const dataSource = data ? data.leaseApplicationWelcomeCalls : []
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
      loggedInUserId = authData.id;
  }
  const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
  const disableFlag = locked_by != loggedInUserId ? true : false

  let columns = [
     {
         title: 'Status',
         dataIndex: 'status',
         key: 'status'
     },
     {
         title: 'Type',
         dataIndex: 'type',
         key: 'type'
     },
     {
         title: 'Result',
         dataIndex: 'result',
         key: 'result'
     },
     {
         title: 'Representative',
         dataIndex: 'representative',
         key: 'representative'
     },
     {
         title: 'Date and Time',
         dataIndex: 'dateAndTime',
         key: 'dateAndTime'
     },
     {
         title: 'Action',
         dataIndex: 'action',
         key: 'action',
         render(val, row) {
             if(!disableFlag){
                 return <Link to={`/lease_application_welcome_calls/${row.id}`}>View</Link>
             }
         }
      }
  ];

  return (
    <Row>
      <Col span={24}>
        <Table className='slc-table'
          rowKey="id" 
          columns={columns} 
          dataSource={dataSource} 
          size="small" 
          pagination={true} 
        />
      </Col>
    </Row>
  );
}