import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Checkbox,
  DatePicker,
  Form,
  Layout,
  Menu,
  message,
  Row,
  Typography,
  Spin,
} from "antd";
import Icon from "@ant-design/icons";
import moment from "moment";
import {GET, PUT} from "../../util/network";
import logger from "../../util/logger";
import MainHeader from "../../components/layouts/MainHeader";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import MainFooter from "../../components/layouts/MainFooter";
import {SiderProps} from "../../components/layouts/MainSiderProps";
import {AddNew} from "../../components/layouts/Svg";

const {Content, Sider} = Layout;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function UpdateDocumentRequest(props) {
  const {id} = props.match.params;
  const {handleNoAuth} = props;
  const history = useHistory();
  const [modelGroupForm] = Form.useForm();
  const {Title} = Typography;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const getDocumentRequest = async () => {
    setLoading(true)
    try {
      const response = await GET(`/api/v1/lease-document-requests/${id}`);
      setData(response?.data.data);
    } catch (e) {
      if (e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error("Error while loading lease document request.")
      }
    }
    setLoading(false)
  }

  const save = async (values) => {
    {
      setLoading(true);
      try {
        await PUT(`/api/v1/lease-document-requests/${id}`, values)
          .then((response) => {
            message.success("Saved successfully");
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              message.error("Error while saving!");
              handleNoAuth(history);
            } else {
              message.error("Error while saving!");
            }
          });
      } catch (e) {
        logger.error("Error while saving!", e);
      }

      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    values = {...values};
    save(values);
  };

  useEffect(() => {
    getDocumentRequest();
  }, []);

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      {data &&
        <Layout>
          <MainHeader activePage="leaseApplicationRelated"/>
          <Layout>
            <Sider {...SiderProps}>
              <Menu
                defaultSelectedKeys={["1"]}
                selectedKeys={["1"]}
                style={{width: "100%"}}
              >
                <Menu.Item key="1">
                  <Icon component={AddNew}/>
                  Edit Document Request
                </Menu.Item>
              </Menu>
            </Sider>

            <Layout id="content-area-layout">
              <MainBreadcrumb
                items={[
                  {text: "Home", link_type: "linkto", link: "/dashboard"},
                  {text: "Lease Applications", link_type: "ahref", link: '/lease-applications-list'},
                  {
                    text: `${data?.applicationIdentifier}`,
                    link_type: "ahref",
                    link: `/lease-applications/${data?.leaseApplicationId}`
                  },
                  {
                    text: "Lease Document Requests",
                    link_type: "ahref",
                    link: "/lease-document-request",
                  },
                  {text: "Edit", link_type: "ahref", link: void 0},
                ]}
              />

              <Content id="main-content">
                <Row gutter={[0, 24]}>
                  <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                    <Title level={3} style={{marginBottom: '10px'}}>Edit Lease Document Request</Title>
                  </Col>
                </Row>
                <Row gutter={[0, 24]}>
                  <Col span={24}>
                    <>
                      <Form
                        form={modelGroupForm}
                        {...layout}
                        colon={false}
                        onFinish={handleSubmit}
                        initialValues={{
                          asset_make: data.assetMake,
                          asset_color: data.assetColor,
                          asset_year: data.assetYear,
                          asset_vin: data.assetVin,
                          manual_vin_verification: data.manualVinVerification,
                          asset_model: data.assetModel,
                          exact_odometer_mileage: data.exactOdometerMileage,
                          trade_in_make: data.tradeInMake,
                          trade_in_year: data.tradeInYear,
                          trade_in_model: data.tradeInModel,
                          delivery_date: data.deliveryDate != null ? moment(data?.deliveryDate) : null,
                          gap_contract_term: data.gapContractTerm,
                          service_contract_term: data.serviceContractTerm,
                          ppm_contract_term: data.ppmContractTerm,
                          tire_contract_term: data.tireContractTerm,
                          equipped_with: data.equippedWith
                        }}
                      >
                        <Row gutter={[16, 16]}>
                          <Col span={24}>
                            <Card title="Lease Document Request Details">
                              <Row gutter={[24, 16]}>
                                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                  <Form.Item
                                    label="Asset make"
                                    name="asset_make"
                                    hasFeedback
                                    messageVariables={{name: "Reason"}}
                                    rules={[{required: true}]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Asset model"
                                    name="asset_model"
                                    hasFeedback
                                    messageVariables={{
                                      name: "notes",
                                    }}
                                    rules={[{required: false}]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Asset year"
                                    name="asset_year"
                                    hasFeedback
                                    messageVariables={{
                                      name: "Asset Year",
                                    }}
                                    rules={[{required: false}]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Asset VIN"
                                    name="asset_vin"
                                    hasFeedback
                                    messageVariables={{
                                      name: "Asset Vin",
                                    }}
                                    rules={[
                                      {
                                        pattern: /^[a-z0-9]+$/i,
                                        message: "Wrong format!"
                                      }
                                    ]}
                                  >
                                    <Input maxlength={17}/>
                                  </Form.Item>
                                  <Form.Item
                                    name="manual_vin_verification"
                                    valuePropName="checked"
                                  >
                                    <Checkbox>VIN manually verified</Checkbox>
                                  </Form.Item>
                                  <Form.Item
                                    label="Trade in make"
                                    name="trade_in_make"
                                    hasFeedback
                                    messageVariables={{
                                      name: "Trade in make",
                                    }}
                                    rules={[{required: false}]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Trade in model"
                                    name="trade_in_model"
                                    hasFeedback
                                    messageVariables={{
                                      name: "Trade in model",
                                    }}
                                    rules={[{required: false}]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Trade in year"
                                    name="trade_in_year"
                                    hasFeedback
                                    messageVariables={{
                                      name: "Trade in year",
                                    }}
                                    rules={[{required: false}]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                </Col>
                                <Col xs={12} sm={12} md={24} lg={12} xl={12}>

                                  <Form.Item
                                    label="Asset color"
                                    name="asset_color"
                                    hasFeedback
                                    messageVariables={{
                                      name: "Asset Color",
                                    }}
                                    rules={[{required: true}]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Exact odometer mileage "
                                    name="exact_odometer_mileage"
                                    hasFeedback
                                    messageVariables={{
                                      name: "Exact odometer mileage",
                                    }}
                                    rules={[{required: true}]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Delivery date"
                                    name="delivery_date"
                                    hasFeedback
                                    messageVariables={{
                                      name: "Delivery Date",
                                    }}
                                    rules={[{required: false}]}
                                  >
                                    <DatePicker/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Gap contract term"
                                    name="gap_contract_term"
                                    hasFeedback
                                    messageVariables={{
                                      name: "Gap contract term",
                                    }}
                                    rules={[{required: false}]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Service contract term"
                                    name="service_contract_term"
                                    hasFeedback
                                    messageVariables={{
                                      name: "Service contract term",
                                    }}
                                    rules={[{required: false}]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Ppm contract term"
                                    name="ppm_contract_term"
                                    hasFeedback
                                    messageVariables={{
                                      name: "Ppm contract term",
                                    }}
                                    rules={[{required: false}]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Tire contract term"
                                    name="tire_contract_term"
                                    hasFeedback
                                    messageVariables={{
                                      name: "Tire contract term",
                                    }}
                                    rules={[{required: false}]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Equipped with"
                                    name="equipped_with"
                                    hasFeedback
                                    messageVariables={{
                                      name: "Equipped with",
                                    }}
                                    rules={[{required: false}]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>

                        <Row gutter={[16, 16]} className="mt-20">
                          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                            <Row>
                              <Col span={24}>
                                <Form.Item>
                                  <Button type="primary" htmlType="submit">
                                    Update
                                  </Button>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  </Col>
                </Row>
              </Content>
            </Layout>
          </Layout>
          <MainFooter/>
        </Layout>
      }
    </Spin>
  );
}
