import React from "react";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {styled} from "@mui/system";
import {LicenseInfo} from "@mui/x-license-pro";
import {updatePageState} from "../../../reducers/DealerUsers";
import {useDispatch} from "react-redux";

LicenseInfo.setLicenseKey(
  "06c2f0cf50adbcfcc3f1fdcdf8402d39Tz00OTMwMCxFPTE2OTIzNzY1MTM3ODUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export default function DealerUserTable({dealerData, columns, total, pageState}) {
  const dispatch = useDispatch()

  return (
    <StyledDataGridPro
      disableColumnReorder={true}
      disableSelectionOnClick={true}
      getRowHeight={() => "auto"}
      density="comfortable"
      columns={columns}
      rows={dealerData}
      rowsPerPageOptions={[25, 50, 100]}
      pagination
      rowCount={total ?? 0}
      page={pageState.page <= 0 ? 0 : pageState.page}
      pageSize={pageState.limit}
      paginationMode="server"
      onPageChange={(newPage) => {
        dispatch(updatePageState({page: newPage}))
      }}
      onPageSizeChange={(newPageSize) => {
        dispatch(updatePageState({limit: newPageSize}))
      }}
    />
  );
}

const StyledDataGridPro = styled(DataGridPro)({
  boxShadow: "none !important",
  border: "none !important",
  "& .MuiDataGrid-cell": {
    padding: "10px",
  },
  "& .MuiDataGrid-row": {
    fontSize: 12,
  },
  "& .wrapHeader .MuiDataGrid-colCellTitle": {
    overflow: "hidden",
    lineHeight: "20px",
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: "normal !important",
    lineHeight: "normal",
    overflowWrap: "break-word",
    letterSpacing: "-0.5px",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#e8ecf0",
    color: "#000000",
  },
  "& .MuiChip-label": {
    paddingLeft: "12px",
    paddingRight: "12px",
    whiteSpace: "normal !important",
    wordWrap: " break-word",
    lineHeight: " 1",
    paddingBottom: "2px",
    paddingTop: "2px",
    letterSpacing: "-0.5px",
  },
  "& .MuiBadge-badge": {
    display: "none !important",
  },
  "& .super-app-theme--Filled": {
    backgroundColor: "#ed6248",
    "&:hover": {backgroundColor: "#e93b1b"},
  },
});
