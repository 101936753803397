import React, { useState } from 'react';
import {
    Button,
    Card,
    Col,
    Input,
    Form,
    Layout,
    message,
    Row
} from 'antd';
import MainHeader from '../../components/layouts/MainHeader';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import MainFooter from '../../components/layouts/MainFooter';
import { PUT } from "../../util/network";
import { handleNoAuth } from '../../util/authenticate';
import { useHistory } from 'react-router-dom';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

const adminUser = JSON.parse(localStorage.getItem("user_data"));

function AdminChangePassword() {
    const history = useHistory();
    const [passwordValidateStatus] = useState(undefined);
    const [passwordErrorMsg] = useState(undefined);
    const [changePassForm] = Form.useForm();

    const save = async (values) => {
        try {
            await PUT(`/api/v1/admin-users/change-password/${adminUser.id}`, values)
                .then(() => {
                    message.success("Password is changed successfully!");
                })
                .catch((error) => {
                    if(error?.response?.status == 401){
                        message.error('Session expired. You are logged out!');
                        handleNoAuth(history);
                    }else{
                        message.error(error.response.data?.message);
                    }
                });
        } catch (e) {
            // logger.error("Error while saving!", e);
        }
    };

    const handleSubmit = async (values) => {
        values = { ...values };
        save(values);
    };

    return (
        <Layout>
            <MainHeader activePage="" />
            <Layout style={{ padding: "30px 20px 100px" }}>
                <MainBreadcrumb items={
                    [
                        { text: "Home", link_type: "linkto", link: "/dashboard" },
                        { text: `${adminUser.full_name}`, link_type: "ahref", link: void (0) },
                        { text: "Change Password", link_type: "ahref", link: void (0) }
                    ]}
                />
                <div className='change-pass-content'>
                    <Form
                        form={changePassForm}
                        {...layout}
                        colon={false}
                        onFinish={handleSubmit}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Card title="Change Password" >
                                    <Row gutter={[24, 16]} style={{width: "60%", margin: "0 auto"}}>
                                        <Col xs={24}>
                                            <Form.Item
                                                label="Current Password"
                                                name="current_password"
                                                hasFeedback
                                                messageVariables={{ name: "Current Password" }}
                                                validateStatus={passwordValidateStatus}
                                                help={passwordErrorMsg}
                                                rules={[
                                                    {
                                                        required: true,
                                                    }
                                                ]}
                                            >
                                                <Input.Password  />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item
                                                label="New Password"
                                                name="password"
                                                hasFeedback
                                                messageVariables={{ name: "New Password" }}
                                                validateStatus={passwordValidateStatus}
                                                help={passwordErrorMsg}
                                                rules={[
                                                    {
                                                        required: true,
                                                        min: 8,
                                                        max: 20,
                                                    },
                                                    {
                                                        pattern: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/g,
                                                        message: `At least 1 capital letter, 1 lower case letter, 1 number and 1 special character`
            
                                                      }
                                                ]}
                                            >
                                                <Input.Password />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} >
                                            <Form.Item
                                                label="Confirm Password"
                                                name="confirm_password"
                                                messageVariables={{
                                                    name: "Password Confirmation",
                                                }}
                                                hasFeedback
                                                rules={[
                                                    {
                                                        required: true,
                                                        min: 8,
                                                        max: 20,
                                                    },
                                                    {
                                                        pattern: /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/g,
                                                        message: `At least 1 capital letter, 1 lower case letter, 1 number and 1 special character`
            
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (
                                                                !value ||
                                                                getFieldValue("password") === value
                                                            ) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(
                                                                new Error("Passwords do not match!")
                                                            );
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Input.Password />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </Card>
                            </Col>
                        </Row>

                        <Row gutter={[16, 16]} className="mt-20">
                            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                Change Password
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Layout>
            <MainFooter />
        </Layout>
    )
}

export default AdminChangePassword;
