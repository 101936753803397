import React from "react";
import { Table, Row, Col, Button, message, Upload } from 'antd';
const BASE_URL = process.env.REACT_APP_BASE_URL;
const auth_token = window.localStorage.getItem('auth_token');

export default function DealershipAttachments({dataSource, dealershipId, getDealership, handleGenerateDealerAgreement, allowedUpdate }) {    

    const columns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description'

        },
        {
            title: 'File Name',
            dataIndex: 'filename',
            key: 'filename'

        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploadedBy',
            key: 'uploadedBy'

        },
        {
            title: 'Actions',
            dataIndex: 'url',
            key: 'url',
            render(val) {
                return <Button href={val} type="link" target="_blank"> Download </Button>
            }

        },
        {
            title: 'Uploaded At' ,
            dataIndex: 'createdAt',
            key: 'createdAt',
            responsive: ['lg']
        },
    ];


    const props = {
        name: 'upload',
        action: `${BASE_URL}/api/v1/dealerships/upload-attachments`,
        data: {id: dealershipId},
        headers: {
            Authorization: ` Token ${auth_token}`,
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            // console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            getDealership();
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };



    return (
        <>
            <Row gutter={[24, 24]} >
                <Col span={24}> 
                    <Table 
                        className='slc-table'
                        rowKey="id" 
                        dataSource={dataSource} 
                        columns={columns} 
                        pagination={false} 
                        size="small" 
                        />
                </Col>
            </Row> 
            <br/>
            {
              allowedUpdate &&
                <Row gutter={[24, 24]} >
                    
                    <Col span={12}> <Upload {...props}> <Button type="primary">Add Attachments</Button>  </Upload> </Col>
                    <Col span={12} style={{textAlign: 'right'}}> <Button type="link" onClick={handleGenerateDealerAgreement}>Generate Dealer Agreement</Button> </Col>
                </Row>               
            }
        </>
    );
}