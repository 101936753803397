import React, { useState } from "react";
import moment from "moment";
import {
    Button,
    Card,
    Input,
    DatePicker,
    Form,
    Spin,
    message,
    Typography,
    Row, Col, Table,
} from "antd";
import { GET, PUT } from "../../util/network";
import DialogModal from "../LeaseApplications/DialogModal";
import { handleNoAuth } from "../../util/authenticate";
import logger from "../../util/logger";
import { getDetails } from "../../reducers/LeaseApplications";
import { useDispatch } from "react-redux";

export default function LeaseDocumentRequests({ data, setLoading }, ...props) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { Link } = Typography;
    const dataSource = data ? data.leaseDocumentRequests : []
    const [loader, setLoader] = useState(false);
    const { handleNoAuth } = props;
    const authData = JSON.parse(window.localStorage.getItem('user_data')) || null;
    const [editingKey, setEditingKey] = useState('');
    const [record, setRecord] = useState({});
    const [leaseDocument, setLeaseDocument] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [changes, setChanges] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [isVinVerified, setIsVinVerified] = useState(false);
    const [isVinSearched, setIsVinSearched] = useState(false);
    let loggedInUserId = null;
    if (authData !== null) {
        loggedInUserId = authData.id;
    }
    const { Title } = Typography;

    const locked_by = data.lockedStatus && data.lockedStatus.lockedById;
    const disableFlag = locked_by != loggedInUserId ? true : false

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({ ...record });
        setRecord(record);
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
        setChanges(false);
        setIsVinSearched(false);
        setIsVinVerified(false);
    };

    const modalMessage = () => {
        if (isVinVerified && changes) {
            return " Vin verification successful. This will change vin, make, model, model-year. Are you sure to continue?"
        } else if (isVinVerified) {
            return " Vin verification successful. This will change vin. Are you sure to continue?"
        }

        return " This will save vin without verifying. Are you sure to continue?"
    };

    const vinVerificationMessage = () => {
        if (isVinVerified) {
            return <Typography style={{ color: 'blue', marginLeft: '7px' }}>VIN verification Successful.</Typography>
        } else {
            return <Typography style={{ color: 'red', marginLeft: '7px' }}>VIN verification failed.</Typography>
        }
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const updateLeaseDocument = async () => {
        setLoader(true);
        handleModalClose();
        cancel();
        try {
            const { id, ...values } = leaseDocument;
            await PUT(`/api/v1/lease-document-requests/${id}/update_vin`, values).then((response) => {
                message.success("Lease document details saved successfully.");
                dispatch(getDetails(response.data.leaseApplication));
                setLoader(false);
            }).catch((error) => {
                setLoader(false);
                if (error.response && error.response.status === 401) {
                    message.error("Error while saving!");
                    handleNoAuth(history);
                } else {
                    message.error("Error while saving!");
                }
            });
        } catch (e) {
            setLoader(false);
            logger.error("Error while saving!", e);
        }
    };

    const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
        const inputNode = inputType === 'alphaNumeric' ? <Input maxlength={17} /> : <Input />;

        if (editing && inputType === 'alphaNumeric') {
            return <td {...restProps}>
                <Row>
                    <Form.Item
                        name={dataIndex}
                        style={{ marginBottom: 5 }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                            {
                                pattern: /^[a-z0-9]+$/i,
                                message: "Wrong format!"
                            }
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                    <Button
                        type="primary"
                        disabled={disableButton}
                        style={{ padding: "0px 5px" }}
                        onClick={() => search()}
                    >
                        Search VIN
                    </Button>
                </Row>
                {isVinSearched && vinVerificationMessage()}
            </td>
        } else if (editing) {
            return <td {...restProps}>
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                        {
                            pattern: /^[a-z]+$/i,
                            message: "Wrong format!"
                        }
                    ]}
                >
                    {inputNode}
                </Form.Item>
            </td>
        } else {
            return (
                <td {...restProps}>
                    {children}
                </td>
            )
        }
    };

    const columns = [
        {
            title: 'Year',
            dataIndex: 'assetYear',
            key: 'assetYear',
            editable: false,
        },
        {
            title: 'Make',
            dataIndex: 'assetMake',
            key: 'assetMake',
            editable: false,
        },
        {
            title: 'Model',
            dataIndex: 'assetModel',
            key: 'assetModel',
            editable: false,
        },
        {
            title: 'VIN',
            dataIndex: 'assetVin',
            key: 'assetVin',
            editable: true,
            width: 330,
        },
        {
            title: 'Color',
            dataIndex: 'assetColor',
            key: 'assetColor',
            editable: true,
        },
        {
            title: 'VIN Verification Status',
            dataIndex: 'vinVerified',
            key: 'vinVerified',
            editable: false,
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            editable: false,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render(_, record) {
                if (!disableFlag) {
                    const editable = isEditing(record);
                    return editable ? <span>
                        <Link style={{ marginRight: 8 }} disabled={disableButton} onClick={() => save(record)}>
                            Save
                        </Link>
                        <Link disabled={disableButton} onClick={() => cancel()}>
                            Cancel
                        </Link>
                    </span> : <>
                        <Link href={`/lease_document_request/${record?.id}`} style={{ marginRight: "8px" }}>View</Link>
                        <Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                            Edit
                        </Link>
                    </>
                } else {
                    return <>
                        <Link disabled={disableFlag} href={`/lease_document_request/${record?.id}`} style={{ paddingRight: "8px" }}>View</Link>
                        <Link disabled={disableFlag} onClick={() => edit(record)}>
                            Edit
                        </Link>
                    </>
                }
            },
        },
    ];

    const modifiedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'assetVin' ? 'alphaNumeric' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                maxlength: 17,
            }),
        };
    });

    const search = async () => {
        setLoading(true);
        setDisableButton(true);
        const assetVin = form.getFieldsValue()?.assetVin;
        await GET(`/api/v1/vin_verification/verify-vin?vin=${assetVin}`).then((res) => {
            if (res.data.vehicleInfo?.make && record.assetMake !== res.data.vehicleInfo?.make) {
                setChanges(true);
                setLeaseDocument({ ...leaseDocument, asset_make: res.data.vehicleInfo?.make })
            }

            if (res.data.vehicleInfo?.year && record.assetYear !== res.data.vehicleInfo?.year) {
                setChanges(true);
                setLeaseDocument({ ...leaseDocument, asset_year: res.data.vehicleInfo?.year })
            }

            if (res.data.vehicleInfo?.model && record.assetModel !== res.data.vehicleInfo?.model) {
                setChanges(true);
                setLeaseDocument({ ...leaseDocument, asset_model: res.data.vehicleInfo?.model })
            }

            setDisableButton(false);
            setIsVinVerified(true);
            setIsVinSearched(true);
            setLoading(false);
            message.success('VIN found successfully')
        }).catch((e) => {
            setDisableButton(false);
            setIsVinSearched(true);
            setLoading(false);
            if (e && e.response.status === 404) {
                message.error('VIN not found.')
            } else {
                message.error('Error verifying VIN.')
            }
        })
    };

    const save = async (record) => {
        try {
            const row = await form.validateFields();
            setLeaseDocument({ ...leaseDocument, id: record.id, lease_application_id: record.leaseApplicationId, asset_vin: row?.assetVin, asset_color: row?.assetColor })
            setModalOpen(true);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    const leaseDocumentData = data.leaseDocumentRequests[data.leaseDocumentRequests.length - 1]
    const handleSubmit = async (values) => {
        values = { ...values };
        saveLeaseData(values)
    }
    const saveLeaseData = async (values) => {
        {
            setLoading(true);
            try {
                await PUT(`/api/v1/lease-document-requests/${leaseDocumentData.id}`, values)
                    .then((response) => {
                        message.success("Saved successfully");
                    })
                    .catch((error) => {
                        if (error.response && error.response.status === 401) {
                            message.error("Error while saving!");
                            handleNoAuth(history);
                        } else {
                            message.error("Error while saving!");
                        }
                    });
            } catch (e) {
                logger.error("Error while saving!", e);
            }

            setLoading(false);
        }
    };
    const [modelGroupForm] = Form.useForm();

    return (<>
        <Row>
            <Col span={24}>
                <Form form={form} component={false}>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        bordered
                        dataSource={dataSource}
                        columns={modifiedColumns}
                        rowClassName="editable-row"
                        pagination={{
                            onChange: cancel,
                        }}
                    />
                </Form>
            </Col>
            <DialogModal
                modalState={modalOpen}
                modalClose={handleModalClose}
                submitFunction={updateLeaseDocument}
                contentData={modalMessage()}
                title={"Update Lease Document request data?"}
            />
        </Row>

        <Row gutter={[0, 24]} style={{ marginTop: '-30px' }}>
            <Col xs={18} sm={18} md={24} lg={18} xl={18}>
                <Title level={3} style={{ textAlign: 'center' }}>Edit Lease Document Request</Title>
            </Col>
        </Row>
        <Spin spinning={loader} size="large" tip="Loading...">
            <Row gutter={[0, 24]}>
                <Col span={24}>
                    <>
                        <Form
                            form={modelGroupForm}
                            {...layout}
                            colon={false}
                            onFinish={handleSubmit}
                            initialValues={{
                                exact_odometer_mileage: leaseDocumentData?.exactOdometerMileage,
                                delivery_date: leaseDocumentData?.deliveryDate != null ? moment(leaseDocumentData?.deliveryDate) : null,
                                gap_contract_term: leaseDocumentData?.gapContractTerm,
                                service_contract_term: leaseDocumentData?.serviceContractTerm,
                                ppm_contract_term: leaseDocumentData?.ppmContractTerm,
                                tire_contract_term: leaseDocumentData?.tireContractTerm,
                                equipped_with: leaseDocumentData?.equippedWith
                            }}
                        >
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Card title="Lease Document Request Details">
                                        <Row gutter={[24, 16]}>
                                            <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                                <Form.Item
                                                    label="Exact odometer mileage "
                                                    name="exact_odometer_mileage"
                                                    hasFeedback
                                                    messageVariables={{
                                                        name: "Exact odometer mileage",
                                                    }}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Input disabled={disableFlag} />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Delivery date"
                                                    name="delivery_date"
                                                    hasFeedback
                                                    messageVariables={{
                                                        name: "Delivery Date",
                                                    }}
                                                    rules={[{ required: false }]}
                                                >
                                                    <DatePicker disabled={disableFlag} format="MM-DD-YYYY"/>
                                                </Form.Item>
                                                <Form.Item
                                                    label="Gap contract term"
                                                    name="gap_contract_term"
                                                    hasFeedback
                                                    messageVariables={{
                                                        name: "Gap contract term",
                                                    }}
                                                    rules={[{ required: false }]}
                                                >
                                                    <Input disabled={disableFlag} />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Service contract term"
                                                    name="service_contract_term"
                                                    hasFeedback
                                                    messageVariables={{
                                                        name: "Service contract term",
                                                    }}
                                                    rules={[{ required: false }]}
                                                >
                                                    <Input disabled={disableFlag} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                                <Form.Item
                                                    label="Ppm contract term"
                                                    name="ppm_contract_term"
                                                    hasFeedback
                                                    messageVariables={{
                                                        name: "Ppm contract term",
                                                    }}
                                                    rules={[{ required: false }]}
                                                >
                                                    <Input disabled={disableFlag} />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Tire contract term"
                                                    name="tire_contract_term"
                                                    hasFeedback
                                                    messageVariables={{
                                                        name: "Tire contract term",
                                                    }}
                                                    rules={[{ required: false }]}
                                                >
                                                    <Input disabled={disableFlag} />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Equipped with"
                                                    name="equipped_with"
                                                    hasFeedback
                                                    messageVariables={{
                                                        name: "Equipped with",
                                                    }}
                                                    rules={[{ required: false }]}
                                                >
                                                    <Input disabled={disableFlag} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item >
                                            <Button disabled={disableFlag} style={{ float: "right" }} type="primary" htmlType="submit">
                                                Update
                                            </Button>
                                        </Form.Item>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                    </>
                </Col>
            </Row>
        </Spin>

    </>
    );
}
