import React, {useState, useEffect} from "react";
import {useHistory} from 'react-router-dom';
import {Spin, message} from "antd";
import Dealerships from "./Dealerships";
import logger from '../../util/logger';
import {GetResourceActions, IsActionAllowed} from '../../util/AllowedActions';
import {fetchDealerships, exportDealershipsData} from '../../reducers/Dealerships';
import {useSelector, useDispatch} from "react-redux";

export default function DealershipsRenderer(props) {
  const {allowedActions, handleNoAuth} = props;
  const history = useHistory();

  const dealershipActions = GetResourceActions('Dealership', allowedActions);
  const allowedGet = IsActionAllowed('get', dealershipActions);
  const allowedCreate = IsActionAllowed('create', dealershipActions);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const pageState = useSelector((state) => state.Dealerships.pageState);
  const dataGridFilter = useSelector((state) => state.Dealerships.dataGridFilter);
  const dataGridFilterFlag = useSelector((state) => state.Dealerships.dataGridFilterFlag);
  const data = useSelector((state) => state.Dealerships.dealerships);
  const isLoading = useSelector((state) => state.Dealerships.isLoading);
  const signButtonVisibility = useSelector((state) => state.Dealerships.signInVisibility);
  const dataGridSort = useSelector((state) => state.Dealerships.dataGridSort);
  let auth_token = window.localStorage.getItem('auth_token');
  const [exportModal, setExportModal] = useState(false);
  const handleExportModalOpen = () => {
    setExportModal(true)
  }

  const handleExportModalClose = () => {
    setExportModal(false)
  }

  const getDealerships = async () => {
    try {
      dispatch(fetchDealerships({pageState: pageState, dataGridFilter: dataGridFilter, dataGridSort: dataGridSort}));
    } catch (e) {
      logger.error("Dealership Error", e);
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while searching Dealerships');
      }
    }
  };

  const exportData = (data, fileName, type) => {
    const blob = new Blob([data], {type});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const exportDealerships = async () => {
    setLoading(true);
    try {
      message.success("Dealership CSV will be sent to email and file will be downloaded in a minutes");
      setExportModal(false);
      dispatch(exportDealershipsData({search: pageState.search, status: pageState.status})).then((response) => {
        exportData(response.payload?.data, 'dealerships_export.csv', 'text/csv;charset=utf-8;');
      })
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while Exporting Dealerships');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    getDealerships();
  }, [pageState, dataGridFilterFlag, dataGridSort]);

  console.log('sort grid', dataGridSort);

  return (
    <Spin spinning={isLoading} size="large" tip="Loading...">
      <Dealerships
        data={data}
        setLoading={setLoading}
        allowedActions={dealershipActions}
        allowedGet={allowedGet}
        allowedCreate={allowedCreate}
        handleNoAuth={handleNoAuth}
        exportModal={exportModal}
        handleExportModalOpen={handleExportModalOpen}
        exportDealerships={exportDealerships}
        handleExportModalClose={handleExportModalClose}
        signButtonVisibility={signButtonVisibility}
        dataGridFilter={dataGridFilter}
        dataGridSort={dataGridSort}
      />
    </Spin>
  );
}
