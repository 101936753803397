import React from "react";
import ReactHtmlParser from 'react-html-parser';
import { Table } from "antd";

const columns = [
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: 150,
  },
  {
    title: 'Audited Type',
    dataIndex: 'audited',
    key: 'audited',
    width: 150,
  },
  {
    title: 'By Who',
    dataIndex: 'byWho',
    key: 'byWho',
    width: 150,
  },
  {
    title: 'Changes',
    key: 'changes',
    dataIndex: 'changes',
    render(val) {
        return (
            <div> { ReactHtmlParser(val) } </div>
        )
    }
  }
];

export default function Audits({ data }) {
  return (
      <Table rowKey="id" columns={columns} dataSource={data.audits} size="small" pagination={true}/>
  );
}