import React, {useState} from "react";
import { Card, Col, Row, Table, Typography, Button } from 'antd';
import IncomeVerificationForm from  './incomeVerification/IncomeVerificationForm';
import YtdForm from './incomeVerification/YtdForm';
import YtdList from "./incomeVerification/YtdList";
import IncomeVerificationList from  './incomeVerification/IncomeVerificationList';
import EmploymentVerificationDetailsModal from './components/EmploymentVerificationDetailsModal';
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';

const { Title } = Typography;


const lesColumns = [
  {
    title: 'Item',
    dataIndex: 'item',
    key: 'item',
  },
  {
    title: 'Phone Line Type',
    dataIndex: 'phone_number_line',
    key: 'phone_number_line',
  },
  {
    title: 'Phone Carrier',
    dataIndex: 'phone_number_carrier',
    key: 'phone_number_carrier',
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
  },
  {
    title: 'Status',
    key: 'status', 
    dataIndex: 'status',
  },
];

const formLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default ({ data, ...props }) => {
  // Authorization
  const { allowedActions } = props;
  const verificationActions = GetResourceActions('EmploymentVerification', allowedActions);
  const allowedGet = IsActionAllowed('get', verificationActions);
  const allowedUpdate = IsActionAllowed('update', verificationActions);

  // Lessee data
  const { lessee } = data || {};

  // Blackbox Automated Verifications
  let bavDataSource = (data && data.dataxEmploymentDetails) || [];
  if (data && data.lessee) {
    bavDataSource = data.dataxEmploymentDetails.filter(val => parseInt(val.lessee_id) ==  parseInt(data.lessee.id) ) || [];
  } else {
    bavDataSource = [];
  }

  const [employmentDetail, setEmploymentDetail] = useState(undefined);

  const bavColumns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Employer',
      dataIndex: 'employer',
      key: 'employer',
    },
    {
      title: 'Title',
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: 'Employment Status',
      key: 'employment_status',
      dataIndex: 'employment_status',
    },
    {
      title: 'Details',
      key: 'details',
      dataIndex: 'details',
      render(text, record, index) {
        return <Button id={index} type="link" onClick={() => setEmploymentDetail({ ...record, timestamp: new Date().getTime()})} style={{ height: 'auto', padding: 0 }}>View</Button>
      }
    },
  ];

  // Lease Employment Submissions
  const lessee_ids = [];
  data && data.lessee && lessee_ids.push(data.lessee.id);
  const lesDataSource = data.leaseValidations.filter(val => ["Employer Phone Validation"].includes(val.type) && lessee_ids.includes(val.validatable_id) ) || [];
  return data ? (
    <>
    { allowedGet && <>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card title='Average Payment(s)'>
              {allowedUpdate && (<>
                <IncomeVerificationForm
                  allowedUpdate={allowedUpdate}
                  data={data}
                  lessee={lessee}

                  layout={formLayout}
                />
              </>)
              }

              {/* lease verification list */}
              <IncomeVerificationList 
                allowedGet={allowedGet}
                allowedUpdate={allowedUpdate}
                data={data} 
                lessee={lessee} 
                 
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}  className='mt-20' >
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card title="Year to Date Payment">
              <YtdForm
                  allowedUpdate={allowedUpdate}
                  data={data}
                  lessee={lessee}

                  layout={formLayout}
                />
                              <YtdList
                data={data}
                allowedGet={allowedGet}
                lessee={data.lessee}
              />
            </Card>
          </Col>
        </Row>
        {/* <Row gutter={[16, 16]}  className='mt-20' >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card title="Combined Calculated Value ">
                {data?.creditAverageSummary?.maxAllowablePayment || 0}
              </Card>
            </Col>
        </Row> */}
        </>
      }
      <Row gutter={[16, 16]} className='mt-20'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>      
          <Card title='Automated Verifications'>
            <Title level={4}> Lease Employment Submissions </Title>
            <Table className='slc-table' rowKey="id" columns={lesColumns} dataSource={lesDataSource} size="small" pagination={true}/>
            <Title level={4}> Blackbox Automated Verifications </Title>
            <Table className='slc-table' rowKey="id" columns={bavColumns} dataSource={bavDataSource} size="small" pagination={true} />
            <EmploymentVerificationDetailsModal dataxEmploymentDetail={employmentDetail}></EmploymentVerificationDetailsModal>
          </Card>
        </Col>
      </Row>          
    </>
  ) : null;
};