import React from "react";
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {styled} from '@mui/system';
import {Button, Drawer} from 'antd';
import Grid from "@mui/material/Grid";
import {Typography} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItem from "@mui/material/ListItem";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

export default function CreditTierFilters(props) {
  const { newPageState, setNewPageState, filter, setFilter, creditTierFilter, clearCreditTierFilter } = props;

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setFilter(open)
  };

  const compareSymbol = [
    {
      value: "=",
      label: "Equal",
    },
    {
      value: "<",
      label: "Less Than",
    },
    {
      value: ">",
      label: "Greater Than",
    },
  ];

  const allFilters = () => (
    <Box
      role="presentation"
    >
      <Grid container direction="row">
        <Grid item md={12} style={{marginBottom: '5px'}}>
          <Typography variant="h5">Description</Typography>
        </Grid>
        <Grid item md={12} style={{marginBottom: '5px'}}>
          <StyledTextField
            label="Description"
            id="outlined-size-small"
            size="small"
            value={newPageState?.description}
            onChange={(e) => {
              setNewPageState({...newPageState, description: e.target.value})
            }}
          />
        </Grid>

        <Grid item md={12} style={{marginBottom: '5px'}}>
          <Typography variant="h5">Created at</Typography>
        </Grid>
        <Grid item md={12} style={{marginBottom: '5px'}}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ListItem style={{paddingLeft: '0px'}}>
              <StyledDatePicker
                clearable
                label="Created at From"
                inputFormat="MM/dd/yyyy"
                value={newPageState.createdAtFrom}
                maxDate = {newPageState.createdAtTo !== null ? newPageState.createdAtTo : null}
                renderInput={(params) => <TextField {...params} size="small" style={{width: '230px'}}/>}
                onChange={(value) => {
                  setNewPageState({...newPageState, createdAtFrom: value})
                }}
                showDaysOutsideCurrentMonth
              />
              <StyledDatePicker
                label="Created at To"
                inputFormat="MM/dd/yyyy"
                value={newPageState.createdAtTo}
                minDate={newPageState.createdAtFrom !== null ? newPageState.createdAtFrom : null}
                renderInput={(params) => <TextField {...params} size="small" style={{width: '230px'}}/>}
                onChange={(value) => {
                  setNewPageState({...newPageState, createdAtTo: value})
                }}
                showDaysOutsideCurrentMonth
              />
            </ListItem>
          </LocalizationProvider>
        </Grid>

        <Grid item md={12} style={{marginBottom: '5px'}}>
          <Typography variant="h5">Updated at</Typography>
        </Grid>
        <Grid item md={12} style={{marginBottom: '5px'}}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ListItem style={{paddingLeft: '0px'}}>
              <StyledDatePicker
                clearable
                label="Updated at From"
                inputFormat="MM/dd/yyyy"
                value={newPageState.UpdatedAtFrom}
                maxDate = {newPageState.UpdatedAtTo !== null ? newPageState.UpdatedAtTo : null}
                renderInput={(params) => <TextField {...params} size="small" style={{width: '230px'}}/>}
                onChange={(value) => {
                  setNewPageState({...newPageState, UpdatedAtFrom: value})
                }}
                showDaysOutsideCurrentMonth
              />
              <StyledDatePicker
                label="Updated at To"
                inputFormat="MM/dd/yyyy"
                value={newPageState.UpdatedAtTo}
                minDate={newPageState.UpdatedAtFrom !== null ? newPageState.UpdatedAtFrom : null}
                renderInput={(params) => <TextField {...params} size="small" style={{width: '230px'}}/>}
                onChange={(value) => {
                  setNewPageState({...newPageState, UpdatedAtTo: value})
                }}
                showDaysOutsideCurrentMonth
              />
            </ListItem>
          </LocalizationProvider>
        </Grid>

        <Grid item md={12} style={{marginBottom: '5px'}}>
          <Typography variant="h5">Position</Typography>
        </Grid>
        <Grid item md={12} style={{marginBottom: '5px'}}>
            <ListItem style={{paddingLeft: '0px'}}>
            <Select
              defaultValue={newPageState.positionComp}
              value={newPageState.positionComp}
              style={{width: '200px'}}
              size="small"
              onChange={(e) => {
                setNewPageState({...newPageState, positionComp: e.target.value})
              }}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              {compareSymbol &&
              compareSymbol.map((record, index) => {
              return (
              <MenuItem key={index} value={record.value}>
                {`${record.label}`}
              </MenuItem>
            );
          })}
            </Select>
            <TextField
            label="position"
            size="small"
            id="outlined-size-small"
            style={{marginLeft: '20px'}}
            value={newPageState?.position}
            onChange={(e) => {
              setNewPageState({...newPageState, position: e.target.value})
            }}
          />
            </ListItem>
        </Grid>

        <Grid item md={12} style={{marginBottom: '5px'}}>
          <Typography variant="h5">Irr value</Typography>
        </Grid>
        <Grid item md={12} style={{marginBottom: '5px'}}>
            <ListItem style={{paddingLeft: '0px'}}>
            <Select
              defaultValue={newPageState.irrValueComp}
              value={newPageState.irrValueComp}
              style={{width: '200px'}}
              size="small"
              onChange={(e) => {
                setNewPageState({...newPageState, irrValueComp: e.target.value})
              }}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              {compareSymbol &&
              compareSymbol.map((record, index) => {
              return (
              <MenuItem key={index} value={record.value}>
                {`${record.label}`}
              </MenuItem>
            );
          })}
            </Select>
            <TextField
            label="irr value"
            id="outlined-size-small"
            size="small"
            style={{marginLeft: '20px'}}
            value={newPageState?.irrValue}
            onChange={(e) => {
              setNewPageState({...newPageState, irrValue: e.target.value})
            }}
          />
            </ListItem>
        </Grid>

        <Grid item md={12} style={{marginBottom: '5px'}}>
          <Typography variant="h5">Maximum fi advance percentage</Typography>
        </Grid>
        <Grid item md={12} style={{marginBottom: '5px'}}>
            <ListItem style={{paddingLeft: '0px'}}>
            <Select
              defaultValue={newPageState.maximumFiPercentageComp}
              value={newPageState.maximumFiPercentageComp}
              style={{width: '200px'}}
              size="small"
              onChange={(e) => {
                setNewPageState({...newPageState, maximumFiPercentageComp: e.target.value})
              }}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              {compareSymbol &&
              compareSymbol.map((record, index) => {
              return (
              <MenuItem key={index} value={record.value}>
                {`${record.label}`}
              </MenuItem>
            );
          })}
            </Select>
            <TextField
            label="maximum fi percentage"
            id="outlined-size-small"
            size="small"
            style={{marginLeft: '20px'}}
            value={newPageState?.maximumFiPercentage}
            onChange={(e) => {
              setNewPageState({...newPageState, maximumFiPercentage: e.target.value})
            }}
          />
            </ListItem>
        </Grid>

        <Grid item md={12} style={{marginBottom: '5px'}}>
          <Typography variant="h5">Maximum advance percentage</Typography>
        </Grid>
        <Grid item md={12} style={{marginBottom: '5px'}}>
            <ListItem style={{paddingLeft: '0px'}}>
            <Select
              defaultValue={newPageState.maximumAdvancePercentageComp}
              value={newPageState.maximumAdvancePercentageComp}
              style={{width: '200px'}}
              size="small"
              onChange={(e) => {
                setNewPageState({...newPageState, maximumAdvancePercentageComp: e.target.value})
              }}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              {compareSymbol &&
              compareSymbol.map((record, index) => {
              return (
              <MenuItem key={index} value={record.value}>
                {`${record.label}`}
              </MenuItem>
            );
          })}
            </Select>
            <TextField
            label="maximum advance percentage"
            id="outlined-size-small"
            size="small"
            style={{marginLeft: '20px'}}
            value={newPageState?.maximumAdvancePercentage}
            onChange={(e) => {
              setNewPageState({...newPageState, maximumAdvancePercentage: e.target.value})
            }}
          />
            </ListItem>
        </Grid>

        <Grid item md={12} style={{marginBottom: '5px'}}>
          <Typography variant="h5">Required Down Payment Percentage</Typography>
        </Grid>
        <Grid item md={12} style={{marginBottom: '5px'}}>
            <ListItem style={{paddingLeft: '0px'}}>
            <Select
              defaultValue={newPageState.requiredDownPaymentComp}
              value={newPageState.requiredDownPaymentComp}
              style={{width: '200px'}}
              size="small"
              onChange={(e) => {
                setNewPageState({...newPageState, requiredDownPaymentComp: e.target.value})
              }}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              {compareSymbol &&
              compareSymbol.map((record, index) => {
              return (
              <MenuItem key={index} value={record.value}>
                {`${record.label}`}
              </MenuItem>
            );
          })}
            </Select>
            <TextField
            label="Required down payment"
            id="outlined-size-small"
            size="small"
            style={{marginLeft: '20px'}}
            value={newPageState?.requiredDownPayment}
            onChange={(e) => {
              setNewPageState({...newPageState, requiredDownPayment: e.target.value})
            }}
          />
            </ListItem>
        </Grid>

        <Grid item md={12} style={{marginBottom: '5px'}}>
          <Typography variant="h5">Security Deposit</Typography>
        </Grid>
        <Grid item md={12} style={{marginBottom: '5px'}}>
            <ListItem style={{paddingLeft: '0px'}}>
            <Select
              defaultValue={newPageState.securityDepositComp}
              value={newPageState.securityDepositComp}
              style={{width: '200px'}}
              size="small"
              onChange={(e) => {
                setNewPageState({...newPageState, securityDepositComp: e.target.value})
              }}
              MenuProps={{
                disableScrollLock: true,
              }}
            >
              {compareSymbol &&
              compareSymbol.map((record, index) => {
              return (
              <MenuItem key={index} value={record.value}>
                {`${record.label}`}
              </MenuItem>
            );
          })}
            </Select>
            <TextField
            label="Security Deposit"
            id="outlined-size-small"
            size="small"
            style={{marginLeft: '20px'}}
            value={newPageState?.securityDeposit}
            onChange={(e) => {
              setNewPageState({...newPageState, securityDeposit: e.target.value})
            }}
          />
            </ListItem>
        </Grid>

        <Grid item md={12} style={{marginBottom: '5px'}}>
          <Typography variant="h5">Effective Date</Typography>
        </Grid>
        <Grid item md={12} style={{marginBottom: '5px'}}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ListItem style={{paddingLeft: '0px'}}>
              <StyledDatePicker
                clearable
                label="Effective date From"
                inputFormat="MM/dd/yyyy"
                value={newPageState.effectiveDateFrom}
                maxDate={newPageState.effectiveDateTo !== null ? newPageState.effectiveDateTo : null}
                renderInput={(params) => <TextField {...params} size="small" style={{width: '230px'}}/>}
                onChange={(value) => {
                  setNewPageState({...newPageState, effectiveDateFrom: value})
                }}
                showDaysOutsideCurrentMonth
              />
              <StyledDatePicker
                label="Effective date To"
                inputFormat="MM/dd/yyyy"
                value={newPageState.effectiveDateTo}
                minDate={newPageState.effectiveDateFrom !== null ? newPageState.effectiveDateFrom : null}
                renderInput={(params) => <TextField {...params} size="small" style={{width: '230px'}}/>}
                onChange={(value) => {
                  setNewPageState({...newPageState, effectiveDateTo: value})
                }}
                showDaysOutsideCurrentMonth
              />
            </ListItem>
          </LocalizationProvider>
        </Grid>

        <Grid item md={12} style={{marginBottom: '5px'}}>
          <Typography variant="h5">End date</Typography>
        </Grid>
        <Grid item md={12} style={{marginBottom: '5px'}}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ListItem style={{paddingLeft: '0px'}}>
              <StyledDatePicker
                clearable
                label="End date From"
                inputFormat="MM/dd/yyyy"
                value={newPageState.endDateFrom}
                maxDate={newPageState.endDateTo !== null ? newPageState.endDateTo : null}
                renderInput={(params) => <TextField {...params} size="small" style={{width: '230px'}}/>}
                onChange={(value) => {
                  setNewPageState({...newPageState, endDateFrom: value})
                }}
                showDaysOutsideCurrentMonth
              />
              <StyledDatePicker
                label="End date To"
                inputFormat="MM/dd/yyyy"
                value={newPageState.endDateTo}
                minDate={newPageState.endDateFrom !== null ? newPageState.endDateFrom : null}
                renderInput={(params) => <TextField {...params} size="small" style={{width: '230px'}}/>}
                onChange={(value) => {
                  setNewPageState({...newPageState, endDateTo: value})
                }}
                showDaysOutsideCurrentMonth
              />
            </ListItem>
          </LocalizationProvider>
        </Grid>

        <Grid item md={12}>
        </Grid>
        <Grid item md={12} style={{marginBottom: '5px'}}>
          <ListItem style={{paddingLeft: '5px'}}>
            <Button
              style={{
                textAlign: "center",
                backgroundColor: "#1976d2",
                color: "#fff",
                fontSize: 16,
                fontWeight: "bold",
                borderRadius: "5px",
                marginRight: "20px",
                width: "100px"
              }}
              onClick={() => creditTierFilter(true)}>
              Filter
            </Button>
            <Button
              style={{
                textAlign: "center",
                backgroundColor: "#fff",
                color: "#000",
                fontSize: 16,
                fontWeight: "bold",
                borderRadius: '5px',
                border: "1px solid #000",
                width: "100px"
              }}
              onClick={() => creditTierFilter(false)}>
              Clear
            </Button>
          </ListItem>
        </Grid>
      </Grid>
    </Box>
  );


  return (
    <Box sx={{flexGrow: 1, pb: 2}}>
      <Drawer
        title="Filters"
        width={500}
        placement="right"
        getContainer={false}
        onClose={toggleDrawer(false)}
        visible={filter}
      >
        {allFilters()}
      </Drawer>
    </Box>
  )
}

const StyledOptionBox = styled(Box)({
  "&:hover": {
    color: "#e93b1b",
    lineHeight: 1.5,
    textDecoration: 'underline',
    textUnderlineOffset: '8px',
    textDecorationcolor: '#e93b1b',
    textDecorationThickness: "2px",
    fontWeight: "500"
  }, "&:focus": {
    borderColor: "#ef4829a8 !important"
  },
});

const StyledAutoComplete = styled(Autocomplete)({
  width: 450, marginLeft: 1
});

const StyledTextField = styled(TextField)({
  width: 450, marginLeft: "1px"
})

const StyledDatePicker = styled(DesktopDatePicker)({
  "& .MuiInputBase-root ": {
    margin: '5px !important',
  }, "& .MuiFormLabel-root": {
    letterSpacing: "-0.5px"
  }
})
