import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { GET, PUT } from '../../../util/network'
import logger from '../../../util/logger'
import { Layout, Menu, Form, Checkbox, Row, Col, Typography, Spin, message, Button } from 'antd'
import MainHeader from '../../../components/layouts/MainHeader'
import MainBreadcrumb from '../../../components/layouts/MainBreadcrumb'
import MainFooter from '../../../components/layouts/MainFooter'
import { SiderProps } from '../../../components/layouts/MainSiderProps'
import FundingRequirements from './FundingRequirements.js'
import './FundingApprovalChecklist.css'

const { Content, Sider } = Layout
const { Text } = Typography

export default function FundingApprovalChecklist(props) {
  const { handleNoAuth } = props
  const history = useHistory()

  const { id } = props.match.params
  const data= undefined

  const lessee = null
  const dealer= null
  const leaseNumber = null
  const documentRequested= 'Documents not yet requested'
  const documentReceivedDate = 'Documents not yet received'

  const [loading, setLoading] = useState(false)
  const packageReviewed = false
  const [packageReviewedOn, setPackageReviewedOn] = useState('N/A')
  //const [reviewedBy, setReviewedBy] = useState('N/A')

  const fundingApproved = false
  const [fundingApprovedOn, setFundingApprovedOn] = useState('N/A')
  //const [approvedBy, setApprovedBy] = useState('N/A')

  const fundingApprovalComplete = false

  const onChangeReviewed = (e) => {
    setTimeout(async () => {
      let funding_requirements = {
        onlineFundingApprovalChecklistAttributes: {
          id: data.onlineFundingApprovalChecklist ? data.onlineFundingApprovalChecklist.id : null,
          packageReviewed: e.target.checked,
        },
      }
      try {
        const response = await putRequest(data && data.applicationIdentifier, funding_requirements)
        message.success('Data saved successfully')
        if (e.target.checked) {
          setPackageReviewedOn(response.data.data.packageReviewedOn)
          //setReviewedBy(response.data.data.reviewedBy)
        } else {
          setPackageReviewedOn('N/A')
          //setReviewedBy('N/A')
        }
      } catch (e) {
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history)
        } else {
          errorMessage(e)
        }
      }
    }, 100)
  }

  const onChangeApproved = (e) => {
    setLoading(true)
    const auth_token = window.localStorage.getItem('auth_token')
    setTimeout(async () => {
      if (!fundingApprovalComplete) {
        window.open(`${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=/admins/lease_applications/${data && data.id}/verification`)
      } else {
        setTimeout(async () => {
          let documentStatus = {
            documentStatus: e.target.checked ? 'funding_approved' : 'lease_package_received',
          }
          try {
            await putRequest(data && data.applicationIdentifier, documentStatus)
            message.success('Data saved successfully')
            
            if (!e.target.checked) {
              setFundingApprovedOn('N/A')
              //setApprovedBy('N/A')
            }
          } catch (e) {
            if (e && e.response && e.response.status === 401) {
              handleNoAuth(history)
            } else {
              errorMessage(e)
            }
          }
        }, 100)
      }
    }, 100)
  }

  const putRequest = (application_identifier, payload) => {
    return PUT(`/api/v1/lease_applications/details?application_identifier=${application_identifier}`, payload)
  }

  const errorMessage = (e) => {
    logger.error('Application Details Error', e)
    message.error('Error while saving your data')
  }

  // const savePdf = async (e) => {
  //   setLoading(true)
  //   e.preventDefault()
  //   try {
  //     await GET(`/api/v1/online_funding_approval_checklists/save_pdf?id=${id}`)
  //     message.success('PDF is being saved!')
  //   } catch (e) {
  //     logger.error('Request Error', e)

  //     if (e && e.response && e.response.status === 401) {
  //       handleNoAuth(history)
  //     } else {
  //       message.error('Error while saving PDF')
  //     }
  //   }
  //   setLoading(false)
  // }

  const downloadPdf = async (e) => {
    setLoading(true)
    e.preventDefault()
    try {
      const response = await GET(`/api/v1/online_funding_approval_checklists/download_pdf?id=${id}`)
      let url = response.data.url.match(/^http[s]?:\/\//) ? response.data.url : 'http://' + response.data.url
      window.open(url, '_blank')
    } catch (e) {
      logger.error('Request Error', e)

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history)
      } else {
        message.error('Error while getting PDF URL')
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    
  }, [])

  const theApprover = data?.theApprover ? data?.theApprover : 'N/A'
  const theReviewer = data?.theReviewer ? data?.theReviewer : 'N/A'

  return !data ? null : (
    <Spin spinning={loading}>
      <Layout>
        <MainHeader activePage="leaseApplication" />
        <Layout>
          <Sider {...SiderProps}>
            <Menu mode="inline" defaultSelectedKeys={['1']} defaultOpenKeys={['sub1']} style={{ height: '100%', borderRight: 0 }}>
              <Menu.Item key="1">
                <span>
                  {/* <Icon component={StickManSvg} /> */}
                  Funding Approval
                </span>
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            {data && (
              <MainBreadcrumb
                items={[
                  { text: 'Home', link_type: 'linkto', link: '/dashboard' },
                  { text: 'Lease Applications', link_type: "linkto", link: "/lease-applications-list" },
                  { text: `${leaseNumber}`, link_type: 'linkto', link: `/lease-applications/${data.id}` },
                  { text: 'Funding Approval Checklist' },
                ]}
              />
            )}
            <Content
              id="main-content"
              style={{
                background: '#fff',
                padding: 24,
              }}
            >
              <Form labelCol={{ span: 5 }} layout="horizontal">
                <Row gutter={[8, 32]}>
                  <Col span={8}>
                    <Text type="secondary">Lessee</Text>
                    <Row>
                      <Text level={4} className="fac-txt-val fac-txt-val-head">
                        {' '}
                        {lessee}{' '}
                      </Text>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Text type="secondary">Dealer</Text>
                    <Row>
                      <Text level={4} className="fac-txt-val fac-txt-val-head">
                        {' '}
                        {dealer}{' '}
                      </Text>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Text type="secondary">Document Requested</Text>
                    <Row>
                      <Text level={4} className="fac-txt-val fac-txt-val-head">
                        {' '}
                        {documentRequested}{' '}
                      </Text>
                    </Row>
                  </Col>
                </Row>

                <Row gutter={[8, 32]}>
                  <Col span={16}>
                    <Text type="secondary">Lease Number</Text>
                    <Row>
                      <Text level={4} className="fac-txt-val fac-txt-val-head">
                        {leaseNumber}{' '}
                      </Text>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Text type="secondary">Document Received Date</Text>
                    <Row>
                      <Text level={4} className="fac-txt-val fac-txt-val-head">
                        {' '}
                        {documentReceivedDate}{' '}
                      </Text>
                    </Row>
                  </Col>
                </Row>

                <Row gutter={[8, 8]} className="fac-req-row">
                  <Col span={24}>
                    <Text level={4} className="fac-txt-val fac-req">
                      {' '}
                      Funding Requirements{' '}
                    </Text>
                  </Col>
                </Row>

                {data && <FundingRequirements data={data} />}

                <Row gutter={[8, 8]} className="fac-req-row">
                  <Col span={24}>
                    <Text level={4} className="fac-txt-val fac-rev">
                      {' '}
                      Reviews{' '}
                    </Text>
                  </Col>
                </Row>

                <Row gutter={[8, 32]}>
                  <Col span={8}>
                    <Checkbox
                      onChange={(value) => {
                        onChangeReviewed(value)
                      }}
                      defaultChecked={packageReviewed}
                    >
                      <Text level={4} className="fac-txt-val">
                        {' '}
                        Package Reviewed{' '}
                      </Text>
                    </Checkbox>
                  </Col>
                  <Col span={16}>
                    <Checkbox
                      onChange={(value) => {
                        onChangeApproved(value)
                      }}
                      defaultChecked={fundingApproved}
                    >
                      <Text level={4} className="fac-txt-val">
                        {' '}
                        Funding Approved{' '}
                      </Text>
                    </Checkbox>
                  </Col>
                </Row>

                <Row gutter={[8, 32]}>
                  <Col span={8}>
                    <Text type="secondary">Reviewed On</Text>
                    <Row>
                      <Text level={4} className="fac-txt-val fac-txt-val-head">
                        {' '}
                        {packageReviewedOn}{' '}
                      </Text>
                    </Row>
                  </Col>
                  <Col span={16}>
                    <Text type="secondary">Approved On</Text>
                    <Row>
                      <Text level={4} className="fac-txt-val fac-txt-val-head">
                        {' '}
                        {fundingApprovedOn}{' '}
                      </Text>
                    </Row>
                  </Col>
                </Row>

                <Row gutter={[8, 32]}>
                  <Col span={8}>
                    <Text type="secondary">Reviewed By</Text>
                    <Row>
                      <Text level={4} className="fac-txt-val fac-txt-val-head">
                        {' '}
                        {theReviewer}{' '}
                      </Text>
                    </Row>
                  </Col>
                  <Col span={16}>
                    <Text type="secondary">Approved By</Text>
                    <Row>
                      <Text level={4} className="fac-txt-val fac-txt-val-head">
                        {theApprover}{' '}
                      </Text>
                    </Row>
                  </Col>
                </Row>
              </Form>
              <Row gutter={[0, 40]}>
                {/* <Col span={2}>
                      <Button type="primary" onClick={(e)=>{savePdf(e)}}> Save PDF </Button>
                  </Col> */}
                <Col span={6}>
                  <Button
                    type="primary"
                    onClick={(e) => {
                      downloadPdf(e)
                    }}
                  >
                    {' '}
                    Generate PDF{' '}
                  </Button>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    </Spin>
  )
}
