export const SiderProps = {
  breakpoint: "md",
  collapsedWidth: "0",
  width: 205,
  id: "slc-sider",
  style: {
    background: '#fff',
    position: 'sticky',
    overflow: 'auto',
    left: 0,
    bottom: 0,
    top: 0,
    height: 'auto',
   },
}
