import React from 'react';
import {styled} from "@mui/system";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {LicenseInfo} from "@mui/x-license-pro";
import Box from "@mui/material/Box";

LicenseInfo.setLicenseKey(
  "06c2f0cf50adbcfcc3f1fdcdf8402d39Tz00OTMwMCxFPTE2OTIzNzY1MTM3ODUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export default function HistoricalRelated(props) {
  const {data, disableFlag, fromModal, total, pageState, setPageState, checkboxSelection, setSelectedRelatedAppIds} = props;

  let columns = [
    {
      field: "application_identifier",
      headerName: "Application",
      type: "string",
      flex: 1,
      minWidth: 75,
      renderCell: (record) => {
        return record?.row?.application_identifier && !disableFlag ?
          <a href={`/lease-applications/${record.row.id}`} target="_blank"
             rel="noreferrer">{record?.row?.application_identifier}</a> : "";
      },
    },
    {
      field: "credit_status",
      headerName: "Credit Status",
      type: "string",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "document_status",
      headerName: "Document Status",
      type: "string",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "dealership",
      headerName: "Dealership",
      type: "string",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "lessee",
      headerName: "Lessee",
      type: "string",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "colessee",
      headerName: "Co-Lessee",
      type: "string",
      flex: 1,
      minWidth: 75,
      renderCell: (record) => {
        return record?.row?.colessee_added_after_submission === false ? record?.row?.colessee : '';
      },
    },
    {
      field: "expiration_date",
      headerName: "Expiration Date",
      type: "string",
      flex: 1,
      minWidth: 75,
    }
  ]

  if(!fromModal) {
    columns.push(
      {
        field: "applicaton_type",
        headerName: "Search Matched with",
        type: "string",
        flex: 2,
        minWidth: 75,
        renderCell: (record) => {
          return record?.row?.application_type === 0 ? 'SSN' : 
          record?.row?.application_type === 1 ? 'Full Name':
          record?.row?.application_type === 2 ? 'Home Address':
          record?.row?.application_type === 3 ? 'Phone Number':
          ''
        },
      },
    )
  }

  return <StyledBox>
    <StyledDataGridPro
      disableColumnReorder={true}
      disableSelectionOnClick={true}
      getRowHeight={() => "auto"}
      density="comfortable"
      columns={columns}
      rows={data}
      rowsPerPageOptions={[10, 15]}
      pagination
      rowCount={total ?? 0}
      page={pageState.page <= 0 ? 0 : pageState.page}
      pageSize={pageState.limit}
      paginationMode="server"
      onPageChange={(newPage) => {
        setPageState({...pageState, page: newPage})
      }}
      onPageSizeChange={(newPageSize) => {
        setPageState({...pageState, limit: newPageSize})
      }}
      checkboxSelection={checkboxSelection}
      onSelectionModelChange={(ids) => {
        const selectedIDs = new Set(ids);
        setSelectedRelatedAppIds([...selectedIDs]);
      }}
    />
  </StyledBox>
}

const StyledDataGridPro = styled(DataGridPro)({
  boxShadow: "none !important",
  border: "none !important",
  "& .MuiDataGrid-cell": {
    padding: "10px",
  },
  "& .MuiDataGrid-row": {
    fontSize: 12,
  },
  "& .wrapHeader .MuiDataGrid-colCellTitle": {
    overflow: "hidden",
    lineHeight: "20px",
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: "normal !important",
    lineHeight: "normal",
    overflowWrap: "break-word",
    letterSpacing: "-0.5px",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#e8ecf0",
    color: "#000000",
  },
  "& .MuiChip-label": {
    paddingLeft: "12px",
    paddingRight: "12px",
    whiteSpace: "normal !important",
    wordWrap: " break-word",
    lineHeight: " 1",
    paddingBottom: "2px",
    paddingTop: "2px",
    letterSpacing: "-0.5px",
  },
  "& .MuiBadge-badge": {
    display: "none !important",
  },
  "& .super-app-theme--Filled": {
    backgroundColor: "#ed6248",
    "&:hover": {backgroundColor: "#e93b1b"},
  },
});

const StyledBox = styled(Box)({
  height: 350,
  display: "flex",
  flexGrow: 1,
  width: "100%",
  "& .super-app-theme--header": {
    backgroundColor: "rgba(255, 7, 0, 0.55)",
  },
});
