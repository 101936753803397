import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Form,
  Layout,
  Menu,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import Icon from "@ant-design/icons";
import { GET, POST } from "../../util/network";
import logger from "../../util/logger";
import MainHeader from "../../components/layouts/MainHeader";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import MainFooter from "../../components/layouts/MainFooter";
import { SiderProps } from "../../components/layouts/MainSiderProps";
import { AddNew } from "../../components/layouts/Svg";
import MaxTermLengthForm from "./component/MaxTermLengthForm";

const { Content, Sider } = Layout;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function NewModelGroup(props) {
  const { handleNoAuth } = props;
  const history = useHistory();
  const [modelGroupForm] = Form.useForm();
  const [makeOptions, setMakeOptions] = useState([]);

  const [loading, setLoading] = useState(false);

  const getMakeOptions = async () => {
    {
     setLoading(true);
     try {
       await GET(`/api/v1/makes/list?filter_option=${true}`)
         .then((response) => {
           setLoading(false);
           setMakeOptions(response.data || []);
         })
         .catch((error) => {
           if (error.response && error.response.status === 401) {
            setLoading(false);
             handleNoAuth(history);
           } else {
            setLoading(false);
             message.error("Error while fetching Dealerships Options");
           }
         });
     } catch (e) {
       setLoading(false);
       logger.error("Error while fetching Dealerships Options", e);
     }
   }
 };

  const save = async (values) => {
    {
      setLoading(true);
      try {
        await POST(`/api/v1/model-groups`, values)
          .then(() => {
            setLoading(false);
            message.success("Saved successfully");
            history.push("/new-assets/model-groups/list");
          })
          .catch((error) => {
            message.error("Error while saving!");
            if (error.response && error.response.status === 401) {
              setLoading(false);
              handleNoAuth(history);
            }
          });
      } catch (e) {
        setLoading(false);
        logger.error("Error while saving!", e);
      }
    }
  };

  const convertTermArrayToObject = (maxTermLength) => {
    if (maxTermLength === null || maxTermLength?.length === 0) {
      return null;
    } else {
      let termObject = {};
      for(let i = 0; i < maxTermLength.length; i++ ) {
       termObject[maxTermLength[i].term_key] = parseInt(maxTermLength[i].term_value);
      }
      return termObject;
    }
  }

  const handleSubmit = async (values) => {
    let maxTermLength = values.maximum_term_length_per_year;
    let termObject = convertTermArrayToObject(maxTermLength);
    values = { ...values,
      maximum_term_length_per_year: termObject
     };
    save(values);
  };

  const maximumTermLength = [
    {
      index: 1,
      value: 24,
    },
    {
      index: 2,
      value: 36,
    },
    {
      index: 3,
      value: 48,
    },
    {
      index: 4,
      value: 60,
    },
  ];

  useEffect(() => {
    getMakeOptions();
  }, []);

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <MainHeader activePage="newAssets" />
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{ width: "100%" }}
            >
              <Menu.Item key="1">
                <Icon component={AddNew} />
                Add New
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                { text: "Home", link_type: "linkto", link: "/dashboard" },
                { text: "New Assets", link_type: "ahref", link: void 0 },
                {
                  text: "Model Group",
                  link_type: "ahref",
                  link: "/new-assets/model-groups/list",
                },
                { text: "Add New", link_type: "ahref", link: void 0 },
              ]}
            />

            <Content id="main-content">
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <>
                    <Form
                      form={modelGroupForm}
                      {...layout}
                      colon={false}
                      onFinish={handleSubmit}
                      scrollToFirstError={true}
                      initialValues={{
                        maximum_term_length: 60,
                        maximum_haircut_0: 1.0,
                        maximum_haircut_1: 1.0,
                        maximum_haircut_2: 1.0,
                        maximum_haircut_3: 1.0,
                        maximum_haircut_4: 1.0,
                        maximum_haircut_5: 1.0,
                        maximum_haircut_6: 1.0,
                        maximum_haircut_7: 1.0,
                        maximum_haircut_8: 1.0,
                        maximum_haircut_9: 1.0,
                        maximum_haircut_10: 1.0,
                        maximum_haircut_11: 1.0,
                        maximum_haircut_12: 1.0,
                        maximum_haircut_13: 1.0,
                        maximum_haircut_14: 1.0,
                        maximum_term_length_per_year: [],
                      }}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Card title="Model Group Info">
                            <Row gutter={[24, 16]}>
                              <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                <Form.Item
                                  label="Name"
                                  name="name"
                                  hasFeedback
                                  messageVariables={{ name: "Name" }}
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Make"
                                  name="make_id"
                                  hasFeedback
                                  messageVariables={{ name: "Make" }}
                                  rules={[{ required: true }]}
                                >
                                  <Select>
                                    {makeOptions &&
                                      makeOptions.map(({ id, name }, index) => {
                                        return (
                                          <Option key={index} value={id}>
                                            {name}
                                          </Option>
                                        );
                                      })}
                                  </Select>
                                </Form.Item>

                                <Form.Item
                                  label="Minimum Dealer Participation Cents"
                                  name="minimum_dealer_participation_cents"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Minimum Dealer Participation",
                                  }}
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Backend Advance Minimum Cents"
                                  name="backend_advance_minimum_cents"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Backend Advance Minimum",
                                  }}
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Residual Reduction Percentage"
                                  name="residual_reduction_percentage"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Residual Reduction Percentage",
                                  }}
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Maximum Term Length"
                                  name="maximum_term_length"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Maximum Term Length",
                                  }}
                                  rules={[{ required: true }]}
                                >
                                  <Select>
                                    {maximumTermLength &&
                                      maximumTermLength.map(
                                        ({ value }, index) => {
                                          return (
                                            <Option key={index} value={value}>
                                              {value}
                                            </Option>
                                          );
                                        }
                                      )}
                                  </Select>
                                </Form.Item>

                                <Form.Item
                                  label="JD POWER Model Type Id"
                                  name="model_type_id"
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Maximum Hair Cut 7"
                                  name="maximum_haircut_7"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Maximum Hair Cut 8"
                                  name="maximum_haircut_8"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Maximum Hair Cut 9"
                                  name="maximum_haircut_9"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                
                                <Form.Item
                                  label="Maximum Hair Cut 10"
                                  name="maximum_haircut_10"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                              </Col>

                              <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                <Form.Item
                                  label="Maximum Hair Cut 0"
                                  name="maximum_haircut_0"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Maximum Hair Cut 1"
                                  name="maximum_haircut_1"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Maximum Hair Cut 2"
                                  name="maximum_haircut_2"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Maximum Hair Cut 3"
                                  name="maximum_haircut_3"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 4"
                                  name="maximum_haircut_4"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 5"
                                  name="maximum_haircut_5"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 6"
                                  name="maximum_haircut_6"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 11"
                                  name="maximum_haircut_11"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 12"
                                  name="maximum_haircut_12"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 13"
                                  name="maximum_haircut_13"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 14"
                                  name="maximum_haircut_14"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]} className="mt-20">
                        <Col span={24}>
                          <Card title="Maximum Term Length Per Year">
                            <Row gutter={[24, 16]}>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <MaxTermLengthForm />
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]} className="mt-20">
                        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                          <Row>
                            <Col span={24}>
                              <Form.Item>
                                <Button type="primary" htmlType="submit">
                                  Create Model Group
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    </Spin>
  );
}
