import React from "react";
import { Row, Col, Input, Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function InputText({label, ruleMessage, name, required, validateStatus, help, onPressEnter, onBlur, type, tooltipText}) {
  return (
    <Row style={{ display: type === "hidden" ? "none" : "block" }}>
      <Col span={24}> 
        <Form.Item 
          label={label} 
          hasFeedback
          name={name}
          rules={[{ required: required, message: ruleMessage }]}
          validateStatus={validateStatus}
          help={help}
          tooltip=
            {
              tooltipText ?
                {
                  title: tooltipText,
                  icon: <InfoCircleOutlined/>,
                }
              :
                undefined
            }
          
        >
          <Input placeholder={label} onPressEnter={onPressEnter} onBlur={onBlur} type={type}/>
        </Form.Item>
      </Col>
    </Row> 
  );
}