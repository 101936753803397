import React, {useState} from "react";
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton, Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Delete as DeleteIcon} from "@material-ui/icons";
import {DELETE, POST} from "../../../util/network";
import logger from "../../../util/logger";
import {handleNoAuth} from "../../../util/authenticate";
import {Spin, message} from "antd";
import {useParams} from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Textarea from "@mui/joy/Textarea";
import {useDispatch} from "react-redux"
import {getDealershipActivities} from '../../../reducers/DealershipActivities'
import {Paper} from "@mui/material";
import DialogModal from "../../LeaseApplications/DialogModal";

const useStyles = makeStyles({
  card: {
    // background: 'linear-gradient(135deg, #e8f0fa 30%, #ddeaff 90%)',
    background: "white",
    margin: "auto",
    minHeight: 190,
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.2)",
  },
  list: {
    backgroundColor: "white",
    borderRadius: 4,
    marginTop: 20,
    maxHeight: 200,
    overflow: "auto",
  },
  button: {
    marginTop: 14,
    marginLeft: 12,
    background: "#ff3500",
  },

  textfield: {
    width: "100%",
    marginBottom: 20,
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: 'rgb(0 0 0 / 42%)'
    },
    '& .MuiInput-underline.Mui-error:before': {
      borderBottomColor: 'rgb(0 0 0 / 42%)'
    },
    '& .MuiAutocomplete-input': {
      borderBottomColor: 'rgb(0 0 0 / 42%)'
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: 'rgb(0 0 0 / 42%)'
    }
  },

  timeField: {
    width: "100%",
    marginBottom: 20,
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: 'rgb(0 0 0 / 42%)'
    },
    '& .MuiInput-underline.Mui-error:before': {
      borderBottomColor: 'rgb(0 0 0 / 42%)'
    },
    '& .MuiAutocomplete-input': {
      borderBottomColor: 'rgb(0 0 0 / 42%)'
    },
    '& .MuiFormLabel-root.Mui-error': {
      color: 'rgb(0 0 0 / 42%)'
    }
  },
});

const LogCallCard = (props) => {
  const classes = useStyles();
  const {currentAdminUser, adminUsers, LogCalls} = props;
  let {id} = useParams();
  const emptyCallInfo = {
    callTime: '',
    callee: "",
    notes: "",
    followUpCall: '',
    adminUser: null,
    adminUserId: null,
  }
  const [callInfo, setCallInfo] = useState(emptyCallInfo);
  const [loading, setLoading] = useState(false);
  const [logCallId, setLogCallId] = useState();
  const [open, setOpen] = useState(false);
  const [isLogCallInvalid, setIsLogCallInvalid] = useState(false);
  const [isFollowupCallInvalid, setIsFollowupCallInvalid] = useState(false);
  const dispatch = useDispatch()

  const handleChange = (event) => {
    if (callInfo.adminUser === null && callInfo.adminUserId === null) {
      setCallInfo({
        ...callInfo,
        [event.target.name]: event.target.value,
        adminUser: currentAdminUser,
        adminUserId: currentAdminUser?.id,
      });
    } else {
      setCallInfo({
        ...callInfo,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleDateTime = (event, attribute) => {
    let value = event.target.value
    let dt = new Date(value);
    if (!isNaN(dt)) {
      if (attribute === "callTime") {
        if (dt <= Date.now()) {
          setIsLogCallInvalid(false);
        } else {
          setIsLogCallInvalid(true);
        }
      } else {
        if (dt <= Date.now()) {
          setIsFollowupCallInvalid(true);
        } else {
          setIsFollowupCallInvalid(false);
        }
      }

      if (callInfo.adminUser === null && callInfo.adminUserId === null) {
        setCallInfo({
          ...callInfo,
          [attribute]: value,
          adminUser: currentAdminUser,
          adminUserId: currentAdminUser?.id
        });
      } else {
        setCallInfo({...callInfo, [attribute]: value});
      }
    } else {
      if (attribute === "callTime" && value !== '') {
        setIsLogCallInvalid(true);
      } else if (attribute === "callTime" && value === '') {
        setIsLogCallInvalid(false);
        setCallInfo({...callInfo, [attribute]: ''});
      } else if (attribute === "followUpCall" && value !== '') {
        setIsFollowupCallInvalid(true);
      } else if (attribute === "followUpCall" && value === '') {
        setCallInfo({...callInfo, [attribute]: ''});
        setIsFollowupCallInvalid(false);
      }
    }
  };

  const handleAssignedTo = (value) => {
    setCallInfo({
      ...callInfo,
      adminUser: value,
      adminUserId: value?.id,
    });
  };

  const handleCallLog = async () => {
    setLoading(true);
    try {
      const response = await POST(
        `/api/v1/dealerships/${id}/log-call`,
        callInfo
      );
      setCallInfo({...emptyCallInfo});
      dispatch(getDealershipActivities({response: response.data}))
      if (callInfo.callType === "logged") {
        message.success("Call logged successfully");
      } else {
        message.success("Call scheduled successfully");
      }
    } catch (e) {
      logger.error("Call Log Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error("Error on logging call.");
      }
    }
    setLoading(false);
  };

  const handleLogCall = () => {
    callInfo.callType = "logged";
    handleCallLog();
  };

  const handleScheduleCall = () => {
    callInfo.callType = "scheduled";
    handleCallLog();
  };

  const handleLogAndScheduleCall = () => {
    callInfo.callType = "log_and_scheduled";
    handleCallLog();
  };

  const handleDelete = (logCallId) => {
    setLogCallId(logCallId);
    setOpen(true);
  };

  const secondaryTexts = (meeting) => {
    if (meeting?.notes.length > 0) {
      return 'Note: ' + meeting?.notes
    } else {
      return ''
    }
  }

  const closeDialog = () => {
    setOpen(false);
  }

  const completeDeletion = () => {
    deleteLogCall();
    closeDialog();
  }

  const deleteLogCall = async () => {
    setLoading(true);
    try {
      const response = await DELETE(`/api/v1/dealerships/${id}/log-call/${logCallId}`);
      dispatch(getDealershipActivities({response: response.data}))
      message.success("Successfully deleted log call.");
    } catch (e) {
      logger.error("Call Log Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error("Error on log call deletion.");
      }
    }
    setLoading(false);
  };

  const buttonValidation = (callType) => {
    if (callType === "callTime") {
      return !callInfo.callee || !callInfo.adminUserId || !callInfo.callTime || callInfo.followUpCall || isLogCallInvalid;
    } else if (callType === "followUpCall") {
      return !callInfo.callee || !callInfo.adminUserId || !callInfo.followUpCall || callInfo.callTime || isLogCallInvalid || isFollowupCallInvalid;
    } else if (callType === "logAndFollowUpCall") {
      return !callInfo.callee || !callInfo.adminUserId || !callInfo.callTime || !callInfo.followUpCall || isLogCallInvalid || isFollowupCallInvalid;
    }
  };

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TextField
              id="datetime-local"
              label="Date Time"
              type="datetime-local"
              value={callInfo.callTime}
              onChange={(e) => handleDateTime(e, "callTime")}
              className={classes.timeField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </LocalizationProvider>
          {isLogCallInvalid &&
            <Typography style={{color: "#FF0000"}} variant="caption">* Please provide a valid previous
              timestamp</Typography>}
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TextField
              id="followUpCall-local"
              label="Follow Up Call"
              type="datetime-local"
              value={callInfo.followUpCall}
              onChange={(e) => handleDateTime(e, "followUpCall")}
              className={classes.timeField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </LocalizationProvider>
          {isFollowupCallInvalid &&
            <Typography style={{color: "#FF0000"}} variant="caption">* Please provide a valid future
              timestamp</Typography>}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            name="callee"
            label="Who You Speak To"
            value={callInfo.callee}
            onChange={handleChange}
            className={classes.textfield}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            key={loading}
            id="combo-box-demo"
            value={callInfo.adminUser}
            getOptionLabel={(option) => option?.label || ''}
            onChange={(event, newValue) => {
              handleAssignedTo(newValue);
            }}
            options={adminUsers}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Assigned To"/>
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Textarea
            name="notes"
            placeholder="Notes..."
            minRows={2}
            value={callInfo.notes}
            onChange={handleChange}
            className={classes.textfield}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleLogCall}
          disabled={buttonValidation("callTime")}
        >
          Log Call
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleScheduleCall}
          disabled={buttonValidation("followUpCall")}
        >
          Schedule Call
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleLogAndScheduleCall}
          disabled={buttonValidation("logAndFollowUpCall")}
        >
          Log and Schedule Call
        </Button>
      </Grid>
      {LogCalls.length > 0 && (
        <div className={classes.list}>
          <Typography variant="h6" style={{marginTop: 10}} gutterBottom>
            <b>Log Call List:</b>
          </Typography>
          <Paper style={{maxHeight: 150, overflow: 'auto'}}>
            <List>
              {LogCalls.map((log) => (
                <ListItem key={log.id}>
                  <Grid md={7}>
                    <ListItemText
                      primary={log?.adminUser + " with " + log?.callee}
                      secondary={secondaryTexts(log)}
                    />
                  </Grid>
                  <Grid md={4}>
                    <ListItemText primary={log?.callTime}/>
                  </Grid>
                  <Grid md={1}>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        style={{color: 'red'}}
                        onClick={() => handleDelete(log.id)}
                      >
                        <DeleteIcon/>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </Grid>
                </ListItem>
              ))}
            </List>
          </Paper>
          <DialogModal
            modalState={open}
            modalClose={closeDialog}
            submitFunction={completeDeletion}
            contentData={""}
            title={`Are you sure you want to delete this Log call?`}
          />
        </div>
      )}
    </Spin>
  );
};

export default LogCallCard;
