import React, { useContext, useState, useEffect, useRef } from 'react';
import { PUT } from '../../../util/network';
import logger from '../../../util/logger';
import { Form, Input, Row, Col, Table, message, Select } from "antd";
import LeaseStipulationListStatus from './LeaseStipulationListStatus';
import StipulationParagraph from "./StipulationParagraph";
import "./LeaseStipulation.css";

const { Option } = Select;
const EditableContext = React.createContext();

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({  
  editable,
  children,
  dataIndex,
  record,
  data,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      // toggleEdit();
      handleSave({ ...record, ...values }, dataIndex);
    } catch (errInfo) {
      //console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  let name = dataIndex === 'attachment' ? 'leaseApplicationAttachmentId' : dataIndex

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={name}
      >
        {
          (dataIndex === 'attachment') ? (
            <Select placeholder="Please select an attachment" ref={inputRef} onChange={save}>
            {data && data.stipulationLeaseApplicationAttachments.map((val,index) => {
                  return (
                      <Option key = {index} value={val[1]} >{val[0]}</Option>
                  )
              })}
          </Select>
          ) : (
            <Input ref={inputRef} onPressEnter={save} onBlur={save} />
          )
        }

      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default function LeaseStipulationList({ data }) {
    const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
    let loggedInUserId = null;
    if(authData !== null) {
        loggedInUserId = authData.id;
    }
    const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
    const disableFlag = locked_by != loggedInUserId ? true : false

    const columns = [
        {
            title: 'Stipulation',
            dataIndex: 'stipulation',
            key: 'stipulation',
            editable: false,
            render(stipulation) {
                return (
                    <StipulationParagraph>{stipulation}</StipulationParagraph>
                )
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            editable: false,
            render(val, row) {
                return (
                    <LeaseStipulationListStatus val={val} row={row} disabled={disableFlag} />
                )
            }
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            editable: !disableFlag,
        },
        {
            title: 'Attachments',
            dataIndex: 'attachment',
            key: 'attachment',
            editable: !disableFlag,
        }

    ];

    const [dataSource, setDataSource] = useState([])
    useEffect(() => {
      setDataSource(data ? data.leaseApplicationStipulations : [])
    }, [data])

    const saveApplicationDetails = async (appNumber, newData, dataIndex) => {
      try {
        await PUT(`/api/v1/lease_applications/details?application_identifier=${appNumber}`, newData);
        message.success(`${dataIndex.charAt(0).toUpperCase() + dataIndex.slice(1)} saved successfully`);
      } catch (e) {
        logger.error("Application Details Error", e);
        message.error("Error while saving your data");
      }
    };

    const handleSave = (row, dataIndex) => {
      let newNotes = {
        lease_application_stipulations_attributes : {
          id: row.id,
          notes: row.notes,
          leaseApplicationAttachmentId: row.leaseApplicationAttachmentId
        }
      }
      saveApplicationDetails(row.applicationIdentifier, newNotes, dataIndex)
    };

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };
    const newColumns = columns.map(col => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          data: data,
          handleSave: handleSave,
        }),
      };
    });

    return (
        <Row>
            <Col span={24}>
                <Table
                  bordered
                  rowKey = "id"
                  components={components}
                  rowClassName={() => 'editable-row'}                  
                  dataSource={dataSource}
                  columns={newColumns}
                  size="small"
                  pagination={true}
              />            
            </Col>
        </Row>
    );
}