import React, {useState} from "react";
import {
  Layout,
  Menu,
  Spin,
  Card, Button,
} from "antd";
import {styled} from '@mui/material'
import {Table, TableHead, TableBody, TableContainer, TableRow} from '@mui/material'
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import {SiderProps} from "../../components/layouts/MainSiderProps";
import {AddNew} from "../../components/layouts/Svg";
import Icon from "@ant-design/icons";
import Box from '@mui/material/Box';
import Typography from "@material-ui/core/Typography";
import {useHistory} from "react-router-dom";

const {Content, Sider} = Layout;

export default function ApplicationWelcomeCallView(props) {
  const {data} = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{width: "100%"}}
            >
              <Menu.Item key="1">
                <Icon component={AddNew}/>
                Document Request
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                {text: "Home", link_type: "linkto", link: "/dashboard"},
                {text: "Lease Applications", link_type: "ahref", link: '/lease-applications-list'},
                {
                  text: `${data?.applicationIdentifier}`,
                  link_type: "ahref",
                  link: `/lease-applications/${data?.leaseApplicationId}`
                },
                {
                  text: "Lease Document Requests",
                  link_type: "ahref",
                  link: "/lease-document-request",
                },
                {text: "Details", link_type: "ahref", link: void 0},
              ]}
            />

            {data && (
              <Box sx={{flexGrow: 1}}>
                <Grid container direction="row" style={{height: "auto"}}>
                  <Grid item md={12} container spacing={2}>
                    <Grid container direction="row" md={12} sm={12} lg={12} style={{marginTop: '5px'}}>
                      <Grid item md={8} sm={6} style={{paddingLeft: '20px'}}>
                        <Typography variant={"h4"}>Lease document request #{data.id}</Typography>
                      </Grid>
                      <Grid item md={4} sm={6} style={{display: "flex", justifyContent: "right"}}>
                        <Button
                          variant="text"
                          onClick={() => {
                            history.push({
                              pathname: `/lease_document_request_edit/${data.id}`,
                            });
                          }}
                        >
                          Edit Lease Document Request
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} lg={12}>
                      <Card title="Lease Document Request Details">
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TableContainer component={Box}>
                              <Table aria-label="customized table">
                                <TableBody>
                                  <StyledTableRow>
                                    <StyledtableCell1>ID</StyledtableCell1>
                                    <StyledtableCell2>{data.id}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>LEASE APPLICATION</StyledtableCell1>
                                    <StyledtableCell2>
                                      <a
                                        href={`/lease-applications/${data.leaseApplicationId}`}>{data.applicationIdentifier}</a>
                                    </StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>ASSET MAKE</StyledtableCell1>
                                    <StyledtableCell2>{data.assetMake}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>ASSET MODEL</StyledtableCell1>
                                    <StyledtableCell2>{data.assetModel}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>ASSET YEAR</StyledtableCell1>
                                    <StyledtableCell2>{data.assetYear}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>ASSET VIN</StyledtableCell1>
                                    <StyledtableCell2>{data.assetVin}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>ASSET COLOR</StyledtableCell1>
                                    <StyledtableCell2>{data.assetColor}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>EXACT ODOMETER MILEAGE</StyledtableCell1>
                                    <StyledtableCell2>{data.exactOdometerMileage}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>TRADE IN MAKE</StyledtableCell1>
                                    <StyledtableCell2>{data.tradeInMake}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>TRADE IN MODEL</StyledtableCell1>
                                    <StyledtableCell2>{data.tradeInModel}</StyledtableCell2>
                                  </StyledTableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                          <Grid item xs={6}>
                            <TableContainer component={Box}>
                              <Table aria-label="customized table">
                                <TableBody>
                                  <StyledTableRow>
                                    <StyledtableCell1>TRADE IN YEAR</StyledtableCell1>
                                    <StyledtableCell2>{data.tradeInYear}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>DELIVERY DATE</StyledtableCell1>
                                    <StyledtableCell2>{data.deliveryDateFormat}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>GAP CONTRACT TERM</StyledtableCell1>
                                    <StyledtableCell2>{data.gapContractTerm}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>SERVICE CONTRACT TERM</StyledtableCell1>
                                    <StyledtableCell2>{data.serviceContractTerm}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>PPM CONTRACT TERM</StyledtableCell1>
                                    <StyledtableCell2>{data.ppmContractTerm}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>TIRE CONTRACT TERM</StyledtableCell1>
                                    <StyledtableCell2>{data.tireContractTerm}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>EQUIPPED WITH</StyledtableCell1>
                                    <StyledtableCell2>{data.equippedWith}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>Note</StyledtableCell1>
                                    <StyledtableCell2>{data.notes}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>CREATED AT</StyledtableCell1>
                                    <StyledtableCell2>{data.createdAt}</StyledtableCell2>
                                  </StyledTableRow>
                                  <StyledTableRow>
                                    <StyledtableCell1>UPDATED AT</StyledtableCell1>
                                    <StyledtableCell2>{data.updatedAt}</StyledtableCell2>
                                  </StyledTableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      </Card>
                      <Box marginTop={2}>
                        <Card title="VIN Verification Details" marginTop={2}>
                          <TableContainer component={Box}>
                            <Table aria-label="customized table">
                              <TableBody>
                                <StyledTableRow>
                                  <StyledtableCell1>ID</StyledtableCell1>
                                  <StyledtableCell2>{data.vinValidationData.id}</StyledtableCell2>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledtableCell1>STATUS</StyledtableCell1>
                                  <StyledtableCell2>{data.vinValidationData.status}</StyledtableCell2>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledtableCell1>NPA VALIDATION RESPONSE</StyledtableCell1>
                                  <StyledtableCell2>{data.vinValidationData.npaValidationResponse}</StyledtableCell2>
                                </StyledTableRow>
                                <StyledTableRow>
                                  <StyledtableCell1>VALIDATION RESPONSE</StyledtableCell1>
                                  <StyledtableCell2>{data.vinValidationData.validationResponse}</StyledtableCell2>
                                </StyledTableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Card>
                      </Box>
                      <Box marginTop={2}>
                        <Card title="Audits" marginTop={2}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>CREATED AT</TableCell>
                                <TableCell>DOCUMENT REQUEST</TableCell>
                                <TableCell>BY WHO</TableCell>
                                <TableCell>CHANGES</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.reteiveAuditsData.map((item) => (
                                <TableRow key={item.id}>
                                  <TableCell>{item.createdAt}</TableCell>
                                  <TableCell>{item.auditedType}</TableCell>
                                  <TableCell>{item.auditedUser}</TableCell>
                                  <TableCell>
                                    <div dangerouslySetInnerHTML={{__html: item.auditedChanges}}></div>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Card>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Layout>
        </Layout>
      </Layout>
    </Spin>
  )
}

const StyledtableCell1 = styled(TableCell)({
  fontWeight: "bold",
  fontSize: "15px",
  width: "300px",
})

const StyledTableRow = styled(TableRow)({
  "& td": {border: 0, padding: 15},
})

const StyledtableCell2 = styled(TableCell)({
  fontSize: "15px"
})
