import React, {useState, useEffect} from "react";
import {Col, Layout, Menu, message, Row, Select, Spin} from "antd";
import Icon from "@ant-design/icons";
import {GET, POST, PUT} from "../../util/network";
import logger from "../../util/logger";
import MainHeader from "../../components/layouts/MainHeader";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import MainFooter from "../../components/layouts/MainFooter";
import {SiderProps} from "../../components/layouts/MainSiderProps";
import {LesseeSvg} from "../../components/layouts/Svg";
import {GetResourceActions, IsActionAllowed} from "../../util/AllowedActions";
import {useHistory, useLocation} from "react-router-dom";
import ContactForm from "./components/ContactForm";
import ContactCreateForm from "./components/ContactCreateForm";

const {Content, Sider} = Layout;

export default function ContactRender(props) {
  const location = useLocation();
  const {allowedActions, handleNoAuth} = props;
  const adminUserActions = GetResourceActions("AdminUser", allowedActions);
  const allowedGet = IsActionAllowed("get", adminUserActions);
  const allowedUpdate = IsActionAllowed("update", adminUserActions);
  const {id} = props.match.params;
  const [loading, setLoading] = useState(false);
  const [contactData, setContactData] = useState();
  const [activeMenu, setActiveMenu] = useState([1]);
  const [actionType, setActionType] = useState('DETAILS');
  const [dealershipId, setDealershipId] = useState(null);
  const [isDisable, setIsDisable] = useState(location.state?.actionType === 'DETAILS');
  const [dealershipOptions, setDealershipOptions] = useState([]);
  const history = useHistory();

  const getContact = async () => {
    setLoading(true);
    if (allowedGet) {
      try {
        const response = await GET(`/api/v1/contacts/${id}?dealership_id=${location.state?.dealershipId}`);
        setContactData(response.data.contact);
      } catch (e) {
        logger.error("Error while fetching Contact", e);
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error(e.response?.data?.message);
        }
      }
    }
    setLoading(false);
  };

  const getDealershipOptions = async () => {
    if (allowedGet) {
      setLoading(true);
      try {
        const response = await GET(`/api/v1/dealerships/dealerships-options`);
        setDealershipOptions(response.data?.dealerships_options);
      } catch (e) {
        logger.error("Error while fetching Dealerships.", e);
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error("Error while fetching Dealerships.");
        }
      }
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    if (allowedUpdate) {
      try {
        if (contactData?.id === undefined) {
          await POST(`/api/v1/contacts`, values);
        } else {
          await PUT(`/api/v1/contacts/${contactData?.id}`, values);
        }
        message.success("Saved successfully");
        history.push(`/contacts`);
      } catch (e) {
        logger.error("Error while saving!", e);
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error("Error while saving!");
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setActionType(location.state?.actionType || "DETAILS");
    setDealershipId(location.state?.dealershipId);
    if (id !== "create") {
      getContact();
    }
    getDealershipOptions()
  }, [setActionType, id]);

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <MainHeader activePage="dealers"/>
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={[1]}
              selectedKeys={[activeMenu.toString()]}
              style={{width: "100%"}}
              onSelect={({key}) => {
                if (!key.includes("sub")) {
                  setActiveMenu([...activeMenu, key]);
                }
              }}
            >
              <Menu.Item key="1">
                <Icon component={LesseeSvg}/>
                Contact
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            {actionType && (
              <MainBreadcrumb
                items={[
                  {text: "Home", link_type: "linkto", link: "/dashboard"},
                  {text: "Contacts", link_type: "ahref", link: "/contacts"},
                  {text: `${id}`, link_type: "ahref", link: void 0},
                ]}
              />
            )}

            <Content id="main-content">
              <Row gutter={[0, 24]}>
                {dealershipId !== null &&
                  <Col span={24}>
                    {id !== 'create' ? contactData && actionType &&
                      <ContactForm
                        contactData={contactData}
                        dealershipOptions={dealershipOptions}
                        isDisable={isDisable}
                        handleSubmit={handleSubmit}
                        setIsDisable={setIsDisable}
                      />
                      : <ContactCreateForm
                        dealershipOptions={dealershipOptions}
                        setLoading={setLoading}
                      />}
                  </Col>}
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter/>
      </Layout>
    </Spin>
  );
}
