import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {message, Spin} from "antd";
import {GetResourceActions, IsActionAllowed} from "../../util/AllowedActions";
import {Layout} from "antd";
import MainHeader from "../../components/layouts/MainHeader";
import MainFooter from "../../components/layouts/MainFooter";
import {GET} from "../../util/network";
import logger from "../../util/logger";
import ContactList from "./components/ContactList";

export default function ContactsRenderer(props) {
  const {handleNoAuth, allowedActions} = props;
  const history = useHistory();
  const dealershipActions = GetResourceActions("Dealership", allowedActions);
  const allowedGet = IsActionAllowed("get", dealershipActions);
  const [loading, setLoading] = useState(false);
  const initialState = {
    total: 0,
    page: 0,
    limit: 50,
    search: "",
  };
  const [pageState, setPageState] = useState({...initialState});
  const [contactData, setContactData] = useState([]);

  const getContacts = async () => {
    if (allowedGet) {
      setLoading(true);
      try {
        let encodedParam = Object.entries(pageState).map(([key, val]) => `${key}=${val}`).join('&');
        const response = await GET(`/api/v1/contacts?${encodedParam}`);
        setContactData(response.data);
      } catch (e) {
        logger.error("Error while fetching call logs.", e);
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error("Error while fetching call logs.");
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getContacts();
  }, [pageState]);

  return (
    <Layout>
      <MainHeader activePage="dealers"/>
      <Layout>
        <Spin spinning={loading}>
          <ContactList contacts={contactData.contacts} allowedGet={allowedGet} total={contactData.total}
                       pageState={pageState} setPageState={setPageState} setLoading={setLoading} setContactData={setContactData}/>
        </Spin>
      </Layout>
      <MainFooter/>
    </Layout>
  );
}
