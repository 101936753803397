import React from 'react';
import { useHistory } from 'react-router-dom';
import logoImage from '../../assets/SpeedLeasing-Logo-Final_Light.png';

function Logo() {
    const history = useHistory();
    return (
        <div className="logo" >
        <img className="logo-img" onClick={()=>{history.push('/lease-applications-list')}} src={logoImage} alt="SpeedLeasing"/>
      </div>
    )
}

export default Logo
