import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Form,
  Layout,
  Menu,
  message,
  Row,
  Select,
  Spin,
  DatePicker,
  Radio
} from "antd";
import Icon from "@ant-design/icons";
import { GET, PUT } from "../../util/network";
import logger from "../../util/logger";
import MainHeader from "../../components/layouts/MainHeader";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import MainFooter from "../../components/layouts/MainFooter";
import { SiderProps } from "../../components/layouts/MainSiderProps";
import { AddNew } from "../../components/layouts/Svg";
import { GetResourceActions, IsActionAllowed } from "../../util/AllowedActions";
import moment from 'moment';

const { Content, Sider } = Layout;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};


export default function UpdateModelYear(props) {
    const { id } = props.match.params;
    const { allowedActions, handleNoAuth } = props;
    const history = useHistory();
    const adminUserActions = GetResourceActions("AdminUser", allowedActions);
    const allowedCreate = IsActionAllowed("create", adminUserActions);
    const [modelYearForm] = Form.useForm();
    const [modelGroupOptions,setModelGroupOptions] = useState([]);
    const [data, setData] = useState();
  
    const [loading, setLoading] = useState(false);

    const getModelGroupOptions = async () => {
        {
          setLoading(true);
          try {
            await GET(`/api/v1/model-groups/list`)
              .then((response) => {
                setModelGroupOptions(response.data.data||[])
              })
              .catch((error) => {
                if (error.response && error.response.status === 401) {
                  handleNoAuth(history);
                } else {
                  message.error("Error while fetching model group Options");
                }
              });
          } catch (e) {
            logger.error("Error while fetching Model Group Options", e);
          }
          setLoading(false);
        }
      };
    
    const getModelYearData = async () => {
        setLoading(true);
        try {
          await GET(`/api/v1/model_years/details/${id}`)
            .then((response) => {
                setData(response.data.model_years|| [])
            })
            .catch((error) => {
              if (error.response && error.response.status === 401) {
                handleNoAuth(history);
              } else {
                message.error("Error while fetching model year data");
              }
            });
        } catch (e) {
          logger.error("Error while fetching model year data", e);
        }
        setLoading(false);
    }

    const save = async (values) => {
        {
          setLoading(true);
          try {
            await PUT(`/api/v1/model_years/${id}`, values)
              .then(() => {
                history.push(`/new-assets/model-years/details/${id}`);
                message.success("Updated successfully");
              })
              .catch((error) => {
                if (error.response && error.response.status === 401) {
                  handleNoAuth(history);
                  message.error("Error while updating!");
                }
              });
          } catch (e) {
            logger.error("Error while saving!", e);
          }
          setLoading(false);
        }
      };

      const handleSubmit = async (values) => {
        values = { ...values };
        save(values);
      };

      useEffect(() => {
        getModelGroupOptions();
        getModelYearData();
      }, []);

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <MainHeader activePage="newAssets" />
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{ width: "100%" }}
            >
              <Menu.Item key="1">
                <Icon component={AddNew} />
                Edit
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                { text: "Home", link_type: "linkto", link: "/dashboard" },
                { text: "New Assets", link_type: "ahref", link: void 0 },
                {
                  text: "Model Year Details",
                  link_type: "ahref",
                  link: `/new-assets/model-years/details/${id}`,
                },
                { text: "Edit", link_type: "ahref", link: void 0 },
              ]}
            />

            <Content id="main-content">
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  {data && (
                    <Form
                      form={modelYearForm}
                      {...layout}
                      colon={false}
                      initialValues={{
                        model_group_id: data?.model_group_id,
                        name: data?.name,
                        maximum_haircut_0: data?.maximum_haircut_0,
                        maximum_haircut_1: data?.maximum_haircut_1,
                        maximum_haircut_2: data?.maximum_haircut_2,
                        maximum_haircut_3: data?.maximum_haircut_3,
                        maximum_haircut_4: data?.maximum_haircut_4,
                        maximum_haircut_5: data?.maximum_haircut_5,
                        maximum_haircut_6: data?.maximum_haircut_6,
                        maximum_haircut_7: data?.maximum_haircut_7,
                        maximum_haircut_8: data?.maximum_haircut_8,
                        maximum_haircut_9: data?.maximum_haircut_9,
                        maximum_haircut_10: data?.maximum_haircut_10,
                        maximum_haircut_11: data?.maximum_haircut_11,
                        maximum_haircut_12: data?.maximum_haircut_12,
                        maximum_haircut_13: data?.maximum_haircut_13,
                        maximum_haircut_14: data?.maximum_haircut_14,
                        maximum_term_length: data?.maximum_term_length,
                        residual_24_cents: data?.residual_24_cents,
                        residual_36_cents: data?.residual_36_cents,
                        residual_48_cents: data?.residual_48_cents,
                        residual_60_cents: data?.residual_60_cents,
                        original_msrp_cents: data?.original_msrp_cents,
                        start_date: moment(data?.start_date, "YYYY-MM-DD"),
                        end_date: moment(data?.end_date),
                        year: moment(data?.year?.toString()),
                        slc_model_group_mapping_flag: true,
                        nada_avg_retail_cents: data?.nada_avg_retail_cents,
                        nada_rough_cents: data?.nada_rough_cents,
                      }}
                      onFinish={handleSubmit}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Card title="Edit Model Years">
                            <Row gutter={[24, 16]}>
                              <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                <Form.Item
                                  label="Model Group"
                                  name="model_group_id"
                                  hasFeedback
                                  rules={[
                                    {
                                      required: true,
                                      message: "Model Group is required!",
                                    },
                                  ]}
                                >
                                  <Select>
                                    {modelGroupOptions &&
                                      modelGroupOptions.map(
                                        ({ id, name, make }, index) => {
                                          return (
                                            <Option key={index} value={id}>
                                              {`${name}(${make})`}
                                            </Option>
                                          );
                                        }
                                      )}
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  label="Name"
                                  name="name"
                                  hasFeedback
                                  messageVariables={{ name: "Name" }}
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Start Date"
                                  name="start_date"
                                  hasFeedback
                                  messageVariables={{ name: "Start_Date" }}
                                  rules={[{ required: true }]}
                                >
                                  <DatePicker />
                                </Form.Item>

                                <Form.Item
                                  label="End Date"
                                  name="end_date"
                                  hasFeedback
                                  messageVariables={{ name: "End_Date" }}
                                  rules={[{ required: true }]}
                                >
                                  <DatePicker />
                                </Form.Item>

                                <Form.Item
                                  label="Year"
                                  name="year"
                                  hasFeedback
                                  messageVariables={{ name: "Year" }}
                                  rules={[{ required: true }]}
                                >
                                  <DatePicker picker="year" />
                                </Form.Item>

                                <Form.Item
                                  label="Original msrp"
                                  name="original_msrp_cents"
                                  hasFeedback
                                  messageVariables={{
                                    name: "original_msrp_cents",
                                  }}
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                      label="Avg Retail(In cents)"
                                      name="nada_avg_retail_cents"
                                      hasFeedback
                                      messageVariables={{
                                          name: "nada_avg_retail_cents",
                                      }}
                                      rules={[{ required: true }]}
                                  >
                                      <Input />
                                  </Form.Item>

                                  <Form.Item
                                      label="Rough Value(In cents)"
                                      name="nada_rough_cents"
                                      hasFeedback
                                      messageVariables={{
                                          name: "nada_rough_cents",
                                      }}
                                      rules={[{ required: true }]}
                                  >
                                      <Input />
                                  </Form.Item>


                                <Form.Item
                                  label="Residual 24"
                                  name="residual_24_cents"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Residual 24",
                                  }}
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Residual 36"
                                  name="residual_36_cents"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Residual 36",
                                  }}
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Residual 48"
                                  name="residual_48_cents"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Residual 48",
                                  }}
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Residual 60"
                                  name="residual_60_cents"
                                  hasFeedback
                                  messageVariables={{
                                    name: "Residual 60",
                                  }}
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Maximum Term Length"
                                  name="maximum_term_length"
                                >
                                  <Input />
                                </Form.Item>

                              <Form.Item
                                  label="SLC Model Group Mapping Flag"
                                  name="slc_model_group_mapping_flag"
                                  hasFeedback
                                  messageVariables={{
                                      name: "Residual 60",
                                  }}
                                  rules={[{ required: true }]}
                              >
                                  <Radio.Group>
                                      <Radio value={true}>Yes</Radio>
                                      <Radio value={false}>No</Radio>
                                  </Radio.Group>
                              </Form.Item>

                                <Form.Item
                                  label="Maximum Hair Cut 0"
                                  name="maximum_haircut_0"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                              </Col>

                              <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                              <Form.Item
                                  label="Maximum Hair Cut 1"
                                  name="maximum_haircut_1"
                                  hasFeedback
                              >
                                  <Input />
                              </Form.Item>

                                <Form.Item
                                  label="Maximum Hair Cut 2"
                                  name="maximum_haircut_2"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Maximum Hair Cut 3"
                                  name="maximum_haircut_3"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 4"
                                  name="maximum_haircut_4"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 5"
                                  name="maximum_haircut_5"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 6"
                                  name="maximum_haircut_6"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 7"
                                  name="maximum_haircut_7"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 8"
                                  name="maximum_haircut_8"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 9"
                                  name="maximum_haircut_9"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 10"
                                  name="maximum_haircut_10"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 11"
                                  name="maximum_haircut_11"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 12"
                                  name="maximum_haircut_12"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 13"
                                  name="maximum_haircut_13"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                                <Form.Item
                                  label="Maximum Hair Cut 14"
                                  name="maximum_haircut_14"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]} className="mt-20">
                        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                          <Row>
                            <Col span={24}>
                              <Form.Item>
                                <Button type="primary" htmlType="submit">
                                  Update
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    </Spin>
  );
}
