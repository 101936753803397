import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import AssignmentReturnedSharpIcon from '@mui/icons-material/AssignmentReturnedSharp';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { GET, POST, PUT } from '../../../util/network'

const useStyles = makeStyles((theme) => ({
    card: {
        marginBottom: theme.spacing(3),
        height: 430,
        overflow: 'auto'
    },
    title: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
    },
    firstCard: {
      boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)'
    }
}));

const ActivityTimeline = (props) => {
    const { activityTimelines } = props
    const classes = useStyles();
    const redirectReference = (resourceType, data) => {
        if (resourceType === 'lease_app') {
            return (
              <Link
                to={`/lease-applications/${data.app_identifier}`}
                target="_blank"
                rel="noreferrer"
              >
                {data.text}
              </Link>
            );
        } else {
            return (
              <Link
                to={`/dealer-users/${data.app_identifier}`}
                target="_blank"
                rel="noreferrer"
              >
                {data.text}
              </Link>
            );
        }
    }

    return (
        activityTimelines &&
        <Card className={[classes.card,classes.firstCard]} >
            <CardContent>
                <Timeline>
                    {activityTimelines?.map((activity, index) => (
                        <TimelineItem key={index} sx={{
                           float: "right",
                        }}>
                            <TimelineOppositeContent style={{ flex: 0 }}/>
                            <TimelineSeparator>
                                <TimelineDot color={(index + 1) % 2 == 0 ? 'secondary' : 'primary'} />
                                {index !== activityTimelines.length - 1 && <TimelineConnector />}
                            </TimelineSeparator>
                            <TimelineContent>
                                {/* <Typography>{activity.text}</Typography> */}
                                {
                                    activity.type ?
                                        (redirectReference(activity.type, activity))
                                        :
                                        (<Typography>{activity.text}</Typography>)
                                }
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            </CardContent>
        </Card>
    );
};

export default ActivityTimeline;