import React from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import {styled} from '@mui/system';
import Box from '@mui/material/Box';

export default function DealershipGroup(props) {
  const {filterDefaultData, newFilterState, setNewFilterState, filterValues, setFilterValues} = props;
  const isDealershipIdPresent = () => {
    return filterValues.dealershipId !== "" && filterValues.dealershipId !== null && filterValues.dealershipId !== undefined;
  }
  const isDealerUserDealershipIdPresent = () => {
    return filterValues.dealerUserDealershipId !== "" && filterValues.dealerUserDealershipId !== null && filterValues.dealerUserDealershipId !== undefined;
  }

  const isDealershipStatePresent = () => {
    return filterValues.dealershipState !== "" && filterValues.dealershipState !== null && filterValues.dealershipState !== undefined;
  }

  const fetchDealerships = () => {
    if (isDealerUserDealershipIdPresent()) {
      return filterDefaultData.dealership?.filter(record => record.id === filterValues.dealerUserDealershipId);
    } else {
      return filterDefaultData.dealership;
    }
  }

  const fetchDealerRepresentatives = () => {
    if (isDealerUserDealershipIdPresent()) {
      return filterDefaultData.representative?.filter(record => record.dealership_ids.includes(filterValues.dealerUserDealershipId));
    } else if (isDealershipIdPresent()) {
      return filterDefaultData.representative?.filter(record => record.dealership_ids.includes(filterValues.dealershipId));
    } else {
      return filterDefaultData.representative;
    }
  }

  const fetchStates = () => {
    if (isDealershipStatePresent()) {
      return filterDefaultData.states?.filter(record => record === filterValues.dealershipState);
    } else {
      return filterDefaultData.states;
    }
  }

  return (
    <>
      <ListItem>
        <StyledAutoComplete
          disablePortal
          id="combo-box-demo"
          options={filterDefaultData.dealerUser}
          value={newFilterState.dealerUseObj}
          getOptionLabel={option => option.name}
          renderInput={(params) => <TextField   {...params} size="small" label="Dealer User"/>}
          onChange={(event, value) => {
            let dealerUserId = (value?.id === "undefined" || value?.id === undefined) ? null : value?.id
            setNewFilterState({
              ...newFilterState,
              dealerUser: dealerUserId,
              dealerUseObj: value,
              dealer: null,
              dealershipObj: null,
              dealerRepresentative: null,
              dealerRepresentativeObj: null,
              dealerState: null
            })
            setFilterValues({...filterValues, dealerUserDealershipId: value?.dealership_id})
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <StyledOptionBox>{option.name}</StyledOptionBox>
            </li>
          )}
        />
      </ListItem>
      <ListItem>
        <StyledAutoComplete
          disablePortal
          id="combo-box-demo"
          options={fetchDealerships()}
          value={newFilterState.dealershipObj}
          getOptionLabel={option => option.name}
          renderInput={(params) => <TextField   {...params} size="small" label="Dealership"/>}
          onChange={(event, value) => {
            let dealerId = (value?.id === "undefined" || value?.id === undefined) ? null : value?.id
            setNewFilterState({
              ...newFilterState,
              dealer: dealerId,
              dealershipObj: value,
              dealerRepresentative: null,
              dealerRepresentativeObj: null,
              dealerState: null
            })
            setFilterValues({...filterValues, dealershipId: dealerId, dealershipState: value?.state})
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <StyledOptionBox>{option.name}</StyledOptionBox>
            </li>
          )}
        />
      </ListItem>
      <ListItem>
        <StyledAutoComplete
          disablePortal
          id="combo-box-demo"
          options={fetchDealerRepresentatives()}
          getOptionLabel={option => option.name}
          value={newFilterState.dealerRepresentativeObj}
          renderInput={(params) => <TextField   {...params} size="small" label="Representative"/>}
          onChange={(event, value) => {
            let dealerRepresentativeId = (value?.id === "undefined" || value?.id === undefined) ? null : value?.id
            setNewFilterState({
              ...newFilterState,
              dealerRepresentative: dealerRepresentativeId,
              dealerRepresentativeObj: value
            })
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <StyledOptionBox>{option.name}</StyledOptionBox>
            </li>
          )}
        />
      </ListItem>
      <ListItem>
        <StyledAutoComplete
          disablePortal
          id="combo-box-demo"
          value={newFilterState.dealerState}
          options={fetchStates()}
          getOptionLabel={option => option}
          renderInput={(params) => <TextField   {...params} size="small" label="Dealer State"/>}
          onChange={(event, value) => {
            setNewFilterState({...newFilterState, dealerState: value})
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <StyledOptionBox>{option}</StyledOptionBox>
            </li>
          )}
        />
      </ListItem>
      <ListItem>
        <StyledAutoComplete
          disablePortal
          id="combo-box-demo"
          value={newFilterState.stipulationObj}
          options={filterDefaultData.stipulation}
          getOptionLabel={option => option.name}
          renderInput={(params) => <TextField   {...params} size="small" label="Stipulation"/>}
          onChange={(event, value) => {
            let stipulationId = (value?.id === "undefined" || value?.id === undefined) ? null : value?.id
            setNewFilterState({...newFilterState, stipulation: stipulationId, stipulationObj: value})
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <StyledOptionBox>{option.name}</StyledOptionBox>
            </li>
          )}
        />
      </ListItem>
    </>
  )
}

const StyledAutoComplete = styled(Autocomplete)({
  width: 350,
  marginLeft: 1
});

const StyledOptionBox = styled(Box)({
  "&:hover": {
    color: "#e93b1b",
    lineHeight: 1.5,
    textDecoration: 'underline',
    textUnderlineOffset: '8px',
    textDecorationcolor: '#e93b1b',
    textDecorationThickness: "2px",
    fontWeight: "500"
  },
  "&:focus": {
    borderColor: "#ef4829a8 !important"
  },
});
