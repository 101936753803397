import React from "react";
import { Switch, Row, Col, Form } from 'antd';

export default function CustomSwitch({label, required, ruleMessage, name, defaultChecked, onChange, disabled}) {
    return (
        <Row>
            <Col span={24}> 
                <Form.Item 
                    label={label} 
                    hasFeedback
                    name={name}
                    rules={[{ required: required, message: ruleMessage }]}
                >  
                      <Switch onChange={onChange} defaultChecked={defaultChecked} disabled={disabled}/>
                </Form.Item>
            </Col>
        </Row> 
    );
}