import React from "react";
import { Row, Col, Table } from "antd";

const columns = [
    {
        title: 'Result',
        dataIndex: 'result',
        key: 'result'
    },
    {
        title: 'Response',
        dataIndex: 'response',
        key: 'response'
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date'
    },
];

export default function NegativePay({ data }) {
    
    const dataSource = data ? data.negativePays : []
    return (
        <Row>
            <Col span={24}>                
                <Table className='slc-table' rowKey="id" columns={columns} dataSource={dataSource} pagination={true} />
            </Col>
        </Row>
    );
}