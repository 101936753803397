import React, { useState } from 'react';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';
import Axios from 'axios';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import './Signin.css'

export default function ChangePassword() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [email, setEmail] = useState('');
    //const [error, setError] = useState('');
    const history = useHistory();
    const handleSubmit = () => {
        let base_url = process.env.REACT_APP_BASE_URL;
        //setError("")


        if (confirmPassword == newPassword ) {
            if(confirmPassword == currentPassword ){
               return  message.error("Current Password and New password can't be same")
            }
            Axios.post(`${base_url}/api/v1/change-password`, {
                admin_user: {
                    email,
                    confirmPassword,
                    newPassword,
                    currentPassword
                },
            }).then(data => {
                if (data.data.status == true) {
                    message.success("Password  updated successfully")
                    history.push('/admins/login');
                }else{
                    message.error(data.data.message)
                }


            }).catch(() => {
                message.success(" Password not correct")
                history.push('/change-password');
            })
        } else {
            message.error("New Password and Confirm Password should be matched")
        }
    }
    return (

        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column id='login-column'>
                <Header as='h3' style={{ color: '0F1419', marginTop: '10px' }} textAlign='center'>
                    Speed Leasing Admin Portal
                </Header>
                <span style={{ color: 'red',fontSize:11 }}> Password Expired ! Please update your password</span>
                <Form size='large' onSubmit={handleSubmit}>

                    <Segment stacked>
                        <Form.Input
                            fluid
                            icon='user'
                            iconPosition='left'
                            name="email"
                            placeholder='Email Address'
                            onChange={(e) => setEmail(e.target.value)}
                            required={true}
                        />
                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            name="currentPassword"
                            type='password'
                            placeholder='Current Password'
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            required={true}
                        />
                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='New Password'
                            type='password'
                            name="newPassword"
                            onChange={(e) => setNewPassword(e.target.value)}
                            required={true}
                            minLength={8}
                            pattern ="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$"
                        />

                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Confirm Password'
                            type='password'
                            name="confirmPassword"
                            match="password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required={true}
                            minLength={8}
                            pattern ="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$"
                        />
                        <span style={{ color: 'blue', fontSize: 11, marginBottom:5 }}>Password length should contain at least min 8 characters, 1 special character, 1 uppercase letter, 1 numeric and 1 lowercase letter </span>
                        <Button
                            id='login-button'
                            primary
                            fluid
                            size='large'
                            type='submit'
                        >
                            Update Password
                        </Button>
                    </Segment>
                </Form>
            </Grid.Column>
        </Grid >
    )
}
