import React from "react";
import { Typography } from "antd";
const { Paragraph } = Typography;

export default function StipulationParagraph({ children, ...props }) {

  return (
    <div>
      <Paragraph {...props}>
        <>{children}</>
      </Paragraph>
    </div>
  );
  
}