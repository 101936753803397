import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {GET, POST, GETDATAFILTER} from '../util/network';

export const fetchDealerships = createAsyncThunk('dashboard/fetchDealershipsdata', async (params) => {
    try {
      let reqParams = {...params.pageState, ...params.dataGridFilter, ...params.dataGridSort}
      const response = await GETDATAFILTER(`/api/v1/dealerships`, reqParams)
        return response && response.data && response.data?.data?.dealerships ? response.data : null;
    } catch (e) {
        console.log(e)
    }
})

export const fetchSearchedDealerships = createAsyncThunk('dashboard/fetchSearchedDealershipsdata', async (params) => {
    try {
        const response = await POST(`/api/v1/dealerships/search`, { query: params.searchData, page: params.page , limit: params.limit });
        if(response.data.data.dealership){
            return response && response.data && response.data.data.dealership ? response.data : null;
        }
    } catch (e) {
        console.log(e)
    }
})


export const exportDealershipsData = createAsyncThunk('dashboard/exportDealershipsdata', async (params) => {
    try {
      return await GET(`/api/v1/dealerships/export-dealerships?search=${params.search}&status=${params.status}`)
    } catch (e) {
        console.log(e)
    }
})

const initialState = {
  dealerships: [],
  dealershipStatuses: {},
  query: {},
  pageState: {
    page: 0,
    limit: 50,
    search: "",
    status: "",
  },
  dataGridFilter: {
    dealership_name: "",
    dealership_compare_operator: "",
    // city_name: "",
    // city_compare_operator: "",
    status_column: "",
  },
  dataGridFilterFlag: false,
  total: 0,
  isLoading: false,
  signInVisibility: false,
  dataGridSort: {
    field: '',
    sort: ''
  }
};

export const DealershipsSlice = createSlice({
    name: "Dealerships",
    initialState: initialState,
    reducers: {
        setQuery: (state, action) => {
            return {
                ...state,
                query: {
                    ...state.query, ...action.payload
                },
                isSearched :true
            };
        },
        updatePageState: (state, action) => {
            return {
              ...state,
              pageState: {
                ...state.pageState, ...action.payload
              },
              isNewAppTimer: false,
            }
          },
        clearDataGridFilters: (state, action) => {
          state.dataGridFilter = initialState.dataGridFilter;
          state.dataGridFilterFlag = false;
          return state
        },
        updateDataGridFilters: (state, action) => {
          return {
            ...state,
            dataGridFilter: {
              ...state.dataGridFilter, ...action.payload
            },
          }
        },
        updateDataGridFilterFlag: (state, action) => {
          state.dataGridFilterFlag = !state.dataGridFilterFlag;
          return state
        },
        updateDataGridSort: (state, action) => {
          return {
            ...state,
            dataGridSort: {
              ...state.dataGridSort, ...action.payload
            }
          }
        }
    },

    extraReducers(builder) {
        builder.addCase(fetchDealerships.fulfilled, (state, action) => {
            state.dealerships = action.payload.data.dealerships
            state.dealershipStatuses = action.payload.dealershipStatuses;
            state.isLoading = false;
            state.signInVisibility = action.payload.dealershipLoginFlag;
            state.total = action.payload.total;
            return state
        }),
            builder.addCase(fetchDealerships.pending, (state) => {
                state.isLoading = true;
            }),
            builder.addCase(fetchDealerships.rejected, (state, action) => {
                state.isLoading = false;
                state.success = false;
                state.error = action.payload;
            });

        builder.addCase(fetchSearchedDealerships.fulfilled, (state, action) => {
            state.dealerships = action.payload.data.dealership
            state.pageState.total = action.payload.total
            state.isLoading = false;
            return state
        }),
            builder.addCase(fetchSearchedDealerships.pending, (state) => {
                state.isLoading = true;
            }),
            builder.addCase(fetchSearchedDealerships.rejected, (state, action) => {
                state.isLoading = false;
                state.success = false;
                state.error = action.payload;
            });
    }
});
export const { setQuery, updatePageState, clearDataGridFilters, updateDataGridFilters, updateDataGridFilterFlag, updateDataGridSort } = DealershipsSlice.actions;
export default DealershipsSlice.reducer;
