import adminSubMenu from './../components/layouts/AdminSubMenu'
import {AllowedActions, AllowedAdminMenu} from './AllowedActions'
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect,  useHistory } from 'react-router-dom';

const allowedAdminMenu = AllowedAdminMenu();

const AdministrationRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      hasAccessToAdminMenu(props.match.url) ? (
        <Component {...props} />
      ) : (
        <Redirect to="/unauthorized" />
      )
    }
  />
);

const hasAccessToAdminMenu = (url) =>{
  let subMenu = adminSubMenu.find(x => url.includes(x.link))?.text
  let hasAccess = subMenu == undefined || allowedAdminMenu == null || allowedAdminMenu.length == 0 || allowedAdminMenu.some(x => x == subMenu)
  return hasAccess
}

export default AdministrationRoute;