import React, { useState } from "react";
import { Spin } from "antd";
import DocusignReports from "./DocusignReports";

export default function DocusignReportsRenderer(props) {
  const { handleNoAuth } = props;
  const [loading, setLoading] = useState(false);

  return (
    <Spin
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      <DocusignReports
        setLoading={setLoading}
        handleNoAuth={handleNoAuth}
      />
    </Spin>
  )
}