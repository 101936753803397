import React, { useState } from "react";
import { Button, Input, Layout, Row, Col } from "antd";
import '../../App.css';
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import Box from '@mui/material/Box';
import {styled} from '@mui/system';
import MakeDataGrid from "./MakeDataGrid";
import AssignmentReturnedSharpIcon from "@mui/icons-material/AssignmentReturnedSharp";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";
import DialogModal from '../LeaseApplications/DialogModal'

export default function MakeList(props) {
  const {Content} = Layout;
  const { Search } = Input;
  const history = useHistory();
  const {data, loading, pageState, setPageState, total, exportMakesList, exportModal,
    handleExportModalOpen, handleExportModalClose} = props;
  const [searchValue, setSearchValue] = useState(pageState.search);
  const authData = JSON.parse(window.localStorage.getItem('user_data')) || null;

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'string',
      flex: .5,
      minWidth: 30,
      filterable: false
    },
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      flex: 1.5,
      minWidth: 75,
      filterOperators: false
    },
    {
      field: 'vinStartsWith',
      headerName: 'Vin Starts With',
      type: 'string',
      flex: .5,
      minWidth: 30,
      filterOperators: false
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'string',
      flex: 1,
      minWidth: 50,
      filterOperators: false
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      type: 'string',
      flex: 1,
      minWidth: 50,
      filterOperators: false
    },
    {
      field: 'lmsManf',
      headerName: 'Lms Manf',
      type: 'string',
      flex: 1,
      minWidth: 50,
      filterOperators: false
    },
    {
      field: 'active',
      headerName: 'Active',
      type: 'string',
      flex: .5,
      minWidth: 30,
      renderCell: (record) => {
        return record.row?.active === true ? "Yes" : "No"
      }
    },
    {
      field: 'versionId',
      headerName: 'Version Number',
      type: 'string',
      flex: .75,
      minWidth: 30,
      renderCell: (record) => {
        return record.row?.versionId ? record.row?.versionId : "N/A"
      }
    },
    {
      field: 'versionName',
      headerName: 'Version Name',
      type: 'string',
      flex: 1,
      minWidth: 50,
      filterOperators: false,
      renderCell: (record) => {
        return record.row?.versionName ? record.row?.versionName : "N/A"
      }
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      type: 'action',
      flex: 1,
      minWidth: 50,
      headerAlign: 'center',
      align: 'center',
      renderCell: (record) => {
        return record ? (
          <>
            <Button
              style={{ textTransform: "none" }}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/new-assets/makes/details/${record.row.id}`,
                });
              }}
            >
              View
            </Button>
            <Button
              style={{ textTransform: "none", marginLeft: ".8rem" }}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/new-assets/makes/edit/${record.row.id}`,
                });
              }}
            >
              Edit
            </Button>
          </>
        ) : (
          ""
        );
      },
    },
  ]

  const handleGlobalSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === '') {
      setPageState({...pageState, search: e.target.value})
    }
  };
  return (
    <Layout>
      <MainHeader activePage="newAssets" />
      <Layout>
        <Layout id="content-area-layout">
          <Content
            id="main-content"
            style={{ backgroundColor: "#ffff !important" }}
          >
            <Row style={{ marginBottom: "1em" }}>
              <Col flex={4}>
                <h2>Make</h2>
              </Col>
              <Col style={{ marginRight: "10px" }}>
                <Button
                  style={{
                    backgroundColor: "#e93b1b",
                    color: "white",
                    border: "none",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: "/new-assets/makes/create",
                    });
                  }}
                  type="primary"
                >
                  Create New Make
                </Button>
              </Col>
            </Row>

            <Row style={{ marginBottom: "1em" }}>
              <Col span={12}>
                <Search
                  placeholder="Global Search"
                  size="large"
                  defaultValue={searchValue}
                  onChange={handleGlobalSearch}
                  allowClear
                  className="leaseAppListSearch"
                  style={{ marginBottom: "10px" }}
                  onSearch={(searchVal) => {
                    setPageState({ page: 0, limit: 50, search: searchVal });
                  }}
                  enterButton
                />
              </Col>
              <Col span={12} type="flex" align="right">
                <Tooltip title={<h5>Export</h5>}>
                  <IconButton
                    style={{ color: "#e93b1b", fontSize: "30px" }}
                    onClick={() => {
                      handleExportModalOpen();
                    }}
                  >
                    <AssignmentReturnedSharpIcon />
                  </IconButton>
                </Tooltip>
              </Col>
            </Row>

            {data && (
              <>
                <StyledBox>
                  <MakeDataGrid
                    columns={columns}
                    data={data}
                    loading={loading}
                    setPageState={setPageState}
                    pageState={pageState}
                    total={total}
                  />
                </StyledBox>
              </>
            )}
            <div>
            <DialogModal
                modalState={exportModal}
                modalClose={handleExportModalClose}
                submitFunction={exportMakesList}
                contentData={"Data will be exported as CSV. Are you sure you want to continue?"}
                title={"Export Model Years?"}
              />
            </div>
          </Content>
        </Layout>
      </Layout>
      <MainFooter />
    </Layout>
  );
}

const StyledBox = styled(Box)({
  height: 400,
  display: "flex",
  flexGrow: 1,
  width: '100%',
  '& .super-app-theme--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)'
  },
})
