import React from 'react';
import { Col, Row, Table } from 'antd';

const columns = [
  {
    title: 'Status',
    dataIndex: 'prenoteStatus',
    key: 'prenoteStatus'
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message'
  },
  {
    title: 'Prenote Attempted',
    dataIndex: 'prenoteAttempted',
    key: 'prenoteAttempted'
  },
  {
    title: 'Prenote Updated',
    dataIndex: 'prenoteUpdated',
    key: 'prenoteUpdated'
  }
];

export default function Prenote({ data }) {
  const dataSource = data ? data.prenotes: [];

  return (
    <Row>
      <Col span={24}>
        <Table 
          className='slc-table' 
          rowKey='id' 
          columns={columns} 
          dataSource={dataSource} 
          pagination={true} 
        />
      </Col>
    </Row>
  );
}