
import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close';
import AssetsGroup from "./components/FilterComponents/AssetsGroup";
import DealershipGroup from "./components/FilterComponents/DealershipGroup";
import LesseeGroup from "./components/FilterComponents/LesseeGroup";
import CoLesseeGroup from "./components/FilterComponents/CoLesseeGroup";
import DateGroup from "./components/FilterComponents/DateGroup";
import { styled } from '@mui/system';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {
    updateFilterState,
    updatePageState,
    updateFilterIconState,
    clearFilterIconState,
} from '../../reducers/LeaseApplications'
import { useDispatch } from "react-redux";

export default function Filters(props) {
    const [lesseeState, setLesseeState] = useState(false)
    const [colesseeState, setColesseeState] = useState(false)
    const [assetState, setAssetState] = useState(false)
    const [dateState, setDateState] = useState(false)
    const [dealerState, setDealerState] = useState(false)
    const { toggleDrawer, sortModel, setFilter, filterDefaultData, pageState, filterState } = props

    const dispatch = useDispatch();
    const [newFilterState, setNewFilterState] = useState({ ...filterState });
    const initialFilterState =  {
        dealerUser: "",
        dealer: "",
        dealerRepresentative: "",
        dealerState: "",
        stipulation: "",
        assetMake: "",
        calculatorStatus: "",
        assetMileageTier: "",
        assetCreditTier: '',
        assetVin: "",
        lesseeFullName: '',
        lesseeFirstName: '',
        lesseeLastName: "",
        lesseeSsn: "",
        colesseeFullName: "",
        colesseeFirstName: "",
        colesseeLastName: "",
        colesseeSsn: "",
        welcomeCallStatus: "",
        hasCoapplicant: "",
        applicantState: "",
        applicationDateFrom: null,
        applicationDateTo: null,
        fundingDelayFrom: null,
        fundingDelayTo: null,
        fundingApprovedFrom: null,
        fundingApprovedTo: null,
        fundedDateFrom: null,
        fundedDateTo: null,
        documentDateFrom: null,
        documentDateTo: null,
        documentReceivedFrom: null,
        documentReceivedTo: null,
        dealerRepresentativeObj: null,
        dealershipObj: null,
        dealerUseObj: null,
        stipulationObj: null,
        welcomeCallObj: null,
        lesseeLicenseNumber: "",
    }
    const initialFilterValues = { dealerUserDealershipId: "", dealershipId: "", assetMake: "", dealershipState: "" }
    const [filterValues, setFilterValues] = useState({
        dealerUserDealershipId: filterState?.dealerUseObj?.dealership_id,
        dealershipId: filterState?.dealer,
        assetMake: filterState?.assetMake,
        dealershipState: filterState?.dealershipObj?.state
    });

    const searchByFilters = () => {
        dispatch(updatePageState({ search: '', dealer: null, dealerid: null, date: "all", page: 0 }))
        dispatch(updateFilterState({ ...newFilterState }))
        dispatch(updateFilterIconState())
        setFilter(false)
    }

    const clearFilter = () => {
        setNewFilterState({ ...initialFilterState })
        setFilterValues({ ...initialFilterValues })
        dispatch(updateFilterState({ ...initialFilterState }))
        dispatch(clearFilterIconState())
        setFilter(false)
    }

    return (
        <Box
            sx={{ width: 500 }}
            role="presentation"
        >
            <Grid container direction="row">
                <Grid item md={12}>
                    <List>
                        <ListItem>
                            <StyledCloseIcon onClick={toggleDrawer(false)} />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item md={6}>
                    <List>
                        <ListItem >
                            <StyledListItemButton size="small" onClick={() => setDealerState(!dealerState)}>
                                <ListItemText>
                                    <StyledTypography variant="body1" >Dealership</StyledTypography>
                                </ListItemText>
                            </StyledListItemButton>
                        </ListItem>
                        {dealerState && <DealershipGroup filterDefaultData={filterDefaultData} newFilterState={newFilterState} setNewFilterState={setNewFilterState} filterValues={filterValues} setFilterValues={setFilterValues} />}
                    </List>
                </Grid>
                <Grid item md={6}>
                    <List>
                        <ListItem >
                            <StyledListItemButton onClick={() => setAssetState(!assetState)} >
                                <ListItemText>
                                    <StyledTypography variant="body1" >Assets</StyledTypography>
                                </ListItemText>
                            </StyledListItemButton>
                        </ListItem>
                        {assetState && <AssetsGroup filterDefaultData={filterDefaultData} newFilterState={newFilterState} setNewFilterState={setNewFilterState} filterValues={filterValues} setFilterValues={setFilterValues} />}
                    </List>
                </Grid>
            </Grid>
            <Divider />
            <Divider />
            <Grid container direction="row">
                <Grid item md={6}>
                    <List>
                        <ListItem >
                            <StyledListItemButton onClick={() => setLesseeState(!lesseeState)} >
                                <ListItemText>
                                    <StyledTypography variant="body1" >Lessee</StyledTypography>
                                </ListItemText>
                            </StyledListItemButton>
                        </ListItem>
                        {lesseeState && <LesseeGroup filterDefaultData={filterDefaultData} filterState={filterState} newFilterState={newFilterState} setNewFilterState={setNewFilterState} />}
                    </List>
                </Grid>
                <Grid item md={6}>
                    <List>
                        <ListItem >
                            <StyledListItemButton onClick={() => setColesseeState(!colesseeState)} >
                                <ListItemText>
                                    <StyledTypography variant="body1" >Co-lessee</StyledTypography>
                                </ListItemText>
                            </StyledListItemButton>
                        </ListItem>
                        {colesseeState && <CoLesseeGroup filterDefaultData={filterDefaultData} filterState={filterState} newFilterState={newFilterState} setNewFilterState={setNewFilterState} />}
                    </List>
                </Grid>
            </Grid>
            <Divider />
            <Divider />
            <Grid container direction="row">
                <Grid item md={12}>
                    <List>
                        <ListItem >
                            <StyledListItemButton onClick={() => setDateState(!dateState)} >
                                <ListItemText>
                                    <StyledTypography variant="body1" >Dates</StyledTypography>
                                </ListItemText>
                            </StyledListItemButton>
                        </ListItem>
                        {dateState && <DateGroup filterDefaultData={filterDefaultData} filterState={filterState} newFilterState={newFilterState} setNewFilterState={setNewFilterState} />}
                    </List>
                </Grid>
            </Grid>
            <Divider />
            <Divider />
            <Grid container direction="row">
                <Grid item md={6}>
                    <List>
                        <ListItem >
                            <StyledAutoComplete
                                disablePortal
                                id="combo-box-demo"
                                value={newFilterState.welcomeCallObj}
                                options={filterDefaultData.welcomeCall}
                                getOptionLabel={option => option.name}
                                renderInput={(params) => <TextField   {...params} size="small" label="Welcome Call Status" />}
                                onChange={(event, value) => {
                                    let welcomeCallStatusId = (value?.id === "undefined" || value?.id === undefined) ? null : value?.id
                                    setNewFilterState({ ...newFilterState, welcomeCallStatus: welcomeCallStatusId, welcomeCallObj: value })
                                }}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        <StyledOptionBox>{option.name}</StyledOptionBox>
                                    </li>
                                )}
                            />
                        </ListItem>
                    </List>
                </Grid>
                <Grid item md={6}>
                    <List>
                        <ListItem >
                            <StyledAutoComplete
                                disablePortal
                                id="combo-box-demo"
                                value={newFilterState.hasCoapplicant}
                                options={filterDefaultData.hasCoapplicant}
                                getOptionLabel={option => option}
                                renderInput={(params) => <TextField   {...params} size="small" label="Has Co-Applicant" />}
                                onChange={(event, value) => {
                                    setNewFilterState({ ...newFilterState, hasCoapplicant: value })
                                }}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        <StyledOptionBox>{option}</StyledOptionBox>
                                    </li>
                                )}
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item md={12}>
                    <List>
                        <ListItem >
                            <StyledTextField
                              label="License Number"
                              id="outlined-size-small"
                              size="small"
                              value={newFilterState.lesseeLicenseNumber}
                              onChange={(event, value) => {
                                  setNewFilterState({ ...newFilterState, lesseeLicenseNumber: event.target.value })
                              }}
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Divider />
            <Divider />
            <Grid container direction="row">
                <Grid item md={6}>
                    <List>
                        <ListItem >
                            <StyledListItemFilterButton onClick={() => { searchByFilters(filterState); toggleDrawer(false) }} >
                                <ListItemText>
                                    <StyledTypography variant="body1" >Filter</StyledTypography>
                                </ListItemText>
                            </StyledListItemFilterButton>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item md={6}>
                    <List>
                        <ListItem >
                            <StyledListItemClearButton onClick={() => { clearFilter(); toggleDrawer(false) }} >
                                <ListItemText>
                                    <StyledClearTypography variant="body1" >Clear Filters</StyledClearTypography>
                                </ListItemText>
                            </StyledListItemClearButton>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            <Divider />
            <Divider />
        </Box>
    )
}

const StyledTextField = styled(TextField)({
    width: '100%',
    marginLeft: "1px"
})

const StyledCloseIcon = styled(CloseIcon)({
    position: "relative",
    top: 0,
    left: '443px',
    cursor: "pointer"
})
const StyledListItemButton = styled(ListItemButton)({
    width: "200px",
    '&:hover': { backgroundColor: "#205e9d" },
    backgroundColor: "#0a1a2b",
    borderRadius: "6px"

})
const StyledListItemFilterButton = styled(ListItemButton)({
    '&:hover': { backgroundColor: "#205e9d" },
    backgroundColor: "#1976d2",
    borderRadius: "6px"

})
const StyledListItemClearButton = styled(ListItemButton)({
    '&:hover': { backgroundColor: "#bdc7d1" },
    backgroundColor: "#d1d9e1",
    borderRadius: "6px"

})

const StyledTypography = styled(Typography)({
    textAlign: "center", color: "#fff", fontSize: 16, fontWeight: "bold"

})

const StyledClearTypography = styled(Typography)({
    textAlign: "center", color: "#black", fontSize: 16, fontWeight: "bold"

})

const StyledAutoComplete = styled(Autocomplete)({
    width: 350,
    marginLeft: 1
});

const StyledOptionBox = styled(Box)({
    "&:hover": {
        color: "#e93b1b",
        lineHeight: 1.5,
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
        textDecorationcolor: '#e93b1b',
        textDecorationThickness: "2px",
        fontWeight: "500"
    },
    "&:focus": {
        borderColor: "#ef4829a8 !important"
    },
});

