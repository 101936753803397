import React from "react";
import { Row, Col, Radio, Form } from 'antd';

export default function CustomRadio({label, required, ruleMessage, name, options, onChange, defaultValue}) {
    return (
        <Row>
            <Col span={24}> 
                <Form.Item 
                    label={label} 
                    hasFeedback
                    name={name}
                    rules={[{ required: required, message: ruleMessage }]}
                >  
                        <Radio.Group onChange={onChange} defaultValue={defaultValue}>
                            {
                                options && options.map(({optionValue, optionLabel}, index) => {
                                  return <Radio key={index} value={optionValue}>{optionLabel}</Radio>
                                })
                              }

                        </Radio.Group> 
                </Form.Item>
            </Col>
        </Row> 
    );
}