import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {
  Layout,
  Menu,
  Spin,
  Card,Button
} from "antd";
import {styled} from '@mui/material'
import {Table, TableBody, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel} from '@mui/material'
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import {SiderProps} from "../../components/layouts/MainSiderProps";
import Box from '@mui/material/Box';
import {AddNew} from "../../components/layouts/Svg";
import Icon from "@ant-design/icons";
import Typography from "@material-ui/core/Typography";
import LosPath from "../../util/LosPath";

const {Content, Sider} = Layout;

export default function ApplicationWelcomeCallView(props) {
  const {data} = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{width: "100%"}}
            >
              <Menu.Item key="1">
                <Icon component={AddNew}/>
                Application Stipulation
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout"  style={{ minHeight: "80vh" }}>
            <MainBreadcrumb
              items={[
                {text: "Home", link_type: "linkto", link: "/dashboard"},
                {text: "Lease Application Related", link_type: "ahref", link: void 0},
                {
                  text: "Application Stipulations",
                  link_type: "ahref",
                  link: "/lease-applications-stipulation",
                },
                {text: "Deatils", link_type: "ahref", link: void 0},
              ]}
            />

            {data && (
              <Box sx={{ flexGrow: 1 }}>
                <Grid container direction="row" style={{ height: "auto" }}>
                  <Grid item md={12} container spacing={2}>
                    <Grid container direction="row" md={12} sm={12} lg={12} style={{ marginTop: '5px' }}>
                      <Grid item md={8} sm={6} style={{ paddingLeft: '20px' }}>
                        <Typography variant={"h4"}>Lease Application Stipulation #{data.id}</Typography>
                      </Grid>
                      <Grid item md={4} sm={6} style={{ display: "flex", justifyContent: "right" }}>
                        <Button
                          variant="text"
                          onClick={() => {
                            history.push({
                              pathname: `/lease_applications_stipulation_edit/${data.id}`,
                            });
                          }}
                        >
                          Edit Lease Application Stipulation
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} lg={12}>
                      <Card title="Lease Application Stipulation Details">
                        <Grid container direction="row">
                          <Grid item md={16} container spacing={2}>
                            <Grid item md={12} sm={9} lg={6}>
                              <TableContainer component={Box}>
                                <Table aria-label="customized table">
                                  <TableBody>
                                    <StyledTableRow>
                                      <StyledtableCell1>ID</StyledtableCell1>
                                      <StyledtableCell2>{data.id}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>LEASE APPLICATION ID</StyledtableCell1>
                                      <StyledtableCell2>
                                        <a onClick={() => {
                                          history.push('/lease-applications/' + data.leaseApplicationId);
                                        }}>{data.applicationIdentifier}</a>
                                      </StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>STIPULATION</StyledtableCell1>
                                      <StyledtableCell2><a href={LosPath(`/admins/stipulations/${data.stipulationId}`)}>{data.stipulation}</a></StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>STATUS</StyledtableCell1>
                                      <StyledtableCell2>{data.status}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>LEASE COLUMN ATTACHMENT</StyledtableCell1>
                                      <StyledtableCell2>{data.leaseApplicationAttachmentId}</StyledtableCell2>
                                    </StyledTableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Layout>
        </Layout>
      </Layout>
    </Spin>
  )
}

const StyledtableCell1 = styled(TableCell)({
  fontWeight: "bold",
  fontSize: "15px",
  width: "300px"
})

const StyledTableRow = styled(TableRow)({
  "& td": {border: 0, padding: 15},
})

const StyledtableCell2 = styled(TableCell)({
  fontSize: "15px"
})
