import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
export default function TransferOwnershipDialogModal(props) {

    const { unlockDialog, handleClose, transferedAdmin, transferApplication } = props;
    return (
        <Dialog
            open={unlockDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {"Transfer Ownership of Lease Application?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This will transfer the ownership to {transferedAdmin.name}. Are you sure you want to continue?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={transferApplication} autoFocus>
                    Agree
                </Button>
            </DialogActions>
        </Dialog>
    )
}
