import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Form,
  Layout,
  Menu,
  message,
  Result,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import Icon from "@ant-design/icons";
import { GET, POST, PUT } from "../../util/network";
import logger from "../../util/logger";
import MainHeader from "../../components/layouts/MainHeader";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import MainFooter from "../../components/layouts/MainFooter";
import { SiderProps } from "../../components/layouts/MainSiderProps";
import { AddNew } from "../../components/layouts/Svg";
import { GetResourceActions, IsActionAllowed } from "../../util/AllowedActions";

const { Content, Sider } = Layout;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function UpdateFundingDelay(props) {
  const { id } = props.match.params;
  const { allowedActions, handleNoAuth } = props;
  const history = useHistory();
  const adminUserActions = GetResourceActions("AdminUser", allowedActions);
  const allowedCreate = IsActionAllowed("create", adminUserActions);
  const [modelGroupForm] = Form.useForm();
  const [data, setData] = useState(null);
  const [reasonOptions, setReasonOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getFundingDelay = async () => {
    setLoading(true)
    try {
      const response = await GET(`/api/v1/funding-delays/${id}`);
      setData(response?.data);
    } catch (e) {
      message.error("Error while loading funding delay.")
    }
    setLoading(false)
  }

  const getReasonOptions = async () => {
     {
      setLoading(true);
      try {
        await GET(`/api/v1/funding_delay_reasons`)
          .then((response) => {
            setReasonOptions(response.data.funding_delay_reasons || []);
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              handleNoAuth(history);
            } else {
              message.error("Error while fetching Make Options");
            }
          });
      } catch (e) {
        logger.error("Error while fetching Make Options", e);
      }

      setLoading(false);
    }
  };

  const save = async (values) => {
   {
      setLoading(true);
      try {
        await PUT(`/api/v1/funding-delays/${id}`, values)
          .then((response) => {
            message.success("Saved successfully");
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              message.error("Error while saving!");
              handleNoAuth(history);
            } else {
              message.error("Error while saving!");
            }
          });
      } catch (e) {
        logger.error("Error while saving!", e);
      }

      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    values = { ...values };
    save(values);
  };

  const status = [
    {
      index: 1,
      value: "Required",
    },
    {
      index: 2,
      value: "Not Required",
    },
    {
      index: 3,
      value: "Cleared",
    },
  ];

  useEffect(() => {
    getFundingDelay();
    getReasonOptions();
  }, []);

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
    { data &&
        <Layout>
        <MainHeader activePage="leaseApplicationRelated" />
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{ width: "100%" }}
            >
              <Menu.Item key="1">
                <Icon component={AddNew} />
                Edit Funding Delay
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                { text: "Home", link_type: "linkto", link: "/dashboard" },
                { text: "Lease Application Related", link_type: "ahref", link: void 0 },
                {
                  text: "Funding Delay",
                  link_type: "ahref",
                  link: "/funding-delays-list",
                },
                { text: "Edit Funding Delay", link_type: "ahref", link: void 0 },
              ]}
            />

            <Content id="main-content">
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <>
                    <Form
                      form={modelGroupForm}
                      {...layout}
                      colon={false}
                      onFinish={handleSubmit}
                      initialValues={{
                        funding_delay_reason_id: data.fundingDelayReasonId,
                        status: data.status,
                        notes: data.notes
                      }}
                      labelCol={{ span: 6, style: { fontWeight: 'bold' } }}
                      labelWrap
                      wrapperCol={{ span: 18 }}
                      labelAlign="left"
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Card title="Funding Delay">
                            <Form.Item
                              label="Funding Delay Reason"
                              name="funding_delay_reason_id"
                              hasFeedback
                              messageVariables={{ name: "Reason" }}
                              rules={[{ required: true }]}
                              colon={false}
                            >
                              <Select>
                                {reasonOptions &&
                                  reasonOptions.map(({ id, reason }, index) => {
                                    return (
                                      <Option key={index} value={id}>
                                        {reason}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              label="Notes"
                              name="notes"
                              hasFeedback
                              messageVariables={{
                                name: "notes",
                              }}
                              rules={[{ required: false }]}
                              colon={false}
                            >
                              <Input.TextArea rows={4} />
                            </Form.Item>

                            <Form.Item
                              label="Status"
                              name="status"
                              hasFeedback
                              messageVariables={{
                                name: "Status",
                              }}
                              rules={[{ required: true }]}
                              colon={false}
                            >
                              <Select>
                                {status &&
                                  status.map(({ value }, index) => {
                                    return (
                                      <Option key={index} value={value}>
                                        {value}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          </Card>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]} className="mt-20">
                        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                          <Row>
                            <Col span={24}>
                              <Form.Item>
                                <Button type="primary" htmlType="submit">
                                  Update
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    }
    </Spin>
  );
}
