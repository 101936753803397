import React, { forwardRef } from 'react';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import './SortableItem.css';

export const SortableItem = (props) => {
  const { suffix, width } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: props.id });

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    width: width ? width : 110,
    height: 30,
    display: "flex",
    alignItems: "center",
    paddingLeft: 5,
    border: "1px solid gray",
    borderRadius: 5,
    marginBottom: 5,
    userSelect: "none",
    cursor: "grab",
    boxSizing: "border-box",
    justifyContent: 'space-between'
  };

  return (
    <div 
      style={itemStyle} 
      ref={setNodeRef} 
      {...attributes} 
      {...listeners}
    >
      {props.value}    
      {suffix}
    </div>
  );
};

export const Item = forwardRef(({ value, ...props }, ref) => {
  const itemStyle = {
    height: 30,
    display: "flex",
    alignItems: "center",
    paddingLeft: 5,
    border: "1px solid gray",
    borderRadius: 5,
    marginBottom: 5,
    justifyContent: 'space-between',
    backgroundColor: '#f9f9f9'
  };

  return (
    <div
      {...props}
      style={itemStyle}
      ref={ref}
    >
      {value}
    </div>
  );
});

Item.displayName = 'Item';

export default SortableItem;
