import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { message, DatePicker, Spin } from 'antd';
import moment from 'moment';
import { GET } from '../../../util/network';
import { BarChart, Bar, Line,LineChart,ComposedChart, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Paper, Typography } from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Grid } from "@material-ui/core";
import Select from '@mui/material/Select';
import { Button } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {useDispatch, useSelector} from "react-redux";
const { RangePicker } = DatePicker;

const TimeAverageReport = (props) => {
    const { setLoading, logger, handleNoAuth, dealershipsOptions, filterState, setFilterState, setPageState} = props

    const [adminUsers, setAdminUsers] = useState([]);

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDealer, setSelectedDealer] = useState(null);
    const timerReport = useSelector(state => state.timerReport);
    const data = timerReport?.avgTimerData?.total_time_per_category_list;

    const formatTime = (timeInSeconds) => {
        const hours = Math.floor(timeInSeconds / 3600);
        const minutes = Math.floor((timeInSeconds % 3600) / 60);
        const seconds = Math.floor(timeInSeconds % 60);

        return `${hours}h ${minutes}m ${seconds}s`;
    };

    const formatHour = (timeInSeconds) => {
        const hours = (timeInSeconds / 3600).toFixed(1);
        return `${hours}h`;
    };

    const CustomXAxisTick = (props) => {
        const { x, y, payload } = props;
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" >
                    {formatHour(payload.value)}
                </text>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload, label }) => {
        const uniquePayload = payload.filter((item, index) => {
            return payload.findIndex(obj => obj.dataKey === item.dataKey) === index;
          });
        if (active) {
            return (
                <div className="custom-tooltip" style={{ margin: 0, padding: '10px', backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                    <p className="label">{label}</p>
                    {uniquePayload.map((entry, index) => (
                        <p key={index} style={{ color: entry.color }}>
                            {`${entry.name}: ${formatTime(entry.value)}`}
                        </p>
                    ))}
                </div>
            );
        }

        return null;
    };

    const getAdminOptionsData = async () => {
        setLoading(true);
        try {
            const response = await GET(`/api/v1/admin-users/active-admin-user-options`);
            setAdminUsers(response.data.admin_user_options.filter(x => x.label !== ' '))
        } catch (e) {
            logger.error("Error while fetching Admin Options", e);
            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history);
            } else {
                message.error("Error while fetching Admin Options");
            }
        }
        setLoading(false);
    };

    const getMaxBreakingPoint = (noOfTick) =>{
        const maxDataValueCredit = Math.max(...data?.filter(en => en.Credit != undefined).map(entry => entry.Credit)) || 1;
        const maxDataValueDoc = Math.max(...data?.filter(en => en.Doc != undefined).map(entry => entry.Doc)) || 1;
        const maxDataValueFunding = Math.max(...data?.filter(en => en.Funding != undefined).map(entry => entry.Funding)) || 1;
        var maxValue = Math.max(maxDataValueCredit, maxDataValueDoc,  maxDataValueFunding)
        return Math.ceil(Math.ceil(maxValue / (3600 * 5)) / noOfTick) * noOfTick * 3600 * 5;
    }

    const getTicks = (noOfTick, maxBreakPoint) => {
        var ticksArr = [];
        for (let index = noOfTick; index > 0; index--) {
            ticksArr.push(maxBreakPoint * (index / noOfTick));
        }
        return ticksArr;
    };

    const GetCharts = () => {

        var noOfTick = 4
        var maxBreakPoint = getMaxBreakingPoint(noOfTick);
        var ticksArr = getTicks(noOfTick, maxBreakPoint);
        return (
            <Grid container spacing={2} direction="row">
                <Grid item md={4} sm >

                    <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
                        <Typography variant="h6" gutterBottom>
                            Credit
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <ComposedChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }} maxBarSize={30}>
                                <XAxis dataKey="name" />
                                <YAxis domain={[0, maxBreakPoint]} ticks={ticksArr} tick={<CustomXAxisTick />} />
                                <Tooltip content={<CustomTooltip />} />
                                <Bar dataKey="Credit" fill="#8884d8" />
                                <Line type="monotone" dataKey="Credit" stroke="#ff7300" />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
                <Grid item md={4} sm >
                    <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
                        <Typography variant="h6" gutterBottom>
                            Doc
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <ComposedChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }} maxBarSize={30}>
                                <XAxis dataKey="name" />
                                <YAxis domain={[0, maxBreakPoint]} ticks={ticksArr} tick={<CustomXAxisTick />} />
                                <Tooltip content={<CustomTooltip />} />
                                <Bar dataKey="Doc" fill="#ffc658" />
                                <Line type="monotone" dataKey="Doc" stroke="#658300" />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
                <Grid item md={4} sm >

                    <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
                        <Typography variant="h6" gutterBottom>
                            Funding
                        </Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <ComposedChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }} maxBarSize={30}>
                                <XAxis dataKey="name" />
                                <YAxis domain={[0, maxBreakPoint]} ticks={ticksArr} tick={<CustomXAxisTick />} />
                                <Tooltip content={<CustomTooltip />} />
                                <Bar dataKey="Funding" fill="#a9d82c" />
                                <Line type="monotone" dataKey="Funding" stroke="#ad8300" />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
            </Grid>
        );
    };

    const uniqueAdminUsers = Array.from(new Set(adminUsers.map((user) => user.label))).map((label) => {
        return adminUsers.find((user) => user.label === label);
    });

    useEffect(() => {
        getAdminOptionsData();
    }, [])

    const AvgTimerReport = () => {
        setPageState({...filterState});
    };

    const handleDateRangeChange = (dates) => {
        if (dates && dates.length === 2) {
            const startDate = dates[0] ? dates[0].startOf('day') : null;
            const endDate = dates[1] ? dates[1].endOf('day') : null;
    
            setFilterState({
                ...filterState,
                dateRange: {
                    startDate,
                    endDate
                },
                filter_flag: true
            });
        } else {
            setFilterState({
                ...filterState,
                dateRange: {
                    startDate: null,
                    endDate: null
                },
                filter_flag: true
            });
        }
    };
    
    const handleRefresh = () => {
        setPageState({...filterState});
    };


    return (
        <Box sx={{ flexGrow: 1, pb: 2 }}>
            <Grid container spacing={2} direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Grid item md={4} sm={4}>
                    <Typography variant={"h5"}>Average Report</Typography>
                </Grid>
                <Grid item md={8} sm={8} container spacing={1} direction="row" justifyContent="flex-end" alignItems="center">
                    <Button variant="text" color="primary"  onClick={handleRefresh}>
                        <RefreshIcon />
                    </Button>
                    <Grid item md={2} sm={2}>
                        <FormControl fullWidth >
                            <InputLabel id="demo-simple-select-label">By Dates</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={filterState.date}
                                label="Dates"
                                onChange={(event) => {
                                    setFilterState({ ...filterState, date: event.target.value, filter_flag: true });
                                    AvgTimerReport();
                                }}
                                size="small"
                            >
                                <MenuItem value="month">This Month</MenuItem>
                                <MenuItem value="year">This Year</MenuItem>
                                <MenuItem value="last_year">Last Year</MenuItem>
                                <MenuItem value="quarter">Last Quarter </MenuItem>
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="custom">Custom</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {filterState.date === 'custom' && (
                        <Grid item md={3} sm={3}>
                            <RangePicker
                                style={{ height: '40px' }}
                                onChange={(value) => {
                                    handleDateRangeChange(value);
                                    AvgTimerReport();
                                }}
                                defaultValue={[
                                    filterState.dateRange.startDate ? moment(filterState.dateRange.startDate) : null,
                                    filterState.dateRange.endDate ? moment(filterState.dateRange.endDate) : null
                                ]}
                            />
                        </Grid>
                    )}
                    <Grid item md={3} sm={3} >
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={selectedDealer}
                            options={dealershipsOptions}
                            getOptionLabel={(option) => option.label || ''}
                            onChange={(event, value) => {
                                setSelectedDealer(value);
                                setFilterState({ ...filterState, dealership_id: value?.value || null, filter_flag: true });
                                AvgTimerReport();
                            }}
                            renderInput={(params) => <TextField {...params} size="small" label="Dealers" />}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <Box>{option.label}</Box>
                                </li>
                            )}
                        />
                    </Grid>

                    <Grid item md={3} sm={3} >
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo2"
                            value={selectedUser}
                            options={uniqueAdminUsers}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) => {
                                setSelectedUser(value);
                                setFilterState({ ...filterState, locked_user: value?.value || null, filter_flag: true });
                                AvgTimerReport();
                            }}
                            renderInput={(params) => <TextField {...params} size="small" label="Users" />}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <Box>{option.label}</Box>
                                </li>
                            )}
                        />
                    </Grid>
                </Grid>

            </Grid>
            {
                data?.length > 0 && <GetCharts />
            }
        </Box>
    );
}

export default TimeAverageReport;
