import { configureStore } from "@reduxjs/toolkit";
import docusignsReducer from "./reducers/Docusign";
import leaseApplicationsReducer from "./reducers/LeaseApplications";
import pendingWelcomeCallsReducer from "./reducers/PendingWelcomeCalls";
import adminUsersReducer from "./reducers/Adminusers";
import dealerUsersReducer from "./reducers/DealerUsers";
import notificationsReducer from "./reducers/Notifications";
import DealershipNotesReducer from "./reducers/DealershipNotes";
import DealershipsReducer from "./reducers/Dealerships";
import DealershipActivitiesReducer from './reducers/DealershipActivities'
import ModelYearsReducer from './reducers/ModelYears'
import TimerReport from './reducers/TimerReport'

const Store = configureStore({
    reducer: {
        docusignHistories: docusignsReducer,
        leaseApplications: leaseApplicationsReducer,
        pendingWelcomeCalls: pendingWelcomeCallsReducer,
        adminUsers: adminUsersReducer,
        dealerUsers: dealerUsersReducer,
        notifications: notificationsReducer,
        DealershipNotes : DealershipNotesReducer,
        Dealerships: DealershipsReducer,
        DealershipActivities: DealershipActivitiesReducer,
        modelYears: ModelYearsReducer,
        timerReport: TimerReport
    },
    middleware:  getDefaultMiddleware =>
      getDefaultMiddleware({
          serializableCheck: false,
      }),
});

export default Store