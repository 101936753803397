import {Col, Row, Typography} from "antd";
import React from "react";
const { Title } = Typography;

export const MaximumAdvances = (props) => {
    const { calculatorData, convertUsd, backendProductsColor } = props;

    return (
        <div>
            <h4 className="payment-calculator-headers"> Maximum Advances </h4>
            <Row className={'bot-spacer-1'} style={{ lineHeight: 2 }}>
                <Col span={12}>
                    <em>Front-End Max Advance</em>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <span>{calculatorData.frontEndMaxAdvance && convertUsd(Number(calculatorData.frontEndMaxAdvance))}</span>
                </Col>
            </Row>
            <Row className={'bot-spacer-1'} style={{ lineHeight: 2 }}>
                <Col span={12} style={{ textAlign: 'left', color: backendProductsColor }}>
                    <em>Back-End Max Advance</em>
                </Col>
                <Col span={12} style={{ textAlign: 'right', color: backendProductsColor }}>
                    <span>{calculatorData.backendMaxAdvance && convertUsd(Number(calculatorData.backendMaxAdvance))}</span>
                </Col>
            </Row>
            <h4 className="payment-calculator-headers"> Current Book Values </h4>
            <Row className={'bot-spacer-1 top-spacer-1'} style={{ lineHeight: 2 }}>
                <Col span={12}>
                    NADA <strong>Retail Value</strong>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <span>{calculatorData.nadaRental && convertUsd(Number(calculatorData.nadaRental))}</span>
                </Col>
            </Row>
            {/* <Row className={'bot-spacer-1 top-spacer-1'} style={{ lineHeight: 2 }}>
                <Col span={12}>
                    NADA <strong>Rough Trade-In Value</strong>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <span>{calculatorData.nadaRough && convertUsd(Number(calculatorData.nadaRough))}</span>
                </Col>
            </Row> */}
        </div>
    )
}

export default MaximumAdvances
