import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Typography, Checkbox, Row, Col, message, Button, Spin } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import { PUT, GET } from '../../util/network';
import logger from '../../util/logger';

const { Title, Text } = Typography;

export default function AutoReject({ emailTemplateName, handleNoAuth }) {
  const history = useHistory();
  
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [emailContent, setEmailContent] = useState("")

  const getEmailTemplate = async () => {
    setLoading(true);
    
    try {
        const response = await GET(`/api/v1/email-templates/get-details?name=${emailTemplateName}`);
        setData(response.data);
        setEmailContent(response.data.template)
        
      } catch (e) {
        logger.error("Error while fetching Email Template", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while fetching Email Template');
        }
      }
    setLoading(false);
  };

  useEffect(() => {
    getEmailTemplate();
  }, []); 



  const handleEditorChange = (content) => {
      setEmailContent(content)
    }


  const putRequest = (name, payload) => {
      return PUT(`/api/v1/email-templates/${name}`, payload);
    }
  
  const saveEmail = async (e) => {
      setLoading(true)
      e.preventDefault();
      let email_template_params = { 
        template: emailContent
      }
      try {
        await putRequest(data.name, email_template_params)
        message.success("Email saved successfully");
      } catch (e) {
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while saving Email');
        }
      }
      setLoading(false)
    }
  
  const onChangeEmailPackageReceived = async (e) => {
      setLoading(true)
      e.preventDefault();
      let email_template_params = { 
        enable_template: e.target.checked
      }
      try {
        await putRequest(data.name, email_template_params)
        message.success("Email settings saved successfully");
      } catch (e) {
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while saving Email settings');
        }
      }
      setLoading(false)
    }


    return data ? (
      <Spin spinning={loading}>
        <Row gutter={[24, 24]}>
          <Col span={24} >
            <Title level={4}>Auto-Reject (Internal)</Title>
          </Col>
        </Row>
        <Row gutter={[0, 24]} className='mt-20'>
          <Col span={24} >
            <Checkbox onChange={onChangeEmailPackageReceived} defaultChecked={data.enable_template}>
                <Text className="fac-txt-val">Notify Support when Blackbox automatically rejects an application</Text>
            </Checkbox>
          </Col>
        </Row>
        <Row gutter={[24, 24]} className='mt-20'>
          <Col span={24} >
              <Editor
                  apiKey=""
                  initialValue={data.template}
                  init={{
                    height: 500,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic backcolor | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat | help'
                  }}
                  onEditorChange={handleEditorChange}
                />
          </Col>
        </Row>
        <Row gutter={[16, 16]} className='mt-20'>
          <Col span={24}>
              <Button type="primary" onClick={saveEmail} > Save Email</Button>
          </Col>
        </Row>
      </Spin>
    ) : null;
}