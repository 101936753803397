import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Layout, Menu, Result, Row, Table } from "antd";
import Icon from '@ant-design/icons';
import { AddNew, MotorSvg } from '../../components/layouts/Svg';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import { SiderProps } from '../../components/layouts/MainSiderProps';

const { Content, Sider } = Layout;

export default function Inventories({ data, ...props }) {
  const { allowedCreate, allowedGet } = props;  

  let Menus = [];

  if (allowedGet) {
    Menus.push(
      {
        label: 'Inventory',
        componentLabel: null,
        iconType: <Icon component={MotorSvg}/>,
        isAllowedToShow: true,
        menuKey: 1,
        link: '#',
      }
    )
  }

  if (allowedCreate) {
    Menus.push(
      {
        label: "Add New",
        componentLabel: null,
        iconType: <Icon component={AddNew}/>,
        isAllowedToShow: true,
        menuKey: 2,
        link: 'inventory/new',
      }
    )
  }  

  const history = useHistory();
  const [activeMenu, setActiveMenu] = useState([1]);
  const activeMenuList =  Menus.filter(menu => menu.isAllowedToShow);

  const getUniqueBy = (arr, prop) => {
    return arr.reduce((a, d) => {
    if (!a.includes(d[prop])) { a.push(d[prop]); }
       return a;
    }, []);
  }

  const convertToObj = (prop) => {
    return data ? getUniqueBy(data.vehicleInventory, prop).map( (val) =>  { return { text: val, value: val } } ) : []
  }

  const columns = [
      {
          title: 'VIN',
          dataIndex: 'assetVin',
          key: 'assetVin',
          sorter: (a, b) => ('' + a.assetVin).localeCompare(b.assetVin),
          onFilter: (value, record) => record.assetVin.includes(value),
          filters: convertToObj('assetVin') 

      },
      {
          title: 'Year',
          dataIndex: 'assetYear',
          key: 'assetYear',
          sorter: (a, b) => ('' + a.assetYear).localeCompare(b.assetYear),
          onFilter: (value, record) => record.assetYear.toString().includes(value.toString()),
          filters: convertToObj('assetYear') 
      },
      {
          title: 'Make',
          dataIndex: 'assetMake',
          key: 'assetMake',
          sorter: (a, b) => ('' + a.assetMake).localeCompare(b.assetMake),
          onFilter: (value, record) => record.assetMake.includes(value),
          filters: convertToObj('assetMake') 
      },
      {
          title: 'Model',
          dataIndex: 'assetModel',
          key: 'assetModel',
          sorter: (a, b) => ('' + a.assetModel).localeCompare(b.assetModel),
          onFilter: (value, record) => record.assetModel.includes(value),
          filters: convertToObj('assetModel') 
      }, 
      {
        title: 'Inventory Status',
        dataIndex: 'inventoryStatus',
        key: 'inventoryStatus',
        onFilter: (value, record) => record.inventoryStatus.includes(value),
        filters: convertToObj('inventoryStatus') 
    }
  ];

  const dataSource = data ? data.vehicleInventory : [];

  return (
    <Layout>
      <MainHeader activePage="inventory"/>
      <Layout>
        <Sider {...SiderProps} >
          <Menu
            defaultSelectedKeys={['1']}
            onSelect={({ key }) => { if (!key.includes("sub")){ setActiveMenu([...activeMenu, key]); } }}
          >
            {
              activeMenuList.map(({ label, iconType, link, menuKey }) => {
                return (
                  <Menu.Item
                    key={(menuKey).toString()}
                  > 
                    <a href={`${link}`}>
                      {iconType}
                      {label}
                    </a>
                  </Menu.Item>
                )
              })
            }
          </Menu>
        </Sider>

        <Layout id='content-area-layout'>  
        <MainBreadcrumb items={
          [{ text: "Home", link_type: "linkto", link: "/dashboard" },
          { text: "Inventory", link_type: "linkto", link: "/inventory" }
          ]}
        />

          <Content id='main-content'>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <>
                  {
                  allowedGet
                    ?
                      <Table 
                        className='slc-content-table'
                        rowKey="id" 
                        dataSource={dataSource} 
                        columns={columns} 
                        onRow={(record) => {
                          return {
                            onDoubleClick: () => {
                              history.push( 
                                {
                                  pathname: `/inventory/${record.id}`,
                                  state: record,
                                }
                              );
                            }
                          };
                        }}
                      />
                    :
                       <Result 
                        status='warning'
                        title='You are not allowed to access this page.'
                      /> 
                  }                      
                </>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
      <MainFooter/>
    </Layout>      
  );
}