import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import { GET, POST } from "../../util/network";
import ModelGroupList from "./ModelGroupList";

export default function ModelGroupsRenderer(props) {
  const { handleNoAuth } = props;
  let auth_token = window.localStorage.getItem('auth_token');

  const [loading, setLoading] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [pageState, setPageState] = useState({
    page: 0,
    limit: 25,
    search: "",
    api_source: "",
    make_id: "",
  });
  const [response, setResponse] = useState({ data: [], total: 0 });
  const [makeOptions, setMakeOptions] = useState([]);

const losPath = (path) => {
    return `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=${path}`
}

const handleExportModalOpen = () => {
    setExportModal(true)
}
const handleExportModalClose = () => {
    setExportModal(false)
}

const exportModelGroupList = async () => {
  handleExportModalClose()
  try {
    message.success("Model Group CSV file will be downloaded in a minutes")
    let response = await POST(`/api/v1/model-groups/export-model-group?search=${pageState.search}&make_id=${pageState.make_id}`)
    let url = losPath(response.data)
    setTimeout(() => {
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }, 1000)
  } catch (e) {
    if (e && e.response && e.response.status === 401) {
      handleNoAuth(history)
    } else {
      message.error('Error while Exporting Model Groups')
    }
  }
}

  const getModels = async () => {
    setLoading(true);
    try {
      const response = await GET(
        `/api/v1/model-groups/list?page=${pageState.page}&limit=${pageState.limit}&search=${pageState.search}&make_id=${pageState.make_id}`
      );
      setResponse(response?.data);
      setLoading(false);
    } catch (e) {
      message.error("Error while loading model group.");
      setLoading(false);
    }
  };

  const getMakeOptions = async () => {
    try {
      setLoading(true);
      const response = await GET(`/api/v1/makes/list?filter_option=${true}`)
      setMakeOptions(response?.data);
      setLoading(false);
    } catch (e) {
      setLoading(false);
       message.error("Error while loading make options.");
    }
  }

  useEffect(() => {
    getModels();
    if (makeOptions?.length == 0){
      getMakeOptions();
    }
  }, [pageState]);

  return (
    <Spin spinning={loading}>
      <ModelGroupList
        data={response.data}
        loading={loading}
        pageState={pageState}
        setPageState={setPageState}
        total={response.total}
        makeOptions={makeOptions}
        exportModelGroupList={exportModelGroupList}
        exportModal={exportModal}
        handleExportModalOpen={handleExportModalOpen}
        handleExportModalClose={handleExportModalClose}
      />
    </Spin>
  );
}
