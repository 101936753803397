import React from 'react'
import {Tag} from "antd";

export default function StatusTag(props) {
  const {name, style} = props
  return (
    (name === "New") && <Tag style={style} color="magenta">{name}</Tag>
    || (name === "Sales Review") && <Tag style={style} color="geekblue">{name}</Tag>
    || (name === "Sales Approved") && <Tag style={style} color="purple">{name}</Tag>
    || (name === "Underwriting Review") && <Tag style={style} color="gold">{name}</Tag>
    || (name === "Underwriting Approved") && <Tag style={style} color="cyan">{name}</Tag>
    || (name === "Credit Committee Review") && <Tag style={style} color="orange">{name}</Tag>
    || (name === "Credit Committee Approved") && <Tag style={style} color="#87d068">{name}</Tag>
    || (name === "Legacy Dealer – Can sign in") && <Tag style={style} color="green">{name}</Tag>
    || (name === "Active Dealer  – Can Submit") && <Tag style={style} color="#16AD44">{name}</Tag>
    || (name === "Declined") && <Tag style={style} color="#f50">{name}</Tag>
    || (name === "Closed") && <Tag style={style} color="red">{name}</Tag>
    || <Tag style={style}>{name}</Tag>
  )
}
