import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  Menu,
  Spin,
} from "antd";
import { styled } from '@mui/material'
import { Table, TableBody, TableContainer, TableRow } from '@mui/material'
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import { SiderProps } from "../../components/layouts/MainSiderProps";
import { AddNew } from "../../components/layouts/Svg";
import Icon from "@ant-design/icons";
import Box from '@mui/material/Box';

const { Sider } = Layout;

export default function ModelGroupView(props) {
    const {data} = props;
    const [loading] = useState(false);
    const history = useHistory();
  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{ width: "100%" }}
            >
              <Menu.Item key="1">
                <Icon component={AddNew} />
                Model Group Details
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                { text: "Home", link_type: "linkto", link: "/dashboard" },
                { text: "New Assets", link_type: "ahref", link: void 0 },
                {
                  text: "Model Group",
                  link_type: "ahref",
                  link: "/new-assets/model-groups/list",
                },
                { text: "Details", link_type: "ahref", link: void 0 },
              ]}
            />

            {data && (
              <Box sx={{ flexGrow: 1 }}>
                <Grid container direction="row">
                  <Grid item md={16} container spacing={2}>
                    <Grid item md={12} sm={9} lg={6}>
                      <TableContainer component={Box}>
                        <Table aria-label="customized table">
                          <TableBody>
                          <StyledTableRow >
                            <StyledtableCell1>NAME</StyledtableCell1>
                            <StyledtableCell2 >{data.name}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAKE</StyledtableCell1>
                            <StyledtableCell2 >
                            <a onClick={() => { history.push('/new-assets/makes/details/' + data.make_id); }} >{data.make}</a>
                            </StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>JD POWER MODEL TYPE ID</StyledtableCell1>
                            <StyledtableCell2 >
                              { data.model_type_id ? data.model_type_id : 'N/A' }
                           </StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MINIMUM DEALER PARTICIPATION</StyledtableCell1>
                            <StyledtableCell2 >{data.minimum_dealer_participation}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>BACKEND ADVANCE MINIMUM</StyledtableCell1>
                            <StyledtableCell2 >{data.backend_advance_minimum}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>RESIDUAL REDUCTION PERCENTAGE</StyledtableCell1>
                            <StyledtableCell2 >{data.residual_reduction_percentage}%</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM TERM LENGTH</StyledtableCell1>
                            <StyledtableCell2 >{data.maximum_term_length}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM TERM LENGTH PER YEAR</StyledtableCell1>
                            <StyledtableCell2 >{data.maximum_term_length_per_year}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>CREATED AT</StyledtableCell1>
                            <StyledtableCell2>{data.created_at}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>UPDATED AT</StyledtableCell1>
                            <StyledtableCell2>{data.updated_at}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 0</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_0}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 1</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_1}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 2</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_2}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 3</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_3}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 4</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_4}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 5</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_5}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 6</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_6}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 7</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_7}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 8</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_8}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 9</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_9}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 10</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_10}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 11</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_11}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 12</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_12}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 13</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_13}</StyledtableCell2>
                          </StyledTableRow>
                          <StyledTableRow >
                            <StyledtableCell1>MAXIMUM HAIRCUT 14</StyledtableCell1>
                            <StyledtableCell2>{data.maximum_haircut_14}</StyledtableCell2>
                          </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Layout>
        </Layout>
      </Layout>
    </Spin>
  )
}

 const StyledtableCell1 = styled(TableCell)({
    fontWeight: "bold",
    fontSize: "12px",
  })
  
  const StyledTableRow = styled(TableRow)({
    "& td": { border: 0, padding: 8 },
  })
  
  const StyledtableCell2 = styled(TableCell)({
    fontSize: "12px"
  })

