import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Layout,
  message,
  Select,
  Card
} from "antd";
import '../../App.css';
import { GET, GetWithParams } from "../../util/network";
import logger from "../../util/logger";

import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import CreditTierDataGrid from "./CreditTierDataGrid";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CreditTierFilters from "./CreditTierFilters";
const { Option } = Select;

export default function CreditTierList(props) {
  const { Content } = Layout;
  const history = useHistory();
  const { handleNoAuth, data, makes, pageState, setPageState, initialState, total, setResponse } = props;
  const [filter, setFilter] = useState(false);
  const [newPageState, setNewPageState] = useState({ ...pageState })
  const [modelGroupOptions, setModelGroupOptions] = useState([]);
  const [selectedModelGroup, setSelectedModelGroup] = useState(undefined);
  const [dataLoading, setDataLoading] = useState(false);
  const [filterButtonTitle, setFilterButtonTitle] = useState(false);

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'string',
      flex: .4,
      minWidth: 30,
      filterable: false
    },
    {
      field: 'description',
      headerName: 'Description',
      type: 'string',
      flex: 1,
      minWidth: 30,
      filterOperators: false,
      filterable: false
    },
    {
      field: 'makeName',
      headerName: 'Make',
      type: 'string',
      flex: .75,
      minWidth: 75,
      filterOperators: false,
      filterable: false
    },
    {
      field: 'modelGroupName',
      headerName: 'Model Group',
      type: 'string',
      flex: .75,
      minWidth: 75,
      filterOperators: false,
      filterable: false
    },
    {
      field: 'irrValue',
      headerName: 'Irr Value',
      type: 'string',
      flex: .5,
      minWidth: 30,
      filterOperators: false,
      filterable: false
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      type: 'string',
      flex: .75,
      minWidth: 50,
      filterOperators: false,
      filterable: false
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      type: 'string',
      flex: .75,
      minWidth: 50,
      filterOperators: false,
      filterable: false
    },
    {
      field: 'acquisitionFeeCents',
      headerName: 'Acquisition Fee',
      type: 'string',
      flex: .5,
      minWidth: 50,
      filterOperators: false,
      filterable: false,
    },
    {
      field: 'maximumFiAdvancePercentage',
      headerName: 'Max Fi Advance Pct.',
      type: 'string',
      flex: .75,
      minWidth: 30,
      filterOperators: false,
      filterable: false,
      renderCell: (params) => {
        return `${params.row.maximumFiAdvancePercentage}%`
      }
    },
    {
      field: 'maximumAdvancePercentage',
      headerName: 'Max Advance Pct.',
      type: 'string',
      flex: .75,
      minWidth: 30,
      filterOperators: false,
      filterable: false,
      renderCell: (params) => {
        return `${params.row.maximumAdvancePercentage}%`
      }
    },
    {
      field: 'requiredDownPaymentPercentage',
      headerName: 'Required Down Payment Pct.',
      type: 'string',
      flex: .75,
      minWidth: 30,
      filterOperators: false,
      filterable: false,
      renderCell: (params) => {
        return `${params.row.requiredDownPaymentPercentage}%`
      }
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      type: 'action',
      flex: 1,
      minWidth: 50,
      renderCell: (record) => {
        return record ? (
          <>
            <Button
              style={{ textTransform: "none" }}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/calculator/credit-tiers/details/${record.row.id}`,
                });
              }}
            >
              View
            </Button>
            <Button
              style={{ textTransform: "none", marginLeft: ".3rem" }}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/calculator/credit-tiers/edit/${record.row.id}`,
                });
              }}
            >
              Edit
            </Button>
          </>
        ) : (
          ""
        );
      },
    },
  ]

  const creditTierFilter = (filterFlag) => {
    if (filterFlag) {
      setFilterButtonTitle(true);
      console.log('New Page State', newPageState);
      setPageState({ ...newPageState, page: 0, filterFlag: true });
      setFilter(false)
    } else {
      setNewPageState({ ...initialState, page: 0, filterFlag: false, makeId: pageState.makeId, modelGroupId: pageState.modelGroupId })
      setPageState({ ...initialState, page: 0, filterFlag: false, makeId: pageState.makeId, modelGroupId: pageState.modelGroupId });
      setFilter(false);
      setFilterButtonTitle(false);
    }
  };

  const clearCreditTierFilter = () => {
    setNewPageState({ ...initialState });
    setPageState({ ...initialState });
    setFilter(false)
  };

  const getModelGroupOptions = async (make_id) => {
    {
      setPageState({ ...initialState, makeId: make_id, filterFlag: false });
      setNewPageState({ ...initialState, makeId: make_id, filterFlag: false });
      try {
        await GET(`/api/v1/model-groups?make_id=${make_id}`)
          .then((response) => {
            setModelGroupOptions(response.data.model_groups || [])
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              handleNoAuth(history);
            } else {
              message.error("Error while fetching Model Group Options");
            }
          });
      } catch (e) {
        logger.error("Error while fetching Model Group Options", e);
      }
    }
  };


  const getCreditTiers = async () => {
    setDataLoading(true);
    try {
      const response = await GetWithParams(`/api/v1/credit_tiers/list`, pageState);
      setResponse(response?.data);
      setDataLoading(false);
    } catch (e) {
      setDataLoading(false);
      message.error(e.response?.data?.message || 'Error while loading data');
    }
  }

  useEffect(() => {
    if (pageState.makeId && pageState.modelGroupId) {
      getCreditTiers();
    }
  }, [pageState])

  return (
    <Layout>
      <MainHeader activePage="calculator" />
      <Layout>
        <Layout id="content-area-layout">
          <Content
            id="main-content"
            style={{ backgroundColor: "#ffff !important" }}
          >
            <Box sx={{ flexGrow: 1, pb: 2 }}>
              <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                <Grid item md={6} sm={4}>
                  <Typography variant={"h4"}>Credit Tiers</Typography>
                </Grid>
                <Grid item md={6} sm={6} container spacing={1} direction="row" justifyContent="flex-end">
                  <Grid item md={6} sm>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                      <Tooltip title={<h5>New Credit Tier</h5>}>
                        <IconButton style={{ color: "#e93b1b", fontSize: "30px", marginRight: '20px' }} onClick={() => {
                          history.push("/calculator/credit-tiers/create");
                        }}>
                          <CreateNewFolderIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ flexGrow: 1, pb: 2 }}>
              <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={12} sm={12} md={12}>
                  <Card title="Make and Model Group Wise Credit Tiers">
                    <Grid container spacing={1} direction="row" justifyContent="flex-start">
                      <Grid item xs={12} sm={12} md={4}>
                        <Form.Item
                          label="Select Make"
                        >
                          <Select
                            onSelect={(value) => {
                              setFilterButtonTitle(false);
                              setSelectedModelGroup(undefined);
                              setResponse({ credit_tiers: [], total: 0 });
                              getModelGroupOptions(value);
                            }}
                          >
                            {makes &&
                              makes.map(({ id, name }, index) => {
                                return (
                                  <Option key={index} value={id}>
                                    {name}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <Form.Item
                          label="Select Model Group"
                        >
                          <Select
                            value={selectedModelGroup}
                            onSelect={(value) => {
                              setFilterButtonTitle(false);
                              setSelectedModelGroup(value);
                              setNewPageState({ ...pageState, modelGroupId: value, page: 0, filterFlag: false });
                              setPageState({ ...pageState, modelGroupId: value, page: 0, filterFlag: false });
                            }}>
                            {modelGroupOptions &&
                              modelGroupOptions.map(
                                ({ id, name }, index) => {
                                  return (
                                    <Option key={index} value={id}>
                                      {name}
                                    </Option>
                                  );
                                }
                              )}
                          </Select>
                        </Form.Item>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        {
                          (data.length > 0 || filterButtonTitle) ? (
                            <div>
                              <Button onClick={() => setFilter(true)}>
                                Filter
                              </Button>
                              {
                                filterButtonTitle && <Button style={{ marginLeft: ".5rem" }} onClick={() => creditTierFilter(false)}>
                                  Clear Filter
                                </Button>
                              }
                            </div>
                          ) : ''
                        }
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                      <Grid item xs={12} sm={12} md={12}>
                        <CreditTierFilters
                          makes={makes}
                          newPageState={newPageState}
                          setNewPageState={setNewPageState}
                          filter={filter}
                          setFilter={setFilter}
                          creditTierFilter={creditTierFilter}
                          clearCreditTierFilter={clearCreditTierFilter}
                        />

                        <StyledBox>
                          {data && (
                            <CreditTierDataGrid
                              columns={columns}
                              data={data}
                              loading={dataLoading}
                              setPageState={setPageState}
                              pageState={pageState}
                              total={total}
                            />
                          )}
                        </StyledBox>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Content>
        </Layout>
      </Layout>
      <MainFooter />
    </Layout>
  );
}

const StyledBox = styled(Box)({
  height: 700,
  display: "flex",
  flexGrow: 1,
  width: '100%',
  '& .super-app-theme--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)'
  },
})
