import React, { useState, useEffect } from "react";
import { Spin, Layout, Menu, Row, Col, Collapse } from "antd";
import { BulbOutlined } from "@ant-design/icons";
import MainBreadcrumb from "../../../components/layouts/MainBreadcrumb";
import { SiderProps } from "../../../components/layouts/MainSiderProps";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";
import { useParams } from "react-router-dom";
import { GET } from "../../../util/network";
import logger from "../../../util/logger";

const { Content, Sider } = Layout;

export default function CreditReportDocument() {
  const params = useParams();
  const appNumber = params.appNumber;
  const id = params.id;
  const [activeMenu, setActiveMenu] = useState([1]);
  const [creditReportHtml, setCreditReportHtml] = useState();
  const [docType, setDocType] = useState();
  const [loading, setLoading] = useState(false);

  function createMarkup() {
    return { __html: creditReportHtml };
  }

  const generatePDF = (
    imgData,
    imgWidth,
    imgHeight,
    pageHeight,
    heightLeft,
    position
  ) => {
    // Create a PDF document and add the image as a page.
    const doc = new JsPDF({
      format: "a4",
      unit: "mm",
    });

    doc.addImage(imgData, "JPEG", 10, 0, imgWidth, imgHeight + 25);
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, "JPEG", 10, position, imgWidth, imgHeight + 25);
      heightLeft -= pageHeight;
    }

    doc.save(`${appNumber}-${docType}.pdf`);
  };

  const htmlStringToPdf = async (htmlString) => {
    let iframe = document.createElement("iframe");
    iframe.style.visibility = "hidden";
    document.body.appendChild(iframe);
    let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
    iframedoc.body.innerHTML = htmlString;

    let canvas = await html2canvas(iframedoc.body, {});
    const imgWidth = 190;
    const pageHeight = 290;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;

    // Convert the iframe into a PNG image using canvas.
    let imgData = canvas.toDataURL("image/jpeg");

    generatePDF(imgData, imgWidth, imgHeight, pageHeight, heightLeft, position);
  };

  const getCreditReportDoc = async () => {
    setLoading(true);
    try {
      const response = await GET(`/api/v1/credit-report-doc/${id}`);
      document.title = response.data.creditReportDocument?.source || 'Report Document';
      setCreditReportHtml(response.data.creditReportDocument?.htmlReport);
      setDocType(response.data.creditReportDocument?.source);
    } catch (e) {
      logger.error("Error while fetching Credit Report", e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCreditReportDoc();
  }, []);

  return (
    <Spin spinning={loading}>
      <Layout>
        <Sider {...SiderProps}>
          <Menu
            defaultSelectedKeys={[1]}
            selectedKeys={[activeMenu.toString()]}
            style={{ width: "100%" }}
            onSelect={({ key }) => {
              if (!key.includes("sub")) {
                setActiveMenu([...activeMenu, key]);
              }
            }}
          >
            <Menu.Item key="1">
              <BulbOutlined />
              {`${docType}-Report Document`}
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout id="content-area-layout">
          <MainBreadcrumb
            items={[
              { text: "Home", link_type: "linkto", link: "/dashboard" },
              {
                text: "Lease Applications",
                link_type: "linkto",
                link: "/lease-applications-list",
              },
              {
                text: `${appNumber}`,
                link_type: "linkto",
                link: `/lease-applications/${appNumber}`,
              },
              {
                text: "Credit Report Document",
                link_type: "ahref",
                link: void 0,
              },
            ]}
          />
          <Content id="main-content">
            <Row gutter={[0, 24]}>
              <Col span={12}>
                <h4>Report Document From CBC</h4>
              </Col>
              {/*<Col span={12}>*/}
              {/*  {creditReportHtml && (*/}
              {/*    <button onClick={() => htmlStringToPdf(creditReportHtml)}>*/}
              {/*      Download Pdf*/}
              {/*    </button>*/}
              {/*  )}*/}
              {/*</Col>*/}
            </Row>

            <Row gutter={[0, 24]}>
              <Col span={24}>
                <Collapse
                  accordion={false}
                  activeKey={activeMenu}
                  onChange={(key) => {
                    key !== undefined && setActiveMenu(key);
                  }}
                >
                  {creditReportHtml && (
                    <div dangerouslySetInnerHTML={createMarkup()} />
                  )}
                </Collapse>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Spin>
  );
}
