import React, {useEffect, useState} from 'react';
import {Col, Layout, Menu, Row, Result, message} from "antd";
import Icon from '@ant-design/icons';
import { SiderProps } from '../../components/layouts/MainSiderProps';
import { LesseeSvg, AddNew } from '../../components/layouts/Svg';
import MainFooter from '../../components/layouts/MainFooter';
import MainHeader from '../../components/layouts/MainHeader';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import AdminUsersTable from './AdminUsersTable';
import {useDispatch, useSelector} from "react-redux";
import {DELETE} from "../../util/network";
import {fetchAdminusers} from "../../reducers/Adminusers";
import DialogModal from "../LeaseApplications/DialogModal";

const { Content, Sider } = Layout;

export default function AdminUsers({ ...props }) {
  const { allowedGet, allowedCreate } = props;
  const dispatch = useDispatch();

  const adminData = useSelector((state) => state.adminUsers.adminList);
  const pageState = useSelector((state) => state.adminUsers.pageState);

  let Menus = [];

  if (allowedGet) {
    Menus.push(
      {
        label: 'Admin Users',
        componentLabel: null,
        iconType: <Icon component={LesseeSvg} />,
        isAllowedToShow: true,
        menuKey: 1,
        link: '#',
      }
    )
  }

  if (allowedCreate) {
    Menus.push(
      {
        label: 'Add New',
        componentLabel: null,
        iconType: <Icon component={AddNew} />,
        isAllowedToShow: allowedCreate,
        menuKey: 2,
        link: '/administration/admin-users/add-new',
      }
    )
  }

  const [activeMenu, setActiveMenu] = useState([1]);
  const activeMenuList = Menus.filter(menu => menu.isAllowedToShow);
  const [open, setOpen] = useState(false);
  const [recordId, setRecordId] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchAdminusers({pageState: pageState}));
  }, [pageState]);

  const closeDialog = () => {
    setOpen(false);
  }

  const completeTask = () => {
    closeDialog();
    setLoading(true);
    completeDeletion();
    setLoading(false);
  }

  const completeDeletion = async () => {
    try {
      await DELETE(`/api/v1/admin-users/${recordId}`);
      dispatch(fetchAdminusers({pageState: pageState}));
      message.success('Successfully deleted admin user.');
    } catch (e) {
      message.error('Error on admin user deletion.');
    }
  }

  return (
    <Layout>
      <MainHeader activePage="administration" />
      <Layout>
        <Sider {...SiderProps} >
          <Menu
            defaultSelectedKeys={['1']}
            onSelect={({ key }) => { if (!key.includes("sub")) { setActiveMenu([...activeMenu, key]); } }}
          >
            {
              activeMenuList.map(({ label, iconType, link, menuKey }) => {
                return (
                  <Menu.Item
                    key={(menuKey).toString()}
                  >
                    <a href={`${link}`}>
                      {iconType}
                      {label}
                    </a>
                  </Menu.Item>
                )
              })
            }
          </Menu>
        </Sider>

        <Layout id='content-area-layout'> {
          <MainBreadcrumb items={
            [
              { text: "Home", link_type: "linkto", link: "/dashboard" },
              { text: "Administration", link_type: "ahref", link: void (0) },
              { text: "Admin Users" }
            ]}
          />}


            <Content id='main-content'>
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <>
                    {
                      allowedGet
                        ?
                        <AdminUsersTable data={adminData?.admins} setOpen={setOpen} setRecordId={setRecordId} total={adminData?.total} loading={loading} />
                        :
                        <Result
                          status='warning'
                          title='You are not allowed to access this page.'
                        />
                    }
                  </>
                </Col>
              </Row>
              <>
                <DialogModal
                  modalState={open}
                  modalClose={closeDialog}
                  submitFunction={completeTask}
                  contentData={""}
                  title={"Are you sure you want to delete this admin user?"}
                />
              </>
            </Content>
        </Layout>
      </Layout>
      <MainFooter />
    </Layout>
  );
}