import React,  { useContext, useState, useEffect, useRef } from "react";
import { Form, Input,Table, Popconfirm, Typography, message, InputNumber, Select } from 'antd';

import { useDispatch } from "react-redux";
import { DELETE, PUT} from '../../../util/network';
import logger from '../../../util/logger';
import {getDetails} from '../../../reducers/LeaseApplications'

const { Option } = Select;
const EditableContext = React.createContext();

let incomeTypes = [
  {
    optionName: "Gross",
    optionValue: 1,
  },
  {
    optionName: "Net",
    optionValue: 2,
  },
]

const EditableRow = ({ ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  editable,
  children,
  dataIndex,
  record,
  data,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave(record, values, dataIndex);
    } catch (errInfo) {
      message.error('Save failed:', errInfo);
    }
  };

  const inputRenderer = (indexName) => {
    switch(indexName) {
      case 'incomeVerificationName':
        return (
          <Select ref={inputRef} onChange={save}>
            {
              data.incomeVerificationTypeOptions && data.incomeVerificationTypeOptions.map((obj, index) => {
                return <Option key={index} value={obj.optionValue}>{obj.optionName}</Option>
              })
            }
          </Select>
        )
      case 'employerClient':
        return <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      // case 'startDate':
      //   return <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      case 'grossIncomeCents':
        return <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} precision={2} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} />
      case 'incomeType':
        return (
            <Select ref={inputRef} onChange={save}>
              {
                  incomeTypes && incomeTypes.map((obj, index) => {
                    return <Option key={index} value={obj.optionValue}>{obj.optionName}</Option>
                  })
              }
            </Select>
        )
      default:
        return <Input ref={inputRef} onPressEnter={save} onBlur={save} />
    }
  }

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
      >
        { inputRenderer(dataIndex) }
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default function YtdList({ data, allowedGet, handleNoAuth, lessee, ...props }) {
  const [dataSource, setDataSource] = useState(lessee.ytdPayIncomes);
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
    loggedInUserId = authData.id;
  }
  const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
  const disableFlag = locked_by != loggedInUserId ? true : false
  const dispatch = useDispatch();

  const columns = [
    {
      title: 'Type of Document',
      dataIndex: 'incomeVerificationName',
      key: 'incomeVerificationName',
      editable: !disableFlag
    },
    {
      title: 'Specification',
      dataIndex: 'otherType',
      key: 'otherType',
      editable: !disableFlag
    },

    {
      title: 'Employer / Client',
      dataIndex: 'employerClient',
      key: 'employerClient',
      editable: !disableFlag
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      editable: !disableFlag
    },
    {
      title: "Period End Date",
      dataIndex: "periodEndDate",
      key: "periodEndDate",
      editable: !disableFlag
    },
    {
      title: "Pay Period",
      dataIndex: "daySincePayPeriod",
      key: "daySincePayPeriod",
    },
    {
      title: "YTD Income",
      dataIndex: "ytdIncome",
      key: "ytdIncome",
      editable: !disableFlag
    },
    {
      title: "Income Type",
      dataIndex: "incomeType",
      key: "incomeType",
      editable: !disableFlag,
    },
    {
      title: "YTD Calculated Value",
      dataIndex: "ytdCalculatedValue",
      key: "ytdCalculatedValue",
      render(record) {
        return <>{parseFloat(record)?.toFixed(2) || 0}</>
      }
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: '',
      key: 'delete',
      render(record) {
        return (
          <>
            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record)}>
              <Typography.Link disabled={disableFlag}>Delete</Typography.Link>
            </Popconfirm>
          </>
        )
      }
    }
  ];

  const handleDelete = async (record) => {
    try {
      const response = await DELETE(`/api/v1/ytd-pay-incomes/${record.id}?leaseApplicationId=${data.id}`);
      if(response) {
        dispatch(getDetails(response.data.leaseApplication));
        message.success("Deleted Successfully");
      }
    } catch (e) {
      logger.error("Request Error", e);
    }
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const submit = async (id, params) => {
      try {
        params.lease_application_id = data.id
        let response = await PUT(`/api/v1/ytd-pay-incomes/${id}`, {ytd_pay_income: params });
        if (response) {
          dispatch(getDetails(response.data.leaseApplication));
          message.success("Data saved successfully");
        }
      } catch (e) {
        logger.error("Income Verification Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while saving your data');
        }
      }
  }

  const handleSave = (row, values, dataIndex) => {
    let params = {}
    params[keyTools[dataIndex].transformKey] = keyTools[dataIndex].format(values[dataIndex])
    if (dataIndex == "otherType" && row.incomeVerificationName.toLowerCase() != "other") {
      message.error("Can't update Specification if Type of Document is not \"Other\"");
      return false
    } 

    let date1, date2;

    if(values.startDate !== undefined || values.periodEndDate !== undefined) {
      if(values.startDate !== undefined) {
        date1 = new Date(values.startDate);
        date2 = new Date(row.periodEndDate);
        date1.setDate(date1.getDate() - 1);
     }
 
     if(values.periodEndDate !== undefined) {
        date1 = new Date(row.startDate);
        date2 = new Date(values.periodEndDate);
        date2.setDate(date2.getDate() + 1);
     }
     
      if(date1 > date2) {
        alert("End Date must be grater than start date")
        return false
      }
     
      const diffInMs = Math.abs(date2 - date1);
      const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
      if(diffInDays > 365) {
          alert('The last date is more than one year. Please change the last date');
          return false
      } else {
        params.daySincePayPeriod = diffInDays
      }
    }
    
    submit(row.id, params)
  };

  const newColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        data: data,
        handleSave: handleSave,
      }),
    };
  });

  const keyTools = {
    incomeVerificationName: {
      transformKey: 'income_verification_type_id',
      format: function(value) {
        return value
      }
    },
    otherType: {
      transformKey: 'otherType',
      format: function(value) {
        return value
      }
    },
    startDate: {
      transformKey: 'startDate',
      format: function(value) {
        return value
      }
    },
    periodEndDate: {
      transformKey: 'periodEndDate',
      format: function(value) {
        return value
      }
    },
    employerClient: {
      transformKey: 'employerClient',
      format: function(value) {
        return value
      }
    },
    incomeType: {
      transformKey: 'incomeType',
      format: function(value) {
        return value;
      }
    },
    ytdIncome: {
      transformKey: 'ytdIncome',
      format: function(value) {
        return value;
      }
    }
  }

  useEffect(() => {
    setDataSource(lessee?.ytdPayIncomes);
  }, [lessee]);



  return (
    <div style={{ marginBottom: "15px" }}>
      {allowedGet && lessee?.ytdPayIncomes.length > 0 && (
        <Table
          className="slc-table"
          rowKey="id"
          components={components}
          dataSource={dataSource}
          columns={newColumns}
          rowClassName={() => 'editable-row'}
          bordered
          title={() => 'YTD Data'}
          pagination={false}
          size="small"
        />
      )}
    </div>
  );
}
