import React from "react";
import { Link } from 'react-router-dom';
import { Breadcrumb } from "antd";
import './MainBreadcrumb.css';

const MainBreadcrumb = (props) => {
  return (
    <Breadcrumb>
        {
          props.items.map(({text, link, link_type}, index) => {
            return (
              <Breadcrumb.Item key={`breadcrumbItem-${index}`}>
                { link_type === "linkto" ? (
                  <Link to={link}>{text}</Link>
                ) :
                  <a href={link}>{text}</a>
                }
              </Breadcrumb.Item>
            )
          })
        }
    </Breadcrumb>
  );
}
export default MainBreadcrumb;