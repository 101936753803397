import React from 'react'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent';
import {styled} from '@mui/system';
import Paper from "@mui/material/Paper";

export default function DealershipStatusCard(props) {
  const {title, data, Icon, backgroundColor, isSelected, textColor} = props

  return (
    <StyledPaper elevation={6}>
      <CardContent sx={{height: 100}} style={{backgroundColor: backgroundColor, border: isSelected ? '2px solid #328ee5' : '', padding: 5}}>
        <StyledTitleTypography gutterBottom variant="caption" style={{color: textColor}}>
          {title}  <Icon fontSize="small"/>
        </StyledTitleTypography>
        <StyledTitleTypography style={{color: textColor}}>
          {data}
        </StyledTitleTypography>
      </CardContent>
    </StyledPaper>
  )
}

const StyledTitleTypography = styled(Typography)({
  fontWeight: "bold",
  color: "#545353",
  letterSpacing: "0.25px "
})

const StyledPaper = styled(Paper)({
  "&:hover": {
    boxShadow: "0px 3px 5px -1px #310a0a33",
  }
})
