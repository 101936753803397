import React from "react";
import { Button, Layout, Row, Col } from "antd";
import '../../App.css';
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import Box from '@mui/material/Box';
import {styled} from '@mui/system';
import MakeCategoryDataGrid from "./MakeCategoryDataGrid";
import { useHistory } from "react-router-dom";

export default function MakeCategoryList(props) {
  const {Content} = Layout;
  const history = useHistory();
  const {data, loading} = props;

  const columns = [
    {
      field: "category_id",
      headerName: "Category ID",
      type: "string",
      flex: 0.5,
      minWidth: 30,
      filterable: false,
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      flex: 1.0,
      minWidth: 50,
      filterOperators: false,
    },
    {
      field: "api_source",
      headerName: "API Source",
      type: "string",
      flex: 0.75,
      minWidth: 30,
    },
    {
      field: "created_at",
      headerName: "Created At",
      type: "string",
      flex: 0.75,
      minWidth: 30,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      type: "string",
      flex: 0.75,
      minWidth: 30,
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "action",
      flex: 1,
      minWidth: 50,
      renderCell: (record) => {
        return record ? (
          <>
            <Button
              style={{ textTransform: "none" }}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/new-assets/make-category/details/${record.row.id}`,
                });
              }}
            >
              View
            </Button>
          </>
        ) : (
          ""
        );
      },
    },
  ];

  return (
    <Layout>
      <MainHeader activePage="newAssets" />
      <Layout>
        <Layout id="content-area-layout">
          <Content
            style={{
              backgroundColor: "#ffff !important",
              height: "80vh"  }}
          >
            <Row style={{ marginLeft: "1em", marginBottom: "2em" }}>
              <Col flex={4}>
                <h2>Make Category</h2>
              </Col>
            </Row>
            {data && (
              <>
                <StyledBox>
                  <MakeCategoryDataGrid
                    columns={columns}
                    data={data}
                    loading={loading}
                  />
                </StyledBox>
              </>
            )}
          </Content>
        </Layout>
      </Layout>
      <MainFooter />
    </Layout>
  );
}

const StyledBox = styled(Box)({
  height: 250,
  display: "flex",
  flexGrow: 1,
  width: '98%',
  margin: '0 auto',
  justifyContent: 'center', // Center horizontally
  '& .super-app-theme--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)'
  },
})
