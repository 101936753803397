import React from 'react';
import { Col, Layout, Row } from 'antd';
import { ExternalLink } from './Svg.js';
import './MainFooter.css';

const { Footer } = Layout;

const MainFooter = () => {
  return (
    <Footer className='main-footer' id='main-footer'>
      <Layout id='main-footer-layout'>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className='footer-menu'>
            <ul className='resources'>
              <li className='list-title'>Resources</li>
              <li>
                <a href='/documentation' target='_blank'>
                  Docs (Beta)
                  <ExternalLink/>
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className='footer-menu'>
            <ul className='legal'>
              <li className='list-title'>Legal</li>
              <li>
                <a 
                  href='https://speedleasing.com/privacy-policy/'
                  target='_blank'
                  rel='noreferrer'
                >
                  Privacy
                  <ExternalLink/>
                </a>
              </li>
              <li>
                <a 
                  href='https://speedleasing.com/terms-and-conditions/'
                  target='_blank'
                  rel='noreferrer'
                >
                  Terms
                  <ExternalLink/>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className='footer-copyright'>
          <Col>
            <p>Copyright &copy; Speed Leasing Company, LLC</p>
          </Col>
        </Row>
      </Layout>      
    </Footer>
  );
}

export default MainFooter;