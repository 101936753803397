import React, { useState, useEffect, useRef } from 'react';
import { Spin, Layout, Menu, Row, Col, Collapse, Table, Alert } from "antd";
import {
    BulbOutlined,
    FallOutlined,
    HighlightOutlined,
    WarningOutlined,
    ExclamationCircleOutlined,
    ControlOutlined
} from "@ant-design/icons";
import { GET } from "../../../util/network";
import logger from "../../../util/logger";
import { SiderProps } from "../../../components/layouts/MainSiderProps";

const { Content, Sider } = Layout;
const { Panel } = Collapse;

const TloReportDetails = (props) => {
    const { selectedTloReport, apiCall } = props
    const [activeMenu, setActiveMenu] = useState([1]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const errorRef = useRef(null);
    const criminalRef = useRef(null);
    const lienRef = useRef(null);
    const driverLicenseRef = useRef(null);
    const currentVehicleRef = useRef(null);

    const summaryCols = [
        {
            title: 'Lessee',
            dataIndex: 'lesseeName',
            key: 'lesseeName',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Request Time',
            dataIndex: 'requestedAt',
            key: 'requestedAt'
        },
        {
            title: 'Report Request Time',
            dataIndex: 'documentRequestedAt',
            key: 'documentRequestedAt'
        },
        {
            title: 'Attachment',
            dataIndex: 'attachment',
            key: 'attachment',
            render(val) {
                return val ? (
                    <a href={val} target="_blank" rel="noreferrer">View</a>
                ) : 'N/A'
            }
        }
    ];

    const bankruptcyCol = [
        {
            title: 'Party Name',
            dataIndex: 'partyName',
            key: 'partyName',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Filling Status',
            dataIndex: 'fillingStatus',
            key: 'fillingStatus',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Status Date',
            dataIndex: 'statusDate',
            key: 'statusDate',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Discharge Date',
            dataIndex: 'dischargeDate',
            key: 'dischargeDate',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Court Name',
            dataIndex: 'courtName',
            key: 'courtName',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Issue Date',
            dataIndex: 'issueDateount',
            key: 'issueDateount',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
    ];

    const criminalArrestCol = [
        {
            title: 'Court',
            dataIndex: 'court',
            key: 'court',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Case No',
            dataIndex: 'caseNumber',
            key: 'caseNumber',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Offence',
            dataIndex: 'offence',
            key: 'offence',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Disposition',
            dataIndex: 'disposition',
            key: 'disposition',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Disposition Date',
            dataIndex: 'dispositionDate',
            key: 'dispositionDate',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Criminal Against',
            dataIndex: 'criminalAgainst',
            key: 'criminalAgainst',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Record Type',
            dataIndex: 'recordType',
            key: 'recordType',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        }
    ];

    const driverLicenseCol = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Date of Birth',
            dataIndex: 'dob',
            key: 'dob',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'DL NO',
            dataIndex: 'driversLicenseNumber',
            key: 'driversLicenseNumber',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Issuing State',
            dataIndex: 'issuingState',
            key: 'issuingState',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'ID Type',
            dataIndex: 'idType',
            key: 'idType',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Expiration Date',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Current Age',
            dataIndex: 'currentAge',
            key: 'currentAge',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        }
    ];

    const lienCols = [
        {
            title: 'Agency',
            dataIndex: 'agency',
            key: 'agency',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Defendant',
            dataIndex: 'defendant',
            key: 'defendant',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Eviction',
            dataIndex: 'eviction',
            key: 'eviction',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Filing Book',
            dataIndex: 'filingBook',
            key: 'filingBook',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Filing Number',
            dataIndex: 'filingNumber',
            key: 'filingNumber',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Filing Page',
            dataIndex: 'filingPage',
            key: 'filingPage',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Lien Judgement Type',
            dataIndex: 'lienJudgementType',
            key: 'lienJudgementType',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
        {
            title: 'Plaintiff',
            dataIndex: 'plaintiff',
            key: 'plaintiff',
            render(val) {
                return val ? (
                    val
                ) : 'N/A'
            }
        },
    ];

    const currentVehicleCol = [
        { title: "VIN", dataIndex: "vin", key: "vin" },
        { title: "Vehicle Type", dataIndex: "vehicleType", key: "vehicleType" },
        { title: "Make", dataIndex: "make", key: "make" },
        { title: "Model", dataIndex: "model", key: "model" },
        { title: "Model Year", dataIndex: "modelYear", key: "modelYear" },
        { title: "registrant Owner", dataIndex: "vehicleRegistrantOwner", key: "vehicleRegistrantOwner" },
        { title: "Lien Holder", dataIndex: "lienHolderName", key: "lienHolderName" },
        { title: "Original Title Date", dataIndex: "originalTitleDate", key: "originalTitleDate" },
        { title: "Title Transfer Date", dataIndex: "titleTransferDate", key: "titleTransferDate" },
        { title: "MSRP", dataIndex: "msrp", key: "msrp" },
    ];
    const errorCol = [
        { title: "Error Code", dataIndex: "errorCode", key: "errorCode" },
        { title: "Error Message", dataIndex: "errorMessage", key: "errorMessage" },
        { title: "Error API", dataIndex: "errorApi", key: "errorApi" },
    ];

    const tloReport = async () => {
        setLoading(true);
        try {
            const response = await GET(`/api/v1/tlo-report/${selectedTloReport}`);
            setData(response.data.tloReport);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            logger.error("Error while fetching Credit Report", e);
        }

    };

    const handlePanelClick = (refNo) => {
        if (refNo === '5') {
            errorRef.current?.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });
        } else if(refNo === '4') {
            criminalRef.current?.scrollIntoView({ behavior: 'smooth'});
        } else if(refNo === '6') {
            driverLicenseRef.current?.scrollIntoView({ behavior: 'smooth'});
        }  else if(refNo === '7') {
            currentVehicleRef.current?.scrollIntoView({ behavior: 'smooth'});
        } else if(refNo === '3') {
            lienRef.current?.scrollIntoView({ behavior: 'smooth'});
        }
    }

    // const [showSsnWarning, setShowSsnWarning] = React.useState(true);

    useEffect(() => {
        tloReport();
        // setTimeout(function () {
        //     setShowSsnWarning(false);
        //   }, 6000);
    }, [apiCall])

    return (
        <Spin spinning={loading}>
            <Layout>
                <Layout>
                    <Sider {...SiderProps}>
                        <Menu
                            defaultSelectedKeys={[1]}
                            selectedKeys={[activeMenu.toString()]}
                            style={{ width: "100%" }}
                            onSelect={({ key }) => {
                                if (!key.includes("sub")) {
                                    handlePanelClick(key);
                                    setActiveMenu([...activeMenu, key]);
                                }
                            }}
                        >
                            <Menu.Item key="1" >
                                <BulbOutlined />
                                Summary
                            </Menu.Item>
                            <Menu.Item key="2" >
                                <FallOutlined />
                                Bankruptcies
                            </Menu.Item>
                            <Menu.Item key="3">
                                <HighlightOutlined />
                                Leign and Judgements
                            </Menu.Item>
                            <Menu.Item key="4">
                                <WarningOutlined />
                                Criminal and Arrests
                            </Menu.Item>
                            <Menu.Item key="6">
                                <ControlOutlined />
                                Driver Licenses
                            </Menu.Item>
                            <Menu.Item key="7">
                                <ControlOutlined />
                                Current Vehicles
                            </Menu.Item>
                        </Menu>
                    </Sider>

                    <Layout id="content-area-layout">
                        <Content id="main-content">
                            <Row gutter={[0, 24]}>
                                <Col span={24}>
                                    <Collapse
                                        accordion={false}
                                        activeKey={activeMenu}
                                        onChange={(key) => {
                                            key !== undefined && setActiveMenu(key);
                                        }}
                                    >
                                        <Panel
                                            header="Summary"
                                            key="1"
                                            className="slc-panel">
                                            {
                                                data.summary && (
                                                    <>
                                                        {!data.summary.ssnMatchedWithPdf && (
                                                            <>
                                                                <Alert
                                                                    message="Lessee SSN not matched with TLO pdf"
                                                                    type="error"
                                                                    showIcon
                                                                    closable
                                                                /><br />
                                                            </>

                                                        )}

                                                        <Table
                                                            scroll={{ x: '100%' }}
                                                            rowKey="id"
                                                            columns={summaryCols}
                                                            dataSource={[data.summary]}
                                                            pagination={false}
                                                            size="small"
                                                            className="slc-table"
                                                        />
                                                    </>
                                                )
                                            }
                                            <br />
                                            {
                                                data.errors && (
                                                    <div ref={errorRef}>
                                                        <Table
                                                            scroll={{ x: '100%' }}
                                                            rowKey="id"
                                                            columns={errorCol}
                                                            dataSource={data.errors}
                                                            pagination={true}
                                                            size="small"
                                                            className="slc-table"
                                                        />
                                                    </div>
                                                )
                                            }
                                        </Panel>

                                        <Panel
                                            header="Bankruptcies"
                                            key="2"
                                            className="slc-panel">
                                            {
                                                data.bankruptcy && (
                                                    <Table
                                                        scroll={{ x: '100%' }}
                                                        rowKey="id"
                                                        columns={bankruptcyCol}
                                                        dataSource={data.bankruptcy}
                                                        pagination={false}
                                                        size="small"
                                                        className="slc-table"
                                                    />
                                                )
                                            }
                                        </Panel>

                                        <Panel
                                            header="Lien and Judgement"
                                            key="3"
                                            className="slc-panel">
                                            {
                                                data.lienJudgement && (
                                                    <div ref={lienRef}>
                                                        <Table
                                                            scroll={{ x: '100%' }}
                                                            rowKey="id"
                                                            columns={lienCols}
                                                            dataSource={data.lienJudgement}
                                                            pagination={true}
                                                            size="small"
                                                            className="slc-table"
                                                        />
                                                    </div>

                                                )
                                            }

                                        </Panel>

                                        <Panel
                                            header="Criminal Records"
                                            key="4"
                                            className="slc-panel">
                                            {
                                                data.criminalRecord && (
                                                    <div ref={criminalRef}>
                                                        <Table
                                                            scroll={{ x: '100%' }}
                                                            rowKey="id"
                                                            columns={criminalArrestCol}
                                                            dataSource={data.criminalRecord}
                                                            pagination={true}
                                                            size="small"
                                                            className="slc-table"
                                                        />
                                                    </div>

                                                )
                                            }
                                        </Panel>

                                        <Panel
                                            header="Driver Licenses"
                                            key="6"
                                            className="slc-panel">
                                            {
                                                data.driverLicense && (
                                                    <div ref={driverLicenseRef}>
                                                        <Table
                                                            scroll={{ x: '100%' }}
                                                            rowKey="id"
                                                            columns={driverLicenseCol}
                                                            dataSource={data.driverLicense}
                                                            pagination={true}
                                                            size="small"
                                                            className="slc-table"
                                                        />
                                                    </div>

                                                )
                                            }
                                        </Panel>
                                        <Panel
                                            header="Current Vehicles"
                                            key="7"
                                            className="slc-panel">
                                            {
                                                data.currentVehicle && (
                                                    <div ref={currentVehicleRef}>
                                                        <Table
                                                            scroll={{ x: '100%' }}
                                                            rowKey="id"
                                                            columns={currentVehicleCol}
                                                            dataSource={data.currentVehicle}
                                                            pagination={true}
                                                            size="small"
                                                            className="slc-table"
                                                        />
                                                    </div>
                                                )
                                            }
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </Spin>
    )
}

export default TloReportDetails
