import React from "react";
import { Table, Row, Col, Typography, Card } from "antd";
import { useHistory } from 'react-router-dom';
import TloReports from './credits/TloReports';

let auth_token = window.localStorage.getItem('auth_token')

export default ({ data}) => {
    const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
    let loggedInUserId = null;
    if(authData !== null) {
      loggedInUserId = authData.id;
    }
    const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
    const disableFlag = locked_by != loggedInUserId ? true : false
   
    const deletedColesseesColumns = [
      {
        title: 'Full Name',
        dataIndex: 'fullName',
        key: 'fullName',
      },
      {
        title: 'Email Address',
        dataIndex: 'emailAddress',
        key: 'emailAddress',
      },

      {
        title: 'Deleted At',
        dataIndex: 'deletedAt',
        key: 'deletedAt',
      },

    ];

  const columns = [
    { title: 'Lessee', dataIndex: 'lessee', key: 'lessee' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Average Score', dataIndex: 'average_score', key: 'average_score' },
    {
      title: 'Actions', dataIndex: 'actions', key: 'actions',
      render(val) {
        return ( val && !disableFlag ?
            <a href={disableFlag ? "javascript:void(0)" : `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=${encodeURIComponent(val)}`} target="_blank" rel="noopener noreferrer">Download</a> :
            null
        )
      }
    },
    { title: 'Requested At', dataIndex: 'requested_at', key: 'requested_at' },
    { title: 'Updated At', dataIndex: 'updated_at', key: 'updated_at' },
    { title: 'Source', dataIndex: 'api_name', key: 'api_name' },
    {
      title: 'Details', dataIndex: 'details', key: 'details',
      render(val, row) {
        return  <a href={`/lease-applications/${data.id}/credit-report/${row.id}/details`}>View</a>
      }
    }
  ];

    return data ? (
      <>
        {
          data.deletedColessees.map((colessee) =>
            {
              return <Card key={colessee.id}>
                <Table className="slc-table" size="small" pagination={false} columns={deletedColesseesColumns} dataSource={[colessee]} />
                <Typography.Title level={4}>
                  Credit Reports
                </Typography.Title>
                <Row>
                  <Col span={24}>
                    <Table className='slc-table' rowKey="id" columns={columns} dataSource={colessee.creditReports} pagination={true} size="small" bordered={false}/>
                  </Col>
                </Row>
                <TloReports data={colessee} />
              </Card>
            }
          )
        }
        </>
    ) : null;
};