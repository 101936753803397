import React, { useEffect } from 'react';
import { Modal } from 'antd';

// `dataxEmploymentDetails`
//   -> Object from `leaseApplication.dataxEmploymentDetails` array
//   -> Must be `undefined` as initial value
export default function EmploymentVerificationDetailsModal({ dataxEmploymentDetail }) {
  const EmploymentInfoContext = React.createContext();
  const [modal, contextHolder] = Modal.useModal();

  const modalConfig = {
    width: 600,
    title: '',
    icon: null,
    closable: true,
    maskClosable: true,
    okButtonProps: { style: { display: 'none' } },
    content: (
      <EmploymentInfoContext.Consumer>
        {data => (
          <>
            <p>
              <b>Employer Name</b>: {data?.employer}
            </p>
            <p>
              <b>Employee First Name</b>: {data?.first_name}
            </p>
            <p>
              <b>Employee Middle Name</b>: {data?.middle_name}
            </p>
            <p>
              <b>Employee Last Name</b>: {data?.last_name}
            </p>
            <p>
              <b>Employee SSN</b>: {data?.employee_ssn}
            </p>
            <p>
              <b>Employment Status</b>: {data?.employment_status}
            </p>
            <p>
              <b>Employment Start Date</b>: {data?.employment_start_date}
            </p>
            <p>
              <b>Total Months With Employer</b>: {data?.total_months_with_employer}
            </p>
            <p>
              <b>Termination Date</b>: {data?.termination_date}
            </p>
            <p>
              <b>Position Title</b>: {data?.title}
            </p>
            <p>
              <b>Rate Of Pay</b>: {data?.rate_of_pay}
            </p>
            <p>
              <b>Annualized Income</b>: {data?.annualized_income}
            </p>
            <p>
              <b>Annual Pay Frequencylized Income</b>: {data?.pay_frequency}
            </p>
            <p>
              <b>Pay Period Frequency</b>: {data?.pay_period_frequency}
            </p>
            <p>
              <b>Average Hours Worked Per Pay Period</b>: {data?.average_hours_worked_per_pay_period}
            </p>
          </>
        )}
      </EmploymentInfoContext.Consumer>
    ),
  };

  useEffect(() => {
    // Everytime `dataxEmploymentDetail` property is passed/updated, the modal will be shown
    // On initial state, `dataxEmploymentDetail` will be `undefined`, thus using truthy conditional block
    if (dataxEmploymentDetail) {
      modal.info(modalConfig)
    }
  }, [dataxEmploymentDetail]);

  return (
    <EmploymentInfoContext.Provider value={dataxEmploymentDetail}>
      {contextHolder}
    </EmploymentInfoContext.Provider>
  )
}
