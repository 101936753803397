import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { Table, Row, Col, Button, message, Tag } from 'antd';
import { POST } from '../../../util/network';
import logger from '../../../util/logger';
import CustomInputText from '../components/CustomInputText';
import './ApprovalEvents.css';
import DealershipNotes from "../notes/DealershipNotes"

export default function ApprovalEvents({ approvalType, type, dealershipId, isApproved, getDealership, dataSource, ...props }) {
  const { allowedApprovalGet, allowedApprovalUpdate, handleNoAuth , underwritingApproval, salesApproval } = props;
 
  const history = useHistory();
 
  const columns = [
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event',
      render(val, row) {
        return row.event.toLowerCase() === 'approved' ? (<Tag color="#87d068">{val}</Tag>) 
        : (<Tag color="#f50">{val}</Tag>)
      }
    },
    {
      title: 'Team Member',
      dataIndex: 'team_member',
      key: 'teamMember'
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments'
    },
    {
      title: 'Date and Time',
      dataIndex: 'created_at',
      key: 'created_at'
    }
  ];

  const [approved, setApproved ] = useState(isApproved);

  const getComments = () => {
    const ele = document.getElementById(`${approvalType}-comments`);

    if (ele && ele.value) {
      return ele.value;
    }

    return null;
  }

  const handleClick = () => {
    const comments = getComments();
    let approval = !approved;
    if (approval) {
      if ((type == "Underwriting") && salesApproval == false) {
        return message.error("Cant be Approved, Sales Approval Needed");
      }

      if (type == "Credit Committee" && underwritingApproval == false ) {
        return message.error("Cant be Approved!, Underwriting Approval and Sales Approval Needed");
      }
    }
    handleApproval(comments, approval);
  }

  const handleApproval = async (comments, approval) => {      
    const approvalType = type == 'Sales' ? 'recommendation' : 'approval';
    const errMessage = `Error while saving ${approvalType}`;

    try {
      await POST(`/api/v1/dealerships/approval?approvalType=${type}&approval=${approval}&id=${dealershipId}`, {
        comments: comments
      });
      setApproved(approval);
      getDealership();
      message.success("Data save successfully");
    } catch (e) {
      logger.error(errMessage, e);
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error(errMessage);
      }      
    }
  }

  return (      
    <>  
      { allowedApprovalUpdate &&
        <Row className='approval-controls' gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={20} xl={21}>
            <CustomInputText id={`${approvalType}-comments`} name={`${approvalType}-comments`} label="Comments" required={false} />
          </Col>
          <Col className='button-col' xs={24} sm={24} md={24} lg={4} xl={3}> 
            {
              approved && approvalType == 'sales' && <Button type="primary" danger onClick={handleClick} disabled={!allowedApprovalUpdate}>Disadvise</Button> ||
              approved && approvalType == 'underwriting' && <Button type="primary" danger onClick={handleClick} disabled={!allowedApprovalUpdate}>Unapproved</Button> || 
              approved && approvalType == 'final' && <Button type="primary" danger onClick={handleClick} disabled={!allowedApprovalUpdate}>Unapproved</Button> ||

              !approved && approvalType == 'sales' && <Button type="primary" onClick={handleClick} disabled={!allowedApprovalUpdate}>Recommend</Button> ||
              !approved && approvalType == 'underwriting' && <Button type="primary" onClick={handleClick} disabled={!allowedApprovalUpdate}>Approve</Button> || 
              !approved && approvalType == 'final' && <Button type="primary" onClick={handleClick} disabled={!allowedApprovalUpdate}>Approve</Button> 

            }
          </Col>
        </Row>
      }
      {allowedApprovalGet &&
        <>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Table
                className='slc-table'
                rowKey="id"
                dataSource={dataSource}
                columns={columns}
                pagination={true}
                size="small"
              />
            </Col>
          </Row>

          {(type == "Underwriting" || type == "Sales") &&
            <DealershipNotes
              type={type}
              handleNoAuth={handleNoAuth}
              dealershipId={dealershipId}
            />
          }
        </>
      }
    </>
  );
}