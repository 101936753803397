import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import {
  Card,
  Form,
  Radio,
  Select,
  Col,
  Input,
  DatePicker,
  message,
  Button,
  Row
} from "antd";
import { GET, POST, PUT } from '../../../util/network';
import logger from '../../../util/logger';
import WelcomeCallHistory from './WelcomeCallHistory';
import moment from 'moment';
import { GetResourceActions, IsActionAllowed } from '../../../util/AllowedActions';
import capitalize from '../../../util/Util';

const Option = Select.Option;

export default function WelcomeCall({ data, appNumber, setLoading, saveApplicationDetails, ...props }) {
  const { allowedActions, handleNoAuth } = props;
  const history = useHistory(); 
  const welcomeCallActions = GetResourceActions('WelcomeCall', allowedActions);

  const allowedCreate = IsActionAllowed('create', welcomeCallActions);
  const allowedGet = IsActionAllowed('get', welcomeCallActions);
  const allowedUpdate = IsActionAllowed('update', welcomeCallActions);

  const [dueDate, setDueDate] = useState(false);
  const [types, setTypes] = useState([]);
  const [results, setResults] = useState([]);
  const [form] = Form.useForm();
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
    loggedInUserId = authData.id;
  }
  const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
  const disableFlag = locked_by != loggedInUserId ? true : false

  useEffect(() => {
    const getTypes = async () => {
      if (allowedGet) {
        try {
          const response = await GET(`/api/v1/welcome-call-types`);
          setTypes(response.data.welcome_call_types);
        } catch (e) {
          logger.error("Fetch Types Error", e);

          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error('Error fetching welcome call types');
          }
        }
      }
    };

    const getResults = async () => {
      if (allowedGet) {
        try {
          const response = await GET(`/api/v1/welcome-call-results`);
          setResults(response.data.welcome_call_results);
        } catch (e) {
          logger.error("Fetch Results Error", e);

          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error('Error fetching welcome call results');
          }
        }
      }
    };

    if (data && data.welcomeCallDueDateUtc) {      
      const formattedDate = moment.utc(data.welcomeCallDueDateUtc).format('YYYY-MM-DD');      
      if (formattedDate) {
        setDueDate(moment(formattedDate))
      }
    }

    getTypes();
    getResults();
  }, []);

  const handleDueDateChange = async (e) => {
    const updatedDate = moment(e).format('YYYY-MM-DD') ? moment(e).format('YYYY-MM-DD') : null;    
    
    if (!updatedDate) return false;
    
    if (moment(updatedDate).isValid() == false) {
      setDueDate(null);
      return false;
    }

    setDueDate(moment(e))
    
    if (allowedUpdate) {
      setLoading(true);

      try {
        await PUT(`/api/v1/lease_applications/welcome-call-due-date`, {
          application_identifier: appNumber,
          welcome_call_due_date: updatedDate
        });

        message.success('Due date successfully changed');
      } catch (e) {
        logger.error('Error updating Welcome Call Due Date: ', e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while saving your data.');
        }
      }
      setLoading(false);
    }
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const onFinish = async (values) => {
    if (allowedCreate) {
      values = { ...values };
      
      let errMessage = 'Error while saving your data.';
      setLoading(true);

      try {
        await POST(`/api/v1/lease_applications/create_welcome_call`, {
          application_identifier: appNumber,
          lease_application_welcome_calls_attributes: values
        });
        message.success("Data saved successfully");
        form.resetFields();
      } catch (e) {
        if (e == 'Error: Request failed with status code 400') {
          errMessage += ' Verify all required lease fields are populated.';
        }
        logger.error("Save Welcome Call Error", errMessage);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error(errMessage);
        }
      }
      setLoading(false);
    }
  }

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  return allowedGet ? (   
    <>      
      <Row>
        <Col className='flex-as-fe mr-auto' xs={24} sm={24} md={8} lg={6} xl={4}>
          <label>Welcome Call Due Date</label>
          <DatePicker
            className='mt-8'
            disabled={ !allowedUpdate || disableFlag }
            format='MM-DD-YYYY'
            onChange={ handleDueDateChange }
            style={{ width: '100%' }}  
            value={ dueDate }
          />
        </Col>
      </Row>
      { allowedCreate && 
        <Form {...layout} form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Row gutter={[16, 16]} className='mt-20'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card title='Log a Call'>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                    <Form.Item 
                      label="Status" 
                      name="welcomeCallStatusId"
                      rules={[{ required: true, message: 'Required' }]}
                    >
                      <Radio.Group>
                        <Radio style={{ display: "block", paddingLeft: 10 }} value="1" disabled={disableFlag}>
                          Complete
                        </Radio>
                        <Radio style={{ display: "block", paddingLeft: 10 }} value="2" disabled={disableFlag}>
                          Not Complete
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                    <Row>
                      <Col className='flex-as-fe mr-auto' xs={24} sm={24} md={24} lg={6} xl={8}>
                        <Form.Item 
                          label="Assigned Department" 
                          name="department"
                          rules={[{ required: true, message: 'Required' }]}
                        >
                          <Select
                            placeholder="Please select department"
                            disabled={disableFlag}
                          >
                            {
                              (Object.keys(data.leaseApplicationWelcomeCallForm.assignedDepartment || {}).map((key) => {
                                return (
                                  <Option key={key} value={data.leaseApplicationWelcomeCallForm.assignedDepartment[key]}>{capitalize(key)}</Option>
                                )
                              }))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col className='flex-as-fe mr-auto' xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item 
                          label="Type" 
                          name="welcome_call_type_id"
                          rules={[{ required: true, message: 'Required' }]}
                        >
                          <Select placeholder="Please select Type" disabled={disableFlag}>
                            {
                              (types || []).map((obj, key) => {
                                return <Option key={key} value={obj.id}>{obj.description}</Option>
                              })
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col className='flex-as-fe' xs={24} sm={24} md={24} lg={10} xl={9}>
                        <Form.Item 
                          label="Result" 
                          name="welcome_call_result_id"
                          rules={[{ required: true, message: 'Required' }]}
                        >
                          <Select placeholder="Please select Result" disabled={disableFlag}>
                            {
                              (results || []).map((obj, key) => {
                                return <Option key={key} value={obj.id}>{obj.description}</Option>
                              })
                            }
                          </Select>
                        </Form.Item>
                      </Col>                      
                    </Row>
                  </Col>
                </Row>
                <Row gutter={[16, 8]}>                
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label="Notes"
                          wrapperCol={{ span: 24 }}
                          style={{ width: "100%" }}
                          name="notes"
                        >
                          <Input.TextArea rows={4} style={{ width: "100%" }} disabled={disableFlag}/>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={[16, 8]}>
                  <Col span={24}>
                    <Button type="primary" htmlType="submit" disabled={disableFlag}>
                      Save
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>              
        </Form>
      }

      <Row gutter={[16, 16]} className='mt-20'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card title='Welcome Call History'>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>      
                <WelcomeCallHistory  setLoading={setLoading} appNumber={appNumber} data={data} save={saveApplicationDetails}  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>                
    </>           
  ) : null;
}