import React from "react";
import { Table } from "antd";

const columns = [
  {
    title: 'Item',
    dataIndex: 'item',
    key: 'item',
  },
  {
    title: 'Phone Line Type',
    dataIndex: 'phone_number_line',
    key: 'phone_number_line',
  },
  {
    title: 'Phone Carrier',
    dataIndex: 'phone_number_carrier',
    key: 'phone_number_carrier',
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
  },
  {
    title: 'Status',
    key: 'status', 
    dataIndex: 'status',
  },
];

export default function CoLesseeVerifications({ data }) {  
    const colessee_ids = []
    data && data.colessee && colessee_ids.push(data.colessee.id)
    data && data.colessee && data.colessee.home_address && colessee_ids.push(data.colessee.home_address.id)
    const dataSource = data.leaseValidations.filter(val => ["Home Phone Validation", "Address Validation"].includes(val.type) && colessee_ids.includes(val.validatable_id) ) || []    
    return data && data.colessee ? (
        <Table className='slc-table' rowKey="id" columns={columns} dataSource={dataSource} size="small" pagination={true}/>
    ) : 'N/A';
}