import React, { useState } from 'react';
import '../Signin.css';
import { useHistory } from 'react-router-dom';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';
import { PUT } from '../../util/network';
import { message } from 'antd';

function ResetAdminPassword(props) {
    const { token } = props.match.params;
    const history = useHistory();
    const [formData, setFormData] = useState({ password: '', confirm_password: '' })
    const [errorMsg, setErrorMsg] = useState('');

    const handleSubmit = async () => {
        try {
            const response = await PUT(`/api/v1/reset-password/${token}`, formData);
            message.success(response.data?.message || 'Password reset is successful. Please login again!')
            history.push('/admins/login');
        } catch (error) {
            setErrorMsg(error.response?.data?.message || '');
        }
    };

    const handleDataChange = (data) => {
        setFormData({ ...formData, [data.target.name]: data.target.value });
    };

    const submitBtnDisable = formData.password && formData.confirm_password ? false : true

    return (
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column id='login-column'>
                <Header as='h3' style={{ color: '0F1419', marginTop: '10px' }} textAlign='center'>
                    Speed Leasing Admin Portal
                </Header>

                { errorMsg &&
                    (<span style={{ color: 'red' }}> {errorMsg} </span>)
                }
                <Form size='large' onSubmit={handleSubmit}>
                    <Segment stacked>
                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            name="password"
                            type='password'
                            placeholder='New Password'
                            onChange={handleDataChange}
                            required={true}
                            minLength={8}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$"
                        />
                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            name="confirm_password"
                            type='password'
                            placeholder='Confirm Password'
                            onChange={handleDataChange}
                            required={true}
                            minLength={8}
                            match={'password'}
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$"
                        />

                        <span style={{ color: 'blue', fontSize: 11, marginBottom: 5 }}>
                            Password length should contain at least min 8 characters, 1 special character, 1 uppercase letter, 1 numeric and 1 lowercase letter
                        </span>

                        <Button
                            id='reset-button'
                            disabled={submitBtnDisable}
                            primary
                            fluid
                            size='large'
                            type='submit'
                        >
                            Save Password
                        </Button>
                    </Segment>
                </Form>
            </Grid.Column>
        </Grid >
    )
}

export default ResetAdminPassword;
