import React from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import { styled } from '@mui/system';
import { clearDataGridFilters, updateDataGridFilters, updatePageState, updateDataGridFilterFlag } from "../../../reducers/LeaseApplications";
import {useDispatch, useSelector} from "react-redux";

LicenseInfo.setLicenseKey('06c2f0cf50adbcfcc3f1fdcdf8402d39Tz00OTMwMCxFPTE2OTIzNzY1MTM3ODUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export default function ApplicationsDataGrid(props) {
    const { columns, data, isTimer, isNewAppTimer, loading, sortModel, updateFilterSorting, total, pageState, filterTotal, filteredData, filterFlag } = props;
    const dispatch = useDispatch();
    const dataGridFilter = useSelector((state) => state.leaseApplications.dataGridFilter);
    let newDataGridFilter = {...dataGridFilter};
    let prevDataGridFilter = {...dataGridFilter};
    const initialDataGridFilter =  {
        dealership_name: "",
        dealership_compare_operator: "",
        lessee_name: "",
        lessee_compare_operator: "",
        colessee_name: "",
        colessee_compare_operator: "",
        document_statuses: [],
        credit_statuses: [],
    }
    const onFilterChange = React.useCallback((filterModel) => {
        newDataGridFilter = {...initialDataGridFilter}
        filterModel.items.map((item) => setFilterItems(item))
        if (JSON.stringify(newDataGridFilter) !== JSON.stringify(prevDataGridFilter)){
            prevDataGridFilter = { ...newDataGridFilter }
            dispatch(updateDataGridFilters({ ...newDataGridFilter }))
            dispatch(updateDataGridFilterFlag({}))
        }
    }, []);

    const setFilterItems = (item) => {
        if(item.columnField === "Lessee"){
            if(item.value && item.value.length >= 4 && (item.value !== dataGridFilter.lessee_name || item.operatorValue !== dataGridFilter.lessee_compare_operator)){
                newDataGridFilter = { ...newDataGridFilter, lessee_name: item.value, lessee_compare_operator: item.operatorValue }
            }
        }
        if(item.columnField === "Colessee"){
            if(item.value && item.value.length >= 4 && (item.value !== dataGridFilter.colessee_name || item.operatorValue !== dataGridFilter.colessee_compare_operator)){
                newDataGridFilter = { ...newDataGridFilter, colessee_name: item.value, colessee_compare_operator: item.operatorValue }
            }
        }
        if(item.columnField === "Dealership"){
            if(item.value && item.value.length >= 4 && (item.value !== dataGridFilter.dealership_name || item.operatorValue !== dataGridFilter.dealership_compare_operator)){
                newDataGridFilter = { ...newDataGridFilter, dealership_name: item.value, dealership_compare_operator: item.operatorValue }
            }
        }
        if(item.columnField === "CreditStatus"){
            const creditStatuses = ['unsubmitted',  'draft', 'credit_review_stage_2', 'awaiting_credit_decision', 'approved_with_contingencies', 'approved', 'requires_additional_information', 'bike_change_requested', 'declined', 'rescinded', 'withdrawn']
            const creditStatus = item.value?.trimEnd()?.toLowerCase()?.replace(/ /g,"_")
            if(item.value && creditStatuses.includes(creditStatus) && !dataGridFilter.credit_statuses.includes(creditStatus)){
                let current_credit_statuses = [...newDataGridFilter.credit_statuses, creditStatus]
                newDataGridFilter = { ...newDataGridFilter, credit_statuses: current_credit_statuses }
            }
        }
        if(item.columnField === "DocumentStatus"){
            const documentStatuses = ['no_documents', 'documents_requested', 'documents_issued', 'lease_package_received', 'funding_delay', 'funding_approved', 'funded', 'canceled']
            const documentStatus = item.value?.trimEnd()?.toLowerCase()?.replace(/ /g,"_")
            if(item.value && documentStatuses.includes(documentStatus) && !dataGridFilter.document_statuses.includes(documentStatus)){
                let current_document_statuses = [...newDataGridFilter.document_statuses, documentStatus]
                newDataGridFilter = { ...newDataGridFilter, document_statuses: current_document_statuses }
            }
        }
    }

    return (
        <>
            <StyledDataGridPro
              disableColumnReorder={true}
              disableSelectionOnClick={true}
              getRowHeight={() => 'auto'}
              columns={columns}
              rows={data}
              getRowClassName={(params) => {
                  return params.row?.isNewApp === true && isNewAppTimer === true ? "highlight" : "";
              }}
              loading={isTimer === true ? false : loading}
              rowsPerPageOptions={[25, 50, 100]}
              sortModel={sortModel}
              onSortModelChange={updateFilterSorting}
              density="comfortable"
              pagination
              rowCount={total ?? 0}
              page={pageState.page <= 0 ? 0 : pageState.page}
              pageSize={pageState.limit}
              paginationMode="server"
              onPageChange={(newPage) => {
                  dispatch(updatePageState({ page: newPage }))
              }}
              onPageSizeChange={(newPageSize) => dispatch(updatePageState({ limit: newPageSize  }))}
              sortingOrder={['desc', 'asc']}
              filterMode='server'
              onFilterModelChange={onFilterChange}
              sx={{
                  ".highlight": {
                      bgcolor: "#e6f3ff !important",
                      "&:hover": {
                          bgcolor: "darkgrey",
                      },
                  },
              }}
            />
        </>
    )
}

const StyledDataGridPro = styled(DataGridPro)({
    boxShadow: 'none !important',
    border: "none !important",
    '& .MuiDataGrid-cell': {
        padding: '10px',
    },
    '& .MuiDataGrid-row': {
        fontSize: 12
    },
    '& .wrapHeader .MuiDataGrid-colCellTitle': {
        overflow: "hidden",
        lineHeight: "20px",
        whiteSpace: "normal"
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontSize: 14,
        fontWeight: 600,
        whiteSpace: 'normal !important',
        lineHeight: 'normal',
        overflowWrap: "break-word",
        letterSpacing: '-0.5px'
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: "#e8ecf0",
        color: "#000000",
    },
    '& .MuiChip-label': {
        paddingLeft: '12px',
        paddingRight: '12px',
        whiteSpace: 'normal !important',
        wordWrap: ' break-word',
        lineHeight: ' 1',
        paddingBottom: '2px',
        paddingTop: '2px',
        letterSpacing: '-0.5px'
    }
})
