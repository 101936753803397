import React, {useState} from "react";
import {Layout, Input} from "antd";
import '../../App.css';
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import Box from '@mui/material/Box';
import LesseesDataGrid from './components/LesseesDataGrid'
import {styled} from '@mui/system';
import Typography from "@material-ui/core/Typography";
import {useHistory} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import LesseeFilter from './LesseeFilter'
import Button from "@mui/material/Button";

export default function LesseesList(props) {
  const {Content} = Layout;
  const {Search} = Input;
  const history = useHistory();
  const {data, loading, initialState, pageState, setPageState, total} = props;
  const [filter, setFilter] = useState(false);
  const [searchVal, setSearchVal] = useState(pageState.search);
  const [state, setState] = useState({...pageState});

  const onChange = (e) => {
    if (e.target.value === '') {
      setPageState({...pageState, search: '', filter: false})
    }
    setSearchVal(e.target.value);
  };

  const isClearButtonViewable = () => {
    return (pageState.filter === true)
  }

  const clearFilter = () => {
    setState({...initialState, search: searchVal})
    if (pageState.filter === true) {
      setPageState({...initialState, search: searchVal})
    }
  }

  const columns = [
    {
      field: 'id',
      headerName: 'Id',
      type: 'string',
      flex: 0.7,
      minWidth: 10,
      filterable: false,
      renderCell: (record) => {
        return <a href={`/lessee/${record.row.id}`}>{record.row.id}</a>
      }
    },
    {
      field: 'fullName', headerName: 'Full Name', type: 'string', flex: 1, minWidth: 75, filterable: false
    },
    {
      field: 'dateOfBirth', headerName: 'Date of Birth', type: 'string', flex: 1, minWidth: 50, filterable: false
    },
    {
      field: 'leaseApplicationIdentifier',
      headerName: 'Lease Application Id',
      type: 'string',
      flex: 1,
      minWidth: 75,
      filterable: false,
      renderCell: (record) => {
        return <a href={`/lease-applications/${record.row.leaseApplicationId}`}>{record.row.leaseApplicationIdentifier}</a>
      }
    },
    {
      field: 'dealership', headerName: 'Dealership', type: 'string', flex: 1, minWidth: 50, filterable: false
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      type: 'action',
      flex: 1,
      filterable: false,
      renderCell: (record) => {
        return record ? (
          <>
            <Button
              style={{textTransform: "none"}}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/lessee/${record.row.id}`,
                });
              }}
            >
              View
            </Button>
            <Button
              style={{textTransform: "none", marginLeft: "1rem"}}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/lessee_edit/${record.row.id}`,
                });
              }}
            >
              Edit
            </Button>
          </>
        ) : (
          ""
        );
      },
    },
  ]

  return (
    <Layout>
      <MainHeader activePage="lesseeRelated"/>
      <Layout>
        <Layout id='content-area-layout'>
          <Content id='main-content' style={{backgroundColor: "#ffff !important"}}>
            <Box sx={{flexGrow: 1, pb: 2}}>
              <Grid container columnSpacing={3} direction="row" justifyContent='flex-start' alignItems="center">
                <Grid item md={9} sm={5}>
                  <Typography variant={"h4"}>Lessees</Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2} direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
              >
                <Grid item md={9} sm={5}>
                  <Search placeholder="Global Search" size="large" value={searchVal} onChange={onChange}
                          allowClear className="leaseAppListSearch" onSearch={() => {
                    setPageState({...initialState, search: searchVal})
                    setState({...initialState, search: searchVal})
                  }} enterButton/>
                </Grid>
                <Grid item md={2} sm={4} style={{display: "flex", justifyContent: "right"}}>
                  {
                    isClearButtonViewable() &&
                    <Button variant="outlined" onClick={() => clearFilter()}>
                      Clear Filter
                    </Button>
                  }
                </Grid>
                <Grid item md={1} sm={3}>
                  <Button variant="outlined" style={{marginLeft: 'auto'}} onClick={() => setFilter(value => !value)}>
                    Filters
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <LesseeFilter
              setPageState={setPageState}
              filter={filter}
              setFilter={setFilter}
              setSearchVal={setSearchVal}
              state={state}
              setState={setState}
              clearFilter={clearFilter}
            />
            <StyledBox>
              {
                data &&
                <LesseesDataGrid
                  columns={columns}
                  data={data}
                  loading={loading}
                  setPageState={setPageState}
                  pageState={pageState}
                  total={total}
                />
              }
            </StyledBox>
          </Content>
        </Layout>
      </Layout>
      <MainFooter/>
    </Layout>
  );
}

const StyledBox = styled(Box)({
  height: 820,
  display: "flex",
  flexGrow: 1,
  width: '100%',
  '& .super-app-theme--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)'
  },
})
