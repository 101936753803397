import React from 'react'
import { Row, Col, Form, Input, Radio, Typography } from 'antd';
const { Text } = Typography

export default function PaymentFrequency(props) {
    const { leaseApplication, radioButtonState, formatDate, secondPaymentDateDisplay, currencyFormatter } = props;

    return (
        <>
            <Row gutter={[8, 8]} className='vcc-row'>
                <Col span={24}>
                    <Text level={4} className='vcc-text'>
                        Can you please confirm whether you will be making payments once a month or split payments? <Text strong>{leaseApplication.payment && leaseApplication.payment.paymentMonthlyPaymentLabel ? leaseApplication.payment.paymentMonthlyPaymentLabel.toLowerCase() : null}</Text>.
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className='vcc-row'>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <Form.Item
                        name={['paymentFrequencyConfirm']}
                        className='vcc-form-item'
                        rules={[{ required: true, message: 'Required' }]}
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                {!radioButtonState.paymentFrequencyConfirm &&
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Form.Item
                            name={['paymentFrequencyConfirmComment']}
                            className='vcc-form-item'
                            rules={[{ required: true, message: 'Required' }]}
                        >
                            <Input  placeholder='Comments' />
                        </Form.Item>
                    </Col>
                }
            </Row>

            <Row gutter={[8, 8]} className='vcc-row'>
                <Col span={24}>
                    <Text level={4} className='vcc-text'>
                        Can you please confirm when your first payment will be due?  <Text strong>{formatDate(leaseApplication.payment ? leaseApplication.payment.firstPaymentDate : null) || "N/A"}</Text>.
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className='vcc-row'>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <Form.Item
                        name={['firstPaymentDateConfirm']}
                        className='vcc-form-item'
                        rules={[{ required: true, message: 'Required' }]}
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                {!radioButtonState.firstPaymentDateConfirm &&
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Form.Item
                            name={['firstPaymentDateConfirmComment']}
                            className='vcc-form-item'
                            rules={[{ required: true, message: 'Required' }]}
                        >
                            <Input placeholder='Comments' />
                        </Form.Item>
                    </Col>
                }
            </Row>

            {
                secondPaymentDateDisplay ?
                    <>
                        <Row gutter={[8, 8]} className='vcc-row'>
                            <Col span={24}>
                                <Text level={4} className='vcc-text'>
                                    Can you please confirm when your second payment will be due? <Text strong>{secondPaymentDateDisplay}</Text>.
                                </Text>
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]} className='vcc-row'>
                            <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                                <Form.Item
                                    name={['secondPaymentDateConfirm']}
                                    className='vcc-form-item'
                                    rules={[{ required: true, message: 'Required' }]}
                                >
                                    <Radio.Group>
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            {!radioButtonState.secondPaymentDateConfirm &&
                                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                    <Form.Item
                                        name={['secondPaymentDateConfirmComment']}
                                        className='vcc-form-item'
                                        rules={[{ required: true, message: 'Required' }]}
                                    >
                                        <Input  placeholder='Comments' />
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                    </>
                    : null
            }

            {/* <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text level={4} className="vcc-text">
                        Does that match your pay date?
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <Form.Item
                        name={["dueDatesMatchLesseePayDate"]}
                        rules={[{ required: true, message: 'Required' }]}
                        className='vcc-form-item'
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                {!radioButtonState.dueDatesMatchLesseePayDate &&
                    <Col xs={24} sm={24} md={24} lg={18} xl={18}>
                        <Form.Item
                            name={['dueDatesMatchLesseePayDateComment']}
                            className='vcc-form-item'
                            rules={[{ required: true, message: 'Required' }]}
                        >
                            <Input size='large' placeholder='Comments' />
                        </Form.Item>
                    </Col>
                }
            </Row> */}

            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text level={4} className="vcc-text">
                    We just want to confirm that you understand that this is a lease, correct? <Text strong>{leaseApplication.lessee ? leaseApplication.lessee.fullName : null}</Text>?
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <Form.Item
                        name={["confirmLessee"]}
                        rules={[{ required: true, message: 'Required' }]}
                        className='vcc-form-item'
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text level={4} className="vcc-text">
                        At the end of the lease, you will have two options. You can return the bike for the fees that are specified in your contract, or you will also have the option to purchase the bike.
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className='vcc-row'>
                <Col span={24}>
                    <Text level={4} className='vcc-text'>
                        Can you please confirm the amount of the purchase option? This is also know as the residual value. <Text strong>{currencyFormatter(leaseApplication.calculator ? (leaseApplication.calculator.customerPurchaseOptionCents / 100) : null) || "N/A"}</Text> (&ldquo;residual value&rdquo;).
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className='vcc-row'>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <Form.Item
                        name={['lesseeConfirmResidualValue']}
                        className='vcc-form-item'
                        rules={[{ required: true, message: 'Required' }]}
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                {!radioButtonState.lesseeConfirmResidualValue &&
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Form.Item
                            name={['lesseeConfirmResidualValueComment']}
                            className='vcc-form-item'
                            rules={[{ required: true, message: 'Required' }]}
                        >
                            <Input placeholder='Comments' />
                        </Form.Item>
                    </Col>
                }
            </Row>


            <Row gutter={[8, 8]} className='vcc-row'>
                <Col span={24}>
                    <Text level={4} className='vcc-text'>
                    Can you please confirm that <Text strong>{leaseApplication.dealer?.first_name && leaseApplication.dealer?.last_name ? `${leaseApplication.dealer.first_name + " " + leaseApplication.dealer.last_name}`: "N/A" }</Text> .will be handling the registration of the bike?  
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className='vcc-row'>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <Form.Item
                        name={['ConfirmDealer']}
                        className='vcc-form-item'
                        rules={[{ required: true, message: 'Required' }]}
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}
