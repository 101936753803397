import React, { useState } from "react";
import { useHistory, Link } from 'react-router-dom';
import { Input, Layout, Row, Col, Select } from "antd";
import Button from '@mui/material/Button';
import {styled} from "@mui/system";
import Box from "@mui/material/Box";
import CallLogTable from "./CallLogTable";

export default function CallLogList(props) {
  const {callLog, dealershipOptions, adminUserOptions, callTypes, allowedGet, total, pageState, setPageState, setLoading} = props;
  const {Content} = Layout;
  const {Search} = Input;
  const { Option } = Select;
  const history = useHistory();
  const [searchVal, setSearchVal] = useState(pageState.search);
  const [dealershipSelected, setDealershipSelected] = useState(null);

  const handleGlobalSearch = (e) => {
    setSearchVal(e.target.value);
    if (e.target.value === '') {
      setPageState({...pageState, search: e.target.value});
    }
  };

  const onSearch = () => {
    setPageState({...pageState, search: searchVal});
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "string",
      flex: .5,
      minWidth: 50,
    },
    {
      field: "callee",
      headerName: "Callee",
      type: "string",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "humanizedCallType",
      headerName: "Call Type",
      type: "string",
      flex: .5,
      minWidth: 75,
    },
    {
      field: "notes",
      headerName: "Notes",
      type: "string",
      flex: 1.5,
      minWidth: 66,
    },
    {
      field: "dealershipName",
      headerName: "Dealership",
      type: "string",
      flex: 1,
      minWidth: 75,
      renderCell(params){
        return params.row.dealershipId ? (
          <Link to={`/dealership/${params.row.dealershipId}/activities`}>{params.row.dealershipName}</Link>
        ) : ''
      }
    },
    {
      field: "dealershipState",
      headerName: "Dealership State",
      type: "string",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "adminUser",
      headerName: "Admin User",
      type: "string",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "callTime",
      headerName: "Call Time",
      type: "string",
      flex: 1,
      minWidth: 75,
      renderCell: (record) => {
        return record?.row?.callTime === null
          ? ""
          : record.row.callTime.replace("EST", "");
      },
    },
    {
      field: "followUpCall",
      headerName: "Follow Up Call",
      type: "string",
      flex: 1,
      minWidth: 75,
      renderCell: (record) => {
        return record?.row?.followUpCall === null
          ? ""
          : record.row.followUpCall.replace("EST", "");
      },
    },
  ];

  return (
    <Layout id="content-area-layout">
      <Content
        id="main-content"
        style={{backgroundColor: "#ffff !important"}}
      >
        <Row style={{marginBottom: "1em"}}>
          <Col span={18}>
            <h1>Call Logs</h1>
          </Col>
          <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button style={{backgroundColor: "#e93b1b", color: 'white'}}
              onClick={() => history.push({pathname: `/dealerships`})}
              type="primary"
            >
              Dealerships
            </Button>
          </Col>
        </Row>
        <Row style={{ marginBottom: "1em" }}>
          <Col span={12} style={{display: 'flex', justifyContent: 'flex-start'}}>
            <Search
              className="leaseAppListSearch"
              placeholder="Callee or Phone"
              onChange={handleGlobalSearch}
              style={{float: "right"}}
              value={searchVal}
              onSearch={onSearch}
              allowClear
            />
          </Col>
          <Col span={4}>
            <Select
              style={{ width: "100%", paddingLeft: '5px', paddingRight: '5px'}}
              size="large"
              placeholder="Select Call Type"
              allowClear
              onChange={(value) => {
                setPageState({...pageState, call_type: value});
              }}
            >
              {callTypes && callTypes.map((record, index) => (
                <Option key={index} value={record.value}>{`${record.label}`}</Option>
              ))}
            </Select>
          </Col>
          <Col span={4} type="flex" align="left">
            <Select
                  style={{ width: "100%", paddingLeft: '5px', paddingRight: '5px'}}
                  size="large"
                  placeholder="Select Admin User"
                  allowClear
                  onChange={(value) => {
                    if(value !== undefined && value !== null){
                      setPageState({...pageState, admin_user_id: value});
                    }
                    else {
                      setPageState({...pageState, admin_user_id: ''});
                    }
                  }}
                  showSearch // Searching
                  filterOption={(input, option) =>
                    option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                  }
                >
                  {adminUserOptions &&
                    adminUserOptions.filter(record => record.label?.replace(/\s+/,'')).map((record, index) => {
                      return (
                        <Option key={index} value={record.value}>
                          {`${record.label}`}
                        </Option>
                      );
                    })}
            </Select>
          </Col>
          <Col span={4} type="flex" align="left">
            <Select
                  style={{ width: "100%", paddingLeft: '5px', paddingRight: '5px'}}
                  size="large"
                  placeholder="Select Dealership"
                  allowClear
                  onChange={(value) => {
                    if(value !== undefined && value!== null){
                      setDealershipSelected(value);
                      setPageState({...pageState, dealership_id: value});
                    }
                    else {
                      setDealershipSelected(null);
                      setPageState({...pageState, dealership_id: ''});
                    }
                  }}
                  showSearch // Searching
                  filterOption={(input, option) =>
                    option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                  }
            >
                  {dealershipOptions &&
                    dealershipOptions.filter(record => record.label?.replace(/\s+/,'')).map((record, index) => {
                      return (
                        <Option key={index} value={record.value}>
                          {`${record.label}`}
                        </Option>
                      );
                    })}
            </Select>
          </Col>
        </Row>
        <StyledBox>
          {callLog && (
            <CallLogTable
              callLog={callLog}
              columns={columns}
              total={total}
              pageState={pageState}
              setPageState={setPageState}
            />
          )}
        </StyledBox>
      </Content>
    </Layout>
  );
}

const StyledBox = styled(Box)({
  height: 820,
  display: "flex",
  flexGrow: 1,
  width: "100%",
  "& .super-app-theme--header": {
    backgroundColor: "rgba(255, 7, 0, 0.55)",
  },
});
