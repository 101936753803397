import React from 'react';
import ApprovalEvents from '../components/ApprovalEvents';

export default function SalesRecommendation({ dealership, dealershipId, getDealership, ...props }) {
  const salesApproval = dealership.salesApproval.approved;
  const { allowedSalesRecGet, allowedSalesRecUpdate } = props;

  return dealership ? (
    <ApprovalEvents 
      allowedApprovalGet={allowedSalesRecGet}
      allowedApprovalUpdate={allowedSalesRecUpdate}
      approvalType='sales' 
      isApproved={salesApproval} 
      type="Sales" 
      dealershipId={dealershipId} 
      getDealership={getDealership} 
      dataSource={dealership.salesApproval.events}
      status ={dealership.status}
    />
  ) : null;
}