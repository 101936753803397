import React from 'react';
import {DataGridPro} from "@mui/x-data-grid-pro";
import {styled} from "@mui/system";
import {Grid, Button} from "@material-ui/core";
import ActiveToggleButton from "../../../components/Buttons/ActiveToggleButton";
import {useHistory} from "react-router-dom";
import moment from "moment";

const ContactListPage = (props) => {
  const {dealershipId, contacts} = props
  const history = useHistory();

  const handleDealerUser = (row) => {}

  const handleIsOwner = (row) => {}

  const columns = [
    {
      field: "id",
      headerName: "ID",
      type: "string",
      flex: .5,
      minWidth: 75,
    },
    {
      field: "firstName",
      headerName: "First Name",
      type: "string",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      type: "string",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "position",
      headerName: "Position",
      type: "string",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "isOwner",
      headerName: "Owner?",
      type: "string",
      flex: 1,
      minWidth: 75,
      renderCell: (record) => {
        return <ActiveToggleButton isActive={record?.row?.isOwner} rowData={record?.row}
                                   handleChange={handleIsOwner}/>
      },
    },
    {
      field: "directPhoneNumber",
      headerName: "Direct phone number",
      type: "string",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "directEmail",
      headerName: "Direct Email",
      type: "string",
      flex: 1,
      minWidth: 75,
    },
    {
      field: "isDealerUser",
      headerName: "Is Dealer User",
      type: "string",
      flex: 1,
      minWidth: 75,
      renderCell: (record) => {
        return <ActiveToggleButton isActive={record?.row?.isDealerUser} rowData={record?.row}
                                   handleChange={handleDealerUser}/>
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "action",
      flex: 1,
      minWidth: 75,
      renderCell: (record) => {
        return record ? (
          <>
            <Button
              style={{textTransform: "none"}}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/contacts/${record.row.id}`,
                  state: {
                    actionType: "DETAILS",
                    dealershipId: dealershipId,
                  },
                });
              }}
            >
              View
            </Button>

            <Button
              style={{textTransform: "none", marginLeft: '5px'}}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/contacts/${record.row.id}`,
                  state: {
                    actionType: "UPDATE",
                    dealershipId: dealershipId,
                  },
                });
              }}
            >
              Edit
            </Button>

            {/*<Button*/}
            {/*  type="link"*/}
            {/*  name="deleteButton"*/}
            {/*  onClick={() => {*/}
            {/*    setOpen(true)*/}
            {/*    setAction({type: 'Delete', status: true})*/}
            {/*    setRecordId(record?.row?.id)*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <DeleteIcon style={{color: 'red'}}/>*/}
            {/*</Button>*/}
          </>
        ) : (
          ""
        );
      },
    },
  ]

  return <>
    <Grid style={{display: 'flex', justifyContent: 'flex-end'}}>
      <Button style={{backgroundColor: "#e93b1b", color: 'white'}} onClick={() => history.push({
        pathname: `/contacts/create`,
        state: {dealershipId: dealershipId},
      })}>
        Add new contacts
      </Button>
    </Grid>
    <Grid style={{
      height: 300,
      width: "100%",
      // paddingLeft: '20px',
      marginTop: '10px'
    }}>
      <StyledDataGridPro rows={contacts} columns={columns}/>
    </Grid>
  </>
}

export default ContactListPage;


const StyledDataGridPro = styled(DataGridPro)({
  boxShadow: "none !important",
  border: "none !important",
  "& .MuiDataGrid-cell": {
    padding: "10px",
  },
  "& .MuiDataGrid-row": {
    fontSize: 12,
  },
  "& .wrapHeader .MuiDataGrid-colCellTitle": {
    overflow: "hidden",
    lineHeight: "20px",
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: "normal !important",
    lineHeight: "normal",
    overflowWrap: "break-word",
    letterSpacing: "-0.5px",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#e8ecf0",
    color: "#000000",
  },
  "& .MuiChip-label": {
    paddingLeft: "12px",
    paddingRight: "12px",
    whiteSpace: "normal !important",
    wordWrap: " break-word",
    lineHeight: " 1",
    paddingBottom: "2px",
    paddingTop: "2px",
    letterSpacing: "-0.5px",
  },
  "& .MuiBadge-badge": {
    display: "none !important",
  },
  "& .super-app-theme--Filled": {
    backgroundColor: "#ed6248",
    "&:hover": {backgroundColor: "#e93b1b"},
  },
});
