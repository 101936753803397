import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { POST, GET, PUT } from '../../../util/network'
import logger from '../../../util/logger';
import { Card, Form, Input, Select, Radio, Row, Col, message, Button } from 'antd';
import LeaseStipulationList from './LeaseStipulationList';
import { useDispatch } from "react-redux";
import { saveStipulation, clearUnseenAttachmentsCount } from '../../../reducers/LeaseApplications'

const { Option } = Select;

export default function LeaseStipulation({ data, handleNoAuth, setLoading }) {
  const history = useHistory();
  const [form] = Form.useForm();
  const [stipulations, setStipulations] = useState([]);
  const [selectedStipulation, setSelectedStipulation] = useState();

  const dispatch = useDispatch();

  const authData = JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if (authData !== null) {
    loggedInUserId = authData.id;
  }
  const locked_by = data.lockedStatus && data.lockedStatus.lockedById;
  const disableFlag = locked_by != loggedInUserId ? true : false

  const getStipulations = async () => {
    setLoading(true)
    try {
      const response = await GET(`/api/v1/stipulations?application-identifier=${data.applicationIdentifier}`);
      setStipulations(response.data.stipulations)
    } catch (e) {
      logger.error("Stipulation Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while fetching stipulations');
      }
    }
    setLoading(false)
  };

  useEffect(() => {
    getStipulations();
    visitAttachmentsByCurrentUser(data)
  }, []);

  const handleChange = (value) => {
    const stipId = stipulations.filter(x => x.description == value).map(x => x.id).toString();

    if (stipId) {
      setSelectedStipulation(stipId);
    }
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const onFinish = async (values) => {
    values.stipulation_id = selectedStipulation;
    let newData = {
      stipulation: { ...values }
    }
    await saveApplicationDetails(newData, form);
  }

  const saveApplicationDetails = async (newData, form) => {
    setLoading(true);
    try {
      const leaseAppId = data?.id
      let response = null;
      delete newData.stipulation.proof;

      if(data.applicationIdentifier === null) {
        response =  await POST(`/api/v1/lease-application-stipulations?id=${leaseAppId}`, newData);
      } else {
        response = await POST(`/api/v1/lease-application-stipulations?application-identifier=${data.applicationIdentifier}`, newData);
      }

      if (response.data.message.id !== undefined) {
        const stipulattion = stipulations.filter(x => x.id == newData.stipulation.stipulation_id).map(x => x.description).toString();
        const attachment = data.stipulationLeaseApplicationAttachments.filter(x => x[1] == newData.stipulation.lease_application_attachment_id).map(x => x[0]).toString();
        let stipulationData = {
          applicationIdentifier: data.applicationIdentifier,
          attachment: attachment,
          id: response.data.message.id,
          leaseApplicationAttachmentId: newData.stipulation.lease_application_attachment_id,
          notes: newData.stipulation.notes,
          status: newData.stipulation.status,
          stipulation: stipulattion
        }
        dispatch(saveStipulation(stipulationData));
      }
      message.success("Data saved successfully");
      form && form.resetFields();
    } catch (e) {
      logger.error("Application Details Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Stipulation ' + e.response.data.message[0]);
      }
    }
    // getStipulations();
    // setSelectedStipulation(null);
    setLoading(false);
  };

  const visitAttachmentsByCurrentUser = async (application) => {
    if (application.unseenAttachmentsCount > 0) {
    try {
        await PUT(`/api/v1/lease_application_attachments/${application.id}/visit-attachments`, {})
        dispatch(clearUnseenAttachmentsCount({}))
      } catch (e) {
        logger.error("Visiting Attachments Error", e);
      }
    }
  }

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  return (
    <Form
      {...layout}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{
        status: "Required",
      }}

    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title='Add Stipulation'>
            <Row>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item
                  name="proof"
                  label="Stipulation"
                  hasFeedback
                  rules={[{ required: true, message: 'Please select your stipulation!' }]}
                >
                  <Select
                    showSearch
                    placeholder="Please select a stipulation"
                    onChange={handleChange}
                    disabled={disableFlag}
                  >
                    {stipulations && stipulations.map((stipulation, index) => {
                      return (
                        <Option key={index} value={stipulation.description} >{stipulation.description}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={{ span: 7, offset: 1 }} xl={{ span: 7, offset: 1 }} >
                <Form.Item
                  name="status"
                  label="Status"
                  hasFeedback
                  rules={[{ required: true, message: 'Please select your status!' }]}
                >
                  <Radio.Group>
                    <Radio value="Required" disabled={disableFlag}>Required</Radio>
                    <Radio value="Not Required" disabled={disableFlag}>Not Required</Radio>
                    <Radio value="Cleared" disabled={disableFlag}>Cleared</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item
                  name="lease_application_attachment_id"
                  label="Attachment"
                >
                  <Select placeholder="Please select an attachment" disabled={disableFlag}>
                    {data && data.stipulationLeaseApplicationAttachments.map((val, index) => {
                      return (
                        <Option key={index} value={val[1]} >{val[0]}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item name="notes" label="Notes" >
                  <Input.TextArea rows={5} disabled={disableFlag} />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" disabled={disableFlag}>
                    Add Stipulation
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} className='mt-20'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card title='Associated Stipulations'>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <LeaseStipulationList data={data} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      {/* {
        data && data.applicationIdentifier
          ?
          <Row gutter={[16, 16]} className='mt-20'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p style={{ fontSize: `1.05rem` }}>
                    Documents satisfying these stipulations can be emailed to <span style={{ fontWeight: `600` }}>{data.applicationIdentifier}{process.env.REACT_APP_ATTACHMENT_EMAIL_DOMAIN}</span>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          :
          'no'
      } */}
    </Form>
  );
}