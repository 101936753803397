import React, { useState, useEffect } from 'react'
import { GET } from "../../util/network";
import MainHeader from "../../components/layouts/MainHeader";
import MainFooter from "../../components/layouts/MainFooter";
import { message, Spin } from "antd";
import ModelGroupView from "./ModelGroupView";

export default function ModelGroupRenderer(props) {
    const { id } = props.match.params;
    const { handleNoAuth } = props;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const getModelGroup = async () => {
        setLoading(true)
        try {
          const response = await GET(`/api/v1/model-groups/details/${id}`);
          setData(response?.data);
        } catch (e) {
          message.error("Error while loading make.")
        }
        setLoading(false)
      }
    
    useEffect(() => {
      getModelGroup();
    }, []);

  return (
    <Spin spinning={loading} size="large" tip="Loading..." >
        <MainHeader activePage="newAssets" />
            {
                data && 
                <ModelGroupView
                data = {data}
                />
            }
        <MainFooter />
    </Spin>
  )
}
