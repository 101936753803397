// @ts-nocheck
import {Col, Form, InputNumber, Row} from "antd";
import React from "react";

export const BikePriceInfo = (props) => {
    const { calculatorData, convertUsd } = props;

    return (
        <>
            <Row className="first-field-height customer-town-field">
                <Col span={18}>
                    Bike Price
                </Col>
                <Col span={1} style={{ paddingTop: '5px' }}>$</Col>
                <Col span={5}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'dealerSalesPrice']}
                    >
                        <InputNumber
                            min={0}
                            controls={false}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                            precision={2}
                            style={{ textAlign: 'right' }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={18}>
                    Dealer Freight and Prep
                </Col>
                <Col span={1} style={{ paddingTop: '5px' }}>$</Col>
                <Col span={5}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'dealerFreightAndSetup']}
                    >
                        <InputNumber
                            min={0}
                            controls={false}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                            precision={2}
                            style={{ textAlign: 'right' }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={18}>
                    Dealer Documentation Fee
                </Col>
                <Col span={1} style={{ paddingTop: '5px' }}>$</Col>
                <Col span={5}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'dealerDocumentationFee']}
                    >
                        <InputNumber
                            min={0}
                            controls={false}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                            precision={2}
                            style={{ textAlign: 'right' }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={18}>
                    Title License and Registration
                </Col>
                <Col span={1} style={{ paddingTop: '5px' }}>$</Col>
                <Col span={5}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'titleLicenseAndLienFee']}
                    >
                        <InputNumber
                            min={0}
                            controls={false}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                            precision={2}
                            style={{ textAlign: 'right' }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={18}>
                    Up-Front Sales or Use Tax
                </Col>
                <Col span={6} style={{textAlign: 'right', height: '1vh'}}>
                    <span>{calculatorData.upfrontTax && convertUsd(Number(calculatorData.upfrontTax))}</span>
                </Col>
            </Row>
        </>
    )
}

export default BikePriceInfo
