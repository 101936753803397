import React, { useState } from "react";
import { Layout } from "antd";
import '../../App.css';
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import Box from '@mui/material/Box';
import ModelYearsDataGrid from './components/ModelYearsDataGrid'
import { styled } from '@mui/system';
import Typography from "@material-ui/core/Typography";
import IconButton from '@mui/material/IconButton';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { useHistory } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import CalculateIcon from '@mui/icons-material/Calculate';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AssignmentReturnedSharpIcon from '@mui/icons-material/AssignmentReturnedSharp';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DialogModal from '../LeaseApplications/DialogModal'
import ModelYearFilters from './ModelYearFilters'
import ActiveModelYear from './components/ActiveModelYear'
import {  getGridStringOperators, getGridNumericOperators, getGridDateOperators } from '@mui/x-data-grid-pro';

export default function ModelYearsList(props) {
  const { Content } = Layout;
  const auth_token = window.localStorage.getItem('auth_token');
  const authData = JSON.parse(window.localStorage.getItem('user_data')) || null;
  const [filter, setFilter] = useState(false);
  const history = useHistory();

  const { data, loading, pageState, setPageState, total, type, historyId, recalculateResiduals, recalculateModal,
          handleRecalculateModalOpen, handleRecalculateModalClose, exportModelYearsList, exportModal,
          handleExportModalOpen, handleExportModalClose, modelGroups, activeModelYear, activeModelYearId, makes } = props;

  const losPath = (path) => {
    return `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=${path}`
  }

  const actionRender = (params) => {
    if(type === 'list'){
      return <Tooltip title={<h5>Details</h5>}>
        <IconButton onClick={() => {history.push("/new-assets/model-years/details/" + params.row.id);}}>
          <WorkHistoryIcon style={{ color: "#e93b1b", fontSize: "30px" }}/>
        </IconButton>
      </Tooltip>
    }
    else if(type === 'history'){
      return <Tooltip title={<h5>Details</h5>}>
        <IconButton onClick={() => {
          let a= document.createElement('a');
          a.target= '_blank';
          a.href = losPath("/admins/model_years/" + params.row.id);
          a.click();
        }}>
          <WorkHistoryIcon style={{ color: "#e93b1b", fontSize: "30px" }}/>
        </IconButton>
      </Tooltip>
    }
  }

  const nameFilterOperators = getGridStringOperators().filter(({ value }) =>
    ['equals', 'contains', 'startsWith', 'endsWith' /* add more over time */ ].includes(value),
  );

  const numberFilterOperators = getGridNumericOperators().filter(
    (operator) => operator.value === '>' || operator.value === '<' || operator.value === '=' || operator.value === '>=' || operator.value === '<=',
  )

  const dateFilterOperators =  getGridDateOperators().filter(
    (operator) => operator.value === 'is' || operator.value === 'onOrAfter' || operator.value === 'onOrBefore'
      || operator.value === 'before' || operator.value === 'after',
  )

  const columns = [
    {
      field: 'id', headerName: 'Id', type: 'string', flex: 1, minWidth: 50, filterable: false
    },
    {
      field: 'name', headerName: 'Name', type: 'string', flex: 1.5, minWidth: 75, filterOperators: nameFilterOperators
    },
    {
      field: 'nadaModelNumber', headerName: 'Model Number', type: 'string', flex: 1, minWidth: 75, filterOperators: nameFilterOperators
    },
    {
      field: 'year', headerName: 'Year', type: 'string', flex: 1, minWidth: 50, filterOperators: numberFilterOperators
    },
    {
      field: 'startDate', headerName: 'Start Date', type: 'string', flex: 1, minWidth: 72, filterOperators: dateFilterOperators, sortable: false,
    },
    {
      field: 'endDate', headerName: 'End Date', type: 'string', flex: 1, minWidth: 72, filterOperators: dateFilterOperators, sortable: false,
    },
    {
      field: 'modelGroup', headerName: 'Model Group', type: 'string', flex: 1, minWidth: 60, filterable: false,
      renderCell: (params) => {
        return (
          <a
          onClick={() => {
            history.push(
              "/new-assets/model-groups/details/" +
               params.row.modelGroupId
            );
          }}
        >
          {params.row.modelGroup}
        </a>
        )
      }
    },
    {
      field: 'make', headerName: 'Make', type: 'string', flex: 1.5, minWidth: 75, filterable: false, sortable: false,
      renderCell: (params) => {
        return (
          <a
            onClick={() => {
              history.push(
                "/new-assets/makes/details/" +
                 params.row.makeId
              );
            }}
          >
            {params.row.make}
          </a>
        )
      }
    },
    {
      field: 'jdModelGroup', headerName: 'Jd Model Group', type: 'string', flex: 1.5, minWidth: 75, filterable: false,
    },
    {
      field: 'slcModelGroupMappingFlag', headerName: 'SLC Model Group Mapping Flag', type: 'string', flex: 1.5, minWidth: 60, filterable: false, sortable: false,
    },
    {
      field: 'originalMsrp', headerName: 'Original Msrp', type: 'string', flex: 1, minWidth: 60, filterOperators: numberFilterOperators
    },
    {
      field: 'nadaAvgRetail', headerName: 'Avg Retail', type: 'number', flex: 1, minWidth: 60, filterOperators: numberFilterOperators
    },
    {
      field: 'nadaRough', headerName: 'Rough Value', type: 'number', flex: 1, minWidth: 60, filterOperators: numberFilterOperators
    },
    {
      field: 'residual24', headerName: 'Residual 24', type: 'number', flex: 1, minWidth: 60, filterOperators: numberFilterOperators
    },
    {
      field: 'residual36', headerName: 'Residual 36', type: 'number', flex: 1, minWidth: 60, filterOperators: numberFilterOperators
    },
    {
      field: 'residual48', headerName: 'Residual 48', type: 'number', flex: 1, minWidth: 60, filterOperators: numberFilterOperators
    },
    {
      field: 'residual60', headerName: 'Residual 60', type: 'number', flex: 1, minWidth: 60, filterOperators: numberFilterOperators
    }
  ]
  const actionField = {
    field: 'Actions',
    headerName: 'Actions',
    type: 'action',
    flex: 1,
    filterable: false,
    renderCell: (params) => {
      return actionRender(params)
    }
  }

  return (
    <Layout>
      <MainHeader activePage="newAssets" />
      <Layout>
        <Layout id='content-area-layout'>
          <Content id='main-content' style={{ backgroundColor: "#ffff !important" }}>
            { type === 'history' && <ActiveModelYear activeModelYear={activeModelYear} activeModelYearId={activeModelYearId}/> }
            <Box sx={{ flexGrow: 1, pb: 2 }}>
              <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                <Grid item md={4} sm={4}>
                  <Typography variant={"h4"}>{ type === 'list' ? 'Model Years' : 'History' }</Typography>
                </Grid>
                <Grid item md={2} sm> </Grid>
                <Grid item md={6} sm={6} container spacing={1} direction="row">
                  <Grid item md={4} sm={4}> </Grid>
                  <Grid item md={4} sm={4}> </Grid>
                  <Grid item md={4} sm >
                  {
                    type === 'list' &&
                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                      <Tooltip title={<h5>New Model Year</h5>}>
                        <IconButton  style={{ color: "#e93b1b", fontSize: "30px" }} onClick={() => {
                          history.push("/new-assets/model-years/create");
                        }}>
                          <CreateNewFolderIcon />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title={<h5>Import Model Years</h5>}>
                        <IconButton  style={{ color: "#e93b1b", fontSize: "30px" }} onClick={() => {
                          location.href = losPath("/admins/model_years/import_excel")
                        }}>
                          <CloudUploadIcon />
                        </IconButton>
                      </Tooltip> */}
                      <Tooltip title={<h5>Recalculate Residuals</h5>}>
                        <IconButton  style={{ color: "#e93b1b", fontSize: "30px" }} onClick={() => {handleRecalculateModalOpen()}}>
                          <CalculateIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={<h5>Export to CSV</h5>}>
                        <IconButton  style={{ color: "#e93b1b", fontSize: "30px" }} onClick={() => {handleExportModalOpen()}}>
                          <AssignmentReturnedSharpIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  }
                  </Grid>
              </Grid>
            </Grid>
            </Box>
            { type === 'list' && <ModelYearFilters pageState={pageState} modelGroupOptions={modelGroups} filter={filter} setFilter={setFilter} makes={makes}/>}
            <StyledBox>
              {
                data &&
                <ModelYearsDataGrid
                  columns={type === 'list' ? [...columns, actionField] : columns}
                  data={data}
                  loading={loading}
                  setPageState={setPageState}
                  pageState={pageState}
                  total={total}
                  historyId={historyId}
                />
              }
            </StyledBox>
            <div>
              <DialogModal
                modalState={recalculateModal}
                modalClose={handleRecalculateModalClose}
                submitFunction={recalculateResiduals}
                contentData={"Are you sure? This action can cause monthly payments to be recalculated on unsubmitted leases."}
                title={"Recalculate Residuals?"}
              />
              <DialogModal
                modalState={exportModal}
                modalClose={handleExportModalClose}
                submitFunction={exportModelYearsList}
                contentData={"Data will be export in CSV format. Are you sure to continue data export?"}
                title={"Export Model Years?"}
              />
            </div>
          </Content>
        </Layout>
      </Layout>
      <MainFooter />
    </Layout >
  );
}

const StyledBox = styled(Box)({
  height: 820,
  display: "flex",
  flexGrow: 1,
  width: '100%',
  '& .super-app-theme--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)'
  },
})