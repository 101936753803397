import React from "react";
import CreditSummary from './CreditSummary';
import CreditReports from './CreditReports';
import BlackboxTable from './BlackboxTable';
import HistoricalRelatedCard from './HistoricalRelatedCard';
import TloReports from "./TloReports";

export default function Credit(props) {
  const {data, handleNoAuth, setLoading, save, relatedData, relatedPageState, setRelatedPageState, getRelatedData, setRelatedData} = props;
  return (
    <>
      <HistoricalRelatedCard
        data={data}
        handleNoAuth={handleNoAuth}
        setLoading={setLoading}
        save={save}
        relatedData={relatedData}
        relatedPageState={relatedPageState}
        setRelatedPageState={setRelatedPageState}
        getRelatedData={getRelatedData}
        setRelatedData={setRelatedData}
      />
      <br/>
      <CreditSummary data={data} setLoading={setLoading} save={save}/><br/>
      <CreditReports data={data} setLoading={setLoading}/><br/>
      <BlackboxTable data={data} setLoading={setLoading}/><br/>
      <TloReports data={data} setLoading={setLoading} /><br/>
    </>
  );
}