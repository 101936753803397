import React from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import { styled } from "@mui/system";

LicenseInfo.setLicenseKey(
  "06c2f0cf50adbcfcc3f1fdcdf8402d39Tz00OTMwMCxFPTE2OTIzNzY1MTM3ODUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export default function DocumentRequestsTable(props) {
  const { columns, data, loading, pageState, total, setPageState } = props;

  return (
    <StyledDataGridPro
      disableColumnReorder={true}
      disableSelectionOnClick={true}
      getRowHeight={() => "auto"}
      columns={columns}
      rows={data}
      loading={loading}
      rowsPerPageOptions={[25, 50]}
      density="comfortable"
      pagination
      rowCount={total ?? 0}
      page={pageState.page <= 0 ? 0 : pageState.page}
      pageSize={pageState.limit}
      paginationMode="server"
      onPageChange={(newPage) => {
        setPageState({ ...pageState, page: newPage });
      }}
      onPageSizeChange={(newPageSize) => {
        setPageState({ ...pageState, limit: newPageSize });
      }}
      sortingOrder={["desc", "asc"]}
      sx={{
        ".highlight": {
          bgcolor: "#e6f3ff !important",
          "&:hover": {
            bgcolor: "darkgrey",
          },
        },
      }}
    />
  );
}

const StyledDataGridPro = styled(DataGridPro)({
  boxShadow: "none !important",
  border: "none !important",
  "& .MuiDataGrid-cell": {
    padding: "10px",
  },
  "& .MuiDataGrid-row": {
    fontSize: 12,
  },
  "& .wrapHeader .MuiDataGrid-colCellTitle": {
    overflow: "hidden",
    lineHeight: "20px",
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: "normal !important",
    lineHeight: "normal",
    overflowWrap: "break-word",
    letterSpacing: "-0.5px",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#e8ecf0",
    color: "#000000",
  },
  "& .MuiChip-label": {
    paddingLeft: "12px",
    paddingRight: "12px",
    whiteSpace: "normal !important",
    wordWrap: " break-word",
    lineHeight: " 1",
    paddingBottom: "2px",
    paddingTop: "2px",
    letterSpacing: "-0.5px",
  },
  "& .MuiBadge-badge": {
    display: "none !important",
  },
  "& .super-app-theme--Filled": {
    backgroundColor: "#ed6248",
    "&:hover": { backgroundColor: "#e93b1b" },
  },
});