import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, message } from 'antd';
import CustomInputMask from '../components/CustomInputMask';
import CustomInputText from '../components/CustomInputText';
import CustomRadio from '../components/CustomRadio';
import CustomSelect from "../components/CustomSelect";
import { GET } from "../../../util/network";

export default function Underwriting({ dealership, handleSubmit, ...props }) {
  const { allowedUpdate } = props;

  const [form] = Form.useForm();

  const [reqPrevContact, setReqPrevContact] = useState(dealership.previouslyApprovedDealership);
  const [states, setStates] = useState([]);

  const previouslyApprovedDealership = [
    { optionValue: true, optionLabel: "Yes"},
    { optionValue: false, optionLabel: "No"},
  ]

  const secretaryOfStateValidOptions = [
    { optionValue: true, optionLabel: "Yes"},
    { optionValue: false, optionLabel: "No"},
  ]

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const handlePreviouslyApprovedDealership = (e) => {
    setReqPrevContact(e.target.value)
  }

  const formatOptions = ({ options }) => {
    let newOptions = [];
      options.map((value) => {
        newOptions.push({
          optionValue: value[0],
          optionLabel: value[0]
        });
      });

    return newOptions;
  };

  const getAllStates = async () => {
    try {
      let response = await GET('/api/v1/states');
      setStates(formatOptions({ options: response?.data?.states || [] }));

    } catch (e) {
       message.error("Request Error");
    }
  }

  useEffect(() => {
    getAllStates();
  }, [])
  
  return dealership ? (
    <Form
      { ...layout }
      form={ form }
      scrollToFirstError={true}
      onFinish={handleSubmit}
      initialValues={{
        employerIdentificationNumber: dealership.employerIdentificationNumber,
        previouslyApprovedDealership: dealership.previouslyApprovedDealership,
        previousDefaultRate: dealership.previousDefaultRate,
        previousTransactionsClosed: dealership.previousTransactionsClosed,
        previousTransactionsSubmitted: dealership.previousTransactionsSubmitted,
        secretaryOfStateWebsite: dealership.secretaryOfStateWebsite,
        secretaryStateValid: dealership.secretaryStateValid,
        yearIncorporatedOrControlYear: (dealership && ( dealership.yearIncorporatedOrControlYear && dealership.yearIncorporatedOrControlYear.toString() )),
        yearsInBusiness: dealership.yearsInBusiness,
        state: dealership.state
      }}
    >
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card className='h-100' title='Legal'>
            <CustomInputMask 
              name="employerIdentificationNumber" 
              label="Employer Identification Number (EIN)" 
              mask="11-1111111" 
              required={true} 
              ruleMessage="Employer Identification Number is required!"
            />
            <CustomRadio 
              name="secretaryStateValid" 
              label="Secretary of State valid?" 
              required={false} 
              //ruleMessage="Secretary of State status is required!"
              options={secretaryOfStateValidOptions}  
            />
            {
              dealership.secretaryOfStateWebsite
              ?
                <a href={`${dealership.secretaryOfStateWebsite}`} target='_blank' rel='noreferrer'>
                  Visit Secretary of State website
                </a>
              :
              ''
            }
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card className='h-100' title="Credit" >
            <CustomInputMask 
              name="yearIncorporatedOrControlYear" 
              label="Date Incorporated / Control Year" 
              mask="1111" 
              required={false} 
              //ruleMessage="Date Incorporated / Control Year is required!"
            />
            <CustomInputText 
              name="yearsInBusiness" 
              label="Years in Business" 
              required={false} 
              //ruleMessage="Years in Business is required!"
            />
            <CustomSelect
              name="state"
              label="State"
              required={false}
              options={states}
            />
            <CustomRadio 
              name="previouslyApprovedDealership" 
              label="Previously-Approved Dealership?" 
              required={false} 
              //ruleMessage="Previously-Approved Dealership is required!"
              onChange={handlePreviouslyApprovedDealership}
              options={previouslyApprovedDealership}
            />
              <Card title="Previous Contract" >
                <CustomInputText 
                  name="previousTransactionsSubmitted" 
                  label="Number of Transactions Submitted" 
                  required={reqPrevContact} 
                  ruleMessage="Number of Transactions Submitted is required!"
                />
                <CustomInputText 
                  name="previousTransactionsClosed" 
                  label="Number of Transactions Closed" 
                  required={reqPrevContact} 
                  ruleMessage="Number of Transactions Closed is required!"
                />
                <CustomInputText 
                  name="previousDefaultRate" 
                  label="Default Rate" 
                  required={reqPrevContact} 
                  ruleMessage="Default Rate is required!"
                />
            </Card>
          </Card>
        </Col>
      </Row>
      <br />
      {allowedUpdate && (
        <Row>
          <Col span={24}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  ) : null;
}
