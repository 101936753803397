import React from 'react';
import { Button, Checkbox, Col, Form, Input, Select, Row } from 'antd';

const Option = Select.Option;
const { TextArea } = Input;

export default function BannerFrom(props) {
    // const [form] = Form.useForm();
    const { onChangeCheckbox, bannerDealerShips, allowedUpdate, handleSubmit, bannerData, form, editable } = props
    return (
        <>
            <Form
                id='banner-message'
                form={form}
                colon={false}
                initialValues={bannerData}
                onFinish={handleSubmit}
            >
                <Row>
                    <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                        <Row className='flex-jc-fs'>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Row className='flex-jc-fs'>
                                    <Form.Item
                                        label='Headline'
                                        name='headline'
                                        style={{ width: '90%' }}
                                        hasFeedback
                                        rules={[{
                                            required: true,
                                            message: 'Headline is required'
                                        }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label='id'
                                        name='id'
                                        style={{ width: '90%' }}
                                        hasFeedback
                                        hidden={true}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Row>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Row className='flex-jc-fs'>
                                    <Form.Item
                                        label='Active'
                                        name='active'
                                        valuePropName='checked'
                                    >
                                        <Checkbox
                                            checked={bannerData.active}
                                            defaultChecked={bannerData.active || false}
                                            onChange={onChangeCheckbox}
                                        >
                                        </Checkbox>
                                    </Form.Item>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='flex-jc-fs'>
                            <Col span={24}>
                                <Form.Item
                                    label="Dealers"
                                    name="dealers"
                                    rules={[{
                                        required: true,
                                        message: 'Dealers is required'
                                    }]}
                                >
                                    <Select
                                        allowClear
                                        mode="multiple"
                                        placeholder="Select  dealership"
                                        filterOption={(inputValue, option) =>
                                            option.props.children
                                                .toString()
                                                .toLowerCase()
                                                .includes(inputValue.toLowerCase())
                                        }
                                        showSearch
                                    >
                                        <Option key={0} value={0}>{"All"}</Option>
                                        {bannerDealerShips && bannerDealerShips.map(option => (
                                            <Option key={option.id} value={option.id}>{option.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className='flex-jc-fs'>
                            <Col span={24}>
                                <Form.Item
                                    label='Message'
                                    name='messages'
                                    hasFeedback
                                    rules={[{
                                        required: true,
                                        message: 'Message is required'
                                    }]}
                                >
                                    <TextArea rows={2} />
                                </Form.Item>
                            </Col>
                        </Row>


                        {allowedUpdate &&
                            <Row className='flex-jc-fs'>
                                <Col xs={24} sm={24} md={24} lg={8} xl={6}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            {editable ? "Update" : "Save"}

                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </Form>
        </>
    )
}
