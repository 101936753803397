import React, { useState, useEffect } from "react";
import { Switch, message } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { PUT } from '../../../util/network';
import logger from '../../../util/logger';

export default function IsActiveSwitch({isActive, rowData}) {

    const [active, setActive] = useState(isActive)
    useEffect(() => {
        setActive(isActive)
    }, []);

    const update = async (val) => {
        // setLoading(true)
        try {
          await PUT(`/api/v1/admin-users/${rowData.id}`, {is_active: val});
          message.success("Saved successfully");
        } catch (e) {
          logger.error("Error while saving!", e);
          message.error("Error while saving!");
          setActive(!val)
        }
        // setLoading(false)
      };

      const handleChange = () => {
        let temp_active = !active
        setActive(temp_active)
        update(temp_active)
      }

    return (
        <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        defaultChecked={isActive}
        checked={active}
        onChange={handleChange}
      />
  );
}