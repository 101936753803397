import React from 'react'
import { Row, Col, Form, Input, Radio, Typography } from 'antd';
const { Text } = Typography

export default function PhoneNumber(props) {
    const { radioButtonState, validateEmail, MaskedInput, emailErrorMessage, emailValidateStatus } = props;

    return (
        <>

            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text strong level={4} className="vcc-text">
                        Once your lease is active, we will send you an email confirmation with our contact information for your record
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text level={4} className="vcc-text">
                        What is your email address?
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={6}>
                    <Form.Item
                        name={["lesseeEmail"]}
                        rules={[{ required: true, message: 'Required', type: 'email' }]}
                        className='vcc-form-item'
                        validateStatus={emailValidateStatus}
                        help={emailErrorMessage}
                    >
                        <Input onChange={validateEmail} size="large" placeholder="Email" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text level={4} className="vcc-text">
                        What is the best phone number to reach you at?
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={6}>
                    <Form.Item
                        name={["lesseeBestPhoneNumber"]}
                        rules={[{ required: true, message: 'Required' }]}
                        className='vcc-form-item'
                    >
                        <MaskedInput mask="(111) 111-1111" placeholder="Phone Number" size="large" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text level={4} className="vcc-text">
                        Can you receive text messages on that number?
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <Form.Item
                        name={["lesseeCanReceiveTextMessages"]}
                        rules={[{ required: true, message: 'Required' }]}
                        className='vcc-form-item'
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                {!radioButtonState.lesseeCanReceiveTextMessages &&
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Form.Item
                            name={['lesseeCanReceiveTextMessagesComment']}
                            className='vcc-form-item'
                            rules={[{ required: true, message: 'Required' }]}
                        >
                            <Input  placeholder='Comments' />
                        </Form.Item>
                    </Col>
                }
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text level={4} className="vcc-text">
                        Can you please provide your home phone number?
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={6}>
                    <Form.Item
                        name={["lesseeBestHomePhoneNumber"]}
                        // rules={[{ required: true, message: 'Required' }]}
                        className='vcc-form-item'
                    >
                        <MaskedInput mask="(111) 111-1111" placeholder="Phone Number" size="large" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text level={4} className="vcc-text">
                        Can you please provide your work phone number?
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={6}>
                    <Form.Item
                        name={["lesseeBestWorkPhoneNumber"]}
                        // rules={[{ required: true, message: 'Required' }]}
                        className='vcc-form-item'
                    >
                        <MaskedInput mask="(111) 111-1111" placeholder="Phone Number" size="large" />
                    </Form.Item>
                </Col>
            </Row>

        </>
    )
}
