

const auth_token = window.localStorage.getItem('auth_token');
const losPath = (path) => {
    return `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=${path}`
  }

  const adminSubMenu = [
    { text: "Admin Users", link_type: "linkto", link: "/administration/admin-users", admin_restriction: false },
    { text: "Common Application Settings", link_type: "href", link: losPath("/admins/common_application_settings/1"), admin_restriction: false },
    { text: "Control Panel", link_type: "linkto", link: "/administration/control-panel", admin_restriction: false },
    { text: "Emails", link_type: "linkto", link: "/administration/email-templates", admin_restriction: false },
    { text: "Funding Delay Reasons", link_type: "href", link: losPath("/admins/funding_delay_reasons"), admin_restriction: false },
    { text: "Lease Application Blackbox", link_type: "href", link: losPath("/admins/lease_application_blackbox_requests"), admin_restriction: false },
    { text: "Lease Package Templates", link_type: "href", link: losPath("/admins/lease_package_templates"), admin_restriction: false },
    { text: "Settings", link_type: "linkto", link: "/administration/settings", admin_restriction: false },
    { text: "Sidekiq", link_type: "href", link: losPath("/sidekiq"), admin_restriction: false },
    { text: "Stipulations", link_type: "href", link: losPath("/admins/stipulations"), admin_restriction: false },
    { text: "Workflow", link_type: "linkto", link: "/administration/workflow", admin_restriction: false },
    { text: "CBC Users", link_type: "linkto", link: "/administration/cbc-users", admin_restriction: false },
  ];

  export default adminSubMenu;