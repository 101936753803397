import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from '../util/network';

export const fetchEnvelopes = createAsyncThunk('ducusign/fetchEnvelopes', async (params) => {
  let response = null;
  if (params !== undefined) {
    let encodedParam = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
    response = await GET(`/api/v1//docusign-esign/all_envelopes?` + encodedParam);
  } else {
    response = await GET(`/api/v1//docusign-esign/all_envelopes`);
  }
  return response.data
})

const initialState = {
  docusign_reports: [],
  docusign_summary_reports: {},
  success: true,
}

export const userSlice = createSlice({
  name: "docusignEnvelopes",
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchEnvelopes.fulfilled, (state, action) => {
      state.isLoading = true;
      state.docusign_reports = action.payload.docusign_reports;
      state.docusign_summary_reports = action.payload.docusign_summary_reports;
      state.isLoading = false;
    }),
      builder.addCase(fetchEnvelopes.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchEnvelopes.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload;
      });
  }
});

export const { addUser, deleteUser, updateUsername } = userSlice.actions;
export default userSlice.reducer;
