import React from "react";
import CbcUsers from "./CbcUsers";
import { Result } from "antd";

const loggedUser = JSON.parse(localStorage.getItem("user_data"));
const allowedMails = process.env.REACT_APP_CBC_ALLOWED_MAILS;

export default function CbcUsersRenderer() {
  const cbcAllowedMails = allowedMails.split(",");
  const email = loggedUser?.email;
  const allowedUser = cbcAllowedMails.includes(email);

  return allowedUser ? (
    <CbcUsers allowedActions={true} allowedGet={true} allowedCreate={true} />
  ) : (
    <Result status="warning" title="You are not allowed to access this page." />
  );
}
