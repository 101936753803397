import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import { GET, POST } from '../../util/network'
import MakeList from "./MakeList";

export default function MakesRenderer(props) {
  const { handleNoAuth } = props;
  const [loading, setLoading] = useState(false);
  const [pageState, setPageState] = useState({page: 0, limit: 25, search: ''});
  const [response, setResponse] = useState({makes: [], total: 0})
  const [exportModal, setExportModal] = useState(false);
  let auth_token = window.localStorage.getItem('auth_token');

  const losPath = (path) => {
    return `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=${path}`
  }

  const getMakes = async () => {
    setLoading(true)
    try {
      const response = await GET(`/api/v1/makes/list?page=${pageState.page}&limit=${pageState.limit}&search=${pageState.search}`);
      setResponse(response?.data);
    } catch (e) {
      message.error("Error while loading models.")
    }
    setLoading(false)
  }

  const handleExportModalOpen = () => {
    setExportModal(true)
  }
  const handleExportModalClose = () => {
    setExportModal(false)
  }

  const exportMakesList = async () => {
    handleExportModalClose()
    try {
      message.success("Make data file will be downloaded in a minutes")
      let response = await POST(`/api/v1/makes/export-makes?search=${pageState.search}`)
      let url = losPath(response.data)
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history)
      } else {
        message.error('Error while Exporting lease application')
      }
    }
  }

  useEffect(() => {
    getMakes();
  }, [pageState]);

  return (
    <Spin spinning={loading}>
      <MakeList
        data={response.makes}
        loading={loading}
        pageState={pageState}
        setPageState={setPageState}
        total={response.total}
        exportMakesList={exportMakesList}
        exportModal={exportModal}
        handleExportModalOpen={handleExportModalOpen}
        handleExportModalClose={handleExportModalClose}
      />
    </Spin>
  )
}
