import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Checkbox, Input, message } from 'antd';
import { closestCenter, DndContext, DragOverlay, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, sortableKeyboardCoordinates, SortableContext } from '@dnd-kit/sortable';
import { Item, SortableItem } from '../../../components/Draggable/SortableItem';
import { PlusCircleOutlined } from '@ant-design/icons';
import { GET, PUT, POST } from '../../../util/network';
import logger from '../../../util/logger';

const NewItem = ({ item, onChange, newMailCarrier, saveNewMailCarrier, isSaving }) => {
  return (
    <div className='sortable-list-new'>
      <Input 
        className="new-sortable-item-input" 
        key={`new${item}`} 
        id={`${item}Id`} 
        onChange={onChange} 
        value={newMailCarrier} 
        suffix={
          <Button 
            id={`${item}Btn`} 
            className="search-btn new-sortable-item-btn" 
            size="large" 
            type="primary" 
            onClick={saveNewMailCarrier} 
            disabled={ (newMailCarrier.replace(/\s/g,"") === "") || isSaving } 
          >
            <PlusCircleOutlined />
          </Button>
        }
      />
    </div>
  );  
};

const MailCarrierList = (props) => {
  const { allowedGet, allowedUpdate, handleNoAuth } = props;
  const history = useHistory();

  const [mailCarriers, setMailCarriers] = useState([]); 
  const [newMailCarrier, setNewMailCarrier] = useState("");

  const [activeId, setActiveId] = useState(null);
  const [activeValue, setActiveValue] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    getMailCarriers();
  }, []);

  const toggleActive = (newItem) => {
    let copyMailCarriers = mailCarriers;

    copyMailCarriers.map((item) => {
      if (item.id === newItem.id) {
        item.active = newItem.active
        updateActive(item)
      }
    });

    setMailCarriers(copyMailCarriers);
  }
  
  const toggleCheckbox = (e, item) => {
    let newItem = JSON.parse(JSON.stringify(item));
    
    newItem.active = e.target.checked;
    toggleActive(newItem);
  }  
 
  const updateSort = async (data) => {
    if (allowedUpdate) {
      data = data.map(({ id }, index) => ({ id: id, sortIndex: index+1 }));

      try {
        await PUT(`/api/v1/mail-carriers/update-sort-order`, { mail_carriers: data });
      } catch (e) {
        logger.error('Request Error', e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error updating sort order');
        }
      }
    }
  }

  const updateActive = async (item) => {
    if (allowedUpdate) {
      let data = {
        id: item.id,
        active: item.active
      }

      try {
        await PUT(`/api/v1/mail-carriers/update-active-mail-carriers`, data);
      } catch (e) {
        logger.error('Request Error', e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error updating active mail carriers');
        }
      }
    }
  }

  const getMailCarriers = async () => {
    if (allowedGet) {
      try {
        let results = await GET(`/api/v1/mail-carriers`);
        setMailCarriers(results.data.mail_carriers);
      } catch (e) {
        logger.error('Request Error', e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error getting mail carriers');
        }
      }
    }
  }

  const saveNewMailCarrier = async (e) => {
    if (allowedUpdate) {
      e.preventDefault();
      setIsSaving(true);
      if (newMailCarrier.replace(/\s/g,"") !== ""){
        let data = {
          mail_carrier: { description: newMailCarrier } 
        }

        try {
            await POST(`/api/v1/mail-carriers`, data);
            getMailCarriers()
        } catch (e) { 
          logger.error('Request Error', e);

          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error('Error saving mail carriers');
          }
        }
        setIsSaving(false);
      } else {
        setIsSaving(false);
      }
    }
  }

  const handleDragStart = (event) => {
    let mailCarrierName;
    setActiveId(event.active.id);

    const activeMailCarrier = mailCarriers.filter(obj => {
      return obj.id === event.active.id
    })

    if (activeMailCarrier && activeMailCarrier[0]) {
      mailCarrierName = activeMailCarrier[0].description;
    }

    if (mailCarrierName) {
      setActiveValue(mailCarrierName);
    }
  }

  const handleDragEnd = (event) => {
    const {active, over} = event;
    
    if (active.id !== over.id) {
      const oldIndex = mailCarriers.map((w) => w.id).indexOf(active.id);
      const newIndex = mailCarriers.map((w) => w.id).indexOf(over.id);

      updateSort(arrayMove(mailCarriers, oldIndex, newIndex));

      setMailCarriers((mailCarriers) => {
        return arrayMove(mailCarriers, oldIndex, newIndex);
      });
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5
      } 
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );  

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext 
          items={mailCarriers.map((item) => item.id)}
          strategy={rectSortingStrategy}
        >
          {
            mailCarriers.map((item) =>
              <div className='sortable-list-container' key={item.id}> 
                <SortableItem 
                  key={item.id} 
                  id={item.id} 
                  value={item.description}
                />
                <Checkbox 
                  checked={item.active} 
                  onChange={(e) => toggleCheckbox(e, item)}  
                >
                </Checkbox>
              </div>
            )
          }
        </SortableContext>
        <DragOverlay>{activeId ? <Item id={activeId} value={activeValue}/> : null}</DragOverlay>
      </DndContext>
      <NewItem item="newMailCarrier" onChange={(e) => setNewMailCarrier(e.target.value)} newMailCarrier={newMailCarrier} saveNewMailCarrier={saveNewMailCarrier} isSaving={isSaving} />
    </>
  );
}

export default MailCarrierList;