import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Spin, message } from "antd";
import Dealership from "./Dealership";
import { GET } from '../../util/network';
import logger from '../../util/logger';
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';

export default function DealershipRenderer(props) {
  const { allowedActions, handleNoAuth } = props;
  const history = useHistory();

  const dealershipActions = GetResourceActions('Dealership', allowedActions);
  const allowedGet = IsActionAllowed('get', dealershipActions);
  const allowedUpdate = IsActionAllowed('update', dealershipActions);

  const salesRecActions = GetResourceActions('DealershipSalesRecommendation', allowedActions);
  const allowedSalesRecGet = IsActionAllowed('get', salesRecActions);
  const allowedSalesRecUpdate = IsActionAllowed('update', salesRecActions);

  const underwritingApprovalActions = GetResourceActions('DealershipUnderwritingApproval', allowedActions);
  const allowedUwApprovalGet = IsActionAllowed('get', underwritingApprovalActions);
  const allowedUwApprovalUpdate = IsActionAllowed('update', underwritingApprovalActions);

  const creditCommitteeApprovalActions = GetResourceActions('DealershipCreditCommitteeApproval', allowedActions);
  const allowedCreditComApprovalGet = IsActionAllowed('get', creditCommitteeApprovalActions);
  const allowedCreditComApprovalUpdate = IsActionAllowed('update', creditCommitteeApprovalActions);

  const dealershipId = props.match.params.id
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [dealerRepresentatives, setDealerRepresentatives] = useState();
  const [remitToDealerCalculation, setRemitToDealerCalculation] = useState();
  
  const getDealership = async () => {
    if (allowedGet) {      
      setLoading(true);
      try {
        const response = await GET(`/api/v1/dealerships/${dealershipId}`);
        setData(response.data);
      } catch (e) {
        logger.error("Dealership Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while fetching dealership');
        }
      }
      setLoading(false);
    }
  };
  const getDealerRepresentatives = async () => {
    setLoading(true);
    try {
      const response = await GET(`/api/v1/dealer_representatives`);
      setDealerRepresentatives(response.data.dealerRepresentatives);
    } catch (e) {
      logger.error("Dealer representatives Error", e);

      if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while fetching dealer representatives');
        }
    }
    setLoading(false);
  };


  const getRemitToDealerCalculation = async () => {
    setLoading(true);
    try {
      const response = await GET(`/api/v1/remit_to_dealer_calculations`);
      setRemitToDealerCalculation(response.data.remitToDealerCalculation);
    } catch (e) {
      logger.error("Remit To Dealer Calculation Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while fetching Remit to Dealership calculation');
      }
    }
    setLoading(false);
  };

  
  useEffect(() => {
    getDealerRepresentatives();
    getRemitToDealerCalculation();
    getDealership();
  }, []);

  return (
    <Spin 
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      {
        data &&          
          <Dealership 
            data={data} 
            dealerRepresentatives={dealerRepresentatives} 
            handleNoAuth={handleNoAuth}
            remitToDealerCalculation={remitToDealerCalculation} 
            setLoading={setLoading} 
            loading={loading} 
            dealershipId={dealershipId} 
            getDealership={getDealership} 
            allowedCreditComApprovalGet={allowedCreditComApprovalGet}
            allowedCreditComApprovalUpdate={allowedCreditComApprovalUpdate}
            allowedGet={allowedGet}
            allowedSalesRecGet={allowedSalesRecGet}
            allowedSalesRecUpdate={allowedSalesRecUpdate}
            allowedUpdate={allowedUpdate}
            allowedUwApprovalGet={allowedUwApprovalGet}
            allowedUwApprovalUpdate={allowedUwApprovalUpdate}
          />
      }

    </Spin>
  )

}