import React, {useState, useEffect} from "react";
import {Spin, Layout, message} from 'antd';
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import {useHistory, useParams} from 'react-router-dom';
import './Dealership.css';
import Activities from "./DealerActivityComponents/Activities";
import {DELETE, GET, POST, PUT} from "../../util/network";
import logger from "../../util/logger";
import {handleNoAuth} from "../../util/authenticate";
import ActivityHeaderCard from "./DealerActivityComponents/ActivityHeaderCard";
import MeetingSchedule from "./DealerActivityComponents/MeetingSchedule";
import ActivitiesTab from "./DealerActivityComponents/ActivitiesTab";
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import moment from "moment";
import {useSelector, useDispatch} from "react-redux"
import {fetchDealershipActivities, getDealershipActivities} from '../../reducers/DealershipActivities'
import {Grid} from "@material-ui/core";
import NoteCard from "./DealerActivityComponents/NoteCard";

const {Content, Sider} = Layout;


export default function DealerActivityRenderer(props) {
  let {id} = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState([])
  const dispatch = useDispatch()
  const currentAdminUser = useSelector((state) => state.DealershipActivities.currentAdminUser);
  const adminUsers = useSelector((state) => state.DealershipActivities.adminUsers);
  const contacts = useSelector((state) => state.DealershipActivities.contacts);
  const dealershipInfo = useSelector((state) => state.DealershipActivities.dealership);
  const activityData = useSelector((state) => state.DealershipActivities.activities);
  const activityTimelines = useSelector((state) => state.DealershipActivities.activityTimelines);
  const scheduledCalls = useSelector((state) => state.DealershipActivities.scheduledCalls);
  const LogCalls = useSelector((state) => state.DealershipActivities.LogCalls);
  const reminders = useSelector((state) => state.DealershipActivities.reminders);
  const emails = useSelector((state) => state.DealershipActivities.emails);

  const fetchColor = (index) => {
    let color;
    switch (index % 7) {
      case 0:
        color = '#5690b4'
        break;
      case 1:
        color = '#ef4829d1'
        break;
      case 2:
        color = '#7B7B7D'
        break;
      case 3:
        color = '#b08832'
        break;
      case 4:
        color = '#4c5ccc'
        break;
      case 5:
        color = '#a158ad'
        break;
      case 6:
        color = '#bb5896'
        break;
      default:
        color = '#ef4829d1'
    }

    return color;
  };

  const addColorToNotes = (coloredNotes) => {
    let color;
    let index = 1;
    coloredNotes.map((coloredNote) => {
      color = fetchColor(index);
      coloredNote.color = color;
      index = index + 1;
      return coloredNote;
    });

    return coloredNotes;
  };

  const addIsEditingKeyPair = (noteData) => {
    let coloredNotes = addColorToNotes(noteData);
    coloredNotes.map((coloredNote) => {
      coloredNote.is_editing = false;
      coloredNote.saved = true;
      return coloredNote;
    });

    return coloredNotes;
  }

  const fetchNotes = async () => {
    setLoading(true);
    try {
      const response = await GET(`/api/v1/notes?dealership_id=${id}`);
      setNotes(addIsEditingKeyPair(response.data));
    } catch (e) {
      logger.error("Notes fetch Error.", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error("Error on fetching data.");
      }
    }
    setLoading(false);
  };

  const addNote = async (description) => {
    setLoading(true);
    try {
      const response = await POST(`/api/v1/notes`, {
        description: description,
        dealership_id: id
      });
      setNotes(addIsEditingKeyPair(response.data));
      dispatch(fetchDealershipActivities({id: id}))
    } catch (e) {
      logger.error("Note addition Error.", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error on fetching data.');
      }
    }
    setLoading(false);
  };

  const editNote = async (note_id, description) => {
    setLoading(true);
    try {
      const response = await PUT(`/api/v1/notes/${note_id}`, {
        description: description,
        dealership_id: id
      });
      setNotes(addIsEditingKeyPair(response.data));
    } catch (e) {
      logger.error("Note addition Error.", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error on fetching data.');
      }
    }
    setLoading(false);
  };

  const deleteNote = async (note_id) => {
    setLoading(true);
    try {
      const response = await DELETE(`/api/v1/notes/${note_id}?dealership_id=${id}`);
      setNotes(addIsEditingKeyPair(response.data));
      message.success('Successfully deleted note.');
    } catch (e) {
      logger.error("Note addition Error.", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error on note deletion.');
      }
    }

    setLoading(false);
  };

  const sendEmail = async (recipient, subject, body) => {
    setLoading(true);
    try {
      const response = await POST(`/api/v1/dealerships/${id}/send-email`, {
        recipient_email: recipient,
        subject: subject,
        body: body
      });
      dispatch(getDealershipActivities({response: response.data}))
      message.success('Successfully sent email.');
    } catch (e) {
      logger.error("Email sending Error.", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error on email sent.');
      }
    }

    setLoading(false);
  };

  const setReminder = async (note, follow_up_time) => {
    setLoading(true);
    try {
      console.log("Printing date from parent: ", follow_up_time);
      const response = await POST(`/api/v1/dealerships/${id}/set-reminder`, {
        notes: note,
        follow_up_call: follow_up_time
      });
      dispatch(getDealershipActivities({response: response.data}))
      message.success('Successfully set reminder.');
    } catch (e) {
      logger.error("Reminder setting Error.", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error on reminder set.');
      }
    }

    setLoading(false);
  };

  const deleteReminder = async (reminderId) => {
    setLoading(true);
    try {
      const response = await DELETE(`/api/v1/dealerships/${id}/delete-reminders?reminderId=${reminderId}`);
      dispatch(getDealershipActivities({response: response.data}))
    } catch (e) {
      logger.error("Notes fetch Error.", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error on fetching data.');
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    dispatch(fetchDealershipActivities({id: id}))
    fetchNotes();
  }, []);

  return (
    <Spin
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      <Layout>
        <MainHeader activePage="dealers"/>
        <Layout name='Dealership Edit' id='#dealership-edit'>
          <Layout id='content-area-layout'>
            <Content id='main-content'>
              <MainBreadcrumb items={
                [
                  {text: "Home", link_type: "linkto", link: "/dashboard"},
                  {text: "Dealerships", link_type: "linkto", link: "/dealerships"},
                  {text: "Dealer Activity"}
                ]}
              />
              <Grid container spacing={4}>
                <Grid item md={12} sm={12}>
                  {dealershipInfo &&
                    <ActivityHeaderCard dealership={dealershipInfo} activityTimelines={activityTimelines} id={id}
                                        contacts={contacts}/>}
                </Grid>
                <Grid item md={12} sm={12}>
                  <Activities countData={activityData} notes={notes} setNotes={setNotes} addNote={addNote}
                              deleteNote={deleteNote} editNote={editNote} id={id}/>
                </Grid>
                <Grid item md={12} sm={12}>
                  <Grid container spacing={2}>
                    <Grid item md={4} sm={4}>
                      <MeetingSchedule scheduleCalls={scheduledCalls}/>
                    </Grid>
                    <Grid item md={8} sm={8}>
                      <ActivitiesTab emails={emails} sendEmail={sendEmail} reminders={reminders}
                                     setReminder={setReminder}
                                     deleteReminder={deleteReminder} currentAdminUser={currentAdminUser}
                                     adminUsers={adminUsers}
                                     dealershipInfo={dealershipInfo} loading={loading} LogCalls={LogCalls}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Content>
          </Layout>
        </Layout>
        <MainFooter/>
      </Layout>
    </Spin>
  );
}
