// @ts-nocheck
import {Col, Form, InputNumber, Row, Typography} from "antd";
import React from "react";

const { Title } = Typography;

export const TradeInfo = (props) => {
    const { calculatorData, convertUsd } = props;

    return (
        <>
            <Title level={5} className="payment-calculator-headers"> Trade Info </Title>
            <Row className="first-field-height customer-town-field">
                <Col span={18}>
                    Gross Trade Allowance
                </Col>
                <Col span={1} style={{ paddingTop: '5px' }}>$</Col>
                <Col span={5} style={{ textAlign: 'right' }}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'grossTradeInAllowance']}
                    >
                        <InputNumber
                            min={0}
                            controls={false}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                            precision={2}
                            style={{ textAlign: 'right' }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={18}>
                    Trade-in Payoff
                </Col>
                <Col span={1} style={{ paddingTop: '5px' }}>$</Col>
                <Col span={5} style={{ textAlign: 'right' }}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'tradeInPayoff']}
                    >
                        <InputNumber
                            min={0}
                            controls={false}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                            precision={2}
                            style={{ textAlign: 'right' }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className="first-field-height customer-town-field">
                <Col span={18}>
                    Net Trade-In Allowance
                </Col>
                <Col span={6} style={{textAlign: 'right'}}>
                    <span>{calculatorData.netTradeInAllowance && convertUsd(Number(calculatorData.netTradeInAllowance))}</span>
                </Col>
            </Row>
        </>
    )
}

export default TradeInfo
