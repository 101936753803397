import React, {useState} from "react";
import {Spin, Button} from "antd";
import IsActiveSwitch from './components/IsActiveSwitch'
import IsLockedSwitch from './components/IsLockedSwitch'
import './AdminUser.css';
import {Input, Col, Row} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {updatePageState} from "../../reducers/Adminusers";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {styled} from "@mui/system";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import {useHistory} from "react-router-dom";

export default function AdminUsersTable({data, setOpen, setRecordId, total, loading}) {
  const {Search} = Input;
  const history = useHistory();
  const dispatch = useDispatch();
  const pageState = useSelector((state) => state.adminUsers.pageState);
  let isLoading = useSelector((state) => state.adminUsers.isLoading);
  const [searchVal, setSearchVal] = useState(pageState.search);

  const columns = [
    {
      field: "lastName",
      headerName: "Last Name",
      type: "string",
      flex: 1.5,
      minWidth: 75,
    },
    {
      field: "firstName",
      headerName: "First Name",
      type: "string",
      flex: 1.5,
      minWidth: 75,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      flex: 2.5,
      minWidth: 75,
    },
    {
      field: "securityRoles",
      headerName: "Security Role",
      type: "string",
      flex: 1.5,
      minWidth: 75,
      renderCell: (record) => {
        const newValue = [...record?.row?.securityRoles]
        const sortedValues = newValue.sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : -1);
        return sortedValues.map(value => value.description).join().toLocaleString('en-US').replace(/,(?=[^\s])/g, ', ');
      },
    },
    {
      field: "lastSignInAtFormated",
      headerName: "Last Sign In",
      type: "string",
      flex: 1.5,
      minWidth: 75,
      renderCell: (record) => {
        return record?.row?.lastSignInAtFormated;
      },
    },
    {
      field: "isActive",
      headerName: "Active",
      flex: 1,
      minWidth: 75,
      renderCell: (record) => {
        return <IsActiveSwitch isActive={record?.row?.isActive} rowData={record?.row}/>
      },
    },
    {
      field: "isLocked",
      headerName: "Locked",
      flex: 1,
      minWidth: 75,
      renderCell: (record) => {
        return <IsLockedSwitch isLocked={record?.row?.isLocked} rowData={record?.row}/>
      },
    },
    {
      field: "isDealershipLogin",
      headerName: "Dealership Login",
      flex: 1,
      minWidth: 50,
      renderCell: (record) => {
        return record?.row?.isDealershipLogin === true ? 'Yes' : 'No'
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "action",
      flex: 2,
      minWidth: 140,
      filterable: false,
      renderCell: (record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                history.push({
                  pathname: `/administration/admin-users/${record.id}`,
                  state: {
                    actionType: "UPDATE",
                  },
                });
              }}
            >
              Edit
            </Button>

            <Button
              type="link"
              name="deleteButton"
              onClick={() => {
                setOpen(true)
                setRecordId(record?.id)
              }}
            >
              <DeleteIcon style={{color: 'red'}}/>
            </Button>
          </>
        )
      }
    }
  ];

  const handleGlobalSearch = (e) => {
    setSearchVal(e.target.value);
    if (e.target.value === ''){
      dispatch(updatePageState({search: e.target.value}))
    }
  };

  const onSearch = () => {
    dispatch(updatePageState({search: searchVal}))
  };

  return (
    <>
      <Spin
        spinning={loading === true ? true : isLoading}
        size='large'
        tip='Loading...'
      >
        <Row style={{marginBottom: "1em"}}>
          <Col flex={4}>
            <h1>Admin Users</h1>
          </Col>
          <Col style={{marginRight: '10px'}}>
            <Search
              placeholder="First Name or Last Name or Email"
              size="large"
              onChange={handleGlobalSearch}
              value={searchVal}
              onSearch={onSearch}
              style={{float: 'right', width: 350}}
            />
          </Col>
        </Row>
        <StyledBox>
          {data && (<StyledDataGridPro
            disableColumnReorder={true}
            disableSelectionOnClick={true}
            getRowHeight={() => "auto"}
            density="comfortable"
            columns={columns}
            rows={data}
            rowsPerPageOptions={[25, 50, 100]}
            pagination
            rowCount={total ?? 0}
            page={pageState.page <= 0 ? 0 : pageState.page}
            pageSize={pageState.limit}
            paginationMode="server"
            onPageChange={(newPage) => {
              dispatch(updatePageState({page: newPage}))
            }}
            onPageSizeChange={(newPageSize) => {
              dispatch(updatePageState({limit: newPageSize}))
            }}
          />)}
        </StyledBox>
      </Spin>
    </>
  );
}


const StyledBox = styled(Box)({
  height: 820,
  display: "flex",
  flexGrow: 1,
  width: "100%",
  "& .super-app-theme--header": {
    backgroundColor: "rgba(255, 7, 0, 0.55)",
  },
});

const StyledDataGridPro = styled(DataGridPro)({
  boxShadow: "none !important",
  border: "none !important",
  "& .MuiDataGrid-cell": {
    padding: "10px",
  },
  "& .MuiDataGrid-row": {
    fontSize: 12,
  },
  "& .wrapHeader .MuiDataGrid-colCellTitle": {
    overflow: "hidden",
    lineHeight: "20px",
    whiteSpace: "normal",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: "normal !important",
    lineHeight: "normal",
    overflowWrap: "break-word",
    letterSpacing: "-0.5px",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#e8ecf0",
    color: "#000000",
  },
  "& .MuiChip-label": {
    paddingLeft: "12px",
    paddingRight: "12px",
    whiteSpace: "normal !important",
    wordWrap: " break-word",
    lineHeight: " 1",
    paddingBottom: "2px",
    paddingTop: "2px",
    letterSpacing: "-0.5px",
  },
  "& .MuiBadge-badge": {
    display: "none !important",
  },
  "& .super-app-theme--Filled": {
    backgroundColor: "#ed6248",
    "&:hover": {backgroundColor: "#e93b1b"},
  },
});