import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import { GET, POST } from "../../util/network";
import ApplicationStipulationsList from "./ApplicationStipulationsList";

export default function ApplicationStipulationsRenderer(props) {
  const { handleNoAuth } = props;
  let auth_token = window.localStorage.getItem('auth_token');

  const [loading, setLoading] = useState(false);
  const [pageState, setPageState] = useState({
    page: 0,
    limit: 25,
  });
  const [response, setResponse] = useState({ data: [], total: 0 });

const losPath = (path) => {
    return `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=${path}`
}

  const getApplicationStipulations = async () => {
    setLoading(true);
    try {
      const response = await GET(
        `/api/v1/lease-application-stipulations?page=${pageState.page}&limit=${pageState.limit}}`
      );
      setResponse(response?.data);
    } catch (e) {
      message.error("Error while loading model group.");
    }
    setLoading(false);
  };

  useEffect(() => {
    getApplicationStipulations();
  }, [pageState]);

  return (
    <Spin spinning={loading}>
      <ApplicationStipulationsList
        data={response.data}
        loading={loading}
        pageState={pageState}
        setPageState={setPageState}
        total={response.total}
      />
    </Spin>
  );
}