import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Col, message, Result, Row, Select } from 'antd';
import { GET, PUT } from '../../../util/network';
import MailCarrierList from '../MailCarrier/MailCarrierList'
import WelcomeCallType from '../WelcomeCallType/WelcomeCallType';
import WelcomeCallResult from '../WelcomeCallResult/WelcomeCallResult';
import logger from '../../../util/logger';
import './LeasePage.css';

const Option = Select.Option;

export default function LeasePage({ setLoading, ...props }) {
  const { allowedGet, allowedUpdate, handleNoAuth } = props;
  const history = useHistory();

  const [initialLoad, setInitialLoad] = useState(null);

  const [applicationMenu] = useState([{id: 103, name: 'Mail Carriers'}]);
  const [applicationSelected, setApplicationSelected] = useState(false);

  const [leaseVerificationMenu] = useState([{ id: 101, name: 'Type' }, { id: 102, name: 'Result' }]);
  const [leaseVerificationSelected, setLeaseVerificationSelected] = useState(false);

  const [selectedApplication, setSelectedApplication] = useState([]);
  const [selectedLeaseVerification, setSelectedLeaseVerification] = useState([]);  

  const [schedulingVal, setSchedulingVal] = useState([]);

  const applicationSelect = (val) => {
    setApplicationSelected(true);
    setSelectedApplication(val);
  }

  const leaseVerificationSelect = (val) => {
    setLeaseVerificationSelected(true);
    setSelectedLeaseVerification(val);
  }

  const getSchedulingDay = async () => {
    if (allowedGet) {
      try {
        let data = await GET('/api/v1/common_application_settings');
        setSchedulingVal(data.data.scheduling_days[0].scheduling_day);
      } catch (e) {
        logger.error('Request Error', e);        

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error getting common application settings');
        }
      }

      setLoading(false);
    }
  }

  const schedulingValueChange = async(e) => {
    if (allowedUpdate) {
      let data = {
        'scheduling_day' : e.target.value.replace(/\D/, '')
      }

      try {      
        await await PUT(`/api/v1/common_application_settings/update_scheduling_day`, data);
        message.success('Data saved successfully');
      } catch (e) {
        logger.error('Request Error', e);        

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error updating scheduling day');
        }
      }

      setLoading(false);
    }
  }

  useEffect(() => {
    if (!initialLoad) {
      getSchedulingDay();
      
      setInitialLoad(true);
    }
  }) 

  return allowedGet ? (
    <div>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card className='h-100' title='Welcome Call Options'>
            <Row>  
              <Col span={24}>
                <h3 className='slc-in-panel-header'>Drop-Down Lists</h3>
                <Select 
                  className='settings-content-select' 
                  placeholder='Please Select' 
                  onSelect={leaseVerificationSelect}
                >
                  {leaseVerificationMenu && leaseVerificationMenu.map((ele,index) => {
                    return (
                      <Option key = {index} value={ele.id} className='settings-content-option'>{ele.name}</Option>
                    )
                  })}
                </Select>
                {leaseVerificationSelected &&       
                  <div className='checkbox-list'>
                    <span style={{ float: 'left' }}>Option</span>
                    <span style={{ float: 'right' }}>Active</span>
                  </div>
                }

                  { (selectedLeaseVerification === 101) && 
                    <WelcomeCallType 
                      allowedGet={ allowedGet }
                      allowedUpdate={ allowedUpdate }
                    />
                  }
                  { ( selectedLeaseVerification === 102) && 
                    <WelcomeCallResult 
                      allowedGet={ allowedGet }
                      allowedUpdate={ allowedUpdate }
                    />
                  }
              </Col>
            </Row>
            <Row className='mt-30'>
              <Col span={24}>
                <h3 className='slc-in-panel-header'>Scheduling</h3>
                <input 
                  type='text' 
                  pattern='[0-9]*' 
                  className='input-scheduling' 
                  maxLength='2' 
                  defaultValue={schedulingVal}
                  onChange={schedulingValueChange}                  
                />
                <span>Number of days prior to First Payment Date (or 1 day after Funding Approved On Date, whichever is earlier)</span>          
              </Col>            
            </Row>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Card className='h-100' title='Application Details'>
            <Row>
              <Col span={24}>
                <h3 className='slc-in-panel-header'>Drop-Down Lists</h3>
                <Select className="settings-content-select" placeholder='Please Select' onSelect={applicationSelect}>
                  {applicationMenu && applicationMenu.map((ele,index) => {
                    return (
                      <Option key = {index} value={ele.id} className='settings-option' >{ele.name}</Option>
                    )
                  })}
                </Select>
                {applicationSelected &&
                  <div className='checkbox-list'>
                    <span style={{ float: 'left' }}>Option</span>
                    <span style={{ float: 'right' }}>Active</span>
                  </div>
                }
                {
                  ( selectedApplication === 103 )   &&
                  <MailCarrierList
                    allowedGet={ allowedGet }
                    allowedUpdate={ allowedUpdate }
                  />
                }
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  ) : <Result 
        status='warning'
        title='You are not allowed to access this data.'
      />;
}