import React from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import { styled } from '@mui/system';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import DeleteIcon from "@mui/icons-material/Delete";
LicenseInfo.setLicenseKey('06c2f0cf50adbcfcc3f1fdcdf8402d39Tz00OTMwMCxFPTE2OTIzNzY1MTM3ODUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export default function TrainingTable(props) {
    const { data, deleteTraining, dealerShips } = props

    const getDealersName = (dealers, dealerShips) => {
        let dealerName = ""
        let dealerList = dealers.split(",")
        if (dealerList.includes('0')){
            return 'All'
        }

        for (let i = 0; i < dealerList.length; i++) {
            let dealershipObj = dealerShips.find(item => item.id == dealerList[i]) || ""
            dealerName += dealershipObj.name + ", "
        }

        return dealerName.slice(-2) === ', ' ? dealerName.slice(0, -2) : dealerName;
    }

    const handlePopUp = (url) =>{
        checkVideoFile(url) ? window.open(`${window.location.origin}/video?${url}`,'name','height=800,width=1280') :
          window.open(`${url}`,'_blank','height=800, width=1280');
    }

    function checkVideoFile(url) {
        return url.match(/.MP4|.MOV|.WMV|.AVI|.WEBM|.MKV|.FLV/i) !== null;
    }

    const columns = [
        {
            field: 'id', headerName: 'ID', type: 'string', flex: .5, minWidth: 75,
        },
        {
            field: 'title', headerName: 'Title', type: 'string', flex: 1, minWidth: 66,
        },
        {
            field: 'file', headerName: 'File', type: 'string', flex: 1,
            renderCell: (params) => {
                return <>
                    <div>
                        <Button style={{textTransform: 'none'}} onClick={() => handlePopUp(params.row?.file_url)}>View File</Button>
                    </div>
                </>
            }
        },
        {
            field: 'thumbnail', headerName: 'Thumbnail', type: 'string', flex: 1,
            renderCell: (params) => {
                return <Button style={{textTransform: 'none'}} onClick={() => handlePopUp(params.row?.thumbnail_url)}>View Thumbnail</Button>
            }
        },
        {
            field: 'dealers', headerName: 'Dealership', type: 'string', flex: 1,
            renderCell: (params) => {
                return getDealersName(params.row.dealers, dealerShips)
            }
        },
        {
            field: 'Actions',
            headerName: 'Actions',
            type: 'action',
            flex: 1,
            minWidth: 50,
            renderCell: (params) => {
                return <>
                    <ListItem >
                        <Button
                          type="link"
                          name="deleteButton"
                          onClick={() => {
                              deleteTraining(params.row?.id)
                          }}
                        >
                            <DeleteIcon style={{color: 'red'}}/>
                        </Button>
                    </ListItem>
                </>
            }
        },
        // {
        //     field: 'clickCount', headerName: 'Click Count', type: 'string', flex: 1, minWidth: 50,
        // },
        // {
        //     field: 'dealersClicked', headerName: 'Dealers Clicked', type: 'string', flex: 1, minWidth: 50,
        // },
    ]

    return (
        <>
            {data &&
                <StyledDataGridPro
                    columns={columns}
                    rows={data}
                    getRowHeight={() => 'auto'}
                    pagination
                />
            }
        </>
    )
}

const StyledDataGridPro = styled(DataGridPro)({
    boxShadow: 'none !important',
    border: "none !important",
    '& .MuiDataGrid-cell': {
        padding: '10px',
        whiteSpace: "normal !important"
    },
    '& .MuiDataGrid-row': {
        fontSize: 12
    },
    '& .wrapHeader .MuiDataGrid-colCellTitle': {
        overflow: "hidden",
        lineHeight: "20px",
        whiteSpace: "normal"
    },
    '& .MuiDataGrid-columnHeaderTitle': {
        fontSize: 14,
        fontWeight: 600,
        whiteSpace: 'normal !important',
        lineHeight: 'normal',
        overflowWrap: "break-word",
        letterSpacing: '-0.5px'
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: "#e8ecf0",
        color: "#000000",
    },
    '& .MuiChip-label': {
        paddingLeft: '12px',
        paddingRight: '12px',
        whiteSpace: 'normal !important',
        wordWrap: ' break-word',
        lineHeight: ' 1',
        paddingBottom: '2px',
        paddingTop: '2px',
        letterSpacing: '-0.5px'
    },
    '& .MuiBadge-badge': {
        display: "none !important"
    }

})
