import React, {useState} from 'react'
import { Form, InputNumber, Input, Button, message } from 'antd'

import { PUT } from '../../../../util/network'
import logger from '../../../../util/logger'
import { getDetails } from '../../../../reducers/LeaseApplications'
import {useDispatch, useSelector} from "react-redux";

export default function CalculationTableForm({ data, setLoading }) {
  const { creditAverageSummary } = data;
  const isCreditUser = useSelector((state) => state.leaseApplications.isCreditUser);
  const [form] = Form.useForm();
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
    loggedInUserId = authData.id;
  }
  const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
  const dispatch = useDispatch();

  const creditTierNumber = parseInt(data?.creditAverageSummary?.approvedCreditTier?.substring(5, 7))
  const disableFlag = !(isCreditUser && locked_by == loggedInUserId);

  const updateLeaseApplicationPaymentLimit = async (id, data) => {
    if (!id) {
      return message.error('Error update information!')
    }

    setLoading(true)
    try {
      await PUT(`/api/v1/lease-application-payment-limits/${id}`, data).then((response) => {
        dispatch(getDetails(response?.data?.leaseApplication));
        setLoading(false)
        message.success('Payment Limit updated successfully')
      }).catch((error) => {
        setLoading(false)
        message.error('Error update information')
      })
    } catch (e) {
      setLoading(false)
      logger.error('Error update information', e)
    }
  }

  const resetLeaseApplicationPaymentLimit = async (id) => {
    if (!id) {
      return message.error('Error reset information!')
    }

    setLoading(true)
    try {
      await PUT(`/api/v1/lease-application-payment-limits/${id}/reset`, data).then((response) => {
        dispatch(getDetails(response?.data?.leaseApplication));
        setLoading(false)
        message.success('Payment Limit reset successfully')
      }).catch((error) => {
        setLoading(false)
        message.error('Error reset information')
      })
    } catch (e) {
      setLoading(false)
      logger.error('Error reset information', e)
    }
  }

  const handleFormFinish = (values) => {
    updateLeaseApplicationPaymentLimit(creditAverageSummary.paymentLimitId, { leaseApplicationPaymentLimit: values })
  }

  const handleFormReset = () => {
    resetLeaseApplicationPaymentLimit(creditAverageSummary.paymentLimitId)
  }

  // const handleMaxAcc = (e) => {
  //   const maxAllowablePayment = parseInt(form.getFieldValue('overrideMaxAllowablePayment'))
  //   const maxAcc = parseInt(form.getFieldValue('maxAcc'))
  //   if(maxAcc > maxAllowablePayment) {
  //     alert(`Max Acc ${maxAcc} should not exceed payment limit value(${maxAllowablePayment})`)
  //     form.setFieldsValue({maxAcc : undefined})
  //   }
  // }

  return data ? (
    <Form
      form={form}
      onFinish={handleFormFinish}
      layout="vertical"
    >
      <Form.Item label="Override Max Allowable Payment" name="overrideMaxAllowablePayment">
        <InputNumber
          required
          min={0}
          controls={false}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
          precision={2}
          disabled={disableFlag}
        />
      </Form.Item>

      {
        creditTierNumber > 0 && creditTierNumber < 6 && (<>
        <Form.Item label="High Comp Credit" name="heighCompCreditAmt"
         rules={[{ required: true, message: 'High Comp Credit is required' }]}
      >
        <InputNumber
          required={true}
          min={0}
          controls={false}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
          precision={2}
          disabled={disableFlag}
          onChange={(e) => {
            form.setFieldsValue({
                  maxAcc: e/0.8
              });
        }}
        />
      </Form.Item>

      <Form.Item label="Max ACC Amount" name="maxAcc" style={{"color" : "red"}}
        rules={[{ required: true, message: 'Max Acc Credit is required' }]}>
        <InputNumber
          required
          min={0}
          controls={false}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
          precision={2}
          disabled={disableFlag}
        />
      </Form.Item>

      <Form.Item label="Lender Name" name="lenderName" rules={[{ required: true, message: 'Lender Name is required' }]}>
        <Input placeholder="Lender Name" className="ant-input-comp" />
      </Form.Item>
        </>)
      }


      <Form.Item>
        <Button type="primary" htmlType="submit" style={{marginRight: '1em'}} disabled={disableFlag}>
          Save
        </Button>
        <Button type="primary" htmlType="button" onClick={handleFormReset} disabled={disableFlag}>
          Reset
        </Button>
      </Form.Item>
    </Form>
  ) : null ;
}
