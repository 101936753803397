import {Col, Form, InputNumber, Row} from "antd";
import React from "react";

export const TermsInfo = (props) => {
    const {calculatorData, convertUsd, cashDownPaymentDanger, handleCashDownPaymentChange} = props;

    return (
        <>
            <Row style={{height:'6vh'}}>
                <Col span={18} style={{color: cashDownPaymentDanger}}>
                    <p>Minimum Required Down Payment
                        ({calculatorData.minimumRequired && convertUsd(Number(calculatorData.minimumRequired))})</p>
                </Col>
                <Col span={1} style={{paddingTop: '5px', color: cashDownPaymentDanger}}>$</Col>
                <Col span={5} style={{textAlign: 'right'}}>
                    <Form.Item
                        name={['leaseCalculatorAttributes', 'cashDownPayment']}
                    >
                        <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                            precision={2}
                            style={{textAlign: 'right', color: cashDownPaymentDanger}}
                            onChange={(e) => {
                                handleCashDownPaymentChange(e)
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ lineHeight: 2, color: cashDownPaymentDanger }}>
                <Col span={18}>
                    Plus First Month&apos;s Payment
                </Col>
                <Col span={6} style={{textAlign: 'right', color: cashDownPaymentDanger}}>
                    <span>{calculatorData.firstMonthlyPayment && convertUsd(Number(calculatorData.firstMonthlyPayment))}</span>
                </Col>
            </Row>
            <Row style={{ lineHeight: 2 }}>
                <Col span={18} style={{fontWeight: 600, color: cashDownPaymentDanger}}>
                    Total Due at Signing
                </Col>
                <Col span={6} style={{textAlign: 'right', fontWeight: 500, color: cashDownPaymentDanger}}>
                    <span>{calculatorData.totalCashAtSignIn && convertUsd(Number(calculatorData.totalCashAtSignIn))}</span>
                </Col>
            </Row>
        </>
    )
}

export default TermsInfo
