import React, { useState } from "react";
import { Col, Layout, Menu, Collapse, Result, Row } from 'antd';
import Icon, { FileExcelOutlined } from '@ant-design/icons';
import { SiderProps } from '../../components/layouts/MainSiderProps';
import MainHeader from '../../components/layouts/MainHeader';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import MainFooter from '../../components/layouts/MainFooter';
import { LesseeSvg } from '../../components/layouts/Svg';
import AutoReject from "./AutoReject";
import LeasePackageReceived from "./LeasePackageReceived";
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';

const { Content, Sider } = Layout;
const { Panel } = Collapse;

const Menus = [
  {
    label: "Auto Reject",
    iconType: <FileExcelOutlined/>,
    Component: AutoReject,
    menuType: "parent",
    isAllowedToShow: true,
    menuKey: 1,
    emailTemplateName: 'Blackbox Auto Reject'
  },
  {
    label: "Lease Package Received",
    iconType: <Icon component={LesseeSvg}/>,
    Component: LeasePackageReceived,
    menuType: "parent",
    isAllowedToShow: true,
    menuKey: 2,
    emailTemplateName: 'Lease Package Received'
  }
]

export default function EmailTemplates(props) {
  const { allowedActions, handleNoAuth } = props; 
  const adminUserActions = GetResourceActions('AdminUser', allowedActions);
  let allowedGet = IsActionAllowed('get', adminUserActions);

  const [activeMenu, setActiveMenu] = useState([1]);
  const activeMenuList =  Menus.filter(menu => menu.isAllowedToShow);

  return (
    <Layout>
      <MainHeader activePage="administration"/>
      <Layout>
        <>
          {
          allowedGet
            ?
              <>      
                <Sider {...SiderProps} >
                  <Menu              
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}              
                    onSelect={({ key }) => { if (!key.includes("sub")){ setActiveMenu([...activeMenu, key]); } }}
                  >
                    {
                      activeMenuList.map(({ label, iconType, menuType, menuKey }) => {
                        return menuType === 'child' ? null : (
                          <Menu.Item
                            key={(menuKey).toString()}
                          > 
                            <a href={`#${label}-p`}>
                              {iconType}
                              {label}
                            </a>
                          </Menu.Item>
                        )
                      })
                    }
                  </Menu>
                </Sider>

                <Layout id='content-area-layout'>
                  <MainBreadcrumb items={
                    [{ text: "Home", link_type: "linkto", link: "/dashboard" },
                      { text: "Administration", link_type: "linkto", link: "/administration" },
                      { text: "Email", link_type: "ahref", link: "#" }
                    ]}
                  />
                  <Content id='main-content'>
                    <Row gutter={[0, 24]} >
                      <Col span={24} >
                        <Collapse accordion={false} onChange={(key) => { key !== undefined && setActiveMenu(key); }} activeKey={activeMenu}>
                          {
                            activeMenuList.map(({ label, Component, panelLabel, menuKey, emailTemplateName }) => {
                              return (
                                <Panel
                                  className='slc-panel'
                                  header={ panelLabel || label }
                                  key={menuKey}
                                  id={`${label}-p`}
                                >
                                  <Component 
                                    emailTemplateName={emailTemplateName}
                                    handleNoAuth={handleNoAuth}
                                  />
                              </Panel>
                              )
                            })
                          }
                        </Collapse>
                      </Col>
                    </Row>
                  </Content>
                </Layout>
              </>
            :
               <Result 
                status='warning'
                title='You are not allowed to access this page.'
              /> 
          }
        </>
      </Layout>
      <MainFooter/>
    </Layout>
  );
}

// TO DO:
// Finish applying this to remaining Admin route components
// Then update.js to check if isAdmin and then conditionally render routes
// Remmeber to update menu