import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Form,
  Layout,
  Menu,
  message,
  Row,
  Spin,
} from "antd";
import Icon from "@ant-design/icons";
import { POST } from "../../util/network";
import logger from "../../util/logger";
import MainHeader from "../../components/layouts/MainHeader";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import MainFooter from "../../components/layouts/MainFooter";
import { SiderProps } from "../../components/layouts/MainSiderProps";
import { AddNew } from "../../components/layouts/Svg";
import { GetResourceActions, IsActionAllowed } from "../../util/AllowedActions";

const { Content, Sider } = Layout;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function NewMake(props) {
  const { allowedActions, handleNoAuth } = props;
  const history = useHistory();
  const adminUserActions = GetResourceActions("AdminUser", allowedActions);
  const allowedCreate = IsActionAllowed("create", adminUserActions);
  const [makeForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
 
  const save = async (values) => {
    setLoading(true);
    try {
      await POST(`/api/v1/makes`, values)
        .then(() => {
          message.success("Saved successfully");
          history.push("/new-assets/makes/list");
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error("Error while saving!");
          }
        });
    } catch (e) {
      logger.error("Error while saving!", e);
    }
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    values = { ...values };
    save(values);
  };

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <MainHeader activePage="newAssets" />
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{ width: "100%" }}
            >
              <Menu.Item key="1">
                <Icon component={AddNew} />
                Add New
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                { text: "Home", link_type: "linkto", link: "/dashboard" },
                { text: "New Assets", link_type: "ahref", link: void 0 },
                {
                  text: "Makes",
                  link_type: "ahref",
                  link: "/new-assets/makes/list",
                },
                { text: "Add New", link_type: "ahref", link: void 0 },
              ]}
            />

            <Content id="main-content">
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <>
                    <Form
                      form={makeForm}
                      {...layout}
                      colon={false}
                      initialValues={{
                        active: false,
                      }}
                      onFinish={handleSubmit}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Card title="Make Info">
                            <Row gutter={[24, 16]}>
                              <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                <Form.Item
                                  label="Name"
                                  name="name"
                                  hasFeedback
                                  messageVariables={{ name: "Name" }}
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Lms manf"
                                  name="lms_manf"
                                  hasFeedback
                                  messageVariables={{ name: "Lms manf" }}
                                  rules={[{ required: true }]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Vin Starts With"
                                  name="vin_starts_with"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  name="active"
                                  valuePropName="checked"
                                >
                                  <Checkbox>Active</Checkbox>
                                </Form.Item>
                              </Col>

                              <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                <Form.Item
                                  label="JD POWER Make Id"
                                  name="jd_make_id"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Version Name"
                                  name="version_name"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  label="Version Id"
                                  name="version_id"
                                  hasFeedback
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]} className="mt-20">
                        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                          <Row>
                            <Col span={24}>
                              <Form.Item>
                                <Button type="primary" htmlType="submit">
                                  Save
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    </Spin>
  );
}
