import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Col, DatePicker, Form, InputNumber, message, Row } from 'antd';
import CustomInputMask from '../components/CustomInputMask';
import CustomInputText from '../components/CustomInputText';
import CustomInputTextArea from '../components/CustomInputTextArea';
import CustomSelect from '../components/CustomSelect';
import { GET } from '../../../util/network';
import logger from '../../../util/logger';
import moment from 'moment';

export default function Organization({ dealerRepresentatives, dealership, handleSubmit, allowedUpdate, ...props }) {
  const { handleNoAuth } = props;
  const [form] = Form.useForm();

  const history = useHistory();

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  // Dealership
  const [dealershipZipValidateStatus, setDealershipZipValidateStatus] = useState(undefined);
  const [dealershipZipErrorMessage, setDealershipZipErrorMessage] = useState(undefined);

  const [dealershipShowState, setDealershipShowState] = useState(null);
  const [dealershipShowCounty, setDealershipShowCounty] = useState(null);
  const [dealershipShowCity, setDealershipShowCity] = useState(null);
  const [dealershipStateOptions, setDealershipStateOptions] = useState([]);

  const [dealershipCountyOptions, setDealershipCountyOptions] = useState([]);
  const [dealershipCityOptions, setDealershipCityOptions] = useState([]);

  const [initialDealershipRender, setInitialDealershipRender] = useState(null);

  // Owner
  const [ownerZipValidateStatus, setOwnerZipValidateStatus] = useState(undefined);
  const [ownerZipErrorMessage, setOwnerZipErrorMessage] = useState(undefined);

  const [ownerShowState, setOwnerShowState] = useState(null);
  const [ownerShowCounty, setOwnerShowCounty] = useState(null);
  const [ownerShowCity, setOwnerShowCity] = useState(null);
  const [ownerStateOptions, setOwnerStateOptions] = useState([]);

  const [ownerCountyOptions, setOwnerCountyOptions] = useState([]);
  const [ownerCityOptions, setOwnerCityOptions] = useState([]);

  const [initialOwnerRender, setInitialOwnerRender] = useState(null);

  // Dealership Control handlers
  const handleDealershipZipCode = (isDealershipShowState) => {    
    let zipCode = form.getFieldValue(['addressAttributes','zipcode'])

    try {
      processZipCode('dealership', zipCode, isDealershipShowState);
    } catch (e) {
      logger.error("handleDealershipZipCode Error", e);
    }
  }

  const handleDealershipStateChange = () => {
    setDealershipShowState(null);
    setDealershipShowCounty({ "open": true });
  }

  const handleDealershipCountyChange = (e) => {    
    const countyId = e ? e : null;
    const addressType = 'dealership';

    if (countyId) {
      filterCities(addressType, countyId); 
    }
    
    setDealershipShowCounty(null);
    setDealershipShowCity({ "open": true });
  }

  const handleDealershipCityChange = () => {
    setDealershipShowCity(null);
  }  

  // Owner Control handlers
  const handleOwnerZipCode = (isOwnerShowState) => {    
    let zipCode = form.getFieldValue(['ownerAddressAttributes','zipcode'])

    try {
      processZipCode('owner', zipCode, isOwnerShowState);
    } catch (e) {
      logger.error('handleOwnerZipCode Error', e);
    }
  }

  const handleOwnerStateChange = () => {
    setOwnerShowState(null);
    setOwnerShowCounty({ "open": true });
  }

  const handleOwnerCountyChange = (e) => {    
    const countyId = e ? e : null;
    const addressType = 'owner';

    if (countyId) {
      filterCities(addressType, countyId);  
    }
    
    setOwnerShowCounty(null);
    setOwnerShowCity({ "open": true });
  }

  const handleOwnerCityChange = () => {
    setOwnerShowCity(null);
  }
  
  // Helpers
  const filterCities = (addressType, countyId) => {
    if (addressType == 'dealership') {
      const filteredCities = dealershipCityOptions.filter(obj => obj.parentId == countyId);

      if (filteredCities) {
        setDealershipCityOptions(filteredCities)  
      }      
    } else {
      const filteredCities = ownerCityOptions.filter(obj => obj.parentId == countyId);

      if (filteredCities) {
        setOwnerCityOptions(filteredCities)  
      }
    }
  }

  const formatOptions = ({ options, type }) => {
    let newOptions = []

    if (type == 'city') {      
      options.map((value) => { 
        newOptions.push({ 
          optionValue: value['id'], 
          optionLabel: value['name'],
          parentId: value['countyId'],
        })
      })
    } else {
      options.map((value) => { 
        newOptions.push({ 
          optionValue: value['id'], 
          optionLabel: value['abbreviation'] ? value['abbreviation'] : value['name']
        })
      })
    }
    
    return newOptions;
  }  

  const processZipCode = async (addressType, zipCode, isShowState) => {
    
    try {
      let result = await GET(`/api/v1/address/city-details?zipcode=${zipCode}`);

      // Consider refactoring to eliminate near duplication of code
      switch (addressType) {        
        case 'dealership':
          setDealershipStateOptions(formatOptions({options: (result.data.state || []), type: 'state'}));
          setDealershipCountyOptions(formatOptions({options: (result.data.county || []), type: 'county'}));
          setDealershipCityOptions(formatOptions({options: (result.data.city || []), type: 'city'}));

          if (isShowState) {
            setDealershipShowState({ "open": true });
          }
          if (result.data.city.length < 1 || result.data.city  == undefined) {
            setDealershipZipValidateStatus("error");
            setDealershipZipErrorMessage("Please apply a valid Zip Code.");
          } else {
            setDealershipZipValidateStatus(undefined);      
            setDealershipZipErrorMessage(undefined);

            let isSameValue = dealership && dealership.address != null ? ( parseInt(dealership.address.zipcode) !== parseInt(zipCode) ) : false;
            
            let isEmpty = (zipCode !== null);
            
            if ( isSameValue && isEmpty ) {
              form.setFieldsValue({
              addressAttributes: {
                county: undefined,
                state: undefined,
                cityId: undefined
                }
              })
            }
          }
          break;
        case 'owner':          
          setOwnerStateOptions(formatOptions({options: (result.data.state || []), type: 'state'}));
          setOwnerCountyOptions(formatOptions({options: (result.data.county || []), type: 'county'}));
          setOwnerCityOptions(formatOptions({options: (result.data.city || []), type: 'city'}));
          
          if (isShowState) {
            setOwnerShowState({ "open": true });
          }
          if (result.data.city.length < 1 || result.data.city  == undefined) {
            setOwnerZipValidateStatus("error");
            setOwnerZipErrorMessage("Please apply a valid Zip Code.");
          } else {
            setOwnerZipValidateStatus(undefined);      
            setOwnerZipErrorMessage(undefined);

            let isSameValue = dealership && dealership.ownerAddress != null ? ( parseInt(dealership.ownerAddress.zipcode) !== parseInt(zipCode) ) : false;
            let isEmpty = (zipCode !== null);

            if ( isSameValue && isEmpty ) {              
              form.setFieldsValue({
              ownerAddressAttributes: {
                county: undefined,
                state: undefined,
                cityId: undefined
                }
              })
            }
          }
          break;
      }

    } catch (e) {
      logger.error("Request Error", e);
      
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Request Error');
      }      
    }
  }

  useEffect(() => {
    if (!initialDealershipRender) {
      if (dealership && dealership.address && dealership.address.zipcode) {
        processZipCode('dealership', dealership.address.zipcode)  
      }
      
      setInitialDealershipRender(true)
    }

    if (!initialOwnerRender) {
      if (dealership && dealership.ownerAddress && dealership.ownerAddress.zipcode) {
        processZipCode('owner', dealership.ownerAddress.zipcode)  
      }
      
      setInitialOwnerRender(true)
    }
  })   

  return (
    <Form
      { ...layout }
      form={ form }
      scrollToFirstError={true}
      onFinish={handleSubmit}
      initialValues={{
        addressAttributes: {
          id: dealership && dealership.address && dealership.address.id,
          street1: dealership && dealership.address && dealership.address.street1,
          street2: dealership && dealership.address && dealership.address.street2,
          zipcode: dealership && dealership.address && dealership.address.zipcode,
          county: dealership && dealership.address && dealership.address.county,
          state: dealership && dealership.address && dealership.address.stateId,
          cityId: dealership && dealership.address && dealership.address.cityId
        },
        businessDescription: dealership && dealership.businessDescription,
        dealerGroup: dealership && dealership.dealerGroup,
        dealerLicenseNumber: dealership && dealership.dealerLicenseNumber,
        dealerRepresentativeIds: dealership && dealership.dealerRepresentatives.map( x => x.id.toString()),
        legalCorporateEntity: dealership && dealership.legalCorporateEntity,
        licenseExpirationDate: dealership && dealership.licenseExpirationDate && moment(dealership.licenseExpirationDate, 'YYYY-MM-DD'),
        name: dealership && dealership.name,
        id: dealership && dealership.id,
        notificationEmail: dealership && dealership.notificationEmail,
        ownerAddressAttributes: {
          id: dealership && dealership.ownerAddress && dealership.ownerAddress.id,
          street1: dealership && dealership.ownerAddress && dealership.ownerAddress.street1,
          street2: dealership && dealership.ownerAddress && dealership.ownerAddress.street2,
          zipcode: dealership && dealership.ownerAddress && dealership.ownerAddress.zipcode,
          county: dealership && dealership.ownerAddress && dealership.ownerAddress.county,
          state: dealership && dealership.ownerAddress && dealership.ownerAddress.stateId,
          cityId: dealership && dealership.ownerAddress && dealership.ownerAddress.cityId
        },                
        ownerFirstName: dealership && dealership.ownerFirstName,
        ownerLastName: dealership && dealership.ownerLastName,
        pctOwnership: dealership && dealership.pctOwnership,
        primaryContactPhone: dealership && dealership.primaryContactPhone,
        website: dealership && dealership.website
      }}
    > 
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card className='h-100' title="General" >
            <span>Dealership ID : {dealership && dealership.address && dealership.address.id}</span>
            <CustomInputText name="name" label="Name" required={true} ruleMessage="Name is required!"/>
            <CustomInputText name="website" label="Website" required={true} ruleMessage="Website is required!"/>
            <CustomInputMask name="primaryContactPhone" label="Primary Phone" required={true} mask="(111) 111-1111" ruleMessage="Primary Phone is required!"/>
            <CustomInputText name="legalCorporateEntity" label="Legal Corporate Entity" required={true} ruleMessage="Legal Corporate Entity is required!"/>
            <CustomInputText 
              name='dealerLicenseNumber' 
              label='License Number'
            />
            <Form.Item 
              label='License Expiration Date'
              name={['licenseExpirationDate']}
              required={true} 
              hasFeedback
            >  
              <DatePicker />
            </Form.Item>
            <CustomInputText name="dealerGroup" label="Dealer Group" />
            <CustomSelect 
              name="dealerRepresentativeIds" 
              label="Dealer Representatives" 
              mode="tags" 
              options={dealerRepresentatives} 
              required={true} 
              ruleMessage="Dealer Representatives is required!"
            />
            <CustomInputText 
              name='notificationEmail'
              label='New Application Notification Email'
              tooltipText='If provided, the LOS will automatically send lease application notifications to this email address.'
            />        
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card className='h-100' title="Location" >
            <CustomInputText name={['addressAttributes', 'id']} type="hidden"/>
            <CustomInputText 
              name={['addressAttributes', 'street1']} 
              label="Street Address (no P.O Boxes)" 
              required={false} 
              //ruleMessage="Street Address is required!"
            />
            <CustomInputText name={['addressAttributes', 'street2']} label="Unit" />
            <CustomInputMask
              name={['addressAttributes', 'zipcode']} 
              label="Zip Code"  
              onPressEnter={handleDealershipZipCode} 
              onBlur={handleDealershipZipCode} 
              required={false}
              //ruleMessage="Zip Code is required!"
              validateStatus={dealershipZipValidateStatus}
              help={dealershipZipErrorMessage}
              mask="11111"
            />
            <CustomSelect name={['addressAttributes', 'state']} 
              label="State" 
              required={false}
              //ruleMessage="State is required!"
              options={dealershipStateOptions} 
              additionalProps={dealershipShowState}
              onSelect={handleDealershipStateChange}
            />
            <CustomSelect 
              name={['addressAttributes', 'county']} 
              label="County/Parish" 
              required={false}
              //ruleMessage="County/Parish is required!" 
              options={dealershipCountyOptions} 
              additionalProps={dealershipShowCounty}
              onSelect={handleDealershipCountyChange}
            />
            <CustomSelect 
              name={['addressAttributes', 'cityId']} 
              label="City"
              required={false}
              //ruleMessage="City is required!"
              options={dealershipCityOptions}  
              additionalProps={dealershipShowCity}
              onSelect={handleDealershipCityChange}
             /> 
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card className='h-100' title='Ownership'>
            <CustomInputText 
              name='ownerFirstName'
              label="Owner's First Name" 
              required={false} 
              //ruleMessage='First Name is required!'
            />
            <CustomInputText 
              name='ownerLastName'
              label="Owner's Last Name" 
              required={false} 
              //ruleMessage='Last Name is required!'
            />
            <CustomInputText name={['ownerAddressAttributes', 'id']} type="hidden"/>
            <CustomInputText 
              name={['ownerAddressAttributes', 'street1']} 
              label='Street Address (no P.O Boxes)'
              required={false} 
              //ruleMessage='Street Address is required!'
            />
            <CustomInputText 
              name={['ownerAddressAttributes', 'street2']} 
              label='Unit'
            />
            <CustomInputMask
              name={['ownerAddressAttributes', 'zipcode']} 
              label="Zip Code"  
              onPressEnter={handleOwnerZipCode} 
              onBlur={handleOwnerZipCode} 
              required={false}
              //ruleMessage='Zip Code is required!'
              validateStatus={ownerZipValidateStatus}
              help={ownerZipErrorMessage}
              mask="11111"
            />
            <CustomSelect name={['ownerAddressAttributes', 'state']} 
              label='State'
              required={false}
              //ruleMessage='State is required!'
              options={ownerStateOptions} 
              additionalProps={ownerShowState}
              onSelect={handleOwnerStateChange}
            />
            <CustomSelect 
              name={['ownerAddressAttributes', 'county']} 
              label='County/Parish'
              required={false}
              //ruleMessage='County/Parish is required!'
              options={ownerCountyOptions} 
              additionalProps={ownerShowCounty}
              onSelect={handleOwnerCountyChange}
            />
            <CustomSelect 
              name={['ownerAddressAttributes', 'cityId']} 
              label='City'
              required={false}
              //ruleMessage='City is required!'
              options={ownerCityOptions}  
              additionalProps={ownerShowCity}
              onSelect={handleOwnerCityChange}
            /> 
            <Form.Item 
              label='Percentage Ownership'
              name={['pctOwnership']}
              hasFeedback
            >
              <InputNumber 
                min='0'
                max='100'
                step='0.01'
              />
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <Row gutter={[24, 24]} className='mt-20'>
          <Col span={24}>
            <CustomInputTextArea 
              name="businessDescription" 
              label="Business Description" 
              required={false} 
              //ruleMessage="Business Description is required!"
              rows={4}
            />
          </Col>
        </Row>
      <Row>
        <Col span={24}> 
          <Form.Item>
            {
              allowedUpdate &&                
                <Button type="primary" htmlType="submit" disabled={!allowedUpdate}>
                  Save
                </Button>
            }            
          </Form.Item>
        </Col> 
      </Row>
    </Form>
  )
}