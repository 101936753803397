import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Form,
  Layout,
  Menu,
  message,
  Row,
  Spin,
} from "antd";
import Icon from "@ant-design/icons";
import { GET, PUT } from "../../util/network";
import logger from "../../util/logger";
import MainHeader from "../../components/layouts/MainHeader";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import MainFooter from "../../components/layouts/MainFooter";
import { SiderProps } from "../../components/layouts/MainSiderProps";
import { AddNew } from "../../components/layouts/Svg";

const { Content, Sider } = Layout;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function MakeEdit(props) {
  const { id } = props.match.params;
  const { handleNoAuth } = props;
  const history = useHistory();
  const [makeForm] = Form.useForm();

  const [makeData, setMakeData] = useState();
  const [loading, setLoading] = useState(false);

   const makeDetails = async () => {
     setLoading(true);
     try {
       await GET(`/api/v1/makes/details/${id}`)
         .then((response) => {
           setMakeData(response.data);
         })
         .catch((error) => {
           if (error.response && error.response.status === 401) {
             handleNoAuth(history);
           } else {
             message.error("Error while fetching Make Details");
           }
         });
     } catch (e) {
       logger.error("Error while fetching Make Details", e);
     }
     setLoading(false);
   };
  
  const convertAPISource = (source) => {
    return source === 'JD POWER' ? 'JD_POWER' : 'NADA';
  }

  const save = async (values) => {
    setLoading(true);
    try {
      await PUT(`/api/v1/makes/${id}`, values)
        .then(() => {
          message.success("Saved successfully");
          history.push("/new-assets/makes/list");
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error("Error while saving!");
          }
        });
    } catch (e) {
      logger.error("Error while saving!", e);
    }
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    values = { ...values };
    save(values);
  };

  useEffect(() => {
    makeDetails();
  }, []);

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <MainHeader activePage="newAssets" />
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{ width: "100%" }}
            >
              <Menu.Item key="1">
                <Icon component={AddNew} />
                Edit Make
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                { text: "Home", link_type: "linkto", link: "/dashboard" },
                { text: "New Assets", link_type: "ahref", link: void 0 },
                {
                  text: "Makes",
                  link_type: "ahref",
                  link: "/new-assets/makes/list",
                },
                { text: "Edit", link_type: "ahref", link: void 0 },
              ]}
            />

            <Content id="main-content">
              <Row gutter={[0, 24]}>
                {makeData && (
                  <Col span={24}>
                    <>
                      <Form
                        form={makeForm}
                        {...layout}
                        colon={false}
                        initialValues={{
                          id: makeData?.id,
                          name: makeData?.name,
                          lms_manf: makeData?.lms_manf,
                          vin_starts_with: makeData?.vin_starts_with,
                          jd_make_id: makeData?.jd_make_id,
                          api_source: convertAPISource(makeData?.api_source),
                          version_name: makeData?.version_name,
                          version_id: makeData?.version_id,
                          active: makeData?.active,
                          nada_enabled: makeData?.nada_enabled,
                        }}
                        onFinish={handleSubmit}
                      >
                        <Row gutter={[16, 16]}>
                          <Col span={24}>
                            <Card title="Make Info">
                              <Row gutter={[24, 16]}>
                                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                  <Form.Item
                                    label="Name"
                                    name="name"
                                    hasFeedback
                                    messageVariables={{ name: "Name" }}
                                    rules={[{ required: true }]}
                                  >
                                    <Input />
                                  </Form.Item>

                                  <Form.Item
                                    label="Lms manf"
                                    name="lms_manf"
                                    hasFeedback
                                    messageVariables={{ name: "Lms manf" }}
                                    rules={[{ required: true }]}
                                  >
                                    <Input />
                                  </Form.Item>

                                  <Form.Item
                                    label="Vin Starts With"
                                    name="vin_starts_with"
                                    hasFeedback
                                  >
                                    <Input />
                                  </Form.Item>

                                  <Form.Item
                                    name="active"
                                    valuePropName="checked"
                                  >
                                    <Checkbox>Active</Checkbox>
                                  </Form.Item>
                                </Col>

                                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                  <Form.Item
                                    label="JD POWER Make Id"
                                    name="jd_make_id"
                                    hasFeedback
                                  >
                                    <Input />
                                  </Form.Item>

                                  <Form.Item
                                    label="Version Name"
                                    name="version_name"
                                    hasFeedback
                                  >
                                    <Input />
                                  </Form.Item>

                                  <Form.Item
                                    label="Version Id"
                                    name="version_id"
                                    hasFeedback
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>

                        <Row gutter={[16, 16]} className="mt-20">
                          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                            <Row>
                              <Col span={24}>
                                <Form.Item>
                                  <Button type="primary" htmlType="submit">
                                    Save
                                  </Button>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  </Col>
                )}
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    </Spin>
  );
}
