import React from "react";
import { Row, Col, Table } from "antd";

const columns = [
    {
        title: 'Date First Seen',
        dataIndex: 'dateFirstSeen',
        key: 'dateFirstSeen'
    },
    {
        title: 'Date Last Seen',
        dataIndex: 'dateLastSeen',
        key: 'dateLastSeen'
    },
    {
        title: 'Street Address',
        dataIndex: 'streetAddress1',
        key: 'streetAddress1'
    },
    {
        title: 'City',
        dataIndex: 'city',
        key: 'city'
    },
    {
        title: 'State',
        dataIndex: 'state',
        key: 'state'
    },
    {
        title: 'ZIP',
        dataIndex: 'zipCode',
        key: 'zipCode'
    },
    {
        title: 'County',
        dataIndex: 'county',
        key: 'county'
    },
    {
        title: 'Building Name',
        dataIndex: 'buildingName',
        key: 'buildingName'
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description'
    },
    {
        title: 'Subdivision Name',
        dataIndex: 'subdivisionName',
        key: 'subdivisionName'
    }
];
export default function TloAddressSearch({ data }) {
  const dataSource = data.leaseApplicationBlackboxRequest.leaseApplicationBlackboxTloPersonSearchAddresses || []
  return data ? (
    <>
      <Row>
        <Col span={24}>
          <Table 
            className='slc-table'
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            size="small"
          />
        </Col>
      </Row>
    </>
  ) : null ;
}