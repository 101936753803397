import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import {
    Form,
    Comment,
    List,    
    Col,
    Input,
    Modal, Descriptions,
    message,
    Button,
    Spin,
    Row
} from "antd";

import { GET, POST, DELETE } from '../../../util/network';
import logger from '../../../util/logger';
import MainHeader from '../../../components/layouts/MainHeader';

export default function WelcomeCallView({ handleNoAuth, match }) {
    const history = useHistory();
    
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [welcomeCallData, setWelcomeCallData] = useState(null);
    const getWelcomeCallData = async () => {
        try {
            const response = await GET(`/api/v1/lease-application-pending-calls/${match.params.appNumber}`);
            setWelcomeCallData(response.data);
        } catch (e) {
            logger.error(" welcome call data Error", e);

            if (e && e.response && e.response.status === 401) {
              handleNoAuth(history);
            } else {
              message.error('Error while loading welcome call data');
            }
        }
    };
    useEffect(() => {
        getWelcomeCallData();
    }, []);

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };

    const onFinish = async (values) => {
        values = { ...values, resourceId: welcomeCallData.resourceId, resourceType: welcomeCallData.resourceType };
        setLoading(true)
        try {
            await POST(`/api/v1/comments`, values);
            getWelcomeCallData();
            message.success("Data saved successfully");
            form.resetFields();
        } catch (e) {
            logger.error("Save Welcome Call Error", e);

            if (e && e.response && e.response.status === 401) {
              handleNoAuth(history);
            } else {
              message.error('Error while saving your data');
            }
        }
        setLoading(false)
    }
    const deleteComment = async (id) => {
        Modal.confirm({
            title: "Confirm",
            content: "Are you sure you want to delete this comment?",
            onOk: async () => {
                setLoading(true)
                try {
                    await DELETE(`/api/v1/comments/${id}`);
                    message.success("Comment deleted successfully");
                    form.resetFields();
                    getWelcomeCallData();
                } catch (e) {
                    logger.error("Delete Comment Error", e);

                    if (e && e.response && e.response.status === 401) {
                      handleNoAuth(history);
                    } else {
                      message.error('Error while deleting your comment');
                    }
                }
                setLoading(false)
            }
        })
    }
    return (
        <>
            <MainHeader activePage="administration"/>
            <main className="main">
                <Spin spinning={loading}>
                    {welcomeCallData ?
                        (<Form className="welcome-call-details-view" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Row gutter={[16, 8]}>
                                <Descriptions bordered title="Lease Application Welcome Call Details" column={1}>
                                    <Descriptions.Item label="Due Date">{welcomeCallData.date_and_time}</Descriptions.Item>
                                    <Descriptions.Item label="Status">{welcomeCallData.status}</Descriptions.Item>
                                    <Descriptions.Item label="Date and Time">{welcomeCallData.date_and_time}</Descriptions.Item>
                                    <Descriptions.Item label="Representative">{welcomeCallData.representative}</Descriptions.Item>
                                    <Descriptions.Item label="Type">{welcomeCallData.type}</Descriptions.Item>
                                    <Descriptions.Item label="Result">{welcomeCallData.result}</Descriptions.Item>
                                    <Descriptions.Item label="Notes">{welcomeCallData.notes}</Descriptions.Item>
                                </Descriptions>
                            </Row>
                            <Row className="comments">
                                <Col span={24}>
                                    
                                    <List
                                        className="comment-list"
                                        header={`${(welcomeCallData.comments || []).length || ""} Comments`}
                                        itemLayout="horizontal"
                                        dataSource={welcomeCallData.comments || []}
                                        renderItem={item => (
                                            <li>
                                                <Comment
                                                    //actions={item.actions}
                                                    author={(
                                                        <a className="comment-link" href={`${process.env.REACT_APP_BASE_URL}/admins/admin_users/${item.author_id}`}>{item.author_name}</a>
                                                    )}
                                                    avatar={null}
                                                    content={item.body}
                                                    datetime={<div>{item.created_at}</div>}
                                                    actions={[
                                                        [<span key={1} className="comment-link" onClick={() => { deleteComment(item.id) }}>Delete Comment</span>]
                                                    ]}
                                                />
                                            </li>
                                        )}
                                    />
                                    <Form.Item
                                        wrapperCol={{ span: 24 }}
                                        style={{ width: "100%" }}
                                        name="body" rules={[{
                                            required: true,
                                            message: "Comment is required"
                                        }]}
                                    >
                                        <Input.TextArea rows={4} style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16, 8]}>
                                <Col span={24}>
                                    <Button type="primary" htmlType="submit">
                                        Add Comment
                                </Button>
                                </Col>
                            </Row>
                        </Form>) : null}
                </Spin>
            </main>
        </>
    );
}