import React, { useState, useEffect } from "react";
import { Select, Table, Row, Col, Card, Input, Form, Button, Spin, Layout, message, Menu, Collapse, Upload, Typography, DatePicker, Modal } from 'antd';
import Icon, { InfoCircleOutlined, InboxOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { MotorSvg } from '../../components/layouts/Svg';
import { GET, PUT, POST, DELETE } from '../../util/network';
import logger from '../../util/logger';
import MainHeader from '../../components/layouts/MainHeader';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import MainFooter from '../../components/layouts/MainFooter';
import { SiderProps } from '../../components/layouts/MainSiderProps';
import "./Inventory.css";
import moment from 'moment';
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';

const { Text } = Typography;
const { Content, Sider } = Layout;
const { Option } = Select;
const { Panel } = Collapse;
const BASE_URL = process.env.REACT_APP_BASE_URL;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const columns = [
  {
    title: 'Date',
    dataIndex: 'startDate'
  },
  {
    title: 'NADA Avg Retail',
    dataIndex: 'nadaAvgRetail',
  },
  {
    title: 'NADA Rough',
    dataIndex: 'nadaRough',
  },
];  

export default function Inventory(props) {
  const { allowedActions, handleNoAuth } = props;
  const inventoryActions = GetResourceActions('VehicleInventory', allowedActions);

  const allowedDelete = IsActionAllowed('delete', inventoryActions);    
  const allowedGet = IsActionAllowed('get', inventoryActions);
  const allowedUpdate = IsActionAllowed('update', inventoryActions);    

  const history = useHistory();
  const vehicleId = props.match.params.id;

  const [assetMake, setAssetMake] = useState(null);
  const [assetYear, setAssetYear] = useState(null);
  const [assetModel, setAssetModel] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [defaultImages, setDefaultImages] = useState([]);
  const [loading, setLoading] = useState(null);

  const [atAcquisitionDataSource, setAtAcquisitionDataSource] = useState([]);
  const [atSaleDataSource, setAtSaleDataSource] = useState([]);

  const [inventoryStatus, setInventoryStatus] = useState(null);

  const [inventoryForm] = Form.useForm();
  const [activeMenu, setActiveMenu] = useState([1]);
  const [fileList, setFileList] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmBtnClass, setConfirmBtnClass] = useState("confirm-delete-btn confirm-delete-btn-disabled");
  const [confirmBtnDisable, setConfirmBtnDisable] = useState(true);

  let auth_token = window.localStorage.getItem('auth_token');

  const losPath = (path) => {
    return `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=${path}`
  }

  const verifyVin = async (vin) => {
    setLoading(true);

    try {
      await GET(`/api/v1/vin_verification/verify-vin?vin=${vin}`).then(response => {
        setAssetMake(response.data.vehicleInfo.make);
        setAssetModel(response.data.vehicleInfo.model);
        setAssetYear(response.data.vehicleInfo.year);

        inventoryForm.setFieldsValue({
          assetMake: response.data.vehicleInfo.make,
          assetYear: response.data.vehicleInfo.year,
          assetModel: response.data.vehicleInfo.model
        })
      }).catch(error => {
        logger.error("Vin Verification Error", error.response)
        message.error(error.response.data.message, 10)

        setAssetMake(null)
        setAssetModel(null)
        setAssetYear(null)

        inventoryForm.setFieldsValue({
          assetMake: null,
          assetYear: null,
          assetModel: null
        })
      })
    } catch (e) {
      logger.error("Vin Verification Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('VIN verification error');
      }
    }

    setLoading(false);
  }

  const handleVerifyVin = (e) => {
    let vin = e.target.value;

    if (vin.length === 17 ) {
      verifyVin(vin);
    }
  }

  const getVehicleInventory = async () => {
    if (allowedGet) {
      setLoading(true);

      try {
        const response = await GET(`/api/v1/vehicle-inventory/${vehicleId}`);
        let tempImages = [];

        response.data.vehicleInventory.vehicleInventoryImages.map(({id, imageUrl, name}) => {
          let url = process.env.REACT_APP_ENV_VAR == 'Development' ? `${BASE_URL}${imageUrl}` : imageUrl;
          tempImages.push({ id: id, uid: id, url: url, name: name});          
        })

        setDefaultImages(tempImages); //set default image first 

        setVehicle(response.data.vehicleInventory);
        setAssetMake(response.data.vehicleInventory.assetMake);
        setAssetYear(response.data.vehicleInventory.assetYear);
        setAssetModel(response.data.vehicleInventory.assetModel);

        inventoryForm.setFieldsValue({
          assetMake: response.data.vehicleInventory.assetMake,
          assetYear: response.data.vehicleInventory.assetYear,
          assetModel: response.data.vehicleInventory.assetModel
        });

        setAtAcquisitionDataSource(response.data.vehicleInventory.atAcquisition);
        setAtSaleDataSource(response.data.vehicleInventory.atSale);
      } catch (e) {
        logger.error("getVehicleInventory Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error getting vehicle inventory');
        }        
      }

      setLoading(false);
    }
  }

  const handleSubmit = async (values) => {
    if (allowedUpdate) {
      values = { ...values };
      updateVehicleInventory(values)      
    }
  }

  const uploadImages = async (id) => {
    let isEmpty = true;

    if (fileList) {
      isEmpty = !Object.keys(fileList).length;
    }

    if (allowedUpdate && !isEmpty) {
      try {
          const formData = new FormData();
            
          formData.append('id', id);
          fileList.forEach(file => {
            formData.append('images[]', file);
          });

          await POST(`/api/v1/vehicle-inventory/upload-image`, formData);
      } catch (e) {
        logger.error("Error saving photos", e);
        
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error saving photos');
        }

      }
    }
  }

  const updateVehicleInventory = async (values) => {
    if (allowedUpdate) {
      try {
         await PUT(`/api/v1/vehicle-inventory/${vehicleId}`, { vehicleInventory: values });
         uploadImages(vehicleId);
         message.success("Save Successfully");
      } catch (e) {
        logger.error("Request Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error saving details');
        }
      }
    }
  }
    
  const getInventoryStatuses = async () => {
    if (allowedGet) {
      try {
        const response = await GET(`/api/v1/inventory-status`);

        setInventoryStatus(response.data.inventoryStatus);
       } catch (e) {
          logger.error("Request Error", e);

          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error('Error getting inventory status options');
          }
       }
    }
  }

  const handleDeleteImage = async (imageId) => {
    if (allowedUpdate) {      
      try {
        await GET(`/api/v1/vehicle-inventory/delete-image?imageId=${imageId}&id=${vehicle.id}`);
        message.success("Deleted Successfully");
      } catch (e) {
        logger.error("Request Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error deleting image');
        }
      }
    }
  }

  const uploadProps = {
    defaultFileList: defaultImages,
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: <DownloadOutlined />,
      showRemoveIcon: true,
      removeIcon: <DeleteOutlined />,
    },
    onRemove: file => {
      if (file.id){
        handleDeleteImage(file.id);
      } else {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList)
      }
    },
    beforeUpload: file => {
        setFileList([...fileList, file])
        return false;
    },
  };

  useEffect(() => {
    getInventoryStatuses()
  }, []);

  useEffect(() => {
    getVehicleInventory()
    getInventoryStatuses()
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };
  
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDeleteMe = (e) => {
    if (e.target.value === 'delete me'){
      setConfirmBtnDisable(false);
      setConfirmBtnClass("confirm-delete-btn confirm-delete-btn-active");
    } else {
      setConfirmBtnDisable(true);
      setConfirmBtnClass("confirm-delete-btn confirm-delete-btn-disabled");
    }
  }

  const handeDelete = () => {
    if (allowedDelete) {
      deleteVehicleInventory();
    }
  }

  const deleteVehicleInventory = async () => {
    try {
      await DELETE(`/api/v1/vehicle-inventory/${vehicleId}`);
      message.success("Successfully Deleted");
      history.push('/inventory');
    } catch (e) {
      logger.error("Request Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error deleting vehicle');
      }
    }
  }

  return vehicle ? (
    <Spin spinning={loading}>
      <Layout>
        <MainHeader activePage="inventory"/>
        <Layout>
          <Sider {...SiderProps} >
            <Menu
              defaultSelectedKeys={[1]}
              selectedKeys={[activeMenu.toString()]}
              style={{ width: '100%' }}
              onSelect={({ key }) => { if (!key.includes("sub")){ setActiveMenu([...activeMenu, key]); } }}
            >
              <Menu.Item key="1" > <Icon component={MotorSvg} /> Vehicle</Menu.Item>
              <Menu.Item key="2" >  <InfoCircleOutlined /> General Info</Menu.Item>
            </Menu>
          </Sider>

          <Layout id='content-area-layout'>
            <MainBreadcrumb items={
              [{ text: "Home", link_type: "linkto", link: "/dashboard" },
              { text: "Inventory", link_type: "linkto", link: "/inventory" },
              { text: `${vehicleId}` }
              ]}
            />
    
            <Content id='main-content'>
              <Form 
                form={inventoryForm} 
                {...layout}  
                colon={false}
                onFinish={handleSubmit}
                scrollToFirstError={true}
                initialValues={{ 
                  assetVin: vehicle && vehicle.assetVin,
                  newUsed: vehicle && vehicle.newUsed,                         
                  stockNumber: vehicle && vehicle.stockNumber,                         
                  assetColor: vehicle && vehicle.assetColor,                          
                  exactOdometerMileage: vehicle && vehicle.exactOdometerMileage,                         
                  inventoryStatusId: vehicle && vehicle.inventoryStatusId && vehicle.inventoryStatusId.toString(),
                  intakeDate: vehicle && moment(vehicle.intakeDate),
                  saleDate: vehicle && moment(vehicle.saleDate),
                  assetMake: vehicle && vehicle.assetMake,
                  assetYear: vehicle && vehicle.assetYear,
                  assetModel: vehicle && vehicle.assetModel              
                }}
              >
                <Row gutter={[0, 24]} >
                  <Col span={24} >
                    <Collapse 
                      accordion={false} 
                      onChange={(key) => { key !== undefined && setActiveMenu(key); }} 
                      activeKey={['1', '2']}
                    >
                      <Panel className='slc-panel' header="Vehicle" key="1">
                        <Row gutter={[24, 24]} >
                          <Col span={12} >
                            <Form.Item label="Upload Image">
                              <Form.Item valuePropName="fileList" noStyle>
                                {/* <Upload.Dragger name="image" action={`${BASE_URL}/api/v1/vehicle-inventory/upload-image`} data={{id: vehicleId}} {...uploadProps}> */}
                                <Upload.Dragger name="image" {...uploadProps}>
                                  <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                  </p>
                                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                  <p className="ant-upload-hint">Support for a single or bulk upload.</p>
                                </Upload.Dragger>
                              </Form.Item>
                            </Form.Item>
                          </Col>
                          <Col span={12} >
                            <Card>
                              <Row>
                                <Col span={24}> 
                                  <Form.Item 
                                    label="VIN" 
                                    hasFeedback
                                    name="assetVin"
                                    rules={[{ required: true, message: 'VIN is required!' }]}
                                  >  
                                    <Input placeholder="VIN" onChange={handleVerifyVin} />
                                  </Form.Item>

                                  <Form.Item 
                                    label="assetMake" 
                                    hidden={true}
                                    hasFeedback
                                    name="assetMake"
                                  >  
                                    <Input />
                                  </Form.Item>

                                  <Form.Item 
                                    label="assetYear" 
                                    hidden={true}
                                    hasFeedback
                                    name="assetYear"
                                  >  
                                    <Input />
                                  </Form.Item>

                                  <Form.Item 
                                    label="assetModel" 
                                    hidden={true}
                                    hasFeedback
                                    name="assetModel"
                                  >  
                                    <Input />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}> 
                                  <Form.Item 
                                    label="New/Used" 
                                    hasFeedback
                                    name="newUsed"
                                    rules={[{ required: true, message: 'New/Used is required!' }]}
                                  >  
                                    <Select 
                                      showSearch 
                                      placeholder="New/Used" >
                                      <Option key="1" value="New">New</Option>
                                      <Option key="2" value="Used">Used</Option>
                                    </Select>
                                  </Form.Item>
                                </Col> 
                              </Row>
                              <Row style={{marginBottom: 10}}><Col span={24}> <Text style={{color: "rgba(0, 0, 0, 0.65)", fontWeight: 700, fontSize: "1.1em" }}> Make </Text> </Col> <Col span={24}> {assetMake} </Col></Row> 
                              <Row style={{marginBottom: 10}}><Col span={24}> <Text style={{color: "rgba(0, 0, 0, 0.65)", fontWeight: 700, fontSize: "1.1em" }}> Year </Text> </Col> <Col span={24}> {assetYear} </Col></Row> 
                              <Row style={{marginBottom: 10}}><Col span={24}> <Text style={{color: "rgba(0, 0, 0, 0.65)", fontWeight: 700, fontSize: "1.1em" }}> Model </Text> </Col> <Col span={24}> {assetModel} </Col></Row> 
                            </Card>
                          </Col>
                          </Row>
                      </Panel>

                      <Panel className='slc-panel' header="General Info" key="2">
                        <Row gutter={[24, 24]} >
                          <Col span={8} >
                            <Card className='h-100' title="Vehicle">
                              <Row>
                                <Col span={24}> 
                                  <Form.Item 
                                    label="Stock Number" 
                                    hasFeedback
                                    name="stockNumber"
                                    rules={[{ required: true, message: 'Stock Number is required!' }]}
                                  >  
                                    <Input placeholder="Stock Number" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}> 
                                  <Form.Item 
                                    label="Vehicle Color" 
                                    hasFeedback
                                    name="assetColor"
                                    rules={[{ required: true, message: 'Vehicle Color is required!' }]}
                                  >  
                                    <Input placeholder="Vehicle Color" />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}> 
                                  <Form.Item 
                                    label="Mileage" 
                                    hasFeedback
                                    name="exactOdometerMileage"
                                    rules={[{ required: true, message: 'Mileage is required!' }]}
                                  >  
                                    <Input placeholder="Mileage" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                          <Col span={8} >
                            <Card className='h-100' title="Life Cycle">
                              <Row>
                                <Col span={24}> 
                                  <Form.Item 
                                    label="Status" 
                                    hasFeedback
                                    name="inventoryStatusId"
                                    rules={[{ required: true, message: 'Status is required!' }]}
                                  >
                                    <Select 
                                      showSearch 
                                      placeholder="Status" >
                                      {
                                        inventoryStatus && inventoryStatus.map(({id, description}, index) => {
                                          return <Option key={index} value={`${id}`}>{description}</Option>
                                        })
                                      }
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={24}> 
                                  <Form.Item 
                                    label="Intake Date" 
                                    hasFeedback
                                    name="intakeDate"
                                    rules={[{ required: true, message: 'Intake Date is required!' }]}
                                  >  
                                    <DatePicker />
                                  </Form.Item>
                                </Col> 
                              </Row>
                              <Row>
                                <Col span={24}> 
                                  <Form.Item 
                                    label="Sale Date" 
                                    hasFeedback
                                    name="saleDate"
                                  >  
                                    <DatePicker />
                                  </Form.Item>
                                </Col> 
                              </Row>
                            </Card>
                          </Col>
                          <Col span={8} >
                            <Card className='h-100' title="NADA Values">
                              <Table
                                columns={columns}
                                dataSource={atAcquisitionDataSource}
                                bordered
                                title={() => 'At Acquisition'}
                                pagination={false}
                                size="small"
                                rowKey={atAcquisitionDataSource => atAcquisitionDataSource.startDate}
                              />
                              <br/>
                              <Table
                                columns={columns}
                                dataSource={atSaleDataSource}
                                bordered
                                title={() => 'At Sale'}
                                pagination={false}
                                size="small"
                                rowKey={atSaleDataSource => atSaleDataSource.startDate}
                              />
                              <br/>
                              <Row gutter={[16, 16]}>
                                <Col span={24}> 
                                  <Button 
                                    type="primary" 
                                    style={{textAlign: 'right'}} 
                                    target='_blank' 
                                    href={losPath(`/admins/model_years?q[name_contains]=${vehicle.modelYear.name}?q[model_group_make_id_eq]=${vehicle.modelYear.makeId}?q[year_equals]=${vehicle.assetYear}?commit=Filter&order=id_desc`)}>
                                      View NADA Value History
                                    </Button>
                                </Col> 
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>

                    <Row gutter={[16, 16]}>
                      <Col span={24} style={{ textAlign: 'right' }}> 
                        <Form.Item>
                          { 
                              allowedDelete && 
                                  <Button onClick={showModal}>
                                      Delete
                                  </Button>
                          }
                          &nbsp;
                          &nbsp;
                          { allowedUpdate && 
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                          }
                        </Form.Item>


                        <Modal centered visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false} header={false}>
                          <p><Text strong>Are you absolutely sure? </Text></p>
                          <p><Text> This action <Text strong>connot</Text> be undone. This will permanently delete the motorcycle and related photographs. </Text></p>
                          <p><Text> Please type <Text strong>delete me</Text> to confirm. </Text></p>
                          <Input onChange={handleDeleteMe}/>
                          <Button className={confirmBtnClass} disabled={confirmBtnDisable} onClick={handeDelete}>I understand the consequences, delete this record</Button>
                        </Modal>

                      </Col> 
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Content>
          </Layout>

        </Layout>
        <MainFooter/>
      </Layout>
    </Spin> 
  ) : null;
}