import React, { useEffect } from 'react';
import { POST } from '../util/network';
import logger from '../util/logger';
import { message } from 'antd';

function SignOut() {
    const expireToken = async () => {
        try {
            const response = await POST('/api/v1/sign-out');
            localStorage.clear();
            window.location.href = `/admins/login`;
        } catch(e) {
            message.error(e.response.data);
            logger.error("Error while saving!", e);
        }
        
    }

    useEffect(() => {
        if (window.localStorage.getItem('auth_token')) {
            expireToken();
        }
    });

    return (
        <></>
    )
}

export default SignOut;
