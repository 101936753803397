import React, {useState, useEffect} from 'react'
import {GET} from "../../util/network";
import MainHeader from "../../components/layouts/MainHeader";
import MainFooter from "../../components/layouts/MainFooter";
import {message, Spin} from "antd";
import ApplicationStipulationView from "./ApplicationStipulationView";

export default function ApplicationWelcomeCallRenderer(props) {
  const {id} = props.match.params;
  const {handleNoAuth} = props;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getStipulations = async () => {
    setLoading(true)
    try {
      const response = await GET(`/api/v1/lease-application-stipulations/${id}`);
      setData(response?.data.data);
    } catch (e) {
      message.error("Error while loading application welcome call.")
    }
    setLoading(false)
  }

  useEffect(() => {
    getStipulations();
  }, []);

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <MainHeader activePage="leaseApplicationRelated"/>
      {
        data &&
        <ApplicationStipulationView
          data={data}
        />
      }
      <MainFooter/>
    </Spin>
  )
}
