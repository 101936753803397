import React from "react";
import { Row, Col, Table } from "antd";

const columns = [
  {
    title: 'Start Date',
    dataIndex: 'employmentStartDate',
    key: 'employmentStartDate'
  },
  {
    title: 'End Date',
    dataIndex: 'terminationDate',
    key: 'terminationDate'
  },
  {
    title: 'Employer',
    dataIndex: 'employerName',
    key: 'employerName'
  },
  {
    title: 'Position Title',
    dataIndex: 'positionTitle',
    key: 'positionTitle'
  },
  {
    title: 'Employment Status',
    dataIndex: 'employmentStatus',
    key: 'employmentStatus'
  },
  {
    title: 'Avg Hours Worked Per Pay Period',
    dataIndex: 'averageHoursWorkedPerPayPeriod',
    key: 'averageHoursWorkedPerPayPeriod'
  },
  {
    title: 'Rate of Pay',
    dataIndex: 'rateOfPay',
    key: 'rateOfPay'
  },
  {
    title: 'Pay Frequency',
    dataIndex: 'payFrequency',
    key: 'payFrequency'
  },
  {
    title: 'Pay Period Frequency',
    dataIndex: 'payPeriodFrequency',
    key: 'payPeriodFrequency'
  },
  {
    title: 'Name While Employed',
    dataIndex: 'fullName',
    key: 'fullName'
  }
];

export default function EmploymentSearch({ data }) {
  const dataSource = data.leaseApplicationBlackboxRequest.leaseApplicationBlackboxEmploymentSearches || []

  const expandedRowRender = (record) => {
    const expandedColumns = [
      {
        title: 'Compensation History',
        children: [
          { title: 'Year', dataIndex: 'year', key: 'year' },
          { title: 'Amount', dataIndex: 'amount', key: 'amount' },
        ]
      },
    ]

    return (
      <Table
        columns={expandedColumns}
        dataSource={record.compensationHistory}
        pagination={false}
        size="small"
      />
    )
  }

  return data ? (
    <>
      <Row>
        <Col span={24}>
          <Table
            className="slc-table"
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            size="small"
            expandable={{
              expandedRowRender: expandedRowRender,
              expandIconColumnIndex: columns.length,
            }}
          />
        </Col>
      </Row>
      <br/>
    </>
  ) : null ;
}
