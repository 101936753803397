import React from 'react';
import { Card, Col, Form, Row } from 'antd';
import CustomSwitch from '../components/CustomSwitch';

export default function Capabilities({ dealership, handleSubmit, allowedUpdate }) {
  const [form] = Form.useForm();

  const creditCommitteeApprovalBtn = dealership.salesApproval.approved && dealership.creditCommitteeApproval.approved && allowedUpdate;

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  return dealership ? (
    <Form
      { ...layout }
      form={ form }
      scrollToFirstError={true}
      onFinish={handleSubmit}   
      initialValues={{
        active: dealership.active,
        canSeeBanner: dealership.canSeeBanner,
        canSubmit: dealership.canSubmit,
        useEquifax: dealership.useEquifax,
        useExperian: dealership.useExperian,
        useTransunion: dealership.useTransunion
      }}
    > 
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Card className='h-100' title="Dealer Portal" >
            <CustomSwitch 
              name="active" 
              defaultChecked={dealership.active} 
              onChange={form.submit} 
              disabled={!creditCommitteeApprovalBtn} 
              label="Legacy Dealer – Can sign in"
            />
            <CustomSwitch 
              name="canSubmit" 
              defaultChecked={dealership.canSubmit} 
              onChange={form.submit} 
              disabled={!creditCommitteeApprovalBtn} 
              label="Active Dealer  – Can Submit"
            />
            <CustomSwitch 
              name="canSeeBanner" 
              defaultChecked={dealership.canSeeBanner} 
              onChange={form.submit} 
              disabled={!creditCommitteeApprovalBtn} 
              label="Can see banner message"
            />
          </Card>
        </Col>
        {/*<Col xs={24} sm={24} md={24} lg={12} xl={6}>*/}
        {/*  <Card className='h-100' title="Credco" >*/}
        {/*    <CustomSwitch */}
        {/*      name="useTransunion" */}
        {/*      defaultChecked={dealership.useTransunion} */}
        {/*      onChange={form.submit} */}
        {/*      disabled={!creditCommitteeApprovalBtn} */}
        {/*      label="Use TransUnion" */}
        {/*    />*/}
        {/*    <CustomSwitch */}
        {/*      name="useExperian" */}
        {/*      defaultChecked={dealership.useExperian} */}
        {/*      onChange={form.submit} */}
        {/*      disabled={!creditCommitteeApprovalBtn} */}
        {/*      label="Use Experian"*/}
        {/*    />*/}
        {/*    <CustomSwitch */}
        {/*      name="useEquifax" */}
        {/*      defaultChecked={dealership.useEquifax} */}
        {/*      onChange={form.submit} */}
        {/*      disabled={!creditCommitteeApprovalBtn} */}
        {/*      label="Use Equifax"*/}
        {/*    />*/}
        {/*  </Card>*/}
        {/*</Col>*/}
      </Row>
    </Form>
  ) : null;
}