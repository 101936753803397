import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import { Input } from 'antd';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import {updatePageState, clearFilterState, clearFilterIconState} from '../../reducers/LeaseApplications'
import { useDispatch } from "react-redux";
export default function SearchFilters(props) {
    const { Search } = Input;
    const { pageState, setSearchDate, dealerOptions, setFilterFlag } = props
    const [searchValue, setSearchValue] = useState(pageState.search)

    const dispatch = useDispatch();
    const onChange = (e) => {
        setSearchValue(e.target.value)
        if(e.target.value === ''){
            dispatch(updatePageState({ search: '', dealer: null, date: "all", page: 0 }))
        }
    };

    useEffect(() => {
        setSearchValue(pageState.search)
    },[pageState])

    return (
        <Box sx={{ flexGrow: 1, pb: 2 }}>
            <Grid container spacing={2} direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Grid item md={4} sm={4}>
                    <Search
                      placeholder="Global Search"
                      size="large"
                      defaultValue={pageState.search}
                      value={searchValue}
                      onChange={onChange}
                      allowClear
                      className="leaseAppListSearch"
                      onSearch={(searchVal) => {
                          dispatch(clearFilterState({}))
                          dispatch(updatePageState({ search: searchVal, dealer: null, dealerid: null, date: "all", page: 0 }))
                      }}
                      enterButton
                    />
                </Grid>
                <Grid item md={2} sm> </Grid>
                <Grid item md={6} sm={6} container spacing={1} direction="row">
                    <Grid item md={4} sm={4}> </Grid>
                    <Grid item md={4} sm={4}>
                        <FormControl fullWidth >
                            <InputLabel id="demo-simple-select-label">By Dates</InputLabel>
                            <StyledSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={pageState.date}
                                label="Dates"
                                onChange={(event) => {
                                    setSearchValue('')
                                    setSearchDate(event)
                                }}
                                size="small"
                            >
                                <StyledMenuItem value="current_month">Current Month</StyledMenuItem>
                                <StyledMenuItem value="month">Last Month</StyledMenuItem>
                                <StyledMenuItem value="quarter">Last Quarter </StyledMenuItem>
                                <StyledMenuItem value="year">This Year</StyledMenuItem>
                                <StyledMenuItem value="all">All</StyledMenuItem>
                            </StyledSelect>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} sm >
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            value={pageState.dealer}
                            options={dealerOptions}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                                setSearchValue('')
                                dispatch(updatePageState({ search: '', dealer: value, dealerid: value?.id, page: 0 }))
                                dispatch(clearFilterState({}))
                            }}
                            renderInput={(params) => <TextField {...params} size="small" label="Dealers" />}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <StyledOptionBox>{option.name}</StyledOptionBox>
                                </li>
                            )}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

const StyledMenuItem = styled(MenuItem)({
    '&.Mui-selected': {
        backgroundColor: "#cdc9c933 !important",
        textDecoration: 'underline !important',
        textUnderlineOffset: '8px !important',
        textDecorationcolor: '#e93b1b !important',
        textDecorationThickness: "2px !important",
        color: "#e93b1b !important",
    },
    "&:hover": {
        color: "#e93b1b !important",
        lineHeight: 1.5,
        textDecoration: 'underline !important',
        textUnderlineOffset: '8px !important',
        textDecorationcolor: '#e93b1b !important',
        textDecorationThickness: "2px !important",
        backgroundColor: "#cdc9c933 !important",
    },
    '&:active': {
        background: "#f1ecec!important"
    }
});

const StyledSelect = styled(Select)({
    '&:active': {
        background: "#f1ecec !important",
    }
})

const StyledOptionBox = styled(Box)({
    "&:hover": {
        color: "#e93b1b",
        lineHeight: 1.5,
        textDecoration: 'underline',
        textUnderlineOffset: '8px',
        textDecorationcolor: '#e93b1b',
        textDecorationThickness: "2px",
        fontWeight: "500"
    },
    "&:focus": {
        borderColor: "#ef4829a8 !important"
    },
});