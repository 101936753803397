import React, { useEffect } from 'react';
import MainFooter from '../../components/layouts/MainFooter';
import MainHeader from '../../components/layouts/MainHeader';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import DocusignReportsTable from './DocusignReportsTable';
import { Layout, Row, Col } from 'antd';
import Store from '../../Store';
import { fetchEnvelopes } from '../../reducers/Docusign'

const { Content } = Layout;

export default function DocusignReports() {

useEffect(()=>{
  Store.dispatch(fetchEnvelopes())
}, [])

  return (
    <Layout>
      <MainHeader activePage="leaseApplicationRelated" />
      <Layout>
        <Layout id='content-area-layout'> {
          <MainBreadcrumb items={
            [
              { text: "Home", link_type: "linkto", link: "/dashboard" },
              { text: "Docusign Reports" }
            ]}
          />}
          <Content id='main-content'>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <>
                  <DocusignReportsTable/>
                </>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
      <MainFooter />
    </Layout>
  );
}