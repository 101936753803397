import React, {useState} from 'react'

import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import {CardHeader, CardActions, CardContent, Typography} from '@mui/material';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {LicenseInfo} from '@mui/x-license-pro';
import ActivityTimeline from './ActivityTimeline';
import moment from "moment/moment";
import {Button} from "@material-ui/core";
import {PUT, POST} from "../../../util/network";
import {message, Modal} from "antd";
import logger from "../../../util/logger";
import {handleNoAuth} from "../../../util/authenticate";
import {
  getDealershipActivities,
  fetchDealershipActivities,
  setTimeLineActivities
} from '../../../reducers/DealershipActivities'
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {Switch} from "antd";
import {FormGroup} from "semantic-ui-react";
import Tooltip from '@mui/material/Tooltip';
import DialogModal from "../../LeaseApplications/DialogModal";
import IconButton from '@mui/material/IconButton';
import AssignmentReturnedSharpIcon from '@mui/icons-material/AssignmentReturnedSharp';
import {Link} from "react-router-dom";
import ContactListPage from "./ContactListPage";

LicenseInfo.setLicenseKey('06c2f0cf50adbcfcc3f1fdcdf8402d39Tz00OTMwMCxFPTE2OTIzNzY1MTM3ODUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');


const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-cell': {
      fontWeight: '500',
    },
    '& .MuiDataGrid-colCellTitle': {
      fontWeight: 'bold',
      fontFamily: 'Roboto',
    },
    '& .MuiChip-root': {
      fontWeight: '500',
      borderRadius: '4px',
      padding: '4px',
    },
    '& .MuiChip-label': {
      fontSize: '0.8rem',
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: "#91908d24 !important"
    }
  },
  chipActive: {
    backgroundColor: '#756b6b12',
    color: '#4caf50',
    borderRadius: "21px",
    border: "none"
  },
  chipInactive: {
    backgroundColor: '#756b6b12',
    color: '#f44336',
    borderRadius: "21px",
    border: "none"
  },
  firstCard: {
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)'
  }
});

export default function ActivityHeaderCard(props) {
  const {activityTimelines, dealership, id, contacts} = props
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [status, setStatus] = useState('');
  const [recordId, setRecordId] = useState(null);
  const [showContacts, setShowContacts] = useState(false);
  const history = useHistory();
  const auth_token = window.localStorage.getItem('auth_token');
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (record) => {
        return <a href={`/dealer-users/${record.id}`} rel="noreferrer">{record.row.name}</a>
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      renderCell: (record) => {
        const color = record.row?.is_disabled ? 'red' : 'green'
        return <p style={{color: color}}>{record.row?.is_disabled ? 'Inactive' : 'Active'}</p>
      },
    },
    {
      field: 'changeStatus',
      headerName: 'Change Status',
      type: "action",
      flex: 0.5,
      renderCell: (record) => {
        const newStatus = record.row?.is_disabled ? 'Active' : 'Inactive'
        const onClick = async (e) => {
          e.stopPropagation(); // don't select this row after clicking
          try {
            setOpen(true);
            setRecordId(record.id);
            setStatus(newStatus);
          } catch (e) {
            logger.error("Error on status change.", e);

            if (e && e.response && e.response.status === 401) {
              handleNoAuth(history);
            } else {
              message.error('Error on status change.');
            }
          }
        };
        return <Button
          style={{
            backgroundColor: newStatus === 'Active' ? 'green' : '#FF5733',
            color: 'white',
            width: '70px',
            fontSize: '10px'
          }}
          onClick={onClick}>{newStatus}
        </Button>
      }
    },
    {
      field: "last_sign_in_at",
      headerName: "Last Sign In At",
      type: "string",
      flex: 1.25,
      minWidth: 75,
      renderCell: (record) => {
        return record.row.last_sign_in_at === null
          ? ""
          : moment(record.row.last_sign_in_at).format("MMMM DD, YYYY HH:mm:ss");
      },
    },
  ];

  const changeStatus = async () => {
    try {
      console.log("Printing status: ", status);
      const response = await PUT(`/api/v1/dealer-users/${recordId}/change_status`, {
        is_disabled: status !== 'Active'
      });

      dispatch(getDealershipActivities({response: response.data}))
      message.success('Successfully changed status.');
    } catch (e) {
      logger.error("Error on status change.", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error on status change.');
      }
    }
  };

  const changeTimelineSetting = (event) => {
    dispatch(setTimeLineActivities({timeline_app_setting: event}));
    dispatch(fetchDealershipActivities({id: id, timeline_app_setting: event}))
  }

  const closeDialog = () => {
    setOpen(false);
  }

  const closeExportModal = () => {
    setExportModalOpen(false);
  }

  const completeDeletion = () => {
    closeDialog();
    changeStatus();
  }

  const completeActivityExport = () => {
    closeExportModal()
    exportActivityList()
  }

  const losPath = (path) => {
    return `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=${path}`
  }

  const exportActivityList = async () => {
    try {
      message.success("Dealership activity CSV and file will be downloaded in a minutes")
      let response = await POST(`/api/v1/dealerships/${id}/export_activities`)
      let url = losPath(response.data)
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history)
      } else {
        message.error('Error while Exporting Dealership activity')
      }
    }
  }

  const expirationDate = (dateTime) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }).format(dateTime) || null;
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleCloseModal = () => {
    setShowContacts(false);
  };

  return <>
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item md={4} sm={4}>
        <Card className={classes.firstCard}>
          <CardContent style={{paddingBottom: '15px'}}>
            <Grid container
                  spacing={2} direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end">
              <Grid item md={12}>
                <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                  <h3 style={{marginRight: '20%'}}>{dealership?.name}</h3>
                  <Tooltip title="App timeline hide">
                    <Switch
                      defaultChecked={dealership.timeline_app_setting}
                      checked={dealership.timeline_app_setting}
                      onChange={e => changeTimelineSetting(e)}
                    />
                  </Tooltip>
                </FormGroup>
              </Grid>
            </Grid>
            <p style={{margin: "0px"}}>
              <span>{dealership?.address?.street + ' '}</span>
              <span>{dealership?.address?.city + ' '}</span>
              <span>{dealership?.address?.state + ' '}</span>
              <span>{dealership?.address?.zipcode + ' '}</span>
            </p>
            <p style={{color: "green", margin: "0px"}}>
              <span>{dealership?.phone}</span>
            </p>
            <p style={{margin: "0px"}}>
              <Link href="#" onClick={() => openInNewTab(dealership?.website)}>{dealership?.website}</Link>
            </p>
            <p style={{margin: "0px"}}>
              {dealership?.license_number && <>
                <span style={{fontWeight: 'bold'}}>{`License number: `}</span>
                <span>{dealership?.license_number}</span>
              </>
              }
            </p>
            <p style={{margin: "0px"}}>
              {dealership?.license_expiration_date && <>
                <span style={{fontWeight: 'bold'}}>{`License expiration date: `}</span>
                <span>{expirationDate(new Date(dealership?.license_expiration_date))}</span>
              </>
              }
            </p>
          </CardContent>
        </Card>
        <Grid style={{marginTop: "5px"}}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Grid item md={12}>
              <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                <h3 style={{marginLeft: '5%', marginRight: '30%'}}>Activity Timeline</h3>
                <Tooltip title={<h5>Export to CSV</h5>}>
                  <IconButton
                    style={{color: "#e93b1b", fontSize: "30px", margin: "0px"}}
                    onClick={() =>
                      setExportModalOpen(true)
                    }>
                    <AssignmentReturnedSharpIcon/>
                  </IconButton>
                </Tooltip>
              </FormGroup>
            </Grid>
          </Grid>
          <ActivityTimeline activityTimelines={activityTimelines}/>
        </Grid>
      </Grid>
      <Grid item md={8} sm={8} className={classes.gridElement}>
        <Card className={classes.firstCard}>
          <CardHeader
            title="Dealer Users"
            action={
              <CardActions>
                <Button style={{backgroundColor: "#e93b1b", color: 'white'}} onClick={() => {
                  history.push({
                    pathname: `/dealerships/${id}`,
                  });
                }} type="primary">
                  Details Page
                </Button>
                <Button style={{backgroundColor: "#e93b1b", color: 'white'}} onClick={() => {
                  history.push({
                    pathname: `/dealer-users/create`,
                    state: {
                      actionType: "CREATE",
                    },
                  });
                }} type="primary">
                  Create Dealer User
                </Button>
              </CardActions>
            }
          />
          <Grid container spacing={2}>
            <Grid style={{height: 550, width: "98%", paddingLeft: '15px'}}>
              <DataGridPro rows={dealership?.dealers} columns={columns} className={classes.root}/>
            </Grid>
          </Grid>
        </Card>
        <Grid style={{marginTop: '15px', display: 'flex', justifyContent: 'flex-end'}}>
          <Button style={{backgroundColor: "#e93b1b", color: 'white'}} onClick={() => setShowContacts(!showContacts)}>
            {showContacts === false ? 'Contacts +' : 'Contacts -'}
          </Button>
        </Grid>
      </Grid>
      <Grid item md={12} sm={12} style={{display: showContacts === false ? 'none' : ''}}>
        <ContactListPage dealershipId={id} contacts={contacts}/>
      </Grid>
      {/*<Modal*/}
      {/*  title="Contacts"*/}
      {/*  visible={modalOpen}*/}
      {/*  centered*/}
      {/*  width={"90VW"}*/}
      {/*  onCancel={handleCloseModal}*/}
      {/*  footer={[*/}
      {/*    <Button key="Cancel" onClick={handleCloseModal}>*/}
      {/*      Cancel*/}
      {/*    </Button>*/}
      {/*  ]}*/}
      {/*  style={{*/}
      {/*    top: 20,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <ContactListPage/>*/}
      {/*</Modal>*/}
      <DialogModal
        modalState={open}
        modalClose={closeDialog}
        submitFunction={completeDeletion}
        contentData={""}
        title={`Are you sure you want to ${status.toLowerCase()} this dealer ?`}
      />
      <DialogModal
        modalState={exportModalOpen}
        modalClose={closeExportModal}
        submitFunction={completeActivityExport}
        contentData={""}
        title={`Do you want to export activity of this dealership ?`}
      />
    </Grid>
  </>
}
