import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Spin, Layout, message, Menu, Collapse, Result } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { GET, POST } from '../../util/network';
import logger from '../../util/logger';
import MainHeader from '../../components/layouts/MainHeader';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import MainFooter from '../../components/layouts/MainFooter';
import { SiderProps } from '../../components/layouts/MainSiderProps';
import Organization from './Organization/Organization';
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';

const { Content, Sider } = Layout;
const { Panel } = Collapse;

const Menus = [
  {
    label: 'Organization',
    componentLabel: null,
    iconType: <InfoCircleOutlined />,
    Component: Organization,
    menuType: 'parent',
    isAllowedToShow: true,
    menuKey: 1,
  }
]

export default function NewDealership(props) {
  const { allowedActions, handleNoAuth } = props;

  const dealershipActions = GetResourceActions('Dealership', allowedActions);
  const allowedCreate = IsActionAllowed('create', dealershipActions);
  
  const history = useHistory();
  const [loading, setLoading] = useState(null);

  const [dealerRepresentatives, setDealerRepresentatives] = useState();  

  const [activeMenu, setActiveMenu] = useState([1]);
  const activeMenuList =  Menus.filter(menu => menu.isAllowedToShow);
  const mainMenuList =  Menus.filter(menu => menu.menuType === 'parent');

  const save = async (values) => {
      setLoading(true);

      try {
        const response = await POST(`/api/v1/dealerships/`, values);
        message.success("Data saved successfully");
        history.push(`/dealerships/${response.data.id}`);
      } catch (e) {
        logger.error("Dealership Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while saving your data');
        }        
      }

      setLoading(false);
  };

  const handleSubmit = async (values) => {
    if (allowedCreate) {
      values = { ...values };
      save(values);
    } else {
      message.warning("You are not allowed to create a dealership");
    }
  };

  const getActiveDealerRepresentatives = async () => {
    try {
      const response = await GET(`/api/v1/dealer_representatives/active`);      
      setDealerRepresentatives(_.sortBy(response.data.dealerRepresentatives, ['firstName']));
    } catch (e) {
      logger.error("Dealer representatives Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while fetching Dealer representatives');
      }
    }
  };

  useEffect(() => {
    getActiveDealerRepresentatives();    
  }, []);

  return (
    <Spin 
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      <Layout className="layout">
        <MainHeader activePage="dealers"/>
        <Layout name='Dealership Edit' id='#dealership-edit'>
          <Sider {...SiderProps} >
            <Menu
              defaultSelectedKeys={[1]}
              selectedKeys={[activeMenu.toString()]}
              style={{ width: '100%' }}
              onSelect={({ key }) => { if (!key.includes("sub")){ setActiveMenu([...activeMenu, key]); } }}
            >
              {
                mainMenuList.map(({ label, iconType, menuKey, isAllowedToShow }) => {
                  return (                      
                    !isAllowedToShow ? null : (
                      <Menu.Item
                        key={(menuKey).toString()}
                      > 
                        <a href={`#${label}-p`}>
                          {iconType}
                          {label}
                        </a>
                      </Menu.Item>
                    )
                  )
                })
              }           
            </Menu>
          </Sider>

          <Layout id='content-area-layout'>
            <MainBreadcrumb items={
              [{ text: "Home", link_type: "linkto", link: "/dashboard" },
              { text: "Dealerships", link_type: "linkto", link: "/dealerships" },
              { text: "New Dealership" }]
              }
            />

            <Content id='main-content'>
              <Row gutter={[0, 24]} >
                <Col span={24} >
                  <>
                    {
                    allowedCreate
                      ?
                        <Collapse accordion={false} onChange={(key) => { key !== undefined && setActiveMenu(key); }} activeKey={activeMenu}>
                          {
                            activeMenuList.map(({ label, componentLabel, Component, menuKey, collapseRef }) => {
                              label = componentLabel ? componentLabel : label;
                              return (
                                <Panel 
                                  className='slc-panel'
                                  header={ label } 
                                  key={menuKey} 
                                  ref={collapseRef} 
                                  id={`${label}-p`}
                                >
                                <Component 
                                  allowedUpdate={ allowedCreate }
                                  dealerRepresentatives={ dealerRepresentatives }
                                  handleNoAuth={ handleNoAuth }
                                  handleSubmit={ handleSubmit }
                                  setLoading={ setLoading }
                                />
                              </Panel>
                              )
                            })
                          }
                        </Collapse>
                      :
                         <Result 
                          status='warning'
                          title='You are not allowed to access this page.'
                        /> 
                    }
                  </>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter/>
      </Layout>
    </Spin> 
  );
}