import React from "react";
import { Select, Row, Col, Form } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

export default function CustomSelect({label, required, ruleMessage, name, mode, onChange, onSelect, options, additionalProps, tooltipText}) {
  return (
    <Row>
      <Col span={24}> 
        <Form.Item 
          label={label} 
          hasFeedback
          name={name}
          rules={[{ required: required, message: ruleMessage }]}
          tooltip=
            {
              tooltipText ?
                {
                  title: tooltipText,
                  icon: <InfoCircleOutlined/>,
                }
              :
                undefined
            }
        >  
          <Select mode={mode} style={{ width: '100%' }} placeholder={label} onChange={onChange} onSelect={onSelect} {...additionalProps} >
            {
                options && options.map(({optionValue, optionLabel}, index) => {
                  return <Option key={index} value={`${optionValue}`}>{optionLabel}</Option>
                })
              }
         </Select>
        </Form.Item>
      </Col>
    </Row> 
  );
}