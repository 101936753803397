import React, { useState, useEffect } from 'react'
import {
  Card,
  Form,
  Col,
  Input,
  DatePicker,
  Checkbox,
  Button,
  Row,
  InputNumber,
  Select
} from "antd";
import isEmail from 'validator/lib/isEmail';
import MaskedInput from 'antd-mask-input';

import moment from 'moment'

export default function InsuranceInformation({ data, disableFlag, save }) {
  const [form] = Form.useForm();  
  const { 
      companyName,
      propertyDamage,
      bodilyInjuryPerPerson,
      bodilyInjuryPerOccurrence,
      comprehensive,
      collision,
      effectiveDate,
      expirationDate,
      policyNumber,
      lossPayee,
      additionalInsured,
      status,
      insuranceType,
      agent,
      phone,
      fax,
      email
  } = data.insurance ? data.insurance : [];
  const [eMailValidateStatus, setEmailValidateStatus] = useState(undefined)
  const[customSelectValue, setCustomSelectValue] = useState('')
  const [effectDate, setEffectDate] = useState(effectiveDate && moment(effectiveDate, 'YYYY-MM-DD'))
  const onFinishFailed = ({ errorFields }) => {
      form.scrollToField(errorFields[0].name);
  };

  const onFinish = async (values) => {
      values = { ...values};
      const myvalues = values
      myvalues.companyName = customSelect && customSelectValue !== '' ? customSelectValue : values.companyName
      await save({
          insuranceAttributes: myvalues
      });
  }

  const handleLesseeEmailChange = (event) => {
    const email = event.target.value;

    if(email !== undefined) {
      if (!isEmail(email) && email.length > 0) {
        setEmailValidateStatus('error');
      } else {
        setEmailValidateStatus(undefined);
      }
    }
  }

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const validateDateDifference = (_, value) => {
    if (!value) return Promise.resolve();

    const expirationDate = moment(value);

    if (expirationDate.diff(effectDate, 'days') <= 30) {
      return Promise.reject(new Error('The expiration date should be more than 30 days.'));
    }

    return Promise.resolve();
  };

  const Option = Select.Option;

  const insuranceTypeOptionsArray = [
    '10/20/10',
    '10/50/10',
    '15/30/5',
    '15/25/20',
    '15/25/50',
    '15/30/25',
    '25/50/15',
    '25/50/20',
    '25/50/25',
    '25/50/50',
    '30/10/15',
    '30/25/30',
    '30/60/15',
    '30/60/20',
    '30/60/25',
    '50/60/60',
    '60/30/25',
    '10/300/50',
    '10/300/100',
    '25/50/100',
    '30/60/100',
    '50/50/100',
    '50/100/25',
    '50/100/50',
    '50/100/300',
    '50/300/50',
    '100/30/50',
    '100/300/10',
    '100/300/15',
    '100/100/300',
    '100/300/100',
    '100/300/20',
    '100/300/50',
    '100/50/25',
    '250/500/10',
    '250/500/100',
    '250/500/250',
    '300/300/10',
    '300/300/100',
  ]

  const insuranceCompanyArray = [
    'AAA Insurance',
    'Alfa',
    'All State',
    'Alliance Insurance',
    'Allied Insurance',
    'Allstate',
    'Allstate Insurance',
    'Amer States Ins Tx',
    'AMERICA STATES INSURANCE',
    'American Adventure Insurance',
    'American Automobile Association',
    'American Family Insurance',
    'American Modern Insurance',
    'American Modern Select',
    'American National Insurance Company',
    'Auto-Owners Insurance',
    'Bynum Insurance',
    'CELINA INSURANCE',
    'Cincinnati Insurance Company',
    'Colonial County Mutual Insurance Company',
    'Colonial Life & Accident Insurance Company',
    'Culver Insurance',
    'Dairyland',
    'Direct Insurance Agrency',
    'Erie Insurance Group',
    'Esurance',
    'Farm Bureau',
    'Farm Bureau Insurance',
    'FARM BUREAU MUTUAL INSURANCE',
    'Farmers Bureau',
    'Farmers Insurance Group',
    'Foremost',
    'Geico',
    'Grange Mutual Casualty Company',
    'Harley-Davidson Insurance',
    'I Go Auto Insurance',
    'INDIANA FARM BEREAU INSURANCE',
    'Indiana Farm Bureau',
    'Indiana Farm Bureau Insurance',
    'Indiana Farmers Mutual',
    'Integon National Insurance',
    'Insgroup',
    'INTEGON',
    'Integon National',
    'Interinsurance Exchange of the Automobile Club',
    'Johnson & Johnson',
    'Liberty Mutual',
    'LM General Insurance',
    'Markel Corporation',
    'MetLife',
    'Metro',
    'Miami Mutual Insurance Company',
    'Mississippi Farm Bureau',
    'Nagionwide',
    'National General',
    'National General Insurance',
    'Nationwide Mutual Insurance Company',
    'Nationwide Property & Casual',
    'NC Farm Bureau',
    'NC Farm BureaunMutual',
    'North Carolina FARM BUREAU',
    'North Crolina Farm Bureau Mutual Insurance',
    'Pacific Life',
    'Pacific Specialty',
    'Pacific Specialty Insurance',
    'Peak Property & Casualty Ins',
    'Peak Property & Casualty Insurance Co.',
    'Peak Property and Casualty',
    'Pekin Insurance',
    'Primerica',
    'Progressive',
    'Rider',
    'Rider Insurance',
    'Safe Auto Insurance Company',
    'Safeco',
    'SC Farm Bureau',
    'Sentry Insurance',
    'Standard Insurance Company',
    'State Auto Insurance Group',
    'State Farm Auto Insurance Group',
    'State Farm Insurance',
    'Superior Insurance of Lexington',
    'Tennessee Farmers Mutual',
    'The American Modern',
    'United Farm Family',
    'Viking Ins Co. of Wisconsin',
    'Vikings Insurance',
    'Westfield Insurance',
    'Wolverine Insurance',
    'Peak Property & Casualty Insurance',
    'Shelter Insurance'
]

  const selectInsuranceType = () => {
    const propertyDamage = form.getFieldValue(['propertyDamage']);
    const bodilyInjuryPerPerson = form.getFieldValue(['bodilyInjuryPerPerson']);
    const bodilyInjuryPerOccurrence = form.getFieldValue(['bodilyInjuryPerOccurrence']);

    if (propertyDamage !== null && bodilyInjuryPerPerson !== null && bodilyInjuryPerOccurrence !== null) {
      const firstTwoDigitOfPropertyDamage = String(propertyDamage).substring(0, 2);
      const firstTwoDigitOfBodilyInjuryPerPerson = String(bodilyInjuryPerPerson).substring(0, 2);
      const firstTwoDigitOfBodilyInjuryPerOccurrence = String(bodilyInjuryPerOccurrence).substring(0, 2);

      const typeOptionSearchString1 = firstTwoDigitOfPropertyDamage + '/' + firstTwoDigitOfBodilyInjuryPerPerson + '/' + firstTwoDigitOfBodilyInjuryPerOccurrence
      const typeOptionSearchString2 = firstTwoDigitOfPropertyDamage + '/' + firstTwoDigitOfBodilyInjuryPerOccurrence + '/' + firstTwoDigitOfBodilyInjuryPerPerson

      if (insuranceTypeOptionsArray.includes(typeOptionSearchString1)) {
        form.setFieldsValue({ insuranceType: typeOptionSearchString1 })
      } else if (insuranceTypeOptionsArray.includes(typeOptionSearchString2)) {
        form.setFieldsValue({ insuranceType: typeOptionSearchString2 })
      } else {
        form.setFieldsValue({ insuranceType: '' })
      }
    }
  }

  const [customSelect, setCustomSelect] = useState(false)
  const handleCustomSelect = (value)=>{
    if(value === 'custom') {
      setCustomSelect(true)
    } else {
      setCustomSelect(false)
    }
  }

  let isCompanyNameAvailable = insuranceCompanyArray.includes(companyName)

  useEffect(() => {
    if (!isCompanyNameAvailable) {
      setCustomSelect(true)
      setCustomSelectValue(companyName)
    }
  }, [isCompanyNameAvailable, setCustomSelectValue])

 

  return !data.insurance ? null : (
    <Form
      {...layout}
      form={form}
      onFinish={onFinish} 
      onFinishFailed={onFinishFailed}
      initialValues={{ 
        companyName: !isCompanyNameAvailable ? "custom" : companyName,
        propertyDamage: propertyDamage && parseFloat(propertyDamage.replace(/,/g, '')),
        bodilyInjuryPerPerson: bodilyInjuryPerPerson && parseFloat(bodilyInjuryPerPerson.replace(/,/g, '')),
        bodilyInjuryPerOccurrence: bodilyInjuryPerOccurrence && parseFloat(bodilyInjuryPerOccurrence.replace(/,/g, '')),
        comprehensive: comprehensive && parseFloat(comprehensive.replace(/,/g, '')),
        collision: collision && parseFloat(collision.replace(/,/g, '')),
        effectiveDate: effectiveDate && moment(effectiveDate, 'YYYY-MM-DD'),
        expirationDate: expirationDate && moment(expirationDate, 'YYYY-MM-DD'),
        policyNumber: policyNumber,
        lossPayee: lossPayee,
        additionalInsured: additionalInsured,
        status: status || "Active",
        insuranceType: insuranceType,
        agent: agent,
        phone: phone,
        fax: fax,
        email: email
      }}
    >
      <Col span={24}>
        <Card>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item label="Company Name" name="companyName" rules={[{
                required: true,
                message: "This field is required"
              }]}>
                <Select style={{ width: "100%" }} onChange={handleCustomSelect} placeholder="Please Select" disabled={disableFlag}>
                <Option key={0} value={"custom"}>{"Input Custom Company Name"}</Option>

                  {
                    insuranceCompanyArray.map((value, key) => {
                      return <>
                      <Option key={key} value={value}>{value}</Option>
                      </> 
                    })
                  }
                </Select>
                {/* <Input disabled={disableFlag}/> */}
              </Form.Item>
              {customSelect && (<Input value={customSelectValue}  onChange={(e)=>{setCustomSelectValue(e.target.value)}} placeholder="Insurance Company Name" disabled={disableFlag}/>)}

            </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Form.Item label="Property Damage" name="propertyDamage" rules={[{
                      required: true,
                      message: "This field is required"
                  }]}>
                      <InputNumber
                          initialValue={propertyDamage}       
                          formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          precision={2}
                          style={{width: `100%`}}
                          disabled={disableFlag}
                          onBlur={selectInsuranceType}
                      />
                  </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Form.Item label="Bodily Injury Per Person" name="bodilyInjuryPerPerson" rules={[{
                      required: true,
                      message: "This field is required"
                  }]}>
                      <InputNumber
                          initialValue={bodilyInjuryPerPerson}       
                          formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          precision={2}
                          style={{width: `100%`}}
                          disabled={disableFlag}
                          onBlur={selectInsuranceType}
                      />
                  </Form.Item>
              </Col>

          </Row>
          <Row gutter={[32, 0]}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Form.Item label="Bodily Injury Per Occurence" name="bodilyInjuryPerOccurrence" rules={[{
                      required: true,
                      message: "This field is required"
                  }]}>
                      <InputNumber
                          initialValue={bodilyInjuryPerOccurrence}       
                          formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          precision={2}
                          style={{width: `100%`}}
                          disabled={disableFlag}
                          onBlur={selectInsuranceType}
                      />
                  </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Form.Item label="Comprehensive" name="comprehensive" rules={[{
                      required: true,
                      message: "This field is required" 
                  }]}>
                      <InputNumber
                          initialValue={comprehensive}       
                          formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          precision={2}
                          style={{width: `100%`}}
                          disabled={disableFlag}
                      />
                  </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Form.Item label="Collision" name="collision" rules={[{
                      required: true,
                      message: "This field is required"
                  }]}>
                      <InputNumber
                          initialValue={collision}       
                          formatter={value => `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          precision={2}
                          style={{width: `100%`}}
                          disabled={disableFlag}
                      />
                  </Form.Item>
              </Col>

          </Row>
          <Row gutter={[32, 0]}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item label="Effective Date" name="effectiveDate" rules={[{
                  required: true,
                  message: "This field is required"
                }]}>
                  <DatePicker format="MM-DD-YYYY" style={{ width: "100%" }} disabled={disableFlag} onChange={(date)=>setEffectDate(date)} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                <Form.Item label="Expiration Date" name="expirationDate" rules={[
                  { required: true, message: "This field is required" },
                  { validator: validateDateDifference },
                ]} >
                  <DatePicker format="MM-DD-YYYY" style={{ width: "100%" }} disabled={disableFlag} />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Form.Item label="Policy Number" name="policyNumber" rules={[{
                      required: true,
                      message: "This field is required"
                  }]}>
                      <Input disabled={disableFlag}/>
                  </Form.Item>
              </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item label="Status" name="status">
                {/* <Input disabled={disableFlag}/> */}
                <Select style={{ width: "100%" }} placeholder="Please Select" disabled={disableFlag}>
                  <Option key="1" value="Active">Active</Option>
                  <Option key="1" value="Inactive">Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item label="Type" name="insuranceType">
              <Select style={{ width: "100%" }} placeholder="Please Select" disabled={disableFlag}>
                          {
                              insuranceTypeOptionsArray.map((value, key) => {
                                  return <Option key={key} value={value}>{value}</Option>
                              })
                          }
                      </Select>
                {/* <Input disabled={disableFlag}/> */}
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item label="Agent" name="agent">
                <Input disabled={disableFlag}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item label="Phone" name="phone">
                <MaskedInput mask="(111) 111-1111" disabled={disableFlag} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item label="Fax" name="fax">
                <MaskedInput mask="(111) 111-1111" disabled={disableFlag} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Form.Item label="Email" name="email" validateStatus={eMailValidateStatus}>
                <Input
                  type='email'
                  onPressEnter={(e) => handleLesseeEmailChange(e)}
                  onBlur={(e) => handleLesseeEmailChange(e)}
                  disabled={disableFlag}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className='flex-jc-fs' gutter={[32, 0]}>
              <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Form.Item valuePropName="checked" name="lossPayee">
                      <Checkbox className='checkbox-required-checked' disabled={disableFlag}>Loss Payee?</Checkbox>
                  </Form.Item>
                  <Form.Item valuePropName="checked" name="additionalInsured">
                      <Checkbox className='checkbox-required-checked' disabled={disableFlag}>Additional Insured</Checkbox>
                  </Form.Item>
              </Col>
          </Row>
          <Row className='flex-jc-fs'>
              <Button type="primary" htmlType="submit" disabled={disableFlag}>Save</Button>
          </Row>
        </Card>
      </Col>
    </Form>
  );
}