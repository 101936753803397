import React from "react";
import { Col, Row, Statistic, Card } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const DocusignReportsSummary = (data) => {
  return (
    <>
      <div className="site-statistic-demo-card" style={{ padding: "30px", background: '#ececec' }}>
        <Row gutter={24}>
          <Col span={6}>
            <Card>
              <Statistic
                title="Total Envelopes Sent"
                value={data?.docusign_summary_reports?.total_sent}
                valueStyle={{
                  color: '#3f8600',
                }}
                prefix={<ArrowUpOutlined />}
                suffix=""
                height="200px"
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Total Lease Application/Docs Generated"
                value={data?.docusign_summary_reports?.total_lease_apps}
                valueStyle={{
                  color: '#3f8600',
                }}
                prefix={<ArrowUpOutlined />}
                suffix=""
                height="200px"
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Total Envelopes Completed"
                value={data?.docusign_summary_reports?.total_completed}
                valueStyle={{
                  color: '#3f8600',
                }}
                prefix={<ArrowDownOutlined />}
                suffix=""
                height="200px"
              />
            </Card>
          </Col>
          <Col span={6}>
            <Card>
              <Statistic
                title="Total Envelopes Voided"
                value={data?.docusign_summary_reports?.total_voided}
                valueStyle={{
                  color: '#cf1322',
                }}
                prefix={<ArrowDownOutlined />}
                suffix=""
                height="200px"
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default DocusignReportsSummary;