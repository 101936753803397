import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET, POST } from '../util/network';

export const fetchDealershipActivities = createAsyncThunk('dashboard/fetchDealerActivitiesData', async (params) => {
  let timeline_app_setting = params?.timeline_app_setting === undefined ? null : params?.timeline_app_setting;
  try {
    const response = await GET(`/api/v1/dealerships/${params.id}/activities?date_range=${params?.option}&time_filter=${params?.time_filter || ''}&timeline_app_setting=${timeline_app_setting}`);
    return response.data;
  } catch (e) {
    console.log(e)
  }
})

const initialState = {
  activities: [
    { label: 'Apps Submitted', count: 0 },
    { label: 'Funded Contracts', count: 0 },
    { label: 'Look to books', count: 0 },
    { label: 'Conversion Rate', count: 0 },
    { label: 'Approval Rates', count: 0 },
    { label: 'Decline Rates', count: 0 },
    { label: 'Docs Issued', count: 0 },
    { label: 'Lease package Received', count: 0 },
    { label: 'Funding Delay', count: 0 },
    { label: 'Funding Approved', count: 0 },
  ],
  activityTimelines: [],
  adminUsers: [],
  contacts: [],
  currentAdminUser: null,
  scheduledCalls: [],
  LogCalls: [],
  reminders: [],
  emails: [],
  dealership: {
    address: {},
    dealers: [],
    name: '',
    timeline_app_setting: false
  },
};

export const DealershipActivitiesSlice = createSlice({
  name: "DealershipActivities",
  initialState: initialState,
  reducers: {
    getDealershipActivities: (state, action) => {
      state  = action.payload.response
      return state
    },
    setTimeLineActivities: (state, action) => {
      state.dealership.timeline_app_setting = action.payload.timeline_app_setting
      return state
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchDealershipActivities.fulfilled, (state, action) => {
      state.activities = action.payload.activities
      state.activityTimelines = action.payload.activityTimelines
      state.currentAdminUser = action.payload.currentAdminUser
      state.adminUsers = action.payload.adminUsers
      state.contacts = action.payload.contacts
      state.dealership = action.payload.dealership
      state.scheduledCalls = action.payload.scheduledCalls
      state.LogCalls = action.payload.LogCalls
      state.reminders = action.payload.reminders
      state.emails = action.payload.emails
      state.isLoading = false;
      return state
    }),
      builder.addCase(fetchDealershipActivities.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchDealershipActivities.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload;
      });
  }
});
export const { getDealershipActivities, setTimeLineActivities } = DealershipActivitiesSlice.actions;
export default DealershipActivitiesSlice.reducer;
