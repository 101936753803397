import React, { useState } from 'react';
import '../Signin.css';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';
import { POST } from '../../util/network';

function ForgetPassword() {
    const [email, setEmail] = useState('');
    const [btnDisable, setBtnDisable] = useState(true);
    const [notice, setNotice] = useState('');
    const [errorMsg, setErrorMsg] = useState(''); 

    const handleSubmit = async () => {
        setBtnDisable(true);
        let params = {
            email: email
        }
        try {
            const response = await POST('/api/v1/reset-pass-request', params)
            setNotice(response.data?.message || '')
        } catch (error) {
            setBtnDisable(false);
            setErrorMsg(error.response?.data?.message || '');
        }
    };

    const handleDataChange = (data) => {
        setEmail(data.target.value);
        setBtnDisable(false);
    };

    return (
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column id='login-column'>
                <Header as='h3' style={{ color: '0F1419', marginTop: '10px' }} textAlign='center'>
                    Speed Leasing Admin Portal
                </Header>
                { notice &&
                (<span style={{ color: 'green', fontWeight: 'bold' }}> {notice} </span>) 
                }
                { errorMsg &&
                (<span style={{ color: 'red' }}> {errorMsg} </span>) 
                }
                <Form size='large' onSubmit={handleSubmit}>
                    <Segment stacked>
                        <Form.Input
                            fluid
                            icon='user'
                            iconPosition='left'
                            name="email"
                            placeholder='E-mail address'
                            value={email}
                            onChange={handleDataChange}
                        />

                        <Button
                            id='reset-button'
                            disabled={btnDisable}
                            primary
                            fluid
                            size='large'
                            type='submit'
                        >
                            Submit
                        </Button>
                    </Segment>
                </Form>
                <Link to='/admins/login' id='link-underline'>Back to Log in</Link>
            </Grid.Column>
        </Grid >
    )
}

export default ForgetPassword;

