import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import { PoweroffOutlined, ImportOutlined } from '@ant-design/icons';
import './styles/CalculatorHead.css';
import Logo from './Logo';
import { useHistory } from 'react-router-dom';

const { Header } = Layout

const CalculatorHead = (props) => {
  const { calculateWithoutApp } = props
  const history = useHistory();
  return (
    <Header>
    <div className='wrapper'>
      <Logo/>
      {/* history.goBack() */}
      <div className="return-to-home icon-wrapper">
          {/* <Link className="steps-exit-btn" to ={``} style={{ color: '#e93b1b' }}><ImportOutlined />Back To Home</Link> */}
      </div>
      {
          calculateWithoutApp === false && <div className="icon-wrapper">
            <a onClick={()=>{history.goBack()}} href={`#`} className="steps-exit-btn" style={{float:"right", color: '#e93b1b'}}>
              <PoweroffOutlined />
              Exit
            </a>
          </div>
      }
    </div>
  </Header>
  )
}
export default CalculatorHead