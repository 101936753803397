import React from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import PublishSharpIcon from '@mui/icons-material/PublishSharp';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import PaidSharpIcon from '@mui/icons-material/PaidSharp';
import BookmarkAddedSharpIcon from '@mui/icons-material/BookmarkAddedSharp';
import TransformSharpIcon from '@mui/icons-material/TransformSharp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';

const StyledFormControlLabel = styled(FormControlLabel)({
    float: "right",
    fontWeight: "600 !important",
    '&  .MuiFormControlLabel-label': {
        fontSize: '14px',
        fontWeight: 600
    }
})

const StyledExportIcon = styled(IconButton)({
    float: "right",
    marginRight: "15px"
})

const StyledClearFilter = styled(Chip)({
    float: "left",
    marginLeft: 12,
    fontWeight: "600 !important",
    '&  .MuiFormControlLabel-label': {
        fontSize: '14px',
        fontWeight: 600
    }
})

const StyledChipApproved = styled(Chip)({
    borderRadius: "5px !important",
    height: "22px !important",
    marginLeft: '10px',
})


const StyledChipLookToBook = styled((props) => (
    <Chip
        {...props}
    />
))(({ theme }) => ({
    borderRadius: "5px !important",
    height: "22px !important",
    [theme.breakpoints.down(1025)]: {
        position: "relative",
    }
}))

const StyledChipConversionRate = styled((props) => (
    <Chip
        {...props}
    />
))(() => ({
    borderRadius: "5px !important",
    height: "22px !important",
}))

const StyledPublishSharpIcon = styled((props) => (
    <PublishSharpIcon
        {...props}
    />
))(({ theme }) => ({
    [theme.breakpoints.up(1025)]: {
        position: "relative",
        top: "6px",
        left: "40px",

    },
    [theme.breakpoints.only(1170)]: {
        position: "relative",
        top: "6px",
        left: "34px",

    },
    [theme.breakpoints.down(1025)]: {
        position: "relative",
        top: "6px",
        left: "12px"
    },
    [theme.breakpoints.down(1024)]: {
        display: "none !important"
    },
    [theme.breakpoints.only(1170)]: {
        position: "relative",
        top: "6px",
        left: "34px",
    },
    [theme.breakpoints.up(1580)]: {
        position: "relative",
        top: "7px",
        left: "120px",
    },
    [theme.breakpoints.down(1025)]: {
        display: "none !important"
    }, 
}))


const StyledCheckBoxSharpIcon = styled((props) => (
    <CheckBoxSharpIcon
        {...props}
    />
))(({ theme }) => ({
    [theme.breakpoints.up(1025)]: {
        position: "relative",
        top: "6px",
        // left: "57px",
        left: "45px"

    },
    [theme.breakpoints.only(1170)]: {
        position: "relative",
        top: "6px",
        left: "34px",

    },
    [theme.breakpoints.down(1025)]: {
        position: "relative",
        top: "7px",
        left: "18px"
    },
    [theme.breakpoints.down(1025)]: {
        display: "none !important"
    },
    [theme.breakpoints.up(1580)]: {
        position: "relative",
        top: "7px",
        left: "125px",

    },
}))


const StyledDeclineIcon = styled((props) => (
    <ThumbDownAltIcon
        {...props}
    />
))(({ theme }) => ({
    [theme.breakpoints.up(1025)]: {
        position: "relative",
        top: "6px",
        // left: "57px",
        left: "50px"

    },
    [theme.breakpoints.only(1170)]: {
        position: "relative",
        top: "6px",
        left: "34px",

    },
    [theme.breakpoints.down(1025)]: {
        position: "relative",
        top: "7px",
        left: "18px"
    },
    [theme.breakpoints.down(1025)]: {
        display: "none !important"
    },
    [theme.breakpoints.up(1580)]: {
        position: "relative",
        top: "7px",
        left: "125px",

    },
}))

const StyledPaidSharpIcon = styled((props) => (
    <PaidSharpIcon
        {...props}
    />
))(({ theme }) => ({
    [theme.breakpoints.up(1025)]: {
        position: "relative",
        top: "7px",
        // left: "80px",
        left: "55px"

    },

    [theme.breakpoints.up(1580)]: {
        position: "relative",
        top: "7px",
        left: "145px",

    },

    [theme.breakpoints.only(1170)]: {
        position: "relative",
        top: "6px",
        left: "34px",

    },
    [theme.breakpoints.down(1025)]: {
        position: "relative",
        top: "7px",
        left: "33px"
    },
    [theme.breakpoints.down(1025)]: {
        display: "none !important"
    },
}))

const StyleBookmarkAddedSharpIcon = styled((props) => (
    <BookmarkAddedSharpIcon
        {...props}
    />
))(({ theme }) => ({
    [theme.breakpoints.up(1025)]: {
        position: "relative",
        top: "6px",
        // left: "45px",
        left: "20px"

    },
    [theme.breakpoints.only(1170)]: {
        position: "relative",
        top: "6px",
        left: "34px",

    },
    [theme.breakpoints.down(1025)]: {
        position: "relative",
        top: "8px",
        left: "38px",
        display: "none !important"
    }, 
    [theme.breakpoints.down(1281)]: {
        display: "none !important"
    }, 
    [theme.breakpoints.up(1580)]: {
        position: "relative",
        top: "7px",
        left: "95px",

    },
}))

const StyleTransformSharpIcon = styled((props) => (
    <TransformSharpIcon
        {...props}
    />
))(({ theme }) => ({
    [theme.breakpoints.up(1024)]: {
        position: "relative",
        top: "6px",
        left: "26px",
        display: "none !important"
    },
    [theme.breakpoints.only(1170)]: {
        position: "relative",
        top: "6px",
        left: "34px",

    },
    [theme.breakpoints.down(1025)]: {
        position: "relative",
        top: "7px",
        left: "15px",
        display: "none !important"
    },
    [theme.breakpoints.down(1024)]: {
        display: "none !important"
    },
    [theme.breakpoints.up(1580)]: {
        position: "relative",
        top: "7px",
        left: "75px",
    },
    [theme.breakpoints.up(1920)]: {
        position: "relative",
        top: "7px",
        left: "75px",
    },
}))


export {
    StyledExportIcon,
    StyledFormControlLabel,
    StyledClearFilter,
    StyledChipApproved,
    StyledChipLookToBook,
    StyledChipConversionRate,
    StyleTransformSharpIcon,
    StyleBookmarkAddedSharpIcon,
    StyledPaidSharpIcon,
    StyledCheckBoxSharpIcon,
    StyledPublishSharpIcon,
    StyledDeclineIcon
};