import React, { useState } from "react";
import {
    Form,
    Select,
    Col,
    Row,
    Button,
    message,
    Spin
} from "antd";

import { PUT } from '../../../util/network'
import logger from '../../../util/logger'
import { useDispatch } from "react-redux";
import {getDetails } from '../../../reducers/LeaseApplications'

import _ from 'lodash';
import {useSelector} from "react-redux";

const Option = Select.Option;

export default function Credit({ data, disableFlag, creditStatusOptions, declineReasonTypeOptions, save, appNumber, handleNoAuth }) {
    const [form] = Form.useForm();
    const isCreditUser = useSelector((state) => state.leaseApplications.isCreditUser);
    const [creditStatus,] = useState(data.creditStatusValue);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const declineReasons = useState(data.declineReasons.map(declineReason => declineReason.description))
    const [requiredeclineReasons, setRequiredeclineReasons] = useState(false)
    const isButtonDisabled = isCreditUser === false ? true : disableFlag;
    // declineReasons possible data types => undefined, [], [<data>]
    const declineReasonPresence = (creditStatus, declineReasons) => {
        if (creditStatus.toLowerCase() === "declined") {
            if (declineReasons === undefined) {
                return false
            } else {
                return declineReasons.length > 0
            }
        } else {
            return false
        }
    }

    const [displayDeclineReasons, setDisplayDeclineReasons] = useState(declineReasonPresence(data.creditStatus, data.declineReasons))

    const handleSubmit = async (value) => {
        if (value.creditStatus.toLowerCase() == "declined") {
            saveApplicationDetails({
                creditStatus: value.creditStatus,
                decline_reasons_attributes:
                    getDeclineReasons(_.uniq(value.declineReasons)).concat(destroyExistingDeclineReasons(data.declineReasons))
            })
        } else {
            form.setFieldsValue({ declineReasons: [] })
            saveApplicationDetails({ creditStatus: value.creditStatus })
        }
    }

    const getDeclineReasons = (declineReasons) => {
        return declineReasons.map(item => {
            return { description: item }
        })
    }

    const destroyExistingDeclineReasons = (declineReasons) => {
        return declineReasons.map(item => {
            return { id: item.id, description: item, _destroy: true }
        })
    }

    const handleUnhide = (value) => {
        if (value === 'declined') {
            setDisplayDeclineReasons(true)
            setRequiredeclineReasons(true)
        } else {
            form.setFieldsValue({ declineReasons: [] })
            setDisplayDeclineReasons(false)
            setRequiredeclineReasons(false)
        }
    }

    const handleOnChangeDeclineReasons = (value) => {
       if(value.length > 0){
           setRequiredeclineReasons(false)
       }
       else{
           setRequiredeclineReasons(true)
       }
    }

    const layout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    const saveApplicationDetails = async (newData, form) => {
        setLoading(true);
        let timer = 0;
        let interval = setInterval(() => {
            timer = timer + 1;
            if (timer > 30) {
                message.success("Retrying. Please wait...");
                clearInterval(interval);
            }
        }, 1000);

        try {
            const leaseAppId = data?.id
            let response = null

            if (appNumber === null && leaseAppId !== undefined) {
                response = await PUT(`/api/v1/lease_applications/details?id=${leaseAppId}`, newData);
            } else {
                response = await PUT(`/api/v1/lease_applications/details?application_identifier=${appNumber}`, newData);
            }
            clearInterval(interval);
            dispatch(getDetails(response?.data?.leaseApplication));
            message.success("Data saved successfully");
            form && form.resetFields();
        } catch (e) {
            clearInterval(interval);
            logger.error("Application Details Error", e);

            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history);
            } else if (e.response.status === 406 && (e.response.data?.message !== undefined || e.response.data?.message !== null)) {
                message.error(e.response.data?.message);
            } else {
                message.error('Error while saving your data');
            }
        }
        setLoading(false);
    };

    return !data ? null : (

        <Spin
            spinning={loading}
            size="large"
            tip="Loading..."
        >
            <Form
                {...layout}
                form={form}
                initialValues={{
                    creditStatus: creditStatus,
                    // declineReasons: declineReasons
                }}
                onFinish={handleSubmit}
            >
                <Row gutter={[32, 0]}>
                    <Col span={24}>
                        <Form.Item label="Credit Status" name="creditStatus">
                            <Select style={{ width: "100%" }} disabled={isButtonDisabled} onChange={(value) => {
                                handleUnhide(value)
                            }}
                            >
                                {
                                    (creditStatusOptions || []).map((obj, key) => {
                                        return <Option key={key} value={obj.optionValue} disabled={obj.disabled}>{obj.optionName}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Decline Reason"
                            name="declineReasons"
                            hidden={!displayDeclineReasons}
                            rules={[{ required: requiredeclineReasons, message: 'Decline Reason is required!' }]}>
                            <Select mode="multiple"
                                defaultValue={declineReasons[0]}
                                disabled={isButtonDisabled}
                                onChange={(value) => { handleOnChangeDeclineReasons(value) }}
                            >
                                {
                                    (declineReasonTypeOptions || []).map((obj, key) => {
                                        return <Option key={obj.optionValue} value={obj.optionName} disabled={obj.disabled}>{obj.optionName}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Button type="primary" htmlType="submit" disabled={isButtonDisabled} >
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Spin>

    );
}