import React, { useState, useEffect } from "react";
import { Switch } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

export default function ActiveToggleButton({isActive, rowData, handleChange}) {
  const [active, setActive] = useState(isActive)
  useEffect(() => {
    setActive(isActive)
  }, []);

  return (
    <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      defaultChecked={isActive}
      checked={active}
      onChange={() => handleChange(rowData)}
    />
  );
}