import React, { useState } from "react";
import { Button, Input, Layout, Row, Col, Select } from "antd";
import "../../App.css";
import MainHeader from "../../components/layouts/MainHeader";
import MainFooter from "../../components/layouts/MainFooter";
import Box from "@mui/material/Box";
import AssignmentReturnedSharpIcon from "@mui/icons-material/AssignmentReturnedSharp";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import ModelGroupTable from "./component/ModelGroupTable";
import { useHistory } from "react-router-dom";
import DialogModal from '../LeaseApplications/DialogModal'

export default function ModelGroupList(props) {
  const { Content } = Layout;
  const { Search } = Input;
  const { Option } = Select;
  const authData = JSON.parse(window.localStorage.getItem('user_data')) || null;
  const { data, loading, pageState, setPageState, total, makeOptions, exportModelGroupList, exportModal,
    handleExportModalOpen, handleExportModalClose } = props;
  const history = useHistory();

  const [searchValue, setSearchValue] = useState(pageState.search);

  const columns = [
    {
      field: "id",
      headerName: "Id",
      type: "string",
      flex: 0.5,
      minWidth: 30,
      filterable: false,
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      flex: 1.5,
      minWidth: 75,
      filterOperators: false,
    },
    {
      field: "make",
      headerName: "Make",
      type: "string",
      flex: 1.5,
      minWidth: 75,
      filterOperators: false,
    },
    {
      field: "minimum_dealer_participation",
      headerName: "Min. Dealer Participation",
      type: "string",
      flex: 0.75,
      minWidth: 50,
      filterOperators: false,
      // renderCell: (params) => {
      //   return `$${params.row.minimum_dealer_participation}`
      // }
    },
    {
      field: "backend_advance_minimum",
      headerName: "Backend Advance Min.",
      type: "string",
      flex: 0.75,
      minWidth: 50,
      filterOperators: false,
      // renderCell: (params) => {
      //   return `$${params.row.backend_advance_minimum}`
      // }
    },
    {
      field: "residual_reduction_percentage",
      headerName: "Residual Reduction %",
      type: "string",
      flex: 0.75,
      minWidth: 50,
      filterOperators: false,
      renderCell: (params) => {
        return `${params.row.residual_reduction_percentage}%`
      }
    },
    {
      field: "maximum_term_length",
      headerName: "Max. Term Length",
      type: "string",
      flex: 0.75,
      minWidth: 50,
      filterOperators: false,
    },
    {
      field: "maximum_term_length_per_year",
      headerName: "Max. Term Length Per Year",
      type: "string",
      flex: 1,
      minWidth: 50,
      filterOperators: false,
    },
    {
      field: "model_type_id",
      headerName: "Model Type Id",
      type: "string",
      flex: 0.5,
      minWidth: 30,
      filterOperators: false,
    },
    {
      field: "Actions",
      headerName: "Actions",
      type: "action",
      flex: 1.2,
      minWidth: 50,
      headerAlign: 'center',
      align: 'center',
      renderCell: (record) => {
        return record ? (
          <>
            <Button
              style={{ textTransform: "none" }}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/new-assets/model-groups/details/${record.row.id}`,
                });
              }}
            >
              View
            </Button>
            <Button
              style={{ textTransform: "none", marginLeft: ".5rem" }}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/new-assets/model-groups/edit/${record.row.id}`,
                });
              }}
            >
              Edit
            </Button>
          </>
        ) : (
          ""
        );
      },
    },
  ];

  const handleGlobalSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setPageState({ ...pageState, search: e.target.value });
    }
  };

  return (
    <Layout>
      <MainHeader activePage="newAssets" />
      <Layout>
        <Layout id="content-area-layout">
          <Content
            id="main-content"
            style={{ backgroundColor: "#ffff !important" }}
          >
            <Row style={{ marginBottom: "1em" }}>
              <Col flex={4}>
                <h2>Model Groups</h2>
              </Col>
              <Col style={{ marginRight: "0px" }}>
                <Button
                  style={{
                    backgroundColor: "#e93b1b",
                    color: "white",
                    border: "none",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: "/new-assets/model-groups/create",
                    });
                  }}
                  type="primary"
                >
                  Create Model Group
                </Button>
                <Tooltip title={<h5>Export</h5>}>
                  <IconButton
                    // size="large"
                    style={{ color: "#e93b1b", fontSize: "30px" }}
                    onClick={() => {
                      handleExportModalOpen();
                    }}
                  >
                    <AssignmentReturnedSharpIcon />
                  </IconButton>
                </Tooltip>
              </Col>
            </Row>

            <Row style={{ marginBottom: "1em" }}>
              <Col span={6}>
                <Search
                  placeholder="Global Search: name"
                  size="large"
                  defaultValue={searchValue}
                  onChange={handleGlobalSearch}
                  allowClear
                  className="leaseAppListSearch"
                  style={{ marginBottom: "10px" }}
                  onSearch={(searchVal) => {
                    setPageState({ page: 0, limit: 25, search: searchVal, make_id:"" });
                  }}
                  enterButton
                />
              </Col>
              <Col span={13}>
              </Col>

              <Col span={5} type="flex" align="left">
              <Select
                  style={{ width: "100%"}}
                  size="large"
                  placeholder="Select Make"
                  allowClear
                  onChange={(value) => {
                    if(value !== undefined && value!== null){
                      setPageState({ page: 0, limit: 25, make_id: value, search:"" });
                    }
                    else {
                      setPageState({ page: 0, limit: 25, make_id:"", search:"" });
                    }
                  }}
                >
                  {makeOptions &&
                    makeOptions.map((record, index) => {
                      return (
                        <Option key={index} value={record.id}>
                          {`${record.name}`}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
            </Row>

            {data && (
              <>
                <StyledBox>
                  <ModelGroupTable
                    columns={columns}
                    data={data}
                    loading={loading}
                    setPageState={setPageState}
                    pageState={pageState}
                    total={total}
                  />
                </StyledBox>
              </>
            )}
            <div>
            <DialogModal
                modalState={exportModal}
                modalClose={handleExportModalClose}
                submitFunction={exportModelGroupList}
                contentData={"Data will be exported as CSV. Are you sure you want to continue?"}
                title={"Export Model Years?"}
              />
            </div>
          </Content>
        </Layout>
      </Layout>
      <MainFooter />
    </Layout>
  );
}

const StyledBox = styled(Box)({
  height: 820,
  display: "flex",
  flexGrow: 1,
  width: "100%",
  "& .super-app-theme--header": {
    backgroundColor: "rgba(255, 7, 0, 0.55)",
  },
});
