import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET } from '../util/network';
import moment from 'moment'

export const fetchNotifications = createAsyncThunk('dashboard/notifications', async () => {
  const response = await GET(`/api/v1/notifications`);
  return response.data;
})

const initialState = {}

export const notificationsSlice = createSlice({
  name: "notification",
  initialState: initialState,
  reducers: {
    markNotificationsAsRead: (state) => {
      state.notifications.map((notification) => {
        if(notification.read_at === null){
          notification.read_at = moment()
        }
      })
    },
    markNotificationByIdAsRead: (state, action) => {
      state.notifications.map((notification) => {
        if(notification.id === action.payload.id){
          notification.read_at = moment()
        }
      })
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload.notifications
      state.isLoading = false;
      return state
    }),
      builder.addCase(fetchNotifications.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchNotifications.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload;
      });
  }
});

export const { markNotificationsAsRead, markNotificationByIdAsRead } = notificationsSlice.actions;

export default notificationsSlice.reducer;
