
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Form,
  Layout,
  Menu,
  message,
  Row,
  Select,
  Spin,
  Switch,
  DatePicker
} from "antd";
import Icon from "@ant-design/icons";
import { GET, PUT } from "../../util/network";
import logger from "../../util/logger";
import MainHeader from "../../components/layouts/MainHeader";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import MainFooter from "../../components/layouts/MainFooter";
import { SiderProps } from "../../components/layouts/MainSiderProps";
import { AddNew } from "../../components/layouts/Svg";
import moment from 'moment';

const { Content, Sider } = Layout;
const { Option } = Select;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const EditCreditTier = (props) => {
  const { id } = props.match.params;
  const { handleNoAuth } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [creditTierForm] = Form.useForm();

  const [makeOptions, setMakeOptions] = useState([]);
  const [modelGroupOptions,setModelGroupOptions] = useState([]);
  const [creditTierData, setCreditTierData] = useState();

  const handleSubmit = (values) => {
    values = { ...values };
    save(values);
  };
  const creditTierDetails = async () => {
    setLoading(true);
    try {
      await GET(`/api/v1/credit_tiers/${id}`)
        .then((response) => {
          setCreditTierData(response.data);
          getMakeOptions();
          getModelGroupOptions(response.data?.make_id)
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error("Error while fetching Make Details");
          }
        });
    } catch (e) {
      logger.error("Error while fetching Make Details", e);
    }
    setLoading(false);
  };
  const save = async (values) => {
    setLoading(true);
    try {
      await PUT(`/api/v1/credit_tiers/${id}/update-tiers`, values)
        .then(() => {
          message.success("Saved successfully");
          history.push("/calculator/credit-tiers/list");
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error("Error while saving!");
          }
        });
    } catch (e) {
      logger.error("Error while saving!", e);
    }
    setLoading(false);
  };
  const getMakeOptions = async () => {
    {
     setLoading(true);
     try {
       await GET(`/api/v1/makes/list?filter_option=true`)
         .then((response) => {
           setMakeOptions(response.data || []);
         })
         .catch((error) => {
           if (error.response && error.response.status === 401) {
             handleNoAuth(history);
           } else {
             message.error("Error while fetching Dealerships Options");
           }
         });
     } catch (e) {
       logger.error("Error while fetching Dealerships Options", e);
     }
     setLoading(false);
   }
};
 
 const getModelGroupOptions = async (make_id) => {
  {
    setLoading(true);
    try {
      await GET(`/api/v1/model-groups?make_id=${make_id}`)
        .then((response) => {
          setModelGroupOptions(response.data.model_groups||[])
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error("Error while fetching Model Group Options");
          }
        });
    } catch (e) {
      logger.error("Error while fetching Model Group Options", e);
    }
    setLoading(false);
  }
};
 
useEffect(() => {
  creditTierDetails();
}, []);

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <MainHeader activePage="calculator" />
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{ width: "100%" }}
            >
              <Menu.Item key="1">
                <Icon component={AddNew} />
                Edit Credit Tier
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                { text: "Home", link_type: "linkto", link: "/dashboard" },
                { text: "Calculator", link_type: "ahref", link: void 0 },
                {
                  text: "Credit Tier",
                  link_type: "ahref",
                  link: "/calculator/credit-tiers/list",
                },
                { text: "Edit", link_type: "ahref", link: void 0 },
              ]}
            />

            <Content id="main-content">
              <Row gutter={[0, 24]}>
              {creditTierData && (
                <Col span={24}>
                  <>
                    <Form
                      form={creditTierForm}
                      {...layout}
                      colon={false}
                      initialValues={{
                        id: creditTierData?.id,
                        make_id: creditTierData?.make_id,
                        model_group_id: creditTierData?.model_group_id,
                        position: creditTierData?.position,
                        description: creditTierData?.description,
                        irr_value: creditTierData?.irr_value,
                        maximum_fi_advance_percentage: creditTierData?.maximum_fi_advance_percentage,
                        maximum_advance_percentage: creditTierData?.maximum_advance_percentage,
                        required_down_payment_percentage: creditTierData?.required_down_payment_percentage,
                        payment_limit_percentage: creditTierData?.payment_limit_percentage,
                        acquisition_fee_cents: creditTierData?.acquisition_fee_cents,
                        security_deposit: creditTierData?.security_deposit,
                        effective_date: moment(creditTierData?.effective_date, 'YYYY-MM-DD') ,
                        end_date: moment(creditTierData?.end_date, 'YYYY-MM-DD') ,
                        enable_security_deposit: creditTierData?.enable_security_deposit,
                      }}
                      onFinish={handleSubmit}
                    >
                      <Card title="Credit Tier Info">
                        <Row gutter={[16, 16]}>
                          <Col span={12}>
                            <Form.Item
                              label="Make"
                              name="make_id"
                              rules={[{ required: true, message: "Please select make" }]}
                            >
                              <Select
                                onSelect={(value) => {
                                  getModelGroupOptions(value??creditTierData?.make_id)
                                }
                              }>
                                {makeOptions &&
                                  makeOptions.map(({ id, name }, index) => {
                                    return (
                                      <Option key={index} value={id}>
                                        {name}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              label="Model group"
                              name="model_group_id"
                              rules={[{ required: true, message: "Please select model group" }]}
                            >
                              <Select>
                                {modelGroupOptions &&
                                  modelGroupOptions.map(({ id, name }, index) => {
                                    return (
                                      <Option key={index} value={id}>
                                        {name}
                                      </Option>
                                    );
                                  })}
                              </Select>
                              </Form.Item>
                              
                              <Form.Item
                              label="Description"
                              name="description"
                              rules={[{ required: true, message: "Description can not be empty" }]}
                              >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              label="Position"
                              name="position"
                            >
                              <Input type="number" />
                            </Form.Item>

                            <Form.Item
                              label="Irr value"
                              name="irr_value"
                            >
                              <Input type="number" />
                            </Form.Item>

                            <Form.Item
                              label="Maximum fi advance percentage"
                              name="maximum_fi_advance_percentage"
                            >
                              <Input type="number" />
                            </Form.Item>

                            <Form.Item
                              label="Maximum advance percentage"
                              name="maximum_advance_percentage"
                            >
                              <Input type="number" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Required down payment percentage"
                              name="required_down_payment_percentage"
                            >
                              <Input type="number" />
                            </Form.Item>
                            <Form.Item
                              label="Payment limit percentage"
                              name="payment_limit_percentage"
                            >
                              <Input type="number" />
                            </Form.Item>

                            <Form.Item
                              label="Acquisition fee"
                              name="acquisition_fee_cents"
                            >
                              <Input type="number" />
                            </Form.Item>

                            <Form.Item
                              label="Security deposit"
                              name="security_deposit"
                            >
                              <Input type="number" />
                            </Form.Item>

                            <Form.Item
                              label="Enable security deposit"
                              name="enable_security_deposit"
                              valuePropName="checked"
                            >
                              <Switch />
                            </Form.Item>

                            <Form.Item
                              label="Effective date"
                              name="effective_date"
                              rules={[{ required: true, message: "Please select the effective date" }]}
                            >
                              <DatePicker style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                              label="End date"
                              name="end_date"
                              rules={[{ required: true, message: "Please select the end date" }]}
                            >
                              <DatePicker style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>

                      <Row gutter={[16, 16]} className="mt-20">
                        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                          <Row>
                            <Col span={24}>
                              <Form.Item>
                                <Button type="primary" htmlType="submit">
                                  Save
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </>
                </Col>)}
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    </Spin>

  );
};

export default EditCreditTier;
