import React from "react";
import {Button, Card, Checkbox, Col, Input, Form, Row, Select} from "antd";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function ContactForm({contactData, dealershipOptions, isDisable, handleSubmit, setIsDisable}) {
  const [dealerUserForm] = Form.useForm();
  const { Option } = Select;

  return (
    <>
      <Form
        form={dealerUserForm}
        {...layout}
        colon={false}
        onFinish={handleSubmit}
        initialValues={{
          id: contactData?.id,
          dealership_id: contactData?.dealershipId,
          first_name: contactData?.firstName,
          last_name: contactData?.lastName,
          direct_email: contactData?.directEmail,
          direct_phone_number: contactData?.directPhoneNumber,
          position: contactData?.position,
          is_dealer_user: contactData?.isDealerUser === true,
          is_owner: contactData?.isOwner === true,
        }}
      >
        {isDisable === true ? <Row style={{marginBottom: "1em", marginRight: '8%'}}>
          <Button
            onClick={() => {
              setIsDisable(false)
            }}
            style={{backgroundColor: "#e93b1b", color: 'white', border: 'none'}}
          >
            Edit Contact
          </Button>
        </Row> : <Row style={{marginBottom: "1em", marginRight: '8%'}}>
          <Button
            onClick={() => {
              setIsDisable(true)
            }}
            style={{backgroundColor: "#e93b1b", color: 'white', border: 'none'}}
          >
            View Contact
          </Button>
        </Row>
        }

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card title="Contact Info">
              <Row gutter={[24, 16]}>
                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    hasFeedback
                    rules={[{required: true, message: "First name is required!"}]}
                  >
                    <Input disabled={isDisable}/>
                  </Form.Item>

                  <Form.Item
                    label="Direct Email"
                    name="direct_email"
                    hasFeedback
                    rules={[
                      {required: true, message: "Direct Email is required!"},
                      {required: false, type: "email", message: "The input is not valid E-mail!"}]}
                  >
                    <Input disabled={isDisable}/>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    hasFeedback
                    rules={[{required: true, message: "Last name is required!"}]}
                  >
                    <Input disabled={isDisable}/>
                  </Form.Item>

                  <Form.Item
                    label="Direct Phone Number"
                    name="direct_phone_number"
                    hasFeedback
                    rules={[{required: true, message: "Direct Phone number is required!"}]}
                  >
                    <Input disabled={isDisable}/>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 16]}>
                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Position"
                    name="position"
                    hasFeedback
                    rules={[{required: true, message: "Position is required!"}]}
                  >
                    <Input disabled={isDisable}/>
                  </Form.Item>

                  <Form.Item
                    name="is_dealer_user"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={isDisable}>Is Dealer User</Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                  <Form.Item
                    label="Dealership"
                    name="dealership_id"
                    hasFeedback
                    rules={[{ required: true, message: "Dealership is required!" }]}
                  >
                    <Select
                      disabled={isDisable}
                      showSearch
                      filterOption={(input, option) =>
                        option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                      }>
                      {dealershipOptions &&
                        dealershipOptions.map(({ value, label }, index) => {
                          return (
                            <Option key={index} value={value}>
                              {label}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="is_owner"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={isDisable}>Is Owner</Checkbox>
                  </Form.Item>

                  <Form.Item
                    name="is_owner"
                    valuePropName="checked"
                  >
                    <Checkbox disabled={isDisable}>Is Owner</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        {isDisable ? (
          ""
        ) : (
          <Row gutter={[16, 16]} className="mt-20">
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <Row>
                <Col span={24}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Update Contact
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
}
