import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {message, Spin} from "antd";
import {GetResourceActions, IsActionAllowed} from "../../util/AllowedActions";
import {Layout} from "antd";
import MainHeader from "../../components/layouts/MainHeader";
import MainFooter from "../../components/layouts/MainFooter";
import CallLogList from "./CallLogComponents/CallLogList";
import {GET} from "../../util/network";
import logger from "../../util/logger";
import {handleNoAuth} from "../../util/authenticate";

export default function CallLogsRenderer(props) {
  const {allowedActions} = props;
  const {id} = props.match.params;
  const history = useHistory();
  const dealershipActions = GetResourceActions("Dealership", allowedActions);
  const allowedGet = IsActionAllowed("get", dealershipActions);
  const [loading, setLoading] = useState(false);
  const [dealershipOptions, setDealershipOptions] = useState([]);
  const [adminUserOptions, setAdminUserOptions] = useState([]);
  const callTypes = [
    {
      "value": 'logged',
      "label": "Logged Call"
    },
    {
      "value": 'scheduled',
      "label": "Scheduled Call"
    },
    {
      "value": 'log_and_scheduled',
      "label": "Log and Scheduled Call"
    }
  ];
  const initialState = {
    total: 0,
    page: 0,
    limit: 50,
    search: "",
    call_type: "",
    admin_user_id: "",
    dealership_id: "",
  };
  const [pageState, setPageState] = useState({...initialState});
  const [callLog, setCallLog] = useState([]);

  const getCallLogs = async () => {
    if (allowedGet) {
      setLoading(true);
      try {
        let encodedParam = Object.entries(pageState).map(([key, val]) => (key !== 'dealer') && `${key}=${val}`).join('&');
        const response = await GET(`/api/v1/call_logs?${encodedParam}`);
        setCallLog(response.data);
      } catch (e) {
        logger.error("Error while fetching call logs.", e);
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error("Error while fetching call logs.");
        }
      }
      setLoading(false);
    }
  };

  const getDealershipOptions = async () => {
    if (allowedGet) {
      setLoading(true);
      try {
        const response = await GET(`/api/v1/dealerships/dealerships-options`);
        setDealershipOptions(response.data?.dealerships_options);
      } catch (e) {
        logger.error("Error while fetching Dealerships.", e);
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error("Error while fetching Dealerships.");
        }
      }
      setLoading(false);
    }
  };

  const getAdminUserOptions = async () => {
    if (allowedGet) {
      setLoading(true);
      try {
        const response = await GET(`/api/v1/admin-users/admin-user-options`);
        setAdminUserOptions(response.data?.admin_user_options);
      } catch (e) {
        logger.error("Error while fetching Admin user", e);
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error("Error while fetching Admin users.");
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getDealershipOptions();
    getAdminUserOptions();
  }, []);

  useEffect(() => {
    getCallLogs();
    const interval = setInterval(() => {
      getCallLogs()
    }, 60000);
    return () => clearInterval(interval);
  }, [pageState]);

  return (
    <Layout>
      <MainHeader activePage="dealers"/>
      <Layout>
        <Spin spinning={loading}>
          <CallLogList callLog={callLog.data?.callLogs} dealershipOptions={dealershipOptions}
                       adminUserOptions={adminUserOptions} callTypes={callTypes}
                       allowedGet={allowedGet} total={callLog.total} pageState={pageState} setPageState={setPageState}
                       setLoading={setLoading}/>
        </Spin>
      </Layout>
      <MainFooter/>
    </Layout>
  );
}
