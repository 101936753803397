import React from "react";
import { Table } from "antd";

const columns = [
  {
    title: 'Item',
    dataIndex: 'item',
    key: 'item',
  },
  {
    title: 'Phone Line Type',
    dataIndex: 'phone_number_line',
    key: 'phone_number_line',
  },
  {
    title: 'Phone Carrier',
    dataIndex: 'phone_number_carrier',
    key: 'phone_number_carrier',
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
  },
  {
    title: 'Status',
    key: 'status', 
    dataIndex: 'status',
  },
];

export default function LesseeVerification({ data }) {
  const lessee_ids = []
  data && data.lessee && lessee_ids.push(data.lessee.id)
  data && data.lessee && data.lessee.homeAddress && lessee_ids.push(data.lessee.homeAddress.id)
  const dataSource = data.leaseValidations.filter(val => ["Home Phone Validation", "Address Validation"].includes(val.type) && lessee_ids.includes(val.validatable_id) ) || []
  return dataSource ? (
      <Table className='slc-table' rowKey="id" columns={columns} dataSource={dataSource} size="small" pagination={true}/>
  ) : null;
}