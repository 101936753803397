import React from 'react'
import { Row, Col, Form, Input, Radio, Typography } from 'antd';

const { Text } = Typography;
export default function IntroductoryInfo(props) {

    const { leaseApplication, radioButtonState } = props;
    return (
        <>
            <Row gutter={[8, 8]} className="vcc-header-row">
                <Col span={24}>
                    <Text  level={3} className="vcc-header-title">Verification Script</Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text level={4} className="vcc-text">
                        Hello, may I speak with <Text strong>{leaseApplication.lessee ? leaseApplication.lessee.fullName : null}</Text>?
                    </Text>
                </Col>
            </Row>

            {/* Available to Speak */}
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text level={4} className="vcc-text">
                        Hi, I am calling from Speed Leasing regarding your new lease with <Text strong>{leaseApplication.dealership ? leaseApplication.dealership.dealership : null}</Text>.
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text level={4} className="vcc-text">
                        Do you have a moment to speak?
                    </Text>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="vcc-row">
                <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                    <Form.Item
                        name={["lesseeAvailableToSpeak"]}
                        className='vcc-form-item'
                        rules={[{ required: true, message: 'Required' }]}
                        id='lessee-available-to-speak'
                    >
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                {!radioButtonState.lesseeAvailableToSpeak &&
                    <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                        <Form.Item
                            name={['lesseeAvailableToSpeakComment']}
                            className='vcc-form-item'
                            rules={[{ required: true, message: 'Required' }]}
                        >
                            <Input placeholder='Comments' />
                        </Form.Item>
                    </Col>
                }
            </Row>

            <Row gutter={[8, 8]} className="vcc-row">
                <Col span={24}>
                    <Text strong level={4} >
                        This call will be recorded for quality assurance and training purposes.
                    </Text>
                </Col>
                <Col span={24}>
                    <Text strong level={4}>
                        The purpose of the call is simply to verify your identity and confirm that you understand the terms of the lease
                    </Text>
                </Col>
            </Row>

        </>
    )
}
