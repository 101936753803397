import React, { Component } from 'react';
import Pagination from '../../components/pagination/Pagination';


class ReadNotifications extends Component{

    constructor(){
        super();
        this.state={
            currentPage:1,
        }
    }

    handlePagination= (page) => {
        this.props.handlePagination(page);
        this.setState({
          currentPage: page
        });
      }

    render(){
        const {notifications} = this.props;
        const {currentPage} = this.state;

        const {
                total_count:totalNotificationsCount,
                start_index:indexOfFirstNotification,
                last_index:indexOfLastNotification,
                total_pages:totalPages
            } =this.props.paginationData || {};      
        return (
            <>
                {notifications || ''}
                <Pagination
                    totalNotificationsCount = {totalNotificationsCount}
                    handleClick = {this.handlePagination}
                    currentPage={currentPage}
                    indexOfFirstNotification={indexOfFirstNotification}
                    indexOfLastNotification={indexOfLastNotification}
                    totalPages={totalPages}
                />
            </>
        );
    }
}

export default ReadNotifications;