import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Spin, message } from "antd";
import Inventories from "./Inventories";
import { GET } from '../../util/network';
import logger from '../../util/logger';
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';

export default function InventoryRenderer(props) {
  const { allowedActions, handleNoAuth } = props;
  const history = useHistory();

  const inventoryActions = GetResourceActions('VehicleInventory', allowedActions);

  const allowedGet = IsActionAllowed('get', inventoryActions);  
  const allowedCreate = IsActionAllowed('create', inventoryActions);  

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  
  const getInventories = async () => {
    if (allowedGet) {
      setLoading(true);
      try {
        const response = await GET('/api/v1/vehicle-inventory');
        setData(response.data);
      } catch (e) {
        logger.error("Application Details Error", e);

        if (e && e.response && e.response.status === 401) {          
          handleNoAuth(history);
        } else {
          message.error('Error while fetching Vehicle Inventory');
        }
      }
      setLoading(false);
    }
  };
  

  useEffect(() => {
    getInventories();
  }, []);

  return (
    <Spin 
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      <Inventories 
        data={data} 
        getInventories={getInventories}
        setLoading={setLoading}
        allowedActions={inventoryActions}
        allowedCreate={allowedCreate}
        allowedGet={allowedGet}
      />
    </Spin>
  )
}