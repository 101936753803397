import React from 'react';
import MainHeader from './../components/layouts/MainHeader';
import MainFooter from './../components/layouts/MainFooter';
import { Layout, Typography } from 'antd';

const { Content } = Layout;
const { Title } = Typography;
export default function UnauthorizedPage() {
  return (
      <Layout>
          <MainHeader activePage="" />
            <Layout id='content-area-layout'>
              <Content id='main-content'>
                <Title level={1}>Unauthorized Access</Title>
                  <Typography variant="body1" gutterBottom>
                    You do not have permission to access this page.
                  </Typography>
              </Content>
            </Layout>
          <MainFooter />
      </Layout>
  );
}

