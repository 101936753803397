import React from "react";
import { Row, Col, Card, Typography, Space } from "antd";
import logger from '../../../util/logger';
import { useHistory } from 'react-router-dom';
import StatusTag from '../../Dealership/DealerShipStatus/StatusTag';
const { Text } = Typography;

const auth_token = window.localStorage.getItem('auth_token');

const SummaryDescription = ({ label, children }) => {
  return <Row style={{ marginBottom: 10 }}><Col span={24}> <Text style={{ color: "rgba(0, 0, 0, 0.65)", fontWeight: 700, fontSize: "1.1em" }}> {label} </Text> </Col> <Col span={24}> {children} </Col></Row>
}

export default ({ data, disableFlag }) => {
  const { colessee, id, documentStatus ,colesseeAddedAfterSubmission  } = data || {};
  const {
    firstName,
    dateOfBirth,
    homePhoneNumber,
    lastName,
    mobilePhoneNumber,
    ssn,
    firstTimeRider,
    driverLicense,
    licenseState,
    driversLicenseIdNumber,
    motorcycleEndorsement,
  } = data?.lessee || {};
  const { assetYear, assetMake, assetModel, assetVin } = data?.vehicle || {};
  const { dealer } = data || {};
  const { payment } = data?.payment || {};
  const { dealership } = data?.dealership || {};
  const history = useHistory();
  const displayVerificationCall = documentStatus && documentStatus != 'no_documents' ? true : false;
  let paymentFrequency = 'N/A';

  if (data && data.paymentFrequency) {
    try {
      paymentFrequency = capitalize(data.paymentFrequency);
    } catch (e) {
      logger.error('Application Summary Error', e);
    }
  }

  let lesseeAddressLineOne = '';
  let lesseeAddressLineTwo = '';

  if (data && data.lessee) {
    try {
      const lesseeData = data.lessee;

      if (lesseeData?.street1) {
        lesseeAddressLineOne = `${lesseeData?.street1}`;
      }

      if (lesseeData?.street2) {
        lesseeAddressLineOne += ` ${lesseeData?.street2}`;
      }

      if (lesseeAddressLineOne) {
        lesseeAddressLineOne.trim();
      }

      const city = lesseeData?.homeAddress?.newCity?.city;
      const state = lesseeData?.homeAddress?.newCity?.state;
      const zip = lesseeData.zipcode;

      if (city) {
        lesseeAddressLineTwo = `${city},`;
      }

      if (state) {
        lesseeAddressLineTwo += ` ${state}`;
      }

      if (zip) {
        lesseeAddressLineTwo += ` ${zip}`;
      }

      if (lesseeAddressLineTwo) {
        lesseeAddressLineTwo.trim();
      }
    } catch (e) {
      logger.error('Application Summary Error', e);
    }
  }

  const capitalize =(str) => {
    const lower = str.toLowerCase()
    return str.charAt(0).toUpperCase() + lower.slice(1)
  }

  const dealershipStatus =() =>
  {
    let text = data?.dealership.status ;
    return  <StatusTag  style={{whiteSpace: "break-spaces"}} name ={text} />
  }

  const dealershipState = data?.dealership?.state || ""
  const dealershipCity = data?.dealership?.city?.name || ""
  const dealershipStreet1 = data?.dealership?.address?.street1 || ""
  const dealershipStreet2 = data?.dealership?.address?.street2 || ""
  const dealershipZip = data?.dealership?.address?.zipcode || ""
  const firstTimeRiderValue = firstTimeRider !== null ? (firstTimeRider === true ? 'YES' : 'NO') : 'N/A'
  const motorcycleLicenceValue = driverLicense !== null ? driverLicense : 'N/A'
  const motorcycleEndorsementValue = motorcycleEndorsement !== null ?
    (motorcycleEndorsement === true ? "Yes" : "No") : "N/A";
  const dealerStatus = dealershipStatus();

  return data ? (
    <>
      <Row gutter={[16, 16]} className='mb-20'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card title='Dealer Information'>
            <Space direction='vertical'>
              <SummaryDescription label="ID">{dealer?.id ? dealer?.id : 'N/A'}</SummaryDescription>
              <SummaryDescription label="Dealership Name">{dealership ? dealership : 'N/A'}</SummaryDescription>
              <SummaryDescription label="Dealership Status">{dealerStatus ? dealerStatus : 'N/A'}</SummaryDescription>
              <SummaryDescription label="Dealership Address">{
                `${dealershipStreet1 ? dealershipStreet1 + " " : ""}` +
                `${dealershipStreet2 ? dealershipStreet2 + ", " : ""}` +
                `${dealershipCity ? dealershipCity + ", " : ""}` +
                `${dealershipState ? dealershipState + ", " : ""}` +
                `${dealershipZip ? dealershipZip : ""}`
              }
              </SummaryDescription>
              <SummaryDescription label="First Name">{dealer?.first_name ? dealer?.first_name.toUpperCase() : 'N/A'}</SummaryDescription>
              <SummaryDescription label="Last Name">{dealer?.last_name ? dealer.last_name?.toUpperCase() : 'N/A'}</SummaryDescription>
              <SummaryDescription label="Email">{dealer?.email ? dealer?.email : 'N/A'}</SummaryDescription>
            </Space>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={6}>
          <Card className='h-100' title="Lessee">
            <SummaryDescription label="Name">{firstName || lastName ? `${firstName} ${lastName}` : 'N/A'}</SummaryDescription>
            <SummaryDescription label="Address">{lesseeAddressLineOne}<br />{lesseeAddressLineTwo}</SummaryDescription>
            <SummaryDescription label="Home Phone">{homePhoneNumber ? homePhoneNumber : 'N/A'}</SummaryDescription>
            <SummaryDescription label="Mobile Phone">{mobilePhoneNumber ? mobilePhoneNumber : 'N/A'}</SummaryDescription>
            <SummaryDescription label="Date of Birth">{dateOfBirth ? dateOfBirth : 'N/A'}</SummaryDescription>
            <SummaryDescription label="Last Four of SSN">{ssn ? ssn.slice(ssn.length - 4) : 'N/A'}</SummaryDescription>
            <SummaryDescription label="First Motorcycle Purchase/Lease">{ firstTimeRiderValue }</SummaryDescription>
            <SummaryDescription label="Driver License">{motorcycleLicenceValue}</SummaryDescription>
            {
              driverLicense == "Yes" && licenseState !== null &&
              <SummaryDescription label="License State">{licenseState}</SummaryDescription>
            }
            {
              driverLicense == "Yes" && driversLicenseIdNumber !== null &&
              <SummaryDescription label="License Number">{driversLicenseIdNumber}</SummaryDescription>
            }
            <SummaryDescription
              label="Motorcycle Endorsement">
              {motorcycleEndorsementValue}
            </SummaryDescription>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={6}>
          <Card className='h-100' title="Co-Lessee" >
            {
              colessee && colesseeAddedAfterSubmission == false
                ?
                <>
                  <SummaryDescription label="Name">{colessee?.firstName} {colessee?.lastName}</SummaryDescription>
                  <SummaryDescription label="Address">{colessee?.street1} {colessee?.street2} <br />
                    {colessee?.homeAddress?.newCity?.city}{colessee && ","} {colessee?.homeAddress?.newCity?.state} {colessee?.zipcode}
                  </SummaryDescription>
                  <SummaryDescription label="Home Phone">{colessee?.homePhoneNumber}</SummaryDescription>
                  <SummaryDescription label="Mobile Phone">{colessee?.mobilePhoneNumber}</SummaryDescription>
                  <SummaryDescription label="Date of Birth">{colessee?.dateOfBirth}</SummaryDescription>
                  <SummaryDescription label="Relationship to Lessee">{colessee?.relationshipToLessee?.description || colessee?.lesseeAndColesseeRelationship}</SummaryDescription>
                  <SummaryDescription label="Last Four of SSN">{colessee?.ssn && colessee?.ssn.slice(colessee?.ssn.length - 4)}</SummaryDescription>
                  <SummaryDescription label="Driver License">{colessee?.driverLicense !== null ? colessee?.driverLicense : 'N/A'}</SummaryDescription>
                  {
                    colessee?.driverLicense == "Yes" && colessee?.licenseState !== null &&
                    <SummaryDescription label="License State">{colessee?.licenseState}</SummaryDescription>
                  }
                  {
                    colessee?.driverLicense == "Yes" && colessee?.driversLicenseIdNumber !== null &&
                    <SummaryDescription label="License Number">{colessee?.driversLicenseIdNumber}</SummaryDescription>
                  }
                  <SummaryDescription
                    label="Motorcycle Endorsement">
                    {colessee?.motorcycleEndorsement !== null ? (colessee?.motorcycleEndorsement === true ? "Yes" : "No") : "N/A"}
                  </SummaryDescription>
                </>
                :
                'N/A'
            }
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
          <Card className='h-100' title="Vehicle *" >
            <SummaryDescription label="Year">{assetYear ? assetYear : 'N/A'}</SummaryDescription>
            <SummaryDescription label="Make">{assetMake ? assetMake : 'N/A'}</SummaryDescription>
            <SummaryDescription label="Model">{assetModel ? assetModel : 'N/A'}</SummaryDescription>
            <SummaryDescription label="VIN">{assetVin ? assetVin : 'N/A'}</SummaryDescription>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={6}>
          <Card className='h-100' title="Payments" >
            <SummaryDescription label="Payment Amount">{payment ? payment : 'N/A'}</SummaryDescription>
            <SummaryDescription label="Frequency">{paymentFrequency} </SummaryDescription>
            {data.calculator && data.calculator.calculatorId &&
              <a onClick={() => { history.push(`/admins/lease_calculators/${data.calculator.calculatorId}/edit`) }}>
                Payment Calculator
              </a>
            }
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} className='mt-20'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card title='Checklists'>
            <Space direction='vertical'>
              {
                !disableFlag && <a href={disableFlag ? "javascript:void(0)" : `/online-funding-approval-checklist/${id}`} id='funding-approval-checklist'>Funding Approval</a>
              }
              {
                displayVerificationCall && !disableFlag &&
                <a onClick={() => { history.push('/online-verification-call-checklist/' + id); }} >Verification Call </a>
              }
            </Space>
          </Card>
        </Col>
      </Row>
    </>
  ) : null;
};
