import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
export default function SubmitAlertModel(props) {

  const { modalState, modalClose, contentData } = props;
  return (
    <Dialog
      open={modalState}
      onClose={modalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
       Submit To Speed Leasing ! 
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {contentData}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={modalClose} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}