import React, { useState,useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, Layout, Menu, Collapse, message, Button, Result, Spin } from 'antd';
import Icon, {LogoutOutlined} from '@ant-design/icons';
import { POST } from '../../util/network'
import MainHeader from '../../components/layouts/MainHeader';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import MainFooter from '../../components/layouts/MainFooter';
import { SiderProps } from '../../components/layouts/MainSiderProps';
import { GlobeSvg } from '../../components/layouts/Svg';
import Lms from './Lms';
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';

const { Content, Sider } = Layout;
const { Panel } = Collapse;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function ControlPanel(props) {
  const { allowedActions, handleNoAuth } = props;
  const history = useHistory();
  let hash = window.location.hash || null;
  
  const adminUserActions = GetResourceActions('AdminUser', allowedActions);
  const allowedGet = IsActionAllowed('get', adminUserActions);
  const allowedUpdate = IsActionAllowed('update', adminUserActions);

  let Menus = [];

  if (allowedGet || allowedUpdate) {
    Menus.push(
      {
        label: 'Dealer Log Out',
        componentLabel: null,
        iconType: <LogoutOutlined/>,
        isAllowedToShow: true,
        menuKey: 1,
      },
      {
        label: 'LMS',
        componentLabel: null,
        iconType: <Icon component={GlobeSvg} />,
        isAllowedToShow: true,
        menuKey: 2,
      }
    )
  }  

  const [activeMenu, setActiveMenu] = useState([1]);
  const [loading, setLoading] = useState(false);

  const dealearLogout = async () => {
    if (allowedUpdate) {
      setLoading(true);
      
      try {
        let res = await POST(`/api/v1/logout_all_dealears`, {});

        if (res.data.status) {
          message.success("All dealers have been logged out");          
        }
      } catch (e) {
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Logout Unsuccessful');
        }        
      }

      setLoading(false);
    }
  }

  const handleUrlHash = () => {
    if(hash !== null)
    {
      let panelHash = hash.match(/#(.*?)-p$/)
      if(panelHash && panelHash[1])
      {
        let menu = Menus.find((menuItem) => menuItem.label === panelHash[1].replace(/%20/g, ' ')) || null;
        if (menu !== null)
        {
          let x = document.getElementById('item-'+menu?.menuKey);
          x.click();
          setActiveMenu([menu?.menuKey])
        }
      }
    }
  }

  useEffect(() => {
    handleUrlHash()
  }, []);

  return(
    <Spin 
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      <Layout className="layout">
        <MainHeader activePage="administration"/>
        <Layout>
          <Sider {...SiderProps} >
            <Menu
              defaultSelectedKeys={[1]}
              selectedKeys={[activeMenu.toString()]}
              style={{ width: '100%' }}
              onSelect={({ key }) => { if (!key.includes("sub")){ setActiveMenu([...activeMenu, key]); } }}
            >
              {
                Menus.map(({ label, iconType, menuKey, isAllowedToShow }) => {
                  return (
                    !isAllowedToShow ? null : (
                      <Menu.Item
                        key={(menuKey).toString()}
                      > 
                        <a href={`#${label}-p`} id= {'item-'+menuKey}>
                          {iconType}
                          {label}
                        </a>
                      </Menu.Item>
                    )
                  )
                })
              }
            </Menu>
          </Sider>

          <Layout id='content-area-layout'>
            { allowedGet || allowedUpdate &&
              <MainBreadcrumb items={
                [{ text: "Home", link_type: "linkto", link: "/dashboard" },
                { text: "Administration", link_type: "ahref", link: void(0)  },
                { text: "Control Panel", link_type: "ahref", link: void(0) }]
                }
              />
            }

            {
              allowedGet 
                ?
                  <Content id='main-content'>
                    <Form 
                      {...layout}  
                      colon={false}
                      scrollToFirstError={true}
                    >
                      <Row gutter={[0, 24]}>
                        <Col span={24}>                      
                          <Collapse 
                            accordion={false} 
                            onChange={(key) => { key !== undefined && setActiveMenu(key); }} 
                            activeKey={activeMenu}
                          >
                            { allowedUpdate &&
                              <Panel 
                                header="Dealer Log Out" 
                                key="1"
                                className='slc-panel'
                              >                          
                                <Button type="primary" onClick={dealearLogout}>Log Out Dealers</Button>
                              </Panel>
                            }
                            <Panel 
                              header="Lease Management System Integration" 
                              key="2"
                              className='slc-panel'
                            >
                              <Lms 
                                allowedGet={allowedGet}
                                allowedUpdate={allowedUpdate}
                                handleNoAuth={handleNoAuth}
                              />
                            </Panel>
                          </Collapse>
                        </Col>
                      </Row>
                    </Form>
                  </Content>
                :
                  <Result 
                    status='warning'
                    title='You are not allowed to access this page.'
                  />                  
            }
            </Layout>
          </Layout>
          <MainFooter/>
        </Layout>
    </Spin>
  );
}