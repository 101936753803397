import React, { useState, useEffect } from "react";
import {
  Col,
  Layout,
  Menu,
  Row,
  Button,
  Form,
  Input,
  Collapse,
  Table,
  message,
  Spin,
} from "antd";
import Icon from "@ant-design/icons";
import { SiderProps } from "../../components/layouts/MainSiderProps";
import { LesseeSvg, AddNew } from "../../components/layouts/Svg";
import MainFooter from "../../components/layouts/MainFooter";
import MainHeader from "../../components/layouts/MainHeader";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import { GET } from "../../util/network";
import logger from "../../util/logger";
import { Link } from "react-router-dom";
import IsActiveSwitch from "./components/IsActiveSwitch";
import { PUT } from "../../util/network";

const { Content, Sider } = Layout;
const { Panel } = Collapse;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function CbcUsers(...props) {
  const { handleNoAuth } = props;
  const [cbcUserForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [activeMenu, setActiveMenu] = useState([1]);
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [cbcUsers, setCbcUsers] = useState([]);
  const [masterCbcUser, setMasterCbcUser] = useState('');

  const Menus = [
    {
      label: "Master User",
      componentLabel: null,
      iconType: <Icon component={LesseeSvg} />,
      isAllowedToShow: true,
      menuKey: 1,
      link: "#",
    },
    {
      label: "CBC Users List",
      componentLabel: "userList",
      iconType: <Icon component={LesseeSvg} />,
      isAllowedToShow: true,
      menuKey: 2,
      link: "/administration/cbc-users/list",
    },
    {
      label: "Add New",
      componentLabel: null,
      iconType: <Icon component={AddNew} />,
      isAllowedToShow: true,
      menuKey: 3,
      link: "/administration/cbc-users/add-new",
    },
  ];
  const activeMenuList = Menus.filter((menu) => menu.isAllowedToShow);
  const cbcUserCol = [
    {
      title: "User Id",
      dataIndex: "user_id",
      key: "user_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render(val, row) {
        return <IsActiveSwitch isActive={val} rowData={row} />;
      },
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Password Changed At",
      dataIndex: "password_changed_at",
      key: "password_changed_at",
    },
    {
      title: "Password Expiry",
      dataIndex: "password_expiry",
      key: "password_expiry",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "",
      render(val) {
        return val ? (
          <Link to={`/administration/cbc-users/${val.user_id}/change-password`}>
            Change Password
          </Link>
        ) : null;
      },
    },
  ];

  const handlePasswordChangeClick = () => {
    setShowPasswordChange(true);
  };

  const handleCancelPassword = () => {
    setShowPasswordChange(false);
  };

  const save = async (values) => {
    setLoading(true);
    try {
      await PUT(`/api/v1/cbc-users/${masterCbcUser}/change-pass`, values)
        .then(() => {
          message.success("Saved successfully");
          handleCancelPassword();
        })
        .catch((error) => {
          message.error(error.response.data?.message);
          if (error.response && error.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error("Error while saving!");
          }
        });
    } catch (e) {
      logger.error("Error while saving!", e);
    }
    setLoading(false);
  };

  const handleSubmit = async (values) => {
    values = { ...values };
    save(values)
  };

  const getCbcUserList = async () => {
    setLoading(true);
    try {
      const response = await GET(`/api/v1/cbc-users`);
      setMasterCbcUser(response.data?.master_user_id);
      setCbcUsers(response.data?.cbc_users);
    } catch (e) {
      logger.error("Error while fetching Credit Report", e);
      message.error("Error while fetching cbc users");
    }
    setLoading(false);
  }

  useEffect(() => {
    getCbcUserList();
  }, [])

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <MainHeader activePage="administration" />
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              onSelect={({ key }) => {
                if (!key.includes("sub")) {
                  setActiveMenu([...activeMenu, key]);
                }
              }}
            >
              {activeMenuList.map(({ label, iconType, link, menuKey }) => {
                return (
                  <Menu.Item key={menuKey.toString()}>
                    <a href={`${link}`}>
                      {iconType}
                      {label}
                    </a>
                  </Menu.Item>
                );
              })}
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            {" "}
            {
              <MainBreadcrumb
                items={[
                  { text: "Administration", link_type: "ahref", link: void 0 },
                  { text: "CBC Users" },
                ]}
              />
            }
            <Content id="main-content">
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <Collapse
                    accordion={false}
                    activeKey={activeMenu}
                    onChange={(key) => {
                      key !== undefined && setActiveMenu(key);
                    }}
                  >
                    <Panel header="Master User" key="1" className="slc-panel">
                      <Form
                        form={cbcUserForm}
                        {...layout}
                        colon={false}
                        onFinish={handleSubmit}
                      >
                        <Row gutter={[0, 24]}>
                          <Col span={10}>
                            <Form.Item label="Master User ID">
                              <Input value={masterCbcUser} disabled={true} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[0, 24]}>
                          <Col span={10}>
                            {showPasswordChange ? (
                              <>
                                <Form.Item
                                  label="New Password"
                                  name="password"
                                  hasFeedback
                                  messageVariables={{ name: "Password" }}
                                  rules={[
                                    {
                                      required: true,
                                      min: 8,
                                      max: 20,
                                    },
                                  ]}
                                >
                                  <Input.Password />
                                </Form.Item>
                                <Form.Item>
                                  <Button type="primary" htmlType="submit">
                                    Save
                                  </Button>
                                  <Button
                                    type="primary"
                                    onClick={handleCancelPassword}
                                    style={{ marginLeft: "0.5rem" }}
                                  >
                                    Cancel
                                  </Button>
                                </Form.Item>
                              </>
                            ) : (
                              <Form.Item>
                                <Button
                                  type="primary"
                                  onClick={handlePasswordChangeClick}
                                >
                                  Change Password
                                </Button>
                              </Form.Item>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </Panel>

                    <Panel
                      header="CBC Users List"
                      key="2"
                      className="slc-panel"
                    >
                      <Table
                        rowKey="id"
                        columns={cbcUserCol}
                        dataSource={cbcUsers || []}
                        pagination={false}
                        size="small"
                        className="slc-table"
                      />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    </Spin>
  );
}