import React, {useEffect, useState} from "react";
import {Col, Form, Layout, message, Row, Spin, Typography} from "antd";
import "../../../App.css";
import {useHistory, useParams} from "react-router-dom";
import {GET} from "../../../util/network";
import logger from "../../../util/logger";
import MainHeader from "../../../components/layouts/MainHeader";
import MainBreadcrumb from "../../../components/layouts/MainBreadcrumb";
import MainFooter from "../../../components/layouts/MainFooter";
import {Content} from "antd/es/layout/layout";
import {TextField, makeStyles, Grid} from "@material-ui/core";

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};


const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "50px",
    background: "white",
    // boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)'
  },
  textField: {
    width: "70%",
    marginBottom: 2,
  },
}));

export default function DealershipEmail({handleNoAuth}) {
  let {id, email_id} = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [dealershipEmailForm] = Form.useForm();
  const [emailData, setEmailData] = useState({});
  const [loading, setLoading] = useState(false);

  const getEmail = async () => {
    setLoading(true);
    try {
      const response = await GET(`/api/v1/dealerships/${id}/emails/${email_id}`);
      setEmailData(response.data.email);
      message.success('Successfully fetched email details.');
    } catch (e) {
      logger.error("Email details fetching Error.", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error on email details fetch.');
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    getEmail();
  }, []);

  console.log("Printing id: ", id)
  console.log("Printing email_id: ", email_id)
  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <MainHeader activePage="dealers"/>
        <Layout>
          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                {text: "Home", link_type: "linkto", link: "/dashboard"},
                {text: "Dealerships", link_type: "linkto", link: "/dealerships"},
                {
                  text: "Dealership Activities",
                  link_type: "linkto",
                  link: `/dealership/${id}/activities`,
                },
                {
                  text: "email",
                  link_type: "ahref",
                  link: void 0,
                },
                {
                  text: `${email_id}`,
                  link_type: "ahref",
                  link: void 0,
                },
              ]}
            />

            <Content id="main-content">
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  {/*<Form*/}
                  {/*  form={dealershipEmailForm}*/}
                  {/*  {...layout}*/}
                  {/*  // colon={false}*/}
                  {/*  onFinish={printHello}*/}
                  {/*  initialValues={{*/}
                  {/*    id: emailData?.id,*/}
                  {/*    subject: emailData?.subject,*/}
                  {/*    body: emailData?.body,*/}
                  {/*    recipient_email: emailData?.recipient_email,*/}
                  {/*    sender: emailData?.sender,*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <Row gutter={[16, 16]}>*/}
                  {/*    <Col span={24}>*/}
                  {/*      <Card title="Email Info">*/}
                  {/*        <Row gutter={[24, 16]}>*/}
                  {/*          <Col xs={12} sm={12} md={24} lg={12} xl={12}>*/}
                  {/*            <Form.Item*/}
                  {/*              label="Email Subject"*/}
                  {/*              name="subject"*/}
                  {/*              // hasFeedback*/}
                  {/*            >*/}
                  {/*              <Input disabled/>*/}
                  {/*            </Form.Item>*/}

                  {/*            <Form.Item*/}
                  {/*              label="Email Body"*/}
                  {/*              name="body"*/}
                  {/*              // hasFeedback*/}
                  {/*            >*/}
                  {/*              <Input disabled/>*/}
                  {/*            </Form.Item>*/}
                  {/*          </Col>*/}
                  {/*          <Col xs={12} sm={12} md={24} lg={12} xl={12}>*/}
                  {/*            <Form.Item*/}
                  {/*              label="Recipient Email"*/}
                  {/*              name="recipient_email"*/}
                  {/*              hasFeedback*/}
                  {/*            >*/}
                  {/*              <Input disabled/>*/}
                  {/*            </Form.Item>*/}

                  {/*            <Form.Item*/}
                  {/*              label="Email Sender"*/}
                  {/*              name="sender"*/}
                  {/*              hasFeedback*/}
                  {/*            >*/}
                  {/*              <Input disabled/>*/}
                  {/*            </Form.Item>*/}
                  {/*          </Col>*/}
                  {/*        </Row>*/}
                  {/*      </Card>*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*</Form>*/}

                  <div className={classes.root}>
                    <h3>Email info</h3>
                    <form>
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <Typography variant="subtitle1">
                            Subject
                          </Typography>
                        </Grid>
                        <Grid item md={12}>
                          <TextField
                            className={classes.textField}
                            // label="Subject"
                            variant="outlined"
                            value={emailData?.subject}
                            size="small"
                            disabled
                          />
                        </Grid>

                        <Grid item md={12}>
                          <Typography variant="subtitle1">
                            Recipient Email
                          </Typography>
                        </Grid>
                        <Grid item md={12}>
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            value={emailData?.recipient_email}
                            size="small"
                            disabled
                          />
                        </Grid>

                        <Grid item md={12}>
                          <Typography variant="subtitle1">
                            Sender
                          </Typography>
                        </Grid>
                        <Grid item md={12}>
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            value={emailData?.sender}
                            size="small"
                            disabled
                          />
                        </Grid>

                        <Grid item md={12}>
                          <Typography variant="subtitle1">
                            Body
                          </Typography>
                        </Grid>
                        <Grid item md={12}>
                          <TextField
                            className={classes.textField}
                            variant="outlined"
                            multiline
                            rows={5}
                            size="small"
                            value={emailData?.body}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter/>
      </Layout>
    </Spin>
  );
}
