import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { message, Result, Spin } from "antd";
import Workflow from "./Workflow";
import { GET } from '../../util/network';
import logger from '../../util/logger';
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';

export default function WorkflowRenderer(props) {
  const { allowedActions, handleNoAuth } = props; 
  const history = useHistory();

  const adminUserActions = GetResourceActions('AdminUser', allowedActions);
  const allowedGet = IsActionAllowed('get', adminUserActions);

  const [loading, setLoading] = useState(false);
  const [adminUsers, setAdminUsers] = useState();
  const [workflowSettingValues, setWorkflowSettingValues] = useState();

  const getWorkflowSettingValues = async () => {
    setLoading(true);
    try {
      const response = await GET(`/api/v1/workflow-setting-values`);
      setWorkflowSettingValues(response.data);
    } catch (e) {
      logger.error("Error while fetching Workflow Setting values", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error getting Workflow Setting values');
      }
    }
    setLoading(false);
  };  

  const getAdminUsers = async () => {
    setLoading(true);
    try {
      const response = await GET(`/api/v1/admin-users?active=1`);
      setAdminUsers(response.data?.admins || []);
    } catch (e) {
      logger.error("Error while fetching Admin Users", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error getting Admin Portal users');
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (allowedGet) {
      getWorkflowSettingValues();
      getAdminUsers();
    }
  }, []);

  return (
    <Spin 
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      {
        allowedGet 
          ?
            <>
            { adminUsers && 
              <Workflow 
                adminUserData={adminUsers} 
                handleNoAuth={handleNoAuth}
                loading={loading}
                setLoading={setLoading} 
                workflowSettingValues={workflowSettingValues} 
              />  
            }
            </>
          :
            <Result 
              status='warning'
              title='You are not allowed to access this page.'
            />
      }
    </Spin>
  )
}