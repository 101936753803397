import React, {useState} from "react";
import {
  Button,
  Card,
  Layout,
  Menu,
  Spin,
} from "antd";
import {styled} from '@mui/material'
import {Table, TableBody, TableContainer, TableRow} from '@mui/material'
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import {SiderProps} from "../../components/layouts/MainSiderProps";
import {AddNew} from "../../components/layouts/Svg";
import Icon from "@ant-design/icons";
import Box from '@mui/material/Box';
import Typography from "@material-ui/core/Typography";
import {useHistory} from "react-router-dom";

const {Content, Sider} = Layout;

export default function LesseeView(props) {
  const {data} = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{width: "100%"}}
            >
              <Menu.Item key="1">
                <Icon component={AddNew}/>
                Lessee
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                {text: "Home", link_type: "linkto", link: "/dashboard"},
                {text: "Lessee Related", link_type: "ahref", link: void 0},
                {
                  text: "Lessees",
                  link_type: "ahref",
                  link: "/lessee-list",
                },
                {text: "Details", link_type: "ahref", link: void 0},
              ]}
            />

            {data && (
              <Box sx={{flexGrow: 1}}>
                <Grid container direction="row" style={{height: "auto"}}>
                  <Grid item md={12} container spacing={2}>
                    <Grid container direction="row" md={12} sm={12} lg={12} style={{marginTop: '5px', marginLeft: '15px'}}>
                      <Grid item md={8} sm={6} style={{display: "flex", justifyContent: "flex-start"}}>
                        <Typography variant={"h4"}>{data.fullName}</Typography>
                      </Grid>
                      <Grid item md={4} sm={6} style={{display: "flex", justifyContent: "flex-end"}}>
                        <Button
                          variant="text"
                          onClick={() => {
                            history.push({
                              pathname: `/lessee_edit/${data.id}`,
                            });
                          }}
                        >
                          Edit Lessee
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item md={12} sm={12} lg={12}>
                      <Card title="Lessee Details">
                        <Grid container direction="row">
                          <Grid item md={16} container spacing={2}>
                            <Grid item md={12} sm={12} lg={12}>
                              <TableContainer component={Box}>
                                <Table aria-label="customized table">
                                  <TableBody>
                                    <StyledTableRow>
                                      <StyledtableCell1>ID</StyledtableCell1>
                                      <StyledtableCell2>{data.id}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>LEASE APPLICATION</StyledtableCell1>
                                      <StyledtableCell2>
                                        <a onClick={() => { history.push(`/lease-applications/${data.leaseApplicationId}`); }} >{data.leaseApplicationIdentifier}</a>
                                      </StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>DEALERSHIP</StyledtableCell1>
                                      <StyledtableCell2>{data.dealership}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>NAME</StyledtableCell1>
                                      <StyledtableCell2>{data.fullName}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>SUFFIX</StyledtableCell1>
                                      <StyledtableCell2>{data.suffix}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>SSN</StyledtableCell1>
                                      <StyledtableCell2>{data.ssn}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>DATE OF BIRTH</StyledtableCell1>
                                      <StyledtableCell2>{data.dateOfBirth}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>MOBILE PHONE NUMBER</StyledtableCell1>
                                      <StyledtableCell2>{data.mobilePhoneNumber}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>MOBILE PHONE NUMBER LINE</StyledtableCell1>
                                      <StyledtableCell2>{data.mobilePhoneNumberLine}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>MOBILE PHONE NUMBER CARRIER</StyledtableCell1>
                                      <StyledtableCell2>{data.mobilePhoneNumberCarrier}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>HOME PHONE NUMBER</StyledtableCell1>
                                      <StyledtableCell2>{data.homePhoneNumber}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>HOME PHONE NUMBER LINE</StyledtableCell1>
                                      <StyledtableCell2>{data?.homePhoneNumberLine}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>HOME PHONE NUMBER CARRIER</StyledtableCell1>
                                      <StyledtableCell2>{data?.homePhoneNumberCarrier}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>DRIVERS LICENSE ID NUMBER</StyledtableCell1>
                                      <StyledtableCell2>{data.driversLicenseIdNumber}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>DRIVERS LICENSE STATE</StyledtableCell1>
                                      <StyledtableCell2>{data.licenseState}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>EMAIL ADDRESS</StyledtableCell1>
                                      <StyledtableCell2>{data.emailAddress}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>EMPLOYMENT DETAILS</StyledtableCell1>
                                      <StyledtableCell2>{data.employmentDetails}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>HOME ADDRESS</StyledtableCell1>
                                      <StyledtableCell2>{data?.homeAddressString}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>MAILING ADDRESS</StyledtableCell1>
                                      <StyledtableCell2>{data?.mailingAddressString}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>EMPLOYMENT ADDRESS</StyledtableCell1>
                                      <StyledtableCell2>{data?.employmentAddressString}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>AT ADDRESS YEARS</StyledtableCell1>
                                      <StyledtableCell2>{data?.atAddressYears}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>AT ADDRESS MONTHS</StyledtableCell1>
                                      <StyledtableCell2>{data?.atAddressMonths}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>MONTHLY MORTGAGE</StyledtableCell1>
                                      <StyledtableCell2>{data.monthlyMortgage}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>HOME OWNERSHIP</StyledtableCell1>
                                      <StyledtableCell2>{data?.homeOwnershipString}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>DRIVERS LICENCE EXPIRES AT</StyledtableCell1>
                                      <StyledtableCell2>{data?.driversLicenceExpiresAt}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>EMPLOYER NAME</StyledtableCell1>
                                      <StyledtableCell2>{data.employerName}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>TIME AT EMPLOYER YEARS</StyledtableCell1>
                                      <StyledtableCell2>{data.timeAtEmployerYears}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>TIME AT EMPLOYER MONTHS</StyledtableCell1>
                                      <StyledtableCell2>{data.timeAtEmployerMonths}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>JOB TITLE</StyledtableCell1>
                                      <StyledtableCell2>{data.jobTitle}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>EMPLOYMENT STATUS</StyledtableCell1>
                                      <StyledtableCell2>{data.employmentStatus}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>EMPLOYER PHONE NUMBER</StyledtableCell1>
                                      <StyledtableCell2>{data.employerPhoneNumber}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>EMPLOYER PHONE NUMBER LINE</StyledtableCell1>
                                      <StyledtableCell2>{data?.employerPhoneNumberLine}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>EMPLOYER PHONE NUMBER CARRIER</StyledtableCell1>
                                      <StyledtableCell2>{data?.employerPhoneNumberCarrier}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>GROSS MONTHLY INCOME</StyledtableCell1>
                                      <StyledtableCell2>{data.grossMonthlyIncome}</StyledtableCell2>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                      <StyledtableCell1>GROSS MONTHLY INCOME</StyledtableCell1>
                                      <StyledtableCell2>{data?.otherMonthlyIncome}</StyledtableCell2>
                                    </StyledTableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Layout>
        </Layout>
      </Layout>
    </Spin>
  )
}

const StyledtableCell1 = styled(TableCell)({
  fontWeight: "bold",
  fontSize: "15px",
  width: "300px"
})

const StyledTableRow = styled(TableRow)({
  "& td": {border: 0, padding: 15},
})

const StyledtableCell2 = styled(TableCell)({
  fontSize: "15px"
})
