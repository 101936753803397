import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, message, Result, Spin } from 'antd';
import { GET, PUT, POST, DELETE } from '../../../util/network';
import logger from '../../../util/logger';
import BannerTable from './BannerTable';
import BannerFrom from './BannerFrom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export default function Banner(props) {
  const { allowedGet, allowedUpdate, handleNoAuth, loading, setLoading } = props;
  const history = useHistory();

  const [form] = Form.useForm();
  const [data, setData] = useState(false);
  const [bannerDealerShips, setBannerDealerShips] = useState();
  const bannerData = {
    id: '',
    headline: "",
    message: "",
    dealers: [],
    active: false
  }

  const [editable, setEditable] = useState(false)

  const getBannerMessage = async () => {
    if (allowedGet) {
      setLoading(true);

      try {
        let response = await GET('/api/v1/banner/admin-banner-list');
        setData(response.data.Banners);
      } catch (e) {
        logger.error('Request Error', e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error getting banner');
        }
      }

      setLoading(false);
    }
  }

  const handleSubmit = async (values) => {

    if (allowedUpdate) {
      values = { ...values };

      if (editable) {
        save(values, 1);
      } else {
        save(values, 0);
      }

    }
  }

  const onChangeCheckbox = (event) => {
    form.setFieldsValue({ [event.target.id]: event.target.checked });
  };

  const save = async (values, flag) => {
    setLoading(true);
    try {
      let formData = new FormData()
      formData.append("id", values.id)
      formData.append("headline", values.headline)
      formData.append("message", values.messages)
      formData.append("dealers", values.dealers)
      formData.append("active", values.active)
      if (flag == 1) {
        await PUT(`/api/v1/banner/${values.id}`, formData);
        message.success('Data saved successfully');
      } else {
        await POST(`/api/v1/banner/create-banner`, formData);
        message.success('Data Updated successfully');
      }


    } catch (e) {
      logger.error('Error saving changes to banner message: ', e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while saving your data');
      }
    }
    setEditable(false)
    getBannerMessage()
    form.resetFields()
    setLoading(false);
  };

  const editBanner = (banner) => {
    form.setFieldsValue({
      messages: banner.message,
      headline: banner.headline,
      active: banner.active,
      dealers: banner.dealers.split(',').map(Number),
      id: banner.id
    });

    setEditable(true)
    // setBannerData(old => ({ ...old, headline: banner.headline, message: banner.message, id: banner.id, active: banner.active }))
  }

  const deleteBanner = async (id) => {
    try {

      await DELETE(`/api/v1/banner/${id}`);
      getBannerMessage()
      message.success('Data deleted successfully');
    } catch (e) {
      logger.error('Error deleteing  banner message: ', e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while deleting your data');
      }
    }

  }

  const getDealerships = async () => {
    setLoading(true);
    try {
      let response = await GET(`/api/v1/promotions/dealerships`);
      setBannerDealerShips(response.data.dealership)
      getBannerMessage();
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while fetching your dealership data');
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    getDealerships()
  }, []);

  return (
    <Spin
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      <>
        {allowedGet
          ?
          <Box>
            <Grid container direction="row">
              <Grid item md={12}>
                <BannerFrom
                  data={data}
                  onChangeCheckbox={onChangeCheckbox}
                  save={save}
                  allowedUpdate={allowedUpdate}
                  handleSubmit={handleSubmit}
                  bannerData={bannerData}
                  form={form}
                  editable={editable}
                  bannerDealerShips={bannerDealerShips}
                />
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item md={12}>
                <Box sx={{ width: "100%", height: "400px" }}>
                  <BannerTable data={data}
                    editBanner={editBanner}
                    deleteBanner={deleteBanner}
                    bannerDealerShips={bannerDealerShips} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          :
          <Result
            status='warning'
            title='You are not allowed to access this data.'
          />
        }
      </>
    </Spin>
  );
}