import React, { useState } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FormGroup } from "semantic-ui-react";
import Switch from '@mui/material/Switch';
import Drawer from '@mui/material/Drawer';
import Filters from './Filters';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AnalyticsCard from "./components/AnalyticsCard";
import Tooltip from '@mui/material/Tooltip';
import AssignmentReturnedSharpIcon from '@mui/icons-material/AssignmentReturnedSharp';
import RefreshIcon from '@mui/icons-material/Refresh';
import { StyledFormControlLabel, StyledExportIcon, StyledDeclineIcon, StyledChipApproved, StyledClearFilter, StyledChipConversionRate, StyledPublishSharpIcon, StyledCheckBoxSharpIcon, StyledPaidSharpIcon, StyleTransformSharpIcon } from "./components/FilterComponents/AnalyticsStyles";
import { clearFilterState } from '../../reducers/LeaseApplications'
import { useDispatch, useSelector } from "react-redux";

export default function Analytics(props) {
    const { convertionPercentage, sortModel,handleRefresh, updateFilterSorting, setFilterFlag, handleExportModalOpen, filterState, pageState, approvedPercentage, declinedPercentage, approved, declined, submitted, filterDefaultData, totalSalePrice, funded } = props;
    const [filter, setFilter] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setFilter(open)
    };
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down(1025));
    const dispatch = useDispatch();
    const clearIconState = useSelector((state) => state.leaseApplications.clearIconState);

    const handleDelete = () => {
        dispatch(clearFilterState({}))
    };

    return (
        <Box sx={{ flexGrow: 1, pb: 2, mb: 5 }} >
            <Grid container spacing={2} direction="row"
                justifyContent="flex-end"
                alignItems="flex-end">
                <Grid container >
                    <Grid item md={12} >
                        <FormGroup >
                            <StyledFormControlLabel control={<Switch checked={filter} onChange={() => setFilter(value => !value)} color="warning" />} />
                        </FormGroup>
                        {clearIconState === true && <StyledClearFilter label="Clear Filter" onDelete={handleDelete} />}
                        <Tooltip title="Export CSV">
                            <StyledExportIcon onClick={handleExportModalOpen}>
                                <AssignmentReturnedSharpIcon />
                            </StyledExportIcon>
                        </Tooltip>
                        <Drawer
                            anchor="left"
                            open={filter}
                            onClose={toggleDrawer(false)}
                            elevation={6}
                        >
                            <Filters setFilter={setFilter}
                                filter={filter}
                                setFilterFlag={setFilterFlag}
                                toggleDrawer={toggleDrawer}
                                pageState={pageState}
                                filterState={filterState}
                                sortModel={sortModel}
                                updateFilterSorting={updateFilterSorting}
                                filterDefaultData={filterDefaultData} />
                        </Drawer>
                        <Tooltip title="Refresh">
                            <StyledExportIcon onClick={handleRefresh}>
                                <RefreshIcon />
                            </StyledExportIcon>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Grid item md={12} container spacing={isSmall ? 2 : 3} >
                    <Grid item md sm >
                        <AnalyticsCard
                            title={"Submitted"}
                            data={submitted}
                            Icon={StyledPublishSharpIcon}
                            hasChip={false}
                        />
                    </Grid>
                    <Grid item md sm>
                        <AnalyticsCard
                            title={"Approved"}
                            data={approved}
                            Icon={StyledCheckBoxSharpIcon}
                            hasChip={true}
                            percentage={approvedPercentage}
                            StyledChip={StyledChipApproved}
                        />
                    </Grid>
                    <Grid item md sm>
                        <AnalyticsCard
                            title={"Declined"}
                            data={declined}
                            Icon={StyledDeclineIcon}
                            hasChip={true}
                            percentage={declinedPercentage}
                            StyledChip={StyledChipApproved}
                        />
                    </Grid>
                    <Grid item md sm>
                        <AnalyticsCard
                            title={"Funded"}
                            data={funded}
                            Icon={StyledPaidSharpIcon}
                            hasChip={false}
                            totalSalePrice={totalSalePrice}
                        />
                    </Grid>
                    <Grid item md sm>
                        <AnalyticsCard
                            title={"Conversion Rate"}
                            Icon={StyleTransformSharpIcon}
                            hasChip={true}
                            percentage={convertionPercentage}
                            StyledChip={StyledChipConversionRate}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}