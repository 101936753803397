import React from "react";
import { Row, Col, Table } from "antd";

const columns = [{
    title: 'Code',
    dataIndex: 'errorCode',
    key: 'errorCode'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message'
  },
  {
    title: 'Failure Conditional',
    dataIndex: 'failureConditional',
    key: 'failureConditional',
    render(val) {
      return val.length > 0 ? JSON.stringify(val) : ''
    }
  }
];

export default function ErrorCodes({ data }) {
  const dataSource = data.leaseApplicationBlackboxRequest.leaseApplicationBlackboxErrors || []
  return data ? (
    <>
      <Row>
        <Col span={24}>
          <Table
            className='slc-table'
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            size="small"
          />
        </Col>
      </Row>
      <br/>
    </>
  ) : null ;
}
