import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function AdminUsersDialogModal(props) {

    const { unlockDialog, handleClose, adminUsers, slcRow, handleTransferOwnerModal } = props;
    const options = [];
    const [value, setValue] = useState(options[0]);

    useEffect(() => {
        adminUsers.forEach((admin) => {
            options.push({
                label: admin.name,
                name: admin.name,
                id: admin.id,
                firstName: admin.firstName
            })
        })
    });

    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "25%",
                    maxHeight: 400
                }
            }}
            open={unlockDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {"Admin Users"}
            </DialogTitle>
            <DialogContent dividers>
                <Autocomplete
                    id="combo-box-demo"
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    options={options}
                    renderInput={(params) => <TextField   {...params} size="small" label="Users" />}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { handleClose(); handleTransferOwnerModal(slcRow, value) }}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}
