import React, { useState } from 'react'
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: "1px solid black"
  }));


export default function TimeHistory(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <a href={`/lease-applications/${row.id}`}>{row.applicationIdentifier}</a>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.lesseeName}
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.dealerName}
                </TableCell>
                <TableCell>{row?.startTime}</TableCell>
                <TableCell>{row?.endTime}</TableCell>
                <TableCell>{row?.totalTime}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                    {row.leaseApplicationTimersGroup.length > 0 &&

                          <Box sx={{ margin: "1rem .5rem" }}>
                              <div style={{marginBottom: ".5rem", fontWeight: "bold"}}>
                                  Status Report:
                              </div>
                              <Table size="small" aria-label="status-history">
                                  <TableHead>
                                      <TableRow>
                                          <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0'}}>Application Status</StyledTableCell>
                                          <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0'}}>Reporting Status</StyledTableCell>
                                          <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0'}}>Status Category</StyledTableCell>
                                          <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0'}}>Locked By</StyledTableCell>
                                          <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0'}}>Is Locked</StyledTableCell>
                                          <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0'}}>Start Time</StyledTableCell>
                                          <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0'}}>End Time</StyledTableCell>
                                          <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0'}}>Time diff</StyledTableCell>
                                          <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0'}}>Total</StyledTableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {row.leaseApplicationTimersGroup.map((historyRow) => (
                                          <>
                                              {historyRow.data.map((detail, index) => (
                                                  <TableRow key={detail.id}>
                                                      <StyledTableCell>{detail.currentTimerStatus}</StyledTableCell>
                                                      <StyledTableCell>{detail.reportCategory}</StyledTableCell>
                                                      <StyledTableCell>
                                                          {detail.currentTimerStatusCategory}
                                                      </StyledTableCell>
                                                      <StyledTableCell>{detail.lockedUser}</StyledTableCell>
                                                      <StyledTableCell>{detail.isLocked ? "Locked" : "Unlocked"}</StyledTableCell>
                                                      <StyledTableCell>{detail.startDate}</StyledTableCell>
                                                      <StyledTableCell>{detail.endDate}</StyledTableCell>
                                                      <StyledTableCell>{detail.timeDifference}</StyledTableCell>
                                                      {index === 0 ? (
                                                          <StyledTableCell
                                                              component="th"
                                                              scope="row"
                                                              rowSpan={historyRow.data.length}
                                                              style={{fontWeight: "bold"}}
                                                          >
                                                              {historyRow.totalTime}
                                                          </StyledTableCell>
                                                      ) : null}
                                                  </TableRow>
                                              ))}
                                          </>
                                      ))}
                                  </TableBody>
                              </Table>
                              {
                                  row.endTime && (
                                      <p style={{ color: '#f44336', padding: '.65rem 0', fontWeight: 'bold' }}>
                                          This application is funded.
                                      </p>
                                  )
                              }
                          </Box>
                    }
                    </Collapse>
                </TableCell>
            </TableRow>
            {
                row.leaseApplicationApiTimersGroup.length > 0 &&

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: "1rem .5rem" }}>
                        <div style={{marginBottom: ".5rem", fontWeight: "bold"}}>
                                API Timer Report:
                            </div>
                            <Table size="small" aria-label="status-history">
                                <TableHead >
                                    <TableRow>
                                        <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0'}}>Application Status</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0' }}>API Name</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0' }}>API Category</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0'}}>API Status</StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0' }}>Start Time</StyledTableCell>
                                        <StyledTableCell align="left" style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0' }}>End Time</StyledTableCell>
                                        <StyledTableCell align="left" style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0' }}>Time diff</StyledTableCell>
                                        <StyledTableCell align="left" style={{ fontWeight: 'bold', backgroundColor: '#E8ECF0' }}>Total</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.leaseApplicationApiTimersGroup.map((historyRow) => (
                                        <>
                                            {historyRow.data.map((detail, index) => (
                                                <TableRow key={historyRow.id}>
                                                    <StyledTableCell>{detail.applicationStatus}</StyledTableCell>
                                                    <StyledTableCell>
                                                        {detail.currentTimerStatusCategory}
                                                    </StyledTableCell>
                                                    <StyledTableCell>{detail.reportCategory}</StyledTableCell>
                                                    <StyledTableCell>{detail.currentTimerStatus}</StyledTableCell>
                                                    <StyledTableCell>{detail.startDate}</StyledTableCell>
                                                    <StyledTableCell>{detail.endDate}</StyledTableCell>
                                                    <StyledTableCell>{detail.timeDifference}</StyledTableCell>
                                                    {index === 0 ? (
                                                        <StyledTableCell
                                                            component="th"
                                                            scope="row"
                                                            rowSpan={historyRow.data.length}
                                                            style={{fontWeight: "bold"}}
                                                        >
                                                            {historyRow.totalTime}
                                                        </StyledTableCell>
                                                    ) : null}
                                                </TableRow>
                                            ))}
                                        </>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            }
        </>
    )
}
