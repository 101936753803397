import React, { useEffect, useState } from 'react'
import {Button, Input, Layout, message, Spin} from "antd";
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TimeHistory from './component/TimeHistory';
import { GET } from '../../util/network';
import logger from '../../util/logger';
import Typography from "@mui/material/Typography";
import TablePagination from '@mui/material/TablePagination';
import {Grid} from "@material-ui/core";
import Box from "@mui/material/Box";
import TimeReportFilter from "./component/TimeReportFilter";
import TimeAverageReport from "./component/TimeAverageReport";
import {getDealershipOptions} from "../../reducers/DealerUsers";
import {useDispatch, useSelector} from "react-redux";
import {handleNoAuth} from "../../util/authenticate";
import { setAverageTimeData, setIsLoading } from "../../reducers/TimerReport";

const initialPageState = {
    page: 0,
    per_page: 25,
    search: '',
    lease_app_id: '',
    applicant_name: '',
    applicant_status: '',
    report_status: '',
    status_category: '',
    api_name: '',
    api_category: '',
    report_category: '',
    dealership_id: null,
    submission_start_date: '',
    submission_end_date: '',
    date: "month",
    dateRange: {
        startDate: null,
        endDate: null
    },
    filter_flag: false
}

export default function TimerReportDataGrid() {
    const dispatch = useDispatch();
    const { Search } = Input;
    const { Content } = Layout;
    const [loading, setLoading] = useState(false);
    const [pageState, setPageState] = useState(initialPageState);
    const [filter, setFilter] = useState(false);
    const [filterState, setFilterState] = useState(initialPageState);
    const dealershipsOptions = useSelector((state) => state.dealerUsers.dealerListOptions);
    const data = useSelector((state) => state.timerReport)
    const timerData = data?.leaseApplications;
    const handleChangePage = (event, newPage) => {
        setPageState({...pageState, page: parseInt(newPage)});
    };

    const handleChangeRowsPerPage = (event) => {
        setPageState({...pageState, per_page: parseInt(event.target.value)});
    };

  const getDealershipOptionsData = async () => {
    setLoading(true);
    try {
      const response = await GET(`/api/v1/dealerships/dealerships-options`);
      dispatch(getDealershipOptions({data: response.data.dealerships_options}));

    } catch (e) {
      logger.error("Error while fetching Dealerships Options", e);
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error("Error while fetching Dealerships Options");
      }
    }
    setLoading(false);
  };

    const onChange = (e) => {
        setFilterState({ ...filterState, search: e.target.value});
        if (e.target.value === '') {
            setFilterState({ ...filterState, search: ''});
            setPageState({ ...filterState, search: ''});
        }
    };

    const onSearch = () => {
        setPageState({ ...filterState, search: filterState.search});
    };
    const fetchtimerData = async () => {
        await dispatch(setIsLoading({loading: true}));
        var params = {
            pageState: { ...filterState },
            dateRange: {
                startDate: filterState.dateRange.startDate,
                endDate: filterState.dateRange.endDate,
            }
        };
        let response;
        let avgTimer;
        if (params.pageState !== undefined) {
            let encodedParam = Object.entries(params.pageState).map(([key, val]) => `${key}=${val}`).join('&');
            response = await GET(`/api/v1/lease-app-timer?${encodedParam}&&start_date=${params.dateRange.startDate}&&end_date=${params.dateRange.endDate}`);
            avgTimer = await GET(`/api/v1/lease-app-timer/average-time?${encodedParam}&&start_date=${params.dateRange.startDate}&&end_date=${params.dateRange.endDate}`);
        } else {
            response = await GET(`/api/v1/lease-app-timer`);
        }
        if (avgTimer?.data?.total_time_per_category_list?.length == 0) {
            message.error("No application found for this filter", 10);
        }
        await dispatch(setAverageTimeData({ data: response?.data, avgTimer: avgTimer?.data }));
    }

    useEffect(() => {
        if ((filterState.date === 'custom' && filterState.dateRange.startDate && filterState.dateRange.endDate) || filterState.date !== 'custom') {
            fetchtimerData();
        }
    }, [pageState])

    useEffect(() => {
      getDealershipOptionsData();
    }, [])

    const clearFilter = () => {
        setFilter(false);
        setFilterState({...initialPageState})
        if (pageState.filter_flag) {
            setPageState({...initialPageState})
        }
    }

    return (
        <Spin spinning={data?.isLoading}>
            <Layout>
                <MainHeader activePage="timerReport" />
                <Layout>
                    <Layout id='content-area-layout'>
                        <Content id='main-content' style={{ backgroundColor: "#ffff !important" }}>
                            
                            <TimeAverageReport
                                setLoading={setLoading}
                                logger={logger}
                                handleNoAuth={handleNoAuth}
                                dealershipsOptions={dealershipsOptions}
                                filterState={filterState}
                                setFilterState={setFilterState}
                                setPageState={setPageState}
                            />
                            <Box sx={{flexGrow: 1, pb: 2}}>
                                <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
                                    <Grid item md={11} sm={10} style={{ display: "flex", justifyContent: "flex-end" }}>
                                        {pageState.filter_flag && (
                                            <Button onClick={clearFilter} style={{ marginLeft: '5px' }}>
                                                Clear Filter
                                            </Button>
                                        )}
                                        <Button style={{ marginLeft: '5px' }} onClick={() => setFilter(true)}>
                                            Filters
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} style={{marginTop: '5px'}} direction="row" justifyContent="flex-end" alignItems="center">
                                    <Grid item md={9} sm={6}>
                                        <Typography variant={"h5"}>Application Turn Time Report</Typography>
                                    </Grid>
                                    <Grid item md={3} sm={6} style={{display: "flex", justifyContent: "right"}}>
                                        <Search
                                          placeholder="Global Search"
                                          size="large"
                                          value={filterState.search}
                                          onChange={onChange}
                                          allowClear
                                          className="leaseAppListSearch"
                                          onSearch={onSearch}
                                          enterButton
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <TimeReportFilter
                              setPageState={setPageState}
                              filter={filter}
                              setFilter={setFilter}
                              filterState={filterState}
                              setFilterState={setFilterState}
                              dealershipsOptions={dealershipsOptions}
                              clearFilter={clearFilter}
                            />
                            <TableContainer component={Paper}>
                                <Table aria-label="collapsible table">
                                    <TableHead style={{backgroundColor: '#001529'}}>
                                        <TableRow >
                                            <TableCell />
                                            <TableCell style={{fontWeight: 'bold', color: '#ffffffa6'}}>Lease App Identifier</TableCell>
                                            <TableCell style={{fontWeight: 'bold', color: '#ffffffa6'}}>Leessee Name</TableCell>
                                            <TableCell style={{fontWeight: 'bold', color: '#ffffffa6'}}>Dealer Name</TableCell>
                                            <TableCell style={{fontWeight: 'bold', color: '#ffffffa6'}}>Start Time</TableCell>
                                            <TableCell style={{fontWeight: 'bold', color: '#ffffffa6'}}>End Time</TableCell>
                                            <TableCell style={{fontWeight: 'bold', color: '#ffffffa6'}}>Total Time</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {timerData?.data && timerData?.data?.leaseApplications?.map((row) => (
                                            <TimeHistory key={row.id} row={row} />
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    component="div"
                                    count={timerData?.total || 0}
                                    page={pageState.page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={pageState.per_page}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableContainer>
                        </Content>
                    </Layout>
                </Layout>
                <MainFooter />
            </Layout >
        </Spin>
    )
}
