import React from 'react';
import ApprovalEvents from '../components/ApprovalEvents';

export default function CreditCommitteeApproval({ dealership, dealershipId, getDealership, ...props }) {  
  const underwritingApprovalBtn = dealership.salesApproval.approved && dealership.underwritingApproval.approved;
  const creditCommitteeApprovalBtn = dealership.creditCommitteeApproval.approved;
  const { allowedCreditComApprovalGet, allowedCreditComApprovalUpdate } = props;
  
  return dealership ? (
    <ApprovalEvents 
      allowedApprovalGet={allowedCreditComApprovalGet}
      allowedApprovalUpdate={allowedCreditComApprovalUpdate}
      approvalType='final' 
      disabled={!underwritingApprovalBtn} 
      isApproved={creditCommitteeApprovalBtn} 
      type="Credit Committee" 
      dealershipId={dealershipId} 
      getDealership={getDealership} 
      dataSource={dealership.creditCommitteeApproval.events}
      status ={dealership.status}
      underwritingApproval = {underwritingApprovalBtn}
    />
  ) : null;
}