import React, { useState, useEffect } from 'react'
import {LicenseInfo} from "@mui/x-license-pro";
import { POST } from "../../../util/network";
import { message } from "antd";
import { Table } from 'antd';

export default function AuditLog({ dealership, dealershipId, getDealership, ...props }) {
  const [auditLog, setAuditLog] = useState(null);
  const [pageState, setPageState] = useState({
    page: 0,
    limit: 10,
  });
  const [totalData, setTotalData] = useState(null);

  const column = [
    {
      dataIndex: "id",
      title: "ID",
      key: "id",
      width: 100,
      fixed: true,
    },
    {
      key: "user",
      dataIndex: "user",
      title: "User",
      width: 100,
      fixed: true,
    },
    {
      dataIndex: "dealership",
      title: "Dealership",
      key: "dealership",
      width: 200,
      fixed: true,
    },
    {
      dataIndex: "created_at",
      title: "Created At",
      key: "created_at",
      width: 200,
      fixed: true,
    },
    {
      dataIndex: "audited_changes",
      title: "Audited Changes",
      key: "audited_changes",
      render: (audited_changes) => <span dangerouslySetInnerHTML={{ __html: audited_changes }} />,
    },
  ];

  const getAuditLogs = async () => {
    try {
      const response = await POST(
        `/api/v1/dealerships/${dealershipId}/audits?page=${pageState.page}&limit=${pageState.limit}`
      );
      setAuditLog(response?.data.data);
      setTotalData(response?.data?.total)
    } catch (e) {
      message.error("Error while loading Audit logs.");
    }
  };

  useEffect(() => {
    getAuditLogs();
  }, [pageState]);

  return (
    auditLog &&
    <Table
    dataSource={auditLog}
    columns={column}
    className="slc-table"
    rowKey="id"
    size="small"
    scroll={{ x: 'max-content' }}
    onChange={ (pagination)=>{
      setPageState({ ...pageState, page: pagination.current - 1 });
    }}
    pagination={{
      total: totalData,
      showSizeChanger: false
   }}
  />
  )
}

