import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Form,
  Layout,
  Menu,
  message,
  Result,
  Row,
  Spin,
} from "antd";
import Icon from "@ant-design/icons";
import { PUT } from "../../util/network";
import logger from "../../util/logger";
import MainHeader from "../../components/layouts/MainHeader";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import MainFooter from "../../components/layouts/MainFooter";
import { SiderProps } from "../../components/layouts/MainSiderProps";
import { AddNew } from "../../components/layouts/Svg";
import { GetResourceActions, IsActionAllowed } from "../../util/AllowedActions";
import { useParams } from "react-router-dom";

const { Content, Sider } = Layout;

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const loggedUser = JSON.parse(localStorage.getItem("user_data"));
const allowedMails = process.env.REACT_APP_CBC_ALLOWED_MAILS;

export default function ChangeCbcPassword(props) {
  const params = useParams();
  const { allowedActions, handleNoAuth } = props;
  const history = useHistory();
  const adminUserActions = GetResourceActions("AdminUser", allowedActions);
  const allowedCreate = IsActionAllowed("create", adminUserActions);
  const [loading, setLoading] = useState(false);
  const [passwordValidateStatus] =
    useState(undefined);
  const [passwordErrorMsg] = useState(undefined);
  const [cbcUserForm] = Form.useForm();

  const cbcAllowedMails = allowedMails.split(",");
  const email = loggedUser?.email;
  const allowedUser = cbcAllowedMails.includes(email);

  const save = async (values) => {
    if (allowedCreate) {
      setLoading(true);
      try {
        await PUT(`/api/v1/cbc-users/${params.userId}/change-pass`, values)
          .then(() => {
            message.success("Saved successfully");
            history.push("/administration/cbc-users");
          })
          .catch((error) => {
            message.error(error.response.data?.message);
            if (error.response && error.response.status === 401) {
              handleNoAuth(history);
            } else {
              message.error("Error while saving!");
            }
          });
      } catch (e) {
        logger.error("Error while saving!", e);
      }
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    values = { ...values };
    save(values);
  };

  return allowedUser ? (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <MainHeader activePage="administration" />
        <Layout>
          <Sider {...SiderProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{ width: "100%" }}
            >
              <Menu.Item key="1">
                <Icon component={AddNew} />
                Change Pssword
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                { text: "Home", link_type: "linkto", link: "/dashboard" },
                { text: "Administration", link_type: "ahref", link: void 0 },
                {
                  text: "CBC Users",
                  link_type: "ahref",
                  link: "/administration/cbc-users",
                },
                { text: "Change Password", link_type: "ahref", link: void 0 },
              ]}
            />

            <Content id="main-content">
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  {!allowedCreate ? (
                    <Result
                      status="warning"
                      title="You are not allowed to access this page."
                    />
                  ) : (
                    <Form
                      form={cbcUserForm}
                      {...layout}
                      colon={false}
                      onFinish={handleSubmit}
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <Card title="CBC User Info">
                            <Row gutter={[24, 16]}>
                              <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                <Form.Item label="User Id">
                                  <Input
                                    value={`${params.userId}`}
                                    disabled={true}
                                  />
                                </Form.Item>

                                <Form.Item
                                  label="Confirm Password"
                                  name="password_confirmation"
                                  messageVariables={{
                                    name: "Password Confirmation",
                                  }}
                                  hasFeedback
                                  rules={[
                                    {
                                      required: true,
                                      min: 8,
                                      max: 20,
                                    },
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        if (
                                          !value ||
                                          getFieldValue("password") === value
                                        ) {
                                          return Promise.resolve();
                                        }
                                        return Promise.reject(
                                          new Error("Passwords do not match!")
                                        );
                                      },
                                    }),
                                  ]}
                                >
                                  <Input.Password />
                                </Form.Item>
                              </Col>
                              <Col xs={12} sm={12} md={24} lg={12} xl={12}>
                                <Form.Item
                                  label="New Password"
                                  name="password"
                                  hasFeedback
                                  messageVariables={{ name: "New Password" }}
                                  validateStatus={passwordValidateStatus}
                                  help={passwordErrorMsg}
                                  rules={[
                                    {
                                      required: true,
                                      min: 8,
                                      max: 20,
                                    },
                                  ]}
                                >
                                  <Input.Password />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]} className="mt-20">
                        <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                          <Row>
                            <Col span={24}>
                              <Form.Item>
                                <Button type="primary" htmlType="submit">
                                  Save
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Col>
              </Row>
            </Content>
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    </Spin>
  ) : (
    <Result status="warning" title="You are not allowed to access this page." />
  );
}
