import React from "react";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import ArticleIcon from '@mui/icons-material/Article';
import Chip from '@mui/material/Chip';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
export default function DocumentStatus(props) {
  const { params } = props;
  return (
    (params.row.DocumentStatus == "Funding Approved") && <Chip variant="outlined" sx={{ color: "#06a377" }} icon={<CheckCircleOutlineIcon style={{ color: "#06a377", fontSize: "14px" }} />} label="Funding Approved" />
    || (params.row.DocumentStatus == "Funding Delay") && <Chip variant="outlined" color="error" icon={<AssignmentLateIcon style={{ color: "red", fontSize: "14px" }} />} label="Funding Delay" />
    || (params.row.DocumentStatus == "Funded") && <Chip variant="outlined" color="success" icon={<CheckCircleOutlineIcon style={{ color: "green", fontSize: "14px" }} />} label="Funded" />
    || (params.row.DocumentStatus == "Canceled") && <Chip variant="outlined" color="error" icon={<CancelIcon style={{ color: "red", fontSize: "14px" }} />} label="Canceled" />
    || (params.row.DocumentStatus == "Lease Package Received") && <Chip variant="outlined" color="primary" icon={<CheckCircleOutlineIcon style={{ color: "blue", fontSize: "14px" }} />} label="Lease Package Received" />
    || (params.row.DocumentStatus == "Documents Issued") && <Chip variant="outlined" color="primary" icon={<ArticleIcon style={{ color: "blue", fontSize: "14px" }} />} label="Documents Issued" />
    || (params.row.DocumentStatus == "Documents Requested") && <Chip variant="outlined" color="primary" icon={<ArticleIcon style={{ color: "blue", fontSize: "14px" }} />} label="Documents Requested" />
    || (params.row.DocumentStatus == "No Documents") && <Chip variant="outlined" color="error" icon={<HourglassEmptyIcon style={{ color: "red", fontSize: "14px" }} />} label="No Documents" />
    || <></>
  )
}