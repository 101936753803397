import React, { useState,useEffect } from 'react';
import { Col, Collapse, Layout, Menu, Row, Result, Spin } from 'antd';
import Icon, { ToolOutlined } from '@ant-design/icons';
import { AuditSvg, BannerSvg, CalculatorSvg } from '../../components/layouts/Svg';
import MainHeader from '../../components/layouts/MainHeader';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import MainFooter from '../../components/layouts/MainFooter';
import { SiderProps } from '../../components/layouts/MainSiderProps';
import Banner from './Banner/Banner';
import DealStructure from './DealStructure/DealStructure';
import LeaseCalculator from './LeaseCalculator/LeaseCalculator';
import LeasePage from './LeasePage/LeasePage';
import './Settings.css';
import { GetResourceActions, IsActionAllowed } from '../../util/AllowedActions';
import Training from './Training/TrainingRenderer';
import Promotion from './Promotion/PromotionRenderer';

const { Content, Sider } = Layout;
const { Panel } = Collapse;

const Menus = [
  {
    label: 'Deal Structure',
    componentLabel: null,
    iconType: <ToolOutlined/>,
    Component: DealStructure,
    menuType: "parent",
    isAllowedToShow: true,
    menuKey: 1,
  },
  {
    label: 'Lease Calculator',
    componentLabel: null,
    iconType: <Icon component={AuditSvg}/>,
    Component: LeaseCalculator,
    menuType: "parent",
    isAllowedToShow: true,
    menuKey: 2,
  },
  {
    label: 'Lease Page',
    componentLabel: null,
    iconType: <Icon component={CalculatorSvg}/>,
    Component: LeasePage,
    menuType: "parent",
    isAllowedToShow: true,
    menuKey: 3,
  },
  {
    label: 'Banner',
    componentLabel: null,
    iconType: <Icon component={BannerSvg}/>,
    Component: Banner,
    menuType: "parent",
    isAllowedToShow: true,
    menuKey: 4,
  },
  {
    label: 'Promotion',
    componentLabel: null,
    iconType: <Icon component={BannerSvg}/>,
    Component: Promotion,
    menuType: "parent",
    isAllowedToShow: true,
    menuKey: 5,
  },
  {
    label: 'Training',
    componentLabel: null,
    iconType: <Icon component={BannerSvg}/>,
    Component: Training,
    menuType: "parent",
    isAllowedToShow: true,
    menuKey: 6,
  },
]

export default function Settings(props) {
  const { allowedActions, handleNoAuth } = props; 
  const adminUserActions = GetResourceActions('AdminUser', allowedActions);
  let allowedGet = IsActionAllowed('get', adminUserActions);
  let allowedUpdate = IsActionAllowed('update', adminUserActions);
  let hash = window.location.hash || null;

  const [activeMenu, setActiveMenu] = useState([1]);

  const [loading, setLoading] = useState(false);

  const activeMenuList =  Menus.filter(menu => menu.isAllowedToShow);  
  const mainMenuList =  Menus.filter(menu => menu.menuType === 'parent');

  const handleUrlHash = () => {
    if(hash !== null)
    {
      let panelHash = hash.match(/#(.*?)-p$/)
      if(panelHash && panelHash[1])
      {
        let menu = Menus.find((menuItem) => menuItem.label === panelHash[1].replace(/%20/g, ' ')) || null;
        if (menu !== null)
        {
          let x = document.getElementById('item-'+menu?.menuKey);
          x.click();
          setActiveMenu([menu?.menuKey])
        }
      }
    }
  }

  useEffect(() => {
    handleUrlHash()
  }, []);

  return (
    <Spin 
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      <Layout className="layout">
        <MainHeader activePage='settings-content'/>
        <Layout name='Settings'>
          <>
            {
            allowedGet
              ?
                <>
                  <Sider {...SiderProps} >
                    <Menu
                      defaultSelectedKeys={[1]}
                      selectedKeys={[activeMenu.toString()]}
                      style={{ width: '100%' }}
                      onSelect={({ key }) => { if (!key.includes("sub")){ setActiveMenu([...activeMenu, key]); } }}
                    >
                      {
                        mainMenuList.map(({ label, iconType, menuKey, isAllowedToShow }) => {
                          return (                    
                            !isAllowedToShow ? null : (
                              <Menu.Item
                                key={(menuKey).toString()}
                              > 
                                <a href={`#${label}-p`} id= {'item-'+menuKey}>
                                  {iconType}
                                  {label}
                                </a>
                              </Menu.Item>
                            )
                          )
                        })
                      }          
                    </Menu>
                  </Sider>

                  <Layout id='content-area-layout'>
                    <MainBreadcrumb items={
                      [{ text: 'Home', link_type: 'linkto', link: '/dashboard' },
                      { text: 'Administration', link_type: 'ahref', link: void(0) },
                      { text: 'Settings', link_type: 'ahref', link: void(0) }]
                      }
                    />

                    <Content id='main-content' className='settings-content'>
                      <Row gutter={[0, 24]} >
                        <Col span={24} >
                          <Collapse accordion={false} onChange={(key) => { key !== undefined && setActiveMenu(key); }} activeKey={activeMenu}>
                            {                        
                              activeMenuList.map(({ label, componentLabel, Component, menuKey, collapseRef }) => {
                                label = componentLabel ? componentLabel : label;
                                return (                          
                                  <Panel 
                                    className='slc-panel'
                                    header={ label } 
                                    key={menuKey} 
                                    ref={collapseRef} 
                                    id={`${label}-p`}
                                  >
                                  <Component                                    
                                    allowedGet={ allowedGet }
                                    allowedUpdate={ allowedUpdate }
                                    handleNoAuth={ handleNoAuth }
                                    loading={ loading }
                                    setLoading={ setLoading }
                                  />
                                </Panel>
                                )
                              })
                            }
                          </Collapse>
                        </Col>
                      </Row>
                    </Content>
                  </Layout>
                </>
              :
                 <Result 
                  status='warning'
                  title='You are not allowed to access this page.'
                /> 
            }
          </>          
        </Layout>
        <MainFooter/>
      </Layout>
    </Spin>
  );
}