import React, {useState} from "react";
import { useHistory } from 'react-router-dom';
import { Card, Row, Col, Table, message, Button } from "antd";
import { GET } from '../../../util/network';
import logger from '../../../util/logger';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { fetchLeaseApplicationDetails, getDetails } from '../../../reducers/LeaseApplications'
import {useDispatch, useSelector} from "react-redux";

let auth_token = window.localStorage.getItem('auth_token')

export default function CreditReports({ data, handleNoAuth, setLoading }) {
    const history = useHistory();
    const dataSource = data.creditReports || []
    const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
    let loggedInUserId = null;
    const isCreditUser = useSelector((state) => state.leaseApplications.isCreditUser);
    if(authData !== null) {
        loggedInUserId = authData.id;
    }

    const dispatch = useDispatch();
    
    const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
    const disableFlag = !(isCreditUser && locked_by == loggedInUserId);
    const [unlockDialog, setUnlockDialog] = useState(false);
    const [applicantType, setApplicantType] = useState('')

    const columns = [
      { title: 'Lessee', dataIndex: 'lessee', key: 'lessee' },
      { title: 'Status', dataIndex: 'status', key: 'status' },
      {
        title: 'Old Report', dataIndex: 'is_old_report', key: 'is_old_report',
        render(val) {
          return (
            val === true ? "Yes" : ''
          )
        }
      },
      { title: 'Average Score', dataIndex: 'average_score', key: 'average_score' },
      {
        title: 'Actions', dataIndex: 'actions', key: 'actions',
        render(val) {
          return ( val && !disableFlag ?
            <a href={disableFlag ? "javascript:void(0)" : `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=${encodeURIComponent(val)}`} target="_blank" rel="noopener noreferrer">Download</a> :
            null
          )
        }
      },
      { title: 'Requested At', dataIndex: 'requested_at', key: 'requested_at' },
      { title: 'Updated At', dataIndex: 'updated_at', key: 'updated_at' },
      { title: 'Source', dataIndex: 'api_name', key: 'api_name' },
      {
        title: 'Details', dataIndex: 'details', key: 'details',
        render(val, row) {
          return  <a href={`/lease-applications/${data.id}/credit-report/${row.id}/details`}>View</a>
        }
      }
    ];

    const handleClose = () => {
      setUnlockDialog(false);
    };

    const handleRepullConditions = (source) => {
        if(source === 'lessee'){
          handleRepullLesseeCredit(data.lessee.id)
          // if(data.showCbcLesseeRepull){
          //   handleRepullLesseeCredit(data.lessee.id)
          // }
          // else{
          //   setUnlockDialog(true)
          // }
        }
        else if(source === 'colessee') {
          handleRepullLesseeCredit(data.colessee.id)
          // if(data.showCbcColesseeRepull){
          //   handleRepullLesseeCredit(data.colessee.id)
          // }
          // else{
          //   setUnlockDialog(true)
          // }
        }
    }

    const handleRepullLesseeCredit = async (lessee_id) => {
        setLoading(true);
        try {
          await GET(`/api/v1/lease_applications/repull-lessee-credit?lessee_id=${lessee_id}`);
          message.success("Credit Reports Will Be Repulled and Appear as a File Attachment Shortly");
        } catch (e) {
          logger.error("Error processing Credit Reports", e);

          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error('Error processing credit reports');
          }
        }
        setLoading(false);
    }
    
    return (
      <>
      <Card title='Credit Reports'>
          <Row className='flex-jc-fs'>
            <Col span={24}>
              <Button type='primary' onClick={async () => {
                setLoading(true);
                const result = await fetchLeaseApplicationDetails({ id: data.id });
                dispatch(getDetails(result.data.leaseApplication));
                setLoading(false);
              }} style={{ float: 'right' }} disabled={disableFlag}>
                <Loading3QuartersOutlined />Refresh
              </Button>
            </Col>
          </Row>

        <Row>
          <Col span={24}>
            <Table className='slc-table' rowKey="id" columns={columns} dataSource={dataSource} pagination={true} size="small" bordered={false}/>
          </Col>
        </Row>        
       
        <Row className='flex-jc-fs'>
          {
            data.creditStatus !== 'Unsubmitted' && (data.showCbcLesseeRepull == null || data.lesseeInfoChange) &&
            <Button
              className='slc-button-link-only'
              type="link"
              onClick={() => {
                setApplicantType('lessee')
                handleRepullConditions('lessee')
              }
              }
              disabled={disableFlag}
            >
              Re-Pull TU Lessee Credit
            </Button>
          }
          {
            data.colessee && !data.colesseeAddedAfterSubmission && data.creditStatus !== 'Unsubmitted' && (data.showCbcColesseeRepull == null || data.colesseeInfoChange) &&
            <Button
              className='slc-button-link-only'
              type="link"
              onClick={() => {
                setApplicantType('colessee')
                handleRepullConditions('colessee')
              }
              }
              disabled={disableFlag}
            >
              Re-Pull TU Co-Lessee Credit
            </Button>
          }
        </Row>            
      </Card>
      <Dialog
        open={unlockDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Repull Credit?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            CBC Credit Report has been repulled successfully in last 60 days. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className='slc-button-link-only'
              type="link"
              onClick={handleClose}>Disagree</Button>
            <Button className='slc-button-link-only'
              type="link"
              onClick={() => {
                handleClose()
                  if(applicantType === 'lessee') {
                    handleRepullLesseeCredit(data.lessee.id)
                  }
                  else if(applicantType === 'colessee') {
                    handleRepullLesseeCredit(data.colessee.id)
                  }
                }
              }
              autoFocus>
              Agree
            </Button>
        </DialogActions>
      </Dialog>
        </>
    );
}