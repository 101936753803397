export const authenticate = () => {
  const auth_token = window.localStorage.getItem('auth_token');
  const user_data = window.localStorage.getItem('user_data');
  const user_name = window.localStorage.getItem('user_name');
  return auth_token && user_data && !!user_name;
}

export const handleNoAuth = (history) => {
  window.localStorage.removeItem('auth_token');
  window.localStorage.removeItem('user_name');
  window.localStorage.removeItem('schedulingVal');
  
  history.push('/admins/login');    
}

export default authenticate;