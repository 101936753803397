import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import { POST } from '../../util/network'
import ModelYearsList from './ModelYearsList'

export default function ModelYearsHistoryRenderer(props) {

  const { id } = props.match.params;
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [pageState, setPageState] = useState(
    {
      page: 0,
      limit: 25,
    }
  )
  const [total, setTotal] = useState(0)
  const [activeModelYear, setActiveModelYear] = useState({})
  const [activeModelYearId, setActiveModelYearId] = useState('')

  const getModelYearsHistory = async () => {
    setLoading(true)
    try {
      const response = await POST(`/api/v1/model_years/${id}/history`, { pageState: pageState });
      setData(response.data.modelYears)
      setTotal(response.data.total)
      setActiveModelYear(response.data.modelYear)
      setActiveModelYearId(response.data.activeModelYearId)
    } catch (e) {
      message.error("Error while getting Model Years")
    }
    setLoading(false)
  }

  useEffect(() => {
    getModelYearsHistory()
  }, [pageState, id]);

  return (
    <Spin spinning={loading}>
      {/*<ActiveModelYear />*/}
      <ModelYearsList
        data={data}
        loading={loading}
        pageState={pageState}
        setPageState={setPageState}
        total={total}
        type={'history'}
        historyId={id}
        activeModelYear={activeModelYear}
        activeModelYearId={activeModelYearId}
      />
    </Spin>
  )
}
