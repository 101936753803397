import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, message, Spin, Result } from 'antd';
import { GET, POST, DELETE } from '../../../util/network';
import PromotionForm from './PromotionForm';
import PromotionTable from './PromotionTable';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};

export default function PromotionRenderer(props) {
    const { allowedGet, allowedUpdate, handleNoAuth, loading, setLoading } = props;
    const history = useHistory();
    const [dealerShips, setDealerShips] = useState();
    const [promotion, setPromotion] = useState();
    const [form] = Form.useForm();
    const getFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const handleSubmit = async (val) => {
        let formData = new FormData()
        formData.append("title", val.title)
        formData.append("image", val.image[0].originFileObj)
        formData.append("file", val.file[0].originFileObj)
        formData.append("dealers",val.dealer )
        try {
            await POST(`/api/v1/promotions/set-promotions`, formData);
            message.success('Data saved successfully');
            getPromotionData();
            form.resetFields()
        } catch (e) {
            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history);
            } else {
                message.error('Error while saving data');
            }
        }
    }

    const getDealerships = async () => {
        setLoading(true);
        try {
            let response = await GET(`/api/v1/promotions/dealerships`);
            setDealerShips(response.data.dealership)
            getPromotionData();
        } catch (e) {
            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history);
            } else {
                message.error('Error while fetching your dealership data');
            }
        }
        setLoading(false);
    }

    const deletePromotion = async (id) => {
        try {
            await DELETE(`/api/v1/promotions/delete-promotion/${id}`);
            getPromotionData();
            message.success('Data deleted successfully');
        } catch (e) {
            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history);
            } else {
                message.error('Error while deleting your data');
            }
        }
    }

    const getPromotionData = async () => {
        if (allowedGet) {
            setLoading(true);
            try {
                let response = await GET(`/api/v1/promotions/admin-promotion-list`);
                setPromotion(response.data)
            } catch (e) {
                if (e && e.response && e.response.status === 401) {
                    handleNoAuth(history);
                } else {
                    message.error('Error getting promotions');
                }
            }
            setLoading(false);
        }
    }


    useEffect(() => {
        getDealerships();
    }, []);

    return (
        <Spin
            spinning={loading}
            size='large'
            tip='Loading...'
        >
            <>
                {allowedGet
                    ?
                    <Box>
                        <Grid container direction="row">
                            <Grid item md={12}>
                                <PromotionForm
                                    allowedGet={allowedGet}
                                    allowedUpdate={allowedUpdate}
                                    layout={layout}
                                    form={form}
                                    handleSubmit={handleSubmit}
                                    getFile={getFile}
                                    dealerShips={dealerShips}
                                />
                            </Grid>
                        </Grid>
                        <Grid container direction="row">
                            <Grid item md={12}>
                                <Box sx={{ width: "100%", height: "400px" }}>
                                    <PromotionTable
                                        data={promotion}
                                        deletePromotion={deletePromotion}
                                        dealerShips={dealerShips}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    <Result
                        status='warning'
                        title='You are not allowed to access this data.'
                    />
                }
            </>
        </Spin>
    );
}
