import React from 'react';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import DealershipAttachments from '../components/DealershipAttachments';
import { GET } from '../../../util/network';
import logger from '../../../util/logger';

export default function Attachments({ dealership, dealershipId, getDealership, setLoading, ...props }) {
  const { allowedUpdate, handleNoAuth } = props;

  const history = useHistory();
  
  const handleGenerateDealerAgreement = async () => {
    setLoading(true);

    try {
      await GET(`/api/v1/dealerships/generate-dealer-agreement?id=${dealershipId}`);
      message.success("Successfully generated!");   
      getDealership()
    } catch (e) {
      logger.error("Error while Generating Dealer Agreement", e);
      
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while Generating Dealer Agreement');
      } 
    }

    setLoading(false);
  }  

  return dealership ? (
    <DealershipAttachments 
      dealershipId={dealershipId} 
      getDealership={getDealership} 
      dataSource={dealership.dealershipAttachments}       
      allowedUpdate={allowedUpdate}
      handleGenerateDealerAgreement={handleGenerateDealerAgreement}
    />
  ) : null;
}