import React, {useEffect, useState} from "react";
import {Button, message, Input} from 'antd'
import HistoricalRelated from '../HistoricalRelated'
import {GET, POST} from "../../../util/network";
import logger from "../../../util/logger";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from '@mui/material/Select';
import CircularProgress from "@mui/material/CircularProgress";
import { green } from '@mui/material/colors';

const { Search } = Input;

export default function RelatedAppsSearch(props) {
    const { data, setRelatedData, handleNoAuth, handleClose } = props;
    const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
    let loggedInUserId = null;
    if(authData !== null) {
        loggedInUserId = authData.id;
    }
    const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
    const disableFlag = locked_by !== loggedInUserId;
    const initialSearchState = {search_value: '', search_option: 'ssn'}
    const [searchState, setSearchState] = useState({...initialSearchState})
    const [relatedAppSearchedData, setRelatedAppSearchedData] = useState({ data: [], count: 0 });
    const [relatedAppSearchedPageState, setRelatedAppSearchedPageState] = useState({ page: 0, limit: 10 });
    const [selectedRelatedAppIds, setSelectedRelatedAppIds] = useState([]);
    const [modalLoading, setModalLoading] = useState(false);
    const [isSsnSearch, setIsSsnSearch] = useState(true);

    const onSearchChange = (e) => {
        setSearchState({...searchState, search_value: e.target.value})
        if(e.target.value === ''){
            setRelatedAppSearchedData({ data: [], count: 0 })
        }
    };

    useEffect(() => {
        searchRelatedApps();
    }, [relatedAppSearchedPageState]);

    const searchRelatedApps = async (search_option) => {
        console.log('search_option', search_option);

        let appId = data.id;
        let queryParams = `search_option=${searchState.search_option}&search_value=${searchState.search_value}`
        

        if(search_option !== undefined && search_option === 'home_address') {
            setSearchState({...searchState, search_option: 'home_address', search_value: data?.lessee?.homeAddress?.street1})
            queryParams = `search_option=home_address&search_value=${data?.lessee?.homeAddress?.street1}`
        } else if(search_option !== undefined && search_option === 'phone_number') {
            setSearchState({...searchState, search_option: 'phone_number', search_value: data?.lessee?.mobilePhoneNumber})
            queryParams = `search_option=phone_number&search_value=${data?.lessee?.mobilePhoneNumber}`
        } else if(search_option !== undefined && search_option === 'full_name') {
            setSearchState({...searchState, search_option: 'full_name', search_value: data?.lessee?.firstName + ' ' + data?.lessee?.lastName})
            queryParams = `search_option=full_name&search_value=${data?.lessee?.firstName + ' ' + data?.lessee?.lastName}`
        } else {
            // setSearchState({...searchState, search_option: 'ssn', search_value: ''})
            if (!(searchState.search_option && searchState.search_value)){
                return true;
            }
        }

        queryParams = queryParams + `&page=${relatedAppSearchedPageState.page}&limit=${relatedAppSearchedPageState.limit}`

        setModalLoading(true);
        try {
            const response = await GET(`/api/v1/lease_applications/${appId}/search_related_applications?${queryParams}`);
            setRelatedAppSearchedData(response.data)
        } catch (e) {
            logger.error("Error processing related application.", e);

            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history);
            } else {
                message.error('Error processing related application.');
            }
        }
        setModalLoading(false);
    };

    const saveRelatedApps = async () => {
        setModalLoading(true);
        try {
            let appId = data.id;
            const response = await POST(`/api/v1/lease_applications/${appId}/save_related_applications`,
                {related_app_ids: selectedRelatedAppIds, app_type: searchState.search_option});
            setRelatedData(response.data)
            message.success("Related apps added successfully")
            handleClose();
        } catch (e) {
            logger.error("Error processing related application.", e);
            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history);
            } else {
                message.error('Error processing related application.');
            }
        }
        setModalLoading(false);
    };


    const Capitalize = (str)=>{
        return str.charAt(0).toUpperCase() + str.slice(1);
        }

    const searchOptionText = searchState.search_option !== 'ssn' ? Capitalize(searchState.search_option.split('_')[0]) + ' ' + Capitalize(searchState.search_option.split('_')[1]) : 'SSN'
    const searchValueText = searchState.search_option === 'home_address' ? data?.lessee?.homeAddress?.zipcode + ' ' + data?.lessee?.homeAddress?.state + ', ' + Capitalize(searchState.search_value) : Capitalize(searchState.search_value)

    return <>
        <Box sx={{ flexGrow: 1, pb: 2 }}>
            <Grid container spacing={1} sx={{mb: '10px'}} direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
            >
                {isSsnSearch && (<Grid item md={3} sm={6}>
                    <Search
                        placeholder="Related search"
                        size="large"
                        disabled={disableFlag}
                        value={searchState.search_value}
                        onChange={onSearchChange}
                        className="leaseAppListSearch"
                        style={{ width: '99%' }}
                        onSearch={searchRelatedApps}
                        enterButton
                    />
                </Grid>)}  
                <Grid item md={2} sm={4}>
                    <FormControl fullWidth >
                        <Select
                          labelId="demo-simple-select-required-label"
                          disabled={disableFlag}
                          value={searchState.search_option}
                          label="Related options"
                          onChange={(e) => {
                            //   setSearchState({...searchState, search_option: e.target.value})
                              setRelatedAppSearchedData({ data: [], count: 0 })
                              if(e.target.value !== 'ssn') {
                                setIsSsnSearch(false)
                                searchRelatedApps(e.target.value)
                              } else {
                                setSearchState({...searchState, search_option: e.target.value, search_value: ''})
                                setIsSsnSearch(true)
                              }
                          }}
                          size="small"
                        >
                            <MenuItem value="ssn">SSN</MenuItem>
                            <MenuItem value="full_name">Full Name</MenuItem>
                            <MenuItem value="phone_number">Phone Number</MenuItem>
                            <MenuItem value="home_address">Address</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
            >
                <Grid item sm={12}>
                    Historical search by  
                    <label> {searchOptionText}</label> {searchState.search_option === 'ssn' ? '' : '='} <label style={{color: "#E93B1B"}}>{searchValueText}</label>
                    <div style={{height: "5px"}}></div>
                    <HistoricalRelated fromModal={true} data={relatedAppSearchedData.data} total={relatedAppSearchedData.count} pageState={relatedAppSearchedPageState}
                                       setPageState={setRelatedAppSearchedPageState} checkboxSelection={true} setSelectedRelatedAppIds={setSelectedRelatedAppIds}/>
                </Grid>
                <Grid item>
                    <Button
                      type='primary'
                      onClick={handleClose}
                      style={{marginRight: '8px'}}
                    >
                        Close
                    </Button>
                    {relatedAppSearchedData.data && <Button
                      type='primary'
                      onClick={saveRelatedApps}
                      disabled={selectedRelatedAppIds.length <= 0}
                    >
                        Save Related Data
                    </Button>}
                </Grid>
            </Grid>
            {modalLoading && (
              <CircularProgress
                size={68}
                sx={{
                    color: green[500],
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    zIndex: 1,
                }}
              />
            )}
        </Box>
    </>
}
