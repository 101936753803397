import React from "react";
import Box from '@mui/material/Box';
import {Button, Col, Form, Row, Drawer, Divider, DatePicker, Input, Select} from 'antd';
import moment from 'moment';

export default function TimeReportFilter(props) {
  const {Search} = Input;
  const {Option} = Select;
  const {RangePicker} = DatePicker;
  const {setPageState, filter, setFilter, filterState, setFilterState, dealershipsOptions, clearFilter} = props;
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setFilter(open)
  };

  const TimeReportFilter = () => {
    if (filterState.filter_flag === true) {
      setFilter(false)
      setPageState({...filterState});
    }
  }

  const applicantStatuses = [
    {name: 'Awaiting Credit Decision', value: 'awaiting_credit_decision'},
    {name: 'Credit Review Stage 2', value: 'credit_review_stage_2'},
    {name: 'Approved with Contingencies', value: 'approved_with_contingencies'},
    {name: 'Approved', value: 'approved'},
    {name: 'Requires Additional Information', value: 'requires_additional_information'},
    {name: 'Bike Change Requested', value: 'bike_change_requested'},
    {name: 'Declined', value: 'declined'},
    {name: 'Rescinded', value: 'rescinded'},
    {name: 'Withdrawn', value: 'withdrawn'},
    {name: 'No Documents', value: 'no_documents'},
    {name: 'Documents Requested', value: 'documents_requested'},
    {name: 'Documents Issued', value: 'documents_issued'},
    {name: 'Lease Package Received', value: 'lease_package_received'},
    {name: 'Funding Delay', value: 'funding_delay'},
    {name: 'Funding Approved', value: 'funding_approved'},
    {name: 'Funded', value: 'funded'},
    {name: 'Canceled', value: 'canceled'},
  ]
  
  const allFilters = () => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            className="lease-doc-filter"
            label="Lease Application Identifier"
          >
            <Search
              style={{padding: '2px', width: "300px"}}
              placeholder="Lease Application Identifier"
              size="large"
              allowClear
              className="leaseAppListSearch"
              onChange={(e) => {
                setFilterState({...filterState, lease_app_id: e.target.value, filter_flag: true})
              }}
              enterButton
              value={filterState.lease_app_id}
            />
          </Form.Item>
        </Col>

        <Divider
          type="horizontal"
          style={{borderColor: "#d0d0cf", margin: '2px', padding: '2px'}}
        />

        <Col span={24}>
          <Form.Item
            label="Applicant Name"
            className="lease-doc-filter"
          >
            <Search
              style={{padding: '2px', width: "300px"}}
              placeholder="Applicant Name"
              size="large"
              allowClear
              className="leaseAppListSearch"
              onChange={(e) => {
                setFilterState({...filterState, applicant_name: e.target.value, filter_flag: true})
              }}
              enterButton
              value={filterState.applicant_name}
            />
          </Form.Item>
        </Col>

        <Divider
          type="horizontal"
          style={{borderColor: "#d0d0cf", margin: '2px', padding: '2px'}}
        />

        <Col span={24}>
          <Form.Item
            label="Search by Application Status"
          >
            <Select
              style={{padding: '2px', width: "300px"}}
              size="large"
              placeholder="Select application status"
              allowClear
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => {
                if (value !== undefined && value !== null) {
                  setFilterState({...filterState, applicant_status: value, filter_flag: true})
                } else {
                  setFilterState({...filterState, applicant_status: ''})
                }
              }}
              value={filterState.applicant_status || null}
              showSearch
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
            >
              {applicantStatuses &&
                applicantStatuses.map((record, index) => {
                  return (
                    <Option key={index} value={record.value}>
                      {`${record.name}`}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>

        <Divider
          type="horizontal"
          style={{borderColor: "#d0d0cf", margin: '2px', padding: '2px'}}
        />

        <Col span={24}>
          <Form.Item
            label="Search by Dealer"
          >
            <Select
              style={{padding: '2px', width: "300px"}}
              size="large"
              placeholder="Select Dealer"
              allowClear
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => {
                if (value !== undefined && value !== null) {
                  setFilterState({...filterState, dealership_id: value, filter_flag: true})
                } else {
                  setFilterState({...filterState, dealership_id: null})
                }
              }}
              value={filterState.dealership_id || null}
              showSearch
              filterOption={(input, option) =>
                option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
              }
            >
              {dealershipsOptions &&
                dealershipsOptions.map(({value, label}, index) => {
                  return (
                    <Option key={index} value={value}>
                      {label}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>

        <Divider
          type="horizontal"
          style={{borderColor: "#d0d0cf", margin: '2px', padding: '2px'}}
        />

        <Col span={24}>
          <Form.Item
            label="Search using Application submission date"
          >
            <RangePicker
              placement="topLeft"
              onChange={(event, value) => {
                setFilterState({
                  ...filterState,
                  submission_start_date: value[0],
                  submission_end_date: value[1],
                  filter_flag: true
                })
              }}
              value={[filterState.submission_start_date !== "" ? moment(filterState.submission_start_date) : null, filterState.submission_end_date !== "" ? moment(filterState.submission_end_date) : null]}
            />
          </Form.Item>
        </Col>

        <Divider
          type="horizontal"
          style={{borderColor: "#d0d0cf", margin: '2px', padding: '2px'}}
        />
      </Row>

      <Row align="middle" gutter={16}>
        <Col span={25}>
          <Form.Item
          >
            <Button
              style={{
                textAlign: "center",
                backgroundColor: "#1976d2",
                color: "#fff",
                fontSize: 16,
                fontWeight: "bold",
                borderRadius: "5px",
                marginRight: "5px",
                marginLeft: "20px",
                width: "100px"
              }}
              onClick={() => TimeReportFilter()}
            >
              Filter
            </Button>
          </Form.Item>
        </Col>
        <Col span={25}>
          <Form.Item
          >
            <Button
              style={{
                textAlign: "center",
                backgroundColor: "#fff",
                color: "#000",
                fontSize: 16,
                fontWeight: "bold",
                borderRadius: '5px',
                border: "1px solid #000",
                width: "100px"
              }}
              onClick={() => clearFilter()}
            >
              Clear
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Box sx={{flexGrow: 1, pb: 0}}>
      <Drawer
        title="Filter"
        placement="right"
        getContainer={false}
        open={filter}
        onClose={toggleDrawer(false)}
        visible={filter}
      >
        {allFilters()}
      </Drawer>
    </Box>
  )
}
