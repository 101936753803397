import React, {useState} from 'react';
import {DataGridPro} from "@mui/x-data-grid-pro";
import {styled} from "@mui/system";
import {Button} from "@material-ui/core";
import ActiveToggleButton from "../../../components/Buttons/ActiveToggleButton";
import {useHistory} from "react-router-dom";
import Box from "@mui/material/Box";
import {Col, Input, Layout, message, Row} from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogModal from "../../LeaseApplications/DialogModal";
import {DELETE} from "../../../util/network";
import {handleNoAuth} from "../../../util/authenticate";

const ContactList = (props) => {
  const {Content} = Layout;
  const {Search} = Input;
  const {contacts, total, pageState, setPageState, setLoading, setContactData} = props
  const history = useHistory();
  const [searchVal, setSearchVal] = useState(decodeURIComponent(pageState.search));
  const [open, setOpen] = useState(false);
  const [recordId, setRecordId] = useState(null);

  const handleDealerUser = (row) => {
  }

  const handleIsOwner = (row) => {
  }
  const handleGlobalSearch = (e) => {
    setSearchVal(e.target.value);
    if (e.target.value === '') {
      setPageState({...pageState, search: ''})
    }
  };

  const onSearch = () => {
    setPageState({...pageState, search: encodeURIComponent(searchVal)})
  };

  const closeDialog = () => {
    setOpen(false);
  }

  const completeDeletion = async () => {
    setLoading(true);
    setOpen(false);
    try {
      let encodedParam = Object.entries(pageState).map(([key, val]) => `${key}=${val}`).join('&');
      const response = await DELETE(`/api/v1/contacts/${recordId}?${encodedParam}`);
      setContactData(response.data);
      message.success('Successfully deleted contacts.');
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error on contact deletion.');
      }
    }
    setLoading(false);
  }

  const columns = [{
    field: "id", headerName: "ID", type: "string", flex: .5, minWidth: 75, filterable: false,
  }, {
    field: "firstName", headerName: "First Name", type: "string", flex: 1, minWidth: 75, filterable: false,
  }, {
    field: "lastName", headerName: "Last Name", type: "string", flex: 1, minWidth: 75, filterable: false,
  }, {
    field: "position", headerName: "Position", type: "string", flex: 1, minWidth: 75, filterable: false,
  }, {
    field: "isOwner",
    headerName: "Owner?",
    type: "string",
    flex: 1,
    minWidth: 75,
    filterable: false,
    renderCell: (record) => {
      return <ActiveToggleButton isActive={record?.row?.isOwner} rowData={record?.row}
                                 handleChange={handleIsOwner}/>
    },
  }, {
    field: "directPhoneNumber",
    headerName: "Direct phone number",
    type: "string",
    flex: 1,
    minWidth: 75,
    filterable: false,
  }, {
    field: "directEmail", headerName: "Direct Email", type: "string", flex: 1.5, minWidth: 75, filterable: false,
  }, {
    field: "isDealerUser",
    headerName: "Is Dealer User",
    type: "string",
    flex: 1,
    minWidth: 75,
    filterable: false,
    renderCell: (record) => {
      return <ActiveToggleButton isActive={record?.row?.isDealerUser} rowData={record?.row}
                                 handleChange={handleDealerUser}/>
    },
  }, {
    field: "actions", headerName: "Actions", type: "action", flex: 1.5, minWidth: 75, renderCell: (record) => {
      return record ? (<>
        <Button
          style={{textTransform: "none"}}
          variant="text"
          onClick={() => {
            history.push({
              pathname: `/contacts/${record.row.id}`, state: {
                actionType: "DETAILS", dealershipId: record.row.dealershipId,
              },
            });
          }}
        >
          View
        </Button>

        <Button
          style={{textTransform: "none", marginLeft: '5px'}}
          variant="text"
          onClick={() => {
            history.push({
              pathname: `/contacts/${record.row.id}`, state: {
                actionType: "UPDATE", dealershipId: record.row.dealershipId,
              },
            });
          }}
        >
          Edit
        </Button>

        <Button
          type="link"
          name="deleteButton"
          onClick={() => {
            setOpen(true)
            setRecordId(record?.row?.id)
          }}
        >
          <DeleteIcon style={{color: 'red'}}/>
        </Button>
      </>) : ("");
    },
  },]

  return <>
    <Layout id="content-area-layout">
      <Content
        id="main-content"
        style={{backgroundColor: "#ffff !important"}}
      >
        <Row style={{marginBottom: "1em"}}>
          <Col flex={4}>
            <h1>Contacts</h1>
          </Col>

          <Col style={{marginRight: '10px'}}>
            <Button style={{backgroundColor: "#e93b1b", color: 'white'}} onClick={() => history.push({
              pathname: `/contacts/create`, state: {actionType: "CREATE"},
            })}>
              Add new contacts
            </Button>
          </Col>

          <Col>
            <Search
              name="globalSearch"
              placeholder="First Name or Last Name or Email"
              size="large"
              onChange={handleGlobalSearch}
              value={searchVal}
              onSearch={onSearch}
            />
          </Col>
        </Row>
        <StyledBox>
          {contacts && <StyledDataGridPro
            disableColumnReorder={true}
            disableSelectionOnClick={true}
            getRowHeight={() => "auto"}
            density="comfortable"
            columns={columns}
            rows={contacts}
            rowsPerPageOptions={[25, 50, 100]}
            pagination
            rowCount={total ?? 0}
            page={pageState.page <= 0 ? 0 : pageState.page}
            pageSize={pageState.limit}
            paginationMode="server"
            filterMode='server'
            onPageChange={(newPage) => {
              setPageState({...pageState, page: newPage})
            }}
            onPageSizeChange={(newPageSize) => {
              setPageState({...pageState, limit: newPageSize})
            }}
          />}
        </StyledBox>
        <DialogModal
          modalState={open}
          modalClose={closeDialog}
          submitFunction={completeDeletion}
          contentData={""}
          title={"Are you sure you want to delete this contact?"}
        />
      </Content>
    </Layout>
  </>
}

export default ContactList;

const StyledDataGridPro = styled(DataGridPro)({
  boxShadow: 'none !important', border: "none !important", '& .MuiDataGrid-cell': {
    padding: '10px',
  }, '& .MuiDataGrid-row': {
    fontSize: 12
  }, '& .wrapHeader .MuiDataGrid-colCellTitle': {
    overflow: "hidden", lineHeight: "20px", whiteSpace: "normal"
  }, '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: 'normal !important',
    lineHeight: 'normal',
    overflowWrap: "break-word",
    letterSpacing: '-0.5px'
  }, '& .MuiDataGrid-columnHeaders': {
    backgroundColor: "#e8ecf0", color: "#000000",
  }, '& .MuiChip-label': {
    paddingLeft: '12px',
    paddingRight: '12px',
    whiteSpace: 'normal !important',
    wordWrap: ' break-word',
    lineHeight: ' 1',
    paddingBottom: '2px',
    paddingTop: '2px',
    letterSpacing: '-0.5px'
  }
})

const StyledBox = styled(Box)({
  height: 820, display: "flex", flexGrow: 1, width: '100%', '& .super-app-theme--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)'
  },
})
