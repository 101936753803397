import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import {
  Card,
  Form,
  Col,
  Input,
  message,
  Button,
  Row,
  Modal
} from "antd";

import { DELETE } from '../../../util/network';
import logger from '../../../util/logger';
import './References.css'
import { useDispatch } from "react-redux";
import { updateReference } from '../../../reducers/LeaseApplications'

const isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
    );
};

const isModifierKey = (event) => {
    const key = event.keyCode;
    return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
        (key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
        (key > 36 && key < 41) || 
        (
           
            (event.ctrlKey === true || event.metaKey === true) &&
            (key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
        )
};

const enforceFormat = (event) => {
    // Input must be of a valid number format or a modifier key, and not longer than ten digits
    if (!isNumericInput(event) && !isModifierKey(event)) {
        event.preventDefault();
        event.stopPropagation();
    }
};

const formatToPhone = (event) => {
    if (isModifierKey(event)) { return; }    
    const target = event.target;
    const input = target.value.replace(/\D/g, '').substring(0, 10); 
    const zip = input.substring(0, 3);
    const middle = input.substring(3, 6);
    const last = input.substring(6, 10);

    if (input.length > 6) { target.value = `(${zip}) ${middle}-${last}`; }
    else if (input.length > 3) { target.value = `(${zip}) ${middle}`; }
    else if (input.length > 0) { target.value = `(${zip}`; }
};

export default function References({ data, handleNoAuth, save: saveData, appNumber, setLoading }) {
  const history = useHistory();

  const [form] = Form.useForm();
  const { references } = data;
  const [records, setRecords] = useState(references || []);
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
      loggedInUserId = authData.id;
  }
  const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
  const disableFlag = locked_by != loggedInUserId ? true : false
  const dispatch = useDispatch();

  const save = async (index) => {
      let record = form.getFieldsValue().records[index]
      let references_attributes = {
        id: record.id,
        firstName: record.firstName,
        lastName: record.lastName,
        city: record.city,
        state: record.state,
        phoneNumber: record.phoneNumber
      };
      await saveData({
          application_identifier: appNumber,
          references_attributes: [references_attributes]
      });
      dispatch(updateReference(references_attributes))
  }
  
  const deleteRecord = async (id, index) => {
      
      Modal.confirm({
          title: "Confirm",
          content: "Are you sure you want to delete this reference?",
          onOk: async () => {
              if(!id){
                  const newRecords = [...records];
                  newRecords.splice(index, 1);
                  setRecords(newRecords);
                  return;
              }
              setLoading(true);
              try {
                  await DELETE(`/api/v1/references/${id}`);
                  const newRecords = [...records];
                  newRecords.splice(index, 1);
                  setRecords(newRecords);
                  message.success("Reference removed successfully");
              } catch (e) {
                  logger.error("Error while removing the reference", e);

                  if (e && e.response && e.response.status === 401) {
                    handleNoAuth(history);
                  } else {
                    message.error('Error while removing the reference');
                  }
              }
              setLoading(false);
          }
      })
  }
  return (
    <Form 
      form={form} 
      initialValues={{ records: records }}
    >
      <Col span={24}>
        <Card>
          <Form.List name="records">
            {(fields, { add }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <Row className='mt-20' key={index} gutter={[16, 0]}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                        <Row gutter={[16, 0]}>
                          <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                            <Row gutter={[32, 0]}>
                              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                  <Form.Item 
                                      name={[field.name, 'firstName']}
                                      fieldKey={[field.fieldKey, 'firstName']}
                                      label="First Name">
                                          <Input disabled={disableFlag} />
                                  </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                  <Form.Item
                                      name={[field.name, 'lastName']}
                                      fieldKey={[field.fieldKey, 'lastName']}
                                      label="Last Name">
                                      <Input disabled={disableFlag}/>
                                  </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={24} xl={14}>
                            <Row gutter={[32, 0]}>
                              <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                                  <Form.Item
                                      name={[field.name, 'phoneNumber']}
                                      fieldKey={[field.fieldKey, 'phoneNumber']}
                                      label="Phone">
                                          <Input maxLength={16} onKeyDown={enforceFormat} onKeyUp={formatToPhone} disabled={disableFlag}/>
                                  </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                                  <Form.Item 
                                      name={[field.name, 'city']}
                                      fieldKey={[field.fieldKey, 'city']}
                                      label="City">
                                          <Input disabled={disableFlag}/>
                                  </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                                  <Form.Item 
                                      name={[field.name, 'state']}
                                      fieldKey={[field.fieldKey, 'state']}
                                      label="State">
                                      <Input disabled={disableFlag}/>
                                  </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                        <Row gutter={[16, 0]}>
                          <Col xs={24} sm={4} md={4} lg={4} xl={12}>
                              <Button onClick={
                                  () => { save(index, {...field}) }
                              } type="primary" disabled={disableFlag}>Save</Button>
                          </Col>
                          <Col xs={24} sm={20} md={20} lg={20} xl={12}>
                              <Button 
                                id='delete-reference'
                                onClick={() => { deleteRecord(field.id, index) }} 
                                type="danger"
                                disabled={disableFlag}
                              >
                                Remove
                              </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))}
                  <Row className='flex-jc-fs mt-20' gutter={[16, 0]}>
                      <Col span={6}>
                          {/* <Button onClick={() => { setRecords([...records, {}]) }} type="primary">Add New Reference</Button> */}
                          <Button onClick={() => { add() }} type="primary" disabled={disableFlag}>Add New Reference</Button>
                      </Col>
                  </Row>
                </>
              )
            }}
          </Form.List>
        </Card>
      </Col>
    </Form>
  );
}