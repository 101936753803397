import React from 'react';
import { Button, Card, Col, Form, Row } from 'antd';
import CustomInputText from '../components/CustomInputText';
import CustomRadio from '../components/CustomRadio';
import CustomSelect from '../components/CustomSelect';

export default function Financial({ dealership, handleSubmit, remitToDealerCalculation, ...props }) {
  const { allowedUpdate } = props;
  const [form] = Form.useForm();

  const accountType = [
    { optionValue: "checking", optionLabel: "Checking"},
    { optionValue: "savings", optionLabel: "Savings"},
  ]

  const enableSecurityDeposit = [
    { optionValue: true, optionLabel: "Enable"},
    { optionValue: false, optionLabel: "Disabled"},
  ]  

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  return dealership ? (
    <Form
      { ...layout }
      form={ form }
      scrollToFirstError={true}
      onFinish={handleSubmit}
      initialValues={{
        accountNumber: dealership.accountNumber,
        accountType: dealership.accountType,
        bankName: dealership.bankName,
        dealFee: dealership.dealFee,
        dealerGroup: dealership.bankName,
        enableSecurityDeposit: dealership.enableSecurityDeposit,
        legalCorporateEntity: dealership.routingNumber,
        remitToDealerCalculationId: dealership.remitToDealerCalculationId && dealership.remitToDealerCalculationId.toString(),
        routingNumber: dealership.routingNumber,
        securityDeposit: dealership.securityDeposit
      }}
    > 
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card className='h-100' title="Banking" id='banking-card' >
            <CustomInputText 
              name="bankName" 
              label="Bank Name" 
              required={true} 
              ruleMessage="Bank Name is required!"
            />
            <CustomInputText 
              name="routingNumber"
              label="Routing Number" 
              required={true} 
              ruleMessage="Routing Number is required!"
            />
            <CustomInputText 
              name="accountNumber" 
              label="Account Number" 
              required={true} 
              ruleMessage="Account Number is required!"
            />
            <CustomSelect 
              name="accountType" 
              label="Account Type" 
              required={true} 
              ruleMessage="Account Type is required!" 
              options={accountType}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card className='h-100' title="Fees" >
            <CustomRadio 
              name="enableSecurityDeposit" 
              label="Security Deposit" 
              required={true} 
              ruleMessage="Security Deposit is required!"
              options={enableSecurityDeposit}
            />
            <CustomInputText 
              name="securityDeposit" 
              label="Security Deposit" 
              required={true} 
              ruleMessage="Security Deposit is required!"
            />
            <CustomInputText 
              name="dealFee" 
              label="Deal Fee" 
              required={true} 
              ruleMessage="Deal Fee is required!"
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <Card className='h-100' title="Calculations" >
            <CustomSelect 
              name="remitToDealerCalculationId" 
              label="Remit to Dealer" 
              required={true} 
              ruleMessage="Remit to Dealer is required!"  
              options={remitToDealerCalculation}
              tooltipText='Unless otherwise directed, set this to "SLC Standard Lease."'
            />
          </Card>
        </Col>
    </Row>
    <br/>
    {
      allowedUpdate &&
        <Row>
            <Col span={24}> 
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
            </Col> 
          </Row>      
    }
    </Form>
  ) : null;
}