import React, { useState, useEffect } from "react";
import { message, Spin } from "antd";
import { GET } from '../../util/network'
import MakeCategoryList from "./MakeCategoryList"

export default function MakeCategoryRenderer(props) {
  const { handleNoAuth } = props;
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null)

  const getMakeCategory = async () => {
    setLoading(true)
    try {
      const response = await GET(`/api/v1/make_categories`);
      // message.success(response.data.message)
      setResponse(response?.data);
    } catch (e) {
      message.error("Error while loading make categories.")
    }
    setLoading(false)
  }

  useEffect(() => {
    getMakeCategory();
  }, []);

  return (
    <Spin spinning={loading}>
      <MakeCategoryList
        data={response}
        loading={loading}
      />
    </Spin>
  )
}
