import React from 'react'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { styled } from '@mui/system';
export default function AnalyticsCard(props) {
    const { title, data, Icon, hasChip, percentage, chipStyle, totalSalePrice, StyledChip } = props
    const percentageChip = (percentage, chipStyle) => {
        return percentage > 50 ? <StyledChip color="success" icon={<TrendingUpIcon />} className={chipStyle} label={percentage + '%'}></StyledChip>
            : <StyledChip color="error" icon={<TrendingDownIcon />} label={percentage + '%'}></StyledChip>
    }
    return (
        <StyledPaper elevation={6} >
            <CardContent justify="center" sx={{ padding: 2.8 }} >
                <StyledTitleTypography gutterBottom variant="h6" >
                    {title}  <Icon />
                </StyledTitleTypography>
                <StyledContentTypography color="text.primary" component={'span'} variant={'body2'}>
                    {data} {totalSalePrice ? `( ${totalSalePrice} )` : ""}
                    {hasChip &&
                        percentageChip(percentage, chipStyle)
                    }
                </StyledContentTypography>
            </CardContent>
        </StyledPaper>
    )
}

const StyledTitleTypography = styled(Typography)({
    fontWeight: "bold",
    color: "#545353",
    letterSpacing: "-1.5px "
})

const StyledContentTypography = styled(Typography)({
    fontWeight: 'bold',
    fontSize: 15,
    letterSpacing: "-0.5px "
})

const StyledPaper = styled(Paper)({
    "&:hover": {
        boxShadow: "0px 3px 5px -1px #310a0a33",
    }
})

