import React, { useState, useEffect } from "react";
import { Switch, message } from "antd";
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { PUT } from '../../../util/network';
import logger from '../../../util/logger';

export default function IsLockedSwitch({isLocked, rowData}) {

  const [locked, setLocked] = useState(isLocked)
  useEffect(() => {
    setLocked(isLocked)
  }, []);


  const update = async (temp_locked) => {
      // setLoading(true)
      try {
        if (temp_locked){
          await PUT(`/api/v1/admin-users/lock-user/${rowData.id}`);
        }else{
          await PUT(`/api/v1/admin-users/unlock-user/${rowData.id}`);
        }
        message.success("Saved successfully");
      } catch (e) {
        logger.error("Error while saving!", e);
        message.error("Error while saving!");
        setLocked(!temp_locked)
      }
      // setLoading(false)
    };

    const handleChange = () => {
      let temp_locked = !locked
      setLocked(temp_locked)
      update(temp_locked)
    }


    return (
        <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        defaultChecked={isLocked}
        checked={locked}
        onChange={handleChange}
      />
  );
}