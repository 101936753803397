import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  makeStyles, Typography, List, ListItem, Grid, ListItemText,
} from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import {Paper} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "white",
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)'
  
  },
  header: {
    background: "transparent",
    textAlign: "center",
    color: "black",
    fontSize: 24,
    fontWeight: "bold",
  },
  content: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  textField: {
    width: "100%",
    marginBottom: 20,
  },
  button: {
    marginTop: "5px",
    background: "#ff3500",
    color: "#fff"
  },
}));

function SendEmail(props) {
  const {emails, sendEmail, dealershipInfo, loading} = props;
  const classes = useStyles();
  const history = useHistory();
  let {id} = useParams();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const options = [];

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send email logic here
    sendEmail(email, subject, body)
    // Reset form
    setEmail("");
    setSubject("");
    setBody("");
  };

  useEffect(() => {
    dealershipInfo?.dealers?.forEach((dealer) => {
      options.push({
        label: dealer.email,
        name: dealer.name,
        email: dealer.email,
        id: dealer.id,
      })
    })
  });

  return (
    <div>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Autocomplete
            key={loading}
            id="combo-box-demo"
            onChange={(event, newValue) => {
              setEmail(newValue?.email || '')
            }}
            options={options}
            renderInput={(params) => (
              <TextField {...params} className={classes.textField}  variant="outlined" size="small" label="To"
                         // onChange={(e) => setEmail(e?.target?.value)}
              />
            )}
          />
          <TextField
            className={classes.textField}
            label="Subject"
            variant="outlined"
            value={subject}
            size="small"
            onChange={(e) => setSubject(e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Body"
            variant="outlined"
            multiline
            rows={5}
            size="small"
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
          <Button
            className={classes.button}
            variant="contained"
            type="submit"
            disabled={!email || !subject || !body}
          >
            Send Email
          </Button>
        </form>
        {emails.length > 0 && (
          <>
            <Typography variant="h6" style={{marginTop: 10}} gutterBottom>
              <b>Email Histories:</b>
            </Typography>
            <Grid container md={12}>
              <Grid md={5}>
                <Typography variant="subtitle1" style={{marginTop: 2}}>
                  <b>Subject</b>
                </Typography>
              </Grid>
              <Grid md={5}>
                <Typography variant="subtitle1" style={{marginTop: 2}}>
                  <b>Sent time</b>
                </Typography>
              </Grid>
              <Grid md={2}>
                <Typography variant="subtitle1" style={{marginTop: 2}}>
                  <b>Action</b>
                </Typography>
              </Grid>
            </Grid>
            <Paper style={{maxHeight: 150, overflow: 'auto'}}>
              <List>
                {emails.map((email) => (
                  <ListItem className={classes.email} key={email?.id}>
                    <Grid md={5}>
                      <ListItemText primary={email?.subject}/>
                    </Grid>
                    <Grid md={5}>
                      <ListItemText primary={email?.sent_time}/>
                    </Grid>
                    <Grid md={2}>
                      <Button
                        style={{textTransform: "none"}}
                        variant="text"
                        onClick={() => {
                          history.push({
                            pathname: `/dealership/${id}/emails/${email?.id}`,
                            state: {
                              actionType: "DETAILS",
                            },
                          });
                        }}
                      >
                        View
                      </Button>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </>
        )}
        </div>
  );
}

export default SendEmail;