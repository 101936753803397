import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card, CardContent} from '@mui/material';
import LogCallCard from './LogCallCard';
import SendEmail from './SendEmail';
import SetReminder from './SetReminder';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export default function ActivitiesTab(props) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const {emails, sendEmail, reminders, setReminder, deleteReminder, currentAdminUser, adminUsers, dealershipInfo, loading, LogCalls} = props;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <Card>
            <Box sx={{ bgcolor: 'background.paper',  overflow: 'auto' }}>
                <AppBar position="static">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="Log /Schedule Call" {...a11yProps(0)} />
                        <Tab label="Send Email" {...a11yProps(1)} />
                        <Tab label="Set Reminder" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
                <CardContent>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <LogCallCard currentAdminUser={currentAdminUser} adminUsers={adminUsers} LogCalls={LogCalls} />
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <SendEmail emails={emails} sendEmail={sendEmail} dealershipInfo={dealershipInfo} loading={loading} />
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        <SetReminder reminders={reminders} setReminder={setReminder} deleteReminder={deleteReminder} />
                    </TabPanel>
                </CardContent>
            </Box>
        </Card>
    );
}
