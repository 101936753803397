import React, { Component } from 'react';
import { Button, Form, Grid, Header, Segment, Checkbox } from 'semantic-ui-react';
import Axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import './Signin.css'
import { message } from 'antd';
import { Link } from 'react-router-dom';
const SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

class SignIn extends Component {
  state = { password: '', email: '', hasError: false };

  handleChange = (data) => {
    const { name, value } = data.target;
    this.setState({ [name]: value });
  }

  componentDidMount(){
    if (this.recaptchaRef){
      this.recaptchaRef.reset();
    }
  }

  handleSubmit = () => {
    const { password, email } = this.state;
    let base_url = process.env.REACT_APP_BASE_URL;
    // if (!this.state.captcha) {
    //   this.setState({showCaptchaMessage : true})
    //   return;
    // }
    Axios.post(`${base_url}/api/v1/sign-in`, {
      admin_login: {
        email,
        password
      },
      "g-recaptcha-response": this.state.captcha
    }).then(data => {
      if (data.data.status == true) {
        window.localStorage.setItem('auth_token', data.data.data.auth_token);
        window.localStorage.setItem('user_name', `${data.data.data.first_name} ${data.data.data.last_name}`);
        window.localStorage.setItem('user_data', JSON.stringify(data.data.data));
        window.localStorage.setItem('dealership_login', data.data.data.dealership_login);
        window.location.href = `/lease-applications-list`
      } else {

        message.error("Password Expired , please reset password")
        setTimeout(() => {
          window.location.href = "/change-password";
        }, 3000);      
      }
    }).catch(error => {
      this.setState({ hasError: true, message: error.response.data.errors[0].detail?? null })
    })
  }


  render() {
    if (window.localStorage.getItem('auth_token')) {      
      let urlObject = new URL(window.location.href)
      if (urlObject.searchParams.get('p') == 0) {
        window.localStorage.removeItem('auth_token');
        window.localStorage.removeItem('user_name');
        window.localStorage.removeItem('schedulingVal');

      } else {
        let auth_token = window.localStorage.getItem('auth_token')
        window.location.href = `${process.env.REACT_APP_BASE_URL}/auth/${auth_token}?path=/admins/lease_applications`
        return null;
      }
    }

    const { password, email } = this.state;
    return (
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column id='login-column'>
          <Header as='h3' style={{ color: '0F1419', marginTop: '10px' }}textAlign='center'>
            Speed Leasing Admin Portal
          </Header>
          {this.state.hasError && (<span style={{ color: 'red' }}> { this.state.message?? "Invalid Email or password."} </span>)}
          <Form size='large' onSubmit={this.handleSubmit}>

            <Segment stacked>
              <Form.Input
                fluid
                icon='user'
                iconPosition='left'
                name="email"
                placeholder='E-mail address'
                value={email}
                onChange={this.handleChange}
              />
              <Form.Input
                fluid
                icon='lock'
                iconPosition='left'
                placeholder='Password'
                type='password'
                name="password"
                value={password}
                onChange={this.handleChange}
              />
              <Form.Field>
                <div className='login-helpers'>
                  <div className='column left'>
                    <Checkbox label='Remember me'/>
                  </div>
                  <div className='column right'>
                    <Link to='/forget-password'>Forgot Password?</Link>
                    {/* <a href={`${process.env.REACT_APP_BASE_URL}/admins/password/new`}>Forgot password</a> */}
                  </div>
                </div>
              </Form.Field>
              {/* <div className="captcha">
                <ReCAPTCHA
                  sitekey={SITE_KEY}
                  ref={(ref) => { this.recaptchaRef = ref; }}
                  onChange={(captcha) => {this.setState({captcha,showCaptchaMessage : false })}}
                />
                {
                  this.state.showCaptchaMessage && (
                    <p style = {{
                      color  :'red'
                    }}>Please solve the captcha</p>
                  )
                }
              </div> */}

              <Button 
                id='login-button'
                primary 
                fluid 
                size='large' 
                type='submit'
              >
                Login
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid >
    )
  }
}

export default SignIn;