import React, { useState, useEffect } from 'react';
import logger from '../../util/logger';
import { GET } from "../../util/network";

import { Spin } from 'antd';
import { Calculator } from './Calculator';
import { useDispatch } from "react-redux";
import {setApplicationDetails } from '../../reducers/LeaseApplications'


function CalculatorRenderer(props) {
  const calculatorId= `${props.match.params.calculatorId}`
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  
  const getCalculatorDetails = async () => {
    if (!loading) { setLoading(true) }

    try {
      let data = await GET(`/api/v1/app-details?calculator_id=${calculatorId}`)
      if (data?.data?.data?.leaseApplication) {
        dispatch(setApplicationDetails(data?.data?.data?.leaseApplication))
      }
      setData(data.data.data.leaseApplication)
      setLoading(false)
    } catch (e) {
      logger.error("Error fetching Applicatins", e);
      setLoading(false)
    }
  }

  useEffect(() => {
    getCalculatorDetails();
  }, [])

  return data ? (
    <Spin
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      <Calculator/>
    </Spin>
  ) : null;
}

export default CalculatorRenderer
