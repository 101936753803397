import Axios from 'axios';
// import { authenticate } from './authenticate';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const auth_token = window.localStorage.getItem('auth_token');

// Axios.interceptors.response.use((response) => {
//         return response;
//     }, (error) => {
//         if (error.response !== undefined && error.response.status === 401 && error?.response?.data?.errors[0]?.detail === "Access denied. Invalid or Expired Token.") {
//             if(authenticate())
//             {
//                 alert("Your account has been accessed from other browser or Token expired!! Please login again")
//                 window.localStorage.clear();
//                 window.location.href = '/admins/login'
//             }
//             else{
//                 return Promise.reject(error);
//             }
//         } else {
//             return Promise.reject(error);
//         }
//     }
// )

export const GET = (url) => {
    return Axios.get(`${BASE_URL}${url}`, {
        headers: {
            Authorization: ` Token ${auth_token}`,
            Accept: "application/json"
        }
    })
}

export const GetWithParams = (url, params) => {
    return Axios.get(`${BASE_URL}${url}`, {
        headers: {
            Authorization: ` Token ${auth_token}`,
            Accept: "application/json"
        },
        params: params
    })
}

export const GETDATAFILTER = (url, params) => {
    return Axios.get(`${BASE_URL}${url}`, {
        params,
        headers: {
            Authorization: ` Token ${auth_token}`,
            Accept: "application/json"
        }
    })
}


export const DELETE = (url) => {
    return Axios.delete(`${BASE_URL}${url}`, {
        headers: {
            Authorization: ` Token ${auth_token}`,
            Accept: "application/json"
        }
    })
}

export const POST = (url, data) => {
    return Axios.post(`${BASE_URL}${url}`, data, {
        headers: {
            Authorization: ` Token ${auth_token}`,
            Accept: "application/json"
        }
    })
}

export const PUT = (url, data) => {
    return Axios.put(`${BASE_URL}${url}`, data, {
        headers: {
            Authorization: ` Token ${auth_token}`,
            Accept: "application/json"
        }
    })
}


export const PATCH = (url, data) => {
    return Axios.patch(`${BASE_URL}${url}`, data, {
        headers: {
            Authorization: ` Token ${auth_token}`,
            Accept: "application/json"
        }
    })
}