import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Checkbox, Col, Form, Input, message, Row } from 'antd';
import { POST, PUT } from '../../../util/network';
import logger from '../../../util/logger';
import { useDispatch } from "react-redux";
import { updateGpsUnit } from '../../../reducers/LeaseApplications'

export default function GpsUnit({ data, handleNoAuth, setLoading }) {
  const history = useHistory();

  const [form] = Form.useForm();
  const { active, gpsSerialNumber, id } = data.leaseApplicationGpsUnit ? data.leaseApplicationGpsUnit : [];
  const leaseApplicationId = data.id ? data.id : null;
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
    loggedInUserId = authData.id;
  }
  const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
  const disableFlag = locked_by != loggedInUserId ? true : false
  const dispatch = useDispatch();

  const save = async (values) => {
    setLoading(true);

    try {
      let response = null;
      if (id) {
        response = await PUT(`/api/v1/lease-application-gps-units/${id}`, values);
      } else {
        response = await POST(`/api/v1/lease-application-gps-units/`, values);
      }

      message.success("Data saved successfully");
      dispatch(updateGpsUnit(response.data));
    } catch (e) {
      logger.error("Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while saving your data');
      }
    }

    setLoading(false);
  };  

  const onFinish = async (values) => {
    values = {
      ...values,
      leaseApplicationId: leaseApplicationId
    };

    await save(values);
  }

  const onFinishFailed = ({ errorFields }) => {
    form.scrollToField(errorFields[0].name);
  };

  const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  return data ? (
    <Form
      { ...layout }
      form={ form }
      onFinish={ onFinish }
      onFinishFailed={ onFinishFailed }
      initialValues={{
        active: active,
        gpsSerialNumber: gpsSerialNumber
      }}
    >
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          <Row className='flex-jc-fs'>
            <Col xs={24} sm={24} md={24} lg={24} xl={6}>
              <Form.Item 
                label='Serial Number'
                name='gpsSerialNumber'
                rules={
                  [{
                    required: true,
                    message: 'This field is required'
                  }]
                }
              >
                <Input disabled={disableFlag}/>
              </Form.Item>
            </Col> 
          </Row>
          <Row className='flex-jc-fs'>     
            <Col xs={24} sm={24} md={24} lg={24} xl={6} style={{alignSelf: `flex-end`}}>
              <Form.Item           
                valuePropName='checked'
                name='active'            
                rules={
                  [{
                    required: true,
                    message: 'This field is required'
                  }]
                }
              >
                <Checkbox disabled={disableFlag} required='required'>I attest this GPS unit has been activated and a ping location received</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className='flex-jc-fs'>
            <Button
              type='primary'
              htmlType='submit'
              disabled={disableFlag}
            >
              Save
            </Button>
          </Row>
        </Card>
      </Col>
    </Form>
  ) : null;
}