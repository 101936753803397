import React, { useState } from "react";
import {
  Card,
  Form,
  Col,
  InputNumber,
  Checkbox,
  Button,
  Row
} from "antd";
import { useDispatch } from "react-redux";
import { updateShortFund } from '../../reducers/LeaseApplications'

export default function DealershipShortfund({ data, disableFlag, save }) {
    const dispatch = useDispatch();

    const [form] = Form.useForm()
    const { isDealershipSubjectToClawback, thisDealDealershipClawbackAmount, afterThisDealDealershiClawbackAmount } = data.dealership;
    const [isDealershipSubjectShortfund, setIsDealershipSubjectShortfund] = useState(isDealershipSubjectToClawback)
    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name);
    };
    const onFinish = async (values) => {
        values = { ...values };
        await save(values);
        dispatch(updateShortFund(values))
    }
    const layout = {
        labelCol: {
          span: 24,
        },
        wrapperCol: {
          span: 24,
        },
      };

    const handleDealershipSubjectShortfund = (e) => {
        setIsDealershipSubjectShortfund(e.target.checked)
        if (!e.target.checked){
            form.setFieldsValue({
                thisDealDealershipClawbackAmount: 0.00
            })
        }

    }

    return (
      <Form 
        {...layout} 
        form={form}
        onFinish={onFinish} 
        onFinishFailed={onFinishFailed}
        initialValues={{ 
            isDealershipSubjectToClawback: isDealershipSubjectToClawback,
            thisDealDealershipClawbackAmount: thisDealDealershipClawbackAmount,
            afterThisDealDealershiClawbackAmount: afterThisDealDealershiClawbackAmount
        }}
      >
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card>
            <Row className='flex-jc-fs' gutter={[32, 0]}>
              <Col xs={24} sm={24} md={18} lg={12} xl={8}>
                <Form.Item valuePropName="checked" name="isDealershipSubjectToClawback">
                    <Checkbox onChange={(e)=>{ handleDealershipSubjectShortfund(e) }} disabled={disableFlag}>Dealership is Subject to Shortfund</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row className='flex-jc-fs' gutter={[32, 0]}>
              <Col xs={24} sm={24} md={14} lg={12} xl={8}>
                <Form.Item label="Dealership Shortfund This Lease" name="thisDealDealershipClawbackAmount">
                  <InputNumber 
                    disabled={!isDealershipSubjectShortfund || disableFlag}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')} 
                    precision={2}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className='flex-jc-fs'>
              <Button type="primary" htmlType="submit" disabled={disableFlag}>Save</Button>
            </Row>
          </Card>
        </Col>
      </Form>
    );
}