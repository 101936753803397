import React, { useState } from 'react';
import logger from '../../../util/logger';
import { GET } from '../../../util/network';
import { Card, Row, Col, Table, Button, Modal, message, Spin } from "antd";
import TloReportDetails from './TloReportDetails';
import { getDetails } from '../../../reducers/LeaseApplications'
import { useDispatch } from "react-redux";

const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
    let loggedInUserId = null;
    if(authData !== null) {
        loggedInUserId = authData.id;
    }

const TloReports = ({ data, handleNoAuth, setLoading }) => {
    const tloReport = data?.tloReports || [];
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedTloReport, setSelectedTloReport] = useState('');
    const [apiCall, setApiCall] = useState('');
    const [loader, setLoader] = useState(false);

    const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
    const disableFlag = locked_by != loggedInUserId ? true : false

    const dispatch = useDispatch();
    // const [showAttachmentRepullButton, setShowAttachmentRepullButton] = useState(true);



    const handleRepullConditions = (source) => {
        if(source === 'lessee'){
          handleRepullTloReport('lessee_id', data.lessee.id)
        }
        else if(source === 'colessee') {
          handleRepullTloReport('colessee_id', data.colessee.id)
        }
    }
    const handleRepullTloReport = async (param, lessee_id) => {
        setLoading(true);
        try {
            const result = await GET(`/api/v1/lease_applications/repull-tlo-report?${param}=${lessee_id}`);
            dispatch(getDetails(result.data.leaseApplication));
        } catch (e) {
          logger.error("Error processing Tlo Reports", e);
          if (e && e.response && e.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error('Error processing tlo reports');
          }
        }
        setLoading(false);
    }
    const handleModal = (row) => {
        setSelectedTloReport(row.id);
        setApiCall(!apiCall);
        setModalOpen(true);
    }

    const handleCloseModal = () => {
        setModalOpen(false);
    }

    const handleRepullTloAttachment = async (row) => {
        setLoader(true)
        let buttonClick = parseInt(window.localStorage.getItem('attachment_pull_button_click_' + row.id)) || 0
        window.localStorage.setItem('attachment_pull_button_click_'+  row.id, buttonClick + 1)
        if (buttonClick >= 3) {
            alert('Unable to pull the TLO attachment.');
            setLoader(false)
            return false
        }

        try {
            // let response = await GET(`/api/v1/tlo-report/repull-attachment/${row.id}`);
            // message.success("TLO attachment repulled successfully");
            // dispatch(getDetails(response.data.leaseApplication));
            setLoader(false)
        } catch (e) {
            setLoader(false)
            logger.error("Error processing attachment", e);
            if (e && e.response && e.response.status === 401) {
                handleNoAuth(history);
            } else {
                message.error('Error processing attachment');
            }
        }
    }

    const cols = [
        {
            title: 'Lessee',
            dataIndex: 'lesseeName',
            key: 'lesseeName',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Request Time',
            dataIndex: 'requestedAt',
            key: 'requestedAt'
        },
        {
            title: 'Report Request Time',
            dataIndex: 'documentRequestedAt',
            key: 'documentRequestedAt'
        },
        // {
        //     title: 'Report',
        //     dataIndex: 'attachment',
        //     key: 'attachment',
        //     render(val) {
        //         return val ? (
        //             <a href={val} target="_blank" rel="noreferrer">View</a>
        //         ) : 'N/A'
        //     }
        // },
        {
            title: 'Details',
            dataIndex: 'details', 
            key: 'details',
            render(_, row) {
                let repullClickCount = parseInt(window.localStorage.getItem('attachment_pull_button_click_' + row.id)) || 0
                return (
                    <>
                        <Button
                            type="primary"
                            onClick={() => handleModal(row)}
                        >
                            View
                        </Button>

                        {
                            // row.attachment === '' && !row.isTokenExpired  && row.documentRequestedAt === null && repullClickCount < 4 && showAttachmentRepullButton && <Button
                            row.attachment === ''  && row.documentRequestedAt === null && repullClickCount < 4 && <Button
                                style={{ marginLeft: '10px' }}
                                type="primary"
                                onClick={() => handleRepullTloAttachment(row)}
                            >
                                Repull Attachment
                            </Button>
                        }

                    </>
                )
            }
        }
    ];
    
    return (
        <>
            <Spin
              spinning={loader}
              size="large"
              tip="Loading..."
            >
                <Card title='TLO Reports'>
                    <Row>
                        <Col span={24}>
                            <Table
                              className='slc-table'
                              rowKey="id"
                              columns={cols}
                              dataSource={tloReport}
                              pagination={true}
                              size="small"
                              bordered={false}
                            />
                        </Col>
                    </Row>
                    <Row className='flex-jc-fs'>
                        {
                          data.creditStatus !== 'Unsubmitted' && data.showTloLesseeRepull && <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                              <Button
                                className='slc-button-link-only'
                                type="link"
                                onClick={() => {
                                    handleRepullConditions('lessee')
                                }
                                }
                                disabled={disableFlag}
                              >
                                  Repull TLO Lessee Report
                              </Button>
                          </Col>
                        }
                        {
                          data.colessee && data.creditStatus !== 'Unsubmitted' && data.showTloColesseeRepull && <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                              <Button
                                className='slc-button-link-only'
                                type="link"
                                onClick={() => {
                                    handleRepullConditions('colessee')
                                }
                                }
                                disabled={disableFlag}
                              >
                                  Repull TLO Co-Lessee Report
                              </Button>
                          </Col>
                        }
                    </Row>
                </Card>
            </Spin>

            <Modal
                title="TLO Report Details"
                visible={modalOpen}
                centered
                width={"90VW"}
                onCancel={handleCloseModal}
                footer={[
                    <Button key="Cancel" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                ]}
                style={{
                    top: 20,
                }}
            >
                <TloReportDetails
                    selectedTloReport={selectedTloReport}
                    apiCall={apiCall}
                />
            </Modal>

        </>
    )
}

export default TloReports
