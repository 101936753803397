import React, {useState} from 'react'
import {Card, CardContent, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, TextField} from '@material-ui/core';
import AppsIcon from '@mui/icons-material/Apps';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ApprovalIcon from '@mui/icons-material/Approval';
import ArticleIcon from '@mui/icons-material/Article';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {useDispatch} from "react-redux"
import {fetchDealershipActivities} from '../../../reducers/DealershipActivities'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {styled} from "@mui/system";
import NoteCard from "./NoteCard";

const useStyles = makeStyles({
  root: {
    //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    background: '#fff',
    borderRadius: 5,
    border: 0,
    color: '#001629',

    padding: '24px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)'
  },
  count: {
    fontWeight: 'bold'
  },
  activityHeader: {
    letterSpacing: "-0.4px",
    fontSize: "16px"
  },
  dealershipheader: {
    letterSpacing: "-0.6px",
    fontSize: "21px"
  },
  dealershipCount: {
    fontWeight: 'bold'
  },
  actionButton: {
    width: '300px'
  },
  acitivityContainer: {
    display: "flex"
  },
  acitivityContant: {
    padding: "5px",
    position: "relative",
    bottom: "5px",
    left: "5px"

  },
  acitivityLogo: {}
});

const ITEM_HEIGHT = 48;
const cardIcon = [
  {
    label: "Apps Submitted",
    icon: <AppsIcon sx={{
      color: "#ffff",
      background: "#ff3500",
      padding: "6px",
      borderRadius: "5px",
      boxShadow: "#ff3500 2px -2px",
      fontSize: 45
    }}/>
  },
  {
    label: "Funded Contracts",
    icon: <MonetizationOnIcon sx={{
      color: "#ffff",
      background: "red",
      padding: "6px",
      borderRadius: "5px",
      boxShadow: "red 2px -2px",
      fontSize: 45
    }}/>
  },
  {
    label: "Conversion Rate",
    icon: <CurrencyExchangeIcon sx={{
      color: "#fff",
      background: "red",
      padding: "6px",
      borderRadius: "5px",
      boxShadow: "red 2px -2px",
      fontSize: 45
    }}/>
  },
  {
    label: "Approval Rates",
    icon: <ApprovalIcon sx={{
      color: "#fff",
      background: "green",
      padding: "6px",
      borderRadius: "5px",
      boxShadow: "green 2px -2px",
      fontSize: 45
    }}/>
  },

  {
    label: "Docs Issued",
    icon: <ArticleIcon sx={{
      color: "#ffff",
      background: "blue",
      padding: "6px",
      borderRadius: "5px",
      boxShadow: "blue 2px -2px",
      fontSize: 45
    }}/>
  },
  {
    label: "Lease package Received",
    icon: <LocalShippingIcon sx={{
      color: "#fff",
      background: "violet",
      padding: "6px",
      borderRadius: "5px",
      boxShadow: "violet 2px -2px",
      fontSize: 45
    }}/>
  },
  {
    label: "Funding Delay",
    icon: <AssignmentLateIcon sx={{
      color: "#fff",
      background: "blue",
      padding: "6px",
      borderRadius: "5px",
      boxShadow: "blue 2px -2px",
      fontSize: 45
    }}/>
  },
  {
    label: "Decline Rates",
    icon: <ThumbDownOffAltIcon sx={{
      color: "#fff",
      background: "blue",
      padding: "6px",
      borderRadius: "5px",
      boxShadow: "blue 2px -2px",
      fontSize: 45
    }}/>
  },
  {
    label: "Funding Approved",
    icon: <CheckCircleIcon sx={{
      color: "#fff",
      background: "green",
      padding: "6px",
      borderRadius: "5px",
      boxShadow: "green 2px -2px",
      fontSize: 45
    }}/>
  },

]

const options = [
  'All',
  'Custom',
  'Last Month',
  'Current Month',
  'Last Quarter',
  'Current Year',
  'Previous Year',
];

export default function Activities(props) {
  const {countData, notes, setNotes, addNote, deleteNote, editNote, id} = props;
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateRangeFilter, setDateRangeFilter] = useState(false);
  const [rangeStartDate, setRangeStartDate] = useState("");
  const [rangeEndDate, setRangeEndDate] = useState("");
  const [rangeEndMinimumStartDate, setRangeEndMinimumStartDate] = useState("");
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const callActivitiesApi = (option) => {
    setAnchorEl(null);
    setDateRangeFilter(false);
    setRangeStartDate("");
    setRangeEndDate("");
    setRangeEndMinimumStartDate("");
    if (option === 'Custom') {
      setDateRangeFilter(true);
    } else {
      dispatch(fetchDealershipActivities({id: id, option: option}));
    }
  };

  const handleChangeDateStart = (newValue) => {
    let startdate = new Date(newValue);
    const formatedDate = new Date(startdate)
      .toLocaleDateString("fr-CA")
      .split("-")
      .join("/");
    setRangeStartDate(formatedDate);
    setRangeEndMinimumStartDate(formatedDate);
    if (rangeEndDate !== "") {
      const timeRangeDates = formatedDate + " - " + rangeEndDate;
      dispatch(
        fetchDealershipActivities({
          id: id,
          option: "Custom",
          time_filter: timeRangeDates,
        }));
    }
  };

  const handleChangeDateEnd = (newValue) => {
    let endDate = new Date(newValue);
    const formatedDate = new Date(endDate)
      .toLocaleDateString("fr-CA")
      .split("-")
      .join("/");
    setRangeEndDate(formatedDate);
    const timeRangeDates = rangeStartDate + " - " + formatedDate;
    console.log(timeRangeDates);
    dispatch(
      fetchDealershipActivities({
        id: id,
        option: "Custom",
        time_filter: timeRangeDates,
      })
    );
  };


  return (
    <Grid container alignItems="flex-start" spacing={2}>
      <Grid item md={7} sm={7} lg={7}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item md={8} style={{marginLeft: 'auto'}}>
                {dateRangeFilter && (
                  <Grid container direction="row">
                    <Grid container item justifyContent="flex-end" spacing={2}>
                      <Grid item sm={6} md={6} lg={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="Start Date"
                            inputFormat="YYYY-MM-DD"
                            value={rangeStartDate}
                            onChange={handleChangeDateStart}
                            renderInput={(params) => <StyledTextField
                              id="filled-input"
                              {...params}
                              size="small"
                              label="Start Date"
                              margin="normal"
                              name="start_date" {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="End Date"
                            inputFormat="YYYY-MM-DD"
                            value={rangeEndDate}
                            onChange={handleChangeDateEnd}
                            minDate={rangeEndMinimumStartDate}
                            renderInput={(params) => <StyledTextField
                              id="filled-input"
                              {...params}
                              size="small"
                              label="End Date"
                              margin="normal"
                              name="end_date" {...params} />}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item md={1} style={{marginLeft: 'auto', marginRight: '50px'}}>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon/>
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: "20ch",
                    },
                  }}
                >
                  {options.map((option) => (
                    <MenuItem
                      key={option}
                      selected={option === "Pyxis"}
                      onClick={() => callActivitiesApi(option)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              {countData.map(({label, count}) => (
                <CountCard key={label} label={label} count={count}/>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={5} sm={5} lg={5}>
        <NoteCard
          notes={notes}
          setNotes={setNotes}
          addNote={addNote}
          deleteNote={deleteNote}
          editNote={editNote}
        />
      </Grid>
    </Grid>
  );
}

const CountCard = ({label, count}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item md={6}>
        <div className={classes.acitivityContainer}>
          <div className={classes.acitivityLogo}>
            {cardIcon.filter(x => x.label === label).map(item => item.icon)}
          </div>
          <div className={classes.acitivityContant}>
            <div className={classes.activityHeader}>
              {label}
            </div>
            <div className={classes.count}>
              {count}
            </div>
          </div>
        </div>
      </Grid>
    </>
  );
};

const StyledTextField = styled(TextField)({
  fontSize: "10px !important",
  "& label.Mui-focused": {
    // color: '#001629',
  },
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      // borderColor: '#001629',
    },
    "&.Mui-focused fieldset": {
      // borderColor: '#001629',
    },
  },
  "& .MuiFormHelperText-root": {
    marginTop: "0px !important",
    marginBottom: "-18px !important",
  },
});