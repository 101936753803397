import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Layout,
  Menu,
  message,
  Spin,
} from "antd";
import Icon from "@ant-design/icons";
import { GET } from "../../util/network";
import logger from "../../util/logger";
import MainHeader from "../../components/layouts/MainHeader";
import MainBreadcrumb from "../../components/layouts/MainBreadcrumb";
import MainFooter from "../../components/layouts/MainFooter";
import { AddNew } from "../../components/layouts/Svg";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";

const { Sider } = Layout;

export default function MakeRenderer(props) {
  const { id } = props.match.params;
  const { handleNoAuth } = props;
  const history = useHistory();

  const [makeData, setMakeData] = useState();
  const [loading, setLoading] = useState(false);

  const makeDetails = async () => {
    setLoading(true);
    try {
      await GET(`/api/v1/makes/details/${id}`)
        .then((response) => {
          setMakeData(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            handleNoAuth(history);
          } else {
            message.error("Error while fetching Make Details");
          }
        });
    } catch (e) {
      logger.error("Error while fetching Make Details", e);
    }
    setLoading(false);
  };

  useEffect(() => {
    makeDetails();
  }, []);

  return (
    <Spin spinning={loading} size="large" tip="Loading...">
      <Layout>
        <MainHeader activePage="newAssets" />
        <Layout>
          <Sider {...SiderStyleProps}>
            <Menu
              defaultSelectedKeys={["1"]}
              selectedKeys={["1"]}
              style={{ width: "100%" }}
            >
              <Menu.Item key="1">
                <Icon component={AddNew} />
                Make Details
              </Menu.Item>
            </Menu>
          </Sider>

          <Layout id="content-area-layout">
            <MainBreadcrumb
              items={[
                { text: "Home", link_type: "linkto", link: "/dashboard" },
                { text: "New Assets", link_type: "ahref", link: void 0 },
                {
                  text: "Makes",
                  link_type: "ahref",
                  link: "/new-assets/makes/list",
                },
                { text: "Deatils", link_type: "ahref", link: void 0 },
              ]}
            />

            {makeData && (
              <Box sx={{ flexGrow: 1 }}>
                <Grid container direction="row">
                  <Grid item md={16} container spacing={2}>
                    <Grid item md={12} sm={9} lg={6}>
                      <TableContainer component={Box}>
                        <Table aria-label="customized table">
                          <TableBody>
                            <StyledTableRow>
                              <StyledtableCell1>Name:</StyledtableCell1>
                              <StyledtableCell2>
                                {makeData.name}
                              </StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>
                                JD Power Make Category:
                              </StyledtableCell1>
                              <StyledtableCell2>
                                {makeData.make_category}
                              </StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>LMS manf:</StyledtableCell1>
                              <StyledtableCell2>
                                {makeData.lms_manf}
                              </StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>
                                VIN Starts With:
                              </StyledtableCell1>
                              <StyledtableCell2>
                                {makeData.vin_starts_with
                                  ? makeData.vin_starts_with
                                  : "N/A"}
                              </StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>API Source:</StyledtableCell1>
                              <StyledtableCell2>
                                {makeData.api_source}
                              </StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>
                                JD POWER Make Id:
                              </StyledtableCell1>
                              <StyledtableCell2>
                                {makeData.jd_make_id
                                  ? makeData.jd_make_id
                                  : "N/A"}
                              </StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>Version:</StyledtableCell1>
                              <StyledtableCell2>
                                {makeData.version_id
                                  ? makeData.version_id
                                  : "N/A"}
                              </StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>Version Name:</StyledtableCell1>
                              <StyledtableCell2>
                                {makeData.version_name
                                  ? makeData.version_name
                                  : "N/A"}
                              </StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>Active:</StyledtableCell1>
                              <StyledtableCell2>
                                {makeData.active ? "Yes" : "No"}
                              </StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>NADA Enabled:</StyledtableCell1>
                              <StyledtableCell2>
                                {makeData.nada_enabled ? "Yes" : "No"}
                              </StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>Created At:</StyledtableCell1>
                              <StyledtableCell2>
                                {makeData.created_at}
                              </StyledtableCell2>
                            </StyledTableRow>
                            <StyledTableRow>
                              <StyledtableCell1>Updated At:</StyledtableCell1>
                              <StyledtableCell2>
                                {makeData.updated_at}
                              </StyledtableCell2>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Layout>
        </Layout>
        <MainFooter />
      </Layout>
    </Spin>
  );
}

const StyledtableCell1 = styled(TableCell)({
  fontWeight: "bold",
  fontSize: "13px",
});

const StyledTableRow = styled(TableRow)({
  "& td": { border: 0, padding: 8 },
});

const StyledtableCell2 = styled(TableCell)({
  fontSize: "12px",
});

export const SiderStyleProps = {
  breakpoint: "md",
  collapsedWidth: "0",
  width: 200,
  id: "slc-sider",
  style: {
    background: "#fff",
    position: "sticky",
    overflow: "auto",
    left: 0,
    bottom: 0,
    top: 0,
    height: "80vh",
  },
};
