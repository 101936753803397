import React, {useState, useEffect} from "react";
import {message, Spin} from "antd";
import {GET} from '../../util/network'
import CreditTierList from "./CreditTierList";
import logger from "../../util/logger";

const initialState = {
  page: 0,
  limit: 10,
  search: '',
  filterFlag: false,
  makeId: '',
  modelGroupId: '',
  makeObj: null,
  description: '',
  createdAtFrom: null,
  createdAtTo: null,
  UpdatedAtFrom: null,
  UpdatedAtTo: null,
  effectiveDateFrom: null,
  effectiveDateTo: null,
  endDateFrom: null,
  endDateTo: null,
  position: '',
  positionComp: "=",
  irrValue: '',
  irrValueComp: "=",
  maximumAdvancePercentage: '',
  maximumAdvancePercentageComp: '=',
  maximumFiPercentage: '',
  maximumFiPercentageComp: '=',
  requiredDownPayment: '',
  requiredDownPaymentComp: "=",
  securityDeposit: '',
  securityDepositComp: "=",
}

export default function CreditTiersRenderer(props) {
  const { handleNoAuth } = props
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({credit_tiers: [], total: 0})
  const [makeOptions, setMakeOptions] = useState([]);
  const [pageState, setPageState] = useState({...initialState});

  const getMakeOptions = async () => {
    {
     setLoading(true);
     try {
       await GET(`/api/v1/makes/list?filter_option=true`)
         .then((response) => {
           setMakeOptions(response.data || []);
         })
         .catch((error) => {
           if (error.response && error.response.status === 401) {
             handleNoAuth(history);
           } else {
             message.error("Error while fetching Dealerships Options");
           }
         });
     } catch (e) {
       logger.error("Error while fetching Dealerships Options", e);
     }

     setLoading(false);
   }
 };

  // const getCreditTiers = async () => {
  //   setLoading(true)
  //   try {
  //     let encodedParams = Object.entries(pageState).map(([key, val]) => `${key}=${val}`).join('&');
  //     const response = await GET(`/api/v1/credit_tiers/list?` + encodedParams);
  //     setResponse(response?.data);
  //   } catch (e) {
  //     message.error("Error while loading credit tiers.")
  //   }
  //   setLoading(false)
  // }
  
  useEffect(() => {
    // getCreditTiers();
    getMakeOptions();
  }, []);

  return (
    <Spin spinning={loading}>
      <CreditTierList
        data={response.credit_tiers}
        makes={makeOptions}
        pageState={pageState}
        setPageState={setPageState}
        initialState={initialState}
        total={response.total}
        setResponse={setResponse}
      />
    </Spin>
  )
}
