import React from "react";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LicenseInfo } from '@mui/x-license-pro';
import { styled } from '@mui/system';
import { useDispatch } from "react-redux"
import ClearIcon from '@mui/icons-material/Clear';
import { updateModelYearsPageState, updateFilters, removeFilters } from '../../../reducers/ModelYears'
LicenseInfo.setLicenseKey('06c2f0cf50adbcfcc3f1fdcdf8402d39Tz00OTMwMCxFPTE2OTIzNzY1MTM3ODUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export default function ModelYearsDataGrid(props) {
  const { columns, data, loading, pageState, total, historyId, setPageState } = props;
  const dispatch = useDispatch();

  const onFilterChange = React.useCallback((filterModel) => {
    // Here you save the data you need from the filter model
    // setQueryOptions({ filterModel: { ...filterModel } });
    // console.log("filtermodels change", filterModel)
    dispatch(removeFilters(filterModel.items))
    filterModel.items.map((item) => setFilterItems(item))
  }, []);

  const setFilterItems = (item) => {
    if(item.columnField === 'name'){
      if(item.value && item.value.length >= 4){
        dispatch(updateFilters({ filter: item }))
      }
    }
    if(item.columnField === 'year'){
      if(item.value && item.value.length == 4){
        dispatch(updateFilters({ filter: item }))
      }
    }
    if(item.columnField === 'nadaAvgRetail'){
      if(item.value && item.value.length >= 4){
        dispatch(updateFilters({ filter: item }))
      }
    }
    if(item.columnField === 'startDate'){
      if(item.value && item.value.length > 0){
        dispatch(updateFilters({ filter: item }))
      }
    }
    if(item.columnField === 'endDate'){
      if(item.value && item.value.length > 0){
        dispatch(updateFilters({ filter: item }))
      }
    }
    if(item.columnField === 'originalMsrp'){
      if(item.value && item.value.length >= 4){
        dispatch(updateFilters({ filter: item }))
      }
    }
    if(item.columnField === 'nadaRough'){
      if(item.value && item.value.length >= 4){
        dispatch(updateFilters({ filter: item }))
      }
    }
    if(item.columnField === 'residual24'){
      if(item.value && item.value.length >= 3){
        dispatch(updateFilters({ filter: item }))
      }
    }
    if(item.columnField === 'residual36'){
      if(item.value && item.value.length >= 3){
        dispatch(updateFilters({ filter: item }))
      }
    }
    if(item.columnField === 'residual48'){
      if(item.value && item.value.length >= 3){
        dispatch(updateFilters({ filter: item }))
      }
    }
    if(item.columnField === 'residual60'){
      if(item.value && item.value.length >= 3){
        dispatch(updateFilters({ filter: item }))
      }
    }
  }

  return (
    <>
      {
        historyId === undefined ?
          <StyledDataGridPro
            components={{ FilterPanelDeleteIcon: () => <ClearIcon color={'error'}/>}}
            componentsProps={{ filterPanel: { linkOperators: ['and'] } }}
            disableColumnReorder={true}
            disableSelectionOnClick={true}
            getRowHeight={() => 'auto'}
            columns={columns}
            // disableMultipleColumnsFiltering='true'
            rows={data}
            loading={loading}
            rowsPerPageOptions={[25, 50, 100]}
            density="comfortable"
            pagination
            rowCount={total ?? 0}
            page={pageState.page <= 0 ? 0 : pageState.page}
            pageSize={pageState.limit}
            paginationMode="server"
            onPageChange={(newPage) => {
              historyId === undefined ? dispatch(updateModelYearsPageState({ page: newPage })) : setPageState({ page: newPage, limit: pageState.limit })
            }}
            onPageSizeChange={(newPageSize) => {
              historyId === undefined ? dispatch(updateModelYearsPageState({ limit: newPageSize  })) : setPageState({ limit: newPageSize, page: pageState.page })
            }}
            sortingOrder={['desc', 'asc']}
            filterMode='server'
            onFilterModelChange={onFilterChange}
            sx={{
              ".highlight": {
                bgcolor: "#e6f3ff !important",
                "&:hover": {
                  bgcolor: "darkgrey",
                },
              },
            }}
          />
          :
          <StyledDataGridPro
            disableColumnReorder={true}
            disableSelectionOnClick={true}
            getRowHeight={() => 'auto'}
            columns={columns}
            // disableMultipleColumnsFiltering='true'
            rows={data}
            loading={loading}
            rowsPerPageOptions={[25, 50, 100]}
            density="comfortable"
            pagination
            rowCount={total ?? 0}
            page={pageState.page <= 0 ? 0 : pageState.page}
            pageSize={pageState.limit}
            paginationMode="server"
            onPageChange={(newPage) => {
              historyId === undefined ? dispatch(updateModelYearsPageState({ page: newPage })) : setPageState({ page: newPage, limit: pageState.limit })
            }}
            onPageSizeChange={(newPageSize) => {
              historyId === undefined ? dispatch(updateModelYearsPageState({ limit: newPageSize  })) : setPageState({ limit: newPageSize, page: pageState.page })
            }}
            sortingOrder={['desc', 'asc']}
            disableColumnFilter={true}
            sx={{
              ".highlight": {
                bgcolor: "#e6f3ff !important",
                "&:hover": {
                  bgcolor: "darkgrey",
                },
              },
            }}
          />
      }
    </>
  )
}

const StyledDataGridPro = styled(DataGridPro)({
  boxShadow: 'none !important',
  border: "none !important",
  '& .MuiDataGrid-cell': {
    padding: '10px',
  },
  '& .MuiDataGrid-row': {
    fontSize: 12
  },
  '& .wrapHeader .MuiDataGrid-colCellTitle': {
    overflow: "hidden",
    lineHeight: "20px",
    whiteSpace: "normal"
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: 'normal !important',
    lineHeight: 'normal',
    overflowWrap: "break-word",
    letterSpacing: '-0.5px'
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: "#e8ecf0",
    color: "#000000",
  },
  '& .MuiChip-label': {
    paddingLeft: '12px',
    paddingRight: '12px',
    whiteSpace: 'normal !important',
    wordWrap: ' break-word',
    lineHeight: ' 1',
    paddingBottom: '2px',
    paddingTop: '2px',
    letterSpacing: '-0.5px'
  },
  '& .MuiBadge-badge':{
    display: "none !important"
  },
  '& .super-app-theme--Filled': {
    backgroundColor: "#ed6248",
    '&:hover': { backgroundColor: "#e93b1b" }
  }
})
