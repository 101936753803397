import React from 'react'
import { Typography } from 'antd'

import './CalculationTable.css'

const { Text } = Typography;

export default function CalculationTable({ data }) {
  const { creditAverageSummary } = data

  let textMaxAllowablePaymentProps = { type: creditAverageSummary?.overrideMaxAllowablePayment ? "danger" : "", delete: !!creditAverageSummary?.overrideMaxAllowablePayment }

  return creditAverageSummary ? (
    <>
      <table className="credit-cal-table">
        <tbody>
        <tr>
          <td className="cal">{creditAverageSummary.provenMonthlyIncome || '0'}</td>
          <td className="cal-label">Proven Monthly Income</td>
        </tr>
        <tr>
          <td className="cal">
            <span className="cal-operator">x</span>
            {creditAverageSummary.paymentLimitPercentage || '0'}
          </td>
          <td className="cal-label">Max Payment Pct.</td>
        </tr>
        <tr>
          <td className="cal cal-equal">
            <span className="cal-operator">=</span>
            {creditAverageSummary.maxAllowablePayment || '0'}
          </td>
          <td className="cal-label">Calculated Max Payment</td>
        </tr>

        <tr>
          <td className="cal">
            <Text {...textMaxAllowablePaymentProps}>{creditAverageSummary.maxAllowablePayment || '0'}</Text>
          </td>
          <td className="cal-label">
            <Text {...textMaxAllowablePaymentProps}>Max Allowable Payment</Text>
          </td>
        </tr>
        { creditAverageSummary.overrideMaxAllowablePayment &&
          <tr>
            <td className="cal">{creditAverageSummary.overrideMaxAllowablePayment}</td>
            <td className="cal-label">Override Max Allowable Payment</td>
          </tr>
        }
        <tr>
          <td className="cal">
            <span className="cal-operator">-</span>
            {creditAverageSummary.totalMonthlyPayment || '0'}
          </td>
          <td className="cal-label">Bike Payment</td>
        </tr>
        <tr>
          <td className={`cal cal-equal ${creditAverageSummary.isVarianceNegative ? 'cal-negative' : ''}`}>
            <span className="cal-operator">=</span>
            {creditAverageSummary.overrideMaxAllowablePayment ? creditAverageSummary.overrideVariance : creditAverageSummary.variance || '0'}
          </td>
          <td className="cal-label">Variance</td>
        </tr>
        </tbody>
      </table>
    </>
  ) : null
}
