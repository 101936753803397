import React, {useState, useEffect} from "react";
import {message, Spin} from "antd";
import {GET} from "../../util/network";
import DocumentRequestsList from "./DocumentRequestsList";

export default function DocumentRequestsRenderer(props) {
  const {handleNoAuth} = props;
  const [loading, setLoading] = useState(false);
  const initialState = {
    page: 0,
    limit: 25,
    filter: false,
    asset_color: '',
    lease_app_id: '',
    asset_make: '',
    asset_model: '',
    asset_year: '',
    asset_vin: ''
  }
  const [pageState, setPageState] = useState({...initialState});
  const [response, setResponse] = useState({data: [], total: 0});

  const getDocumentRequests = async () => {
    setLoading(true);
    let encodedParam = Object.entries(pageState).map(([key, val]) => `${key}=${val}`).join('&');
    try {
      const response = await GET(
        `/api/v1/lease-document-requests?` + encodedParam
      );
      setResponse(response?.data);
    } catch (e) {
      message.error("Error while loading Data.");
    }
    setLoading(false);
  };

  useEffect(() => {
    getDocumentRequests();
  }, [pageState]);

  return (
    <Spin spinning={loading}>
      <DocumentRequestsList
        data={response.data}
        loading={loading}
        initialState={initialState}
        pageState={pageState}
        setPageState={setPageState}
        total={response.total}
      />
    </Spin>
  );
}
