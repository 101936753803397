import React, { useState } from "react";
import { Card, Row, Col, Spin } from "antd";
import Document from  './workflow/Document';
import Credit from  './workflow/Credit';
import Workflow from  './workflow/Workflow';

export default function WorkflowParent({ data, disableFlag, save, ...props }) {
  const { allowedActions, creditStatusOptions, declineReasonTypeOptions, appNumber } = props;

  const [loading, setLoading] = useState(false);

  return !data ? null : (
    <Spin
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Card className='h-100' title="Workflow">
            <Workflow
              allowedActions={allowedActions}
              data={data}
              save={save}
              disableFlag={disableFlag}
              setLoading={setLoading}
              {...props}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Card className='h-100' title="Credit">
            <Credit
              data={data}
              disableFlag={disableFlag}
              creditStatusOptions={creditStatusOptions}
              declineReasonTypeOptions={declineReasonTypeOptions}
              save={save}
              appNumber={appNumber}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Card className='h-100' title="Document">
            <Document
              data={data}
              disableFlag={disableFlag}
              save={save}
            />
          </Card>
        </Col>
      </Row>
    </Spin>
  );
}