import React from "react";
import { Row, Col, Table } from "antd";
const columns = [
    {
        title: 'Code',
        dataIndex: 'reasonCode',
        key: 'reasonCode'
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description'
    },
    {
        title: 'Suggested Correction',
        dataIndex: 'suggestedCorrection',
        key: 'suggestedCorrection'
    }
];
export default function AdverseReasonCodes({ data }) {
  const dataSource = data.leaseApplicationBlackboxRequest.leaseApplicationBlackboxAdverseReasons || []
  return data ? (
    <>
      <Row>
        <Col span={24}>
          <Table
            className='slc-table'
            rowKey="id"
            columns={columns}
            dataSource={dataSource}
            pagination={false} 
            size="small"
          />
        </Col>
      </Row>
    </>
  ) : null ;
}