import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Form, Input, Select, Button, InputNumber, message } from "antd";
import { GET } from '../../util/network';
import logger from '../../util/logger';
import _ from "lodash";

const { Option } = Select;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default ({ data, disableFlag, handleNoAuth, save }) => {
    const history = useHistory();

    const { colessee } = data || {};
    const lessee = colessee
    const [employerForm] = Form.useForm();
    const [secondEmployerForm] = Form.useForm();
    const [employmentDetailsForm] = Form.useForm();
    const [employerStateOptions, setEmployerStateOptions] = useState([])
    const [employmentStatusOptions, setEmploymentStatusOptions] = useState([])
    const [requireEmploymentFields, setRequireEmploymentFields] = useState(false)
    const [requireSecondEmploymentFields, setRequireSecondEmploymentFields] = useState(false)
    
    const getEmployerStates = async ( ) => {
      try {
        let result = await GET(`/api/v1/states`);
        setEmployerStateOptions(formatOptions({options: (result.data.states || []), type: 'collection'}))
      } catch (e) {
        logger.error("Request Error", e);
      }
    }
    useEffect(() => {
      getEmployerStates()
    }, []);


    const getEmployerStatus = async ( ) => {
      try {
        let result = await GET(`/api/v1/employment-status`);
        setEmploymentStatusOptions(formatOptions({options: (result.data.employment_status || []), type: 'collection'}))

        if (lessee && result.data && result.data.employment_status) {
          let currentStatus = _.find(result.data.employment_status, function(item) { return item[0] == colessee.employmentStatus; })
          if (currentStatus[1] == 0 || currentStatus[1] == 1) {
            setRequireEmploymentFields(true)
          }
        }

        if (lessee && result.data && result.data.employment_status) {
          let currentStatus = _.find(result.data.employment_status, function(item) { return item[0] == colessee.secondEmploymentStatus; })
          if (currentStatus[1] == 0 || currentStatus[1] == 1) {
            setRequireSecondEmploymentFields(true)
          }
        }
        
      } catch (e) {        
        logger.error("Request Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Employment status request error');
        }
      }
    }
    useEffect(() => {
      getEmployerStates()
      getEmployerStatus()
    }, []);


    const formatOptions = ({options, type}) => {
      let newOptions = []
      if (type == 'collection'){
        options.map((value) => { newOptions.push({ value: value[1], label: value[0]})})
      }else{
        options.map((value) => { newOptions.push({ value: value, label: value})})
      }
      return newOptions
    }

    const handleEmploymentStatus = (value) => {
      if (value === '0' || value === '1') {
        setRequireEmploymentFields(true)
      } else {
        setRequireEmploymentFields(false)
      }
    }

    const handleSecondEmploymentStatus = (value) => {
      if (value === '0' || value === '1') {
        setRequireSecondEmploymentFields(true)
      } else {
        setRequireSecondEmploymentFields(false)
      }
    }

    const handleSubmit = async (values) => {
      values = { ...values };
      save(values)
  }

    return data ? (
      lessee 
        ?   
          <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={6}>
                <Card className='h-100' title="Employer">
                  <Form 
                    form={employerForm} 
                    {...layout}  
                    colon={false}
                    initialValues={{ 
                      colesseeAttributes: {
                        id: lessee && lessee.id,
                        employerName: lessee && lessee.employerName,
                        employerPhoneNumber: lessee && lessee.employerPhoneNumber,
                        employmentAddressAttributes: {
                          id: lessee && (lessee.employmentAddress && lessee.employmentAddress.id),
                          city: lessee && (lessee.employmentAddress && lessee.employmentAddress.city),
                          state: lessee && (lessee.employmentAddress && lessee.employmentAddress.state),
                        }
                      }
                    }}
                    onFinish={handleSubmit}
                  >
                    { lessee && <Form.Item style={{display: 'none'}} name={['colesseeAttributes','id']} > <Input /> </Form.Item>}
                    { lessee && (lessee.employmentAddress && <Form.Item style={{display: 'none'}} name={['colesseeAttributes', 'employmentAddressAttributes','id']} > <Input /> </Form.Item>)}
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="Employer Name" 
                          name={['colesseeAttributes', 'employerName']}
                          hasFeedback
                          rules={[{ required: true, message: 'Employer Name is required!' }]}
                        >  
                          <Input disabled={disableFlag}/>
                        </Form.Item>
                      </Col> 
                    </Row>
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="Phone Number" 
                          name={['colesseeAttributes', 'employerPhoneNumber']}
                          hasFeedback
                          rules={[{ required: true, message: 'Employer Name is required!' }]}
                        >  
                          <Input disabled={disableFlag}/>
                        </Form.Item>
                      </Col> 
                    </Row>
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="City" 
                          name={['colesseeAttributes', 'employmentAddressAttributes', 'city']}
                          hasFeedback
                          rules={[{ required: true, message: 'City is required!' }]}
                        >  
                          <Input disabled={disableFlag}/>
                        </Form.Item>
                      </Col> 
                    </Row>
                    <Row>
                        <Col span={24}> 
                          <Form.Item 
                            label="State" 
                            name={['colesseeAttributes', 'employmentAddressAttributes','state']}
                            hasFeedback
                            rules={[{ required: true, message: 'State is required!' }]}
                          >  
                            <Select disabled={disableFlag}>
                              {
                                employerStateOptions && employerStateOptions.map(({value, label}, index) => {
                                  return <Option key={index} value={`${value}`}>{label}</Option>
                                })
                              }
                            </Select>
                          </Form.Item>
                        </Col> 
                      </Row>
                      <Row>
                        <Col span={24}> 
                          <Form.Item>
                            <Button type="primary" htmlType="submit" disabled={disableFlag}>
                              Save
                            </Button>
                          </Form.Item>
                        </Col> 
                      </Row>
                  </Form>
                </Card>
              </Col>

              <Col xs={24} sm={24} md={24} lg={12} xl={6}>
                <Card className='h-100' title="Employment Details">
                  <Form 
                    form={employmentDetailsForm} 
                    {...layout}  
                    colon={false}
                    initialValues={{ 
                      colesseeAttributes: {
                        id: lessee && lessee.id,
                        employmentStatus: lessee && lessee.employmentStatus,
                        job_title: lessee && lessee.jobTitle,
                        timeAtEmployerMonths: lessee && lessee.timeAtEmployerMonths,
                        timeAtEmployeYears: lessee && lessee.timeAtEmployerYears,
                        grossMonthlyIncome: lessee && lessee.grossMonthlyIncome,
                        secondEmploymentStatus: lessee?.secondEmploymentStatus,
                        secondEmploymentJobTitle: lessee?.secondEmploymentJobTitle,
                        secondEmploymentTimeAtEmployerMonths: lessee?.secondEmploymentTimeAtEmployerMonths,
                        secondEmploymentTimeAtEmployerYears: lessee?.secondEmploymentTimeAtEmployerYears,
                        secondEmploymentGrossMonthlyIncome: lessee?.secondEmploymentGrossMonthlyIncome,
                      }
                    }}
                    onFinish={handleSubmit}
                  >
                    { 
                      lessee && <Form.Item style={{display: 'none'}} name={['colesseeAttributes','id']} > <Input /> </Form.Item>
                    }
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="Employment Status"
                          name={['colesseeAttributes','employmentStatus']}
                          hasFeedback
                          rules={[{ required: true, message: 'Employment Status is required!' }]}
                        >  
                          <Select onChange={handleEmploymentStatus} disabled={disableFlag}>
                            {
                              employmentStatusOptions && employmentStatusOptions.map(({value, label}, index) => {
                                return <Option key={index} value={`${value}`}>{label}</Option>
                              })
                            }
                          </Select>
                        </Form.Item>
                      </Col> 
                    </Row>
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="Job Title" 
                          name={['colesseeAttributes', 'job_title']}
                          hasFeedback
                          rules={[{ required: requireEmploymentFields, message: 'Job Title is required!' }]}
                        >  
                          <Input disabled={disableFlag}/>
                        </Form.Item>
                      </Col> 
                    </Row>
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="Years Employed" 
                          name={['colesseeAttributes', 'timeAtEmployeYears']}
                          hasFeedback
                          rules={[{ required: requireEmploymentFields, message: 'Years Employed is required!' }]}
                        >  
                          <InputNumber disabled={disableFlag}/>
                        </Form.Item>
                      </Col> 
                    </Row>                    
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="Months Employed" 
                          name={['colesseeAttributes', 'timeAtEmployerMonths']}
                        >  
                          <InputNumber disabled={disableFlag}/>
                        </Form.Item>
                      </Col> 
                    </Row>
                    <Row>
                      <Col span={24}> 
                        <Form.Item 
                          label="Gross Monthly Income" 
                          name={['colesseeAttributes', 'grossMonthlyIncome']}
                          hasFeedback
                          rules={[{ required: requireEmploymentFields, message: 'Gross Monthly Income is required!' }]}
                        >  
                          <InputNumber disabled={disableFlag}/>
                        </Form.Item>
                      </Col> 
                    </Row>
                    
                    <Row>
                        <Col span={24}> 
                          <Form.Item>
                            <Button type="primary" htmlType="submit" disabled={disableFlag}>
                              Save
                            </Button>
                          </Form.Item>
                        </Col> 
                      </Row>
                  </Form>
                </Card>
              </Col>


          {
            (lessee?.secondEmploymentStatus == "Self employed" || lessee?.secondEmploymentStatus == "Employed") &&
            <Col xs={24} sm={24} md={24} lg={12} xl={6}>
              <Card className='h-100' title="Employer">
                <Form
                  form={secondEmployerForm}
                  {...layout}
                  colon={false}
                  initialValues={{
                    colesseeAttributes: {
                      id: lessee && lessee.id,
                      secondEmployerName: lessee && lessee.secondEmployerName,
                      secondEmployerPhoneNumber: lessee && lessee.secondEmployerPhoneNumber,
                      secondEmploymentAddressAttributes: {
                        id: lessee && (lessee.secondEmploymentAddress && lessee.secondEmploymentAddress.id),
                        city: lessee && (lessee.secondEmploymentAddress && lessee.secondEmploymentAddress.city),
                        state: lessee && (lessee.secondEmploymentAddress && lessee.secondEmploymentAddress.state),
                      }
                    }
                  }}
                  onFinish={handleSubmit}
                >
                  {lessee && <Form.Item style={{ display: 'none' }} name={['colesseeAttributes', 'id']} > <Input /> </Form.Item>}
                  {lessee && (lessee.secondEmploymentAddress && <Form.Item style={{ display: 'none' }} name={['colesseeAttributes', 'secondEmploymentAddressAttributes', 'id']} > <Input /> </Form.Item>)}
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Employer Name"
                        name={['colesseeAttributes', 'secondEmployerName']}
                        hasFeedback
                        rules={[{ required: true, message: 'Employer Name is required!' }]}
                      >
                        <Input disabled={disableFlag} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Phone Number"
                        name={['colesseeAttributes', 'secondEmployerPhoneNumber']}
                        hasFeedback
                        rules={[{ required: true, message: 'Employer Name is required!' }]}
                      >
                        <Input disabled={disableFlag} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="City"
                        name={['colesseeAttributes', 'secondEmploymentAddressAttributes', 'city']}
                        hasFeedback
                        rules={[{ required: true, message: 'City is required!' }]}
                      >
                        <Input disabled={disableFlag} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="State"
                        name={['colesseeAttributes', 'secondEmploymentAddressAttributes', 'state']}
                        hasFeedback
                        rules={[{ required: true, message: 'State is required!' }]}
                      >
                        <Select disabled={disableFlag}>
                          {
                            employerStateOptions && employerStateOptions.map(({ value, label }, index) => {
                              return <Option key={index} value={`${value}`}>{label}</Option>
                            })
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={disableFlag}>
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          }
              
              {
                lessee?.hasSecondEmployment && (<>

                  <Col xs={24} sm={24} md={24} lg={12} xl={6}>
                    <Card className='h-100' title="Second Employment Details">
                      <Form
                        form={employmentDetailsForm}
                        {...layout}
                        colon={false}
                        initialValues={{
                          colesseeAttributes: {
                            id: lessee && lessee.id,
                            employmentStatus: lessee && lessee.employmentStatus,
                            job_title: lessee && lessee.jobTitle,
                            timeAtEmployerMonths: lessee && lessee.timeAtEmployerMonths,
                            timeAtEmployeYears: lessee && lessee.timeAtEmployerYears,
                            grossMonthlyIncome: lessee && lessee.grossMonthlyIncome,
                            secondEmploymentStatus: lessee?.secondEmploymentStatus,
                            secondEmploymentJobTitle: lessee?.secondEmploymentJobTitle,
                            secondEmploymentTimeAtEmployerMonths: lessee?.secondEmploymentTimeAtEmployerMonths,
                            secondEmploymentTimeAtEmployerYears: lessee?.secondEmploymentTimeAtEmployerYears,
                            secondEmploymentGrossMonthlyIncome: lessee?.secondEmploymentGrossMonthlyIncome,
                          }
                        }}
                        onFinish={handleSubmit}
                      >

                        <Row>
                          <Col span={24}>
                            <Form.Item
                              label="Employment Status"
                              name={['colesseeAttributes', 'secondEmploymentStatus']}
                              hasFeedback
                              rules={[{ required: true, message: 'Employment Status is required!' }]}
                            >
                              <Select onChange={handleSecondEmploymentStatus} disabled={disableFlag}>
                                {
                                  employmentStatusOptions && employmentStatusOptions.map(({ value, label }, index) => {
                                    return <Option key={index} value={`${value}`}>{label}</Option>
                                  })
                                }
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              label="Job Title"
                              name={['colesseeAttributes', 'secondEmploymentJobTitle']}
                              hasFeedback
                              rules={[{ required: requireSecondEmploymentFields, message: 'Job Title is required!' }]}
                            >
                              <Input disabled={disableFlag} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              label="Years Employed"
                              name={['colesseeAttributes', 'secondEmploymentTimeAtEmployerYears']}
                              hasFeedback
                              rules={[{ required: requireSecondEmploymentFields, message: 'Years Employed is required!' }]}
                            >
                              <InputNumber disabled={disableFlag} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              label="Months Employed"
                              name={['colesseeAttributes', 'secondEmploymentTimeAtEmployerMonths']}
                            >
                              <InputNumber disabled={disableFlag} />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <Form.Item
                              label="Gross Monthly Income"
                              name={['colesseeAttributes', 'secondEmploymentGrossMonthlyIncome']}
                              hasFeedback
                              rules={[{ required: requireSecondEmploymentFields, message: 'Gross Monthly Income is required!' }]}
                            >
                              <InputNumber disabled={disableFlag} />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col span={24}>
                            <Form.Item>
                              <Button type="primary" htmlType="submit" disabled={disableFlag}>
                                Save
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Card>
                  </Col>
                </>)
              }
          </Row>
        :
           'N/A'     
    ) : null;
};
