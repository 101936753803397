import React from "react";
import Box from '@mui/material/Box';
import {Button, Col, DatePicker, Divider, Drawer, Form, Input, Row} from 'antd';
import moment from "moment";

export default function LesseeFilter(props) {
  const {Search} = Input;
  const {RangePicker} = DatePicker;
  const {setPageState, filter, setFilter, setSearchVal, state, setState, clearFilter} = props;
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setFilter(open)
  };

  const LesseeFilter = () => {
    if (state.filter === true) {
      setPageState({...state, search: ''});
      setSearchVal('');
      setFilter(false);
    }
  }

  const allFilters = () => (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            className="lease-doc-filter"
            label="Lessee Name"
          >
            <Search
              style={{padding: '2px', width: "300px"}}
              placeholder="Lessee Name"
              size="large"
              allowClear
              className="leaseAppListSearch"
              onChange={(e) => {
                setState({...state, name: e.target.value, filter: true})
              }}
              enterButton
              value={state.name}
            />
          </Form.Item>
        </Col>

        <Divider
          type="horizontal"
          style={{borderColor: "#d0d0cf", margin: '2px', padding: '2px'}}
        />

        <Col span={24}>
          <Form.Item
            label="Search using lessee date of birth"
          >
            <RangePicker
              placement="topLeft"
              onChange={(event, value) => {
                setState({...state, start_date: value[0], end_date: value[1], filter: true})
              }}
              value={[state.start_date !== "" ? moment(state.start_date) : null, state.end_date !== "" ? moment(state.end_date) : null]}
            />
          </Form.Item>
        </Col>

        <Divider
          type="horizontal"
          style={{borderColor: "#d0d0cf", margin: '2px', padding: '2px'}}
        />

        <Col span={24}>
          <Form.Item
            className="lease-doc-filter"
            label="Mobile Phone Number"
          >
            <Search
              style={{padding: '2px', width: "300px"}}
              placeholder="Mobile Phone Number"
              size="large"
              allowClear
              className="leaseAppListSearch"
              onChange={(e) => {
                setState({...state, mobile_number: e.target.value, filter: true})
              }}
              enterButton
              value={state.mobile_number}
            />
          </Form.Item>
        </Col>

        <Divider
          type="horizontal"
          style={{borderColor: "#d0d0cf", margin: '2px', padding: '2px'}}
        />

        <Col span={24}>
          <Form.Item
            className="lease-doc-filter"
            label="Home Phone Number"
          >
            <Search
              style={{padding: '2px', width: "300px"}}
              placeholder="Home Phone Number"
              size="large"
              allowClear
              className="leaseAppListSearch"
              onChange={(e) => {
                setState({...state, home_number: e.target.value, filter: true})
              }}
              enterButton
              value={state.home_number}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row align="middle" gutter={16}>
        <Col span={25}>
          <Form.Item
          >
            <Button
              style={{
                textAlign: "center",
                backgroundColor: "#1976d2",
                color: "#fff",
                fontSize: 16,
                fontWeight: "bold",
                borderRadius: "5px",
                marginRight: "5px",
                marginLeft: "20px",
                width: "100px"
              }}
              onClick={() => LesseeFilter()}
            >
              Filter
            </Button>
          </Form.Item>
        </Col>
        <Col span={25}>
          <Form.Item
          >
            <Button
              style={{
                textAlign: "center",
                backgroundColor: "#fff",
                color: "#000",
                fontSize: 16,
                fontWeight: "bold",
                borderRadius: '5px',
                border: "1px solid #000",
                width: "100px"
              }}
              onClick={() => clearFilter()}
            >
              Clear
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Box sx={{flexGrow: 1, pb: 0}}>
      <Drawer
        title="Filter"
        placement="right"
        getContainer={false}
        open={filter}
        onClose={toggleDrawer(false)}
        visible={filter}
      >
        {allFilters()}
      </Drawer>
    </Box>
  )
}
