import { Card } from "antd";
import React from "react";
import { styled } from '@mui/material'
import BikeInfo from "./CustomerBikeInfo/BikeInfo";
import CustomerInfo from "./CustomerBikeInfo/CustomerInfo";
import MaximumAdvances from "./CustomerBikeInfo/MaximumAdvances";

export const CustomerAndBikeInformation = (props) => {
    const { handleDealershipStateChange, activeStatesOptions, taxJurisdictionLabel, taxJurisdictionOptions, hideBikeSelectOptions,
            handleNewUsedStateChange, handleMakesStateChange, handleMakes, showMakeState, makesOptions, handleYear, handleYearStateChange,
            showYearState, yearsOptions, handleModelStateChange, showModelState, modelsOptions, handleMileageRangeStateChange, showMileageRangeState,
            mileageRangeOptions, showCreditTierState, creditTierOptions, data, maximumTermLength, calculatorData, convertUsd, backendProductsColor,
            GeorgiaTaxLink, taxLink, lesseeForm, creditTierDisableFlag, calculateWithoutApp, handleModel } = props;

    return (
        <StyledCard className="card-header" title="Customer and Bike Information" bordered={true} >
            <CustomerInfo
                handleDealershipStateChange={handleDealershipStateChange}
                activeStatesOptions={activeStatesOptions}
                taxJurisdictionLabel={taxJurisdictionLabel}
                taxJurisdictionOptions={taxJurisdictionOptions}
                GeorgiaTaxLink={GeorgiaTaxLink}
                taxLink={taxLink}
                lesseeForm={lesseeForm}
            />
           
           <div style={{ lineHeight: 2 }}><b>Motorcycle - {data?.leaseCalculator?.assetSource}</b></div>
            <BikeInfo
                handleNewUsedStateChange={handleNewUsedStateChange}
                hideBikeSelectOptions={hideBikeSelectOptions}
                handleMakesStateChange={handleMakesStateChange}
                handleMakes={handleMakes}
                showMakeState={showMakeState}
                makesOptions={makesOptions}
                handleYear={handleYear}
                handleYearStateChange={handleYearStateChange}
                showYearState={showYearState}
                yearsOptions={yearsOptions}
                handleModelStateChange={handleModelStateChange}
                showModelState={showModelState}
                modelsOptions={modelsOptions}
                handleMileageRangeStateChange={handleMileageRangeStateChange}
                showMileageRangeState={showMileageRangeState}
                mileageRangeOptions={mileageRangeOptions}
                showCreditTierState={showCreditTierState}
                creditTierOptions={creditTierOptions}
                data={data}
                maximumTermLength={maximumTermLength}
                creditTierDisableFlag={creditTierDisableFlag}
                calculateWithoutApp={calculateWithoutApp}
                handleModel={handleModel}
            />
          <MaximumAdvances
              calculatorData={calculatorData}
              convertUsd={convertUsd}
              backendProductsColor={backendProductsColor}
          />
        </StyledCard>
    )
}

const StyledCard = styled(Card)`
.ant-card-head-title {
  font-size: 20px;
}
`;
export default CustomerAndBikeInformation
