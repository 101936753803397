import React from "react";
import Chip from '@mui/material/Chip';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import BackspaceIcon from '@mui/icons-material/Backspace';
import ApprovalIcon from '@mui/icons-material/Approval';
import CancelIcon from '@mui/icons-material/Cancel';
import ArticleIcon from '@mui/icons-material/Article';
export default function CreditStatus(props) {
  const { params } = props;
  return (
    (params.row.CreditStatus == "Approved") && <Chip variant="outlined" color="success" icon={<CheckCircleOutlineIcon style={{ color: "green", fontSize: "14px" }} />} label="Approved" />
    || (params.row.CreditStatus == "Awaiting Credit Decision") && <Chip variant="outlined" color="secondary" icon={<HourglassBottomIcon style={{ color: "#9c27b0", fontSize: "14px" }} />} label="Awaiting Credit Decision" />
    || (params.row.CreditStatus == "Credit Review Stage 2") && <Chip variant="outlined" color="secondary" icon={<HourglassBottomIcon style={{ color: "#9c27b0", fontSize: "14px" }} />} label="Credit Review Stage 2" />
    || (params.row.CreditStatus == "Approved With Contingencies") && <Chip variant="outlined" sx={{ color: "green" }} icon={<ApprovalIcon style={{ color: "green", fontSize: "14px" }} />} label="Approved With Contingencies" />
    || (params.row.CreditStatus == "Unsubmitted") && <Chip variant="outlined" color="primary" icon={<CancelIcon style={{ color: "blue", fontSize: "14px" }} />} label="Saved -Not Submitted" />
    || (params.row.CreditStatus == "Requires Additional Information") && <Chip variant="outlined" sx={{ color: "#db6806" }} icon={<CheckCircleOutlineIcon style={{ color: "#db6806", fontSize: "14px" }} />} label="Required More Information" />
    || (params.row.CreditStatus == "Draft") && <Chip variant="outlined" color="primary" icon={<ArticleIcon style={{ color: "blue", fontSize: "14px" }} />} label="Draft" />
    || (params.row.CreditStatus == "Declined") && <Chip variant="outlined" color="error" icon={<ThumbDownAltIcon style={{ color: "red", fontSize: "14px" }} />} label="Declined" />
    || (params.row.CreditStatus == "Withdrawn") && <Chip variant="outlined" color="error" icon={<BackspaceIcon style={{ color: "red", fontSize: "14px" }} />} label="Withdrawn" />
    || <></>
  )
}