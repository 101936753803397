import React, { useState } from 'react';
import { Col, Layout, Menu, Result, Row, Input } from "antd";
import Icon from '@ant-design/icons';
import {useHistory, Link} from 'react-router-dom';
import {AddNew, MotorcycleDealership} from '../../components/layouts/Svg';
import MainBreadcrumb from '../../components/layouts/MainBreadcrumb';
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import StatusTag from './DealerShipStatus/StatusTag';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { updateDataGridFilterFlag, updateDataGridFilters, updatePageState, updateDataGridSort } from '../../reducers/Dealerships';
import {useDispatch, useSelector} from "react-redux";
import {DataGridPro, getGridStringOperators} from '@mui/x-data-grid-pro';
import {LicenseInfo} from '@mui/x-license-pro';
import {styled} from '@mui/system';
import Typography from '@mui/material/Typography';
import GetAppIcon from '@mui/icons-material/GetApp';
import DealershipActionButton from './components/DealershipActionButton';
import DialogModal from "../LeaseApplications/DialogModal";
import Grid from "@mui/material/Grid";
import DealershipStatusCard from "./DealershipStatusCard";
import moment from "moment/moment";
import {
  StyledAddIcon,
  StyledCheckBoxSharpIcon,
  StyledCheckCircleIcon,
  StyledDoneIcon,
  StyledDoDisturbIcon,
  StyledCreditScoreIcon,
  StyledPublishIcon,
  StyledCloseIcon
} from "./components/DealershipStatusCardIcons";

LicenseInfo.setLicenseKey('06c2f0cf50adbcfcc3f1fdcdf8402d39Tz00OTMwMCxFPTE2OTIzNzY1MTM3ODUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const {Content, Sider} = Layout;
const {Search} = Input;
const SiderProps = {
  breakpoint: "md",
  collapsedWidth: "0",
  width: 150,
  id: "slc-sider",
  style: {
    background: '#fff',
    position: 'sticky',
    overflow: 'auto',
    left: 0,
    bottom: 0,
    top: 0,
    height: 'auto',
  },
}

export default function Dealerships({data, ...props}) {
  const {
    allowedCreate,
    allowedGet,
    exportModal,
    handleExportModalOpen,
    handleExportModalClose,
    exportDealerships,
    signButtonVisibility,
    dataGridFilter
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  let Menus = [];
  const dealershipStatuses = useSelector((state) => state.Dealerships.dealershipStatuses);
  const pageState = useSelector((state) => state.Dealerships.pageState);
  const total = useSelector((state) => state.Dealerships.total);
  let newDataGridFilter = {...dataGridFilter};
  let prevDataGridFilter = {...dataGridFilter};
  const initialDataGridFilter = {
    dealership_name: "",
    dealership_compare_operator: "",
    // city_name: "",
    // city_compare_operator: "",
    status_column: "",
  }

  if (allowedGet) {
    Menus.push(
      {
        label: 'Dealerships',
        componentLabel: null,
        iconType: <Icon component={MotorcycleDealership}/>,
        isAllowedToShow: true,
        menuKey: 1,
        link: '#',
      }
    )
  }

  if (allowedCreate) {
    Menus.push(
      {
        label: "Add New",
        componentLabel: null,
        iconType: <Icon component={AddNew}/>,
        isAllowedToShow: true,
        menuKey: 2,
        link: 'dealerships/new',
      }
    )
  }

  const [activeMenu, setActiveMenu] = useState([1]);
  const [searchVal, setSearchVal] = useState(pageState.search);

  const activeMenuList = Menus.filter(menu => menu.isAllowedToShow);

  const onChange = (e) => {
    setSearchVal(e.target.value);
    if (e.target.value === '') {
      dispatch(updatePageState({...pageState, search: e.target.value}))
    }
  };

  const nameFilterOperators = getGridStringOperators().filter(({value}) =>
    ['equals', 'contains', 'startsWith', 'endsWith' /* add more over time */].includes(value),
  );

  const statusFilterOperators = getGridStringOperators().filter(({value}) =>
    ['equals'].includes(value),
  );

  const onFilterChange = React.useCallback((filterModel) => {
    newDataGridFilter = {...initialDataGridFilter}
    filterModel.items.map((item) => setFilterItems(item))
    if (JSON.stringify(newDataGridFilter) !== JSON.stringify(prevDataGridFilter)) {
      prevDataGridFilter = {...newDataGridFilter}
      dispatch(updateDataGridFilters({...newDataGridFilter}))
      dispatch(updateDataGridFilterFlag({}))
    }
  }, []);

  const setFilterItems = (item) => {
    if (item.columnField === "name") {
      if (item.value && item.value.length >= 3 && (item.value !== dataGridFilter.dealership_name || item.operatorValue !== dataGridFilter.dealership_compare_operator)) {
        newDataGridFilter = {
          ...newDataGridFilter,
          dealership_name: item.value,
          dealership_compare_operator: item.operatorValue
        }
      }
    }
    if (item.columnField === "status") {
      const documentStatuses = ['new', 'active', 'declined', 'credit committee approved', 'sales approved', 'underwriting approved']
      let value = item.value?.toLowerCase()?.replace(/_/g, " ")
      if (item.value && documentStatuses.includes(value) && item.value !== dataGridFilter.status_column) {
        newDataGridFilter = {...newDataGridFilter, status_column: value}
      }
    }
  }
  const handleSortModelChange = React.useCallback((sortModel) => {
    let sortCol = sortModel[0] ? sortModel[0] : { field: '', sort: '' };
    dispatch(updateDataGridSort({ ...sortCol }))
  }, [])

  const onSearch = () => {
    dispatch(updatePageState({...pageState, search: searchVal}))
  };

  const filterWithStatus = (status) => {
    if (pageState.status !== status) {
      dispatch(updatePageState({...pageState, status: status}))
    }
  };

  const fetchStatus = (row) => {
    let status;
    if (row.status === 'Active' && row.canSubmit === true) {
      status = 'Active Dealer  – Can Submit';
    } else if (row.status === 'Active') {
      status = 'Legacy Dealer – Can sign in';
    } else {
      status = row.status;
    }
    return status;
  };

  const clearStatus = () => {
    dispatch(updatePageState({...pageState, status: ''}))
  };

  const columns = [
    {
      field: "name",
      headerName: "Dealership Name",
      type: "string",
      flex: 1.2,
      filterOperators: nameFilterOperators,
      minWidth: 66,
      renderCell: (params) => {
        let path = "/dealerships/" + params.row.id;
        return <Link underline="none" to={path}> {params.row.name} </Link>
      }
    },
    {
      field: "cityName",
      headerName: "City",
      type: "string",
      flex: 1.2,
      filterable: false,
      sortable: false
    },
    {
      field: "stateAbbrev",
      headerName: "State",
      type: "string",
      flex: 1.2,
      filterable: false,
      minWidth: 70,
      sortable: false
    },
    {
      field: "primaryContactPhone",
      headerName: "Primary Contact Phone",
      type: "string",
      flex: 1.2,
      filterable: false,
      minWidth: 75,
      sortable: false
    },
    {
      field: "status",
      headerName: "Status",
      type: "string",
      flex: 2,
      filterOperators: statusFilterOperators,
      minWidth: 138,
      renderCell: (params) => {
        return (
          <StatusTag style={{whiteSpace: "break-spaces"}} name={fetchStatus(params.row)}/>
        );
      },
    },
    {
      field: "canSubmit",
      headerName: "Can Submit",
      type: "string",
      flex: 1.2,
      filterable: false,
      minWidth: 75,
      renderCell: (record) => {
        return record?.row?.canSubmit === true ? 'True' : 'False';
      },
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      type: 'action',
      flex: 2.5,
      filterable: false,
      sortable: false,
      minWidth: 140,
      renderCell: (params) => {
        return (
          <>
            <DealershipActionButton params={params} signButtonVisibility={signButtonVisibility}/>
          </>
        );
      }
    },
    {
      field: "submittedApplicationCount",
      headerName: "Submitted Count",
      type: "string",
      flex: 1,
      filterable: false,
      minWidth: 72,
      sortable: false
    },
    {
      field: "fundedApplicationCount",
      headerName: "Funded Count",
      type: "string",
      flex: 1,
      filterable: false,
      minWidth: 72,
      sortable: false
    },
    {
      field: 'updatedAt',
      headerName: 'Last Activity',
      type: 'string',
      flex: 1.2,
      filterable: false,
      minWidth: 72,
      renderCell: (record) => {
        return record?.row?.updatedAt === null ? "" : moment(record.row.updatedAt).format("MMMM d, YYYY HH:mm");
      },
    }
  ]

  return (
    <Layout>
      <MainHeader activePage="dealers"/>
      <Layout>
        <Sider {...SiderProps}>
          <Menu
            defaultSelectedKeys={['1']}
            onSelect={({key}) => {
              if (!key.includes("sub")) {
                setActiveMenu([...activeMenu, key]);
              }
            }}
          >
            {
              activeMenuList.map(({label, iconType, link, menuKey}) => {
                return (
                  <Menu.Item
                    key={(menuKey).toString()}
                  >
                    <a href={`${link}`}>
                      {iconType}
                      {label}
                    </a>
                  </Menu.Item>
                )
              })
            }
          </Menu>
        </Sider>

        <Layout id='content-area-layout'>
          <MainBreadcrumb items={
            [
              {text: "Home", link_type: "linkto", link: "/dashboard"},
              {text: "Dealerships"}
            ]}
          />

          <Content id='main-content'>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <>
                  {
                    allowedGet
                      ?
                      <>
                        <Grid container spacing={2}>
                          <Grid container spacing={2} style={{marginBottom: "20px"}}>
                            <Grid item md={9} sm={8}>
                              <Typography variant="h4">Dealerships</Typography>
                            </Grid>
                            <Grid justifyContent="flex-end" item md={3} sm={4} style={{display: 'flex'}}>
                              <Button style={{backgroundColor: "#e93b1b", color: 'white'}}
                                      onClick={() => history.push({pathname: `/call-logs`})}
                                      type="primary"
                              >
                                Call logs
                              </Button>
                            </Grid>
                            <Grid item md={12} sm={12}>
                              <Grid container>
                                <Grid item md={7} sm={4}>
                                  <Button variant="contained" onClick={() => handleExportModalOpen()}
                                          endIcon={<GetAppIcon/>}  style={{marginRight: "10px"}}>
                                    Export
                                  </Button>
                                </Grid>
                                <Grid justifyContent="flex-end" item md={2.5} sm={4} style={{display: 'flex'}}>
                                  {pageState.status !== '' &&
                                    <Button variant="outlined" color="error" onClick={() => clearStatus()} style={{}}>
                                      Clear status
                                    </Button>}
                                </Grid>
                                <Grid item md={2.5} sm={4} style={{display: 'flex', justifyContent: "flex-start"}}>
                                  <span style={{marginLeft: '20px'}}></span>
                                  <Search
                                    className="leaseAppListSearch"
                                    placeholder="Name or City or Phone"
                                    onChange={onChange}
                                    style={{marginTop: "10px", width: '99%'}}
                                    value={searchVal}
                                    onSearch={onSearch}
                                    allowClear
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} style={{marginBottom: "20px"}}>
                            <Grid item sm={1.5} onClick={() => filterWithStatus('New')}>
                              <DealershipStatusCard
                                title={"New"}
                                data={dealershipStatuses?.new || 0}
                                Icon={StyledAddIcon}
                                textColor={'#c41d7f'}
                                backgroundColor={'#fff0f6'}
                                isSelected={pageState.status === 'New'}
                              />
                            </Grid>
                            <Grid item sm={1.5} onClick={() => filterWithStatus('Sales Approved')}>
                              <DealershipStatusCard
                                title={"Sales  Approved"}
                                data={dealershipStatuses?.salesApproved || 0}
                                Icon={StyledCheckCircleIcon}
                                textColor={'#531dab'}
                                backgroundColor={'#f9f0ff'}
                                isSelected={pageState.status === 'Sales Approved'}
                              />
                            </Grid>
                            <Grid item sm={1.5} onClick={() => filterWithStatus('Underwriting Approved')}>
                              <DealershipStatusCard
                                title={"Underwriting"}
                                data={dealershipStatuses?.underwriting || 0}
                                Icon={StyledDoneIcon}
                                textColor={'#08979c'}
                                backgroundColor={'#e6fffb'}
                                isSelected={pageState.status === 'Underwriting Approved'}
                              />
                            </Grid>
                            <Grid item sm={1.5} onClick={() => filterWithStatus('Credit Committee Approved')}>
                              <DealershipStatusCard
                                title={"Credit  Committee"}
                                data={dealershipStatuses?.creditCommitteeApproved || 0}
                                Icon={StyledCreditScoreIcon}
                                textColor={'#FFFFFF'}
                                backgroundColor={'#87D068FF'}
                                isSelected={pageState.status === 'Credit Committee Approved'}
                              />
                            </Grid>
                            <Grid item sm={1.5} onClick={() => filterWithStatus('Legacy Dealer – Can sign in')}>
                              <DealershipStatusCard
                                title={"Legacy Dealer – Can sign in"}
                                data={dealershipStatuses?.active || 0}
                                Icon={StyledCheckBoxSharpIcon}
                                textColor={'#389e0d'}
                                backgroundColor={'#f6ffed'}
                                isSelected={pageState.status === 'Legacy Dealer – Can sign in'}
                              />
                            </Grid>
                            <Grid item sm={1.5} onClick={() => filterWithStatus('Active Dealer – Can submit')}>
                              <DealershipStatusCard
                                title={"Active Dealer – Can submit"}
                                data={dealershipStatuses?.activeAndCanSubmit || 0}
                                Icon={StyledPublishIcon}
                                textColor={'#FFFFFF'}
                                backgroundColor={'#16AD44'}
                                isSelected={pageState.status === 'Active Dealer – Can submit'}
                              />
                            </Grid>
                            <Grid item sm={1.5} onClick={() => filterWithStatus('Declined')}>
                              <DealershipStatusCard
                                title={"Declined"}
                                data={dealershipStatuses?.declined || 0}
                                Icon={StyledDoDisturbIcon}
                                textColor={'#FFFFFF'}
                                backgroundColor={'#FF5500FF'}
                                isSelected={pageState.status === 'Declined'}
                              />
                            </Grid>
                            <Grid item sm={1.5} onClick={() => filterWithStatus('Closed')}>
                              <DealershipStatusCard
                                title={"Closed"}
                                data={dealershipStatuses?.closed || 0}
                                Icon={StyledCloseIcon}
                                textColor={'#cf1322'}
                                backgroundColor={'#ffa39e'}
                                isSelected={pageState.status === 'Closed'}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <StyledBox>
                          {data && <StyledDataGridPro
                            disableColumnReorder={true}
                            disableSelectionOnClick={true}
                            getRowHeight={() => "auto"}
                            density="comfortable"
                            columns={columns}
                            rows={data}
                            rowsPerPageOptions={[25, 50, 100]}
                            pagination
                            rowCount={total ?? 0}
                            page={pageState.page <= 0 ? 0 : pageState.page}
                            pageSize={pageState.limit}
                            paginationMode="server"
                            filterMode='server'
                            onFilterModelChange={onFilterChange}
                            sortingMode="server"
                            // sortingOrder={['desc', 'asc']}
                            onSortModelChange={handleSortModelChange}
                            onPageChange={(newPage) => {
                              dispatch(updatePageState({ page: newPage }))
                            }}
                            onPageSizeChange={(newPageSize) => {
                              dispatch(updatePageState({ limit: newPageSize }))
                            }}
                          />
                          }
                        </StyledBox>
                        <div>
                          <DialogModal
                            modalState={exportModal}
                            modalClose={handleExportModalClose}
                            submitFunction={exportDealerships}
                            contentData={"This may take some time to download all dealership data into csv. Are you sure you want to continue?"}
                            title={"Export Dealerships?"}
                          />
                        </div>
                      </>
                      :
                      <Result
                        status='warning'
                        title='You are not allowed to access this page.'
                      />
                  }
                </>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
      <MainFooter/>
    </Layout>
  );
}

const StyledDataGridPro = styled(DataGridPro)({
  boxShadow: 'none !important',
  border: "none !important",
  '& .MuiDataGrid-cell': {
    padding: '10px',
  },
  '& .MuiDataGrid-row': {
    fontSize: 12
  },
  '& .wrapHeader .MuiDataGrid-colCellTitle': {
    overflow: "hidden",
    lineHeight: "20px",
    whiteSpace: "normal"
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: 'normal !important',
    lineHeight: 'normal',
    overflowWrap: "break-word",
    letterSpacing: '-0.5px'
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: "#e8ecf0",
    color: "#000000",
  },
  '& .MuiChip-label': {
    paddingLeft: '12px',
    paddingRight: '12px',
    whiteSpace: 'normal !important',
    wordWrap: ' break-word',
    lineHeight: ' 1',
    paddingBottom: '2px',
    paddingTop: '2px',
    letterSpacing: '-0.5px'
  }
})
const StyledBox = styled(Box)({
  height: 820,
  display: "flex",
  flexGrow: 1,
  width: '100%',
  '& .super-app-theme--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)'
  },
})
