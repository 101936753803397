import React, {useState} from "react";
import {Layout, Button} from "antd";
import '../../App.css';
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import Box from '@mui/material/Box';
import DocumentRequestsDataGrid from './components/DocumentRequestsDataGrid'
import {styled} from '@mui/system';
import Typography from "@material-ui/core/Typography";
import {useHistory} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import DocumentRequestFilter from './DocumentRequestFilter'

export default function DocumentRequestsList(props) {
  const {data, loading, initialState, pageState, setPageState, total} = props;
  const {Content} = Layout;
  const history = useHistory();

  const [filter, setFilter] = useState(false);
  const [state, setState] = useState({...pageState})

  const isClearButtonViewable = () => {
    return (pageState.filter === true)
  }

  const clearFilter = () => {
    setState({...initialState})
    if (pageState.filter === true) {
      setPageState({...initialState})
    }
  }

  const columns = [
    {
      field: 'id', headerName: 'Id', type: 'string', flex: 0.7, minWidth: 10, filterable: false
    },
    {
      field: 'applicationIdentifier',
      headerName: 'Lease Application Id',
      type: 'string',
      flex: 1,
      minWidth: 75,
      filterable: false,
      renderCell: (record) => {
        return record ?
          <a href={`/lease-applications/${record.row.leaseApplicationId}`}>{record.row.applicationIdentifier}</a> : "";
      },
    },
    {
      field: 'assetMake', headerName: 'Asset Make', type: 'string', flex: 1, minWidth: 75, filterable: false
    },
    {
      field: 'assetModel', headerName: 'Asset Model', type: 'string', flex: 1, minWidth: 50, filterable: false
    },
    {
      field: 'assetYear', headerName: 'Asset Year', type: 'string', flex: 0.7, minWidth: 50, filterable: false
    },
    {
      field: 'assetVin', headerName: 'Asset Vin', type: 'string', flex: 1, minWidth: 50, filterable: false
    },
    {
      field: 'assetColor', headerName: 'Asset Color', type: 'string', flex: 1, minWidth: 50, filterable: false
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      type: 'action',
      flex: 1,
      filterable: false,
      renderCell: (record) => {
        return record ? (
          <>
            <Button
              style={{textTransform: "none"}}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/lease_document_request/${record.row.id}`,
                });
              }}
            >
              View
            </Button>
            <Button
              style={{textTransform: "none", marginLeft: "1rem"}}
              variant="text"
              onClick={() => {
                history.push({
                  pathname: `/lease_document_request_edit/${record.row.id}`,
                });
              }}
            >
              Edit
            </Button>
          </>
        ) : (
          ""
        );
      },
    },
  ]

  return (
    <Layout>
      <MainHeader activePage="leaseApplicationRelated"/>
      <Layout>
        <Layout id='content-area-layout'>
          <Content id='main-content' style={{backgroundColor: "#ffff !important"}}>
            <Box sx={{flexGrow: 1, pb: 2}}>
              <Grid container columnSpacing={3} direction="row" justifyContent="center" alignItems="center">
                <Grid item md={9} sm={5}>
                  <Typography variant={"h4"}>Lease Document Requests</Typography>
                </Grid>
                <Grid item md={2} sm={4} style={{display: "flex", justifyContent: "right"}}>
                  {
                    isClearButtonViewable() &&
                    <Button onClick={() => clearFilter()}>
                      Clear Filter
                    </Button>
                  }
                </Grid>
                <Grid item md={1} sm={3}>
                  <Button style={{marginLeft: 'auto'}} onClick={() => setFilter(value => !value)}>
                    Filters
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <DocumentRequestFilter
              setPageState={setPageState}
              filter={filter}
              setFilter={setFilter}
              state={state}
              setState={setState}
              clearFilter={clearFilter}
            />
            <StyledBox>
              {
                data &&
                <DocumentRequestsDataGrid
                  columns={columns}
                  data={data}
                  loading={loading}
                  setPageState={setPageState}
                  pageState={pageState}
                  total={total}
                />
              }
            </StyledBox>
          </Content>
        </Layout>
      </Layout>
      <MainFooter/>
    </Layout>
  );
}

const StyledBox = styled(Box)({
  height: 820,
  display: "flex",
  flexGrow: 1,
  width: '100%',
  '& .super-app-theme--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)'
  },
})
