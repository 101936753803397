import React, { useState } from "react";
import { Layout } from "antd";
import { Link } from 'react-router-dom';
import '../../App.css';
import MainHeader from '../../components/layouts/MainHeader';
import MainFooter from '../../components/layouts/MainFooter';
import Box from '@mui/material/Box';
import { LicenseInfo } from '@mui/x-license-pro';
import Analytics from "./Analytics.js";
import SearchFilters from "./SearchFilters";
import DialogModal from "./DialogModal";
import CreditStatus from "./CreditStatus";
import DocumentStatus from "./DocumentStatus";
import ActionsButton from "./components/ActionsButton";
import TransferOwnershipDialogModal from "./TransferOwnershipDialogModal";
import SubmitAlertModel from "./SubmitAlertModel";
import AdminUsersDialogModal from "./AdminUsersDialogModal";
import ApplicationsDataGrid from "./components/ApplicationsDataGrid";
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import NotesIcon from '@mui/icons-material/Notes';
import NotesDialogModal from './NotesDialogModal'
import { message } from "antd";
import Badge from '@material-ui/core/Badge';
import { POST, PUT, DELETE } from '../../util/network'
import {
  clearUnseenNotesCountFromList,
  deleteNoteFromState,
  editNoteFromState,
  insertNote,
  insertNoteReply,
  deleteReplyFromNote,
  updateNoteVisibleToDealers
} from '../../reducers/LeaseApplications'
import Typography from '@mui/material/Typography'
import {getGridStringOperators} from "@mui/x-data-grid-pro";
LicenseInfo.setLicenseKey('06c2f0cf50adbcfcc3f1fdcdf8402d39Tz00OTMwMCxFPTE2OTIzNzY1MTM3ODUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export default function LeaseApplicationList(props) {
  const { Content } = Layout;
  const { data, isTimer, isNewAppTimer, sortModel, updateFilterSorting, slcRow, handleRefresh, setFilterFlag, isLoading, handleCloseSubmitAppModal, SubmitAppMessage, submitAppModal, submitToSpeedLeasing, exportLeaseApplication, handleExportModalOpen, exportModal, handleExportModalClose,
    authData, filterFlag, filteredData, filterTotal, applicationLockedBy, convertionPercentage, lookToBookPercentage, filterState, filterDefaultData, approvedPercentage, declinedPercentage, actionColors, setApplicationlockedBy, total, setSearchDate, submitted, totalSalePrice, dealerOptions, anchorEl, unlockDialog, pageState, funded, approved, declined, handleClickOpen, handleClose, unlockApplication, lockApplication, handleClose2, handleClick,
    handleTransferOwnershipClick, unlockTransferModalDialog, transferedAdmin, handleCloseTransferModal, transferApplication, adminUsers, unlockAdminUsersModalDialog, handleCloseAdminUsersModal, handleTransferOwnerModal, setAnchorEl } = props;
  const open = Boolean(anchorEl);
  const [unlockNotesDialog, setUnlockNotesDialog] = useState(false);
  const [notes, setNotes] = useState([])
  const [applicationRow, setApplicationRow] = useState();
  const dispatch = useDispatch()
  const leaseApplicationsData = useSelector((state) => state.leaseApplications.leaseApplicationLists.data);
  const [visibleToDealers, setVisibleToDealers] = useState(false)

  const handleNotesClose = () => {
    setUnlockNotesDialog(false)
  }

  const addNote = async (newNote) => {
    try {
      const response = await POST(`/api/v1/comments/x/lease-applications/${applicationRow?.id}`, { commit: newNote, visible_to_dealers: visibleToDealers })
      const addedNotes = [response.data, ...notes]
      setNotes(addedNotes)
      dispatch(insertNote({ id: applicationRow?.id, note: response.data }))
      message.success("Note saved successfully!")
    } catch (e) {
      message.error("Error while saving!")
    }
  }

  const editNote = async (editedNote, commentId) => {
    try {
      const response = await PUT(`/api/v1/comments/${commentId}/edit`, { commit: editedNote })
      const newNotes = [...notes]
      const index = notes.findIndex(note => note.id === commentId)
      const copy = { ...newNotes[index] }
      copy.body = response.data.body
      newNotes[index] = copy
      setNotes(newNotes)
      dispatch(editNoteFromState({ id: applicationRow?.id, noteId: commentId, body: editedNote }))
      message.success("Note updated successfully!")
    } catch (e) {
      message.error("Error while updating!")
    }
  }

  const deleteNote = async (commentId) => {
    try {
      await DELETE(`/api/v1/comments/${commentId}`)
      const newNotes = notes.filter(note => note.id !== commentId)
      setNotes(newNotes)
      dispatch(deleteNoteFromState({ id: applicationRow?.id, noteId: commentId }))
      message.success("Note deleted successfully!")
    } catch (e) {
      message.error("Error while deleting!")
    }
  }

  const visitNotesByCurrentUser = async (application) => {
    if (application.unseenNotesCount > 0) {
      try {
        await PUT(`/api/v1/comments/x/lease-applications/${application.id}/visit-notes-for-admins`, {})
        dispatch(clearUnseenNotesCountFromList({ id: application?.id }))
      } catch (e) {
        message.error("Error while visiting notes")
      }
    }
  }

  const addReply = async (comment, reply) => {
    try {
      let response = await POST(`/api/v1/comments/${comment.id}/reply`, { commit: reply })
      dispatch(insertNoteReply({ id: applicationRow?.id, commentId: comment.id, response: response.data }))
      message.success("Note reply added successfully!")
    } catch (e) {
      message.error("Error while saving note reply!")
    }
  }

  const deleteNoteReply = async (comment, reply) => {
    try {
      await DELETE(`/api/v1/comments/${comment.id}/reply/${reply.id}`)
      dispatch(deleteReplyFromNote({ id: applicationRow?.id, commentId: comment.id, replyId: reply.id }))
      message.success("Note Reply deleted successfully!")
    } catch (e) {
      message.error("Error while deleting note reply!")
    }
  }

  const handleVisibility = async (comment, value) => {
    try {
      await PUT(`/api/v1/comments/${comment.id}/toggle-visibility`);
      dispatch(updateNoteVisibleToDealers({ id: applicationRow?.id, commentId: comment.id, visibleToDealers: value }))
      message.success("Visibility updated successfully");
    } catch (e) {
      message.error('Error while updating visibility');
    }
  }


  const identifierMap = (params) => {
    let path = "/lease-applications/" + params.row.id;
    if(params.row.archived){
      return <div>
        <Link underline="none" to={path}  > { params.row.ApplicationIdentifier} </Link>
        <br/>
        <Typography variant={'body4'}>Archived by Dealer</Typography>
      </div>
    }
    else{
      return  <Link underline="none" to={path}  > { params.row.ApplicationIdentifier} </Link>
    }
  }

  const nameFilterOperators = getGridStringOperators().filter(({ value }) =>
    ['equals', 'contains', 'startsWith', 'endsWith' /* add more over time */ ].includes(value),
  );

  const statusFilterOperators = getGridStringOperators().filter(({ value }) =>
    ['equals'].includes(value),
  );

  // const numberFilterOperators = getGridNumericOperators().filter(
  //   (operator) => operator.value === '>' || operator.value === '<' || operator.value === '=' || operator.value === '>=' || operator.value === '<=',
  // )
  //
  // const dateFilterOperators =  getGridDateOperators().filter(
  //   (operator) => operator.value === 'is' || operator.value === 'onOrAfter' || operator.value === 'onOrBefore'
  //     || operator.value === 'before' || operator.value === 'after',
  // )

  const columns = [
    {
      field: 'ApplicationIdentifier', headerName: 'Application Identifier', type: 'string', flex: 1.5, filterable: false, minWidth: 75,
      renderCell: (params) => {
        return identifierMap(params)
      }
    },
    {
      field: 'Dealership', type: 'string', flex: 1.2, filterOperators: nameFilterOperators, minWidth: 66
    },
    { field: 'Lessee', type: 'string', flex: 1.6, filterOperators: nameFilterOperators },
    {
      field: 'Colessee', type: 'string', flex: 1.2, filterOperators: nameFilterOperators, minWidth: 70,
      renderCell: (params) => {
        return params.row.colesseeAddedAfterSubmission == false ? params.row.Colessee : ""
      },
    },
    { field: 'Asset', type: 'string', flex: 1.5, filterable: false, minWidth: 75 },
    { field: 'CreditTier', headerName: 'Credit Tier', type: 'string', flex: 1.5, filterable: false, minWidth: 75 },
    {
      field: 'CreditStatus', headerName: 'Credit Status', type: 'string', flex: 2.2, filterOperators: statusFilterOperators, minWidth: 138,
      renderCell: (params) => {
        return <CreditStatus params={params} />
      },
    },
    {
      field: 'DocumentStatus', headerName: 'Document Status', type: 'string', flex: 2.2, filterOperators: statusFilterOperators, minWidth: 142,
      renderCell: (params) => {
        return <DocumentStatus params={params} />
      }
    },
    { field: 'UpdatedAt', headerName: 'Updated At', type: 'string', flex: 1, filterable: false, minWidth: 72 },
    {
      field: 'ExpirationDate', headerName: 'Expiry Date', type: 'string', flex: 1.3, filterable: false, minWidth: 60,
      sortingOrder: ['desc', 'asc'],
      sortComparator: (v1, v2, param1, param2) => {
        return param1.id - param2.id;
      },
      renderCell: (params) => {
        if (params.row.ExpirationDate == "Expired") {
          return <span style={{ color: "#ff0000", fontWeight: 700, fontSize: 11, padding: "7px", borderRadius: "10px" }}> Expired</span>
        } else {
          return params.row.ExpirationDate
        }
      }
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      type: 'action',
      flex: 2.1,
      filterable: false,
      minWidth: 140,
      renderCell: (params) => {
        return <>
          <ActionsButton
            open={open}
            params={params}
            slcRow={slcRow}
            handleClose2={handleClose2}
            anchorEl={anchorEl}
            actionColors={actionColors}
            setApplicationlockedBy={setApplicationlockedBy}
            applicationLockedBy={applicationLockedBy}
            handleClick={handleClick}
            handleClickOpen={handleClickOpen}
            lockApplication={lockApplication}
            handleTransferOwnershipClick={handleTransferOwnershipClick}
            setAnchorEl={setAnchorEl}
            submitToSpeedLeasing={submitToSpeedLeasing}
          />
        </>
      }
    },
    {
      field: 'notes', headerName: 'Notes', type: 'string', flex: 1.3, filterable: false, minWidth: 60,
      renderCell: params => {
        return (
          <IconButton onClick={() => {
            setUnlockNotesDialog(true)
            setApplicationRow(params.row)
            setNotes(params.row.comments)
            visitNotesByCurrentUser(params.row)
          }}>
            <Badge badgeContent={params.row.unseenNotesCount} color="error">
              <NotesIcon style={{ color: "blue", fontSize: "30px" }}></NotesIcon>
            </Badge>
          </IconButton>
        )
      }
    },
  ]

  return (
    <Layout>
      <MainHeader activePage="leaseApplication" />
      <Layout>
        <Layout id='content-area-layout'>
          <Content id='main-content' style={{ backgroundColor: "#ffff !important" }}>
            <Analytics
              submitted={submitted}
              approved={approved}
              declined={declined}
              total={total}
              approvedPercentage={approvedPercentage}
              declinedPercentage={declinedPercentage}
              lookToBookPercentage={lookToBookPercentage}
              convertionPercentage={convertionPercentage}
              totalSalePrice={totalSalePrice}
              funded={funded}
              filterDefaultData={filterDefaultData}
              filterState={filterState}
              pageState={pageState}
              setFilterFlag={setFilterFlag}
              filterFlag={filterFlag}
              authData={authData}
              sortModel={sortModel}
              updateFilterSorting={updateFilterSorting}
              handleExportModalOpen={handleExportModalOpen}
              handleRefresh= {handleRefresh}
            />
            <SearchFilters
              pageState={pageState}
              dealerOptions={dealerOptions}
              setSearchDate={setSearchDate}
              filterFlag={filterFlag}
              setFilterFlag={setFilterFlag}

            />
            <StyledBox>
              {
                (data || filteredData) &&
                <ApplicationsDataGrid
                  columns={columns}
                  filteredData={filteredData}
                  filterTotal={filterTotal}
                  data={data}
                  loading={isLoading}
                  isTimer={isTimer}
                  isNewAppTimer={isNewAppTimer}
                  total={total}
                  pageState={pageState}
                  filterState={filterState}
                  setFilterFlag={setFilterFlag}
                  sortModel={sortModel}
                  updateFilterSorting={updateFilterSorting}
                  filterFlag={filterFlag} />
              }
            </StyledBox>
            <div>
              <DialogModal
                modalState={unlockDialog}
                modalClose={handleClose}
                submitFunction={unlockApplication}
                contentData={" This will unlock the application from " + applicationLockedBy.lockedByUser + ". Are you sure you want to continue?"}
                title={"Unlock Lease Application?"}
              />
              <AdminUsersDialogModal
                adminUsers={adminUsers}
                unlockDialog={unlockAdminUsersModalDialog}
                handleClose={handleCloseAdminUsersModal}
                slcRow={slcRow}
                handleTransferOwnerModal={handleTransferOwnerModal}

              >
              </AdminUsersDialogModal>
              <TransferOwnershipDialogModal
                transferApplication={transferApplication}
                unlockDialog={unlockTransferModalDialog}
                handleClose={handleCloseTransferModal}
                transferedAdmin={transferedAdmin}
              />
              <DialogModal
                modalState={exportModal}
                modalClose={handleExportModalClose}
                submitFunction={exportLeaseApplication}
                contentData={" This will send a CSV export of Lease Applications to email:  " + authData.email + ". Are you sure you want to continue?"}
                title={"Export Lease Application?"}
              />
              <SubmitAlertModel
                modalState={submitAppModal}
                modalClose={handleCloseSubmitAppModal}
                contentData={SubmitAppMessage}
              />
              <NotesDialogModal
                unlockDialog={unlockNotesDialog}
                handleClose={handleNotesClose}
                notesData={notes}
                data={leaseApplicationsData}
                paramsRow={applicationRow}
                addNote={addNote}
                saveEditedNote={editNote}
                deleteNote={deleteNote}
                addReply={addReply}
                deleteNoteReply={deleteNoteReply}
                setVisibleToDealers={setVisibleToDealers}
                handleVisibility={handleVisibility}
              />
            </div>
          </Content>
        </Layout>
      </Layout>
      <MainFooter />
    </Layout >
  );
}

const StyledBox = styled(Box)({
  height: 820,
  display: "flex",
  flexGrow: 1,
  width: '100%',
  '& .super-app-theme--header': {
    backgroundColor: 'rgba(255, 7, 0, 0.55)'
  },
})

