import React, { useState } from "react";
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material'
import { Table, TableBody, TableContainer, TableRow } from '@mui/material'
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CalculateIcon from '@mui/icons-material/Calculate';
import Tooltip from '@mui/material/Tooltip';
import { PUT } from '../../../util/network'
import { message, Spin } from "antd";
import DialogModal from '../../LeaseApplications/DialogModal'
import { useHistory } from "react-router-dom";
import MainBreadcrumb from "../../../components/layouts/MainBreadcrumb";

export default function ActiveModelYear(props) {
  const { activeModelYear, activeModelYearId } = props;
  const [loading, setLoading] = useState(false)
  const [recalculateModal, setRecalculateModal] = useState(false);
  const history = useHistory();

  const handleRecalculateModalOpen = () => {
    setRecalculateModal(true)
  }
  const handleRecalculateModalClose = () => {
    setRecalculateModal(false)
  }

  const activeStatus = () => {
    return activeModelYear.id !== activeModelYearId ? 'Inactive' : 'Active'
  }

  const recalculateResidual = async () => {
    handleRecalculateModalClose()
    setLoading(true)
    try {
      const response = await PUT(`/api/v1/model_years/${activeModelYear.id}/recalculate-residual`);
      message.success(response.data.message)
    } catch (e) {
      message.error("Error while recalculating residuals")
    }
    setLoading(false)
  }

  return (
    <Spin spinning={loading}>
      <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center">
        <Grid item md={6} sm={6}>
          <MainBreadcrumb
              items={[
                { text: "Home", link_type: "linkto", link: "/dashboard" },
                { text: "New Assets", link_type: "ahref", link: void 0 },
                {
                  text: "Model Years",
                  link_type: "ahref",
                  link: `/new-assets/model-years/list`,
                },
                { text: `${activeModelYear?.name || ''}`, link_type: "ahref", link: void 0 },
              ]}
            />
        </Grid>
        <Grid item md={6} sm={6} container spacing={1} direction="row" style={{textAlign: "right"}}>
          <Grid item md={4} sm={4}> </Grid>
          <Grid item md={4} sm={4}> </Grid>
          <Grid item md={4} sm>
            <Tooltip title={<h5>Edit Model Year</h5>}>
              <IconButton onClick={() => { history.push(
                "/new-assets/model-years/edit/" + activeModelYear?.id
              ); }}>
                <EditIcon style={{ color: "#e93b1b", fontSize: "30px" }}/>
              </IconButton>
            </Tooltip>
            <Tooltip title={<h5>Recalculate Residual</h5>}>
              <IconButton onClick={() => {handleRecalculateModalOpen()}}>
                <CalculateIcon style={{ color: "#e93b1b", fontSize: "30px" }}/>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1, pb: 2, marginTop: 2, marginBottom: 2 }}>
        {/*<Paper elevation={12}>*/}
          <Grid container direction="row">
            <Grid item md={12} container spacing={2} >
              <Grid item md={9} sm={9} lg={3} >
                <TableContainer component={Box}>
                  <Table aria-label="customized table" sx={{ height: "300px", tableLayout: 'fixed' }}>
                    <TableBody>
                      <StyledTableRow >
                        <StyledtableCell1>ACTIVE STATUS:</StyledtableCell1>
                        <StyledtableCell2 >{activeStatus()}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow >
                        <StyledtableCell1>NAME:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.name}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                                <StyledtableCell1>MAKE:</StyledtableCell1>
                                <StyledtableCell2>
                                  <a
                                    onClick={() => {
                                      history.push(
                                        "/new-assets/makes/details/" +
                                          activeModelYear.makeId
                                      );
                                    }}
                                  >
                                    {activeModelYear.make}
                                  </a>
                                </StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow >
                          <StyledtableCell1>MODEL GROUP:</StyledtableCell1>
                          <StyledtableCell2>
                                  <a
                                    onClick={() => {
                                      history.push(
                                        "/new-assets/model-groups/details/" +
                                          activeModelYear.modelGroupId
                                      );
                                    }}
                                  >
                                    {activeModelYear.modelGroup}
                                  </a>
                                </StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow >
                        <StyledtableCell1>JD MODEL GROUP:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.jdModelGroup}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow >
                        <StyledtableCell1>YEAR:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.year}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow >
                        <StyledtableCell1>MODEL NUMBER:</StyledtableCell1>
                        <StyledtableCell2 >{activeModelYear.nadaModelNumber}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>VOLUME NUMBER:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.nadaVolumeNumber}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow >
                        <StyledtableCell1>POLICE BIKE:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.policeBike}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow >
                        <StyledtableCell1>SLC MODEL GROUP MAPPING FLAG:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.slcModelGroupMappingFlag}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow >
                        <StyledtableCell1>MAXIMUM TERM LENGTH:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumTermLength}</StyledtableCell2>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item md={9} sm={9} lg={3}>
                <TableContainer component={Box}>
                  <Table aria-label="customized table" sx={{ height: "300px", tableLayout: 'fixed' }}>
                    <TableBody>
                      <StyledTableRow >
                        <StyledtableCell1>ORIGINAL MSRP:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.originalMsrp}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow >
                        <StyledtableCell1>AVG RETAIL:</StyledtableCell1>
                        <StyledtableCell2 >{activeModelYear.nadaAvgRetail}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow >
                        <StyledtableCell1>ROUGH VALUE:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.nadaRough}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow >
                        <StyledtableCell1>RESIDUAL 24:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.residual24}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow >
                        <StyledtableCell1>RESIDUAL 36:</StyledtableCell1>
                        <StyledtableCell2 >{activeModelYear.residual36}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>RESIDUAL 48:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.residual48}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow >
                        <StyledtableCell1>RESIDUAL 60:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.residual60}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>CREATED AT:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.createdAt}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>UPDATED AT:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.updatedAt}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>START DATE:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.startDate}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>END DATE:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.endDate}</StyledtableCell2>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item md={9} sm={9} lg={3}>
                <TableContainer component={Box}>
                  <Table aria-label="customized table" sx={{ height: "300px", tableLayout: 'fixed' }}>
                    <TableBody>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 0:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut0}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 1:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut1}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 2:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut2}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 3:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut3}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 4:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut4}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 5:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut5}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 6:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut6}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 7:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut7}</StyledtableCell2>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item md={9} sm={9} lg={3}>
                <TableContainer component={Box}>
                  <Table aria-label="customized table" sx={{ height: "300px", tableLayout: 'fixed' }}>
                    <TableBody>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 8:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut8}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 9:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut9}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 10:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut10}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 11:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut11}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 12:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut12}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 13:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut13}</StyledtableCell2>
                      </StyledTableRow>
                      <StyledTableRow>
                        <StyledtableCell1>MAXIMUM HAIRCUT 14:</StyledtableCell1>
                        <StyledtableCell2>{activeModelYear.maximumHaircut14}</StyledtableCell2>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        {/*</Paper>*/}
      </Box>
      <div>
        <DialogModal
          modalState={recalculateModal}
          modalClose={handleRecalculateModalClose}
          submitFunction={recalculateResidual}
          contentData={"Are you sure? This action can cause monthly payments to be recalculated for this lease.."}
          title={"Recalculate Residuals?"}
        />
      </div>
    </Spin>
  )
}

const StyledtableCell1 = styled(TableCell)({
  fontWeight: "bold",
  fontSize: "12px",
})

const StyledTableRow = styled(TableRow)({
  "& td": { border: 0, padding: 8 },
})

const StyledtableCell2 = styled(TableCell)({
  fontSize: "12px"
})