import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { message, Form, Row , Col, Input, Checkbox, Button, Spin} from "antd";
import { PUT } from '../../../util/network';
import logger from '../../../util/logger';
import './AttachmentQuickEdits.css';
import { updateAttachments } from '../../../reducers/LeaseApplications'
import { useDispatch } from "react-redux";

const layout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

export default function AttachmentQuickEdit({ setExpandedRowKeys, record, data, handleNoAuth }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const {attachmentCategoryOptions, incomeVerificationRecordOptions} = data
  const [loading, setLoading] = useState(false);
  const [isIncomeVerificationChecked, setIsIncomeVerificationChecked] = useState(record.is_income_verification_selected || false);
  const updateAttachment = async (values) => {
    setLoading(true);
    try {
      const response = await PUT(`/api/v1/lease_application_attachments/${record.id}`, values);
      dispatch(updateAttachments(response?.data?.data))
      setExpandedRowKeys([]);
      message.success("Attachment saved successfully"); 
    } catch (e) {
      logger.error("Update Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while saving your data');
      }
    }
    setLoading(false);
  };



  const handleAttachmentCategory = (checkedValue) => {
    if (checkedValue.includes(record.income_verification_file_type_id)) {
      setIsIncomeVerificationChecked(true)
    }else{
      setIsIncomeVerificationChecked(false)
    }
  }

  const handleSubmit = async (values) => {
    values = { ...values };
    updateAttachment(values)
}

    return (
        <Spin spinning={loading}>
            <Form
            {...layout}
            onFinish={handleSubmit}
            initialValues={{ 
              description: record.description, 
              fileAttachmentTypeIds: record.file_attachment_type_ids,
              incomeVerificationAttachmentIds: record.income_verification_attachment_ids
            }}
            > 
              <Col span={24}>
                <Row gutter={[16, 16]} style={{ justifyContent: 'start' }}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={10}>
                    <Row style={{ justifyContent: 'start' }}>
                      <Col span={24}>
                        <Form.Item 
                          label="Description" 
                          name="description"
                          hasFeedback
                        >  
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: 'start' }}>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          Update
                        </Button>
                      </Form.Item>
                    </Row>
                  </Col> 
                  <Col xs={24} sm={24} md={24} lg={10} xl={6}> 
                    <Form.Item 
                      label="Categories" 
                      name="fileAttachmentTypeIds"
                      className="attachements-quick-edits"
                    >  
                          <Checkbox.Group
                            onChange={handleAttachmentCategory}
                            options={attachmentCategoryOptions}
                          />
                    </Form.Item>
                  </Col> 

                  {
                    ((incomeVerificationRecordOptions.length > 0) && (isIncomeVerificationChecked)) && 
                    <Col xs={24} sm={24} md={24} lg={10} xl={8}>
                      <Form.Item 
                        label="Income Verification Records" 
                        className="attachements-quick-edits"
                        name="incomeVerificationAttachmentIds"

                      >  
                          <Checkbox.Group
                            options={incomeVerificationRecordOptions}
                          />
                    </Form.Item>
                  </Col> 
                  }     
                </Row>
              </Col>
            </Form>
        
        </Spin>
    )
}