import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, message, Button, Spin, Radio, Select, Input} from 'antd';
import { PUT, GET } from '../../util/network'
import logger from '../../util/logger';

const { Option } = Select;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};


export default function Lms(props) {
  const { allowedGet, allowedUpdate, handleNoAuth } = props;
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [lmsForm] = Form.useForm();
  const [data, setData] = useState(false);

  const getLms = async () => {
    setLoading(true)
    try {
      let result = await GET(`/api/v1/lease-management-systems/get-details`);
      setData(result.data.lms);
    } catch (e) {
      logger.error("Request Error", e);

      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Request Error');
      } 
    }
    setLoading(false)
  }

  const save = async (values) => {
    setLoading(true);
    try {
        let id = lmsForm.getFieldValue('id')
        await PUT(`/api/v1/lease-management-systems/${id}`, values);
        message.success("Data saved successfully");
    } catch (e) {
        logger.error("Lease Management System Error", e);

        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while saving your data');
        }
    }
    setLoading(false);      
  };


  const handleSubmit = async (values) => {
    if (allowedUpdate) {
      values = { ...values };
      save(values);
    }
  }  

  useEffect(() => {
    getLms()
  }, []);


  return data && allowedGet ? (
    <Spin 
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      <Form 
        {...layout}  
        form={lmsForm}
        colon={false}
        scrollToFirstError={true}
        initialValues={{
            id: data && data.id,
            apiDestination: data && data.apiDestination,
            sendLeasesToLms: data && data.sendLeasesToLms,
            leaseManagementSystemDocumentStatusId: data && data.leaseManagementSystemDocumentStatusId  && data.leaseManagementSystemDocumentStatusId.toString()
        }}
        onFinish={handleSubmit}
      >
        <Row>
          <Col xs={24} sm={24} md={24} lg={8} xl={6}>
            <Row className='flex-jc-fs'>
              <Form.Item 
              name="sendLeasesToLms"
              label="Send Leases to LMS?"
              hasFeedback
              >
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group> 
              </Form.Item>
            </Row>
          </Col> 
          <Col xs={24} sm={24} md={24} lg={16} xl={18}>
            <Row className='flex-jc-fs'>                      
              <Form.Item 
                  label="Send to LMS at This Document Status" 
                  style={{ width: '25rem', maxWidth: '90%' }}
                  hasFeedback
                  name="leaseManagementSystemDocumentStatusId"
                  rules={[{ required: true, message: 'Document Status is required!' }]}
              >  
                <Select>
                  {
                  data && data.documentStatusOptions && data.documentStatusOptions.map(({value, label}, index) => {
                      return <Option key={index} value={`${value}`}>{label}</Option>
                  })
                  }
                </Select>
              </Form.Item>                      
            </Row>
            <Row className='flex-jc-fs'>                      
              <Form.Item 
                label="API Destination" 
                name="apiDestination"
                style={{ width: '25rem', maxWidth: '90%' }}
                hasFeedback
                rules={[{ required: true, message: 'API Destination is required!' }]}
              >  
                  <Input />
              </Form.Item>                      
            </Row>
          </Col>
        </Row>
        { allowedUpdate &&
          <Row className='flex-jc-fs'>
              <Col xs={24} sm={24} md={24} lg={8} xl={6}>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                      Save
                  </Button>
                </Form.Item>
              </Col> 
          </Row>
        }
      </Form>
    </Spin>
  ) : null ;
}