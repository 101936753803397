import React from 'react'
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { styled } from '@mui/system';
import { updateFilterState} from '../../../../reducers/LeaseApplications'
import { useDispatch } from "react-redux";

export default function DateGroup(props) {
    const { filterState, newFilterState, setNewFilterState } = props;

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <ListItem spacing={2} >
                    <StyledDatePicker
                        clearable
                        label="Application  From"
                        inputFormat="MM/dd/yyyy"
                        value={newFilterState.applicationDateFrom}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(value) =>
                          setNewFilterState({ ...newFilterState, applicationDateFrom: value })
                        }
                        showDaysOutsideCurrentMonth
                    />
                    <StyledDatePicker
                        label="Application  To"
                        inputFormat="MM/dd/yyyy"
                        value={newFilterState.applicationDateTo}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(value) =>
                          setNewFilterState({ ...newFilterState, applicationDateTo: value })
                        }
                        showDaysOutsideCurrentMonth
                    />
                </ListItem>
                <ListItem >
                    <StyledDatePicker
                        label="Funding Delay From"
                        inputFormat="MM/dd/yyyy"
                        value={newFilterState.fundingDelayFrom}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(value) =>
                          setNewFilterState({ ...newFilterState, fundingDelayFrom: value })
                        }
                        showDaysOutsideCurrentMonth
                    />
                    <StyledDatePicker
                        label="Funding Delay To"
                        inputFormat="MM/dd/yyyy"
                        value={newFilterState.fundingDelayTo}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(value) =>
                          setNewFilterState({ ...newFilterState, fundingDelayTo: value })
                        }
                        showDaysOutsideCurrentMonth
                    />
                </ListItem>
                <ListItem >
                    <StyledDatePicker
                        label="Funding Approved From"
                        inputFormat="MM/dd/yyyy"
                        value={newFilterState.fundingApprovedFrom}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(value) =>
                          setNewFilterState({ ...newFilterState, fundingApprovedFrom: value })
                        }
                        showDaysOutsideCurrentMonth
                    />
                    <StyledDatePicker
                        label="Funding Approved To"
                        inputFormat="MM/dd/yyyy"
                        value={newFilterState.fundingApprovedTo}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(value) =>
                          setNewFilterState({ ...newFilterState, fundingApprovedTo: value })
                        }
                        showDaysOutsideCurrentMonth
                    />
                </ListItem>
                <ListItem >
                    <StyledDatePicker
                        label="Funded From"
                        inputFormat="MM/dd/yyyy"
                        value={newFilterState.fundedDateFrom}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(value) =>
                          setNewFilterState({ ...newFilterState, fundedDateFrom: value })
                        }
                        showDaysOutsideCurrentMonth
                    />

                    <StyledDatePicker
                        label="Funded  To"
                        inputFormat="MM/dd/yyyy"
                        value={newFilterState.fundedDateTo}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(value) =>
                          setNewFilterState({ ...newFilterState, fundedDateTo: value })
                        }
                        showDaysOutsideCurrentMonth
                    />
                </ListItem>
                <ListItem >
                    <StyledDatePicker
                        label="Documents  From"
                        inputFormat="MM/dd/yyyy"
                        value={newFilterState.documentDateFrom}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(value) =>
                          setNewFilterState({ ...newFilterState, documentDateFrom: value })
                        }
                        showDaysOutsideCurrentMonth
                    />
                    <StyledDatePicker
                        label="Documents  To"
                        inputFormat="MM/dd/yyyy"
                        value={newFilterState.documentDateTo}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(value) =>
                          setNewFilterState({ ...newFilterState, documentDateTo: value })
                        }
                        showDaysOutsideCurrentMonth
                    />
                </ListItem>
                <ListItem >
                    <StyledDatePicker
                        label="Documents Received From"
                        inputFormat="MM/dd/yyyy"
                        value={newFilterState.documentReceivedFrom}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(value) =>
                          setNewFilterState({ ...newFilterState, documentReceivedFrom: value })
                        }
                        showDaysOutsideCurrentMonth
                    />

                    <StyledDatePicker
                        label="Documents Received To"
                        inputFormat="MM/dd/yyyy"
                        value={newFilterState.documentReceivedTo}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(value) =>
                          setNewFilterState({ ...newFilterState, documentReceivedTo: value })
                        }
                        showDaysOutsideCurrentMonth
                    />
                </ListItem>
            </LocalizationProvider>
        </>
    )
}

const StyledDatePicker = styled(DesktopDatePicker)({
    "& .MuiInputBase-root ": {
        margin: '5px !important',
    },
    "& .MuiFormLabel-root": {
        letterSpacing: "-0.5px"
    }
})
