import React from "react";
import MaskedInput from 'antd-mask-input'
import { Row, Col, Form } from 'antd';

export default function CustomInputMask({mask, label, required, ruleMessage, name, validateStatus, help, onPressEnter, onBlur, type}) {
    return (
        <Row>
            <Col span={24}> 
                <Form.Item 
                    label={label} 
                    hasFeedback
                    name={name}
                    rules={[{ required: required, message: ruleMessage }]}
                    validateStatus={validateStatus}
                    help={help}
                >  
                    <MaskedInput mask={mask} placeholder={label} onPressEnter={onPressEnter} onBlur={onBlur} type={type} />
                </Form.Item>
            </Col>
        </Row> 
    );
}