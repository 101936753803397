import React from 'react'
import { Row, Col, Typography } from 'antd';
import moment from 'moment';
const { Text } = Typography;

export default function HeaderInfo(props) {

    const { data, leaseApplication, formatDate } = props
    return (
        <>
            <Row gutter={[8, 32]} >
                <Col span={6}>
                    <Text strong level={4}  >Date</Text>
                    <Row>
                        <Text level={5} className="vcc-info">{formatDate(data.createdAt) || moment().format("MMMM DD, YYYY")}</Text>
                    </Row>
                </Col>
                <Col span={6}>
                    <Text strong  level={4}>Dealer</Text>
                    <Row>
                        <Text level={5} className="vcc-info">{leaseApplication.dealership ? leaseApplication.dealership.dealership : null}</Text>
                    </Row>
                </Col>
                <Col span={6}>
                    <Text strong level={4} >Lessee</Text>
                    <Row>
                        <Text level={5} className="vcc-info">{leaseApplication.lessee ? leaseApplication.lessee.fullName : null}</Text>
                    </Row>
                </Col>
                <Col span={6}>
                    <Text strong  level={4} >Lease Number</Text>
                    <Row>
                        <Text level={5} className="vcc-info">{leaseApplication.applicationIdentifier || leaseApplication.id}</Text>
                    </Row>
                </Col>
            </Row>
        </>
    )
}
