import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, DatePicker, message, InputNumber, Select } from 'antd';
import { useDispatch } from "react-redux";
import { POST } from '../../../util/network';
import logger from '../../../util/logger';
import {getDetails} from '../../../reducers/LeaseApplications'

export default function YtdForm({ data, handleNoAuth, lessee, layout, ...props }) {
  
  const { allowedUpdate } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const authData =  JSON.parse(window.localStorage.getItem('user_data')) || null;
  let loggedInUserId = null;
  if(authData !== null) {
    loggedInUserId = authData.id;
  }
  const incomeVerificationTypeOptions = data.incomeVerificationTypeOptions || [];

  const { Option } = Select;
  const locked_by =  data.lockedStatus && data.lockedStatus.lockedById;
  const disableFlag = locked_by != loggedInUserId ? true : false

  const [dayDifference, setDayDifference] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [periodEndDate, setPeriodEndDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [specification, setSpecification] = useState(false)
  const [incomeType, setIncomeType] = useState('1')

  const handleTypeOfDocumentChoice = (values, data)=>{
     if(data.children.toLowerCase() == "other"){
       setSpecification(true)
     }else{
       setSpecification(false)
     }
  }

  const handleSubmit = async (values) => {
    setIsLoading(true)
    let params = {
      lessee_id: lessee.id,
      lease_application_id: data.id,
      day_since_pay_period: dayDifference,
      start_date: startDate,
      period_end_date: periodEndDate,
      ytd_income: values.ytd_income,
      income_type: incomeType,
      employer_client: values.employer_client,
      other_type: values.other_type,
      income_verification_type_id: values.income_verification_type_id
    } 
    // if (allowedUpdate) {
      try {
        let response = await POST(`/api/v1/ytd-pay-incomes`, {ytd_pay_income: params});
        if (response) {
          dispatch(getDetails(response.data.leaseApplication));
          message.success("Data saved successfully");
          form.resetFields()
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        logger.error("Income Verification Error", e);
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error while saving your data');
        }
      }
    // }
  }

  const onChangeStartDate = (date, dateString) => {
    setStartDate(dateString)
  };
  
  const onChangeEndDate = (date, dateString) => {
    setPeriodEndDate(dateString)
    const date1 = new Date(startDate) ;
    const date2 = new Date(dateString) ;
    date2.setDate(date2.getDate() + 1);

    const diffInMs = Math.abs(date2 - date1);
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if(diffInDays > 365) {
        alert('The last date is more than one year. Please change the last date');
        form.setFieldsValue({
            ['period_end_date']: '',
        })
    } else {
      setDayDifference(diffInDays)
    } 
  };

  const handleChange = value => {
    setIncomeType(value);
  };
  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select selected={incomeType} onSelect={handleChange}
        style={{
          width: 80,
        }}
        defaultValue={incomeType}
      >
        <Option value="1">Gross</Option>
        <Option value="2">Net</Option>
      </Select>
    </Form.Item>
  );

  return data && allowedUpdate ? (
    <Form form={form} onFinish={handleSubmit} {...layout} colon={false}>
      <Row gutter={16}>
      <Col xs={24} sm={24} md={24} lg={5} xl={4}>
          <Form.Item name={"income_verification_type_id"} label={"Type of Document"} rules={[{ required: true, message: "Type of Document is required! " }]}>
            <Select size="large" onChange={handleTypeOfDocumentChoice} disabled={disableFlag}>
              {
                incomeVerificationTypeOptions && incomeVerificationTypeOptions.map((obj, index) => {
                  return <Option key={index} value={obj.optionValue}>{obj.optionName}</Option>
                })
              }
            </Select>
          </Form.Item>
          {
            specification ? (<Form.Item 
                            name={"other_type"} 
                            label={"Please Specify"} 
                            rules={[{ required: true, message: "Specification is required!" }]}>
                            <Input size="large" />
                            </Form.Item>) : null
          }
          
        </Col>

        <Col xs={24} sm={24} md={24} lg={5} xl={4}>
          <Form.Item name={"employer_client"} label={"Employer / Client"} rules={[{ required: true, message: "Employer / Client is required!" }]}>
            <Input size="large" disabled={disableFlag}/>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={5} xl={4}>
          <Form.Item name={"start_date"} label={"Start Date"} rules={[{ required: true, message: "Start date is required! " }]}>
            <DatePicker size="large" value="" onChange={onChangeStartDate} className="ant-input-comp" format={"MM-DD-YYYY"} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={5} xl={4}>
          <Form.Item name={"period_end_date"} label={"Period End Date"} rules={[{ required: true, message: "Period end date is required! " }]}>
            <DatePicker size="large" onChange={onChangeEndDate} className="ant-input-comp" format={"MM-DD-YYYY"} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={5} xl={4}>
          <Form.Item name={"ytd_income"} label={"YTD Income"} rules={[{ required: true, message: "YTD income is required!" }]}>
            <InputNumber disabled={disableFlag} addonAfter={suffixSelector} precision={2} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} size="large" />
          </Form.Item>
        </Col>
        
        <Col xs={24} sm={24} md={24} lg={5} xl={3}>
          <Form.Item label={"\u00a0"}>
            <Button type="primary" htmlType="submit" size="large" disabled={disableFlag || isLoading}>
              Add
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  ) : null;
}
