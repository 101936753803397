import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { POST } from '../util/network';

export const fetchActiveModelYears = createAsyncThunk('modelYears/getModelYears', async (params) => {
  let response = null;
  if (params.pageState !== undefined) {
    response = await POST(`/api/v1/model_years/active-model-years`, {
      pageState: params.pageState,
      filterFlag: params.filterFlag,
      filters: params.filters
    } );
  } else {
    response = await POST(`/api/v1/model_years/active-model-years`, {
      pageState: params.pageState,
      filterFlag: params.filterFlag,
      filters: params.filters
    });
  }
  return response.data
})

const initialState = {
  modelYearList: {},
  pageState: {
    page: 0,
    limit: 50,
    modelGroupId: '',
    search: '',
    modelYear: '',
    startDate: '',
    endDate: '',
    makeId: '',
    modelNumber: '',
    volumeNumber: '',
    modelName: '',
    slcMap: ''
  },
  filterFlag: false,
  filters: [],
  errorMessage: null,
}

export const modelYearsSlice = createSlice({
  name: "modelYears",
  initialState: initialState,
  reducers: {
    updateModelYearsPageState: (state, action) => {
      // let payload = action.payload
      // console.log("page", payload.page)
      // state.pageState.page = payload.page
      // state.pageState.limit = payload.limit || state.pageState.limit
      // state.pageState.modelGroupId = payload.modelGroupId || state.pageState.modelGroupId
      // state.pageState.search = payload.search ||  state.pageState.search
      // if(action.payload.filterType === 'notColumn'){
      //   state.filters = []
      //   state.filterFlag = false
      //   state.pageState.page = 0
      //   state.pageState.limit = payload.limit || state.pageState.limit
      //   state.pageState.modelGroupId = payload.modelGroupId
      //   state.pageState.search = payload.search ||  state.pageState.search
      // }
      return {
        ...state,
        pageState: {
          ...state.pageState, ...action.payload
        },
        filters: state.filters,
        filterFlag: action.payload.filterType === 'notColumn' ? false: state.filterFlag
      }
    },
    updateFilters: (state, action) => {
      const filter = state.filters.find((f) => f.id?.toString() === action.payload.filter.id?.toString() )
      if(filter === undefined || filter === 'undefined'){
        state.filters.push(action.payload.filter)
      }
      else{
        state.filters.map((f) => {
          if(f.id === action.payload.filter.id){
            f.columnField = action.payload.filter.columnField
            f.value = action.payload.filter.value
            f.operatorValue = action.payload.filter.operatorValue
          }
        })
      }
      state.filterFlag = true
      state.pageState.page = 0
    },
    removeFilters: (state, action) => {
      const stateFilters = state.filters
      const resultArray = stateFilters.filter(
        (x) => !action.payload.find((x2) => x.id === x2.id)
      );
      if(resultArray.length > 0){
        state.filters = action.payload
      }
      if(state.filters.length == 0){
        state.filterFlag = false
      }
    }
  },

  extraReducers(builder) {
    builder.addCase(fetchActiveModelYears.fulfilled, (state, action) => {
      state.modelYearList = action.payload
      state.isLoading = false
      return state
    }),
      builder.addCase(fetchActiveModelYears.pending, (state, action) => {
        state.isLoading = true;
      }),
      builder.addCase(fetchActiveModelYears.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.errorMessage = 'error occurred';
        return state;
      });
  }
});


export const { updateModelYearsPageState, updateFilters, removeFilters } = modelYearsSlice.actions;

export default modelYearsSlice.reducer;
