import React, {useState} from 'react';
import {Card, CardContent, Grid, IconButton, Typography} from '@material-ui/core';
import {Delete, Edit, Add} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import { Input } from '@mui/material';

const useStyles = makeStyles({
  root: {
    background: 'white',
    borderRadius: 3,
    border: 0,
    // borderRadius: '10px',
    // border: '1px dashed gray',
    color: 'black',
    minHeight: '192px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
    marginBottom: '16px'
  },
});


const styles = {
  card: {
    width: '95%',
    margin: '10px',
    backgroundColor: '#ef4829d1',
    cursor: 'text',
    borderRadius: '10px',
    position: 'relative',
  },
  input: {
    border: 'none',
    outline: 'none',
    fontSize: '1rem',
    fontFamily: 'inherit',
    width: '100%',
    margin: 0,
    padding: '16px',
    backgroundColor: '#ef48291a',
  },
  addButton: {
    position: 'absolute',
    bottom: '-32px',
    right: '-32px',
  },
  editButton: {
    position: 'absolute',
    top: '8px',
    right: '40px',
    color: "#fff"
  },
  deleteButton: {
    position: 'absolute',
    top: '8px',
    right: '2px',
    color: "#fff"
  },
};

function NoteCardData({id, note, notes, setNotes, addNote, handleDelete, editNote, setAddButtonVisibility}) {
  const [text, setText] = useState(note.description);
  const [editButtonVisibility, setEditButtonVisibility] = useState(true);

  const handleCardClick = () => {
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleUnsavedNote = (id) => {
    setAddButtonVisibility(true);
    setNotes(notes.filter((note) => note.id !== id));
  };

  const handleNoteDelete = () => {
    note.saved ? handleDelete(id) : handleUnsavedNote(id)
  };

  const handleEditNote = () => {
    setEditButtonVisibility(false);
    setAddButtonVisibility(false);
    note.is_editing = true;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setAddButtonVisibility(true);
      setEditButtonVisibility(true);
      note.is_editing = false
      note.saved ? editNote(id, text) : addNote(text);
    }
  };

  return (
    <Card onClick={handleCardClick} style={styles.card}>
        <CardContent style={{backgroundColor: note.color}}>
          <Input
            type="text"
            value={text}
            onChange={handleTextChange}
            onKeyDown={handleKeyDown}
            style={{
              border: 'none',
              outline: 'none',
              fontSize: '0.9rem',
              fontFamily: 'inherit',
              width: '85%',
              margin: 0,
              padding: '0px',
              backgroundColor: note.color,
              whiteSpace: 'pre-wrap'
            }}
            disableUnderline={true}
            inputProps={{
              style: {
                color: 'white',
              },
              disabled: !note.is_editing,
            }}
            multiline={true}
            placeholder="Enter Note"
          />
          <IconButton onClick={() => handleEditNote()} style={{
            position: 'absolute',
            top: '8px',
            right: '40px',
            color: "#fff",
            display: note?.is_editing ? 'none' : 'block',
          }}>
            <Edit/>
          </IconButton>
          <IconButton onClick={() => handleNoteDelete()} style={{
            position: 'absolute',
            top: '8px',
            right: '2px',
            color: "#fff",
            display: note?.is_editing && !editButtonVisibility ? 'none' : 'block',
          }}>
            <Delete/>
          </IconButton>
        </CardContent>
    </Card>
  );
}

function NoteCard(props) {
  const {notes, setNotes, addNote, deleteNote, editNote} = props
  const classes = useStyles();
  const [addButtonVisibility, setAddButtonVisibility] = useState(true);
  const handleAddNote = () => {
    setAddButtonVisibility(false);
    setNotes([...notes, {id: notes.length, is_editing: true, color: '#ef4829d1', saved: false}]);
  };

  const handleDeleteNote = (id) => {
    deleteNote(id)
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h6" component="h2" sx={{fontWeight: "medium", marginBottom: 5, marginLeft: "18px"}}>
            Add Note
            <IconButton onClick={handleAddNote} style={{margin: '16px'}} disabled={!addButtonVisibility}>
              <Add/>
            </IconButton>
          </Typography>
          <Grid style={{
            justifyContent: 'center', overflow: 'auto',
            maxHeight: '320px'
          }}>
            {notes?.map((note) => (
              <NoteCardData key={note.id} id={note.id} note={note}  notes={notes}  setNotes={setNotes} addNote={addNote} handleDelete={handleDeleteNote}
                            editNote={editNote} setAddButtonVisibility={setAddButtonVisibility}/>
            )).reverse()}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

export default NoteCard;




