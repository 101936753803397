import React, { useState, useEffect } from 'react'
import { GET, PUT } from "../../util/network";
import MainHeader from "../../components/layouts/MainHeader";
import MainFooter from "../../components/layouts/MainFooter";
import { message, Spin } from "antd";
import FundingDelayView from "./FundingDelayView";

export default function FundingDelayRenderer(props) {
    const { id } = props.match.params;
    const { handleNoAuth } = props;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    const getFundingDelay = async () => {
        setLoading(true)
        try {
          const response = await GET(`/api/v1/funding-delays/${id}`);
          setData(response?.data);
        } catch (e) {
          message.error("Error while loading make.")
        }
        setLoading(false)
      }
    
    useEffect(() => {
        getFundingDelay();
    }, []);

  return (
    <Spin spinning={loading} size="large" tip="Loading..." >
        <MainHeader activePage="leaseApplicationRelated" />
            {
                data && 
                <FundingDelayView
                data = {data}
                />
            }
        <MainFooter />
    </Spin>
  )
}
