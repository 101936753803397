import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';

const MaxTermLengthForm = () => (
  <Form.List name="maximum_term_length_per_year">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }) => (
            <Space
              key={key}
              style={{
                display: 'flex',
                marginBottom: 8,
              }}
              align="baseline"
            >
              <Form.Item
                {...restField}
                name={[name, 'term_key']}
                rules={[
                  {
                    required: true,
                    message: 'Missing key value',
                  },
                ]}
              >
                <Input placeholder="Key Value, eg: 2007-2014" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, 'term_value']}
                rules={[
                  {
                    required: true,
                    message: 'Missing field value',
                  },
                ]}
              >
                <Input type='number' placeholder="Field Value" />
              </Form.Item>
              <MinusCircleOutlined onClick={() => remove(name)} />
            </Space>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />} style={{width: "25%"}}>
              Add Term Length
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
);
export default MaxTermLengthForm;
