import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Form, message, Spin, Result} from 'antd';
import {GET, POST, DELETE} from '../../../util/network';
import TrainingForm from './TrainingForm';
import TrainingTable from './TrainingTable';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

export default function TrainingRenderer(props) {
  const {allowedGet, allowedUpdate, handleNoAuth, loading, setLoading} = props;
  const history = useHistory();
  const [dealerShips, setDealerShips] = useState();
  const [training, setTraining] = useState();
  const [form] = Form.useForm();
  const [thumbnailErrorMessage, setThumbnailErrorMessage] = useState('');
  const [fileErrorMessage, setFileErrorMessage] = useState('');

  const getThumbnail = (e) => {
    if (e.file?.error?.total === 0){
      e.file.status = 'done'
    }

    if (e.file.type !== 'image/png' && e.file.type !== 'image/jpeg' && e.file.type !== 'image/jpg') {
      setThumbnailErrorMessage('Please give valid image.');
      return null;
    } else {
      setThumbnailErrorMessage('');
      if (Array.isArray(e)) {
        return e;
      }

      return e && e.fileList;
    }
  };

  const getFile = (e) => {
    if (e.file?.error?.total === 0){
      e.file.status = 'done'
    }

    if (e.file.type !== 'application/pdf' && e.file.type !== 'video/mp4' && e.file.type !== 'video/webm' && e.file.type !== 'video/wmv' && e.file.type !== 'video/mov' && e.file.type !== 'video/mkv' && e.file.type !== 'video/flv') {
      setFileErrorMessage('Please give valid file.');
      return null;
    } else {
      setFileErrorMessage('');
      if (Array.isArray(e)) {
        return e;
      }

      return e && e.fileList;
    }
  };

  const handleSubmit = async (val) => {
    setLoading(true);
    let formData = new FormData()
    formData.append("title", val.title)
    formData.append("file", val.file[0].originFileObj)
    formData.append("thumbnail", val.thumbnail[0].originFileObj)
    formData.append("dealers", val.dealer)
    try {
      await POST(`/api/v1/trainings/set-trainings`, formData);
      message.success('Data saved successfully');
      getTrainingData();
      form.resetFields()
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while saving data');
      }
    }
    setLoading(false);
  }

  const getDealerships = async () => {
    setLoading(true);
    try {
      let response = await GET(`/api/v1/trainings/dealerships`);
      setDealerShips(response.data.dealership)
      getTrainingData();
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while fetching your dealership data');
      }
    }
    setLoading(false);
  }

  const deleteTraining = async (id) => {
    try {
      await DELETE(`/api/v1/trainings/delete-training/${id}`);
      getTrainingData();
      message.success('Data deleted successfully');
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        handleNoAuth(history);
      } else {
        message.error('Error while deleting your data');
      }
    }
  }

  const getTrainingData = async () => {
    if (allowedGet) {
      setLoading(true);
      try {
        let response = await GET(`/api/v1/trainings/admin-training-list`);
        setTraining(response.data)
      } catch (e) {
        if (e && e.response && e.response.status === 401) {
          handleNoAuth(history);
        } else {
          message.error('Error getting trainings');
        }
      }
      setLoading(false);
    }
  }


  useEffect(() => {
    getDealerships();
  }, []);

  return (
    <Spin
      spinning={loading}
      size='large'
      tip='Loading...'
    >
      <>
        {allowedGet
          ?
          <Box>
            <Grid container direction="row">
              <Grid item md={12}>
                <TrainingForm
                  allowedGet={allowedGet}
                  allowedUpdate={allowedUpdate}
                  layout={layout}
                  form={form}
                  handleSubmit={handleSubmit}
                  getFile={getFile}
                  getThumbnail={getThumbnail}
                  thumbnailErrorMessage={thumbnailErrorMessage}
                  fileErrorMessage={fileErrorMessage}
                  dealerShips={dealerShips}
                />
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Grid item md={12}>
                <Box sx={{width: "100%", height: "400px"}}>
                  <TrainingTable
                    data={training}
                    deleteTraining={deleteTraining}
                    dealerShips={dealerShips}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          :
          <Result
            status='warning'
            title='You are not allowed to access this data.'
          />
        }
      </>
    </Spin>
  );
}
